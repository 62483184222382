import * as React from "react";
import moment from "moment";
// components
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { AutoSizer, Column, Table, WindowScroller } from "react-virtualized";
import Datepicker from "../input/Datepicker";
import Input from "../input/Input";
import Select from "../input/Select";
import Checkbox from "../input/Checkbox";
import Label from "../label/Label";
import Button from "../button/Button";
import Foot from "../table/Foot";
// enums
import { breakpoints } from "../../enums/breakpoints";
// types
import { ICurrencyType } from "../../types/CurrencyType";
import { IPayArea } from "../../types/PayArea";
import { ProcessingError } from "../../types/ProcessingError";
import { IRequestType } from "../../types/RequestType";
import { IWageType } from "../../types/WageType";

export interface IEditingProps {
  _checkChange: any;
  _dateChange: any;
  _handleCheckAll: any;
  _inputBlur: any;
  _inputChange: any;
  _removeTask: any;
  checked: number;
  currencyTypes: ICurrencyType[];
  currencyTypeOptions: JSX.Element[];
  invalid: ProcessingError[];
  merged: number;
  payAreas: IPayArea[];
  payAreaOptions: JSX.Element[];
  requestType: IRequestType;
  statusId: number | null;
  tasks: any[];
  unmatched: ProcessingError[];
  wageTypes: IWageType[];
  wageTypeOptions: JSX.Element[];
}
export interface IEditingState {
  height: number;
}

export default class Editing extends React.Component<IEditingProps, IEditingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      height: 45
    };
    this._buttonRenderer = this._buttonRenderer.bind(this);
    this._checkboxRenderer = this._checkboxRenderer.bind(this);
    this._datepickerRenderer = this._datepickerRenderer.bind(this);
    this._headerCheckboxRenderer = this._headerCheckboxRenderer.bind(this);
    this._inputHiddenRenderer = this._inputHiddenRenderer.bind(this);
    this._inputNumberRenderer = this._inputNumberRenderer.bind(this);
    this._inputTextRenderer = this._inputTextRenderer.bind(this);
    this._rowRenderer = this._rowRenderer.bind(this);
    this._selectRenderer = this._selectRenderer.bind(this);
    this._setHeight = this._setHeight.bind(this);
  }
  public componentDidMount(): void {
    // this.table.forceUpdateGrid();
    window.addEventListener("resize", this._setHeight);
    this._setHeight();
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this._setHeight);
  }

  public render() {
    const rowsProcessed: JSX.Element = (
      <div className="processing">
        <p>{this.props.tasks.length} rows have been processed.</p>
      </div>
    );
    const validationErrors: JSX.Element =
      this.props.invalid.length > 0 ? (
        <div className="processing">
          <p>
            There {this.props.invalid.length === 1 ? "was" : "were"} {this.props.invalid.length} validation{" "}
            {this.props.invalid.length === 1 ? "error" : "errors"} during processing.
          </p>
          <ul className="processingErrors">
            {this.props.invalid.map((invalid, index) => {
              return (
                <li className="processingError" key={index}>
                  <Icon iconName="Error" /> Invalid task at row {invalid.task}.
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="processing">
          <p>There were no validation errors during processing.</p>
        </div>
      );
    const matchErrors: JSX.Element =
      this.props.unmatched.length > 0 ? (
        <div className="processing">
          <p>
            There {this.props.unmatched.length === 1 ? "was" : "were"} {this.props.unmatched.length} match{" "}
            {this.props.unmatched.length === 1 ? "error" : "errors"} during processing.
          </p>
          <ul className="processingErrors">
            {this.props.unmatched.map((unmatched, index) => {
              return (
                <li className="processingError" key={index}>
                  <Icon iconName="Error" /> Unmatched employee at row {unmatched.task}.
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="processing">
          <p>There were no match errors during processing.</p>
        </div>
      );
    return (
      <div className={"editing"}>
        <div className="editInner">
          <div
            className={
              this.props.requestType && this.props.requestType.code === "IT0014"
                ? "editingIT0014"
                : this.props.requestType && this.props.requestType.code === "IT2010"
                ? "editingIT2010"
                : "editingIT0015"
            }
          >
            <WindowScroller
              scrollElement={document.querySelectorAll("#content > div > div") ? document.querySelectorAll("#content > div > div")[0] : window}
            >
              {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight={true}>
                  {({ width }) => (
                    <Table
                      autoHeight={true}
                      headerHeight={35}
                      height={height ? height : 0}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      overscanRowCount={2}
                      rowHeight={this.state.height}
                      rowCount={this.props.tasks.length}
                      rowGetter={({ index }) => this.props.tasks[index]}
                      rowRenderer={this._rowRenderer}
                      scrollTop={scrollTop}
                      width={width}
                    >
                      <Column
                        width={35}
                        label=""
                        dataKey="selectTask"
                        className="checkboxColumn"
                        headerRenderer={this._headerCheckboxRenderer}
                        cellRenderer={this._checkboxRenderer}
                        flexShrink={0}
                      />
                      <Column className="srOnly" headerClassName="srOnly" width={0} label="" dataKey="id" cellRenderer={this._inputHiddenRenderer} />
                      <Column
                        className="srOnly"
                        headerClassName="srOnly"
                        width={0}
                        label=""
                        dataKey="bottlerEmployeeId"
                        cellRenderer={this._inputHiddenRenderer}
                      />
                      <Column width={120} label="First" dataKey="firstName" cellRenderer={this._inputTextRenderer} />
                      <Column width={60} label="MI" dataKey="middleInitial" cellRenderer={this._inputTextRenderer} />
                      <Column width={120} label="Last" dataKey="lastName" cellRenderer={this._inputTextRenderer} />
                      <Column width={60} label="Suffix" dataKey="suffix" cellRenderer={this._inputTextRenderer} />
                      <Column width={105} label="Nickname" dataKey="nickname" cellRenderer={this._inputTextRenderer} />
                      <Column width={120} label="PERNR" dataKey="pernr" cellRenderer={this._inputTextRenderer} />
                      <Column width={120} label="Cost Center" dataKey="costCenter" cellRenderer={this._inputTextRenderer} />
                      <Column width={120} label="Pay Area" dataKey="payAreaId" cellRenderer={this._selectRenderer} />
                      <Column width={100} label="Wage Type" dataKey="wageTypeId" cellRenderer={this._selectRenderer} />
                      {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                        <Column width={90} label="Hours" dataKey="hours" cellRenderer={this._inputNumberRenderer} />
                      ) : null}
                      {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                        <Column width={90} label="Number" dataKey="number" cellRenderer={this._inputNumberRenderer} />
                      ) : null}
                      <Column width={90} label="Units" dataKey="units" cellRenderer={this._inputNumberRenderer} />
                      <Column width={135} label="Date" dataKey="dateOfPay" cellRenderer={this._datepickerRenderer} flexGrow={0} />
                      <Column width={120} label="Amount" dataKey="amount" cellRenderer={this._inputNumberRenderer} />
                      <Column width={100} label="Currency" dataKey="currencyTypeId" cellRenderer={this._selectRenderer} />
                      {this.props.requestType && this.props.requestType.code === "IT0014" ? (
                        <Column width={120} label="Dollar Limit" dataKey="dollarLimit" cellRenderer={this._inputNumberRenderer} />
                      ) : null}
                      {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                        <Column width={90} label="Rate" dataKey="rate" cellRenderer={this._inputNumberRenderer} />
                      ) : null}
                      <Column width={120} label="Authority" dataKey="authority" cellRenderer={this._inputTextRenderer} />
                      <Column width={120} label="Comment" dataKey="comment" cellRenderer={this._inputTextRenderer} flexGrow={1} />
                      <Column width={40} label="" dataKey="deleteTask" cellRenderer={this._buttonRenderer} flexShrink={0} />
                    </Table>
                    // </div>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          </div>
        </div>
        <Foot>
          {rowsProcessed}
          {validationErrors}
          {matchErrors}
        </Foot>
      </div>
    );
  }
  private _rowRenderer({ a11yProps, className, columns, key, rowData, style }: any): JSX.Element {
    return (
      <div
        {...a11yProps}
        className={rowData.isChecked ? className + " checked" : !rowData.isMatched ? className + " notMatched" : className}
        key={key}
        role="row"
        style={style}
      >
        {columns}
      </div>
    );
  }
  private _headerCheckboxRenderer({ dataKey, disableSort, label, sortBy, sortDirection }: any): JSX.Element {
    return (
      <div>
        <Checkbox
          id="select-all-rows"
          text=""
          title={"Select All Rows"}
          name="select-all-rows"
          disabled={this.props.tasks.length < 2}
          checked={this.props.checked === this.props.tasks.length - this.props.merged}
          onChange={e => this.props._handleCheckAll(e)}
        />
      </div>
    );
  }
  private _checkboxRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    if (rowData.isMatched === false) {
      return (
        <div className="tip tipRight" data-tip="Task does not match any employees.">
          <Icon iconName="CalculatorNotEqualTo" />
        </div>
      );
    } else if (rowData.data.deletedAt) {
      return (
        <div className="tip tipRight" data-tip="Deleted tasks will not be submitted.">
          <Icon iconName="Cancel" />
        </div>
      );
    } else if (rowData.data.isMerged === true) {
      return (
        <div className="tip tipRight" data-tip="Merged tasks will not be submitted.">
          <Icon iconName="Merge" />
        </div>
      );
    } else {
      return (
        <div>
          <Checkbox
            id={dataKey + "-" + rowIndex + "-" + columnIndex}
            text=""
            title={"Select This Task"}
            name={dataKey}
            disabled={rowData.isValid === false || this.props.tasks.length < 2 ? true : false}
            checked={rowData.isChecked}
            onChange={e => this.props._checkChange(e, rowIndex)}
          />
        </div>
      );
    }
  }
  private _inputHiddenRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const value: string = dataKey === "id" ? rowData.data.id : dataKey === "bottlerEmployeeId" ? rowData.data.bottlerEmployeeId : "";
    const required: boolean = dataKey === "bottlerEmployeeId" ? true : false;
    return (
      <div>
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="hidden"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowIndex, columnIndex)}
          onChange={e => this.props._inputChange(e, rowIndex, columnIndex)}
          required={required}
          tabIndex={-1}
          value={value}
        />
      </div>
    );
  }
  private _inputNumberRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const readOnly: boolean =
      this.props.statusId === 3 || this.props.statusId === 4 || rowData.data.isMerged === true || rowData.data.deletedAt ? true : false;
    const tabIndex: number =
      this.props.statusId === 3 || this.props.statusId === 4 || rowData.data.isMerged === true || rowData.data.deletedAt ? -1 : 0;
    const text: string =
      dataKey === "amount"
        ? "Amount"
        : dataKey === "dollarLimit"
        ? "Dollar Limit"
        : dataKey === "number"
        ? "Number"
        : dataKey === "rate"
        ? "Rate"
        : dataKey === "hours"
        ? "Hours"
        : dataKey === "units"
        ? "Units"
        : "";
    const value: string =
      dataKey === "amount"
        ? rowData.data.amount
        : dataKey === "dollarLimit"
        ? rowData.data.dollarLimit
        : dataKey === "number"
        ? rowData.data.number
        : dataKey === "rate"
        ? rowData.data.rate
        : dataKey === "hours"
        ? rowData.data.hours
        : dataKey === "units"
        ? rowData.data.units
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="number"
          min="0"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowIndex, columnIndex)}
          onChange={e => this.props._inputChange(e, rowIndex, columnIndex)}
          pattern="[0-9]*"
          readOnly={readOnly}
          step="0.01"
          tabIndex={tabIndex}
          value={value}
        />
      </div>
    );
  }
  private _inputTextRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const autoFocus: boolean = rowData.autoFocus && dataKey === "firstName";
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const text: string =
      dataKey === "firstName"
        ? "First Name"
        : dataKey === "middleInitial"
        ? "Middle Initial"
        : dataKey === "lastName"
        ? "Last Name"
        : dataKey === "suffix"
        ? "Suffix"
        : dataKey === "nickname"
        ? "Nickname"
        : dataKey === "pernr"
        ? "PERNR"
        : dataKey === "costCenter"
        ? "Cost Center"
        : dataKey === "authority"
        ? "Authority"
        : dataKey === "comment"
        ? "Comment"
        : "";
    const readOnly: boolean =
      this.props.statusId === 3 || this.props.statusId === 4 || rowData.data.isMerged === true || rowData.data.deletedAt ? true : false;
    const required: boolean =
      dataKey === "firstName" || dataKey === "lastName" || dataKey === "pernr" || dataKey === "costCenter" || dataKey === "authority" ? true : false;
    const showValidation: boolean =
      (dataKey === "firstName" && !rowData.firstNameValid && !rowData.data.firstName) ||
      (dataKey === "lastName" && !rowData.lastNameValid && !rowData.data.lastName) ||
      (dataKey === "pernr" && !rowData.pernrValid && !rowData.data.pernr) ||
      (dataKey === "costCenter" && !rowData.costCenterValid && !rowData.data.costCenter) ||
      (dataKey === "authority" && !rowData.costCenterValid && !rowData.data.authority) ||
      (dataKey === "pernr" && rowData.isDuplicate)
        ? true
        : false;
    const tabIndex: number =
      this.props.statusId === 3 || this.props.statusId === 4 || rowData.data.isMerged === true || rowData.data.deletedAt ? -1 : 0;
    const value: string =
      dataKey === "firstName"
        ? rowData.data.firstName
        : dataKey === "middleInitial"
        ? rowData.data.middleInitial
        : dataKey === "lastName"
        ? rowData.data.lastName
        : dataKey === "suffix"
        ? rowData.data.suffix
        : dataKey === "nickname"
        ? rowData.data.nickname
        : dataKey === "pernr"
        ? rowData.data.pernr
        : dataKey === "costCenter"
        ? rowData.data.costCenter
        : dataKey === "authority"
        ? rowData.data.authority
        : dataKey === "comment"
        ? rowData.data.comment
        : "";
    return (
      <div>
        <Label htmlFor={id} required={required} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          autoFocus={autoFocus}
          id={id}
          inputType="text"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowIndex, columnIndex)}
          onChange={e => this.props._inputChange(e, rowIndex, columnIndex)}
          readOnly={readOnly}
          required={required}
          showRequired={required}
          showValidation={showValidation}
          tabIndex={tabIndex}
          value={value}
        />
      </div>
    );
  }
  private _selectRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean =
      this.props.statusId === 3 || this.props.statusId === 4 || rowData.data.isMerged === true || rowData.data.deletedAt ? true : false;
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const value: string =
      dataKey === "wageTypeId" && rowData.data.wageTypeId
        ? rowData.data.wageTypeId
        : dataKey === "currencyTypeId" && rowData.data.currencyTypeId
        ? rowData.data.currencyTypeId
        : dataKey === "payAreaId" && rowData.data.payAreaId
        ? rowData.data.payAreaId
        : "";
    if (dataKey === "wageTypeId") {
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Wage Type" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowIndex, columnIndex)}
            onChange={e => this.props._inputChange(e, rowIndex, columnIndex)}
            options={this.props.wageTypeOptions}
            placeholder={"Select..."}
            required={true}
            selections={this.props.wageTypes}
            showOptionsOnFocus={true}
            showPlaceholder={true}
            showRequired={true}
            showValidation={!rowData.wageTypeIdValid && !rowData.data.wageTypeId ? true : false}
            value={value}
          />
        </div>
      );
    } else if (dataKey === "currencyTypeId") {
      return (
        <div>
          <Label htmlFor={id} srOnlyAt={breakpoints.md} text="Currency" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowIndex, columnIndex)}
            onChange={e => this.props._inputChange(e, rowIndex, columnIndex)}
            options={this.props.currencyTypeOptions}
            selections={this.props.currencyTypes}
            selectionType={"label"}
            showOptionsOnFocus={true}
            placeholder={"Select..."}
            value={value ? value : "1"} // default to USD
          />
        </div>
      );
    } else {
      // dataKey === "payAreaId"
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Pay Area" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowIndex, columnIndex)}
            onChange={e => this.props._inputChange(e, rowIndex, columnIndex)}
            options={this.props.payAreaOptions}
            placeholder={"Select..."}
            required={true}
            selections={this.props.payAreas}
            selectionType={"label"}
            showOptionsOnFocus={true}
            showPlaceholder={true}
            showRequired={true}
            showValidation={!rowData.payAreaIdValid || (!rowData.payAreaIdValid && !rowData.data.payAreaId) ? true : false}
            value={value}
          />
        </div>
      );
    }
  }
  private _datepickerRenderer({ rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean =
      this.props.statusId === 3 || this.props.statusId === 4 || rowData.data.isMerged === true || rowData.data.deletedAt ? true : false;
    const value: Date | null = rowData.data.dateOfPay ? moment(rowData.data.dateOfPay, "YYYY-MM-DD").toDate() : null;
    return (
      <div>
        <Datepicker
          disabled={disabled}
          label="Date"
          onChange={(date: Date) => this.props._dateChange(date, rowIndex)}
          placeholder={""}
          required={false}
          value={value}
        />
      </div>
    );
  }
  private _buttonRenderer({ columnIndex, rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean =
      rowData.data.isMatched === false ||
      this.props.tasks.length < 2 ||
      this.props.statusId === 4 ||
      rowData.data.isMerged === true ||
      (rowData.data.deletedAt !== null && rowData.data.deletedAt !== undefined)
        ? true
        : false;
    return (
      <div>
        <Button
          className="buttonLinkPrimaryColor buttonDelete"
          disabled={disabled}
          hideText={true}
          iconLeft={<Icon iconName="Delete" />}
          id={rowData.data.id ? "delete-" + rowData.data.id.toString() : "delete-add-" + rowIndex.toString() + "-" + columnIndex.toString()}
          onClick={e => this.props._removeTask(e, rowIndex)}
          text="Delete Task"
          title="Delete Task"
        />
      </div>
    );
  }
  private _setHeight(): void {
    if (window.innerWidth < 1024) {
      if (this.props.requestType && this.props.requestType.code === "IT2010") {
        this.setState({ height: 1200 });
      } else if (this.props.requestType && this.props.requestType.code === "IT0014") {
        this.setState({ height: 1100 });
      } else {
        this.setState({ height: 930 });
      }
    } else {
      this.setState({ height: 45 });
    }
  }
}
