import * as React from "react";

export interface IThProps {
  text: string;
  title: string;
  hidden?: boolean;
}

const Th: React.StatelessComponent<IThProps> = ({ children, hidden, text, title }) => {
  const className: string = hidden ? "displayNone" : "th";
  return (
    <th className={className} title={title}>
      <div className="thInner">
        {text && text}
        {children && children}
      </div>
    </th>
  );
};

export default Th;
