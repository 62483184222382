import * as React from "react";
import Swagger from "./Swagger";
import Permissions from "../permissions/Permissions";

export interface ISwaggerV3Props {}

const SwaggerV3: React.StatelessComponent<ISwaggerV3Props> = ({}) => {
  return (
    <Permissions title={`Swagger v3`}>
      <Swagger version="v3" />
    </Permissions>
  );
};

export default SwaggerV3;
