import * as React from "react";
import Dropdown from "../dropdown/Dropdown";

export interface IFilterProps {
  className?: string;
  filterDisabled: boolean;
  filtered: boolean;
  filterOpen?: boolean;
  filterPosition?: "filterLeft" | "filterRight";
  filterText: string;
}

export interface IFilterState {}

export default class Filter extends React.Component<IFilterProps, IFilterState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<IFilterProps> {
    const {
      children,
      className,
      filterDisabled,
      filtered,
      filterPosition,
      filterText
    } = this.props;

    let cssClass: string = filterPosition ? `filter ${filterPosition}` : `filter`;

    if (className) {
      cssClass = `${cssClass} ${className}`;
    }

    const icon: string = filtered ? "FilterSolid" : "Filter";

    const text: string = filterText ? "Filter By " + filterText : "Filter";

    return (
      <div className={cssClass}>
        <Dropdown
          buttonHideText={true}
          buttonIcon={icon}
          buttonText={text}
          disabled={filterDisabled}
          id="filter"
        >
          {children}
        </Dropdown>
      </div>
    );
  }
}
