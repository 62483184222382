import * as React from "react";
import { connect } from "react-redux";
// components
import Page from "../../components/page/Page";
import RefreshGroup from "../../components/refresh/Group";
import BottlerSwitcher from "../../components/switcher/BottlerSwitcher";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import UserAdoptionChart from "../../components/notify/UserAdoptionChart";
// enums
import { RequestTypes } from "../../enums/RequestTypes";
// hocs
import { withPageLog } from "../logging/LogComponentChange";
import FetchData from "../../hocs/FetchData";
// types
import { IBottlerMemberAndLoginCountsResponse } from "../../types/Bottler";

interface IUserAdoptionProps {
  bottlerId: number | null;
}

interface IBottlersFetchDataProps {
  data: IBottlerMemberAndLoginCountsResponse;
  isLoading: boolean;
  fetchData: Function;
}

const UserAdoption = ({ bottlerId }: IUserAdoptionProps) => {
  return (
    <Page key={bottlerId || undefined} permissions={true} title="User Adoption">
      {bottlerId ? (
        <FetchData url={`/api/v1/bottlerMemberAndLoginCounts/${bottlerId}`}>
          {({ data, isLoading, fetchData }: IBottlersFetchDataProps) => {
            return (
              <>
                <RefreshGroup refreshId="user-adoption" handleRefresh={fetchData} isLoading={isLoading} />
                <BottlerSwitcher showAllOption={true} requestTypeId={RequestTypes.EquipmentRepair} />
                <LoaderWrapper>
                  <UserAdoptionChart data={data && data.bottlers ? data.bottlers : []} />
                  <Loader loading={isLoading} type="Overlay" showImage={true} position="Top" />
                </LoaderWrapper>
              </>
            );
          }}
        </FetchData>
      ) : (
        <FetchData url="/api/v1/bottlerMemberAndLoginCounts">
          {({ data, isLoading, fetchData }: IBottlersFetchDataProps) => {
            return (
              <>
                <RefreshGroup refreshId="user-adoption" handleRefresh={fetchData} isLoading={isLoading} />
                <BottlerSwitcher showAllOption={true} requestTypeId={RequestTypes.EquipmentRepair} />
                <LoaderWrapper>
                  <UserAdoptionChart data={data && data.bottlers ? data.bottlers : []} />
                  <Loader loading={isLoading} type="Overlay" showImage={true} position="Top" />
                </LoaderWrapper>
              </>
            );
          }}
        </FetchData>
      )}
    </Page>
  );
};

export default connect((state: any) => ({
  bottlerId: state.bottler.bottler && state.bottler.bottler.id
}))(withPageLog(UserAdoption));
