import * as React from "react";
import Band from "../../components/layout/Band";
import H1 from "../../components/typography/H1";
import Section from "../../components/layout/Section";
import ButtonLink from "../../components/button/ButtonLink";
import DashboardLogo from "../../images/documentation/BSNALogoCircle.png";
import DocsImage2 from "../../images/documentation/Admin-2.png";
import DocsImage4 from "../../images/documentation/Admin-4.png";
import DocsImage8 from "../../images/documentation/Admin-8.png";

export interface IDocumentationProps {}

export interface IDocumentationState {}

class Documentation extends React.Component {
  public render() {
    return (
      <div className="documentation">
        <Band>
          <H1>Dashboard</H1>
        </Band>
        <Section>
          <div className="container all-center">
            <div className="col-25">
              <img src={DashboardLogo} alt="" />
            </div>
            <div className="col-75">
              <h1>Welcome to the newly designed BSNA Admin app.</h1>
              <p className="lead">
                If it is your first time here, take a second to click the link below to visit our Documentation page to view screenshots and instructions that help walk you through
                all the features of the app.
              </p>
              <ButtonLink href="/documentation" text="View Documentation" title="View Documentation" />
            </div>
          </div>

          <div className="container white-bg">
            <div className="col-33">
              <h3>Left-Side Navigation</h3>
              <p>
                By clicking on any navigation item On the left panel you are taken to a screen with a sub navigation menu just to the right to help you drill down further within
                this category.
              </p>
              <img src={DocsImage2} alt="" />
            </div>
            <div className="col-33">
              <h3>Usage Reports</h3>
              <p>
                The top section is a graph and the bottom section displays a list of each individual request made. Both can be exported by clicking the associated download buttons.
              </p>
              <img src={DocsImage4} alt="" />
            </div>
            <div className="col-33">
              <h3>Service Requests</h3>
              <p>
                In the Requests sub navigation menu you are able to edit and process individual requests. You can also filter and sort the results per column to view the desired
                data set.
              </p>
              <img src={DocsImage8} alt="" />
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default Documentation;
