import { isJsonString } from "./validation";

export const formatName = (displayName: string | null, givenName: string | null, surname: string | null): string => {
  if (displayName) {
    return displayName;
  }
  if (givenName && surname) {
    return `${givenName} ${surname}`;
  }
  return "";
};

export const formatEmail = (mail: string | null | undefined, userPrincipalName: string): string => {
  return mail ? mail : userPrincipalName;
};

export const formatUserName = (emailPart: string): string => {
  let username: string = "";

  if (emailPart && emailPart.indexOf("@") > -1) {
    const emailParts: string[] = emailPart.split("@");
    username = emailParts[0];
  } else if (emailPart) {
    username = emailPart;
  }

  return username;
};

/**
 * Formats Slate WYSIWYG stringified JSON into a plain string
 * @param requestDescription string | null
 */
export const formatWysiwyg = (requestDescription: string | null) => {
  const wysiwygKeywords = /object|document|nodes|leaves/g;
  if (requestDescription) {
    if (isJsonString(requestDescription) && wysiwygKeywords.test(requestDescription)) {
      let description: any = [];
      const wysiwyg = JSON.parse(requestDescription);

      if (wysiwyg && wysiwyg.document) {
        wysiwyg.document.nodes.map(node => {
          if (node.type === "paragraph") {
            const leaves = node.nodes[0].leaves;

            if (leaves && leaves.length > 0) {
              description.push(leaves[0].text.trim());
            }
          }

          if (node.type === "bulleted-list") {
            const bullets: any[] = [];

            node.nodes.map(_node => {
              const leaves = _node.nodes[0].leaves;

              if (leaves && leaves.length > 0) {
                bullets.push(leaves[0].text.trim());
              }
            });

            description.push(bullets);
          }
        });
      }
      return (
        description
          .join(" ")
          // remove line breaks
          .replace(/(\r\n|\n|\r)/gm, "")
          .trim()
      );
    }

    return requestDescription;
  }
};
