const userAgent: string = window.navigator.userAgent;
export const isEdgeBrowser = (): boolean => {
  if (userAgent.indexOf("Edge/") === -1) {
    return false;
  }
  return true;
};

export const isIEBrowser = (): boolean => {
  if (userAgent.indexOf("Trident/") === -1) {
    return false;
  }
  return true;
};
