import * as React from "react";

interface ILoaderWrapperProps {
  className?: string;
}

export default class LoaderWrapper extends React.Component<
  ILoaderWrapperProps
> {
  public render() {
    const { className, children } = this.props;
    return (
      <div
        className={className ? `$loaderWrapper ${className}` : "loaderWrapper"}
      >
        {children}
      </div>
    );
  }
}
