import { getRequest, putRequest } from "../core/api/requestConfig";
import { IAppModePut, IAppModePutResponse, IAppModeGetResponse } from "../types/AppMode";

/**
 * Get app modes
 * @returns IAppMode[]
 */
export const getAppModes = async (): Promise<IAppModeGetResponse> => {
  try {
    const result = await getRequest(`/api/v1/appModes`);
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Update an app mode
 * @param update IAppModePut
 * @returns IAppModePutResponse
 */
export const putAppMode = async (update: IAppModePut): Promise<IAppModePutResponse> => {
  try {
    const result = await putRequest(`/api/v1/appModes/${update.uuid}`, update);
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};
