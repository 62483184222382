import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createHashHistory } from "history";
// components
import Button from "../button/Button";
import PrimaryNavLink from "./PrimaryNavLink";
import PrimaryNavLoading from "./PrimaryNavLoading";
import SubNavLink from "./SubNavLink";
import SecondaryNavLoading from "./SecondaryNavLoading";
// types
import { IFeatureFlags } from "../../core/featureFlags/components/launchDarkly";
import { IAdminAppRoute } from "../../types/AdminAppRoute";

export interface INavsProps {
  adminAppRoutes: IAdminAppRoute[];
  adminAppRoutesLoading: boolean;
  featureFlags: IFeatureFlags;
  getAdminAppRoutes: any;
  isAdmin: boolean;
  isLDReady: boolean;
}

export interface INavsState {
  navsOpen: boolean;
}

class Navs extends React.Component<INavsProps, INavsState> {
  public constructor(props: INavsProps) {
    super(props);
    this.state = {
      navsOpen: true
    };

    this._handleClosingNav = this._handleClosingNav.bind(this);
    this._handleOpeningNav = this._handleOpeningNav.bind(this);
    this._handleToggleMobileMenu = this._handleToggleMobileMenu.bind(this);
  }

  public componentDidMount(): void {
    const { adminAppRoutes, isAdmin } = this.props;

    if (adminAppRoutes && adminAppRoutes.length > 0) {
      const id = this._getRouteId(adminAppRoutes);
      if (id) {
        this._handleOpeningNav(id, false);
      } else {
        this.setState({
          navsOpen: false
        });
      }
    }

    if (isAdmin === false) {
      const history: any = createHashHistory();
      history.push("/not-allowed");
    }
  }

  public componentDidUpdate(prevProps: INavsProps): void {
    const { adminAppRoutes, adminAppRoutesLoading, featureFlags, isAdmin, isLDReady } = this.props;

    // if feature flags change, we need to re-render the nav to verify the user can access the route
    if (isLDReady && prevProps.isLDReady && featureFlags !== prevProps.featureFlags) {
      this.forceUpdate();
    }

    if ((adminAppRoutesLoading !== prevProps.adminAppRoutesLoading || isLDReady !== prevProps.isLDReady) && !adminAppRoutesLoading && isLDReady) {
      const id = this._getRouteId(adminAppRoutes);
      if (id) {
        this._handleOpeningNav(id, false);
      } else {
        this.setState({
          navsOpen: false
        });
      }
    }

    if (isAdmin !== prevProps.isAdmin && isAdmin === false) {
      const history: any = createHashHistory();
      history.push("/not-allowed");
    }
  }

  public render(): React.ReactElement<INavsProps> {
    const { isAdmin } = this.props;
    const { navsOpen } = this.state;

    if (isAdmin) {
      let primaryNav: JSX.Element = this._renderPrimaryNav();
      let secondaryNav: JSX.Element = this._renderSecondaryNav();

      return (
        <div className={navsOpen ? "navs navsOpen" : "navs"} id="navs">
          <Button className="closeMenu" iconLeft={<Icon iconName="Cancel" />} onClick={e => this._handleToggleMobileMenu()} text="Close" title="Close Menu" />
          <div className="navsInner">
            <nav className="nav" id="nav">
              {primaryNav}
              {secondaryNav}
            </nav>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  private _renderPrimaryNav(): JSX.Element {
    const { adminAppRoutes, adminAppRoutesLoading, featureFlags, isLDReady } = this.props;

    const primaryNavTop: JSX.Element[] = [];
    const primaryNavBottom: JSX.Element[] = [];

    if (adminAppRoutesLoading || !isLDReady) {
      for (let i = 0; i < 3; i++) {
        primaryNavTop.push(<PrimaryNavLoading key={`top-${i}`} />);
      }
      for (let i = 0; i < 2; i++) {
        primaryNavBottom.push(<PrimaryNavLoading key={`bottom-${i}`} />);
      }
    } else {
      adminAppRoutes.map((adminAppRoute, index) => {
        const hasFFOn: boolean = featureFlags && adminAppRoute.featureFlag ? featureFlags[adminAppRoute.featureFlag] : true;
        // if the route has a feature flag and the feature flag is off for the user, don't show it
        if (!hasFFOn) {
          return null;
        } else {
          if (adminAppRoute.adminAppRoutePositionId === 1) {
            return primaryNavTop.push(
              <PrimaryNavLink
                key={`primaryid-${adminAppRoute.id}-index-${index}`}
                icon={adminAppRoute.icon}
                onClick={e => this._handleOpeningNav(adminAppRoute.id, true)}
                text={adminAppRoute.text}
                title={adminAppRoute.title ? adminAppRoute.title : undefined}
                to={adminAppRoute.href}
              />
            );
          }
          if (adminAppRoute.adminAppRoutePositionId === 2) {
            return primaryNavBottom.push(
              <PrimaryNavLink
                key={`primaryid-${adminAppRoute.id}-index-${index}`}
                icon={adminAppRoute.icon}
                onClick={e => this._handleOpeningNav(adminAppRoute.id, true)}
                text={adminAppRoute.text}
                title={adminAppRoute.title ? adminAppRoute.title : undefined}
                to={adminAppRoute.href}
              />
            );
          }
        }
      });
    }

    return (
      <div className="primaryNav">
        <Scrollbars>
          <div className="primaryNavInner">
            <div className="primaryNavTop">{primaryNavTop}</div>
            <div className="primaryNavBottom">{primaryNavBottom}</div>
          </div>
        </Scrollbars>
      </div>
    );
  }

  private _renderSecondaryNav(): JSX.Element {
    const { adminAppRoutes, adminAppRoutesLoading, featureFlags, isLDReady } = this.props;

    const secondaryNavLinks: JSX.Element[] = [];

    if (adminAppRoutesLoading || !isLDReady) {
      for (let i = 0; i < 3; i++) {
        secondaryNavLinks.push(<SecondaryNavLoading key={`secondary-${i}`} />);
      }
    } else {
      adminAppRoutes.map((adminAppRoute, index) => {
        if (adminAppRoute.secondaryNavRoutes) {
          const secondaryNavs: JSX.Element[] = [];

          adminAppRoute.secondaryNavRoutes.map(secondaryNavRoute => {
            const hasFFOn: boolean = featureFlags && secondaryNavRoute.featureFlag ? featureFlags[secondaryNavRoute.featureFlag] : true;
            // if the route has a feature flag and the feature flag is off for the user, don't show it
            if (!hasFFOn) {
              return null;
            } else {
              if (secondaryNavRoute.href === "/repair-requests/usage-reports") {
                secondaryNavs.push(
                  <h6 className="subNavSectionHeading" key={`secondary-section-bottler-app`}>
                    Coke Bottler App
                  </h6>
                );
              }
              if (secondaryNavRoute.href === "/repair-requests/usage-reports/coke-service-app") {
                secondaryNavs.push(
                  <h6 className="subNavSectionHeading" key={`secondary-section-service-app`}>
                    Coke Service App
                  </h6>
                );
              }
              return secondaryNavs.push(
                <SubNavLink
                  exact={true}
                  key={`secondaryid-${secondaryNavRoute.id}-index-${index}`}
                  icon={secondaryNavRoute.icon}
                  onClick={e => this._handleClosingNav()}
                  text={secondaryNavRoute.text}
                  title={secondaryNavRoute.title ? secondaryNavRoute.title : undefined}
                  to={secondaryNavRoute.href}
                />
              );
            }
          });

          secondaryNavLinks.push(
            <div className="secondaryNavLinks" id={adminAppRoute.id.toString()} key={`secondary-links-${adminAppRoute.id}`}>
              {secondaryNavs}
            </div>
          );
        }
      });
    }

    return (
      <div className="secondaryNav">
        <Scrollbars>
          <div className="secondaryNavInner">{secondaryNavLinks}</div>
        </Scrollbars>
      </div>
    );
  }

  private _getRouteId = (adminAppRoutes: IAdminAppRoute[]): number | null => {
    const url: string = window.location.hash.replace("#", "");

    let id: number | null = null;

    adminAppRoutes.map(adminAppRoute => {
      if (adminAppRoute.secondaryNavRoutes) {
        adminAppRoute.secondaryNavRoutes.map(secondaryNavRoute => {
          if (url === secondaryNavRoute.href || url.includes(adminAppRoute.href)) {
            id = adminAppRoute.id;
          }
        });
      }
    });

    return id;
  };

  private _handleOpeningNav(id: number, click: boolean): void {
    const secondaryNavs: NodeListOf<HTMLElement> = document.querySelectorAll(".secondaryNavLinks");

    for (let i: number = 0; i < secondaryNavs.length; i++) {
      const nav: HTMLElement = secondaryNavs[i];
      nav.classList.remove("secondaryNavOpen");
    }

    const secondaryNav: HTMLElement | null = document.getElementById(id.toString());

    if (secondaryNav) {
      secondaryNav.classList.add("secondaryNavOpen");

      this.setState(
        {
          navsOpen: true
        },
        () => {
          const secondaryNavLinks: NodeListOf<HTMLAnchorElement> = secondaryNav.querySelectorAll(".subNavLink");

          if (secondaryNavLinks.length > 0) {
            const firstSecondaryNavLink: HTMLAnchorElement = secondaryNavLinks[0];

            const hash: string = window.location.hash.replace("#", "");
            const href: string = firstSecondaryNavLink.href.split("#")[1];
            const slashes: string[] = hash.split("/"); // like /group-membership

            // only go to the href of the secondary nav link if the current route doesn't match the first secondary nav href
            // and user is loading a primary nav link OR user clicked on a primary nav link and the mobile nav isn't open
            if (href && href !== hash && (slashes.length === 2 || click)) {
              const history: any = createHashHistory();
              history.push(href);
            }
          }
        }
      );
    } else {
      // if we don't have secondary nav, close the mobile menu
      this.setState(
        {
          navsOpen: false
        },
        () => {
          this._closeMobileMenu();
        }
      );
    }
  }

  private _handleClosingNav(): void {
    const mobileMenuOpen = this._isMobileMenuOpen();

    // close the mobile nav if it's open
    if (mobileMenuOpen) {
      this._handleToggleMobileMenu();
    }
  }

  private _handleToggleMobileMenu(): void {
    const mobileMenuOpen = this._isMobileMenuOpen();

    if (mobileMenuOpen) {
      this._closeMobileMenu();
    } else {
      this._openMobileMenu();
    }
  }

  private _closeMobileMenu(): void {
    const body: HTMLElement = document.getElementById("body") as HTMLElement;
    body.classList.add("menuIsClosing");

    setTimeout(() => {
      body.classList.remove("menuOpen", "menuIsClosing");
    }, 400);
  }

  private _openMobileMenu(): void {
    const body: HTMLElement = document.getElementById("body") as HTMLElement;
    body.classList.add("menuOpen");
  }

  private _isMobileMenuOpen(): boolean {
    let menuOpen: boolean = false;

    const body: HTMLElement = document.getElementById("body") as HTMLElement;

    if (body.classList.contains("menuOpen")) {
      menuOpen = true;
    }

    return menuOpen;
  }
}

export const mapStateToProps = (state: any) => {
  return {
    adminAppRoutes: state.adminAppRoutes.adminAppRoutes,
    adminAppRoutesLoading: state.adminAppRoutes.loading,
    featureFlags: state.featureFlags,
    isAdmin: state.adminAppRoutes.isAdmin,
    isLDReady: state.featureFlags.isLDReady
  };
};

export default withRouter(connect(mapStateToProps)(Navs) as any);
