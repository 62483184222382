export interface IColumn {
  sortBy: string;
  text: string;
  title: string;
}

export const columns: IColumn[] = [
  {
    sortBy: "name",
    text: "Name",
    title: "AD Group Name"
  },
  {
    sortBy: "description",
    text: "Description",
    title: "AD Group Description"
  },
  {
    sortBy: "",
    text: "",
    title: "Actions"
  }
];

export type FilterType = "createdAt";

export interface IAuditColumn {
  databaseColumn: string; // used for search
  databaseTable: string; // used for search
  id: string; // used for map key
  sort: boolean;
  orderBy?: string; // used for sorting
  sortMobile: boolean; // add to mobile select dropdown for sorting
  text: string; // mobile text label
  title: string; // desktop table th text
  type: "string" | "number"; // for excel export
}

export const auditColumns: IAuditColumn[] = [
  {
    databaseColumn: "createdAt",
    databaseTable: "auditLog",
    id: "createdAt",
    orderBy: "createdAt",
    sort: true,
    sortMobile: true,
    text: "Date",
    title: "Date Created",
    type: "string"
  },
  {
    databaseColumn: "createdTime",
    databaseTable: "auditLog",
    id: "createdTime",
    orderBy: "createdAt",
    sort: true,
    sortMobile: true,
    text: "Time",
    title: "Time Created",
    type: "string"
  },
  {
    databaseColumn: "adGroupName",
    databaseTable: "auditLog",
    id: "adGroupName",
    sort: true,
    orderBy: "adGroupName",
    sortMobile: true,
    text: "AD Group Name",
    title: "AD Audit Group Name",
    type: "string"
  },
  {
    databaseColumn: "changedByUserName",
    databaseTable: "auditLog",
    id: "changedByUserName",
    sort: true,
    orderBy: "changedByUserName",
    sortMobile: true,
    text: "Admin User Name",
    title: "Admin User Name",
    type: "string"
  },
  {
    databaseColumn: "changedByUserEmail",
    databaseTable: "auditLog",
    id: "changedByUserEmail",
    sort: true,
    orderBy: "changedByUserEmail",
    sortMobile: true,
    text: "Admin User Email Address",
    title: "Admin User Email Address",
    type: "string"
  },
  {
    databaseColumn: "changedUserName",
    databaseTable: "auditLog",
    id: "changedUserName",
    sort: true,
    orderBy: "changedUserName",
    sortMobile: true,
    text: "User Name",
    title: "User Name",
    type: "string"
  },
  {
    databaseColumn: "changedUserEmail",
    databaseTable: "auditLog",
    id: "changedUserEmail",
    sort: true,
    orderBy: "changedUserEmail",
    sortMobile: true,
    text: "User Email Address",
    title: "User Email Address",
    type: "string"
  },
  {
    databaseColumn: "typeName",
    databaseTable: "auditLog",
    id: "typeName",
    sort: true,
    orderBy: "typeName",
    sortMobile: true,
    text: "Action",
    title: "Action",
    type: "string"
  }
];
