import * as React from "react";
import { AppInsights } from "applicationinsights-js";

export interface IState {
  pageUrl: string;
}

export interface IProps {
  location?: any;
}

// This function takes a component...
export function withPageLog(WrappedComponent: any) {
  // ...and returns another component...
  return class extends React.Component<any, IState> {
    componentDidMount() {
      const { location } = this.props;
      this.setState({ pageUrl: location.pathname });
    }

    componentWillUnmount() {
      AppInsights.trackPageView(this.state.pageUrl);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
