import * as React from "react";
import { Route, Switch } from "react-router-dom";
import Caching from "./Caching";
import Routes from "./Routes";
import RequestTypes from "./RequestTypes";
import AppModes from "./AppModes";
import PrivacyPolicy from "./PrivacyPolicy";
import SLAManagement from "./SlaManagement";
import BottlerAccess from "./BottlerAccess";
// types
import { Apps } from "../../types/App";

class SettingsRoutes extends React.Component {
  public render() {
    return (
      <div className="settingsRoutes">
        <Switch>
          <Route exact={true} path="/settings/app-modes" component={AppModes} />
          <Route exact={true} path="/settings/caching" component={Caching} />
          <Route exact={true} path="/settings/request-types" component={RequestTypes} />
          <Route exact={true} path="/settings/routes" component={Routes} />
          <Route path="/settings/sla-management/coke-service-app" render={props => <SLAManagement {...props} appId={Apps.COKE_BOTTLER_APP} />} />
          <Route path="/settings/access/coke-service-app" render={props => <BottlerAccess {...props} />} />
          <Route exact={true} path="/settings/privacy-policy/coke-service-app" component={PrivacyPolicy} />
        </Switch>
      </div>
    );
  }
}

export default SettingsRoutes;
