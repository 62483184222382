import { getRequest } from "../../../core/api/requestConfig";

export type UsageTotal = {
  Total: number;
  programId: string;
  programDescription: string;
  programLabel: string;
};

enum TypeKeys {
  GET_USAGE_TOTALS_ALL_PROGRAMS_REQUEST = "GET_USAGE_TOTALS_ALL_PROGRAMS_REQUEST",
  GET_USAGE_TOTALS_ALL_PROGRAMS_SUCCESS = "GET_USAGE_TOTALS_ALL_PROGRAMS_SUCCESS",
  GET_USAGE_TOTALS_ALL_PROGRAMS_FAILURE = "GET_USAGE_TOTALS_ALL_PROGRAMS_FAILURE",
  GET_USAGE_TOTALS_BY_PROGRAM_REQUEST = "GET_USAGE_TOTALS_BY_PROGRAM_REQUEST",
  GET_USAGE_TOTALS_BY_PROGRAM_SUCCESS = "GET_USAGE_TOTALS_BY_PROGRAM_SUCCESS",
  GET_USAGE_TOTALS_BY_PROGRAM_FAILURE = "GET_USAGE_TOTALS_BY_PROGRAM_FAILURE"
}

type Error = string | null;

type getUsageTotalsForAllProgramsRequest = {
  isLoading: boolean;
  type: TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_REQUEST;
};

type getUsageTotalsForAllProgramsSuccess = {
  usageTotal: UsageTotal[];
  type: TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_SUCCESS;
};

type getUsageTotalsForAllProgramsFailure = {
  error: Error;
  type: TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_FAILURE;
};

type getUsageTotalsByProgramRequest = {
  isLoading: boolean;
  type: TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_REQUEST;
};

type getUsageTotalsByProgramSuccess = {
  usageTotal: UsageTotal[];
  type: TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_SUCCESS;
};

type getUsageTotalsByProgramFailure = {
  error: Error;
  type: TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_FAILURE;
};

/**
 *
 * @param isLoading boolean
 */
export const getUsageTotalsForAllProgramsRequest = (isLoading: boolean): getUsageTotalsForAllProgramsRequest => {
  return {
    isLoading,
    type: TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_REQUEST
  };
};

/**
 *
 * @param usage usage values
 */
export const getUsageTotalsForAllProgramsSuccess = (usageTotal: UsageTotal[]): getUsageTotalsForAllProgramsSuccess => {
  return {
    usageTotal,
    type: TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const getUsageTotalsForAllProgramsFailure = (error: Error): getUsageTotalsForAllProgramsFailure => {
  return {
    error,
    type: TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_FAILURE
  };
};

/**
 *
 * @param isLoading boolean
 */
export const getUsageTotalsByProgramRequest = (isLoading: boolean): getUsageTotalsByProgramRequest => {
  return {
    isLoading,
    type: TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_REQUEST
  };
};

/**
 *
 * @param usage usage values
 */
export const getUsageTotalsByProgramSuccess = (usageTotal: UsageTotal[]): getUsageTotalsByProgramSuccess => {
  return {
    usageTotal,
    type: TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const getUsageTotalsByProgramFailure = (error: Error): getUsageTotalsByProgramFailure => {
  return {
    error,
    type: TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_FAILURE
  };
};

/**
 *
 * @param apiUrl relative url for api endpoint
 */
export const getUsageTotalsForAllProgramsCreator = (apiUrl: string) => {
  return async dispatch => {
    dispatch(getUsageTotalsForAllProgramsRequest(true));

    try {
      const response = await getRequest(apiUrl);
      dispatch(getUsageTotalsForAllProgramsSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(getUsageTotalsForAllProgramsFailure(error));
    }
  };
};

/**
 *
 * @param apiUrl relative url for api endpoint
 */
export const getUsageTotalsByProgramCreator = (apiUrl: string) => {
  return async dispatch => {
    dispatch(getUsageTotalsByProgramRequest(true));

    try {
      const response = await getRequest(apiUrl);
      dispatch(getUsageTotalsByProgramSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(getUsageTotalsByProgramFailure(error));
    }
  };
};

export type Actions =
  | getUsageTotalsForAllProgramsRequest
  | getUsageTotalsForAllProgramsSuccess
  | getUsageTotalsForAllProgramsFailure
  | getUsageTotalsByProgramRequest
  | getUsageTotalsByProgramSuccess
  | getUsageTotalsByProgramFailure;

export interface IState {
  isLoading: boolean;
  usageTotal: UsageTotal[];
  error: Error | null;
}

export const initialState = {
  isLoading: false,
  usageTotal: [],
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_REQUEST:
      newState.isLoading = action.isLoading;
      break;
    case TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_SUCCESS:
      newState.usageTotal = action.usageTotal;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_USAGE_TOTALS_ALL_PROGRAMS_FAILURE:
      newState.error = action.error as string;
      newState.usageTotal = [];
      newState.isLoading = false;
      break;
    case TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_REQUEST:
      newState.isLoading = action.isLoading;
      break;
    case TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_SUCCESS:
      newState.usageTotal = action.usageTotal;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_USAGE_TOTALS_BY_PROGRAM_FAILURE:
      newState.error = action.error as string;
      newState.usageTotal = [];
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};
