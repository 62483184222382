import * as React from "react";
import Swagger from "./Swagger";
import Permissions from "../permissions/Permissions";

export interface ISwaggerV2Props {}

export interface ISwaggerV2State {}

class SwaggerV2 extends React.Component<ISwaggerV2Props, ISwaggerV2State> {
  public render(): React.ReactElement<ISwaggerV2Props> {
    return (
      <Permissions title={`Swagger v2`}>
        <Swagger version="v2" />
      </Permissions>
    );
  }
}

export default SwaggerV2;
