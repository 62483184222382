import * as React from "react";

export interface IOlProps {
  listItems: JSX.Element[];
}

export default class Ol extends React.Component<IOlProps, {}> {
  public render(): React.ReactElement<IOlProps> {
    return <ol className="ol">{this.props.listItems}</ol>;
  }
}
