export type FilterType =
  | "bottler"
  | "tower"
  | "requestGroup"
  | "requestType"
  | "requestPriority"
  | "status"
  | "createdAt"
  | "user"
  | "assignedTo"
  | "dueDate";

export interface IColumn {
  database: string;
  filter: boolean;
  filterPosition?: "filterLeft" | "filterRight";
  filterType?: FilterType;
  filterInput?: "dates" | "multiselect";
  sortBy: string;
  text: string;
  title: string;
}

export const columns: IColumn[] = [
  {
    database: "request.id",
    filter: false,
    sortBy: "[request].[id]",
    text: "ID",
    title: "ID"
  },
  {
    database: "bottler.name",
    filter: true,
    filterInput: "multiselect",
    filterPosition: "filterLeft",
    filterType: "bottler",
    sortBy: "[bottler.name]",
    text: "Bottler",
    title: "Bottler"
  },
  {
    database: "requestType.tower.label",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "tower",
    sortBy: "[requestType.tower.label]",
    text: "Tower",
    title: "Tower"
  },
  {
    database: "request.summary",
    filter: false,
    sortBy: "[request].[summary]",
    text: "Summary",
    title: "Summary"
  },
  {
    database: "requestType.requestGroup.label",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "requestGroup",
    sortBy: "[requestType.requestGroup.label]",
    text: "Request Group",
    title: "Request Group"
  },
  {
    database: "requestType.label",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "requestType",
    sortBy: "[requestType.label]",
    text: "Request Type",
    title: "Request Type"
  },
  {
    database: "requestPriority.name",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "requestPriority",
    sortBy: "[requestPriority].[name]",
    text: "Request Priority",
    title: "Request Priority"
  },
  {
    database: "status.label",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "status",
    sortBy: "[status.label]",
    text: "Status",
    title: "Status"
  },
  {
    database: "user.name",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "user",
    sortBy: "[user.name]",
    text: "Requester",
    title: "Requester"
  },
  {
    database: "request.createdAt",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "dates",
    filterType: "createdAt",
    sortBy: "[request].[createdAt]",
    text: "Requested",
    title: "Requested"
  },
  {
    database: "request.assignedTo",
    filter: true,
    filterPosition: "filterLeft",
    filterInput: "multiselect",
    filterType: "assignedTo",
    sortBy: "[assignedToUser.name]",
    text: "Assigned To",
    title: "Assigned To"
  },
  {
    database: "request.dueDate",
    filter: true,
    filterPosition: "filterRight",
    filterInput: "dates",
    filterType: "dueDate",
    sortBy: "request.dueDate",
    text: "Due Date",
    title: "Due Date"
  },
  {
    database: "",
    filter: false,
    sortBy: "",
    text: "",
    title: "Edit Or View"
  }
];
