import * as React from "react";
import { toast } from "react-toastify";
import { getRequest } from "../core/api/requestConfig";

interface IFetchDataProps {
  children: any;
  url: string;
}

interface IFetchDataState {
  isLoading: boolean;
  data: any;
}

class FetchData extends React.PureComponent<IFetchDataProps, IFetchDataState> {
  state = {
    isLoading: false,
    data: null
  };
  componentDidMount() {
    this.fetchData();
  }
  render() {
    return this.props.children({ ...this.state, ...this.props, fetchData: this.fetchData });
  }
  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const data = await getRequest(this.props.url);

      this.setState({
        isLoading: false,
        data
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({ isLoading: false });
    }
  };
}

export default FetchData;
