import { IError } from "../types/Error";
import { IRequest, IRequestList, IRequestDashboard } from "../types/Request";
import { getRequest as getR, putRequest as putR, postRequest as postR } from "../core/api/requestConfig";
import { ISelfServiceRequestList, ISelfServiceRequest } from "../types/SelfServiceRequest";
import { IFilter } from "../types/Filter";

export const getRequest = (requestId: number) => {
  return new Promise<IRequest>((resolve, reject) => {
    getR("/legacy/api/v1/request/" + requestId)
      .then((request: IRequest) => {
        resolve(request);
      })
      .catch((error: IError) => {
        console.error(error);
        return reject(error);
      });
  });
};

export const putRequest = (request: IRequest) => {
  return new Promise<IRequest>((resolve, reject) => {
    putR("/legacy/api/v1/request", {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request)
    })
      .then((updatedRequest: IRequest) => {
        resolve(updatedRequest);
      })
      .catch((error: IError) => {
        console.error(error);
        return reject(error);
      });
  });
};

export function getRequests(
  assignedTo: number[],
  assignedToShowNull: boolean,
  bottlerId: number | null,
  bottlers: number[],
  createdAtStart: string | null,
  createdAtEnd: string | null,
  dueDateStart: string | null,
  dueDateEnd: string | null,
  filter: boolean,
  isAdmin: boolean,
  limit: number | null,
  offset: number | null,
  page: number,
  requestGroups: number[],
  requestTypes: number[],
  requestPriorities: number[],
  sortBy: string[] | string,
  sortOrder: string[] | string,
  statuses: number[],
  search: string,
  towers: number[],
  updatedAtEnd: string | null,
  updatedAtStart: string | null,
  userId: number,
  users: number[]
): Promise<IRequestList> {
  return new Promise<IRequestList>((resolve, reject) => {
    postR("/legacy/api/v1/request/filter", {
      assignedTo,
      assignedToShowNull,
      bottlerId,
      bottlers,
      createdAtStart,
      createdAtEnd,
      dueDateStart,
      dueDateEnd,
      filter,
      isAdmin,
      limit,
      offset,
      page,
      requestGroups,
      requestTypes,
      requestPriorities,
      sortBy,
      sortOrder,
      statuses,
      search,
      towers,
      updatedAtEnd,
      updatedAtStart,
      userId,
      users
    })
      .then((requests: IRequestList) => {
        return resolve(requests);
      })
      .catch((error: Error) => {
        if (error.message) {
          return reject(error);
        } else {
          const err: Error = Error("Error retrieving requests.");
          return reject(err);
        }
      });
  });
}

export function getSelfServiceRequests(bottlerId: number, filter: IFilter[], page: number, sortBy: string, sortOrder: string, search: string): Promise<ISelfServiceRequestList> {
  return new Promise<ISelfServiceRequestList>((resolve, reject) => {
    postR("/api/v1/getSelfServiceRequestList", {
      bottlerId: bottlerId,
      filter: filter,
      page: page,
      sortBy: sortBy,
      sortOrder: sortOrder,
      search: search,
      offset: 0
    })
      .then((requests: ISelfServiceRequestList) => {
        return resolve(requests);
      })
      .catch((error: IError) => {
        console.error(error);
        return reject(error);
      });
  });
}

export function processServiceRequest(isApproved: boolean, request: ISelfServiceRequest): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    putR("/api/v1/selfServiceRequest", {
      id: request.id,
      name: request.name,
      email: request.email,
      requestGroup: request.requestGroup,
      isApproved: isApproved
    })
      .then((result: boolean) => {
        return resolve(result);
      })
      .catch((error: IError) => {
        console.error(error);
        return reject(error);
      });
  });
}

export const getDashboard = (days: number, requestTypes: number[]) => {
  return new Promise<IRequestDashboard>((resolve, reject) => {
    postR("/legacy/api/v1/request/dashboard/admin", {
      days: days,
      requestTypes: requestTypes
    })
      .then((stats: IRequestDashboard) => {
        if (stats) {
          return resolve(stats);
        } else {
          throw Error("Error retrieving dashboard stats.");
        }
      })
      .catch((error: Error) => {
        if (error && error.message) {
          return reject(error);
        } else {
          const err: Error = {
            message: "Error retrieving dashboard stats.",
            name: ""
          };
          return reject(err);
        }
      });
  });
};
