import * as React from "react";

export default class H1 extends React.Component {
  public render() {
    return (
      <h1 className="h1">
        {this.props.children}
      </h1>
    );
  }
}
