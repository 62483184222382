import * as React from "react";
import RefreshButton from "../button/Refresh";
import RefreshToggle from "../toggle/RefreshToggle";

interface IGroupProps {
  refreshId: string;
  handleRefresh: Function;
  isLoading: boolean;
}

const Group = ({ refreshId, handleRefresh, isLoading }: IGroupProps) => {
  return (
    <div className="refreshGroup">
      <RefreshButton onClick={handleRefresh} isLoading={isLoading} />
      <RefreshToggle
        id={refreshId}
        onRefresh={handleRefresh}
        shouldStartInterval={!isLoading}
      />
    </div>
  );
};

export default Group;
