import * as React from "react";
import { connect } from "react-redux";
import { GetUserCreator, IState, mapStateToProps } from "../user";
import LoaderFullscreen from "../../../components/loader/LoaderFullscreen";

interface WrappedProps extends IState {}

export class UserDataProvider extends React.Component<WrappedProps, IState> {
  public props: any;

  public componentDidMount() {
    this.props.dispatch(GetUserCreator());
  }
  public render() {
    if (this.props.isLoading) {
      return <LoaderFullscreen />;
    }
    const component = this.props.user ? React.cloneElement(this.props.children, { user: this.props.user }) : <div>{`error loading authorization: ${this.props.error}`}</div>;

    return component;
  }
}

export const UserProvider = connect(mapStateToProps)(UserDataProvider);
