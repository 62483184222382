import * as React from "react";
import { toast } from "react-toastify";
// components
import { Hr } from "../typography";
import Stat from "../stat/Stat";
import Modal from "../modal/Modal";
import RequestAuditList from "./RequestAuditList";
// services
import { getRequest } from "../../services/Request";
// types
import { IRequest } from "../../types/Request";
// helpers
import { shortFriendlyDateTime } from "../../helpers/time";

interface IRequestHistoryModalProps {
  showDetails?: boolean;
  requestId: number;
  bottlerName: string;
}

interface IRequestHistoryModalState {
  isLoading: boolean;
  request: IRequest | object;
}

class RequestHistoryModal extends React.PureComponent<IRequestHistoryModalProps, IRequestHistoryModalState> {
  state = {
    isLoading: true,
    request: {
      title: "",
      user: {
        name: ""
      },
      createdAt: ""
    }
  };
  render() {
    const { requestId, bottlerName, showDetails = true } = this.props;
    const { isLoading, request } = this.state;

    return (
      <Modal
        body={
          !isLoading
            ? [
                <>
                  {showDetails && (
                    <>
                      <div className="requestDetails">
                        <Stat title="Bottler" value={bottlerName} />
                        <Stat title="Request Type" value={request.title} />
                        <Stat title="Requester" value={request.user.name} />
                        <Stat title="Requested" value={shortFriendlyDateTime(request.createdAt)} />
                      </div>
                      <Hr />
                    </>
                  )}
                  <RequestAuditList bottlerName={bottlerName} requestId={requestId} userName={request.user.name} createdAt={request.createdAt} />
                </>
              ]
            : []
        }
        bodyLoading={isLoading}
        bodyLoadingPosition="Top"
        bodyLoadingShowImage={true}
        cancelIcon="Cancel"
        cancelText="Cancel"
        cancelTitle="Cancel"
        heading={`History For Request #${requestId}`}
        id="requestHistoryModal"
        modalSize="Xxxl"
        openClassName="buttonLinkPrimaryColor"
        openIcon=""
        openText="View History"
        openTitle="View History"
        onOpen={this.fetchData}
        showCancel={false}
        showFooter={false}
        showSave={false}
      />
    );
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    const { requestId } = this.props;
    try {
      const request = await getRequest(requestId);
      this.setState({ request, isLoading: false });
    } catch (error) {
      toast.error("Error retrieving request information", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({ isLoading: false });
    }
  };
}

export default RequestHistoryModal;
