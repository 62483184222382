import { getRequest } from "../core/api/requestConfig";

export const getWageTypes = () => {
  return new Promise<any>((resolve, reject) => {
    getRequest("/legacy/api/v1/wagetypes")
      .then(wageTypes => {
        if (wageTypes && wageTypes.length) {
          return resolve(wageTypes);
        } else {
          return resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        return reject(null);
      });
  });
};
