import { DatePicker, DayOfWeek, IDatePickerStrings } from "office-ui-fabric-react/lib/DatePicker";
import * as React from "react";

export interface IDatepickerProps {
  allowTextInput?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: any;
  placeholder: string;
  required?: boolean;
  restrictedDates?: Date[];
  value: Date | null;
  minDate?: Date;
  maxDate?: Date;
}
export interface IDatepickerState {
  firstDayOfWeek?: DayOfWeek;
  value?: Date | null;
}

const DayPickerStrings: IDatePickerStrings = {
  months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  shortDays: ["S", "M", "T", "W", "T", "F", "S"],
  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  isRequiredErrorMessage: "Date is required.",
  invalidInputErrorMessage: "Invalid date format."
};

export default class Datepicker extends React.Component<IDatepickerProps, IDatepickerState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      firstDayOfWeek: DayOfWeek.Sunday,
      value: null
    };
    this._onSelectDate = this._onSelectDate.bind(this);
    this._onFormatDate = this._onFormatDate.bind(this);
    this._onParseDateFromString = this._onParseDateFromString.bind(this);
  }
  public componentWillMount(): void {
    this.setState({
      value: this.props.value
    });
  }
  public componentDidUpdate(prevProps: IDatepickerProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
  }
  public render(): React.ReactElement<IDatepickerProps> {
    const { allowTextInput = true, minDate, maxDate, placeholder, required, disabled, label, restrictedDates } = this.props;

    const { firstDayOfWeek, value } = this.state;
    const className: string = this.props.required ? "datePickerRequired" : "datePicker";
    return (
      <div className={className}>
        <DatePicker
          allowTextInput={allowTextInput}
          calendarProps={{
            strings: DayPickerStrings,
            restrictedDates
          }}
          disabled={disabled}
          firstDayOfWeek={firstDayOfWeek}
          firstWeekOfYear={1}
          formatDate={this._onFormatDate}
          isRequired={required}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          onSelectDate={this._onSelectDate}
          parseDateFromString={this._onParseDateFromString}
          placeholder={placeholder}
          showMonthPickerAsOverlay={true}
          showWeekNumbers={true}
          strings={DayPickerStrings}
          value={value!}
        />
      </div>
    );
  }
  private _onSelectDate(date: Date | undefined | null): void {
    this.setState(
      {
        value: date
      },
      () => {
        this.props.onChange(date);
      }
    );
  }
  private _onFormatDate(date: Date | undefined): string {
    if (date) {
      return date.getMonth() + 1 + "/" + date.getDate() + "/" + (date.getFullYear() % 100);
    }
    return "";
  }
  private _onParseDateFromString(value: string): Date {
    const date: Date = this.state.value || new Date();
    const values: any = (value || "").trim().split("/");
    const month: number = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[0], 10))) - 1 : date.getMonth();
    const day: number = values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[1], 10))) : date.getDate();
    let year: number = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
    if (year < 100) {
      year += date.getFullYear() - (date.getFullYear() % 100);
    }
    return new Date(year, month, day);
  }
}
