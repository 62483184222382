import { IError } from "../types/Error";
import { INote } from "../types/Note";
import { getRequest, postRequest } from "../core/api/requestConfig";

export function getNotes(requestId: number): Promise<INote[]> {
  return new Promise<INote[]>((resolve, reject) => {
    getRequest("/legacy/api/v1/note/request/" + requestId)
      .then((notes: INote[]) => {
        return resolve(notes);
      })
      .catch((error: IError) => {
        console.log(error);
        return reject(error);
      });
  });
}

export function postNote(note: INote): Promise<INote> {
  return new Promise<INote>((resolve, reject) => {
    postRequest("/legacy/api/v1/note", note)
      .then((createdNote: INote) => {
        return resolve(createdNote);
      })
      .catch((error: IError) => {
        console.log(error);
        return reject(error);
      });
  });
}
