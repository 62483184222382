import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
// components
import Button from "../button/Button";
import Editing from "./Editing";
import Merging from "./Merging";
import Checking from "./Checking";
// types
import { ICurrencyType } from "../../types/CurrencyType";
import { IPayArea } from "../../types/PayArea";
import { ProcessingError } from "../../types/ProcessingError";
import { Row, Rows } from "../../types/Task";
import { IWageType } from "../../types/WageType";
import P from "../typography/P";
import Hr from "../typography/Hr";

export interface ITasksProps {
  _addTask: any;
  _cancelDuplicates: any;
  _cancelMerge: any;
  _checkChange: any;
  _dateChange: any;
  _openDuplicates: any;
  _handleCheckAll: any;
  _handleNoteAdd: any;
  _inputChange: any;
  _inputBlur: any;
  _openMerge: any;
  _removeTask: any;
  _saveDuplicates: any;
  _saveMerge: any;
  _setDuplicate: any;
  _stepThroughDuplicates: any;
  checked: number;
  checking: boolean;
  columns: any[];
  currencyTypes: ICurrencyType[];
  duplicates: Rows[];
  editing: boolean;
  invalid: ProcessingError[];
  loading: boolean;
  merged: number;
  mergee: Row[];
  merges: Row[];
  merging: boolean;
  payAreas: IPayArea[];
  payrollPeriod: any;
  requestType: any;
  set: number;
  statusId: number | null;
  step: number;
  stepping: boolean;
  suggestions: Row[];
  tasks: any[];
  unmatched: ProcessingError[];
  wageTypes: IWageType[];
}

export interface ITasksState {}

export default class Tasks extends React.Component<ITasksProps, ITasksState> {
  public render(): React.ReactElement<ITasksProps> {
    const { currencyTypes, payAreas, statusId, wageTypes } = this.props;

    const currencyTypeOptions: JSX.Element[] = [];
    if (currencyTypes && currencyTypes.length > 0) {
      currencyTypes.map(currencyType => {
        currencyTypeOptions.push(
          <option value={currencyType.id} key={currencyType.id}>
            {currencyType.label}
          </option>
        );
      });
    }

    const payAreaOptions: JSX.Element[] = [];
    if (payAreas && payAreas.length > 0) {
      payAreas.map(payArea => {
        payAreaOptions.push(
          <option value={payArea.id} key={payArea.id}>
            {payArea.label}
          </option>
        );
      });
    }

    const wageTypeOptions: JSX.Element[] = [];
    if (wageTypes && wageTypes.length > 0) {
      wageTypes.map(wageType => {
        wageTypeOptions.push(
          <option value={wageType.id} key={wageType.id}>
            {wageType.code}
          </option>
        );
      });
    }

    if (
      currencyTypes &&
      currencyTypes.length > 0 &&
      payAreas &&
      payAreas.length > 0
    ) {
      return (
        <div className="tasks">
          <Hr />
          <div className="taskbar">
            <Button
              className="buttonWhite"
              disabled={statusId !== 3 && statusId !== 4 ? false : true}
              iconLeft={<Icon iconName="CircleAddition" />}
              onMouseDown={e => this.props._addTask(e)}
              text="Add Task"
              title="Add A Task"
            />
            <Checking
              _cancelDuplicates={this.props._cancelDuplicates}
              _dateChange={this.props._dateChange}
              _inputChange={this.props._inputChange}
              _inputBlur={this.props._inputBlur}
              _openDuplicates={this.props._openDuplicates}
              _saveDuplicates={this.props._saveDuplicates}
              _setDuplicate={this.props._setDuplicate}
              _stepThroughDuplicates={this.props._stepThroughDuplicates}
              checking={this.props.checking}
              currencyTypes={this.props.currencyTypes}
              currencyTypeOptions={currencyTypeOptions}
              duplicates={this.props.duplicates}
              disabled={
                statusId !== 3 &&
                statusId !== 4 &&
                this.props.tasks.length > 1 &&
                this.props.tasks.length - this.props.merged > 1 &&
                this.props.invalid.length === 0 &&
                this.props.unmatched.length === 0
                  ? false
                  : true
              }
              payAreas={this.props.payAreas}
              payAreaOptions={payAreaOptions}
              requestType={this.props.requestType}
              set={this.props.set}
              step={this.props.step}
              stepping={this.props.stepping}
              suggestions={this.props.suggestions}
              wageTypes={this.props.wageTypes}
              wageTypeOptions={wageTypeOptions}
            />
            <Merging
              _cancelMerge={this.props._cancelMerge}
              _dateChange={this.props._dateChange}
              _inputChange={this.props._inputChange}
              _inputBlur={this.props._inputBlur}
              _openMerge={this.props._openMerge}
              _saveMerge={this.props._saveMerge}
              currencyTypes={this.props.currencyTypes}
              currencyTypeOptions={currencyTypeOptions}
              disabled={
                statusId !== 3 &&
                statusId !== 4 &&
                this.props.checked > 1 &&
                this.props.tasks.length > 1 &&
                this.props.invalid.length === 0 &&
                this.props.unmatched.length === 0
                  ? false
                  : true
              }
              mergee={this.props.mergee}
              merges={this.props.merges}
              merging={this.props.merging}
              payAreas={this.props.payAreas}
              payAreaOptions={payAreaOptions}
              requestType={this.props.requestType}
              wageTypes={this.props.wageTypes}
              wageTypeOptions={wageTypeOptions}
            />
          </div>
          <Editing
            _checkChange={this.props._checkChange}
            _dateChange={this.props._dateChange}
            _handleCheckAll={this.props._handleCheckAll}
            _inputChange={this.props._inputChange}
            _inputBlur={this.props._inputBlur}
            _removeTask={this.props._removeTask}
            checked={this.props.checked}
            currencyTypes={this.props.currencyTypes}
            currencyTypeOptions={currencyTypeOptions}
            invalid={this.props.invalid}
            merged={this.props.merged}
            payAreas={this.props.payAreas}
            payAreaOptions={payAreaOptions}
            requestType={this.props.requestType}
            statusId={statusId}
            tasks={this.props.tasks}
            wageTypes={this.props.wageTypes}
            wageTypeOptions={wageTypeOptions}
            unmatched={this.props.unmatched}
          />
        </div>
      );
    } else {
      return (
        <div className="tasksLoading">
          <Hr />
          <P>Loading tasks...</P>
        </div>
      );
    }
  }
}
