import * as React from "react";
import moment from "moment";
// components
import { AutoSizer, Column, Table } from "react-virtualized";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Datepicker from "../input/Datepicker";
import Alert from "../alert/Alert";
import Input from "../input/Input";
import Select from "../input/Select";
import Label from "../label/Label";
import Modal from "../modal/Modal";
import Button from "../button/Button";
// enums
import { breakpoints } from "../../enums/breakpoints";
// types
import { ICurrencyType } from "../../types/CurrencyType";
import { IPayArea } from "../../types/PayArea";
import { IRequestType } from "../../types/RequestType";
import { Row, Rows } from "../../types/Task";
import { IWageType } from "../../types/WageType";

export interface ICheckingProps {
  _cancelDuplicates: any;
  _dateChange: any;
  _inputBlur: any;
  _inputChange: any;
  _openDuplicates: any;
  _saveDuplicates: any;
  _setDuplicate: any;
  _stepThroughDuplicates: any;
  checking: boolean;
  currencyTypes: ICurrencyType[];
  currencyTypeOptions: JSX.Element[];
  disabled: boolean;
  duplicates: Rows[];
  payAreas: IPayArea[];
  payAreaOptions: JSX.Element[];
  requestType: IRequestType;
  set: number;
  step: number;
  stepping: boolean;
  suggestions: Row[];
  wageTypes: IWageType[];
  wageTypeOptions: JSX.Element[];
}
export interface ICheckingState {
  height: number;
  duplicatesHeight: number;
  suggestionHeight: number;
}

export default class Checking extends React.Component<ICheckingProps, ICheckingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      height: 45,
      duplicatesHeight: 200,
      suggestionHeight: 80
    };
    this._datepickerDuplicates = this._datepickerDuplicates.bind(this);
    this._inputNumberDuplicates = this._inputNumberDuplicates.bind(this);
    this._inputTextDuplicates = this._inputTextDuplicates.bind(this);
    this._selectDuplicates = this._selectDuplicates.bind(this);
    this._datepickerRenderer = this._datepickerRenderer.bind(this);
    this._inputNumberRenderer = this._inputNumberRenderer.bind(this);
    this._inputTextRenderer = this._inputTextRenderer.bind(this);
    this._selectRenderer = this._selectRenderer.bind(this);
    this._setHeight = this._setHeight.bind(this);
  }
  public componentDidMount(): void {
    window.addEventListener("resize", this._setHeight);
  }

  public componentWillMount(): void {
    this._setHeight();
  }
  public componentWillUnmount(): void {
    window.removeEventListener("resize", this._setHeight);
  }
  public render() {
    let duplicatesBody: JSX.Element[] = [];
    if (this.props.checking && this.props.duplicates.length && this.props.suggestions.length) {
      duplicatesBody = this.props.duplicates.map((duplicate, step) => {
        const suggestion: Row = this.props.suggestions[step];
        suggestion!.step = step;
        const mergee: Row[] = [];
        mergee.push(suggestion);
        const duplicatesGrid: JSX.Element = (
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <Table
                headerHeight={35}
                height={this.state.duplicatesHeight}
                overscanRowCount={2}
                rowHeight={this.state.height}
                rowCount={duplicate.rows.length}
                rowGetter={({ index }) => duplicate.rows[index]}
                width={width}
              >
                <Column width={120} label="First" dataKey="firstName" cellRenderer={this._inputTextDuplicates} />
                <Column width={60} label="MI" dataKey="middleInitial" cellRenderer={this._inputTextDuplicates} />
                <Column width={120} label="Last" dataKey="lastName" cellRenderer={this._inputTextDuplicates} />
                <Column width={60} label="Suffix" dataKey="suffix" cellRenderer={this._inputTextDuplicates} />
                <Column width={105} label="Nickname" dataKey="nickname" cellRenderer={this._inputTextDuplicates} />
                <Column width={120} label="PERNR" dataKey="pernr" cellRenderer={this._inputTextDuplicates} />
                <Column width={120} label="Cost Center" dataKey="costCenter" cellRenderer={this._inputTextDuplicates} />
                <Column width={120} label="Pay Area" dataKey="payAreaId" cellRenderer={this._selectDuplicates} />
                <Column width={100} label="Wage Type" dataKey="wageTypeId" cellRenderer={this._selectDuplicates} />
                {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                  <Column width={90} label="Hours" dataKey="hours" cellRenderer={this._inputNumberDuplicates} />
                ) : null}
                {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                  <Column width={90} label="Number" dataKey="number" cellRenderer={this._inputNumberDuplicates} />
                ) : null}
                <Column width={90} label="Units" dataKey="units" cellRenderer={this._inputNumberDuplicates} />
                <Column width={135} label="Date" dataKey="dateOfPay" cellRenderer={this._datepickerDuplicates} flexGrow={0} />
                <Column width={120} label="Amount" dataKey="amount" cellRenderer={this._inputNumberDuplicates} />
                <Column width={100} label="Currency" dataKey="currencyTypeId" cellRenderer={this._selectDuplicates} />
                {this.props.requestType && this.props.requestType.code === "IT0014" ? (
                  <Column width={120} label="Dollar Limit" dataKey="dollarLimit" cellRenderer={this._inputNumberDuplicates} />
                ) : null}
                {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                  <Column width={90} label="Rate" dataKey="rate" cellRenderer={this._inputNumberDuplicates} />
                ) : null}
                <Column width={120} label="Authority" dataKey="authority" cellRenderer={this._inputTextDuplicates} />
                <Column width={120} label="Comment" dataKey="comment" cellRenderer={this._inputTextDuplicates} flexGrow={1} />
              </Table>
            )}
          </AutoSizer>
        );
        const duplicatesSuggestion: JSX.Element = (
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <Table
                headerHeight={35}
                height={this.state.suggestionHeight}
                overscanRowCount={0}
                rowHeight={this.state.height}
                rowCount={1}
                rowGetter={({ index }) => mergee[index]}
                width={width}
              >
                <Column width={120} label="First" dataKey="firstName" cellRenderer={this._inputTextRenderer} />
                <Column width={60} label="MI" dataKey="middleInitial" cellRenderer={this._inputTextRenderer} />
                <Column width={120} label="Last" dataKey="lastName" cellRenderer={this._inputTextRenderer} />
                <Column width={60} label="Suffix" dataKey="suffix" cellRenderer={this._inputTextRenderer} />
                <Column width={105} label="Nickname" dataKey="nickname" cellRenderer={this._inputTextRenderer} />
                <Column width={120} label="PERNR" dataKey="pernr" cellRenderer={this._inputTextRenderer} />
                <Column width={120} label="Cost Center" dataKey="costCenter" cellRenderer={this._inputTextRenderer} />
                <Column width={120} label="Pay Area" dataKey="payAreaId" cellRenderer={this._selectRenderer} />
                <Column width={100} label="Wage Type" dataKey="wageTypeId" cellRenderer={this._selectRenderer} />
                {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                  <Column width={90} label="Hours" dataKey="hours" cellRenderer={this._inputNumberRenderer} />
                ) : null}
                {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                  <Column width={90} label="Number" dataKey="number" cellRenderer={this._inputNumberRenderer} />
                ) : null}
                <Column width={90} label="Units" dataKey="units" cellRenderer={this._inputNumberRenderer} />
                <Column width={135} label="Date" dataKey="dateOfPay" cellRenderer={this._datepickerRenderer} flexGrow={0} />
                <Column width={120} label="Amount" dataKey="amount" cellRenderer={this._inputNumberRenderer} />
                <Column width={100} label="Currency" dataKey="currencyTypeId" cellRenderer={this._selectRenderer} />
                {this.props.requestType && this.props.requestType.code === "IT0014" ? (
                  <Column width={120} label="Dollar Limit" dataKey="dollarLimit" cellRenderer={this._inputNumberRenderer} />
                ) : null}
                {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                  <Column width={90} label="Rate" dataKey="rate" cellRenderer={this._inputNumberRenderer} />
                ) : null}
                <Column width={120} label="Authority" dataKey="authority" cellRenderer={this._inputTextRenderer} />
                <Column width={120} label="Comment" dataKey="comment" cellRenderer={this._inputTextRenderer} flexGrow={1} />
              </Table>
            )}
          </AutoSizer>
        );
        return (
          <div className={"wizard"} key={step} aria-hidden={this.props.step === step ? false : true}>
            <p>Possible duplicates detected:</p>
            {duplicatesGrid}
            <p>Merged task:</p>
            {duplicatesSuggestion}
            <div className={"duplicatesControls"}>
              <div>
                <Button
                  className={duplicate.shouldCombine === true ? "buttonInfo" : "buttonWhite"}
                  iconLeft={duplicate.shouldCombine === true ? <Icon iconName="CompletedSolid" /> : <Icon iconName="CheckMark" />}
                  text="Combine"
                  title="Combine"
                  disabled={false}
                  onClick={e => this.props._setDuplicate(step, true)}
                />
                <Button
                  className={duplicate.shouldCombine === false ? "buttonInfo" : "buttonWhite"}
                  iconLeft={duplicate.shouldCombine === false ? <Icon iconName="StatusErrorFull" /> : <Icon iconName="Cancel" />}
                  text="Ignore"
                  title="Ignore"
                  disabled={false}
                  onClick={e => this.props._setDuplicate(step, false)}
                />
              </div>
              {this.props.duplicates.length > 1 ? (
                <div className={"duplicatesStep"}>
                  <Button
                    className="buttonWhite"
                    text="Previous"
                    title="Previous"
                    iconLeft={<Icon iconName="ChevronLeft" />}
                    disabled={step === 0 ? true : false}
                    onClick={e => this.props._stepThroughDuplicates(step - 1)}
                  />
                  <Button
                    className="buttonWhite"
                    iconLeft={<Icon iconName="ChevronRight" />}
                    text="Next"
                    title="Next"
                    disabled={step === this.props.duplicates.length - 1 ? true : false}
                    onClick={e => this.props._stepThroughDuplicates(step + 1)}
                  />
                </div>
              ) : (
                undefined
              )}
            </div>
          </div>
        );
      });
    }
    const footer: JSX.Element = <Alert show={true} icon="Info" text="Merged entries will not be deleted." type="Info" />;
    return (
      <Modal
        id="check-for-duplicates"
        modalSize={this.props.duplicates.length === 0 ? "Xs" : "Xxl"}
        disabled={this.props.disabled}
        onOpen={this.props._openDuplicates}
        onSave={this.props._saveDuplicates}
        onCancel={this.props._cancelDuplicates}
        heading={this.props.duplicates.length > 1 ? "Check Duplicates: Duplicate #" + (this.props.step + 1) : "Check Duplicates"}
        body={
          this.props.checking && duplicatesBody.length
            ? duplicatesBody
            : [<Alert key="no-duplicates" show={true} icon="CheckMark" text="There are no duplicate tasks in your request." type="Success" />]
        }
        footer={this.props.checking && duplicatesBody.length ? footer : null}
        bodyLoading={this.props.stepping}
        openIcon={"BranchCompare"}
        openText={"Check For Duplicates"}
        openTitle={"Check For Duplicates"}
        cancelIcon={"Cancel"}
        cancelText={"Cancel"}
        cancelTitle={"Cancel Check For Duplicates"}
        saveDisabled={this.props.duplicates.length === 0 || this.props.set !== this.props.duplicates.length ? true : false}
        saveIcon={"BranchCompare"}
        saveText={"Done Checking"}
        saveTitle={"Done Checking"}
        showCancel={true}
        showSave={true}
      />
    );
  }

  private _inputNumberDuplicates({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-duplicates-" + rowIndex + "-" + columnIndex;
    const text: string =
      dataKey === "amount"
        ? "Amount"
        : dataKey === "dollarLimit"
        ? "Dollar Limit"
        : dataKey === "number"
        ? "Number"
        : dataKey === "rate"
        ? "Rate"
        : dataKey === "hours"
        ? "Hours"
        : dataKey === "units"
        ? "Units"
        : "";
    const value: string =
      dataKey === "amount"
        ? rowData.data.amount
        : dataKey === "dollarLimit"
        ? rowData.data.dollarLimit
        : dataKey === "number"
        ? rowData.data.number
        : dataKey === "rate"
        ? rowData.data.rate
        : dataKey === "hours"
        ? rowData.data.hours
        : dataKey === "units"
        ? rowData.data.units
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="number"
          min="0"
          name={dataKey}
          onBlur={null}
          onChange={null}
          pattern="[0-9]*"
          readOnly={true}
          step="0.01"
          tabIndex={-1}
          value={value}
        />
      </div>
    );
  }
  private _inputTextDuplicates({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-duplicates-" + rowIndex + "-" + columnIndex;
    const text: string =
      dataKey === "firstName"
        ? "First Name"
        : dataKey === "middleInitial"
        ? "Middle Initial"
        : dataKey === "lastName"
        ? "Last Name"
        : dataKey === "suffix"
        ? "Suffix"
        : dataKey === "nickname"
        ? "Nickname"
        : dataKey === "pernr"
        ? "PERNR"
        : dataKey === "costCenter"
        ? "Cost Center"
        : dataKey === "authority"
        ? "Authority"
        : dataKey === "comment"
        ? "Comment"
        : "";
    const value: string =
      dataKey === "firstName"
        ? rowData.data.firstName
        : dataKey === "middleInitial"
        ? rowData.data.middleInitial
        : dataKey === "lastName"
        ? rowData.data.lastName
        : dataKey === "suffix"
        ? rowData.data.suffix
        : dataKey === "nickname"
        ? rowData.data.nickname
        : dataKey === "pernr"
        ? rowData.data.pernr
        : dataKey === "costCenter"
        ? rowData.data.costCenter
        : dataKey === "authority"
        ? rowData.data.authority
        : dataKey === "comment"
        ? rowData.data.comment
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="text"
          name={dataKey}
          onBlur={null}
          onChange={null}
          readOnly={true}
          tabIndex={-1}
          value={value}
        />
      </div>
    );
  }
  private _selectDuplicates({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = true;
    const id: string = dataKey + "-duplicates-" + rowIndex + "-" + columnIndex;
    const value: string =
      dataKey === "wageTypeId" && rowData.data.wageTypeId
        ? rowData.data.wageTypeId
        : dataKey === "currencyTypeId" && rowData.data.currencyTypeId
        ? rowData.data.currencyTypeId
        : dataKey === "payAreaId" && rowData.data.payAreaId
        ? rowData.data.payAreaId
        : "";
    if (dataKey === "wageTypeId") {
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Wage Type" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={null}
            onChange={null}
            options={this.props.wageTypeOptions}
            placeholder={"Select..."}
            value={value}
          />
        </div>
      );
    } else if (dataKey === "currencyTypeId") {
      return (
        <div>
          <Label htmlFor={id} srOnlyAt={breakpoints.md} text="Currency" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={null}
            onChange={null}
            options={this.props.currencyTypeOptions}
            placeholder={"Select..."}
            value={value ? value : "1"} // default to USD
          />
        </div>
      );
    } else {
      // dataKey === "payAreaId"
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Pay Area" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={null}
            onChange={null}
            options={this.props.payAreaOptions}
            placeholder={"Select..."}
            value={value}
          />
        </div>
      );
    }
  }
  private _datepickerDuplicates({ rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = true;
    const value: Date | null = rowData.data.dateOfPay ? moment(rowData.data.dateOfPay, "YYYY-MM-DD").toDate() : null;
    return (
      <div>
        <Datepicker disabled={disabled} label="Date" onChange={null} placeholder={""} required={false} value={value} />
      </div>
    );
  }
  private _inputNumberRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-suggestion-" + rowData.step + "-" + columnIndex;
    const readOnly: boolean = false;
    const tabIndex: number = 0;
    const text: string =
      dataKey === "amount"
        ? "Amount"
        : dataKey === "dollarLimit"
        ? "Dollar Limit"
        : dataKey === "number"
        ? "Number"
        : dataKey === "rate"
        ? "Rate"
        : dataKey === "hours"
        ? "Hours"
        : dataKey === "units"
        ? "Units"
        : "";
    const value: string =
      dataKey === "amount"
        ? rowData.data.amount
        : dataKey === "dollarLimit"
        ? rowData.data.dollarLimit
        : dataKey === "number"
        ? rowData.data.number
        : dataKey === "rate"
        ? rowData.data.rate
        : dataKey === "hours"
        ? rowData.data.hours
        : dataKey === "units"
        ? rowData.data.units
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="number"
          min="0"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowData.step, "suggestion")}
          onChange={e => this.props._inputChange(e, rowData.step, "suggestion")}
          pattern="[0-9]*"
          readOnly={readOnly}
          step="0.01"
          tabIndex={tabIndex}
          value={value}
        />
      </div>
    );
  }
  private _inputTextRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const autoFocus: boolean = rowData.step === 0 && dataKey === "firstName";
    const id: string = dataKey + "-suggestion-" + rowData.step + "-" + columnIndex;
    const text: string =
      dataKey === "firstName"
        ? "First Name"
        : dataKey === "middleInitial"
        ? "Middle Initial"
        : dataKey === "lastName"
        ? "Last Name"
        : dataKey === "suffix"
        ? "Suffix"
        : dataKey === "nickname"
        ? "Nickname"
        : dataKey === "pernr"
        ? "PERNR"
        : dataKey === "costCenter"
        ? "Cost Center"
        : dataKey === "authority"
        ? "Authority"
        : dataKey === "comment"
        ? "Comment"
        : "";
    const readOnly: boolean = false;
    const required: boolean =
      dataKey === "firstName" || dataKey === "lastName" || dataKey === "pernr" || dataKey === "costCenter" || dataKey === "authority" ? true : false;
    const showValidation: boolean =
      (dataKey === "firstName" && !rowData.firstNameValid && !rowData.data.firstName) ||
      (dataKey === "lastName" && !rowData.lastNameValid && !rowData.data.lastName) ||
      (dataKey === "pernr" && !rowData.pernrValid && !rowData.data.pernr) ||
      (dataKey === "costCenter" && !rowData.costCenterValid && !rowData.data.costCenter) ||
      (dataKey === "authority" && !rowData.costCenterValid && !rowData.data.authority) ||
      (dataKey === "pernr" && rowData.isDuplicate)
        ? true
        : false;
    const tabIndex: number = 0;
    const value: string =
      dataKey === "firstName"
        ? rowData.data.firstName
        : dataKey === "middleInitial"
        ? rowData.data.middleInitial
        : dataKey === "lastName"
        ? rowData.data.lastName
        : dataKey === "suffix"
        ? rowData.data.suffix
        : dataKey === "nickname"
        ? rowData.data.nickname
        : dataKey === "pernr"
        ? rowData.data.pernr
        : dataKey === "costCenter"
        ? rowData.data.costCenter
        : dataKey === "authority"
        ? rowData.data.authority
        : dataKey === "comment"
        ? rowData.data.comment
        : "";
    return (
      <div>
        <Label htmlFor={id} required={required} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          autoFocus={autoFocus}
          id={id}
          inputType="text"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowData.step, "suggestion")}
          onChange={e => this.props._inputChange(e, rowData.step, "suggestion")}
          readOnly={readOnly}
          required={required}
          showRequired={required}
          showValidation={showValidation}
          tabIndex={tabIndex}
          value={value}
        />
      </div>
    );
  }
  private _selectRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = false;
    const id: string = dataKey + "-suggestion-" + rowData.step + "-" + columnIndex;
    const value: string =
      dataKey === "wageTypeId" && rowData.data.wageTypeId
        ? rowData.data.wageTypeId
        : dataKey === "currencyTypeId" && rowData.data.currencyTypeId
        ? rowData.data.currencyTypeId
        : dataKey === "payAreaId" && rowData.data.payAreaId
        ? rowData.data.payAreaId
        : "";
    if (dataKey === "wageTypeId") {
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Wage Type" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowData.step, "suggestion")}
            onChange={e => this.props._inputChange(e, rowData.step, "suggestion")}
            options={this.props.wageTypeOptions}
            placeholder={"Select..."}
            required={true}
            showPlaceholder={true}
            showRequired={true}
            showValidation={!rowData.wageTypeIdValid && !rowData.data.wageTypeId ? true : false}
            value={value}
          />
        </div>
      );
    } else if (dataKey === "currencyTypeId") {
      return (
        <div>
          <Label htmlFor={id} srOnlyAt={breakpoints.md} text="Currency" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowData.step, "suggestion")}
            onChange={e => this.props._inputChange(e, rowData.step, "suggestion")}
            options={this.props.currencyTypeOptions}
            placeholder={"Select..."}
            value={value ? value : "1"} // default to USD
          />
        </div>
      );
    } else {
      // dataKey === "payAreaId"
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Pay Area" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowData.step, "suggestion")}
            onChange={e => this.props._inputChange(e, rowData.step, "suggestion")}
            options={this.props.payAreaOptions}
            placeholder={"Select..."}
            required={true}
            showPlaceholder={true}
            showRequired={true}
            showValidation={!rowData.payAreaIdValid || (!rowData.payAreaIdValid && !rowData.data.payAreaId) ? true : false}
            value={value}
          />
        </div>
      );
    }
  }
  private _datepickerRenderer({ rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = false;
    const value: Date | null = rowData.data.dateOfPay ? moment(rowData.data.dateOfPay, "YYYY-MM-DD").toDate() : null;
    return (
      <div>
        <Datepicker
          disabled={disabled}
          label="Date"
          onChange={(date: Date) => this.props._dateChange(date, rowData.step, "suggestion")}
          placeholder={""}
          required={false}
          value={value}
        />
      </div>
    );
  }
  private _setHeight(): void {
    if (window.innerWidth < 1024) {
      if (this.props.requestType && this.props.requestType.code === "IT2010") {
        this.setState({
          height: 1100,
          duplicatesHeight: 300,
          suggestionHeight: 1100
        });
      } else if (this.props.requestType && this.props.requestType.code === "IT0014") {
        this.setState({
          height: 950,
          duplicatesHeight: 300,
          suggestionHeight: 950
        });
      } else {
        this.setState({
          height: 870,
          duplicatesHeight: 300,
          suggestionHeight: 870
        });
      }
    } else {
      this.setState({
        height: 45,
        duplicatesHeight: 200,
        suggestionHeight: 80
      });
    }
  }
}
