import * as React from "react";
import _sortBy from "lodash/sortBy";
// components
import Sort from "./Sort";
import Th from "./Th";
import FilterCheckboxes from "../filter/FilterCheckboxes";
import FilterRequestTypes from "../filter/FilterRequestTypes";
import FilterDatePicker from "../filter/FilterDatePicker";

// interface IFilterProps {
//   isDisabled: boolean;
//   isFiltering: boolean;
//   isAllChecked: boolean;
//   handleFilterChange: Function;
//   label?: string;
//   options: any[];
//   selectedOptions: number[];
// }

// interface IColumn {
//   sortBy?: string;
//   text: string;
//   filterProps?: IFilterProps;
// }

interface IHeaderColumns {
  columns: any[];
  sortBy: string;
  sortOrder: string;
  handleSort: Function;
  data: any;
}

const HeaderColumns = ({
  columns,
  sortBy,
  sortOrder,
  data,
  handleSort
}: IHeaderColumns) => {
  const renderFilters = (column: any): JSX.Element | null => {
    if (column.filterProps) {
      if (column.text === "Request Type") {
        return (
          <FilterRequestTypes
            {...column.filterProps}
            filterText={column.text}
          />
        );
      }
      if (column.isDateFilter) {
        return (
          <FilterDatePicker {...column.filterProps} filterText={column.text} />
        );
      }
      return (
        <FilterCheckboxes {...column.filterProps} filterText={column.text} />
      );
    }
    return null;
  };
  return (
    <>
      {columns.map((column, index) => {
        return (
          <Th hidden={false} text={column.text} title={column.text} key={index}>
            {column.sortBy && (
              <Sort
                text={column.text}
                ascending={column.sortBy === sortBy && sortOrder === "ASC"}
                descending={column.sortBy === sortBy && sortOrder === "DESC"}
                onClick={e => {
                  if (sortOrder === "DESC") {
                    return handleSort({
                      data: _sortBy(data, [column.sortBy]),
                      sortOrder: "ASC",
                      sortBy: column.sortBy
                    });
                  }
                  return handleSort({
                    data: _sortBy(data, [column.sortBy]),
                    sortOrder: "DESC",
                    sortBy: column.sortBy
                  });
                }}
                disabled={false}
              />
            )}
            {renderFilters(column)}
          </Th>
        );
      })}
    </>
  );
};

export default HeaderColumns;
