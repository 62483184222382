import { IUser } from "../types/User";
import { getRequest, postRequest } from "../core/api/requestConfig";
import { IAdInvite, IAdInviteResponse, IAdInviteResponses } from "../types/AdInvite";

export function getAssignees(adGroupId: string): Promise<IUser[]> {
  return new Promise<IUser[]>((resolve, reject) => {
    getRequest(`/api/v1/getUsersByAzureGroupId?azureGroupId=${adGroupId}`)
      .then((users: IUser[]) => {
        return resolve(users);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function inviteAdUser(invite: IAdInvite): Promise<IAdInviteResponse> {
  return new Promise<IAdInviteResponse>((resolve, reject) => {
    postRequest(`/api/v1/inviteAdUser`, invite)
      .then((inviteResponse: IAdInviteResponse) => {
        return resolve(inviteResponse);
      })
      .catch((error: IAdInviteResponse) => {
        return reject(error);
      });
  });
}

export function inviteAdUsers(invite: IAdInvite[]): Promise<IAdInviteResponses> {
  return new Promise<IAdInviteResponses>((resolve, reject) => {
    postRequest(`/api/v1/inviteAdUsers`, invite)
      .then((inviteResponse: IAdInviteResponses) => {
        return resolve(inviteResponse);
      })
      .catch((error: IAdInviteResponses) => {
        return reject(error);
      });
  });
}
