import * as React from "react";
// components
import LoaderGradient from "./LoaderGradient";
import Tr from "../table/Tr";
import Td from "../table/Td";

const genColumns = (num: number) => {
  const arr: number[] = [];
  let n: number = 1;
  while (n <= num) {
    arr.push(n);
    n++;
  }
  return arr;
};

const LoaderTable = ({ numOfColumns }: { numOfColumns: number }) => {
  return (
    <>
      {genColumns(5).map((row: number) => (
        <Tr key={`loading-${row}`}>
          {genColumns(numOfColumns).map(num => {
            return (
              <Td key={`column-${num}`}>
                <LoaderGradient />
              </Td>
            );
          })}
        </Tr>
      ))}
    </>
  );
};

export default LoaderTable;
