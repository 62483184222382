import { getRequest } from "../core/api/requestConfig";
import { ILoginCountByDateRangeReq, ILoginCountByDateRangeRes, ILoginsReq, ILoginsRes, ILoginV2GetFilter, ILoginV2GetResponse } from "../types/Logins";

/**
 * Get login counts from SQL
 * @param query ILoginCountByDateRangeReq
 * @returns ILoginCountByDateRangeRes
 */
export function getLoginCountsByDateRange({ appId, bottlerId, startDate, endDate }: ILoginCountByDateRangeReq): Promise<ILoginCountByDateRangeRes> {
  return new Promise<ILoginCountByDateRangeRes>((resolve, reject) => {
    getRequest(`/api/v1/loginCountPerDayByDateRange?startDate=${startDate}&endDate=${endDate}${appId ? `&appId=${appId}` : ""}${bottlerId ? `&bottlerId=${bottlerId}` : ""}`)
      .then(res => {
        return resolve(res);
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = "Error retrieving login counts by date range";
        }
        return reject(error);
      });
  });
}

/**
 * Get logins from SQL
 * @param query ILoginsReq
 * @returns Promise<ILoginsRes>
 */
export function getLogins({ bottlerId, startDate, endDate, orderBy = "[login].[createdAt]", pageNumber = 1, pageSize = 10, sortOrder = "DESC" }: ILoginsReq): Promise<ILoginsRes> {
  return new Promise<ILoginsRes>((resolve, reject) => {
    getRequest(
      `/api/v1/logins?${
        bottlerId ? `bottlerId=${bottlerId}&` : ""
      }orderBy=${orderBy}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&createdAtStart=${startDate}&createdAtEnd=${endDate}`
    )
      .then(res => {
        return resolve(res);
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = "Error retrieving login information";
        }
        return reject(error);
      });
  });
}

/**
 * Get /v2/logins from SQL
 * @param filter ILoginV2GetFilter
 * @returns Promise<ILoginV2GetResponse>
 */
export const getLoginsV2 = async (filter: ILoginV2GetFilter): Promise<ILoginV2GetResponse> => {
  try {
    const { appId, bottlerId, createdAtEnd, createdAtStart, limit = 10, offset = 1, sortBy = "[login].[createdAt]", sortOrder = "DESC" } = filter;

    const result = await getRequest(
      `/api/v2/logins?appId=${appId}&createdAtStart=${createdAtStart}&createdAtEnd=${createdAtEnd}&limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortOrder=${sortOrder}${
        bottlerId ? `&bottlerId=${bottlerId}` : ""
      }`
    );
    return Promise.resolve(result);
  } catch (error) {
    if (!error.message) {
      error.message = "Error retrieving login information";
    }
    return Promise.reject(error);
  }
};
