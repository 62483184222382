import { getRequest } from "../core/api/requestConfig";
import { IRequestUsageGetResponse } from "../types/RequestUsage";
import { Bottler } from "../types/Bottler";
/**
 * Get request usages from SQL
 * @param bottler Bottler | null
 * @param requestTypeId number
 * @param usageDateStart string
 * @param usageDateEnd string
 * @param appId number
 * @returns IRequestUsageGetResponse
 */
export const getRequestUsages = async (
  bottler: Bottler | null,
  requestTypeId: number,
  usageDateStart: string,
  usageDateEnd: string,
  appId: number,
  cstRequestTypeId?: number
): Promise<IRequestUsageGetResponse> => {
  try {
    let query = `?requestTypeId=${requestTypeId}${
      cstRequestTypeId ? `&cstRequestTypeId=${cstRequestTypeId}` : ""
    }&usageDateStart=${usageDateStart}&usageDateEnd=${usageDateEnd}&appId=${appId}`;
    if (bottler) {
      query += `&bottlerId=${bottler.id}`;
    }
    const response = await getRequest(`/api/v2/requestUsages${query}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
