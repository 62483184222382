import { getRequest } from "../api/requestConfig";
import { IBottlersForSwitcherResponse } from "../types/Bottler";

type Bottler = {
  code: string;
  id: number;
  name: string;
} | null;

enum TypeKeys {
  GET_BOTTLERS_SUCCESS = "GET_BOTTLERS_SUCCESS",
  GET_BOTTLERS_FAILURE = "GET_BOTTLERS_FAILURE",
  SET_BOTTLERS_SUCCESS = "SET_BOTTLERS_SUCCESS",
  SET_BOTTLERS_FAILURE = "SET_BOTTLERS_FAILURE"
}

type Error = string | null;

type getBottlersSuccess = {
  bottlers: Bottler[] | null;
  type: TypeKeys.GET_BOTTLERS_SUCCESS;
};

type getBottlersFailure = {
  error: Error;
  type: TypeKeys.GET_BOTTLERS_FAILURE;
};

type setBottlersSuccess = {
  bottlers: Bottler[] | null;
  type: TypeKeys.SET_BOTTLERS_SUCCESS;
};

type setBottlersFailure = {
  error: Error;
  type: TypeKeys.SET_BOTTLERS_FAILURE;
};

/**
 *
 * @param bottlers bottlers values
 */
export const getBottlersSuccessCreator = (bottlers: Bottler[]): getBottlersSuccess => {
  return {
    bottlers,
    type: TypeKeys.GET_BOTTLERS_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const setBottlersFailureCreator = (error: Error): setBottlersFailure => {
  return {
    error,
    type: TypeKeys.SET_BOTTLERS_FAILURE
  };
};

/**
 *
 * @param bottlers bottlers values
 */
export const setBottlersSuccessCreator = (bottlers: Bottler[] | null): setBottlersSuccess => {
  return {
    bottlers,
    type: TypeKeys.SET_BOTTLERS_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const getBottlersFailureCreator = (error: Error): getBottlersFailure => {
  return {
    error,
    type: TypeKeys.GET_BOTTLERS_FAILURE
  };
};

/**
 * Get bottlers for bottler switcher
 * @param requestTypeId number | number[]
 */
export const getBottlersCreator = (requestTypeId?: number | number[]) => {
  return async dispatch => {
    try {
      let bottlers: Bottler[] = [];

      let query: string = ``;

      if (requestTypeId) {
        query += `?`;

        if (Array.isArray(requestTypeId)) {
          requestTypeId.map((type, index) => {
            query += `requestTypeId=${type}`;

            if (index !== 0 && index !== requestTypeId.length - 1) {
              query += `&`;
            }
          });
        } else {
          query += `requestTypeId=${requestTypeId}`;
        }
      }

      const response: IBottlersForSwitcherResponse = await getRequest(`/api/v2/bottlers${query}`);

      response.bottlers.map(bottler => {
        bottlers.push(bottler);
      });

      dispatch(setBottlersCreator(null));
      dispatch(getBottlersSuccessCreator(bottlers));
    } catch (error) {
      console.log(error);

      dispatch(getBottlersFailureCreator(error));
    }
  };
};

export const setBottlersCreator = (bottlers: Bottler[] | null) => {
  return async dispatch => {
    try {
      dispatch(setBottlersSuccessCreator(bottlers));
    } catch (error) {
      console.log(error);
      dispatch(setBottlersFailureCreator(error));
    }
  };
};

export type Actions = getBottlersSuccess | getBottlersFailure | setBottlersSuccess | setBottlersFailure;

export interface IState {
  isLoading: boolean;
  bottlers: Bottler[] | null;
  error: Error;
}

export const initialState = {
  isLoading: true,
  bottlers: null,
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_BOTTLERS_SUCCESS:
      newState.bottlers = action.bottlers;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_BOTTLERS_FAILURE:
      newState.error = action.error as string;
      newState.bottlers = null;
      newState.isLoading = false;
      break;
    case TypeKeys.SET_BOTTLERS_SUCCESS:
      newState.bottlers = action.bottlers;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.SET_BOTTLERS_FAILURE:
      newState.error = action.error as string;
      newState.bottlers = null;
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};
