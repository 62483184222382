import * as React from "react";

export interface ICardsProps {
  cards: JSX.Element[] | any[];
  centered?: boolean;
  className?: string;
  columns?: number;
}
export interface ICardsState {}

export default class Cards extends React.Component<ICardsProps, ICardsState> {
  public render(): React.ReactElement<ICardsProps> {
    let className: string = "cards";
    if (this.props.centered) {
      className += " cardsCentered";
    }
    if (this.props.columns === 2) {
      className += " cardsTwoColumns";
    }
    if (this.props.className) {
      className += " " + this.props.className;
    }
    return <div className={className}>{this.props.cards}</div>;
  }
}
