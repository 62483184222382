import * as React from "react";
import DateTimePicker from "./DateTimePicker";
import { Icon } from "office-ui-fabric-react";

export interface IDateRangePickerProps {
  endDate: Date | string | null;
  endDateChange: Function;
  endDateId: string;
  endDateMax: string;
  endDateMin: string;
  endDatePlaceholder: string;
  startDate: Date | string | null;
  startDateChange: Function;
  startDateId: string;
  startDateMax: string;
  startDateMin?: string;
  startDatePlaceholder: string;
}

export interface IDateRangePickerState {}

export default class DateRangePicker extends React.Component<IDateRangePickerProps, IDateRangePickerState> {
  public render(): React.ReactElement<IDateRangePickerProps> {
    const {
      endDate,
      endDateChange,
      endDateId,
      endDateMax,
      endDateMin,
      endDatePlaceholder,
      startDate,
      startDateChange,
      startDateId,
      startDateMax,
      startDateMin,
      startDatePlaceholder
    } = this.props;

    return (
      <div className="dateRangePickerWrapper">
        <DateTimePicker
          date={startDate}
          id={startDateId}
          maxDate={startDateMax}
          minDate={startDateMin}
          preventClickOnDateTimeOutsideRange={true}
          placeholder={startDatePlaceholder}
          onChange={startDateChange}
          showTime={false}
          time={null}
        />
        <span className="srOnly">TO</span>
        <Icon className="dateRangePickerIcon" iconName="Forward" />
        <DateTimePicker
          date={endDate}
          id={endDateId}
          maxDate={endDateMax}
          minDate={endDateMin}
          preventClickOnDateTimeOutsideRange={true}
          placeholder={endDatePlaceholder}
          onChange={endDateChange}
          showTime={false}
          time={null}
        />
      </div>
    );
  }
}
