import * as React from "react";
// components
import Checkbox from "../input/Checkbox";
import Label from "../label/Label";
import Filter from "./Filter";
import { H6 } from "../typography";
// types
import { IRequestTypeFilter, IRequestTypeWithProcessFilter } from "../../types/RequestType";

interface IFilterRequestTypesProps {
  filterText: string;
  handleFilterChange: Function;
  isAllChecked?: boolean;
  isDisabled?: boolean;
  isFiltering?: boolean;
  label?: string;
  options: IRequestTypeFilter[];
  selectedOptions: number[];
}

const FilterRequestTypes = ({
  filterText,
  handleFilterChange,
  isAllChecked = true,
  isDisabled = false,
  isFiltering = false,
  label,
  options,
  selectedOptions
}: IFilterRequestTypesProps) => {
  return (
    <>
      {label && <Label text="Request Type" />}
      <Filter filterDisabled={isDisabled} filterText={filterText} filterPosition="filterLeft" filtered={isFiltering}>
        <>
          <Checkbox
            key="requestTypes-all"
            checked={isAllChecked}
            className="checkboxHr"
            id="requestTypes-all"
            name="requestTypes-all"
            onChange={e => {
              if (!isAllChecked) {
                return handleFilterChange(
                  [].concat.apply(
                    [],
                    // @ts-ignore
                    options.map(rt => rt.requestTypes.map(type => type.id))
                  )
                );
              }
            }}
            text="Toggle All"
          />
          {options.map((rt: IRequestTypeFilter, i: number) => (
            <React.Fragment key={`requestTypelabel-${i}`}>
              <H6 className="requestTypeFilterLabel">{rt.requestProcessLabel}</H6>
              {rt.requestTypes.map((type: IRequestTypeWithProcessFilter) => {
                const isChecked: boolean = selectedOptions.includes(type.id);
                return (
                  <Checkbox
                    key={`requestType-${type.id}`}
                    checked={isChecked}
                    id={`requestType-${type.id}`}
                    name={`requestType-${type.id}`}
                    onChange={() => (isChecked ? handleFilterChange(selectedOptions.filter(so => so !== type.id)) : handleFilterChange([...selectedOptions, type.id]))}
                    text={type.label}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </>
      </Filter>
    </>
  );
};

export default FilterRequestTypes;
