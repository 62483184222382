import * as React from "react";
// components
import Band from "../../components/layout/Band";
import H1 from "../../components/typography/H1";
import Permissions from "../permissions/Permissions";
import Section from "../../components/layout/Section";
// containers
import AdGroupsList from "./AdGroupsList";
// enums
import { Bottlers } from "../../enums/Bottler";
// logging
import { withPageLog } from "../logging/LogComponentChange";

export interface IServicesAppGroupsProps {}

export const ServicesAppGroups: React.StatelessComponent<IServicesAppGroupsProps> = () => {
  const title: string = "Services App Groups";

  return (
    <div className="groupMembership adGroups">
      <Band>
        <H1>{title}</H1>
      </Band>
      <Section>
        <Permissions title={title}>
          <AdGroupsList bottlerCode={["BSNA-AD", "BSNA-AAD"]} bottlerDomains={["coke-bsna.com", "ccbss.com"]} bottlerId={Bottlers.Bsna} showAddUsersMessage={true} title={title} />
        </Permissions>
      </Section>
    </div>
  );
};

export default withPageLog(ServicesAppGroups);
