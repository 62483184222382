import * as React from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Skeleton from "./components/skeleton/Skeleton";
import Loader from "./components/loader/Loader";
import ErrorMessage from "./components/errors/ErrorMessage";
import Dashboard from "./containers/dashboard/Dashboard";
import GroupMembership from "./containers/group-membership/GroupMembership";
import Notify from "./containers/notify/Notify";
import SharedServices from "./containers/shared-services/SharedServices";
import NotFound from "./containers/not-found/NotFound";
import WifiNetwork from "./containers/wifi-network/WifiNetwork";
import { UserProvider } from "./core/user/components/UserProvider";
import { store } from "./core/store";
import { ConfigurationProvider } from "./core/config/components/Config";
import { FeatureFlagProvider } from "./core/featureFlags/components/launchDarkly";
import { Logger } from "./core/logger/components/LogProvider";
import SettingsRoutes from "./containers/settings/SettingsRoutes";
import DocumentationRoutes from "./containers/documentation/DocumentationRoutes";
import NotAllowed from "./containers/not-allowed/NotAllowed";
import { tokenRequest } from "./core/authentication/authenticationConfig";

function ErrorComponent({ error }: any) {
  return <ErrorMessage errorCode={500} errorCodeType="Error" errorMessage={error?.message || "Error logging in with microsoft azure"} />;
}

function LoadingComponent() {
  return <Loader centered type="Overlay" />;
}

export const App: React.FC = () => {
  return (
    <Provider store={store as any}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={tokenRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <UserProvider>
          <ConfigurationProvider>
            <FeatureFlagProvider>
              <Logger>
                <HashRouter>
                  <Skeleton>
                    <Switch>
                      <Route exact={true} path="/" component={Dashboard} />
                      <Route path="/dashboard" component={Dashboard} />
                      <Route path="/repair-requests" component={Notify} />
                      <Route path="/shared-services" component={SharedServices} />
                      <Route path="/wifi-network" component={WifiNetwork} />
                      <Route path="/documentation" component={DocumentationRoutes} />
                      <Route path="/group-membership" component={GroupMembership} />
                      <Route path="/settings" component={SettingsRoutes} />
                      <Route path="/not-allowed" component={NotAllowed} />
                      <Route path="/not-found" component={NotFound} />
                      <Redirect to="/not-found" />
                    </Switch>
                  </Skeleton>
                </HashRouter>
                <ToastContainer />
              </Logger>
            </FeatureFlagProvider>
          </ConfigurationProvider>
        </UserProvider>
      </MsalAuthenticationTemplate>
    </Provider>
  );
};
