import * as React from "react";
import Toggle from "./Toggle";

interface IRefreshToggleProps {
  id: string;
  onRefresh: Function;
  shouldStartInterval: boolean;
}

interface IRefreshToggleState {
  isChecked: boolean;
}

let interval: any;

const startRefreshInterval = (cb: Function) => {
  if (!interval) {
    interval = setInterval(() => {
      cb();
    }, 60000); // 1min
  }
};

const stopRefreshInterval = () => {
  clearInterval(interval);
  interval = null;
};

class RefreshToggle extends React.PureComponent<
  IRefreshToggleProps,
  IRefreshToggleState
> {
  state = {
    isChecked: localStorage.getItem("autoRefresh") === "true" || false
  };
  public componentDidMount() {
    // in some cases the props are set before the component is mounted so start the interval
    if (this.state.isChecked && this.props.shouldStartInterval) {
      return startRefreshInterval(this.props.onRefresh);
    }
    return stopRefreshInterval();
  }
  public componentDidUpdate(prevProps: IRefreshToggleProps) {
    // start interval when the state updates and when its time to start the interval
    if (!interval && this.state.isChecked && this.props.shouldStartInterval) {
      return startRefreshInterval(this.props.onRefresh);
    }
    return stopRefreshInterval();
  }
  public componentWillUnmount() {
    stopRefreshInterval();
  }
  handleChange = () => {
    if (this.state.isChecked) {
      localStorage.setItem("autoRefresh", "false");
      return this.setState({ isChecked: false });
    }
    localStorage.setItem("autoRefresh", "true");
    return this.setState({ isChecked: true });
  };
  public render() {
    const { id } = this.props;
    const { isChecked } = this.state;
    return (
      <Toggle
        id={id}
        isChecked={isChecked}
        label="Auto-Refresh"
        icons={{
          checked: <p className="toggleLabelOn">On</p>,
          unchecked: <p className="toggleLabelOff">Off</p>
        }}
        handleChange={this.handleChange}
      />
    );
  }
}

export default RefreshToggle;
