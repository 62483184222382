import { getRequest, putRequest } from "../core/api/requestConfig";
import { IRequestTypeFilter, IRequestTypeByBottler, IRequestType, IRequestTypesResponse, IRequestTypeV2, IRequestTypeUpdateResponse } from "../types/RequestType";

export function getRequestTypesForFilter(): Promise<IRequestTypeFilter[]> {
  return new Promise<IRequestTypeFilter[]>((resolve, reject) => {
    getRequest("/api/v1/getRequestTypesForFilter")
      .then(requestTypes => {
        return resolve(requestTypes);
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = "Error retrieving request types.";
        }
        return reject(error);
      });
  });
}

export function getRequestTypesByBottlerId(id: number): Promise<IRequestTypeByBottler> {
  return new Promise<IRequestTypeByBottler>((resolve, reject) => {
    getRequest(`/api/requestTypesByBottlerId?bottlerId=${id}`)
      .then(res => {
        return resolve(res);
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = `Error retrieving request types for bottler id ${id}.`;
        }
        return reject(error);
      });
  });
}

export function updateRequestType(requestType: IRequestType): Promise<IRequestType> {
  return new Promise<IRequestType>((resolve, reject) => {
    putRequest("/api/requestType", requestType)
      .then(res => {
        return resolve(res);
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = `Error updating bottler id ${requestType.id}.`;
        }
        return reject(error);
      });
  });
}

/**
 * Get all request types
 */
export function getRequestTypes(): Promise<IRequestTypesResponse> {
  return new Promise<IRequestTypesResponse>((resolve, reject) => {
    getRequest("/api/v2/requestTypes")
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        return reject(error);
      });
  });
}

/**
 * Get request type by id
 * @param id
 */
export function getRequestType(id: number): Promise<IRequestTypesResponse> {
  return new Promise<IRequestTypesResponse>((resolve, reject) => {
    getRequest(`/api/v2/requestTypes/${id}`)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        return reject(error);
      });
  });
}

/**
 * Update request type
 * @param requestType
 */
export function updateRequestTypeV2(requestType: IRequestTypeV2): Promise<IRequestTypeUpdateResponse> {
  return new Promise<IRequestTypeUpdateResponse>((resolve, reject) => {
    putRequest(`/api/v2/requestTypes/${requestType.id}`, requestType)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        return reject(error);
      });
  });
}
