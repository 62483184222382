import * as React from "react";
import { connect } from "react-redux";
import { GetConfigCreator, IState, mapStateToProps } from "../config";
import LoaderFullscreen from "../../../components/loader/LoaderFullscreen";

const apiUrl = "/api/v1/config";

interface WrappedProps extends IState {}

export class ConfigProvider extends React.Component<WrappedProps, IState> {
  public props: any;
  public componentDidMount() {
    this.props.dispatch(GetConfigCreator(apiUrl));
  }
  public render() {
    if (this.props.isLoading) {
      return <LoaderFullscreen />;
    } // TODO add loadable HOC

    const component = this.props.config ? React.cloneElement(this.props.children, { config: this.props.config }) : <div>{`error loading config: ${this.props.error}`}</div>;

    return component;
  }
}

export const ConfigurationProvider = connect(mapStateToProps)(ConfigProvider);
