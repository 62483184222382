import * as React from "react";
import Dropzone from "react-dropzone";

export interface IDragDropProps {
  handleFileChange: any;
  multiple: boolean;
  text: JSX.Element;
}

export interface IDragDropState {
  hover: boolean;
}

export class DragDropFile extends React.Component<IDragDropProps, IDragDropState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hover: false
    };
    this._handleFileChange = this._handleFileChange.bind(this);
  }
  private _handleFileChange(acceptedFiles: any[], rejectedFiles: any[]): void {
    if (!this.props.multiple) {
      this.props.handleFileChange(acceptedFiles[0]);
    } else {
      this.props.handleFileChange(acceptedFiles);
    }
    this.setState({ hover: false });
  }
  public render(): React.ReactElement<IDragDropProps> {
    return (
      <Dropzone
        onDrop={(acceptedFiles, rejectedFiles) => this._handleFileChange(acceptedFiles, rejectedFiles)}
        className={this.state.hover ? "dragDropHover" : "dragDrop"}
        onDragEnter={() => this.setState({ hover: true })}
      >
        {this.props.text}
      </Dropzone>
    );
  }
}
