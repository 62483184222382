import { getAdminAppRoutes } from "../adminAppRoute/adminAppRoute";
import { IUser } from "../types/User";
import { getRequest } from "../api/requestConfig";
import { msalInstance } from "../../index";

type Error = string | null;

type GetUserSuccess = {
  user: IUser;
  type: "GET_USER_SUCCESS";
};

type GetUserFailure = {
  error: Error;
  type: "GET_USER_FAILURE";
};

// post is a findOrCreate
export function getProfile(cacheUser: boolean): Promise<IUser> {
  return new Promise<IUser>((resolve, reject) => {
    getRequest(`/api/v1/getProfile?cacheUser=${cacheUser}`)
      .then((createdUser: IUser) => {
        // assign an empty array to test not having access to the admin app
        // (no BSNA-AAD-RequestAdmin-* AD groups)
        // createdUser.adGroupObjectIds = [];

        return resolve(createdUser);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

/**
 *
 * @param user user values
 */
export const GetUserSuccessCreator = (user: IUser): GetUserSuccess => {
  return {
    user,
    type: "GET_USER_SUCCESS"
  };
};

/**
 *
 * @param error error message
 */
export const GetUserFailureCreator = (error: Error): GetUserFailure => {
  return {
    error,
    type: "GET_USER_FAILURE"
  };
};

/**
 *
 * @param apiUrl relative url for api endpoint
 */
export const GetUserCreator = () => {
  return async dispatch => {
    try {
      const authUser = msalInstance.getActiveAccount();
      const cacheUser: string | null = localStorage.getItem("cacheUser");
      const user: IUser = await getProfile(cacheUser === "false" ? false : true);
      user.username = authUser?.username as any;
      user.firstname = authUser?.name?.split(" ")?.[0] ? authUser.name?.split(" ")?.[0] : null;
      user.lastname = authUser?.name?.split(" ")?.[1] ? authUser.name?.split(" ")?.[1] : null;

      dispatch(getAdminAppRoutes(user.adGroupObjectIds));

      dispatch(GetUserSuccessCreator(user));
    } catch (error) {
      dispatch(GetUserFailureCreator(error));
    }
  };
};

export type Actions = GetUserSuccess | GetUserFailure;

export interface IState {
  isLoading: boolean;
  user: IUser | null;
  error: Error;
}

export const initialState = {
  isLoading: true,
  user: null,
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case "GET_USER_SUCCESS":
      newState.user = action.user;
      newState.error = null;
      newState.isLoading = false;
      break;
    case "GET_USER_FAILURE":
      newState.error = action.error as string;
      newState.user = null;
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};

export const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    error: state.user.error,
    isLoading: state.user.isLoading
  };
};
