import React, { useState, FC, useEffect } from "react";
import { toast } from "react-toastify";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Prompt } from "react-router-dom";
// components
import Button from "../../components/button/Button";
import Page from "../../components/page/Page";
import P from "../../components/typography/P";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import TBody from "../../components/table/TBody";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import THead from "../../components/table/THead";
import Toggle from "../../components/toggle/Toggle";
import Tr from "../../components/table/Tr";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// types
import { IAccessGetData } from "../../types/BottlerAccess";
// services
import { updateBottlerAccess, getBottlersAccess } from "../../services/BottlerAccess";

interface IBottlerAccess {
  appId: number;
}

const BottlerAccess: FC<IBottlerAccess> = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAccessGetData[] | null>(null);
  const [selectedBottlers, setSelectedBottlers] = useState<number[]>([]);
  const [initialData, setInitialData] = useState<IAccessGetData[]>([]);
  const fetchBottlerAccess = async () => {
    try {
      setLoading(true);
      const res = await getBottlersAccess();
      setData(res.data);
      setInitialData(res.data);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBottlerAccess();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Page permissions={true} title="Coke Service App - Control Access by Bottler">
        <P italic>When the app is disabled for a Bottler, then no requests can be submitted for any equipment owned by that Bottler.</P>
        <TableWrapper loading={isLoading}>
          <Table>
            <THead>
              <Tr>
                <Th text="Bottler" title="Bottler" />
                <Th text="Enable/Disable Coke Service App" title="Enable/Disable Coke Service App" />
              </Tr>
            </THead>
            <TBody>
              {data &&
                data.map((item: IAccessGetData, index: number) => {
                  const selected = data.find(d => d.id === item.id);
                  const hasChanged = selectedBottlers.includes(item.id);
                  return (
                    <Tr hover={true} key={`sla-${index}`} striped={true}>
                      <Td>{item.bottler.name}</Td>
                      <Td>
                        <Toggle
                          id={`${item.id}`}
                          isChecked={item.isActive}
                          handleChange={() => {
                            hasChanged ? setSelectedBottlers(selectedBottlers.filter(id => id !== item.id)) : setSelectedBottlers([...selectedBottlers, item.id]);
                            setData(
                              data.map(d => {
                                if (selected && selected.id === d.id) {
                                  return {
                                    ...selected,
                                    isActive: !selected.isActive
                                  };
                                }
                                return d;
                              })
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </TBody>
          </Table>
        </TableWrapper>
        <div className="field">
          <Button
            className="buttonSave"
            iconLeft={<Icon iconName="Save" />}
            text="Save"
            title="Save"
            disabled={selectedBottlers.length === 0}
            onMouseDown={async () => {
              try {
                setLoading(true);
                if (data) {
                  const changedBottlers: IAccessGetData[] = [];

                  data.map(item => {
                    if (selectedBottlers.includes(item.id)) {
                      return changedBottlers.push(item);
                    }
                  });
                  const promises = changedBottlers.map(async item => await updateBottlerAccess(item.id, item.isActive));
                  await Promise.all(promises);
                  toast.success("Bottler Access updated", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                  // reset state
                  setSelectedBottlers([]);
                }
              } catch (error) {
                toast.error(error.message, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
              } finally {
                setLoading(false);
              }
            }}
          />
          <Button
            className="buttonWhite"
            text="Cancel"
            title="Cancel"
            disabled={selectedBottlers.length === 0}
            onMouseDown={() => {
              setData(initialData);
              // reset state
              setSelectedBottlers([]);
            }}
          />
        </div>
      </Page>
      <Prompt when={selectedBottlers.length > 0} message="You have unsaved changes, are you sure you want to leave?" />
    </>
  );
};

export default withPageLog(BottlerAccess);
