import { getRequest } from "../core/api/requestConfig";

export const getCurrencyTypes = () => {
  return new Promise<any>((resolve, reject) => {
    getRequest("/legacy/api/v1/currencyTypes")
      .then(currencyTypes => {
        if (currencyTypes && currencyTypes.length) {
          return resolve(currencyTypes);
        } else {
          return resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        return reject(null);
      });
  });
};
