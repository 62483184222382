import React, { useState, FC, useEffect } from "react";
import { toast } from "react-toastify";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { title } from "case";
// components
import Alert from "../../components/alert/Alert";
import Page from "../../components/page/Page";
import P from "../../components/typography/P";
import Select from "../../components/input/Select";
import Sort from "../../components/table/Sort";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import TBody from "../../components/table/TBody";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import THead from "../../components/table/THead";
import Tr from "../../components/table/Tr";
import Pagination from "../../components/pagination/Pagination";
import Button from "../../components/button/Button";
import Checkbox from "../../components/input/Checkbox";
import Modal from "../../components/modal/Modal";
import Input from "../../components/input/Input";
// redux
import { getBottlersCreator } from "../../core/bottler/bottlers";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// types
import { Apps } from "../../types/App";
import { ISlaGetFilter, ISla } from "../../types/Sla";
import { RequestTypes } from "../../enums/RequestTypes";
import { IEquipmentProblem } from "../../types/EquipmentProblems";
import { Bottler } from "../../types/Bottler";
import { IEquipmentType } from "../../types/EquipmentTypes";
// services
import { getSlas, deleteSlas, createSla, updateSla } from "../../services/Sla";
import { getEquipmentProblems, getEquipmentProblemsByEquipmentId } from "../../services/EquipmentProblems";
import { getEquipmentTypes } from "../../services/EquipmentTypes";

const DEFAULT_LIMIT: number = 10;
const DEFAULT_OFFSET: number = 0;
const DEFAULT_SORT_ORDER: string = "DESC";
const DEFAULT_SORT_BY: string = "bottler.name";

const headerColumns = [
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "",
    databaseTable: "",
    id: "bottlerName",
    sort: false,
    sortBy: "",
    sortMobile: false,
    text: "",
    title: "",
    type: ""
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "name",
    databaseTable: "bottler",
    id: "bottlerName",
    sort: true,
    sortBy: "bottler.name",
    sortMobile: true,
    text: "Bottler",
    title: "Bottler",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "description",
    databaseTable: "equipmentType",
    id: "equipmentType",
    sort: true,
    sortBy: "[equipmentProblem->equipmentType].description",
    sortMobile: true,
    text: "Equipment Type",
    title: "Equipment Type",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "description",
    databaseTable: "equipmentProblem",
    id: "equipmentProblemDescription",
    sort: true,
    sortBy: "equipmentProblem.description",
    sortMobile: true,
    text: "Problem Desription",
    title: "Problem Desription",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "problemCode",
    databaseTable: "equipmentProblem",
    id: "equipmentProblemCode",
    sort: true,
    sortBy: "equipmentProblem.problemCode",
    sortMobile: true,
    text: "Problem Code",
    title: "Problem Code",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "sla",
    databaseTable: "sla",
    id: "sla",
    sort: true,
    sortBy: "sla",
    sortMobile: true,
    text: "SLA",
    title: "SLA",
    type: "string"
  }
];

const filteredEquipmentTypes = ["CARD READER", "FREESTYLE", "PREMIX", "PROJECT", "TEA URN"];

interface ISLAManagement {
  appId: number;
  bottlers?: Bottler[];
  getBottlers: (requestTypeId: number) => void;
}

interface ISLASelected {
  id: number;
  bottlerId: number;
  equipmentProblemId: number;
  sla: number;
}

const SLAManagement: FC<ISLAManagement> = ({ appId, bottlers, getBottlers }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [isModalAddOpen, setAddModalOpen] = useState<boolean>(false);
  const [isModalUpdateOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>(DEFAULT_SORT_BY);
  const [sortOrder, setOrder] = useState<string>(DEFAULT_SORT_ORDER);
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [count, setCount] = useState<number>(0);
  const [selectedSlas, setSelectedSlas] = useState<ISLASelected[]>([]);
  const [data, setDate] = useState<ISla[]>([]);
  const [slaOptions, setSlaOptions] = useState<ISla[]>([]);
  const [bottlerId, setSelectedBottler] = useState<number | null>(null);
  const [newBottlerId, setNewBottlerId] = useState<number | null>(null);
  const [equipmentProblemId, setSelectedProblem] = useState<number | null>(null);
  const [newEquipmentProblemId, setNewEquipmentProblem] = useState<number | null>(null);
  const [equipmentTypeId, setSelectedEquipmentType] = useState<number | null>(null);
  const [newEquipmentTypeId, setNewSelectedEquipmentType] = useState<number | null>(null);
  const [filterSla, setSelectedSla] = useState<number | null>(null);
  const [equipmentProblems, setEquipmentProblems] = useState<IEquipmentProblem[]>([]);
  const [equipmentProblemsByEquipment, setEquipmentProblemsByEquipment] = useState<IEquipmentProblem[] | null>(null);
  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [slaValue, setSlaInput] = useState<string>("");

  const fetchSlas = async () => {
    setLoading(true);
    try {
      const filters: ISlaGetFilter = {
        sortBy,
        sortOrder,
        offset,
        limit: DEFAULT_LIMIT
      };
      if (bottlerId) {
        filters.bottlerId = bottlerId;
      }
      if (equipmentProblemId) {
        filters.equipmentProblemId = equipmentProblemId;
      }
      if (equipmentTypeId) {
        filters.equipmentTypeId = equipmentTypeId;
      }
      if (filterSla) {
        filters.sla = filterSla;
      }
      const res = await getSlas(filters);
      setDate(res.slas);
      setCount(res.count);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchEquipmentProblems = async () => {
    try {
      const res = await getEquipmentProblems();
      // grab
      const cokeServiceProblemCodes = res.filter(code => code.bottlerId === 0).filter((item, index, self) => index === self.findIndex(t => t.problemCode === item.problemCode));
      const nativeProblemCodes = res.filter(code => code.bottlerId !== 0).filter((item, index, self) => index === self.findIndex(t => t.problemCode === item.problemCode));
      setEquipmentProblems([...cokeServiceProblemCodes, ...nativeProblemCodes]);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const fetchEquipmentProblemsByEquipment = async () => {
    try {
      const cokeServiceProblemCodes = await getEquipmentProblemsByEquipmentId(Number(newEquipmentTypeId), 0);
      const res = await getEquipmentProblemsByEquipmentId(Number(newEquipmentTypeId), newBottlerId as number);
      const custProblemCodes = cokeServiceProblemCodes.map(code => code.problemCode);
      // need to loop through the bottler problem codes and remove any duplicates that already exist
      // in the coke service problem codes request for bottlerId=0
      const uniqueProblemCodes = res
        .map(code => {
          if (custProblemCodes.includes(code.problemCode)) {
            return null;
          }
          return code;
        })
        .filter(Boolean) as IEquipmentProblem[];
      setEquipmentProblemsByEquipment([...cokeServiceProblemCodes, ...uniqueProblemCodes]);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const fetchEquipmentTypes = async () => {
    try {
      const res = await getEquipmentTypes();
      // filter out equipment types that aren't used in the Coke Service PWA
      setEquipmentTypes(res.filter(type => !filteredEquipmentTypes.includes(type.description)));
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const fetchSlaOptions = async () => {
    try {
      const res = await getSlas({ distinct: true });
      setSlaOptions(res.slas);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const resetFilters = () => {
    setSelectedProblem(null);
    setSelectedEquipmentType(null);
    setSelectedSla(null);
    setSelectedBottler(null);
  };

  const resetNewForm = () => {
    setSlaInput("");
    setNewEquipmentProblem(null);
    setNewSelectedEquipmentType(null);
    setNewBottlerId(null);
    setEquipmentProblemsByEquipment(null);
  };

  useEffect(() => {
    getBottlers(RequestTypes.EquipmentRepair);
    fetchEquipmentProblems();
    fetchEquipmentTypes();
    fetchSlaOptions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (newEquipmentTypeId) {
      fetchEquipmentProblemsByEquipment();
    }
    // eslint-disable-next-line
  }, [newEquipmentTypeId]);

  useEffect(() => {
    fetchSlas();
    // eslint-disable-next-line
  }, [sortBy, sortOrder, offset, bottlerId, equipmentTypeId, filterSla, equipmentProblemId]);

  return (
    <>
      <Page permissions={true} title="Coke Service - SLA Management">
        <P italic>The default SLA is 48 hours. If SLA is not provided below, then 48 hours will be assumed.</P>
        <div className="slaManagementSelectWrapper">
          <Select
            id="bottlers"
            name="bottlers"
            showPlaceholder
            onChange={e => setSelectedBottler(e.target.value)}
            classNameWrapper="slaManagementSelect"
            placeholder="Filter by Bottler"
            value={`${bottlerId}`}
            options={
              bottlers
                ? bottlers.map((bottler, index: number) => (
                    <option key={`${index}`} value={bottler.id}>
                      {bottler.name}
                    </option>
                  ))
                : []
            }
          />
          <Select
            id="problem-descriptions"
            name="problem-descriptions"
            onChange={e => setSelectedProblem(e.target.value)}
            showPlaceholder
            classNameWrapper="slaManagementSelect"
            placeholder="Filter by Problem Description"
            value={`${equipmentProblemId}`}
            options={equipmentProblems.map((problem, index: number) => (
              <option key={`${index}`} value={problem.id}>
                {problem.bottlerId === 0 ? `${problem.problemCode} - ${problem.description} - ${problem.equipmentType}` : problem.description}
              </option>
            ))}
          />
          <Select
            id="sla-select"
            name="sla-select"
            onChange={e => setSelectedSla(e.target.value)}
            showPlaceholder
            classNameWrapper="slaManagementSelect"
            placeholder="Filter by SLA"
            value={`${filterSla}`}
            options={slaOptions.map((option, index: number) => (
              <option key={`${index}`} value={option.sla}>
                {option.sla}
              </option>
            ))}
          />
          <Select
            id="equipment-type-options"
            name="equipment-type-options"
            onChange={e => setSelectedEquipmentType(e.target.value)}
            showPlaceholder
            classNameWrapper="slaManagementSelect"
            placeholder="Filter by Equipment Type"
            value={`${equipmentTypeId}`}
            options={equipmentTypes.map((equipmentType, index: number) => (
              <option key={`${index}`} value={equipmentType.id}>
                {title(equipmentType.description)}
              </option>
            ))}
          />
        </div>
        {(equipmentTypeId || bottlerId || equipmentProblemId || filterSla) && (
          <Button className="slaManagementResetButton" id="reset-filters" title="Reset Filters" text="Reset Filters" onClick={() => resetFilters()} />
        )}
        <div className="slaManagementButtonWrapper">
          <Modal
            staticBodyContent={
              <div>
                <Input
                  id="update-sla-hours"
                  name="update-sla-hours"
                  value={slaValue}
                  onChange={e => setSlaInput(e.target.value.replace(/[^0-9]/g, ""))}
                  placeholder="SLA (in hours)"
                />
              </div>
            }
            showCancel
            showSave
            bodyClassName="slaUpdateModalWrapper"
            saveText="Save"
            modalSize="Xxs"
            id="sla-update-modal"
            heading="Update SLA"
            openClassName="buttonSave"
            openText="Update"
            openTitle="Update"
            openIcon="Save"
            handleCancel={() => {
              const body: HTMLElement = document.getElementById("body") as HTMLElement;
              if (slaValue) {
                const result = window.confirm("You have unsaved changes. Are you sure you want to close this modal?");
                if (result) {
                  body.classList.remove("modalOpen");
                  setUpdateModalOpen(false);
                  return setSlaInput("");
                }
                return;
              }
              body.classList.remove("modalOpen");
              setUpdateModalOpen(false);
            }}
            handleOpen={() => {
              const body: HTMLElement = document.getElementById("body") as HTMLElement;
              body.classList.add("modalOpen");
              setUpdateModalOpen(true);
            }}
            isOpen={isModalUpdateOpen}
            handleSave={async () => {
              try {
                const promises = selectedSlas.map(
                  async sla =>
                    await updateSla(sla.id, {
                      bottlerId: sla.bottlerId,
                      equipmentProblemId: sla.equipmentProblemId,
                      sla: Number(slaValue)
                    })
                );
                await Promise.all(promises);
                toast.success("SLA updated", {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                const body: HTMLElement = document.getElementById("body") as HTMLElement;
                body.classList.remove("modalOpen");
                // refetch the table data
                fetchSlas();
                // refetch the sla filter options
                fetchSlaOptions();
                setUpdateModalOpen(false);
                setSlaInput("");
                // reset any selected items
                setSelectedSlas([]);
              } catch (error) {
                toast.error(error.message, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
              }
            }}
            saveDisabled={!slaValue}
            disabled={selectedSlas.length === 0}
          />
          <Button
            className="buttonWhite"
            iconLeft={<Icon iconName="Delete" />}
            disabled={selectedSlas.length === 0 || isDeleting}
            onClick={async () => {
              const result = window.confirm("Are you sure you want to delete this sla?");
              if (result) {
                try {
                  setDeleting(true);
                  const promises = selectedSlas.map(async sla => await deleteSlas(sla.id));
                  await Promise.all(promises);
                  toast.success("SLA deleted", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                  // refetch the table data
                  fetchSlas();
                  // refetch the sla filter options
                  fetchSlaOptions();
                  // reset any selected items
                  setSelectedSlas([]);
                } catch (error) {
                  toast.error(error.message, {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                } finally {
                  setDeleting(false);
                }
              }
            }}
            text="Delete"
            title="Delete SLA"
          />
        </div>
        <TableWrapper loading={isLoading || isDeleting}>
          <Table>
            <THead>
              <Tr>
                {headerColumns.map((column, index: number) => {
                  if (column.appIds.includes(appId)) {
                    const ascending: boolean = (column.sortBy === sortBy && sortOrder === "ASC") || false;
                    const descending: boolean = (column.sortBy === sortBy && sortOrder === "DESC") || false;
                    return (
                      <Th key={`headerColumn-${index}`} text={column.text} title={column.title}>
                        {column.sortBy && (
                          <Sort
                            text={column.title}
                            ascending={ascending}
                            descending={descending}
                            onClick={() => {
                              if (sortOrder === "ASC" && column.sortBy === sortBy) {
                                setOrder("DESC");
                              }
                              if (sortOrder === "DESC" && column.sortBy === sortBy) {
                                setOrder("ASC");
                              }
                              if (column.sortBy !== sortBy) {
                                setOrder("DESC");
                              }
                              setSortBy(column.sortBy);
                              // reset any selected items if you change sort
                              setSelectedSlas([]);
                            }}
                            disabled={data.length === 0}
                          />
                        )}
                      </Th>
                    );
                  }
                })}
              </Tr>
            </THead>
            <TBody>
              {data.length > 0 ? (
                data.map((item: ISla, index: number) => {
                  const selectedOption = selectedSlas.find(selectedSla => selectedSla.id === item.id);
                  return (
                    <Tr hover={true} key={`sla-${index}`} striped={true}>
                      <Td>
                        <Checkbox
                          checked={!!selectedOption}
                          id={`sla-${index}`}
                          showLabel={false}
                          text="Select SLA"
                          title="Select SLA"
                          name={`${item.id}`}
                          onChange={() =>
                            selectedOption
                              ? setSelectedSlas(selectedSlas.filter(selectedSla => selectedSla.id !== item.id))
                              : setSelectedSlas([
                                  ...selectedSlas,
                                  {
                                    id: item.id as number,
                                    bottlerId: item.bottlerId as number,
                                    equipmentProblemId: item.equipmentProblemId as number,
                                    sla: item.id as number
                                  }
                                ])
                          }
                        />
                      </Td>
                      <Td>{item.bottler && item.bottler.name}</Td>
                      <Td>{item.equipmentProblem && title(item.equipmentProblem.equipmentType.description)}</Td>
                      <Td>{item.equipmentProblem && item.equipmentProblem.description}</Td>
                      <Td>{item.equipmentProblem && item.equipmentProblem.problemCode}</Td>
                      <Td>{item.sla}</Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td colSpan={headerColumns.length}>
                    <Alert show={true} type="Info">
                      No SLA entries to display. Click on the "Add New" button to add a new SLA.
                    </Alert>
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
        </TableWrapper>
        <Modal
          showCancel
          showSave
          saveText="Save"
          modalSize="Xxs"
          id="sla-add-modal"
          heading="Add SLA"
          openClassName="buttonInfo"
          openText="Add New"
          openTitle="Add New"
          openIcon="Add"
          staticBodyContent={
            <div>
              <div className="field">
                <Select
                  id="bottlers"
                  name="bottlers"
                  showPlaceholder
                  onChange={e => setNewBottlerId(e.target.value)}
                  placeholder="Bottler"
                  value={`${newBottlerId}`}
                  options={
                    bottlers
                      ? bottlers.map((bottler, index: number) => (
                          <option key={`${index}`} value={bottler.id}>
                            {bottler.name}
                          </option>
                        ))
                      : []
                  }
                />
              </div>
              <div className="field">
                <Select
                  id="new-equipment-type-options"
                  name="new-equipment-type-options"
                  onChange={e => setNewSelectedEquipmentType(e.target.value)}
                  showPlaceholder
                  placeholder="Equipment Type"
                  value={`${newEquipmentTypeId}`}
                  options={equipmentTypes.map((equipmentType, index: number) => (
                    <option key={`${index}`} value={equipmentType.id}>
                      {title(equipmentType.description)}
                    </option>
                  ))}
                />
              </div>
              {equipmentProblemsByEquipment && (
                <div className="field">
                  <Select
                    id="new-problem-descriptions"
                    name="new-problem-descriptions"
                    onChange={e => setNewEquipmentProblem(e.target.value)}
                    showPlaceholder
                    placeholder="Problem Description"
                    value={`${newEquipmentProblemId}`}
                    options={
                      equipmentProblemsByEquipment &&
                      equipmentProblemsByEquipment.map((problem, index: number) => (
                        <option key={`${index}`} value={problem.id}>
                          {problem.description}
                        </option>
                      ))
                    }
                  />
                </div>
              )}
              <div className="field">
                <Input id="add-sla-hours" name="sla-hours" onChange={e => setSlaInput(e.target.value.replace(/[^0-9]/g, ""))} value={slaValue} placeholder="SLA (in hours)" />
              </div>
            </div>
          }
          isOpen={isModalAddOpen}
          handleOpen={e => {
            const body: HTMLElement = document.getElementById("body") as HTMLElement;
            body.classList.add("modalOpen");
            setAddModalOpen(true);
          }}
          handleCancel={() => {
            const body: HTMLElement = document.getElementById("body") as HTMLElement;
            if (slaValue || newBottlerId || newEquipmentTypeId || newEquipmentProblemId) {
              const result = window.confirm("You have unsaved changes. Are you sure you want to close this modal?");
              if (result) {
                body.classList.remove("modalOpen");
                setAddModalOpen(false);
                return resetNewForm();
              }
              return;
            }
            body.classList.remove("modalOpen");
            setAddModalOpen(false);
          }}
          saveDisabled={!slaValue || !newBottlerId || !newEquipmentTypeId || !newEquipmentProblemId}
          handleSave={async () => {
            try {
              await createSla({
                bottlerId: newBottlerId as number,
                equipmentProblemId: Number(newEquipmentProblemId),
                sla: Number(slaValue)
              });
              toast.success("SLA created", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              const body: HTMLElement = document.getElementById("body") as HTMLElement;
              body.classList.remove("modalOpen");
              setAddModalOpen(false);
              fetchSlas();
              // refetch the sla filter options
              fetchSlaOptions();
              resetNewForm();
            } catch (error) {
              toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER
              });
            }
          }}
        />
        <div className="field">
          <Pagination count={count} loading={isLoading} page={offset / DEFAULT_LIMIT + 1} perPage={DEFAULT_LIMIT} paginate={(e, page) => setOffset((page - 1) * DEFAULT_LIMIT)} />
        </div>
      </Page>
    </>
  );
};

export default connect(
  (state: any) => ({
    bottlers: state.bottlers.bottlers
  }),
  {
    getBottlers: getBottlersCreator
  }
)(withPageLog(SLAManagement));
