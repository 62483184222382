import * as React from "react";

export interface IUlProps {
  className?: string;
  listItems: JSX.Element[];
}

export interface IUlState {}

export default class Ul extends React.Component<IUlProps, IUlState> {
  public render(): React.ReactElement<IUlProps> {
    const { className, listItems } = this.props;

    const cssClass: string = className ? "ul " + className : "ul";

    return <ul className={cssClass}>{listItems}</ul>;
  }
}
