/**
 * Escape characters for exporting to csv
 * @param cell string
 * @returns string
 */
export function sanitizeCsvString(cell: string | null | undefined): string {
  // check if string is empty, null, or undefined
  if (cell) {
    // escape double quotes
    if (cell.indexOf('"') !== -1) {
      cell = cell.replace(/"/g, '""');
    }
    // escape commas
    if (cell.match(/"|,/)) {
      cell = '"' + cell + '"';
    }
    // replace new line with space
    if (cell.indexOf("\n") !== -1) {
      cell = cell.replace(/\n/g, " ");
    }
    // replace new line with space
    if (cell.indexOf("\r") !== -1) {
      cell = cell.replace(/\r/g, " ");
    }
    // replace new line with space
    if (cell.indexOf("\r\n") !== -1) {
      cell = cell.replace(/\r\n/g, " ");
    }
    // replace unicode null \u0000
    // TODO: figure out how this character got added by React Native
    // maybe replace other unicode characters? http://www.endmemo.com/unicode/ascii.php
    if (cell.indexOf("\u0000") !== -1) {
      cell = cell.replace(/\u0000/g, "");
    }
    return cell;
  } else {
    return "";
  }
}
