import * as React from "react";

export interface IOnFormSubmit {
  (e: React.FormEvent<HTMLFormElement>): void;
}

export interface IFormProps {
  className?: string;
  onSubmit?: IOnFormSubmit;
}

class Form extends React.Component<IFormProps, {}> {
  public render(): React.ReactElement<IFormProps> {
    const { className, onSubmit } = this.props;

    const cssClass: string = className ? "form " + className : "form";

    return (
      <form className={cssClass} onSubmit={onSubmit}>
        {this.props.children}
      </form>
    );
  }
}

export default Form;
