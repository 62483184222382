import * as React from "react";
// components
import Table from "./Table";
import Pagination from "../pagination/Pagination";
import TableWrapper from "./TableWrapper";

interface ITablePaginationProps {
  className?: string;
  count: number;
  children: any;
  isLoading: boolean;
  pageNumber: number;
  perPage?: number;
  handlePaginateChange: Function;
}

const TablePagination = ({
  className,
  count,
  children,
  isLoading,
  pageNumber,
  perPage = 10,
  handlePaginateChange
}: ITablePaginationProps) => {
  return (
    <>
      <TableWrapper loading={isLoading}>
        <div className={className}>
          <Table className="tableEllipsis">{children}</Table>
        </div>
      </TableWrapper>
      <div className="field">
        <Pagination
          count={count}
          loading={isLoading}
          page={pageNumber}
          perPage={perPage}
          paginate={(e, page) => handlePaginateChange(page)}
        />
      </div>
    </>
  );
};

export default TablePagination;
