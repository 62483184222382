import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";
import Button from "../button/Button";
import Required from "./Required";

export interface IInputProps {
  autoFocus?: boolean;
  autoComplete?: string;
  autoCorrect?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  id: string;
  inputType?: string;
  min?: string;
  minLength?: number;
  max?: string;
  maxLength?: number;
  name: string;
  onBlur?: any;
  onFocus?: any;
  onChange?: any;
  onKeyUp?: any;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  refs?: any;
  required?: boolean;
  showRequired?: boolean;
  step?: string;
  tabIndex?: number;
  value?: string;
  showValidation?: boolean;
  showTogglePassword?: boolean;
}

export interface IInputState {
  showPassword: boolean;
}

export default class Input extends React.Component<IInputProps, IInputState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showPassword: false
    };
    this._toggleShowPassword = this._toggleShowPassword.bind(this);
  }
  public render(): React.ReactElement<IInputProps> {
    let className: string = "input";
    if (this.props.showValidation) {
      className += " inputRequired";
    }
    if (this.props.showTogglePassword) {
      className += " inputShowPassword";
    }
    if (this.props.className) {
      className = this.props.className;
    }
    const showRequired: JSX.Element | null = this.props.showRequired ? (
      <Required />
    ) : null;
    const showTogglePassword: JSX.Element | null = this.props
      .showTogglePassword ? (
      <Button
        className="button buttonTogglePassword"
        disabled={this.props.disabled}
        hideText={true}
        iconLeft={
          this.state.showPassword ? (
            <Icon iconName="Hide" />
          ) : (
            <Icon iconName="RedEye" />
          )
        }
        onClick={e => this._toggleShowPassword(e)}
        text={this.state.showPassword ? "Hide Password" : "Show Password"}
        title={this.state.showPassword ? "Hide Password" : "Show Password"}
      />
    ) : null;
    const inputWrapper: string = this.props.showRequired
      ? "inputWrapperRequired"
      : "inputWrapper";
    return (
      <div className={inputWrapper}>
        <input
          autoFocus={this.props.autoFocus}
          autoComplete={this.props.autoComplete}
          autoCorrect={this.props.autoCorrect}
          className={className}
          defaultValue={
            this.props.defaultValue ? this.props.defaultValue : undefined
          }
          disabled={this.props.disabled}
          id={this.props.id}
          type={this.state.showPassword ? "text" : this.props.inputType}
          min={this.props.min}
          minLength={this.props.minLength}
          max={this.props.max}
          maxLength={this.props.maxLength}
          name={this.props.name}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onKeyUp={this.props.onKeyUp}
          pattern={this.props.pattern}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          ref={this.props.refs}
          required={this.props.required}
          step={this.props.step}
          tabIndex={this.props.tabIndex}
          value={this.props.value ? this.props.value : ""}
        />
        {showRequired}
        {showTogglePassword}
      </div>
    );
  }
  private _toggleShowPassword(e: any): void {
    e.preventDefault();
    this.setState({
      showPassword: this.state.showPassword ? false : true
    });
  }
}
