import * as React from "react";
import Band from "../../../components/layout/Band";
import Section from "../../../components/layout/Section";
import H1 from "../../../components/typography/H1";
import Hr from "../../../components/typography/Hr";
import Permissions from "../../permissions/Permissions";

class HorizontalRules extends React.Component {
  public render() {
    const title: string = "Design System: Horizontal Rules";

    return (
      <div className="typography">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <Hr />
          </Permissions>
        </Section>
      </div>
    );
  }
}

export default HorizontalRules;
