import * as React from "react";

class Wrap extends React.Component {
  public render() {
    return (
      <div className="wrapper" id="wrapper">
        <div className="wrap" id="wrapper-inner">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Wrap;
