import { IRequestTypeFilter } from "../types/RequestType";
import { IUser } from "../types/User";
import { uniq } from "lodash";

// RequestDetail
export function isHrRequest(requestTypeId: number | null): boolean {
  let hrRequest: boolean = false;
  if (
    requestTypeId &&
    (requestTypeId === 1 || requestTypeId === 2 || requestTypeId === 3)
  ) {
    hrRequest = true;
  }
  return hrRequest;
}
export function getRequestTypeIds(
  requestTypeFilters: IRequestTypeFilter[],
  user: IUser
): number[] {
  let requestTypes: number[] = [];
  user.requestTypes.map(requestType => {
    requestTypeFilters.map(rtf => {
      rtf.requestTypes.map(rt => {
        if (requestType.RequestTypeID === rt.id) {
          requestTypes.push(rt.id);
        }
      });
    });
  });
  return uniq(requestTypes);
}
