import * as React from "react";
import { toast } from "react-toastify";
// components
import Alert from "../alert/Alert";
import LoaderWrapper from "../loader/LoaderWrapper";
import Loader from "../loader/Loader";
import Search from "../search/Search";
import Stat from "../stat/Stat";
// services
import { getRequestAuditsById } from "../../services/RequestAudits";
// types
import { IRequestAudit } from "../../types/RequestAudits";
// helpers
import { shortFriendlyDateTime, shortFileDateTime } from "../../helpers/time";
import { sanitizeCsvString } from "../../helpers/csv";
import zipcelx, { Config, Sheet, Row, Data } from "../../helpers/zipcelx";

interface IRequestAuditListProps {
  requestId: number;
  userName: string;
  createdAt: string;
  bottlerName: string;
}

interface IRequestAuditListState {
  isLoading: boolean;
  requestAudits: IRequestAudit[];
  searchTerm: string;
}

class RequestAuditList extends React.PureComponent<IRequestAuditListProps, IRequestAuditListState> {
  timer;

  state = {
    isLoading: true,
    requestAudits: [],
    searchTerm: ""
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { requestId } = this.props;
    const { requestAudits, isLoading, searchTerm } = this.state;
    return (
      <div className="requestAudits">
        <LoaderWrapper>
          <Search
            autoFocus={false}
            onSearchChange={e => this.handleSearch(e.target.value)}
            placeholder="Search history..."
            search={searchTerm}
            searchDisabled={isLoading || (requestAudits.length === 0 && searchTerm.length === 0)}
            searchLabelText="Search History"
            showExport={true}
            exportText="Export History"
            exportTitle="Export History"
            exportDisabled={requestAudits.length === 0}
            showSearchButton={false}
            onExportButtonLinkClick={e => this.handleExport(e)}
          />
          <ul className="requestAuditsList">
            {requestAudits.map((audit: IRequestAudit, index: number) => {
              return (
                <li className="requestAuditsListItem" key={index}>
                  <div className="requestAudit">
                    <Stat title="Changed At" value={shortFriendlyDateTime(audit.createdAt)} />
                    <Stat title="Changed By" value={audit.userName} />
                    <Stat title="Event" value={audit.requestAuditTypeName} />
                    <Stat title="Changed From" value={audit.changedFrom} />
                    <Stat title="Changed To" value={audit.changedTo} />
                  </div>
                </li>
              );
            })}
          </ul>

          {requestAudits.length === 0 && !isLoading && (
            <Alert show={true} type="Info">
              No Request History for {searchTerm.length > 0 ? <q>{searchTerm}</q> : `#${requestId}`}.{" "}
              {searchTerm.length > 0 && (
                <a href="#" onClick={e => this.setState({ searchTerm: "" }, this.fetchData)}>
                  Undo your search
                </a>
              )}
            </Alert>
          )}

          {isLoading && <Loader loading={isLoading} type="Inline" position="Centered" showImage={isLoading} text="Loading history..." />}
        </LoaderWrapper>
      </div>
    );
  }

  fetchData = async () => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }
    const { requestId } = this.props;
    const { searchTerm } = this.state;
    try {
      const { requestAudits } = await getRequestAuditsById(requestId, searchTerm);
      this.setState({
        isLoading: false,
        requestAudits
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({ isLoading: false });
    }
  };

  handleSearch = (searchTerm: string) => {
    clearTimeout(this.timer);

    this.setState(
      {
        searchTerm
      },
      () => {
        this.timer = setTimeout(() => {
          this.fetchData();
        }, 300);
      }
    );
  };

  handleExport = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { requestId, userName, createdAt, bottlerName } = this.props;
    const { requestAudits } = this.state;

    const data: Data = [];

    const header: Row = [
      {
        value: "Changed At",
        type: "string"
      },
      {
        value: "Changed By",
        type: "string"
      },
      {
        value: "Event",
        type: "string"
      },
      {
        value: "Changed From",
        type: "string"
      },
      {
        value: "Changed To",
        type: "string"
      }
    ];

    data.push(header);

    requestAudits.map((audit: IRequestAudit) => {
      data.push([
        {
          value: sanitizeCsvString(shortFriendlyDateTime(audit.createdAt)),
          type: "string"
        },
        {
          value: sanitizeCsvString(audit.userName),
          type: "string"
        },
        {
          value: sanitizeCsvString(audit.requestAuditTypeName),
          type: "string"
        },
        {
          value: sanitizeCsvString(audit.changedFrom),
          type: "string"
        },
        {
          value: sanitizeCsvString(audit.changedTo),
          type: "string"
        }
      ]);
    });

    const filename = `History_Request_${requestId}_${bottlerName}_${userName}_${shortFileDateTime(createdAt)}`;

    const sheet: Sheet = {
      data
    };

    const config: Config = {
      filename,
      sheet
    };

    zipcelx(config);
  };
}

export default RequestAuditList;
