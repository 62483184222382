import { getRequest } from "../core/api/requestConfig";
import { IEquipmentType } from "../types/EquipmentTypes";

export const getEquipmentTypes = async (): Promise<IEquipmentType[]> => {
  try {
    const res = await getRequest(`/api/v1/equipmentTypes`);
    return Promise.resolve(res.equipmentTypes);
  } catch (error) {
    return Promise.reject(error);
  }
};
