import * as React from "react";
import logo from "../../images/logo/logo-circle-color.svg";

export interface ILogoRoundProps {}

export default class LogoRound extends React.Component<ILogoRoundProps, {}> {
  public render(): React.ReactElement<ILogoRoundProps> {
    const alt: string = "Coca-Cola Business Services North America";
    return <img className="logoRound" src={logo} alt={alt} title={alt} />;
  }
}
