export const Columns = [
  {
    id: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "id",
      excel: "",
      full: "ID",
      short: "ID",
      type: "number",
      step: "1",
      min: "1",
      required: false,
      hidden: true,
      merging: true,
      checking: true,
      exported: false
    }
  },
  {
    bottlerEmployeeId: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "bottlerEmployeeId",
      excel: "",
      full: "Bottler Employee ID",
      short: "Bottler Employee ID",
      type: "number",
      step: "1",
      min: "1",
      required: false,
      hidden: true,
      merging: true,
      checking: true,
      exported: false
    }
  },
  {
    firstName: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "firstName",
      excel: "FirstName",
      full: "First Name",
      short: "First",
      type: "string",
      required: true,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    middleIntial: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "middleInitial",
      excel: "MiddleInitial",
      full: "Middle Initial",
      short: "MI",
      type: "string",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    lastName: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "lastName",
      excel: "LastName",
      full: "Last Name",
      short: "Last",
      type: "string",
      required: true,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    suffix: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "suffix",
      excel: "Suffix",
      full: "Suffix",
      short: "Suffix",
      type: "string",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    nickname: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "nickname",
      excel: "Nickname",
      full: "Nickname",
      short: "Nickname",
      type: "string",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    pernr: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "pernr",
      excel: "PERNR",
      full: "PERNR",
      short: "PERNR",
      type: "string",
      required: true,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    wageTypeId: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "wageTypeId",
      excel: "WageTypeId",
      full: "Wage Type ID",
      short: "Wage Type ID",
      type: "number",
      step: "1",
      min: "1",
      required: false,
      hidden: true,
      merging: true,
      checking: true,
      exported: false
    }
  },
  {
    wageType: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "wageType",
      excel: "WageType",
      full: "Wage Type",
      short: "Wage Type",
      type: "string",
      required: true,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    hours: {
      IT0014: false,
      IT0015: false,
      IT2010: true,
      database: "hours",
      excel: "Hours",
      full: "Hours",
      short: "Hours",
      type: "string",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    number: {
      IT0014: false,
      IT0015: false,
      IT2010: true,
      database: "number",
      excel: "Number",
      full: "Number",
      short: "Number",
      type: "number",
      step: "0.25",
      min: "0",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    units: {
      IT0014: false,
      IT0015: false,
      IT2010: true,
      database: "units",
      excel: "Units",
      full: "Units",
      short: "Units",
      type: "string",
      step: "0.25",
      min: "0",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    amount: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "amount",
      excel: "Amount",
      full: "Amount",
      short: "Amount",
      type: "number",
      step: "0.01",
      min: "0",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    rate: {
      IT0014: false,
      IT0015: false,
      IT2010: true,
      database: "rate",
      excel: "Rate",
      full: "Rate",
      short: "Rate",
      type: "number",
      step: "0.25",
      min: "0",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    dollarLimit: {
      IT0014: true,
      IT0015: false,
      IT2010: false,
      database: "dollarLimit",
      excel: "DollarLimit",
      full: "Dollar Limit",
      short: "Dollar Limit",
      type: "number",
      step: "0.01",
      min: "0",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    costCenter: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "costCenter",
      excel: "CostCenter",
      full: "Cost Center",
      short: "Cost Center",
      type: "string",
      required: true,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    authority: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "authority",
      excel: "Authority",
      full: "Authority",
      short: "Authority",
      type: "string",
      required: true,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    comment: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "comment",
      excel: "Comment",
      full: "Comment",
      short: "Comment",
      type: "string",
      required: false,
      hidden: false,
      merging: true,
      checking: true,
      exported: true
    }
  },
  {
    isMerged: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "isMerged",
      excel: "",
      full: "Is Merged",
      short: "Is Merged",
      type: "boolean",
      required: false,
      hidden: true,
      merging: false,
      checking: false,
      exported: false
    }
  },
  {
    removeTask: {
      IT0014: true,
      IT0015: true,
      IT2010: true,
      database: "removeTask",
      excel: "",
      full: "",
      short: "",
      type: "",
      required: false,
      hidden: false,
      merging: false,
      checking: false,
      exported: false
    }
  }
];
