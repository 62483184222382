import * as React from "react";
import LoaderImage from "./LoaderImage";

export interface ILoaderProps {
  centered?: boolean;
  deleting?: boolean;
  loading?: boolean;
  position?: "Top" | "Centered";
  saving?: boolean;
  searching?: boolean;
  showImage?: boolean;
  text?: string;
  type?: "Overlay" | "Inline";
}

export default class Loader extends React.Component<ILoaderProps, {}> {
  public render(): React.ReactElement<ILoaderProps> {
    const { deleting, loading, position, saving, searching, showImage, text, type } = this.props;

    const t: string = text ? text : deleting ? "Deleting..." : saving ? "Saving..." : searching ? "Searching..." : "Loading...";

    let cssClass: string = "loader";

    if (position) {
      cssClass += ` loader${position}`;
    }

    if (type) {
      cssClass += ` loader${type}`;
    }

    if (showImage) {
      cssClass += " hasLoaderImage";
    }

    if (!deleting && !loading && !saving && !searching) {
      cssClass = "displayNone";
    }

    return (
      <div className={cssClass}>
        {showImage && <LoaderImage />}
        <p className="loaderText">{t}</p>
      </div>
    );
  }
}
