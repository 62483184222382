import moment from "moment-timezone";
import { SHORT_FRIENDLY, fullTime, SHORT_DATE } from "./time";

/**
 * Returns equipment repair request createdAt in friendly string format
 * @param time string | number
 * @param timezone string
 * @returns string
 */
export const createdAtFriendly = (time: string, timezone: string): string => {
  const abbr: string = moment()
    .tz(timezone)
    .format("z"); // EST, EDT, etc.

  const momentObject: moment.Moment = moment(time);
  const dateTime: string = momentObject
    .utc()
    .tz(timezone)
    .format(SHORT_FRIENDLY);

  return `${dateTime} ${abbr}`;
};

/**
 * Returns equipment repair request createdAt in ISO format
 * @param time string | number
 * @param timezone string
 * @returns string
 */
export const createdAtIso = (time: string, timezone: string): string => {
  const momentObject: moment.Moment = moment(time);
  const isoString: string = momentObject.utc().toISOString();

  const adjusted: string = moment(isoString)
    // @ts-ignore
    .tz(timezone)
    .format();

  return adjusted;
};

/**
 * Convert an ISO string to a date
 * @param isoString string
 * @param timezone string
 * @returns string
 */
export const createdAtDate = (isoString: string, timezone: string): string => {
  const momentObject: moment.Moment = moment(isoString);
  const date: string = momentObject
    .utc()
    .tz(timezone)
    .format(SHORT_DATE);

  return date;
};

/**
 * Convert an ISO string to time + timezone
 * @param isoString string
 * @param timezone string
 * @returns string
 */
export const createdAtTime = (isoString: string, timezone: string): string => {
  const abbr: string = moment()
    .tz(timezone)
    .format("z"); // EST, EDT, etc.

  const time = fullTime(isoString);
  return `${time} ${abbr}`;
};

/**
 * Returns outlet open hours
 * @param startTime string | null
 * @param endTime string | null
 * @example 6:00am-6:00pm
 * @returns string
 */
export const outletHours = (startTime: string | null, endTime: string | null): string => {
  return startTime && endTime ? `${startTime}-${endTime}` : startTime && !endTime ? startTime : !startTime && endTime ? endTime : ``;
};
