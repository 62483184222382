import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withPageLog } from "../logging/LogComponentChange";
import SelfServiceRequestsList from "./SelfServiceRequestsList";
import ServicesAppGroups from "./ServicesAppGroups";
import BottlerAppUsers from "./BottlerAppUsers";
import PowerBIMembers from "./PowerBIMembers";
import { AdminManagement } from "./AdminManagement";
import AuditLogReport from "./AuditLogReport";

export interface IGroupMembershipProps {}

class GroupMembership extends React.Component<IGroupMembershipProps> {
  public render() {
    return (
      <div className="groupMembership">
        <Switch>
          <Route exact={true} path="/group-membership/services-app-groups" component={ServicesAppGroups} />
          <Route exact={true} path="/group-membership/bottler-app-users" component={BottlerAppUsers} />
          <Route exact={true} path="/group-membership/power-bi-members" component={PowerBIMembers} />
          <Route exact={true} path="/group-membership/self-service-requests" component={SelfServiceRequestsList} />
          <Route exact={true} path="/group-membership/bsna-admin-management" component={AdminManagement} />
          <Route exact={true} path="/group-membership/audit-log-report" component={AuditLogReport} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(withPageLog(GroupMembership) as any);
