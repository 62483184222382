import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
// components
import Button from "../../components/button/Button";
import Checkbox from "../../components/input/Checkbox";
import P from "../../components/typography/P";
// helpers
import { formatName, formatEmail } from "../../helpers/format";
// types
import { IMSGraphUserEnhanced } from "../../types/User";

interface IAddUsersListProps {
  removing: boolean;
  msGraphUsers: IMSGraphUserEnhanced[];
  handleSendEmailChange: Function;
  removeUser: Function;
}

const AddUsersList = ({ removing, msGraphUsers, handleSendEmailChange, removeUser }: IAddUsersListProps) => {
  return (
    <>
      {msGraphUsers.map((msGraphUser, index) => {
        const name = formatName(msGraphUser.displayName, msGraphUser.givenName, msGraphUser.surname);
        const email = formatEmail(msGraphUser.mail, msGraphUser.userPrincipalName);

        if (msGraphUser.invite) {
          return (
            <li className="addNewUsersAddedListItem" key={msGraphUser.id}>
              <Icon className="addNewUserIcon" iconName="CalculatorAddition" />
              <P className="addNewUsersName">{msGraphUser.displayName ? msGraphUser.displayName : ""}</P>
              <P className="addNewUsersEmail">({msGraphUser.mail ? msGraphUser.mail : ""})</P>
              <Checkbox
                checked={msGraphUser.sendEmail ? msGraphUser.sendEmail : false}
                id={`send-invite-${index}`}
                name={`send-invite-${index}`}
                onChange={e => handleSendEmailChange(e, index)}
                text="Send Invite Email"
              />
              <Button
                className="colorRed paddingNone"
                disabled={removing}
                iconLeft={<Icon iconName="Delete" />}
                onClick={e => removeUser(index)}
                text="Remove"
                title="Remove"
              />
            </li>
          );
        } else {
          return (
            <li className="addNewUsersAddedListItem" key={msGraphUser.id}>
              <Icon className="addNewUserIcon" iconName="CalculatorAddition" />
              <P className="addNewUsersName">{name}</P>
              <P className="addNewUsersEmail">({email})</P>
              <Button
                className="colorRed paddingNone"
                disabled={removing}
                iconLeft={<Icon iconName="Delete" />}
                onClick={e => removeUser(index)}
                text="Remove"
                title="Remove"
              />
            </li>
          );
        }
      })}
    </>
  );
};

export default AddUsersList;
