import * as React from "react";
import moment from "moment";
import { Icon } from "office-ui-fabric-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
// components
import Alert from "../../../components/alert/Alert";
import BottlerSwitcher from "../../../components/switcher/BottlerSwitcher";
import Button from "../../../components/button/Button";
import DateRangePicker from "../../../components/input/DateRangePicker";
import HeaderColumns from "../../../components/table/HeaderColumns";
import Hr from "../../../components/typography/Hr";
import Loader from "../../../components/loader/Loader";
import LoaderWrapper from "../../../components/loader/LoaderWrapper";
import LoaderTable from "../../../components/loader/LoaderTable";
import Page from "../../../components/page/Page";
import Pagination from "../../../components/pagination/Pagination";
import RefreshGroup from "../../../components/refresh/Group";
import Table from "../../../components/table/Table";
import Tr from "../../../components/table/Tr";
import Td from "../../../components/table/Td";
import THead from "../../../components/table/THead";
import TBody from "../../../components/table/TBody";
import TableWrapper from "../../../components/table/TableWrapper";
// data
import { columns } from "./Columns";
// enums
import { RequestTypes } from "../../../enums/RequestTypes";
// export
import zipcelx, { Data, Row, Sheet, Config, Cell } from "../../../helpers/zipcelx";
import { sanitizeCsvString } from "../../../helpers/csv";
// helpers
import { shortFriendlyDateTime, shortFileDateTime } from "../../../helpers/time";
// logging
import { withPageLog } from "../../logging/LogComponentChange";
// services
import { getLoginCountsByDateRange, getLoginsV2 } from "../../../services/Logins";
// types
import { ILoginCounts, ILoginV2 } from "../../../types/Logins";
import { Apps } from "../../../types/App";

interface ILoginsV2Props {
  appId: number;
  bottlerId?: number;
}

interface ILoginsV2State {
  count: number;
  createdAtEnd: string;
  createdAtStart: string;
  exporting: boolean;
  loadingLoginCounts: boolean;
  loadingLogins: boolean;
  limit: number;
  logins: ILoginV2[];
  loginCounts: ILoginCounts[];
  offset: number;
  sortBy: string;
  sortOrder: string;
}

class Logins extends React.Component<ILoginsV2Props, ILoginsV2State> {
  DEFAULT_LIMIT: number = 10;
  DEFAULT_OFFSET: number = 0;
  DEFAULT_SORT_ORDER: string = "DESC";
  DEFAULT_SORT_BY: string = "[login].[createdAt]";

  constructor(props: ILoginsV2Props) {
    super(props);

    this.state = {
      count: 0,
      createdAtEnd: moment().toISOString(),
      exporting: false,
      loadingLoginCounts: true,
      loadingLogins: false,
      logins: [],
      loginCounts: [],
      sortBy: this.DEFAULT_SORT_BY,
      offset: this.DEFAULT_OFFSET,
      limit: this.DEFAULT_LIMIT,
      sortOrder: this.DEFAULT_SORT_ORDER,
      createdAtStart: moment()
        .subtract(7, "days")
        .toISOString()
    };
  }

  public componentDidMount(): void {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: ILoginsV2Props, prevState: ILoginsV2State): void {
    const { appId, bottlerId } = this.props;
    const { createdAtEnd, createdAtStart } = this.state;

    if (appId !== prevProps.appId || prevProps.bottlerId !== bottlerId) {
      // reset some of the state
      this.setState({
        logins: [],
        loginCounts: [],
        sortBy: "[login].[createdAt]",
        offset: this.DEFAULT_OFFSET,
        sortOrder: "DESC"
      });
      this.fetchData();
    }
    if (prevState.createdAtStart !== createdAtStart || prevState.createdAtEnd !== createdAtEnd) {
      this.setState({ offset: this.DEFAULT_OFFSET });
      this.fetchData();
    }
  }

  public render() {
    const { appId } = this.props;
    const { count, createdAtEnd, createdAtStart, exporting, loadingLoginCounts, loadingLogins, loginCounts, logins, offset, sortBy, sortOrder } = this.state;

    const loginsColumns = this.getColumns();

    let appTitle: string = `Bottler App`;
    if (appId === Apps.COKE_SERVICES_APP) {
      appTitle = "Service App";
    }
    const title: string = `Repair Requests: Logins & Actions for ${appTitle}`;

    return (
      <Page className="loginsActions" permissions={true} title={title}>
        <RefreshGroup
          refreshId="login-counts"
          handleRefresh={() => {
            this.setState({ loadingLogins: true, loadingLoginCounts: true });
            this.fetchData();
          }}
          isLoading={loadingLoginCounts || loadingLogins}
        />
        <BottlerSwitcher showAllOption={true} requestTypeId={RequestTypes.EquipmentRepair} />
        <LoaderWrapper>
          <div className="flexAlignCenter flexWrap">
            <DateRangePicker
              endDate={createdAtEnd}
              endDateChange={(date: string) => {
                this.setState({ createdAtEnd: date });
              }}
              endDateId="endDate"
              endDateMax={createdAtEnd}
              endDateMin={createdAtStart}
              endDatePlaceholder="End date..."
              startDate={createdAtStart}
              startDateChange={(date: string) => {
                this.setState({ createdAtStart: date });
              }}
              startDateId="startDate"
              startDateMax={createdAtEnd}
              startDateMin={moment()
                .subtract(1, "year")
                .toISOString()}
              startDatePlaceholder="Start date..."
            />
          </div>
          <div className="field">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={loginCounts}>
                <XAxis dataKey="dateFormatted" />
                <Tooltip />
                <YAxis />
                <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                {appId === Apps.COKE_BOTTLER_APP && <Line type="monotone" dataKey="loginCount" stroke="#68b90f" name="Logins" />}
                <Line type="monotone" dataKey="equipmentLookupCount" stroke="#e51e2b" name="Equipment Lookups" />
                <Line type="monotone" dataKey="requestCreatedCount" stroke="#005ba4" name="Requests Created" />
                <Legend verticalAlign="bottom" iconType="square" wrapperStyle={{ bottom: 0 }} />
              </LineChart>
            </ResponsiveContainer>
            <Hr />
          </div>
          <div className="defaultTable loginsActionsTable">
            <Button
              className="buttonWhite marginBottomSmall"
              disabled={exporting}
              iconLeft={<Icon iconName="Download" />}
              onClick={!exporting ? e => this.exportToExcel(e) : undefined}
              text={this.renderExportText()}
              title={this.renderExportText()}
            />
            <TableWrapper>
              <Table>
                <THead>
                  <Tr>
                    <HeaderColumns
                      columns={loginsColumns}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      data={logins}
                      handleSort={callback =>
                        this.setState(
                          {
                            sortOrder: callback.sortOrder,
                            sortBy: callback.sortBy,
                            offset: this.DEFAULT_OFFSET,
                            loadingLogins: true
                          },
                          () => this.fetchLogins()
                        )
                      }
                    />
                  </Tr>
                </THead>
                <TBody>
                  {logins.map((login, index) => (
                    <Tr hover={true} striped={true} error={false} key={index}>
                      <Td>
                        <p className="label">{appId === Apps.COKE_BOTTLER_APP ? "Login Created" : "Lookup Created"}</p>
                        {shortFriendlyDateTime(login.createdAt)}
                      </Td>
                      <Td>
                        <p className="label">Bottler</p>
                        {(login.bottler && login.bottler.name) || ""}
                      </Td>
                      {appId === Apps.COKE_BOTTLER_APP && (
                        <>
                          <Td>
                            <p className="label">User Name</p>
                            {(login.user && login.user.name) || ""}
                          </Td>
                          <Td>
                            <p className="label">User Email</p>
                            {(login.user && login.user.email) || ""}
                          </Td>
                          <Td>
                            <p className="label">Equipment Lookup</p>
                            {login.equipmentLookupId ? "Yes" : "No"}
                          </Td>
                        </>
                      )}
                      <Td>
                        <p className="label">Equipment Lookup Type</p>
                        {(login.equipmentLookup && login.equipmentLookup.equipmentLookupType.name) || ""}
                      </Td>
                      <Td>
                        <p className="label">Request</p>
                        {login.requestId ? "Yes" : "No"}
                      </Td>
                      <Td>
                        <p className="label">Request Type</p>
                        {(login.request && login.request.requestType.label) || ""}
                      </Td>
                      {appId === Apps.COKE_SERVICES_APP && (
                        <>
                          <Td>
                            <p className="label">Service App Contact First Name</p>
                            {(login.request && login.request.requestContact && login.request.requestContact.firstName) || ""}
                          </Td>
                          <Td>
                            <p className="label">Service App Contact Last Name</p>
                            {(login.request && login.request.requestContact && login.request.requestContact.lastName) || ""}
                          </Td>
                          <Td>
                            <p className="label">Service App Contact Email</p>
                            {(login.request && login.request.requestContact && login.request.requestContact.email) || ""}
                          </Td>
                          <Td>
                            <p className="label">Service App Employee Name</p>
                            {(login.request && login.request.requestEmployee && login.request.requestEmployee.employeeName) || ""}
                          </Td>
                          <Td>
                            <p className="label">Service App Employee Email</p>
                            {(login.request && login.request.requestEmployee && login.request.requestEmployee.employeeEmail) || ""}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
                  {loadingLoginCounts && logins.length === 0 && <LoaderTable numOfColumns={loginsColumns.length} />}
                  {logins.length === 0 && !loadingLoginCounts && (
                    <Tr key="no-results">
                      <Td colSpan={loginsColumns.length}>
                        <Alert show={true} type="Info">
                          There are no logins. Switch your Bottler.
                        </Alert>
                      </Td>
                    </Tr>
                  )}
                </TBody>
              </Table>
            </TableWrapper>
            <div className="field">
              <Pagination
                count={count}
                loading={loadingLoginCounts || loadingLogins}
                page={offset / this.DEFAULT_LIMIT + 1}
                perPage={this.DEFAULT_LIMIT}
                paginate={(e, page) => {
                  this.setState({ offset: (page - 1) * this.DEFAULT_LIMIT, loadingLogins: true }, () => this.fetchLogins());
                }}
              />
            </div>
          </div>
          <Loader loading={loadingLogins || loadingLoginCounts} type="Overlay" showImage={loadingLogins} text={loadingLogins ? "Loading..." : " "} position="Top" />
        </LoaderWrapper>
        <ToastContainer />
      </Page>
    );
  }

  private fetchLogins = async () => {
    const { createdAtStart, createdAtEnd, limit, sortBy, sortOrder, offset } = this.state;
    const { appId, bottlerId } = this.props;

    try {
      const { logins, count } = await getLoginsV2({
        appId,
        bottlerId,
        createdAtStart: createdAtStart.toString(),
        createdAtEnd: createdAtEnd.toString(),
        limit,
        sortBy,
        sortOrder,
        offset: offset === 0 ? 0 : offset
      });
      this.setState({
        count,
        logins,
        loadingLogins: false
      });
    } catch (error) {
    } finally {
      this.setState({ loadingLogins: false });
    }
  };

  private fetchLoginCounts = async () => {
    const { createdAtStart, createdAtEnd } = this.state;
    const { appId, bottlerId } = this.props;

    try {
      const { loginCounts } = await getLoginCountsByDateRange({
        appId,
        bottlerId,
        startDate: createdAtStart.toString(),
        endDate: createdAtEnd.toString()
      });
      this.setState({ loginCounts });
    } catch (error) {
      toast.error("Error retrieving login information", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } finally {
      this.setState({ loadingLoginCounts: false });
    }
  };

  private fetchData = async () => {
    this.fetchLoginCounts();
    this.fetchLogins();
  };

  private getColumns = (): { sortBy: string; text: string }[] => {
    const { appId } = this.props;

    const headerColumns: { sortBy: string; text: string }[] = [];

    columns.map(column => {
      const include = column.appIds.find(id => id === appId);
      if (include) {
        headerColumns.push({ sortBy: column.sortBy, text: column.text });
      }
    });

    return headerColumns;
  };

  exportToExcel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    this.setState(
      {
        exporting: true
      },
      async () => {
        const { appId, bottlerId } = this.props;
        const { createdAtEnd, createdAtStart, sortBy, sortOrder } = this.state;

        try {
          const result = await getLoginsV2({
            appId,
            bottlerId,
            createdAtStart: createdAtStart.toString(),
            createdAtEnd: createdAtEnd.toString(),
            limit: 0,
            offset: 0,
            sortBy,
            sortOrder
          });

          const data: Data = [];
          const header: Row = [];

          columns.map(column => {
            const include = column.appIds.find(id => id === appId);
            if (include) {
              header.push({ value: column.text, type: "string" });
            }
          });

          data.push(header);

          result.logins.map(login => {
            const cell: Cell[] = [];

            columns.map(column => {
              const include = column.appIds.find(id => id === appId);
              if (include) {
                let value;
                let obj: {} | null = {};

                column.keys.map((key, index) => {
                  if (column.keys.length > 1) {
                    if (index === 0) {
                      obj = login[key];
                    }
                    if (column.keys && index === column.keys.length - 1 && obj) {
                      value = obj[key];
                      obj = {};
                    }
                    if (value === undefined && index !== 0 && obj) {
                      obj = obj[key];
                    }
                  } else {
                    value = login[key];
                    obj = {};
                  }
                });

                value =
                  column.type === "boolean" && value
                    ? "Yes"
                    : column.type === "boolean" && !value
                    ? "No"
                    : column.type === "datetime" && value
                    ? shortFriendlyDateTime(value)
                    : column.type === "number" && value
                    ? sanitizeCsvString(value.toString())
                    : value
                    ? sanitizeCsvString(value)
                    : "";

                cell.push({ value, type: "string" });
              }
            });

            data.push(cell);
          });

          const now: string = moment().toISOString();
          const filename = "Logins_Actions_" + shortFileDateTime(now);

          const sheet: Sheet = {
            data
          };

          const config: Config = {
            filename,
            sheet
          };

          zipcelx(config);
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });
        } finally {
          this.setState({ exporting: false });
        }
      }
    );
  };

  renderExportText = (): string => {
    const { appId } = this.props;
    const { count } = this.state;

    return `Export ${count.toString()} ${appId === Apps.COKE_BOTTLER_APP ? "Logins" : "Lookups"}`;
  };
}

export default connect((state: any) => ({
  bottlerId: state.bottler.bottler && state.bottler.bottler.id
}))(withRouter(withPageLog(Logins)));
