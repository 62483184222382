import * as React from "react";
import * as ReactDOM from "react-dom";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Button from "../button/Button";

export interface IDropdownProps {
  buttonClass?: string;
  buttonHideText?: boolean;
  buttonIcon: string;
  buttonText: string;
  disabled: boolean;
  id: string;
  showCaret?: boolean;
}
export interface IDropdownState {
  isOpen: boolean;
}

export default class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false
    };
    this._handleClickOutside = this._handleClickOutside.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
  }

  public componentDidMount(): void {
    document.addEventListener("click", this._handleClickOutside, true);
  }

  public componentWillUnmount(): void {
    document.removeEventListener("click", this._handleClickOutside, true);
  }

  public render(): React.ReactElement<IDropdownProps> {
    const { buttonClass, buttonHideText, buttonIcon, buttonText, children, disabled, showCaret } = this.props;
    const { isOpen } = this.state;

    const caret: JSX.Element | null =
      isOpen && showCaret ? <Icon iconName="CaretSolidUp" /> : showCaret ? <Icon iconName="CaretSolidDown" /> : null;

    const buttonCssClass: string = buttonClass ? buttonClass : "";

    return (
      <div className={"dropdown"} aria-expanded={isOpen}>
        <Button
          className={buttonCssClass}
          onClick={e => this._handleToggle(e)}
          disabled={disabled}
          hideText={buttonHideText}
          iconLeft={buttonIcon ? <Icon iconName={buttonIcon} /> : null}
          iconRight={caret}
          text={buttonText}
          title={buttonText}
        />
        <div className={"dropdownList"}>{children}</div>
      </div>
    );
  }

  private _handleToggle(e: any): void {
    e.preventDefault();

    const { disabled } = this.props;
    const { isOpen } = this.state;

    if (!disabled) {
      this.setState({
        isOpen: !isOpen
      });
    }
  }

  private _handleClickOutside(e: any): void {
    const domNode: Element | Text | null = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        isOpen: false
      });
    }
  }
}
