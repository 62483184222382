import { combineReducers } from "redux";
import ldRedux from "ld-redux";
import { reducer as adminAppRoutes } from "./adminAppRoute/adminAppRoute";
import { reducer as bottler } from "./bottler/bottler";
import { reducer as bottlers } from "./bottler/bottlers";
import { reducer as config } from "./config/config";
import { reducer as filters } from "./filters/filters";
import { reducer as program } from "./notify/programs/program";
import { reducer as programs } from "./notify/programs/programs";
import { reducer as requestsearch } from "./requestsearch/requestsearch";
import { reducer as servicerequests } from "./notify/reports/serviceRequests";
import { reducer as usage } from "./notify/reports/usage";
import { reducer as usageTotal } from "./notify/reports/usageTotal";
import { reducer as user } from "./user/user";
import { reducer as wifi } from "./wifiNetwork/wifiNetwork";

// these reducers must be pure functions, no http calls, date.now, etc
export const reducers = combineReducers({
  // TODO: fix these typescript warnings
  // @ts-ignore
  adminAppRoutes,
  // @ts-ignore
  bottlers,
  // @ts-ignore
  bottler,
  // @ts-ignore
  user,
  // @ts-ignore
  config,
  // @ts-ignore
  filters,
  // @ts-ignore
  program,
  // @ts-ignore
  programs,
  // @ts-ignore
  usage,
  // @ts-ignore
  usageTotal,
  // @ts-ignore
  requestsearch,
  // @ts-ignore
  servicerequests,
  // @ts-ignore
  wifi,
  featureFlags: ldRedux.reducer()
});
