import * as React from "react";

export interface IHrProps {
  className?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
}

export default class Hr extends React.Component<IHrProps, {}> {
  public render(): React.ReactElement<IHrProps> {
    const { className, marginTop, marginBottom } = this.props;

    const cssClass: string =
      marginTop === false
        ? "hr marginTopNone"
        : marginBottom === false
          ? "hr marginBottomNone"
          : className
            ? "hr " + className
            : "hr";

    return <hr className={cssClass} />;
  }
}
