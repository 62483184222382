import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { Draggable } from "react-beautiful-dnd";
// components
import P from "../typography/P";
import Modal from "../modal/Modal";
import RequestDetail from "../../containers/shared-services/Requests/RequestDetail";

interface IKanbanCardProps {
  body?: any;
  columnText: string;
  footer?: any;
  group?: string;
  icons?: any;
  id: number;
  index: number;
  isHighPriority: boolean;
  isDragDisabled?: boolean;
  summary?: string | null;
  title?: string;
  handleModalClose: Function;
}

interface IKanbanCardState {
  didUpdateRequest: boolean;
}

class KanbanCard extends React.PureComponent<IKanbanCardProps, IKanbanCardState> {
  state = {
    didUpdateRequest: false
  };
  public render() {
    const { columnText, footer, group, icons, isHighPriority, isDragDisabled, id, index, body, summary, title, handleModalClose } = this.props;
    const { didUpdateRequest } = this.state;

    const openText: string | JSX.Element = summary ? (
      <span>
        <span className="kanbanModalButtonId">{id}</span>
        <span className="kanbanModalButtonSummary">{summary}</span>
      </span>
    ) : (
      `${id}`
    );

    return (
      <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <div className="kanbanCard" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={isHighPriority ? `kanbanCardTitle High` : `kanbanCardTitle ${columnText}`}>
              <P className="kanbanCardTitleText">{group}</P>
              <Icon iconName={columnText === "New" ? "Unlock" : "LockSolid"} />
            </div>
            <div className="kanbanCardBody">
              {/* This span is here so we can handle the onMouseDown event here instead of `Draggable` handling it, which doesn't let us handle the focus inside the modal */}
              <span role="button" onMouseDown={e => e.stopPropagation()}>
                <Modal
                  body={[<RequestDetail isModal={true} requestId={id} key={id} requestUpdated={() => this.setState({ didUpdateRequest: true })} />]}
                  heading={`Edit Request #${id}`}
                  id="kanbanCardModal"
                  className="kanbanCardModal"
                  modalSize="Xxxl"
                  openClassName="buttonLinkPrimaryColor kanbanModalButton"
                  openText={openText}
                  openTitle={`Edit Request #${id}`}
                  onCancel={() => {
                    if (didUpdateRequest) {
                      this.setState({ didUpdateRequest: false });
                      return handleModalClose();
                    }
                    return null;
                  }}
                  showCancel={true}
                  showSave={false}
                  showFooter={false}
                />
              </span>
              <P className="kanbanCardRequestType" bold={true}>
                {title}
              </P>
              {body}
              {icons}
              <div className="kanbanCardFooter">{footer}</div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default KanbanCard;
