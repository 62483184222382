import { postRequest, deleteRequest } from "../core/api/requestConfig";
// types
import { IAddtionalUserCreateRes, IAddtionalUserDeleteRes, IAddtionalUserCreate } from "../types/User";

export function addAddtionalUser(create: IAddtionalUserCreate): Promise<IAddtionalUserCreateRes> {
  return new Promise<IAddtionalUserCreateRes>((resolve, reject) => {
    postRequest(`/api/v1/requestAdditionalUsers`, create)
      .then((res: IAddtionalUserCreateRes) => resolve(res))
      .catch((error: Error) => reject(error));
  });
}
export function removeAddtionalUser(uuid: string): Promise<IAddtionalUserDeleteRes> {
  return new Promise<IAddtionalUserDeleteRes>((resolve, reject) => {
    deleteRequest(`/api/v1/requestAdditionalUsers/${uuid}`)
      .then((res: IAddtionalUserDeleteRes) => resolve(res))
      .catch((error: Error) => reject(error));
  });
}
