import * as React from "react";
// components
import Button from "../button/Button";
import Label from "../label/Label";
import Filter from "./Filter";
import DateTimePicker from "../input/DateTimePicker";

interface IFilterDatePickerProps {
  isDisabled: boolean;
  filterText: string;
  label?: string;
  start: Date;
  end: Date;
  handleDateChange: Function;
  handleDateClear: Function;
}

const FilterDatePicker = ({
  isDisabled,
  filterText,
  start,
  end,
  handleDateChange,
  handleDateClear,
  label
}: IFilterDatePickerProps) => {
  return (
    <>
      {label && <Label text="Due Date" />}
      <Filter
        className="hasCalendar"
        filterDisabled={isDisabled}
        filterText={filterText}
        filterPosition="filterLeft"
        filtered={!!start || !!end}
      >
        <>
          <div className="field">
            <Label htmlFor="" text={`${filterText} After`} />
            <DateTimePicker
              date={start}
              id={`${filterText}-date`}
              minDate={undefined}
              onChange={(date: Date) => handleDateChange("start", date)}
              time={start}
            />
          </div>
          <div className="field">
            <Label htmlFor="" text={`${filterText} Before`} />
            <DateTimePicker
              date={end}
              id={`${filterText}-time`}
              minDate={undefined}
              onChange={(date: Date) => handleDateChange("end", date)}
              time={end}
            />
          </div>
          <div className="field">
            <Button
              className="buttonInfo buttonBlock"
              disabled={!start && !end}
              onClick={handleDateClear}
              text="Clear Filter"
              title="Clear Fitler"
            />
          </div>
        </>
      </Filter>
    </>
  );
};

export default FilterDatePicker;
