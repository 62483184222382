import * as React from "react";
import { toast } from "react-toastify";
import moment from "moment";
// components
import HeaderColumns from "../table/HeaderColumns";
import LoaderTable from "../loader/LoaderTable";
import RefreshGroup from "../refresh/Group";
import RequestHistoryModal from "./RequestHistoryModal";
import Search from "../search/Search";
import TBody from "../table/TBody";
import THead from "../table/THead";
import TableAlert from "../alert/TableAlert";
import TablePagination from "../table/TablePagination";
import Td from "../table/Td";
import Tr from "../table/Tr";
// services
import { getRequestAudits } from "../../services/RequestAudits";
import { getFilters } from "../../services/Filters";
// types
import { IBottler } from "../../types/Bottler";
import { IFilters } from "../../types/Filters";
import { IRequestAudit } from "../../types/RequestAudits";
// helpers
import { shortFriendlyDateTime, shortFileDateTime } from "../../helpers/time";
import { sanitizeCsvString } from "../../helpers/csv";
import zipcelx, { Config, Sheet, Row, Data } from "../../helpers/zipcelx";

interface IRequestHistoryTableProps {
  bottler: IBottler | null;
}

interface IRequestHistoryTableState {
  count: number;
  isLoading: boolean;
  requests: IRequestAudit[];
  orderBy: string;
  pageNumber: number;
  sortOrder: string;
  createdAtEnd: string | null;
  createdAtStart: string | null;
  filters: IFilters;
  requestTypeIds: number[];
  searchTerm: string;
  selectedBottlers: number[];
  selectedAuditTypes: number[];
  selectedRequestProcesses: number[];
  selectedRequestTypes: number[];
  selectedTowers: number[];
  selectedUsers: number[];
}

type selectKeys = "selectedBottlers" | "selectedRequestGroups" | "selectedRequestTypes" | "selectedTowers" | "selectedUsers" | "selectedAuditTypes" | "selectedRequestProcesses";

class RequestHistoryTable extends React.PureComponent<IRequestHistoryTableProps, IRequestHistoryTableState> {
  timer;

  constructor(props: IRequestHistoryTableProps) {
    super(props);

    this.state = {
      count: 0,
      isLoading: false,
      requests: [],
      orderBy: "[requestAudit].[createdAt]",
      pageNumber: 1,
      sortOrder: "DESC",
      createdAtEnd: null,
      createdAtStart: null,
      filters: {
        assignedTo: [],
        bottlers: [],
        requestAuditTypes: [],
        requestGroups: [],
        requestProcesses: [],
        requestTypes: [],
        requestPriorities: [],
        statuses: [],
        towers: [],
        users: []
      },
      requestTypeIds: [],
      searchTerm: "",
      selectedBottlers: [],
      selectedAuditTypes: [],
      selectedRequestProcesses: [],
      selectedRequestTypes: [],
      selectedTowers: [],
      selectedUsers: []
    };
  }

  componentDidMount(): void {
    this.fetchFilters();
    this.fetchData();
  }

  componentDidUpdate(prevProps: IRequestHistoryTableProps, prevState: IRequestHistoryTableState) {
    const { filters }: IRequestHistoryTableState = this.state;

    if (prevProps.bottler !== this.props.bottler) {
      // reset some of the state
      this.setState({
        requests: [],
        orderBy: "[requestAudit].[createdAt]",
        pageNumber: 1,
        sortOrder: "DESC",
        searchTerm: "",
        selectedBottlers: filters.bottlers.map(b => b.id),
        selectedAuditTypes: filters.requestAuditTypes.map(rat => rat.id),
        selectedRequestProcesses: filters.requestProcesses.map(rp => rp.id),
        selectedRequestTypes: [].concat.apply(
          [],
          // @ts-ignore
          filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
        ),
        selectedTowers: filters.towers.map(t => t.id),
        selectedUsers: filters.users.map(u => u.id)
      });

      this.fetchData();
    }
    if (prevState.createdAtStart !== this.state.createdAtStart || prevState.createdAtEnd !== this.state.createdAtEnd) {
      this.setState({ pageNumber: 1 });
      this.fetchData();
    }
  }

  render() {
    const { bottler } = this.props;
    const {
      count,
      createdAtEnd,
      createdAtStart,
      isLoading,
      requests,
      orderBy,
      pageNumber,
      sortOrder,
      filters,
      requestTypeIds,
      searchTerm,
      selectedBottlers,
      selectedAuditTypes,
      selectedRequestProcesses,
      selectedRequestTypes,
      selectedTowers,
      selectedUsers
    } = this.state;

    // prettier-ignore
    const hasAllBottlersToggled = selectedBottlers.length === filters.bottlers.length;
    const hasAllUsersToggled = selectedUsers.length === filters.users.length;
    // prettier-ignore
    const hasAllAuditsToggled = selectedAuditTypes.length === filters.requestAuditTypes.length;
    // prettier-ignore
    const hasAllProcessesToggled = selectedRequestProcesses.length === filters.requestProcesses.length;
    // prettier-ignore
    const hasAllTypesToggled = selectedRequestTypes.length === requestTypeIds.length;
    const hasAllTowersToggled = selectedTowers.length === filters.towers.length;

    return (
      <div className="requestHistory">
        <RefreshGroup refreshId="request-history" handleRefresh={() => this.fetchData()} isLoading={isLoading} />
        <Search
          autoFocus={true}
          onSearchChange={e => this.handleSearch(e.target.value)}
          placeholder="Search requests..."
          search={searchTerm}
          searchButtonTitle="Search Requests"
          searchDisabled={isLoading}
          searchLabelText="Search Requests"
          showSearchButton={false}
          showExport={true}
          exportText="Export History"
          exportTitle="Export History"
          exportDisabled={requests.length === 0 || isLoading}
          onExportButtonClick={e => this.handleExport(e)}
        />
        <TablePagination
          isLoading={isLoading}
          className="defaultTable"
          count={count}
          pageNumber={pageNumber}
          handlePaginateChange={page => {
            this.setState({ pageNumber: page, isLoading: true }, () => this.fetchData());
          }}
        >
          <THead>
            <Tr>
              <HeaderColumns
                columns={[
                  {
                    sortBy: "[requestAudit].[createdAt]",
                    text: "Changed At",
                    isDateFilter: true,
                    filterProps: {
                      isDisabled: !createdAtEnd && !createdAtStart && requests.length === 0,
                      start: createdAtStart,
                      end: createdAtEnd,
                      handleDateChange: (type, date) => {
                        if (type === "start") {
                          return this.setState({ createdAtStart: date });
                        }
                        return this.setState({ createdAtEnd: date });
                      },
                      handleDateClear: () =>
                        this.setState({
                          createdAtEnd: null,
                          createdAtStart: null
                        })
                    }
                  },
                  {
                    sortBy: "[requestAudit].[requestId]",
                    text: "Request ID"
                  },
                  !bottler && {
                    sortBy: "[bottler].[name]",
                    text: "Bottler",
                    filterProps: {
                      isDisabled: hasAllBottlersToggled && requests.length === 0,
                      isFiltering: !hasAllBottlersToggled,
                      isAllChecked: hasAllBottlersToggled,
                      handleFilterChange: newSelectedBottlers => this.handleFilterChange("selectedBottlers", newSelectedBottlers),
                      options: filters.bottlers,
                      selectedOptions: selectedBottlers
                    }
                  },
                  {
                    sortBy: "[user].[name]",
                    text: "User",
                    filterProps: {
                      isDisabled: hasAllUsersToggled && requests.length === 0,
                      isFiltering: !hasAllUsersToggled,
                      isAllChecked: hasAllUsersToggled,
                      handleFilterChange: newSelectedUsers => this.handleFilterChange("selectedUsers", newSelectedUsers),
                      options: filters.users,
                      selectedOptions: selectedUsers
                    }
                  },
                  {
                    sortBy: "[tower].[label]",
                    text: "Tower",
                    filterProps: {
                      isDisabled: hasAllTowersToggled && requests.length === 0,
                      isFiltering: !hasAllTowersToggled,
                      isAllChecked: hasAllTowersToggled,
                      handleFilterChange: newTowers => this.handleFilterChange("selectedTowers", newTowers),
                      options: filters.towers,
                      selectedOptions: selectedTowers
                    }
                  },
                  {
                    sortBy: "[requestProcess].[label]",
                    text: "Request Process",
                    filterProps: {
                      isDisabled: hasAllProcessesToggled && requests.length === 0,
                      isFiltering: !hasAllProcessesToggled,
                      isAllChecked: hasAllProcessesToggled,
                      handleFilterChange: newProcesses => this.handleFilterChange("selectedRequestProcesses", newProcesses),
                      options: filters.requestProcesses,
                      selectedOptions: selectedRequestProcesses
                    }
                  },
                  {
                    sortBy: "[requestType].[label]",
                    text: "Request Type",
                    filterProps: {
                      isDisabled: hasAllTypesToggled && requests.length === 0,
                      isFiltering: !hasAllTypesToggled,
                      isAllChecked: hasAllTypesToggled,
                      handleFilterChange: newTypes => this.handleFilterChange("selectedRequestTypes", newTypes),
                      options: filters.requestTypes,
                      selectedOptions: selectedRequestTypes
                    }
                  },
                  {
                    sortBy: "[requestAuditType].[name]",
                    text: "Audit Type",
                    filterProps: {
                      isDisabled: hasAllAuditsToggled && requests.length === 0,
                      isFiltering: !hasAllAuditsToggled,
                      isAllChecked: hasAllAuditsToggled,
                      handleFilterChange: newAudits => this.handleFilterChange("selectedAuditTypes", newAudits),
                      options: filters.requestAuditTypes,
                      selectedOptions: selectedAuditTypes
                    }
                  },
                  { text: "Actions" }
                ].filter(Boolean)}
                sortBy={orderBy}
                sortOrder={sortOrder}
                data={requests}
                handleSort={sortCb =>
                  this.setState(
                    {
                      sortOrder: sortCb.sortOrder,
                      orderBy: sortCb.sortBy,
                      pageNumber: 1,
                      isLoading: true
                    },
                    () => this.fetchData()
                  )
                }
              />
            </Tr>
          </THead>
          <TBody>
            {requests.map((req: IRequestAudit, index: number) => (
              <Tr hover={true} striped={true} error={false} key={index}>
                <Td>
                  <p className="label">Logged In At</p>
                  {shortFriendlyDateTime(req.createdAt)}
                </Td>
                <Td>
                  <p className="label">Request ID</p>
                  {req.requestId}
                </Td>
                {!bottler && (
                  <Td>
                    <p className="label">Bottler</p>
                    {req.bottlerName}
                  </Td>
                )}
                <Td>
                  <p className="label">User</p>
                  {req.userName} ({req.userEmail})
                </Td>
                <Td>
                  <p className="label">Tower</p>
                  {req.towerLabel}
                </Td>
                <Td>
                  <p className="label">Request Process</p>
                  {req.requestProcessLabel}
                </Td>
                <Td>
                  <p className="label">Request Type</p>
                  {req.requestTypeLabel}
                </Td>
                <Td>
                  <p className="label">Audit Type</p>
                  {req.requestAuditTypeName}
                </Td>
                <Td>
                  <p className="label">Actions</p>
                  <RequestHistoryModal requestId={req.requestId} bottlerName={req.bottlerName} />
                </Td>
              </Tr>
            ))}
            {isLoading && requests.length === 0 && <LoaderTable numOfColumns={!bottler ? 9 : 8} />}
            {requests.length === 0 && !isLoading && <TableAlert numOfColumns={!bottler ? 9 : 8} message="There are no requests. Switch your Bottler or adjust a filter." />}
          </TBody>
        </TablePagination>
      </div>
    );
  }

  handleFilterChange = (type: selectKeys, value: any) => {
    return this.setState(
      {
        [type]: value
      } as Pick<IRequestHistoryTableState, keyof IRequestHistoryTableState>,
      () => this.fetchData()
    );
  };

  handleSearch = (searchTerm: string) => {
    clearTimeout(this.timer);

    this.setState(
      {
        searchTerm
      },
      () => {
        this.timer = setTimeout(() => {
          this.fetchData();
        }, 300);
      }
    );
  };

  fetchFilters = async () => {
    this.setState({ isLoading: true });

    const filters = await getFilters({
      includeBottlers: true,
      includeRequestAuditTypes: true,
      includeRequestProcesses: true,
      includeRequestTypes: true,
      includeTowers: true,
      includeUsers: true
    });

    this.setState({
      filters,
      requestTypeIds: [].concat.apply(
        [],
        // @ts-ignore
        filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
      ),
      selectedBottlers: filters.bottlers.map(b => b.id),
      selectedAuditTypes: filters.requestAuditTypes.map(rat => rat.id),
      selectedRequestProcesses: filters.requestProcesses.map(rp => rp.id),
      selectedRequestTypes: [].concat.apply(
        [],
        // @ts-ignore
        filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
      ),
      selectedTowers: filters.towers.map(t => t.id),
      selectedUsers: filters.users.map(u => u.id)
    });
  };

  fetchData = async () => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    const { bottler } = this.props;

    const {
      orderBy,
      pageNumber,
      sortOrder,
      createdAtEnd,
      createdAtStart,
      filters,
      searchTerm,
      selectedAuditTypes,
      selectedBottlers,
      selectedRequestProcesses,
      selectedTowers,
      selectedUsers,
      selectedRequestTypes
    } = this.state;

    let bottlerIds: number[] = [];

    if (filters.bottlers.length !== selectedBottlers.length) {
      bottlerIds = selectedBottlers;
    } else if (bottler) {
      bottlerIds = [bottler.id];
    } else {
      bottlerIds = [];
    }

    try {
      const { requestAudits, requestAuditsTotalCount } = await getRequestAudits({
        bottlerId: bottlerIds,
        createdAtEnd,
        createdAtStart,
        orderBy,
        pageNumber,
        requestAuditTypeId: selectedAuditTypes,
        requestProcessId: selectedRequestProcesses,
        requestTypeId: selectedRequestTypes,
        search: searchTerm,
        sortOrder,
        towerId: selectedTowers,
        userId: selectedUsers
      });
      this.setState({
        count: requestAuditsTotalCount,
        requests: requestAudits,
        isLoading: false
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({ isLoading: false });
    }
  };

  handleExport = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ isLoading: true }, async () => {
      try {
        const { bottler } = this.props;

        const {
          orderBy,
          sortOrder,
          createdAtEnd,
          createdAtStart,
          filters,
          searchTerm,
          selectedAuditTypes,
          selectedBottlers,
          selectedRequestProcesses,
          selectedTowers,
          selectedUsers,
          selectedRequestTypes
        } = this.state;

        let bottlerIds: number[] = [];

        if (filters.bottlers.length !== selectedBottlers.length) {
          bottlerIds = selectedBottlers;
        } else if (bottler) {
          bottlerIds = [bottler.id];
        } else {
          bottlerIds = [];
        }

        const requestAudits = await getRequestAudits({
          bottlerId: bottlerIds,
          createdAtEnd,
          createdAtStart,
          orderBy,
          pageNumber: 1,
          pageSize: 0,
          requestAuditTypeId: selectedAuditTypes,
          requestProcessId: selectedRequestProcesses,
          requestTypeId: selectedRequestTypes,
          search: searchTerm,
          sortOrder,
          towerId: selectedTowers,
          userId: selectedUsers
        });

        const data: Data = [];

        const header: Row = [
          {
            value: "Changed At",
            type: "string"
          },
          {
            value: "Changed By",
            type: "string"
          },
          {
            value: "Event",
            type: "string"
          },
          {
            value: "Changed From",
            type: "string"
          },
          {
            value: "Changed To",
            type: "string"
          }
        ];

        data.push(header);

        requestAudits.requestAudits.map(audit => {
          data.push([
            {
              value: sanitizeCsvString(shortFriendlyDateTime(audit.createdAt)),
              type: "string"
            },
            {
              value: sanitizeCsvString(audit.userName),
              type: "string"
            },
            {
              value: sanitizeCsvString(audit.requestAuditTypeName),
              type: "string"
            },
            {
              value: sanitizeCsvString(audit.changedFrom),
              type: "string"
            },
            {
              value: sanitizeCsvString(audit.changedTo),
              type: "string"
            }
          ]);
        });

        const bottlerName: string = bottler ? `${bottler.name}_` : ``;

        const filename = `History_${bottlerName}${shortFileDateTime(moment().toISOString())}`;

        const sheet: Sheet = {
          data
        };

        const config: Config = {
          filename,
          sheet
        };

        zipcelx(config);

        this.setState({ isLoading: false });
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });

        this.setState({ isLoading: false });
      }
    });
  };
}

export default RequestHistoryTable;
