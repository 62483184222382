import * as React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import clsx from "clsx";
// components
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Page from "../../components/page/Page";
import Form from "../../components/forms/Form";
import { Wysiwyg } from "../../components/wysiwyg/Wysiwyg";
import { Hr } from "../../components/typography";
import Button from "../../components/button/Button";
import ButtonGroup from "../../components/button/ButtonGroup";
import { Icon } from "office-ui-fabric-react";
import { Prompt } from "react-router-dom";
import Switcher from "../../components/switcher/Switcher";
// helpers

// logging
import { withPageLog } from "../logging/LogComponentChange";
import Alert from "../../components/alert/Alert";

// services
import { getPrivacyPolicy, updatePrivacyPolicy } from "../../services/PrivacyPolicy";

// types
import { IUser } from "../../types/User";
import { IPrivacyPolicyInner } from "../../types/PrivacyPolicy";

export interface IPrivacyPolicyProps {
  user: IUser;
}
type TranslationOption = {
  language: string;
  label: string;
};
export interface IPrivacyPolicyState {
  error: Error | null;
  loading: boolean;
  disableButtons: boolean;
  disableField: boolean;
  policy: IPrivacyPolicyInner | null;
  originalPolicy: IPrivacyPolicyInner | null;
  editorInitialized: boolean;
  isSaved: boolean;
  language: TranslationOption;
}

const translationOptions: TranslationOption[] = [
  {
    language: "EN",
    label: "English"
  },
  {
    language: "FR",
    label: "French"
  }
];

class PrivacyPolicy extends React.Component<IPrivacyPolicyProps, IPrivacyPolicyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      disableButtons: true,
      disableField: false,
      policy: null,
      originalPolicy: null,
      editorInitialized: false,
      isSaved: true,
      language: translationOptions[0] // English
    };
  }

  public componentDidMount(): void {
    this._getPrivacyPolicy();
  }

  handleTranslationChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value: string = e.target.value;

    let language: TranslationOption = translationOptions[0];

    translationOptions.map(option => {
      if (value === option.language) {
        language = option;
      }
    });

    if (!this.state.disableButtons) {
      // If changes are detected, trigger alert
      const result = window.confirm("You have unsaved changes, are you sure you want to leave?");
      if (result) {
        // If OK clicked, reset policies before switching, else leave things alone
        this._resetPrivacyPolicy();
        this.setState({
          language: language,
          editorInitialized: false
        });
      }
    } else {
      // Default Behavior
      this.setState({
        language: language,
        editorInitialized: false
      });
    }
  };

  public render(): React.ReactElement {
    const { error, loading, disableButtons, disableField, policy, originalPolicy, editorInitialized, isSaved, language } = this.state;
    const options: JSX.Element[] = translationOptions.map(option => {
      return (
        <option key={option.language} value={option.language}>
          {option.label}
        </option>
      );
    });
    return (
      <Page className={clsx("appModes", "privacyPolicy")} permissions={true} title="Settings: Privacy Policy for Service App">
        <LoaderWrapper>
          <Switcher
            disabled={disableField}
            headingText="Switch Translation:"
            labelText="Switch Translation"
            id="languageChange"
            onChange={e => this.handleTranslationChange(e)}
            options={options}
            value={language.language.toString()}
          />
          {error && !loading && <Alert show={true} text={error.message ? error.message : "Error loading privacy policies. Please refresh to try again."} type="Error" />}
          {!loading && (
            <Form>
              {language.language.toString() === "EN" && (
                <Wysiwyg
                  className=""
                  id="privacy-policy"
                  readOnly={disableField}
                  onFocus={() => {
                    this.setState({
                      editorInitialized: true
                    });
                  }}
                  onChange={value => {
                    //Editor formats value once passed. Need to make sure onChange event isn't triggered on initial formatting so that the value for originalPolicy stays intact
                    if (editorInitialized) {
                      if (value === "" || value === null || value === "<p><br></p>") {
                        this.setState({
                          policy: { ...policy, html: null } as IPrivacyPolicyInner,
                          disableButtons: true,
                          isSaved: false
                        });
                      } else {
                        this.setState({
                          policy: { ...policy, html: value } as IPrivacyPolicyInner,
                          disableButtons: originalPolicy && value === originalPolicy.html ? true : false,
                          isSaved: false
                        });
                      }
                    } else {
                      this.setState({
                        editorInitialized: true,
                        isSaved: true
                      });
                    }
                  }}
                  value={policy ? policy.html : ""}
                />
              )}

              {language.language.toString() === "FR" && (
                <Wysiwyg
                  className=""
                  id="privacy-policy-fr"
                  readOnly={disableField}
                  onFocus={() => {
                    this.setState({
                      editorInitialized: true
                    });
                  }}
                  onChange={value => {
                    //Editor formats value once passed. Need to make sure onChange event isn't triggered on initial formatting so that the value for originalPolicy stays intact
                    if (editorInitialized) {
                      if (value === "" || value === null || value === "<p><br></p>") {
                        this.setState({
                          policy: { ...policy, frenchHtml: null } as IPrivacyPolicyInner,
                          disableButtons: originalPolicy && value === originalPolicy.frenchHtml ? true : false,
                          isSaved: false
                        });
                      } else {
                        this.setState({
                          policy: { ...policy, frenchHtml: value } as IPrivacyPolicyInner,
                          disableButtons: originalPolicy && value === originalPolicy.frenchHtml ? true : false,
                          isSaved: false
                        });
                      }
                    } else {
                      this.setState({
                        editorInitialized: true,
                        isSaved: true
                      });
                    }
                  }}
                  value={policy ? policy.frenchHtml : ""}
                />
              )}
              <Hr />
              <ButtonGroup>
                <Button
                  className="buttonSave buttonLarge buttonGeneralSelected"
                  disabled={disableButtons}
                  iconLeft={<Icon iconName="Save" />}
                  onClick={() => this._savePrivacyPolicy()}
                  text="Save Privacy Policy"
                  title="Save Privacy Policy"
                />
                <Button
                  className="buttonCancel buttonLarge buttonGeneral"
                  disabled={this.state.disableButtons}
                  iconLeft={<Icon iconName="Cancel" />}
                  onClick={() => {
                    this._resetPrivacyPolicy();
                    this._getPrivacyPolicy();
                  }}
                  text="Cancel"
                  title="Discard Privacy Policy Changes"
                />
              </ButtonGroup>
            </Form>
          )}

          <Loader loading={loading} position="Top" showImage={true} type="Overlay" />
        </LoaderWrapper>
        <Prompt when={!isSaved} message="You have unsaved changes, are you sure you want to leave?" />
      </Page>
    );
  }
  private _getPrivacyPolicy = (): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const result = await getPrivacyPolicy();
          const policy = result.privacyPolicies[0];

          this.setState(
            {
              policy,
              loading: false
            },
            async () => {
              this.setState({
                originalPolicy: policy,
                isSaved: true
              });
            }
          );
        } catch (error) {
          console.log(error);
          this.setState({
            error: error,
            loading: false,
            isSaved: false
          });
        }
      }
    );
  };
  private _savePrivacyPolicy = (): void => {
    const { policy } = this.state;
    this.setState(
      {
        disableButtons: true,
        disableField: true
      },
      async () => {
        if (policy) {
          try {
            await updatePrivacyPolicy(policy.id, { appId: policy.appId, html: policy.html, updatedAt: policy.updatedAt, frenchHtml: policy.frenchHtml });
            toast.success("Privacy Policy Saved", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              originalPolicy: policy,
              disableField: false,
              disableButtons: true,
              isSaved: true
            });
          } catch (error) {
            console.log(error);
            toast.error(error.message ? error.message : "Error saving privacy policies. Please try saving again.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              error: error,
              disableButtons: false,
              disableField: false,
              isSaved: false
            });
          }
        }
      }
    );
  };

  private _resetPrivacyPolicy = (): void => {
    const { originalPolicy } = this.state;
    this.setState(
      {
        disableButtons: true,
        disableField: true,
        editorInitialized: false,
        error: null
      },
      async () => {
        this.setState({
          policy: originalPolicy,
          disableButtons: true,
          disableField: false
        });
      }
    );
  };
}

export const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps)(withPageLog(PrivacyPolicy));
