import * as React from "react";
import { Link } from "react-router-dom";

export interface IButtonLinkOnClick {
  (e: React.MouseEvent<HTMLAnchorElement>): void;
}

export interface IButtonLinkProps {
  block?: boolean;
  className?: string;
  download?: boolean;
  disabled?: boolean;
  href: string;
  iconLeft?: JSX.Element | null;
  iconRight?: JSX.Element | null;
  onClick?: IButtonLinkOnClick;
  openInNewTab?: boolean;
  text: string;
  title?: string;
}
export interface IButtonLinkState {}

export default class ButtonLink extends React.Component<IButtonLinkProps, IButtonLinkState> {
  public render(): React.ReactElement<IButtonLinkProps> {
    const { className, disabled, download, href, iconLeft, iconRight, onClick, openInNewTab, text, title } = this.props;
    return (
      <Link
        className={`button buttonLink ${disabled ? "linkDisabled" : ""} ${className ? className : ""}`}
        download={download ? download : false}
        onClick={onClick}
        target={openInNewTab ? "_blank" : "_self"}
        title={title || text}
        to={href}
      >
        <span className="buttonLinkIconLeft">{iconLeft}</span>
        <span>{text}</span>
        <span className="buttonLinkIconRight">{iconRight}</span>
      </Link>
    );
  }
}
