import * as React from "react";
import HR from "../../components/typography/Hr";
import DocsImage2 from "../../images/documentation/Admin-2.png";
import DocsImage3 from "../../images/documentation/Admin-3.png";
import DocsImage4 from "../../images/documentation/Admin-4.png";
import DocsImage5 from "../../images/documentation/Admin-5.png";
import DocsImage7 from "../../images/documentation/Admin-7.png";
import DocsImage8 from "../../images/documentation/Admin-8.png";
import DocsImage9 from "../../images/documentation/Admin-9.png";
import DocsImage10 from "../../images/documentation/Admin-10.png";
import DocsImage11 from "../../images/documentation/Admin-11.png";
import DocsImage12 from "../../images/documentation/Admin-12.png";
import DocsImage13 from "../../images/documentation/Admin-13.png";
import DocsImage14 from "../../images/documentation/Admin-14.png";
import DocsImage15 from "../../images/documentation/Admin-15.png";
import DocsImage16 from "../../images/documentation/Admin-16.png";
import Page from "../../components/page/Page";

export interface IDocumentationProps {}

export interface IDocumentationState {}

class Documentation extends React.Component {
  public render() {
    return (
      <Page className="documentation" permissions={false} title="Documentation: Help">
        <h2>Left-Side Navigation</h2>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage2} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              On the left panel you will find the main navigation for the application.  By clicking on any navigation item you are taken to a screen with a sub navigation menu just
              to the right to help you drill down further within this category. 
            </p>
          </div>
        </div>
        <HR />
        <h2>Repair Requests</h2>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage3} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              In the Repair request section you can view requests from the BSNA Services Mobile Application. The usage reports provide a granular view of each individual bottlers
              requests over a given period of time.
            </p>
          </div>
        </div>
        <HR />
        <h2>Usage Reports</h2>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage4} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              The top section is a graph and the bottom section displays a list of each individual request made.  Both can be exported by clicking the associated download buttons.
            </p>
          </div>
        </div>
        <HR />
        <h2>Bottler Totals Reports</h2>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage5} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              Under Bottler Totals Reports in the sub navigation menu you are able to select all or individual bottlers to get a better understanding of how many reports are coming
              from individual bottlers and how they compare to their peers. You can also export the totals to a .xlsx report the same as the Usage Reports.
            </p>
          </div>
        </div>
        <HR />
        <h2>Service Requests - Payroll Periods</h2>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage7} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              Under Service Requests you are able to see a list of payroll periods. You are able to add a new payroll period by clicking on the Add A Payroll Period button at the
              bottom of the list. This brings up an editable field that you then fill in.
            </p>
            <p className="lead">
              {" "}
              You can also remove a period or activate/deactivate a period form this screen. When you are finished editing your payroll periods you will save your changes by
              clicking the green Save Payroll Periods button at the bottom of the page.{" "}
            </p>
          </div>
        </div>
        <HR />
        <h2>Service Requests - Requests</h2>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage8} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">In the Requests sub navigation menu you are able to edit and process individual requests.</p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage9} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">You can search through the requests to narrow down your results. </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage10} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">You can filter by selecting the filter drop down and entering the information you would like to filter by. </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage11} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">You are also able to sort by clicking on the sort arrows. </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage12} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">Or page through the information using the pagination options below the table. </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage13} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">Here you can export a request to a .xlsx file by clicking Export or you can begin to work with an individual request by clicking edit. </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage14} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              When you examine an individual request you are able to edit the individual request line items the same as you are via SharePoint to submit a request. You are under
              the same business rules and will be prompted to validate in the same ways in both applications.{" "}
            </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage15} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              Here you will find information about the requests. You will be able to add notes to the request. By clicking on the Add A Note button below the line items.
            </p>
          </div>
        </div>
        <div className="container all-center">
          <div className="col-50">
            <img src={DocsImage16} alt="" />
          </div>
          <div className="col-50">
            <p className="lead">
              You will then be given a field where you are able to enter a note up to 1000 characters in length. You are able to create multiple notes. Once a note is saved there
              is no way to delete this note and will be permanently attached to this request, but you are able to remove the note before saving it.{" "}
            </p>
            <p className="lead">
              You are also able to update the status of this request as it is taken through processing and completion. As well you can export the individual request by clicking
              Export Excel at the bottom{" "}
            </p>
          </div>
        </div>
      </Page>
    );
  }
}

export default Documentation;
