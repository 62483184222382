import { getRequest } from "../core/api/requestConfig";
import { IAdGroupUserAuditResponse, IAdGroupUserAuditFilter } from "../types/AdGroupUserAudits";

export function getAdGroupUserAudits(filter?: IAdGroupUserAuditFilter): Promise<IAdGroupUserAuditResponse> {
  let query: string = ``;

  if (filter) {
    query += `?`;

    if (filter.typeId) {
      query += `typeId=${filter.typeId}&`;
    }

    if (filter.bottlerId) {
      query += `bottlerId=${filter.bottlerId}&`;
    }

    if (filter.createdAtEnd) {
      query += `createdAtEnd=${filter.createdAtEnd}&`;
    }

    if (filter.createdAtStart) {
      query += `createdAtStart=${filter.createdAtStart}&`;
    }

    if (filter.pageSize !== undefined) {
      query += `pageSize=${filter.pageSize}&`;
    }

    if (filter.pageNumber !== undefined) {
      query += `pageNumber=${filter.pageNumber}&`;
    }

    if (filter.search) {
      query += `search=${filter.search}&`;
    }

    if (filter.orderBy) {
      query += `orderBy=${filter.orderBy}&`;
    }

    if (filter.sortOrder) {
      query += `sortOrder=${filter.sortOrder}&`;
    }
  }
  return new Promise<IAdGroupUserAuditResponse>((resolve, reject) => {
    getRequest(`/api/v1/adGroupUserAudits${query}`)
      .then((response: IAdGroupUserAuditResponse) => {
        return resolve(response);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}
