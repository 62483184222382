import * as React from "react";
import { Route } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import Navs from "./Navs";
import Overlay from "./Overlay";
import Wrap from "./Wrap";
// 10/28/22 removed during upgrade of react-adal to msal-react
// don't think it is needed
// import { AuthorizationProvider } from "../../core/authorization/components/AuthorizeProvider";

class Skeleton extends React.Component {
  public render() {
    return (
      // <AuthorizationProvider>
      <Wrap>
        <Header />
        <div className="container" id="container">
          <Route component={Navs} />
          <div className="content" id="content">
            <Scrollbars>
              <div className="contentInner" id="contentInner">
                <Main>{this.props.children}</Main>
                <Footer />
              </div>
            </Scrollbars>
          </div>
        </div>
        <Overlay />
      </Wrap>
      // </AuthorizationProvider>
    );
  }
}

export default Skeleton;
