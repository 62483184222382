import { IResponse } from "../types/Response";
import { IMSGraphUser } from "../core/types/User";
import { getRequest } from "../core/api/requestConfig";

// prefix of MS AD is to denote these users are from AD via MS Graph API
// not users in BSNA SQL
export interface IMsAdUser {
  displayName: string;
  givenName: string;
  id: string;
  mail: string;
  surname: string;
  userPrincipalName: string;
}

export const MsAdUserSelect: string = `displayName,givenName,id,mail,surname,userPrincipalName`;

export interface IMsAdUsersResponse extends IResponse {
  adUsers: IMsAdUser[];
  adUsersCount: number;
}

export function getAdUsers(search: string, domain?: string | null): Promise<IMSGraphUser[]> {
  return new Promise<IMSGraphUser[]>((resolve, reject) => {
    const d: string = domain ? domain : ``;
    getRequest(`/api/v1/getAdUsers?search=${search}&domain=${d}`)
      .then((users: IMSGraphUser[]) => {
        return resolve(users);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

/**
 * Get AD users from MS Graph API
 * @param search string
 * @param domain string | string[]
 * @returns IMsAdUsersResponse
 */
export const getAdUsersv2 = async (search: string, domain?: string | string[]): Promise<IMsAdUsersResponse> => {
  try {
    let domains = ``;

    if (domain) {
      if (Array.isArray(domain)) {
        domain.map(_domain => {
          domains += `&domain=${_domain}`;
        });
      } else {
        domains = `&domain=${domain}`;
      }
    }

    const result = await getRequest(`/api/v2/adUsers?search=${search}${domains}`);
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};
