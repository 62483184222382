import * as React from "react";
import { connect } from "react-redux";
// components
import AdGroupsList from "./AdGroupsList";
import Band from "../../components/layout/Band";
import BottlerSwitcher from "../../components/switcher/BottlerSwitcher";
import H1 from "../../components/typography/H1";
import Permissions from "../permissions/Permissions";
import Section from "../../components/layout/Section";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// types
import { IBottler } from "../../types/Bottler";

interface IPowerBIMembersProps {
  bottler: IBottler | null;
}

const PowerBIMembers: React.StatelessComponent<IPowerBIMembersProps> = ({ bottler }) => {
  const title: string = "Power BI Members";

  const bottlerDomains: string[] = [];

  if (bottler) {
    bottler.domains.map(domain => {
      bottlerDomains.push(domain.domain);
    });
  }

  return (
    <div className="groupMembership adGroups">
      <Band>
        <H1>{title}</H1>
      </Band>
      <Section>
        <Permissions title={title}>
          <BottlerSwitcher />
          <AdGroupsList
            allowExternalInvite={true}
            bottlerCode={bottler ? [`${bottler.code}-${bottler.bottlerType.name}-PBI`] : null}
            bottlerDomains={bottlerDomains}
            bottlerId={bottler ? bottler.id : null}
            showAddUsersMessage={true}
            title={title}
          />
        </Permissions>
      </Section>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    bottler: state.bottler.bottler
  };
};

export default connect(mapStateToProps)(withPageLog(PowerBIMembers));
