import * as React from "react";
import Section from "../../components/layout/Section";
import ErrorUnauthorized from "../../components/errors/ErrorUnauthorized";

class NotAllowed extends React.Component {
  public render() {
    return (
      <div className="notAllowed">
        <Section>
          <ErrorUnauthorized errorMessage="You do not have permission to access the BSNA Services admin." />
        </Section>
      </div>
    );
  }
}

export default NotAllowed;
