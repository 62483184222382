// polyfills to get the Slate Editor/Admin running in Edge/IE11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "element-closest";

import "semantic-ui-css/semantic.min.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom";
import { initializeIcons } from "@uifabric/icons";
import { msalConfig } from "./core/authentication/authenticationConfig";
import "./index.scss";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});
initializeIcons();
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
