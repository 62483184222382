import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";

export interface ISortProps {
  ascending: boolean;
  descending: boolean;
  disabled: boolean;
  onClick: any;
  text: string;
}
export interface ISortState {}

export default class Sort extends React.Component<ISortProps, ISortState> {
  public render(): React.ReactElement<ISortProps> {
    const { ascending, descending, disabled, onClick, text } = this.props;

    const icon: string = ascending ? "SortDown" : descending ? "SortUp" : "Sort";

    const sortBy: string = ascending || descending ? "Change To Sorting By " : "Sort By" ;
    const order: string = ascending ? " Descending" : descending ? " Ascending" : "";
    const t: string = `${sortBy}${text}${order}`;

    return (
      <button type="button" className="buttonSort" title={t} disabled={disabled} onClick={onClick}>
        <Icon iconName={icon} />
        <span className="srOnly">{t}</span>
      </button>
    );
  }
}
