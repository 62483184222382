import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";
import { NavLink } from "react-router-dom";

export interface IPrimaryNavLinkProps {
  icon: string;
  onClick?: any;
  text: string;
  title: string | undefined;
  to: string;
}

class PrimaryNavLink extends React.Component<IPrimaryNavLinkProps> {
  public render(): React.ReactElement<IPrimaryNavLinkProps> {
    const { icon, onClick, text, title, to } = this.props;

    return (
      <NavLink className="navLink" activeClassName="navLinkActive" onClick={onClick} title={title} to={to}>
        <span className="navLinkIcon">
          <Icon iconName={icon} />
        </span>
        <span className="navLinkText">{text}</span>
        <span className="navLinkBorder" />
      </NavLink>
    );
  }
}

export default PrimaryNavLink;
