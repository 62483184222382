import * as React from "react";

export interface IBadgeProps {
  text: string;
  title: string;
}
export interface IBadgeState {}

export default class Badge extends React.Component<IBadgeProps, IBadgeState> {
  public render(): React.ReactElement<IBadgeProps> {
    return (
      <span className="badge" title={this.props.title}>
        {this.props.text}
      </span>
    );
  }
}
