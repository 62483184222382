import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Button from "../button/Button";
import Note from "./Note";
import { INote } from "../../types/Note";
import { fullFriendlyDateTime } from "../../helpers/time";

export interface INotesProps {
  _handleNoteAdd: any;
  _handleNoteChange: any;
  _handleNoteDelete: any;
  _handleNoteSave: any;
  notesNew: INote[];
  notesExisting: INote[];
  notesSaving: boolean;
  requestId?: number;
}

const renderButtonText = (notesExisting: INote[], notesNew: INote[]) => {
  if (notesExisting.length > 0 && notesNew.length === 0) {
    return "Add Another Note";
  }
  if (notesExisting.length === 0 && notesNew.length === 0) {
    return "Add a Note";
  }
  return "Save Note";
};

const Notes = ({
  notesExisting,
  notesNew,
  _handleNoteAdd,
  _handleNoteChange,
  _handleNoteDelete,
  _handleNoteSave,
  notesSaving
}: INotesProps) => {
  return (
    <div className="notes">
      <h6>Notes</h6>
      {notesExisting.map((note, index) => {
        return (
          <Note
            key={note.id}
            count={notesExisting.length}
            createdAt={fullFriendlyDateTime(note.createdAt)}
            createdBy={
              note.user && note.user.name
                ? note.user.name
                : note.user
                ? note.user.email
                : ""
            }
            editing={false}
            id={note.id}
            index={index}
            newCount={notesNew.length}
            noteChange={_handleNoteChange}
            notesSaving={notesSaving}
            removeNote={_handleNoteDelete}
            saveNote={_handleNoteSave}
            text={note.text}
          />
        );
      })}
      {notesNew.map((note, index) => {
        return (
          <Note
            key={note.createdAt}
            count={notesExisting.length}
            createdAt={fullFriendlyDateTime(note.createdAt)}
            createdBy={
              note.user && note.user.name
                ? note.user.name
                : note.user
                ? note.user.email
                : ""
            }
            editing={true}
            id={note.id}
            index={index}
            newCount={notesNew.length}
            noteChange={_handleNoteChange}
            notesSaving={notesSaving}
            removeNote={_handleNoteDelete}
            saveNote={_handleNoteSave}
            text={note.text}
          />
        );
      })}
      <div className="buttonGroup">
        <Button
          className="button buttonSave noteAdd"
          iconLeft={
            <Icon iconName={notesNew.length > 0 ? "Save" : "CircleAddition"} />
          }
          id="note-add"
          onClick={e =>
            notesNew.length > 0 ? _handleNoteSave(e) : _handleNoteAdd(e)
          }
          text={renderButtonText(notesExisting, notesNew)}
          title={renderButtonText(notesExisting, notesNew)}
          disabled={notesSaving}
        />
        {notesNew.length > 0 && (
          <Button
            className="button buttonPrimary noteAdd"
            iconLeft={<Icon iconName="Delete" />}
            id="note-add"
            onClick={e => _handleNoteDelete(e)}
            text="Delete Note"
            title="Delete Note"
            disabled={notesSaving}
          />
        )}
      </div>
    </div>
  );
};

export default Notes;
