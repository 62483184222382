import moment from "moment";
import Holidays from "date-holidays";

const hd = new Holidays("US");

// creates an array of Dates that are disabled in the request due date picker. Includes some holidays and weekends
export const getBSNADisabledDates = (): Array<Date> => {
  var d: Date = new Date();
  // check for leap years
  var getTotDays: number = new Date().getFullYear() % 4 === 0 ? 366 : 365;
  const disabledDates: Date[] = [];
  for (var i = 1; i <= getTotDays; i++) {
    // looping through days in month
    var newDate = new Date(d.getFullYear(), d.getMonth(), i);
    // check for weekends
    if (newDate.getDay() === 0 || newDate.getDay() === 6) {
      disabledDates.push(
        // remove timezone when setting these dates
        moment(new Date(d.getFullYear(), d.getMonth(), i)).toDate()
      );
    }
  }
  // BSNA holidays, these names match up with the date-holidays package names
  const requestedHolidays = ["Christmas Day", "New Year's Day", "Thanksgiving Day", "Independence Day", "Memorial Day", "Labor Day"];
  // go through all national holidays and only select the BSNA ones
  hd.getHolidays().map(holiday => {
    if (requestedHolidays.includes(holiday.name)) {
      disabledDates.push(moment(holiday.date).toDate());
    }
  });
  return disabledDates;
};

export const isHoliday = (date: Date) => hd.isHoliday(date);

/**
 * Returns whether a given date is a weekend day
 * @example 2019-01-26 => true // Saturday
 * @example 2019-01-27 => true // Sunday
 * @example 2019-01-25 => false // Friday
 * @example 2019-01-28 => false // Monday
 * @param date Moment.Moment | Date | string
 * @returns boolean
 */
export function isWeekend(date: moment.Moment | Date | string): boolean {
  const today: string = moment(date).format("dddd");

  if (today === "Saturday" || today === "Sunday") {
    return true;
  } else {
    return false;
  }
}
