export interface IColumn {
  database: string;
  filter: string;
  sortBy: string;
  filterPosition: string;
  text: string;
  title: string;
}

export const columns: IColumn[] = [
  {
    database: "ssr.id",
    filter: "ssr.id",
    sortBy: "[ssr].[id]",
    text: "ID",
    title: "ID",
    filterPosition: "left"
  },
  {
    database: "b.name",
    filter: "b.name",
    sortBy: "[b].[name]",
    text: "Bottler",
    title: "Bottler",
    filterPosition: "left"
  },
  {
    database: "ssr.name",
    filter: "ssr.name",
    sortBy: "[ssr].[name]",
    text: "Name",
    title: "Name",
    filterPosition: "left"
  },
  {
    database: "ssr.email",
    filter: "ssr.email",
    sortBy: "[ssr].[email]",
    text: "Email",
    title: "Email",
    filterPosition: "left"
  },
  {
    database: "rg.label",
    filter: "rg.label",
    sortBy: "[rg].[label]",
    text: "Requested Group",
    title: "Requested Group",
    filterPosition: "left"
  },
  {
    database: "ssr.createdAt",
    filter: "ssr.createdAt",
    sortBy: "[ssr].[createdAt]",
    text: "Requested",
    title: "Requested",
    filterPosition: "left"
  },
  {
    database: "",
    filter: "",
    sortBy: "",
    text: "Actions",
    title: "Actions",
    filterPosition: ""
  }
];
