import { getRequest, putRequest, postRequest, deleteRequest } from "../core/api/requestConfig";
import { IAdminAppRoutesResponse, IAdminAppRouteResponse, IAdminAppRouteUpdate, IAdminAppRouteCreate } from "../types/AdminAppRoute";
import { IResponseDeleted } from "../types/Response";

export function getAdminAppRoutes(): Promise<IAdminAppRoutesResponse> {
  return new Promise<IAdminAppRoutesResponse>((resolve, reject) => {
    getRequest(`/api/v1/adminAppRoutes`)
      .then((adminAppRoutes: IAdminAppRoutesResponse) => {
        return resolve(adminAppRoutes);
      })
      .catch((error: IAdminAppRoutesResponse) => {
        return reject(error);
      });
  });
}

export function getAdminAppRoute(id: number): Promise<IAdminAppRouteResponse> {
  return new Promise<IAdminAppRouteResponse>((resolve, reject) => {
    getRequest(`/api/v1/adminAppRoutes/${id}`)
      .then((adminAppRoute: IAdminAppRouteResponse) => {
        return resolve(adminAppRoute);
      })
      .catch((error: IAdminAppRouteResponse) => {
        return reject(error);
      });
  });
}

export function updateAdminAppRoute(update: IAdminAppRouteUpdate): Promise<IAdminAppRouteResponse> {
  return new Promise<IAdminAppRouteResponse>((resolve, reject) => {
    putRequest(`/api/v1/adminAppRoutes`, update)
      .then((adminAppRoute: IAdminAppRouteResponse) => {
        return resolve(adminAppRoute);
      })
      .catch((error: IAdminAppRouteResponse) => {
        return reject(error);
      });
  });
}

export function createAdminAppRoute(create: IAdminAppRouteCreate): Promise<IAdminAppRouteResponse> {
  return new Promise<IAdminAppRouteResponse>((resolve, reject) => {
    postRequest(`/api/v1/adminAppRoutes`, create)
      .then((adminAppRoute: IAdminAppRouteResponse) => {
        return resolve(adminAppRoute);
      })
      .catch((error: IAdminAppRouteResponse) => {
        return reject(error);
      });
  });
}

export function deleteAdminAppRoute(id: number): Promise<IResponseDeleted> {
  return new Promise<IResponseDeleted>((resolve, reject) => {
    deleteRequest(`/api/v1/adminAppRoutes/${id}`)
      .then((deleted: IResponseDeleted) => {
        return resolve(deleted);
      })
      .catch((error: IResponseDeleted) => {
        return reject(error);
      });
  });
}
