import { IAdminAppRoute } from "../types/AdminAppRoute";
import { postRequest } from "../api/requestConfig";

export function getAdminAppRoutesByAdGroupObjectIds(adGroupObjectIds: string[]): Promise<IAdminAppRoute[]> {
  return new Promise<IAdminAppRoute[]>((resolve, reject) => {
    postRequest(`/api/v1/getAdminAppRoutesByAdGroupObjectIds`, {
      adGroupObjectIds: adGroupObjectIds
    })
      .then((adminAppRoutes: IAdminAppRoute[]) => {
        return resolve(adminAppRoutes);
      })
      .catch((error: Error) => {
        if (!error || !error.message) {
          const err: Error = {
            message: "Error retrieving admin app routes.",
            name: "getAdminAppRoutesByAdGroupObjectIds"
          };
          return reject(err);
        } else {
          return reject(error);
        }
      });
  });
}
