import * as React from "react";
import Required from "../input/Required";

export interface ILabelProps {
  className?: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  id?: string;
  htmlFor?: string;
  required?: boolean;
  srOnly?: boolean;
  srOnlyAt?: "Md"; // breakpoints.ts
  subText?: string;
  text: string;
  title?: string;
}

export interface ILabelState {}

export default class Label extends React.Component<ILabelProps, ILabelState> {
  public render() {
    const { children, className, htmlFor, required, srOnly, srOnlyAt, subText, text } = this.props;

    let cssClass: string = "label";
    if (srOnly) {
      cssClass += " srOnly";
    }
    if (srOnlyAt) {
      cssClass += " srOnly" + srOnlyAt;
    }
    if (className) {
      cssClass = className;
    }
    const showRequired: JSX.Element | null = required ? <Required /> : null;
    return (
      <label className={cssClass} htmlFor={htmlFor}>
        <span className="labelText">{text}</span>
        {children}
        {showRequired}
        {subText && <span className="labelSubText">{subText}</span>}
      </label>
    );
  }
}
