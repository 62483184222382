import * as React from "react";
import { Link } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";

export interface IErrorNotFoundProps {}

export interface IErrorNotFoundState {}

class ErrorNotFound extends React.Component<
  IErrorNotFoundProps,
  IErrorNotFoundState
> {
  public render(): React.ReactElement<IErrorNotFoundProps> {
    return (
      <ErrorMessage
        className="errorNotFound"
        errorCode={404}
        errorCodeType={"Not Found"}
        errorMessage={
          <span>
            The page you are looking for cannot be found.{" "}
            <Link to="/">Go to the Dashboard</Link>.
          </span>
        }
      />
    );
  }
}

export default ErrorNotFound;
