import { setLocalStorage } from "../storage/localStorage";

export enum TypeKeys {
  SEARCH_SUCCESS = "SEARCH_SUCCESS"
}

type Error = string | null;

type searchSuccess = {
  requestsearch: String;
  type: TypeKeys.SEARCH_SUCCESS;
};

export const setSearch = (requestsearch: String): searchSuccess => {
  return {
    requestsearch,
    type: TypeKeys.SEARCH_SUCCESS
  };
};

export const setSearchCreator = (search: String) => {
  return async dispatch => {
    try {
      setLocalStorage("search", JSON.stringify(search));
      dispatch(setSearch(search));
    } catch (error) {
      console.log(error);
    }
  };
};

export type Actions = searchSuccess;

export interface ISearchState {
  search: String;
  error: Error;
}

export const initialState = {
  search: "",
  error: null
};

export const reducer = (state: ISearchState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.SEARCH_SUCCESS:
      newState.search = action.requestsearch;
      newState.error = null;
      break;
    default:
      break;
  }
  return newState;
};
