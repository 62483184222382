import * as React from "react";
import * as ReactDOM from "react-dom";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Select from "../input/Select";
import Input from "../input/Input";

export const filters: IFilters[] = [
  {
    id: "contains",
    label: "Contains"
  },
  {
    id: "notContain",
    label: "Not Contain"
  },
  {
    id: "equals",
    label: "Equals"
  },
  {
    id: "notEquals",
    label: "Not Equals"
  }
  // {
  //   id: "greaterThan",
  //   label: "Greater Than"
  // },
  // {
  //   id: "lessThan",
  //   label: "Less Than"
  // }
];

export interface IFilterContainsProps {
  _filter: any;
  _filterEnter?: any;
  _filterSearch: any;
  _filterBy: any;
  _undoFilter: any;
  dropdownDisabled?: boolean;
  filter: any;
  filterBy: string;
  filterDisabled: boolean;
  filterSearch: string;
  filtered: boolean;
  id: string;
  position?: string;
  text: string;
  undoDisabled: boolean;
}

export interface IFilterContainsState {
  isOpen: boolean;
}

export interface IFilters {
  id: string;
  label: string;
}

export default class FilterContains extends React.Component<IFilterContainsProps, IFilterContainsState> {
  constructor(props: IFilterContainsProps) {
    super(props);
    this.state = {
      isOpen: false
    };
    this._handleClickOutside = this._handleClickOutside.bind(this);
    this._handleFilter = this._handleFilter.bind(this);
    this._handleToggle = this._handleToggle.bind(this);
    this._handleUndo = this._handleUndo.bind(this);
  }

  public componentDidMount(): void {
    document.addEventListener("click", this._handleClickOutside, true);
  }

  public componentWillUnmount(): void {
    document.removeEventListener("click", this._handleClickOutside, true);
  }

  public render(): React.ReactElement<IFilterContainsProps> {
    const {
      _filterBy,
      _filterEnter,
      _filterSearch,
      dropdownDisabled,
      filterBy,
      filterDisabled,
      filterSearch,
      filtered,
      id,
      position,
      text,
      undoDisabled
    } = this.props;

    const icon: string = filtered ? "FilterSolid" : "Filter";

    const _text: string = text ? "Filter By " + text : "Filter";

    const options: JSX.Element[] = filters.map(filter => {
      return (
        <option key={filter.id} value={filter.id}>
          {filter.label}
        </option>
      );
    });

    return (
      <div className={"filterContains"} aria-expanded={this.state.isOpen}>
        <button
          type="button"
          className={"filterContainsDropdownButton"}
          disabled={dropdownDisabled}
          title={text}
          onClick={e => this._handleToggle(e)}
        >
          <Icon iconName={icon} />
          <span className={"srOnly"}>{_text}</span>
        </button>
        <div
          className={position === "right" ? "filterContainsDropdownRight" : "filterContainsDropdownLeft"}
          onKeyUp={e => _filterEnter(e)}
        >
          <Select
            id={"select-filter-" + id}
            name={"select-filter-" + id}
            onChange={_filterBy}
            options={options}
            placeholder="Filter by..."
            showPlaceholder={true}
            value={filterBy}
          />
          <Input
            id={"input-filter-" + id}
            inputType="text"
            name={"input-filter-" + id}
            onChange={_filterSearch}
            value={filterSearch}
          />
          <div className={"buttonGroup"}>
            <button
              type="button"
              className={"filterContainsButton"}
              disabled={filterDisabled}
              onClick={e => {
                this._handleFilter(e);
              }}
            >
              Filter
            </button>
            <button
              type="button"
              className={"filterContainsButton"}
              disabled={undoDisabled}
              onClick={e => {
                this._handleUndo(e);
              }}
            >
              Undo
            </button>
          </div>
        </div>
      </div>
    );
  }

  private _handleFilter(e: any): void {
    e.preventDefault();

    this.props._filter(e);

    this._handleToggle(e);
  }

  private _handleUndo(e: any): void {
    e.preventDefault();

    this.props._undoFilter(e);

    this._handleToggle(e);
  }

  private _handleToggle(e: any): void {
    e.preventDefault();

    this.setState({
      isOpen: this.state.isOpen ? false : true
    });
  }

  private _handleClickOutside(e: any): void {
    const domNode: Element | Text | null = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        isOpen: false
      });
    }
  }
}
