import { getRequest } from "../core/api/requestConfig";

export const getPayrollPeriods = (payAreaId: number) => {
  return new Promise<any>((resolve, reject) => {
    getRequest("/legacy/api/v1/payrollperiod/payarea/" + payAreaId)
      .then(payrollPeriods => {
        if (payrollPeriods && payrollPeriods.length) {
          return resolve(payrollPeriods);
        } else {
          return resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        return reject(null);
      });
  });
};

export const getActivePayrollPeriods = (payAreaId: number) => {
  return new Promise<any>((resolve, reject) => {
    getRequest("/legacy/api/v1/payrollperiod/payarea/" + payAreaId + "/active")
      .then(payrollPeriods => {
        if (payrollPeriods && payrollPeriods.length) {
          return resolve(payrollPeriods);
        } else {
          return resolve(null);
        }
      })
      .catch(error => {
        console.log(error);
        return reject(null);
      });
  });
};
