import * as React from "react";

class Section extends React.Component {
  public render() {
    return (
      <section className="section">
        <div className="sectionInner">{this.props.children}</div>
      </section>
    );
  }
}

export default Section;
