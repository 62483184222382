import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { Icon } from "office-ui-fabric-react";
import Select from "react-select";
import { orderBy } from "lodash";
// components
import Alert from "../../../components/alert/Alert";
import Band from "../../../components/layout/Band";
import Button from "../../../components/button/Button";
import ButtonGroup from "../../../components/button/ButtonGroup";
import DateTimePicker from "../../../components/input/DateTimePicker";
import Documents from "../../../components/documents/Documents";
import Editor from "../../../components/editor";
import ErrorUnauthorized from "../../../components/errors/ErrorUnauthorized";
import Field from "../../../components/forms/Field";
import Form from "../../../components/forms/Form";
import { H1, Hr, P } from "../../../components/typography";
import Input from "../../../components/input/Input";
import Label from "../../../components/label/Label";
import Loader from "../../../components/loader/Loader";
import LoaderWrapper from "../../../components/loader/LoaderWrapper";
import Notes from "../../../components/note/Notes";
import RequestAuditList from "../../../components/shared-services/RequestAuditList";
import RequestExport from "./RequestExport";
import RequestMetadata from "../../../components/shared-services/RequestMetadata";
import RequestDetailAdditionalUsers from "../../../components/shared-services/RequestDetailAdditionalUsers";
import RequestsSave from "../../../components/shared-services/RequestSave";
import Section from "../../../components/layout/Section";
import Tasks from "../../../components/shared-services/Tasks";
import Textarea from "../../../components/input/Textarea";
import Validation from "../../../components/validation/Validation";
import { Columns } from "../../../components/shared-services/Columns";
// containers
import Permissions from "../../permissions/Permissions";
// helpers
import { isHrRequest } from "../../../helpers/request";
import { shortFriendlyDateTime, payrollPeriodDates, fullFriendlyDateTime } from "../../../helpers/time";
import { requestTypeHierarchy } from "../../../helpers/requestType";
import { isEdgeBrowser, isIEBrowser } from "../../../helpers/browser";
import { getBSNADisabledDates, isWeekend, isHoliday } from "../../../helpers/date";
// hocs
import { withPageLog } from "../../logging/LogComponentChange";
// services
import { postDocs, deleteDoc, getDocUrl } from "../../../services/Doc";
import { getNotes, postNote } from "../../../services/Note";
import { getRequest as getR, putRequest as putR } from "../../../core/api/requestConfig";
import { getAssignees } from "../../../services/User";
import { getBottlerEmployeeMatch } from "../../../services/BottlerEmployee";
import { IRequestTypeV2 } from "../../../types/RequestType";
import { getCurrencyTypes } from "../../../services/CurrencyType";
import { getPayAreas } from "../../../services/PayArea";
// types
import { IBottler } from "../../../types/Bottler";
import { ICurrencyType } from "../../../types/CurrencyType";
import { IDoc, IDocs, IDocDeleted } from "../../../types/Doc";
import { IError } from "../../../types/Error";
import { INote } from "../../../types/Note";
import { IPayArea } from "../../../types/PayArea";
import { IPayrollPeriod } from "../../../types/PayrollPeriod";
import { IRequest, IRequestUpdate } from "../../../types/Request";
import { IRequestType } from "../../../types/RequestType";
import { IUser } from "../../../types/User";
import { ProcessingError } from "../../../types/ProcessingError";
import { Row, Task, Rows } from "../../../types/Task";
import { IWageType } from "../../../types/WageType";
import { IBottlerEmployeeMatch } from "../../../types/BottlerEmployee";
import { IWysiwyg } from "../../../types/Wysiwyg";
// redux
import { getBottlersCreator } from "../../../core/bottler/bottlers";
const unassignedUser = {
  id: 0,
  adGroupObjectIds: [],
  androidNotification: null,
  emailNotification: null,
  iosNotification: null,
  requestTypes: [],
  createdAt: "",
  deletedAt: null,
  updatedAt: "",
  username: null,
  firstname: null,
  lastname: null,
  memberid: null,
  groups: null,
  email: "",
  name: null,
  uuid: null,
  azureObjectId: null,
  isManager: false,
  value: null,
  label: "Unassigned",
  webNotification: null
};

export interface IRequestDetailProps {
  bottlers: IBottler[];
  copyPasteImagesFF: boolean;
  creating: boolean;
  editing: boolean;
  getBottlersCreator: Function;
  history: any;
  isModal?: boolean;
  match: any;
  pageContext: any;
  requestId: number;
  requestUpdated?: Function;
  requestPostPilotFF: boolean;
  user: IUser;
}

export interface IRequestDetailState {
  assignedTo: IUser | null;
  assignedToNew: IUser | null; // need to track loaded (assignedTo) and change (assignedToNew) for dueDateReminderSent
  assignedToDirty: boolean;
  assignees: IUser[];
  assigneesLoading: boolean;
  bottler: IBottler | null;
  checked: number;
  checking: boolean;
  columns: any[];
  currencyTypes: ICurrencyType[];
  description: JSX.Element[];
  descriptionDirty: boolean;
  dirty: boolean;
  dueDateNew: string | null;
  dueDateDirty: boolean;
  duplicates: Rows[];
  editing: boolean;
  error: boolean;
  didUpdateTime: boolean;
  docs: IDoc[];
  docsDirty: boolean;
  docsNew: any[];
  invalid: ProcessingError[];
  isWysiwyg: boolean;
  loading: boolean;
  merged: number;
  mergee: Row[];
  merges: Row[];
  merging: boolean;
  notesDirty: boolean;
  notesExisting: INote[];
  notesNew: INote[];
  notesSaving: boolean;
  payArea: IPayArea | null;
  payAreas: IPayArea[];
  payrollPeriod: IPayrollPeriod | null;
  payrollPeriods: IPayrollPeriod[];
  permission: boolean;
  request: IRequest | null;
  requestType: IRequestType | null;
  set: number;
  selectedRequestType: IRequestTypeV2 | null;
  selectedRequestPriority: {
    label: string;
    value: number;
  } | null;
  showHistory: boolean;
  step: number;
  stepping: boolean;
  suggestions: Row[];
  summaryDirty: boolean;
  tasks: Row[];
  unmatched: ProcessingError[];
  wageTypes: IWageType[];
}

class RequestDetail extends React.Component<IRequestDetailProps, IRequestDetailState> {
  state = {
    assignedTo: null,
    assignedToDirty: false,
    assignedToNew: null,
    assignees: [],
    assigneesLoading: false,
    bottler: null,
    checked: 0,
    checking: false,
    columns: [],
    currencyTypes: [],
    description: [],
    descriptionDirty: false,
    didUpdateTime: false,
    dirty: false,
    dueDateDirty: false,
    dueDateNew: null,
    duplicates: [],
    editing: true,
    error: false,
    docs: [],
    docsDirty: false,
    docsNew: [],
    invalid: [],
    isWysiwyg: true,
    loading: true,
    merged: 0,
    mergee: [],
    merges: [],
    merging: false,
    notesDirty: false,
    notesExisting: [],
    notesNew: [],
    notesSaving: false,
    payArea: null,
    payAreas: [],
    payrollPeriod: null,
    payrollPeriods: [],
    permission: true,
    request: null,
    requestType: null,
    set: 0,
    selectedRequestType: null,
    selectedRequestPriority: null,
    showHistory: false,
    step: 0,
    stepping: false,
    suggestions: [],
    summaryDirty: false,
    tasks: [],
    unmatched: [],
    wageTypes: []
  } as IRequestDetailState;

  componentDidMount(): void {
    const { bottlers, isModal } = this.props;
    if (!bottlers) {
      this.props.getBottlersCreator();
    }
    let requestId: number;
    if (isModal) {
      requestId = this.props.requestId;
    } else {
      requestId = this.props.match.params.requestId;
    }
    if (requestId) {
      this.loadRequest(Number(requestId));
    } else {
      toast.error("Request ID is not valid.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({
        error: true,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps: IRequestDetailProps, prevState: IRequestDetailState) {
    const { request } = this.state;
    const { bottlers } = this.props;
    // finds the selected bottler based on all the bottlers and the request bottlerId
    if (prevState.request !== request && bottlers && bottlers.length > 0) {
      if (request) {
        const bottler = bottlers.find(b => request && b.id === request.bottlerId);
        if (bottler) {
          this.setState({ bottler });
        }
      }
    }
  }

  renderDetails = () => {
    const { user, requestPostPilotFF, isModal } = this.props;

    let requestId: number;

    if (isModal) {
      requestId = this.props.requestId;
    } else {
      requestId = this.props.match.params.requestId;
    }

    const {
      assignedToNew,
      assignees,
      assigneesLoading,
      bottler,
      checked,
      checking,
      columns,
      currencyTypes,
      description,
      dirty,
      docs,
      docsDirty,
      dueDateDirty,
      docsNew,
      dueDateNew,
      duplicates,
      editing,
      error,
      invalid,
      isWysiwyg,
      loading,
      merged,
      mergee,
      merges,
      merging,
      notesExisting,
      notesNew,
      notesSaving,
      payArea,
      payAreas,
      payrollPeriod,
      permission,
      request,
      requestType,
      set,
      selectedRequestType,
      selectedRequestPriority,
      showHistory,
      step,
      stepping,
      suggestions,
      tasks,
      unmatched,
      wageTypes
    } = this.state;

    const hrRequest: boolean = isHrRequest(request && request.requestTypeId ? request.requestTypeId : null);
    const requestExport: JSX.Element | null = this.renderRequestExport();
    return (
      <>
        {error && !loading && <P>Error retrieving request.</P>}
        {!permission && !loading && <ErrorUnauthorized errorMessage="You do not have permission to view or edit this request." />}

        {permission && (
          <LoaderWrapper>
            <RequestMetadata
              bottlerName={bottler ? bottler.name : "Loading..."}
              isHrRequest={hrRequest}
              canChangeRequestTypes={user.isManager || (request && request.assignedToUser && request.assignedToUser.name === user.name) || false}
              handleRequestPriority={option => this.setState({ selectedRequestPriority: option, dirty: true })}
              handleRequestTypeChange={this.handleRequestTypeChange}
              assignedTo={
                !hrRequest && request && (request.assignedTo || assignees.length > 0 || assigneesLoading) ? (
                  <div className="assignedTo">
                    <Label className="h6" htmlFor="assign-to" text="Assigned To" />
                    <Select
                      className="reactSelect"
                      classNamePrefix="reactSelect"
                      inputId="assign-to"
                      isLoading={assigneesLoading}
                      isDisabled={assigneesLoading}
                      onChange={(value: any) => this.handleAssignee(value)}
                      options={assignees}
                      placeholder="Assign a person..."
                      value={assignedToNew ? assignedToNew : null}
                    />
                  </div>
                ) : null
              }
              createdBy={request && request.user && request.user.name ? request.user.name : request && request.user ? request.user.email : "Loading..."}
              createdAt={request ? shortFriendlyDateTime(request.createdAt) : "Loading..."}
              updatedAt={request ? shortFriendlyDateTime(request.updatedAt) : "Loading..."}
              updatedAtHistory={
                <>
                  <span>Last Updated</span>
                  {requestPostPilotFF && (
                    <Button
                      className="buttonHistory"
                      onClick={() =>
                        this.setState({
                          showHistory: !showHistory
                        })
                      }
                      iconLeft={<Icon iconName={showHistory ? "SkypeCircleMinus" : "CirclePlus"} />}
                      text={showHistory ? "Hide History" : "View History"}
                      title={showHistory ? "Hide History" : "View History"}
                    />
                  )}
                </>
              }
              dueDate={
                !hrRequest && request ? (
                  <>
                    <Label className="h6" htmlFor="dueDate" required={true} text="Due Date" />
                    <DateTimePicker
                      id="dueDate"
                      onChange={(newDate: Date) => {
                        this.setState({ didUpdateTime: false }, () => this.handleDueDateChange(newDate));
                      }}
                      onChangeDateTime={(newDate: Date) => {
                        this.setState({ didUpdateTime: true }, () => this.handleDueDateChange(newDate));
                      }}
                      placeholder="Select Date..."
                      minDate={moment().startOf("day")}
                      maxDate={moment().endOf("year")}
                      restrictedDates={getBSNADisabledDates()}
                      date={dueDateNew || request.dueDate || null}
                      minTime={dueDateNew && moment(dueDateNew).isAfter(moment()) ? moment("8:00:00 AM", "HH:mm:SS A") : moment()}
                      maxTime={moment("5:00:00 PM", "HH:mm:SS A")}
                      showTime={true}
                      time={dueDateNew || request.dueDate || null}
                      hideOutsideDateTimes={true}
                    />
                    <Validation showValidation={dueDateNew === null} text="Due date is required." type="Error" />
                  </>
                ) : null
              }
              payArea={payArea ? payArea.label : "Loading..."}
              payrollPeriod={payrollPeriod ? payrollPeriodDates(payrollPeriod.startDate, payrollPeriod.endDate, payrollPeriod.checkDate) : "Loading..."}
              requestType={request ? requestTypeHierarchy(request) : "Loading..."}
              requestPostPilotFF={requestPostPilotFF}
              requestPriority={(selectedRequestPriority && selectedRequestPriority.label) || (request && request.requestPriority.name)}
              selectedRequestTypeId={selectedRequestType ? selectedRequestType.id : request && request.requestType.id}
              status={request && request.status ? request.status.label : "Loading..."}
            />

            {request && showHistory && (
              <div className="requestAuditListWrapper">
                <RequestAuditList
                  bottlerName={bottler ? bottler.name : ""}
                  requestId={requestId}
                  userName={(request && request.user && request.user.name) || ""}
                  createdAt={request.createdAt}
                />
                <Hr />
              </div>
            )}

            {!hrRequest && (
              <div className="requestFormInputs">
                <Field>
                  <Label id="summaryLabel" htmlFor="summary" title="Request Summary" text="Request Summary" required={true} />
                  <Input id="summary" name="summary" onChange={e => this.handleSummary(e)} value={!loading && editing ? (request && request.summary ? request.summary : "") : ""} />
                </Field>
                <Field>
                  <Label id="descriptionLabel" htmlFor="description" title="Request Description" text="Request Description" required={true} />
                  {requestPostPilotFF && request && !isEdgeBrowser() && !isIEBrowser() && (
                    <Editor
                      readOnly={request && (request.statusId === 3 || request.statusId === 4)}
                      // need to pass in default value of " " so it doesn't break the editor
                      value={request.description || " "}
                      onChange={value => this.handleEditorChange(value)}
                    />
                  )}
                  {requestPostPilotFF && request && (isEdgeBrowser() || isIEBrowser()) && isWysiwyg && (
                    <>
                      <div className="requestDescription">{description}</div>
                      <Alert
                        show={true}
                        type="Warning"
                        text="Your browser does not support advanced text editing. Please use Chrome, Firefox, or Safari to edit the request description."
                      />
                    </>
                  )}
                  {requestPostPilotFF && request && (isEdgeBrowser() || isIEBrowser()) && !isWysiwyg && (
                    <>
                      <Textarea
                        id="description"
                        name="description"
                        onChange={e => this.handleDescription(e)}
                        readOnly={request && (request.statusId === 3 || request.statusId === 4)}
                        value={!loading && editing ? (request && request.description ? request.description : "") : ""}
                      />
                    </>
                  )}

                  {!requestPostPilotFF && request && (
                    <Textarea
                      id="description"
                      name="description"
                      onChange={e => this.handleDescription(e)}
                      readOnly={request && (request.statusId === 3 || request.statusId === 4)}
                      value={!loading && editing ? (request && request.description ? request.description : "") : ""}
                    />
                  )}
                </Field>
                <Hr />
              </div>
            )}

            {hrRequest && (
              <div>
                <Tasks
                  _addTask={this.addTask}
                  _cancelDuplicates={this.cancelDuplicates}
                  _cancelMerge={this.cancelMerge}
                  _dateChange={this.dateChange}
                  _checkChange={this.checkChange}
                  _handleCheckAll={this.handleCheckAll}
                  _handleNoteAdd={this.handleNoteAdd}
                  _inputBlur={this.inputBlur}
                  _inputChange={this.inputChange}
                  _openDuplicates={this.openDuplicates}
                  _openMerge={this.openMerge}
                  _removeTask={this.removeTask}
                  _saveDuplicates={this.saveDuplicates}
                  _saveMerge={this.saveMerge}
                  _setDuplicate={this.setDuplicate}
                  _stepThroughDuplicates={this.stepThroughDuplicates}
                  checked={checked}
                  checking={checking}
                  columns={columns}
                  currencyTypes={currencyTypes}
                  duplicates={duplicates}
                  editing={editing}
                  invalid={invalid}
                  merged={merged}
                  mergee={mergee}
                  merges={merges}
                  merging={merging}
                  loading={loading}
                  payAreas={payAreas}
                  payrollPeriod={payrollPeriod}
                  requestType={requestType}
                  set={set}
                  statusId={request && request.statusId ? request.statusId : null}
                  step={step}
                  stepping={stepping}
                  suggestions={suggestions}
                  tasks={tasks}
                  unmatched={unmatched}
                  wageTypes={wageTypes}
                />
                <Hr />
              </div>
            )}

            <div>
              <Notes
                _handleNoteAdd={this.handleNoteAdd}
                _handleNoteChange={this.handleNoteChange}
                _handleNoteDelete={this.handleNoteDelete}
                _handleNoteSave={this.handleNoteSave}
                notesExisting={notesExisting}
                notesNew={notesNew}
                notesSaving={notesSaving}
              />
              <Hr />
            </div>

            {!hrRequest && (
              <Documents
                docDelete={this.handleDocDelete}
                docDownload={this.handleDocDownload}
                docNewAdd={this.handleDocNewAdd}
                docNewDelete={this.handleDocNewDelete}
                docs={docs}
                docsDirty={docsDirty}
                docsNew={docsNew}
                requestPostPilotFF={requestPostPilotFF}
              />
            )}

            {request && request.bottler && request.bottler.domain && request.user && !hrRequest && requestPostPilotFF && (
              <RequestDetailAdditionalUsers
                assignees={assignees}
                bottlerDomain={request.bottler.domain}
                initialAdditionalUsers={request.additionalUsers}
                isLoading={assigneesLoading}
                requestId={request.id}
                requestUser={request.user}
              />
            )}

            {!hrRequest && (
              <ButtonGroup>
                <Button
                  className={request && request.statusId === 2 ? "buttonLarge buttonGeneralSelected" : "buttonLarge buttonGeneral"}
                  disabled={!request || !dirty || (dueDateNew === null && dueDateDirty)}
                  iconLeft={<Icon iconName="Save" />}
                  onClick={e => {
                    this.saveRequest(e, 2);
                  }}
                  text="Save But Not Yet Worked On"
                  title={!dirty ? "Make a change to save the request." : ""}
                />

                <Button
                  className={request && request.statusId === 3 ? "buttonLarge buttonGeneralSelected" : "buttonLarge buttonGeneral"}
                  disabled={!request || !dirty || (dueDateNew === null && dueDateDirty)}
                  iconLeft={<Icon iconName="Processing" />}
                  onClick={e => {
                    this.saveRequest(e, 3);
                  }}
                  text="Save As Working On It"
                  title={!dirty ? "Make a change to save the request." : ""}
                />

                <Button
                  className={request && request.statusId === 4 ? "buttonLarge buttonGeneralSelected" : "buttonLarge buttonGeneral"}
                  disabled={!request || !dirty || (dueDateNew === null && dueDateDirty)}
                  iconLeft={<Icon iconName="CompletedSolid" />}
                  onClick={e => {
                    this.saveRequest(e, 4);
                  }}
                  text="Save As Completed"
                  title={!dirty ? "Make a change to save the request." : ""}
                />
              </ButtonGroup>
            )}

            {!hrRequest && (
              <P className="helpText">
                Current status: {request && request.statusId === 2 ? "Not Yet Worked On" : request && request.statusId === 3 ? "Working On It" : "Completed"}
                {". "}
                {!dirty && <em>Make a change to save the request.</em>}
              </P>
            )}

            {hrRequest && (
              <div className="requestActions">
                <RequestsSave _saveRequest={this.saveHrRequest} admin={true} invalid={invalid.length} loading={loading} tasks={tasks.length} unmatched={unmatched.length} />
                {requestExport}
              </div>
            )}
            <Loader loading={loading} type="Overlay" showImage={true} position="Top" />
          </LoaderWrapper>
        )}
      </>
    );
  };

  render() {
    const { isModal } = this.props;
    const { error, loading, request } = this.state;

    const title: string = !error && request ? ` / Edit Request #${request.id}` : !error && loading ? ` / Loading...` : ``;

    return isModal ? (
      this.renderDetails()
    ) : (
      <Form className="requestDetail">
        <Band>
          <H1>
            <Link to="/shared-services/requests" onClick={e => this.backToRequests(e)}>
              Requests
            </Link>
            {title}
          </H1>
        </Band>
        <Section>
          <Permissions title={title}>{this.renderDetails()}</Permissions>
        </Section>
        <ToastContainer />
      </Form>
    );
  }

  handleRequestTypeChange = (option: { value: IRequestTypeV2; label: string }) => {
    let newDueDate: string | null = null;
    if (option.value.sla) {
      const newDateWithSla: moment.Moment = moment().add(option.value.sla, "hours");
      if (!isWeekend(newDateWithSla) && !isHoliday(newDateWithSla.toDate())) {
        newDueDate = newDateWithSla.toISOString();
      }
      if (newDateWithSla.format("dddd") === "Saturday") {
        newDueDate = newDateWithSla.add(2, "days").toISOString();
      } else if (newDateWithSla.format("dddd") === "Sunday" || isHoliday(newDateWithSla.toDate())) {
        newDueDate = newDateWithSla.add(1, "days").toISOString();
      }
    }
    this.setState(
      {
        selectedRequestType: option.value,
        dueDateNew: newDueDate,
        dueDateDirty: true,
        assignedToNew: null
      },
      () => {
        this.loadAssignees(option.value.adGroupAzureObjectId);
        this.setDirty();
      }
    );
  };

  detectWysiwyg = () => {
    const { request } = this.state;

    let description: JSX.Element[] = [];
    let isWysiwyg: boolean = false;

    if (request && request.description) {
      if (request.description.includes("object") && request.description.includes("document") && request.description.includes("nodes") && request.description.includes("leaves")) {
        isWysiwyg = true;
        description = this.renderDescription(request.description);
      }
    }

    this.setState({
      description,
      isWysiwyg
    });
  };

  renderDescription = (requestDescription: string | null): JSX.Element[] => {
    let description: JSX.Element[] = [];

    if (requestDescription) {
      const wysiwyg: IWysiwyg = JSON.parse(requestDescription);

      if (wysiwyg && wysiwyg.document) {
        wysiwyg.document.nodes.map(node => {
          if (node.type === "paragraph") {
            const leaves = node.nodes[0].leaves;

            if (leaves && leaves.length > 0) {
              description.push(<p>{leaves[0].text}</p>);
            }
          }

          if (node.type === "bulleted-list") {
            const bullets: JSX.Element[] = [];

            node.nodes.map(_node => {
              const leaves = _node.nodes[0].leaves;

              if (leaves && leaves.length > 0) {
                bullets.push(<li>{leaves[0].text}</li>);
              }
            });

            description.push(<ul>{bullets}</ul>);
          }
        });
      }
    }

    return description;
  };

  loadRequest = async (requestId: number): Promise<void> => {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const request: IRequest = await getR(`/legacy/api/v1/request/${requestId}`);
          if (request) {
            const { requestType } = request;

            if (requestType) {
              this.loadAssignees(requestType.adGroupAzureObjectId);
            }

            const permission: boolean = this.verifyPermission(request.requestTypeId);

            this.setState(
              {
                docs: request.docs ? request.docs : [],
                docsNew: [],
                dueDateNew: request && request.dueDate,
                editing: true,
                error: false,
                loading: false,
                notesExisting: request.notes ? request.notes : [],
                notesNew: [],
                payArea: request.payArea ? request.payArea : null,
                payrollPeriod: request.payrollPeriod ? request.payrollPeriod : null,
                permission: permission,
                request: request,
                requestType: request.requestType ? request.requestType : null,
                wageTypes: request.requestType && request.requestType.wageTypes ? request.requestType.wageTypes : []
              },
              () => {
                this.setDirty(true);
                this.detectWysiwyg();
                const hrRequest: boolean = isHrRequest(request && request.requestTypeId ? request.requestTypeId : null);

                if (hrRequest) {
                  this.getCurrencyTypes();
                  this.getPayAreas();
                  this.getPayrollPeriods();
                  this.handleColumns();
                  this.handleTasks(request.tasks ? request.tasks : []);
                }
              }
            );
          } else {
            toast.error("Request not found.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState(
              {
                docs: [],
                docsNew: [],
                editing: false,
                error: true,
                loading: false,
                notesExisting: [],
                notesNew: [],
                payArea: null,
                payrollPeriod: null,
                request: null,
                requestType: null,
                tasks: []
              },
              () => {
                this.setDirty(true);
              }
            );
          }
        } catch (error) {
          toast.error("Error retrieving request.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState(
            {
              docs: [],
              docsNew: [],
              editing: false,
              error: true,
              loading: false,
              notesExisting: [],
              notesNew: [],
              payArea: null,
              payrollPeriod: null,
              request: null,
              requestType: null,
              tasks: []
            },
            () => {
              this.setDirty(true);
            }
          );
        }
      }
    );
  };

  loadAssignees = async (adGroupId: string): Promise<void> => {
    this.setState(
      {
        assigneesLoading: true
      },
      async () => {
        try {
          const assigneesResponse: IUser[] = await getAssignees(adGroupId);

          const assignees: IUser[] = assigneesResponse.map(assignee => ({
            ...assignee,
            value: `${assignee.id}`,
            label: assignee.name || assignee.label
          }));
          // push into the array a mock assignee that is "Unassigned"
          assignees.unshift(unassignedUser);
          this.setState(
            {
              assignees,
              assigneesLoading: false
            },
            () => {
              const { request } = this.state;

              if (request && request.assignedTo) {
                const selectedAssignee: IUser | any = assignees.find(assignee => assignee.id === request.assignedTo);
                this.setState({
                  assignedTo: selectedAssignee,
                  assignedToNew: selectedAssignee
                });
              } else {
                this.setState({
                  assignedTo: unassignedUser,
                  assignedToNew: unassignedUser
                });
              }
            }
          );
        } catch (error) {
          toast.error(error.error, {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            assigneesLoading: false
          });
        }
      }
    );
  };

  handleAssignee = (assignedTo: IUser | any[]) => {
    // reselect returns IUser object or an empty array if no assignedTo selected
    this.setState(
      {
        assignedToNew: Array.isArray(assignedTo) ? null : assignedTo,
        assignedToDirty: true
      },
      () => {
        this.setDirty();
      }
    );
  };

  handleDueDateChange = (date: Date): void => {
    if (date) {
      let dueDateNew: string = "";
      // only changed the time
      if (this.state.didUpdateTime) {
        dueDateNew = moment(date).toISOString();
        // set the date to today so use the current time/date
      } else if (moment(date).isBefore(moment())) {
        dueDateNew = moment().toISOString();
        // set the date to a future date
      } else {
        dueDateNew = moment(date)
          .add(8, "hours")
          .toISOString();
      }

      this.setState(
        {
          dueDateNew,
          dueDateDirty: true
        },
        () => {
          this.setDirty();
        }
      );
    } else {
      this.setState(
        {
          dueDateNew: null,
          dueDateDirty: true
        },
        () => {
          this.setDirty();
        }
      );
    }
  };

  handleSummary = (e: React.FormEvent<HTMLInputElement>): void => {
    const { request } = this.state;
    const value: string = e.currentTarget.value;

    if (request) {
      request.summary = value;

      this.setState(
        {
          request: request,
          summaryDirty: true
        },
        () => {
          this.setDirty();
        }
      );
    }
  };

  handleEditorChange = (value: string) => {
    const { request } = this.state;

    if (request) {
      request.description = value;

      this.setState(
        {
          descriptionDirty: true,
          request: request
        },
        () => {
          this.setDirty();
        }
      );
    }
  };

  handleDescription = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    const { request } = this.state;
    const value: string = e.currentTarget.value;

    if (request) {
      request.description = value;

      this.setState(
        {
          descriptionDirty: true,
          request: request
        },
        () => {
          this.setDirty();
        }
      );
    }
  };

  saveRequest = (e: React.MouseEvent<HTMLButtonElement>, statusId: number): void => {
    e.preventDefault();
    const { requestUpdated, user } = this.props;

    const { assignedToNew, dueDateNew, editing, docsNew, notesNew, request, selectedRequestType, selectedRequestPriority } = this.state;

    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          if (!request) {
            toast.error("Error updating request.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              loading: false
            });
          } else {
            const now: string = moment().toISOString();

            const update: IRequest = request;
            // updatedAt & updatedAtFormatted
            update.updatedAt = now;
            update.updatedAtFormatted = fullFriendlyDateTime(now);
            update.updatedByUserId = user.id;
            // notes
            const notes: INote[] = [];
            notesNew.map(note => {
              if (note.text) {
                notes.push(note);
              }
            });
            update.notes = notes;
            // seenSinceLastUpdate
            update.seenSinceLastUpdate = false;
            // updatedByAdmin
            if (statusId !== request.statusId) {
              update.updatedByAdmin = true;
            }
            // dueDateReminderSent
            if ((assignedToNew && assignedToNew.id !== request.assignedTo) || (dueDateNew && dueDateNew !== request.dueDate)) {
              update.dueDateReminderSent = false;
            }
            // assignedTo, there is also a check if the assignToNew.id is 0 which is the "Unassigned" option
            update.assignedTo = assignedToNew && assignedToNew.id !== 0 ? assignedToNew.id : null;
            // dueDate
            update.dueDate = dueDateNew ? dueDateNew : request.dueDate;
            // statusId
            update.statusId = statusId;
            // requestTypeId
            if (selectedRequestType) {
              update.requestTypeId = selectedRequestType.id;
            }
            if (selectedRequestPriority) {
              update.requestPriorityId = selectedRequestPriority.value;
            }
            // save docs
            let docs: IDocs = {
              docs: []
            };
            let docsError: boolean = false;
            if (docsNew && docsNew.length > 0) {
              docs = await postDocs(request.id.toString(), request.uuid, docsNew, user.id.toString());
              if (docs.error) {
                docsError = true;
              }
            }
            // save request
            const updatedRequest: IRequest = await putR("/legacy/api/v1/request", update);
            if (!docsError && request) {
              toast.success("Request updated successfully.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              if (requestUpdated) {
                requestUpdated();
              }
              this.loadRequest(updatedRequest.id);
            } else {
              const errorMessage: string = editing ? "Error updating request." : "Error creating request.";
              throw Error(errorMessage);
            }
          }
        } catch (error) {
          if (error.error) {
            toast.error(error.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          } else {
            const errorMessage: string = editing ? "Error updating request." : "Error creating request.";
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  saveHrRequest = (e: React.MouseEvent<HTMLButtonElement>, statusId: number): void => {
    e.preventDefault();

    this.setState(
      {
        loading: true
      },
      () => {
        const { user, requestUpdated } = this.props;
        const { bottler, notesExisting, notesNew, payrollPeriod, request, requestType, tasks, selectedRequestType, selectedRequestPriority } = this.state;

        if (tasks.length === 0) {
          toast.error("Please add a task to submit a request.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({ loading: false });
        } else if (bottler && request && requestType && payrollPeriod && payrollPeriod.id) {
          const now: string = moment().toISOString();

          const newNotes: INote[] = [];
          notesNew.map(note => {
            if (note && note.text !== "") {
              newNotes.push(note);
            }
          });

          const updateRequest: IRequestUpdate = {
            assignedTo: null,
            bottlerId: bottler.id,
            createdAt: request.createdAt,
            createdAtFormatted: fullFriendlyDateTime(request.createdAt),
            deletedAt: null,
            description: null,
            dueDate: null,
            dueDateReminderSent: false,
            id: request.id,
            notes: newNotes.concat(notesExisting),
            payrollPeriodId: payrollPeriod.id,
            requestImportFilename: request.requestImportFilename,
            requestTypeId: (selectedRequestType && selectedRequestType.id) || requestType.id,
            requestPriority: selectedRequestPriority
              ? {
                  id: selectedRequestPriority.value,
                  name: selectedRequestPriority.label
                }
              : request.requestPriority,
            requestPriorityId: (selectedRequestPriority && selectedRequestPriority.value) || request.requestPriorityId,
            seenSinceLastUpdate: false,
            // 2 = New, 3 = BSNA Processing, 4 = Completed
            // 1 = Draft, but admin's can't draft
            statusId: statusId,
            summary: null,
            title: request.title,
            updatedAt: now,
            updatedAtFormatted: fullFriendlyDateTime(now),
            updatedByAdmin: statusId !== request.statusId ? true : false,
            updatedByUserId: user.id,
            userId: request.userId,
            uuid: request.uuid
          };

          putR("/legacy/api/v1/request", updateRequest)
            .then(requestData => {
              if (requestData.error) {
                toast.error(requestData.error, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                  loading: false
                });
              } else {
                const updateTasks: any[] = tasks.slice();

                const saveTasks: any[] = [];
                updateTasks.map(row => {
                  if (row && row.data) {
                    row.data.updatedAt = now;
                    row.data.requestId = requestData.id;
                    saveTasks.push(row.data);
                  }
                });

                // todo: catch errors
                putR("/legacy/api/v1/task", saveTasks)
                  .then(taskData => {
                    if (taskData.error) {
                      toast.error(taskData.error, {
                        position: toast.POSITION.BOTTOM_CENTER
                      });

                      this.setState({
                        loading: false
                      });
                    } else {
                      this.setState({
                        dirty: false
                      });

                      toast.success("Request saved successfully.", {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                      if (requestUpdated) {
                        requestUpdated();
                      }
                      this.loadRequest(request.id);
                    }
                  })
                  .catch(error => {
                    console.error(error);
                    toast.error("Error saving request tasks.", {
                      position: toast.POSITION.BOTTOM_CENTER
                    });

                    this.setState({
                      loading: false
                    });
                  });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Error saving request.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                loading: false
              });
            });
        } else {
          toast.error("Error saving request.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({ loading: false });
        }
      }
    );
  };

  handleNoteAdd = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const { id } = this.props.user;
    const { request } = this.state;
    const notesNew: INote[] = this.state.notesNew.slice();
    const now: string = moment().toISOString();

    if (request && id) {
      notesNew.push({
        createdAt: now,
        updatedAt: now,
        createdByIsAdmin: true,
        deletedAt: null,
        text: "",
        requestId: request.id,
        userId: id
      });
    }
    this.setState(
      {
        notesDirty: true,
        notesNew: notesNew
      },
      () => {
        this.setDirty();
      }
    );
  };

  handleNoteChange = (noteIndex: number, text: string): void => {
    const notesNew: INote[] = this.state.notesNew.slice();
    notesNew[noteIndex].text = text;
    this.setState(
      {
        notesDirty: true,
        notesNew: notesNew
      },
      () => {
        this.setDirty();
      }
    );
  };

  handleNoteDelete = (e: React.MouseEvent<HTMLButtonElement>, noteIndex: number): void => {
    e.preventDefault();
    const notesNew = this.state.notesNew.slice();
    notesNew.pop();
    this.setState(
      {
        notesDirty: false,
        notesNew
      },
      () => {
        this.setDirty();
      }
    );
  };

  handleNoteSave = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    const noteNew: INote = this.state.notesNew[0];

    this.setState(
      {
        loading: false,
        notesSaving: true
      },
      async () => {
        try {
          const note: INote = await postNote(noteNew);

          if (note && note.id) {
            toast.success("Note saved successfully.", {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState(
              {
                loading: false,
                notesNew: [],
                notesSaving: false
              },
              () => this.loadNotes()
            );
          } else {
            toast.error("Error creating note.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              loading: false,
              notesSaving: false
            });
          }
        } catch (error) {
          toast.error(error.error ? error.error : "Error creating note.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            loading: false,
            notesSaving: false
          });
        }
      }
    );
  };
  loadNotes = (): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const { request } = this.state;
          if (request) {
            const notesExisiting: INote[] | IError = await getNotes(request.id);
            this.setState(
              {
                notesDirty: true,
                notesExisting: notesExisiting,
                loading: false
              },
              () => {
                this.setDirty();
              }
            );
          }
        } catch (error) {
          if (error.error) {
            toast.error(error.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          } else {
            toast.error("Error fetching notes.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  backToRequests = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    const { dirty } = this.state;

    if (dirty) {
      const result: boolean = window.confirm("You have unsaved changes. Are you sure you want to leave?");
      if (result) {
        localStorage.setItem("requestsView", "Grid");
        this.props.history.push("/shared-services/requests");
      }
    } else {
      localStorage.setItem("requestsView", "Grid");
      this.props.history.push("/shared-services/requests");
    }
  };

  handleDocNewAdd = (acceptedFiles: any[], rejectedFiles: any[]): void => {
    const docsNew: IDoc[] = this.state.docsNew.slice();

    if (acceptedFiles.length > 0) {
      acceptedFiles.map((acceptedFile, index) => {
        docsNew.push(acceptedFile);
      });
    }
    this.setState(
      {
        docsDirty: true,
        docsNew: docsNew
      },
      () => {
        this.setDirty();
      }
    );
  };

  handleDocNewDelete = (e: React.MouseEvent<HTMLButtonElement>, index: number): void => {
    const docsNew: IDoc[] = this.state.docsNew.slice();
    docsNew.splice(index, 1);
    this.setState(
      {
        docsDirty: docsNew.length > 0,
        docsNew: docsNew
      },
      () => {
        this.setDirty();
      }
    );
  };

  handleDocDelete = (e: React.MouseEvent<HTMLButtonElement>, index: number): void => {
    e.preventDefault();
    const result: boolean = window.confirm("Are you sure you want to delete this file?");
    if (result) {
      this.setState(
        {
          loading: true
        },
        async () => {
          const { user } = this.props;

          try {
            const docs: IDoc[] = this.state.docs.slice();
            const docDelete: IDoc = {
              ...docs[index],
              updatedByUserId: user.id
            };
            const deleted: IDocDeleted = await deleteDoc(docDelete);

            if (deleted.deleted) {
              docs.splice(index, 1);
              this.setState(
                {
                  docs: []
                },
                () => {
                  toast.success(deleted.success, {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                  this.setState({
                    loading: false,
                    docs: docs
                  });
                }
              );
            } else {
              toast.error(deleted.error, {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                loading: false
              });
            }
          } catch (error) {
            toast.error(error.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              loading: false
            });
          }
        }
      );
    }
  };

  handleDocDownload = (e: React.MouseEvent<HTMLAnchorElement>, name: string): void => {
    e.preventDefault();
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const url: any = await getDocUrl(name);
          if (url && url.url) {
            const anchor: HTMLAnchorElement = document.createElement("a");
            anchor.setAttribute("href", url.url);
            anchor.setAttribute("download", "download");
            anchor.click();
            const parent: Node | null = anchor.parentNode;
            if (parent) {
              parent.removeChild(anchor);
            }
            this.setState({
              loading: false
            });
          } else {
            toast.error("Error downloading document.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              loading: false
            });
          }
        } catch (error) {
          toast.error("Error downloading document.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  setDirty = (clearDirty?: boolean): void => {
    if (clearDirty) {
      this.setState({
        assignedToDirty: false,
        descriptionDirty: false,
        dirty: false,
        docsDirty: false,
        dueDateDirty: false,
        notesDirty: false,
        summaryDirty: false
      });
    } else {
      const { assignedToDirty, descriptionDirty, docsDirty, dueDateDirty, notesDirty, summaryDirty } = this.state;

      this.setState({
        dirty: assignedToDirty || descriptionDirty || docsDirty || dueDateDirty || notesDirty || summaryDirty ? true : false
      });
    }
  };

  verifyPermission = (requestTypeId: number): boolean => {
    const { user } = this.props;

    let hasPermission: boolean = false;

    if (requestTypeId && user && user.requestTypes) {
      user.requestTypes.map(requestType => {
        if (requestTypeId === requestType.RequestTypeID) {
          hasPermission = true;
        }
      });
    }

    return hasPermission;
  };

  renderRequestExport = (): JSX.Element | null => {
    const { payrollPeriod, request, requestType } = this.state;

    if (request && request.id && request.status && requestType) {
      return <RequestExport _afterExport={e => this.afterExport(request.id)} payrollPeriod={payrollPeriod} request={request} requestType={requestType} showExportHistory={true} />;
    } else {
      return null;
    }
  };

  afterExport = (requestId: number): void => {
    this.loadRequest(requestId);
  };

  // hr request detail
  getPayrollPeriods = (): void => {
    const { payArea, requestType } = this.state;

    if (requestType && payArea) {
      getR("/legacy/api/v1/payrollperiod/payarea/" + payArea.id + "/active/")
        .then(payrollPeriods => {
          this.setState({
            payrollPeriods: payrollPeriods
          });
        })
        .catch(error => {
          console.error(error);
          toast.error("There was an error fetching payroll periods.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        });
    }
  };

  getMatch = async (task: Row): Promise<IBottlerEmployeeMatch | null> => {
    const { bottler } = this.state;

    if (bottler && task) {
      const bottlerId: number | undefined = bottler.id;
      // typescript is being weird so these need declaring
      const firstName: string = task.data!.firstName;
      const middleInitial: string | null = task.data!.middleInitial;
      const lastName: string = task.data!.lastName;
      const nickname: string | null = task.data!.nickname;
      const suffix: string | null = task.data!.suffix;
      const pernr: string = task.data!.pernr;
      const costCenter: string = task.data!.costCenter;
      const payAreaId: number | undefined = task.data!.payAreaId;
      const match: IBottlerEmployeeMatch = await getBottlerEmployeeMatch(
        bottlerId ? bottlerId.toString() : " ",
        firstName ? firstName : " ",
        middleInitial ? middleInitial : " ",
        lastName ? lastName : " ",
        nickname ? nickname : " ",
        suffix ? suffix : " ",
        pernr ? pernr : " ",
        costCenter ? costCenter : " ",
        payAreaId ? payAreaId.toString() : " "
      );
      return match;
    } else {
      return null;
    }
  };

  checkMatch = async (rowIndex: number) => {
    const tasks: Row[] = this.state.tasks.slice();
    const task: Row = tasks[rowIndex];
    const match: IBottlerEmployeeMatch | null = await this.getMatch(task);
    if (match) {
      tasks[rowIndex]!.isMatched = match.id ? true : false;
      tasks[rowIndex]!.data!.bottlerEmployeeId = match.id ? match.id : undefined;
      this.setState(
        {
          tasks: tasks
        },
        () => {
          this.handleUnmatched();
        }
      );
    } else {
      toast.error("There was an error matching the task to an employee.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  checkMatches = async () => {
    const tasks: Row[] = this.state.tasks.slice();
    await Promise.all(
      tasks.map(async (task, rowIndex) => {
        if (task && !task.data!.isMerged) {
          const match: IBottlerEmployeeMatch | null = await this.getMatch(task);
          if (match) {
            tasks[rowIndex]!.isMatched = match.id ? true : false;
            tasks[rowIndex]!.data!.bottlerEmployeeId = match.id ? match.id : undefined;
          } else {
            toast.error("There was an error matching the task to an employee.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        }
      })
    );
    this.setState(
      {
        tasks: []
      },
      () => {
        this.setState(
          {
            tasks: tasks
          },
          () => {
            this.handleUnmatched();
          }
        );
      }
    );
  };

  getCurrencyTypes = async () => {
    try {
      const currencyTypes = await getCurrencyTypes();
      if (currencyTypes) {
        this.setState({
          currencyTypes: currencyTypes
        });
      } else {
        toast.error("There was an error fetching currency types.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.setState({
          currencyTypes: []
        });
      }
    } catch (error) {
      toast.error("There was an error fetching currency types.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({
        currencyTypes: []
      });
    }
  };

  getPayAreas = async () => {
    try {
      const payAreas = await getPayAreas();
      if (payAreas) {
        this.setState({
          payAreas: payAreas
        });
      } else {
        toast.error("There was an error fetching pay areas.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.setState({
          payAreas: []
        });
      }
    } catch (error) {
      toast.error("There was an error fetching pay areas.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({
        payAreas: []
      });
    }
  };

  handleColumns = (): void => {
    if (this.state.requestType) {
      const code: string = this.state.requestType.code;
      const columns: any[] = [];
      Columns.map((column, index) => {
        let add: any;
        let addColumn: boolean = false;
        Object.keys(column).forEach(type => {
          if (column[type][code] === true) {
            addColumn = true;
            add = column[type];
          }
        });
        if (addColumn) {
          columns.push(add);
        }
      });
      this.setState({
        columns: columns
      });
    }
  };

  handleTasks = (tasks: Task[]): void => {
    const { request, requestType } = this.state;

    if (tasks && requestType) {
      const code: string = requestType.code;
      const newTasks: any[] = [];
      if (tasks.length > 0) {
        tasks.map(task => {
          let newRow: Row = this.createRow(false, false, true);
          if (task && newRow && newRow.data) {
            newRow.data.deletedAt = task.deletedAt;
            newRow.data.isMerged = task.isMerged;
            newRow.data.isCompleted = task.isCompleted;
            newRow.data.id = task.id;
            newRow.data.bottlerEmployeeId = task.bottlerEmployee ? task.bottlerEmployee.id : undefined;
            newRow.data.firstName = task.bottlerEmployee ? task.bottlerEmployee.firstName : "";
            newRow.data.middleInitial = task.bottlerEmployee ? task.bottlerEmployee.middleInitial : "";
            newRow.data.lastName = task.bottlerEmployee ? task.bottlerEmployee.lastName : "";
            newRow.data.suffix = task.bottlerEmployee ? task.bottlerEmployee.suffix : "";
            newRow.data.nickname = task.bottlerEmployee ? task.bottlerEmployee.nickname : "";
            newRow.data.pernr = task.bottlerEmployee ? task.bottlerEmployee.pernr : "";
            newRow.data.costCenter = task.bottlerEmployee ? task.bottlerEmployee.costCenter : "";
            newRow.data.payAreaId = task.bottlerEmployee ? task.bottlerEmployee.payAreaId : undefined;
            newRow.data.wageTypeId = task.wageTypeId;
            if (code === "IT2010") {
              newRow.data.hours = task.hours ? task.hours : undefined;
              newRow.data.number = task.number ? task.number : undefined;
              newRow.data.rate = task.rate ? task.rate : undefined;
            }
            newRow.data.units = task.units ? task.units : undefined;
            newRow.data.dateOfPay = task.dateOfPay ? task.dateOfPay : null;
            newRow.data.amount = task.amount ? task.amount : undefined;
            newRow.data.currencyTypeId = task.currencyTypeId ? task.currencyTypeId : undefined;
            if (code === "IT0014") {
              newRow.data.dollarLimit = task.dollarLimit;
            }
            newRow.data.authority = task.authority;
            newRow.data.comment = task.comment;
          }
          newTasks.push(newRow);
        });
        this.setState(
          {
            loading: false,
            tasks: newTasks
          },
          () => {
            this.updateCount();
          }
        );
      } else if ((request && request.statusId === 1) || (request && request.statusId === 2)) {
        this.addRow(false);
        this.setState({
          loading: false
        });
      }
    }
  };

  dateChange = (date: Date | null, rowIndex: number, changeType: string | null): void => {
    if (date) {
      const iso: string = moment(date).toISOString();
      const tasks: Row[] = changeType === "merge" ? this.state.mergee.slice() : changeType === "suggestion" ? this.state.suggestions.slice() : this.state.tasks.slice();
      tasks![rowIndex]!.data!.dateOfPay = iso;
      if (changeType === "merge") {
        this.setState({
          dirty: true,
          mergee: tasks
        });
      } else if (changeType === "suggestion") {
        this.setState({
          dirty: true,
          suggestions: tasks
        });
      } else {
        this.setState({
          dirty: true,
          tasks: tasks
        });
      }
    }
  };

  inputChange = (e: any, rowIndex: number, changeType: string | null): void => {
    const value: string = e.target.value;
    const name: string = e.target.name;
    const type: string = e.target.type;
    const tasks: Row[] = changeType === "merge" ? this.state.mergee.slice() : changeType === "suggestion" ? this.state.suggestions.slice() : this.state.tasks.slice();
    if (tasks) {
      if (type === "text") {
        tasks[rowIndex]!.data![name] = value;
      } else {
        tasks[rowIndex]!.data![name] = value ? Number(value) : undefined;
      }
      tasks[rowIndex]!.autofocus = false;
    }
    if (name === "payAreaId") {
      this.handlePayAreaValidity(rowIndex);
    }
    if (changeType === "merge") {
      this.setState({
        dirty: true,
        mergee: tasks
      });
    } else if (changeType === "suggestion") {
      this.setState({
        dirty: true,
        suggestions: tasks
      });
    } else {
      this.setState({
        dirty: true,
        tasks: tasks
      });
    }
  };

  inputBlur = (e: any, rowIndex: number, blurType: string | null): void => {
    if (blurType !== "suggestion" && blurType !== "merge") {
      const value: string = e.target.value;
      const name: string = e.target.name;
      const required: boolean = e.target.required;
      const tasks: Row[] = this.state.tasks.slice();
      tasks[rowIndex]!.isTouched = true;
      tasks[rowIndex]!.autofocus = false;
      if (!value && required) {
        tasks[rowIndex]!.isValid = false;
      }
      if (name === "firstName") {
        tasks[rowIndex]!.firstNameValid = !value ? false : true;
        tasks[rowIndex]!.firstNameTouched = true;
      }
      if (name === "middleInitial") {
        tasks[rowIndex]!.middleInitialTouched = true;
      }
      if (name === "lastName") {
        tasks[rowIndex]!.lastNameValid = !value ? false : true;
        tasks[rowIndex]!.lastNameTouched = true;
      }
      if (name === "suffix") {
        tasks[rowIndex]!.suffixTouched = true;
      }
      if (name === "nickname") {
        tasks[rowIndex]!.nicknameTouched = true;
      }
      if (name === "pernr") {
        tasks[rowIndex]!.pernrValid = !value ? false : true;
        tasks[rowIndex]!.pernrTouched = true;
      }
      if (name === "costCenter") {
        tasks[rowIndex]!.costCenterValid = !value ? false : true;
        tasks[rowIndex]!.costCenterTouched = true;
      }
      if (name === "payAreaId") {
        tasks[rowIndex]!.payAreaIdValid = !value ? false : true;
        if (this.state.payArea && value) {
          tasks[rowIndex]!.payAreaIdValid = Number(value) !== this.state.payArea.id ? false : true;
        }
        tasks[rowIndex]!.payAreaIdTouched = true;
      }
      if (name === "wageTypeId") {
        tasks[rowIndex]!.wageTypeIdValid = !value ? false : true;
        tasks[rowIndex]!.wageTypeIdTouched = true;
      }
      if (name === "authority") {
        tasks[rowIndex]!.authorityValid = !value ? false : true;
        tasks[rowIndex]!.authorityTouched = true;
      }
      if (tasks[rowIndex]!.isManual && rowIndex === 0) {
        if (
          tasks[rowIndex]!.firstNameTouched &&
          tasks[rowIndex]!.lastNameTouched &&
          tasks[rowIndex]!.pernrTouched &&
          tasks[rowIndex]!.costCenterTouched &&
          tasks[rowIndex]!.payAreaIdTouched &&
          (name === "firstName" ||
            name === "middleInitial" ||
            name === "lastName" ||
            name === "suffix" ||
            name === "nickname" ||
            name === "pernr" ||
            name === "costCenter" ||
            name === "payAreaId")
        ) {
          this.checkMatch(rowIndex);
        }
        if (
          (tasks[rowIndex]!.firstNameTouched &&
            tasks[rowIndex]!.lastNameTouched &&
            tasks[rowIndex]!.pernrTouched &&
            tasks[rowIndex]!.costCenterTouched &&
            tasks[rowIndex]!.payAreaIdTouched &&
            tasks[rowIndex]!.wageTypeIdTouched &&
            tasks[rowIndex]!.authorityTouched) ||
          !tasks[rowIndex]!.firstNameValid ||
          !tasks[rowIndex]!.lastNameValid ||
          !tasks[rowIndex]!.pernrValid ||
          !tasks[rowIndex]!.costCenterValid ||
          !tasks[rowIndex]!.payAreaIdValid ||
          !tasks[rowIndex]!.wageTypeIdValid ||
          !tasks[rowIndex]!.authorityValid
        ) {
          this.handleValidity(rowIndex);
        }
      } else {
        if (
          name === "firstName" ||
          name === "middleInitial" ||
          name === "lastName" ||
          name === "suffix" ||
          name === "nickname" ||
          name === "pernr" ||
          name === "costCenter" ||
          name === "payAreaId"
        ) {
          this.checkMatch(rowIndex);
        }
        this.handleValidity(rowIndex);
      }
      this.handleInvalid();
      this.setState({
        tasks: tasks
      });
    }
  };

  handleValidity = (rowIndex: number): void => {
    const tasks: any[] = this.state.tasks.slice();
    let isValid: boolean = true;
    Object.keys(tasks[rowIndex].data).forEach(key => {
      if (
        (key === "bottlerEmployeeId" ||
          key === "firstName" ||
          key === "lastName" ||
          key === "pernr" ||
          key === "wageTypeId" ||
          key === "amount" ||
          key === "costCenter" ||
          key === "payAreaId" ||
          key === "currencyTypeId" ||
          key === "authority") &&
        (tasks[rowIndex].data[key] === "" || tasks[rowIndex].data[key] === undefined || tasks[rowIndex].data[key] === null)
      ) {
        isValid = false;
      }
      if (key === "payAreaId" && !tasks[rowIndex].payAreaIdValid) {
        isValid = false;
      }
    });
    tasks[rowIndex].isValid = isValid;
    this.setState({
      tasks: tasks
    });
  };

  handlePayAreaValidity = (rowIndex: number): void => {
    if (this.state.payArea) {
      const requestPayAreaId: number = this.state.payArea.id;
      const tasks: Row[] = this.state.tasks.slice();
      const payAreaId: number | undefined = tasks[rowIndex]!.data!.payAreaId;
      if (!payAreaId || payAreaId !== requestPayAreaId) {
        tasks[rowIndex]!.isValid = false;
        tasks[rowIndex]!.payAreaIdTouched = true;
        tasks[rowIndex]!.payAreaIdValid = false;
      } else {
        tasks[rowIndex]!.payAreaIdTouched = true;
        tasks[rowIndex]!.payAreaIdValid = true;
      }
      // todo: make a function that detects row validity
      if (
        !this.state.tasks[rowIndex]!.firstNameValid ||
        !this.state.tasks[rowIndex]!.lastNameValid ||
        !this.state.tasks[rowIndex]!.pernrValid ||
        !this.state.tasks[rowIndex]!.costCenterValid ||
        !this.state.tasks[rowIndex]!.payAreaIdValid ||
        !this.state.tasks[rowIndex]!.wageTypeIdValid ||
        !this.state.tasks[rowIndex]!.authorityValid
      ) {
        tasks[rowIndex]!.isValid = false;
      } else {
        tasks[rowIndex]!.isValid = true;
      }
      this.setState(
        {
          tasks: []
        },
        () => {
          this.setState(
            {
              tasks: tasks
            },
            () => {
              this.handleInvalid();
            }
          );
        }
      );
    }
  };

  handleInvalid = (): void => {
    let invalid: ProcessingError[] = [];
    this.state.tasks.map((task, rowIndex) => {
      if (task && task.isValid === false) {
        invalid.push({
          task: rowIndex + 1
        });
      }
    });
    this.setState(
      {
        invalid: []
      },
      () => {
        this.setState({
          invalid: invalid
        });
      }
    );
  };

  handleUnmatched = (): void => {
    let unmatched: ProcessingError[] = [];
    this.state.tasks.map((task, rowIndex) => {
      if (task && task.isMatched === false) {
        unmatched.push({
          task: rowIndex + 1
        });
      }
    });
    this.setState({
      unmatched: unmatched
    });
  };

  checkChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number): void => {
    const checked: boolean = e.target.checked;
    const tasks: any[] = this.state.tasks.slice();
    if (checked) {
      tasks[rowIndex].isChecked = true;
    } else {
      tasks[rowIndex].isChecked = false;
    }
    this.setState(
      {
        tasks: tasks
      },
      () => {
        this.updateCount();
      }
    );
  };

  handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const checked: boolean = e.target.checked;
    const tasks: any[] = this.state.tasks.slice();
    if (checked) {
      tasks.map(task => {
        if (task.isValid && !task.data.isMerged) {
          task.isChecked = true;
        } else {
          task.isChecked = false;
        }
      });
    } else {
      tasks.map(task => {
        task.isChecked = false;
      });
    }
    this.setState(
      {
        tasks: tasks
      },
      () => {
        this.updateCount();
      }
    );
  };

  uncheckAll = (): void => {
    const tasks: any[] = this.state.tasks.slice();
    tasks.map(task => {
      task.isChecked = false;
    });
    this.setState(
      {
        tasks: tasks
      },
      () => {
        this.updateCount();
      }
    );
  };

  updateCount = (): void => {
    let checked: number = 0;
    let merged: number = 0;
    this.state.tasks.map(task => {
      if (task && task.isChecked === true) {
        checked++;
      }
      if (task && task.data && task.data.isMerged === true) {
        merged++;
      }
    });
    this.setState({
      checked: checked,
      merged: merged
    });
  };

  createRow = (merging: boolean, added: boolean, matched: boolean): Row => {
    const { request } = this.state;

    const newRow: Row = {
      autofocus: false,
      isMerging: merging,
      isChecked: false,
      isDirty: false,
      isDuplicate: false,
      isManual: added,
      isTouched: false,
      isValid: true,
      isMatched: matched,
      firstNameValid: true,
      firstNameTouched: false,
      middleInitialTouched: false,
      lastNameValid: true,
      lastNameTouched: false,
      suffixTouched: false,
      nicknameTouched: false,
      pernrValid: true,
      pernrTouched: false,
      costCenterValid: true,
      costCenterTouched: false,
      payAreaIdValid: true,
      payAreaIdTouched: false,
      wageTypeIdValid: true,
      wageTypeIdTouched: false,
      authorityValid: true,
      authorityTouched: false,
      rowIndex: undefined,
      showStep: false,
      step: 0,
      data: {
        amount: undefined,
        authority: "",
        bottlerEmployeeId: undefined,
        comment: "",
        costCenter: "",
        currencyTypeId: 1, // default to USD
        dateOfPay: null,
        deletedAt: null,
        dollarLimit: undefined,
        firstName: "",
        hours: undefined,
        id: undefined,
        isCompleted: false,
        isMerged: false,
        lastName: "",
        middleInitial: "",
        nickname: "",
        number: undefined,
        payAreaId: undefined,
        pernr: "",
        suffix: "",
        rate: undefined,
        requestId: request ? request.id : undefined,
        updatedAt: "",
        units: undefined,
        wageTypeId: undefined
      }
    };
    return newRow;
  };

  addRow = (merging: boolean): void => {
    const newRow: Row = this.createRow(merging, true, true);
    if (!merging) {
      const tasks: Row[] = this.state.tasks.slice();
      tasks.unshift(newRow);
      this.setState(
        {
          tasks: [] // fix for task duplicating instead of blank
        },
        () => {
          this.setState(
            {
              dirty: true,
              tasks: tasks
            },
            () => {
              this.handleInvalid();
            }
          );
        }
      );
    }
  };

  removeRow = (rowIndex: number): void => {
    const tasks: any[] = this.state.tasks.slice();
    if (tasks[rowIndex].data.id) {
      tasks[rowIndex].data.deletedAt = moment().toISOString();
    } else {
      tasks.splice(rowIndex, 1);
    }
    this.setState({
      tasks: tasks
    });
  };

  removeTask = (e: React.MouseEvent<HTMLButtonElement>, rowIndex: number): void => {
    this.removeRow(rowIndex);
  };

  addTask = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    this.addRow(false);
  };

  openMerge = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const newRow: Row = this.createRow(true, true, true);
    const mergee: Row[] = [newRow];
    const merges: Row[] = [];
    const tasks: Row[] = this.state.tasks.slice();
    tasks.map(task => {
      if (task && task.isChecked) {
        merges.push(task);
      }
    });
    this.setState({
      mergee: mergee,
      merges: merges,
      merging: true
    });
  };

  saveMerge = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const tasks: Row[] = this.state.tasks.slice();
    tasks.map(task => {
      if (task) {
        if (task && task.data && task.isChecked === true) {
          task.data.isMerged = true;
        }
        task.isChecked = false;
        task.isMerging = false;
      }
    });
    const mergees: any[] = this.state.mergee.slice();
    mergees[0].autofocus = true;
    tasks.push(mergees[0]);
    this.setState(
      {
        dirty: true,
        mergee: [],
        merges: [],
        merging: false,
        tasks: [] // needed for react-virtualized
      },
      () => {
        this.setState(
          {
            tasks: orderBy(tasks, ["data.firstName", "data.lastName"], ["asc", "asc"])
          },
          () => {
            this.updateCount();
          }
        );
      }
    );
  };

  cancelMerge = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    this.setState({
      mergee: [],
      merges: [],
      merging: false
    });
  };

  saveDuplicates = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const tasks: Row[] = this.state.tasks.slice();
    tasks.map(task => {
      if (task) {
        task.isMerging = false;
        if (task && task.data && (task.isChecked === true || task.isDuplicate === true)) {
          task.isChecked = false;
          task.isDuplicate = false;
          task.data.isMerged = true;
        }
      }
    });
    this.state.suggestions.map(suggestion => {
      if (suggestion && suggestion.isMerging) {
        suggestion.autofocus = true;
        tasks.push(suggestion);
      }
    });
    this.setState(
      {
        checking: false,
        dirty: true,
        tasks: [], // needed for react-virtualized
        duplicates: [],
        set: 0,
        step: 0,
        suggestions: []
      },
      () => {
        this.setState(
          {
            tasks: orderBy(tasks, ["data.firstName", "data.lastName"], ["asc", "asc"])
          },
          () => {
            this.uncheckAll();
            this.checkMatches();
          }
        );
      }
    );
  };

  openDuplicates = (): void => {
    const tasks: Row[] = this.state.tasks.slice();
    const duplicates: Rows[] = this.state.duplicates.slice();
    const suggestions: Row[] = this.state.suggestions.slice();
    tasks.map((task, rowIndex) => {
      if (!task!.data!.isMerged && !task!.data!.deletedAt && !task!.data!.isCompleted) {
        let firstName: string = task!.data!.firstName;
        let lastName: string = task!.data!.lastName;
        let pernr: string = task!.data!.pernr;
        let costCenter: string = task!.data!.costCenter;
        let payArea: number | undefined = task!.data!.payAreaId;
        let wageType: number | undefined = task!.data!.wageTypeId;
        let dups: Row[] = [];
        tasks.map((t, r) => {
          if (rowIndex !== r && !t!.isDuplicate && !t!.data!.isMerged && !t!.data!.deletedAt && !t!.data!.isCompleted) {
            let fN: string = t!.data!.firstName;
            let lN: string = t!.data!.lastName;
            let p: string = t!.data!.pernr;
            let cC: string = t!.data!.costCenter;
            let pA: number | undefined = t!.data!.payAreaId;
            let wT: number | undefined = t!.data!.wageTypeId;
            if (t && firstName === fN && lastName === lN && pernr === p && costCenter === cC && payArea === pA && wageType === wT) {
              t.isDuplicate = true;
              t.rowIndex = r;
              dups.push(t);
            }
          }
        });
        if (task && dups.length > 0) {
          task.isDuplicate = true;
          task.showStep = dups.length === 1 ? true : false;
          task.rowIndex = rowIndex;
          dups.unshift(task);
          let dup: Rows = { rows: dups, shouldCombine: null };
          duplicates.push(dup);
        }
      }
    });
    if (duplicates.length) {
      duplicates.map((dups, index) => {
        const row: Row = this.createRow(true, false, false);
        if (row && row.data && dups && dups.rows) {
          row.data.bottlerEmployeeId = dups.rows[0]!.data!.bottlerEmployeeId ? dups.rows[0]!.data!.bottlerEmployeeId : undefined;
          row.data.wageTypeId = dups.rows[0]!.data!.wageTypeId ? dups.rows[0]!.data!.wageTypeId : undefined;
          row.data.payAreaId = dups.rows[0]!.data!.payAreaId ? dups.rows[0]!.data!.payAreaId : undefined;
          row.data.currencyTypeId = dups.rows[0]!.data!.currencyTypeId ? dups.rows[0]!.data!.currencyTypeId : undefined;
          dups.rows.map(task => {
            if (row && row.data) {
              row.data.firstName = row.data.firstName.length >= task!.data!.firstName.length ? row.data.firstName : task!.data!.firstName;
              row.data.middleInitial =
                row.data.middleInitial && row.data.middleInitial.length >= task!.data!.middleInitial!.length ? row.data.middleInitial : task!.data!.middleInitial;
              row.data.lastName = row.data.lastName.length >= task!.data!.lastName.length ? row.data.lastName : task!.data!.lastName;
              row.data.suffix = row.data.suffix && row.data.suffix.length >= task!.data!.suffix!.length ? row.data.suffix : task!.data!.suffix;
              row.data.nickname = row.data.nickname && row.data.nickname.length >= task!.data!.nickname!.length ? row.data.nickname : task!.data!.nickname;
              row.data.pernr = row.data.pernr && row.data.pernr.length >= task!.data!.pernr.length ? row.data.pernr : task!.data!.pernr;
              row.data.costCenter = row.data.costCenter && row.data.costCenter.length >= task!.data!.costCenter.length ? row.data.costCenter : task!.data!.costCenter;
              row.data.authority = row.data.authority && row.data.authority.length >= task!.data!.authority.length ? row.data.authority : task!.data!.authority;
              row.data.comment = row.data.comment && row.data.comment.length >= task!.data!.comment!.length ? row.data.comment : task!.data!.comment;
              row.data.amount =
                !row.data.amount && task!.data!.amount
                  ? Number(task!.data!.amount)
                  : row.data.amount && task!.data!.amount
                  ? Number(row.data.amount) + Number(task!.data!.amount)
                  : row.data.amount;
              row.data.hours = row.data.hours && row.data.hours >= task!.data!.hours! ? row.data.hours : task!.data!.hours;
              row.data.number = row.data.number && row.data.number >= task!.data!.number! ? row.data.number : task!.data!.number;
              row.data.units = row.data.units && row.data.units >= task!.data!.units! ? row.data.units : task!.data!.units;
              row.data.dateOfPay =
                row.data.dateOfPay && task && task.data && task.data.dateOfPay && row.data.dateOfPay <= task.data.dateOfPay
                  ? row.data.dateOfPay
                  : row.data.dateOfPay && (!task || (task && !task.data) || (task && task.data && !task.data.dateOfPay))
                  ? row.data.dateOfPay
                  : !row.data.dateOfPay && task && task.data && task.data.dateOfPay
                  ? task.data.dateOfPay
                  : null;
              row.data.rate = row.data.rate && row.data.rate >= task!.data!.rate! ? row.data.rate : task!.data!.rate;
              row.data.dollarLimit = row.data.dollarLimit && row.data.dollarLimit >= task!.data!.dollarLimit! ? row.data.dollarLimit : task!.data!.dollarLimit;
            }
          });
        }
        if (row) {
          suggestions.push(row);
        }
      });
    }
    this.setState({
      checking: true,
      dirty: true,
      duplicates: duplicates,
      set: 0,
      step: 0,
      suggestions: suggestions
    });
  };

  cancelDuplicates = (): void => {
    const tasks: Row[] = this.state.tasks.slice();
    tasks.map(task => {
      if (task) {
        task.isDuplicate = false;
      }
    });
    this.setState(
      {
        tasks: []
      },
      () => {
        this.setState({
          checking: false,
          duplicates: [],
          set: 0,
          step: 0,
          suggestions: [],
          tasks: tasks
        });
      }
    );
  };

  stepThroughDuplicates = (step: number): void => {
    this.setState(
      {
        stepping: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            step: step,
            stepping: false
          });
        }, 500); // timeout is for brief loading animation
      }
    );
  };

  setDuplicate = (step: number, combine: boolean): void => {
    const tasks: Row[] = this.state.tasks.slice();
    const duplicates: Rows[] = this.state.duplicates.slice();
    const suggestions: Row[] = this.state.suggestions.slice();
    const duplicate = duplicates[step];
    const suggestion = suggestions[step];
    duplicate.shouldCombine = combine;
    suggestion!.isMerging = combine;
    duplicates[step].rows.map(dup => {
      if (dup) {
        const row: number | undefined = dup.rowIndex;
        if (row) {
          tasks[row]!.isDuplicate = combine;
        }
      }
    });
    this.setState(
      {
        duplicates: duplicates,
        set: this.state.set + 1,
        suggestions: suggestions
      },
      () => {
        if (step !== this.state.duplicates.length - 1) {
          this.stepThroughDuplicates(step + 1);
        }
      }
    );
  };
}

export default withRouter(
  connect(
    (state: any) => ({
      bottlers: state.bottlers.bottlers,
      user: state.user.user,
      copyPasteImagesFF: state.featureFlags.copyPasteImages,
      requestPostPilotFF: state.featureFlags.requestPostPilot
    }),
    {
      getBottlersCreator
    }
  )(withPageLog(RequestDetail)) as any
) as any;
