import * as React from "react";
import Textarea from "../input/Textarea";
// import Button from "../button/Button";

export interface INoteProps {
  count: number;
  createdAt: string;
  createdBy: string;
  editing: boolean;
  id: number | undefined;
  index: number;
  newCount: number;
  noteChange: any;
  notesSaving: boolean;
  removeNote: any;
  text: string;
  saveNote: any;
}

export interface INoteState {
  count: number;
  text: string;
}

export default class Note extends React.Component<INoteProps, INoteState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      count: 1000,
      text: ""
    };
    this._handleText = this._handleText.bind(this);
    this._handleResize = this._handleResize.bind(this);
  }

  public componentDidUpdate(): void {
    this._handleResize();
  }

  public render(): React.ReactElement<INoteProps> {
    const noteText: JSX.Element | null = !this.props.editing ? (
      <p className="noteText">{this.props.text}</p>
    ) : null;

    const noteMetadata: JSX.Element = !this.props.editing ? (
      <p className="noteMetadata">
        {this.props.createdBy} on {this.props.createdAt}
      </p>
    ) : (
      <div className="noteMetadata">
        <p className="noteCount">{this.state.count} characters remaining</p>
      </div>
    );

    const textarea: JSX.Element | null = this.props.editing ? (
      <Textarea
        autoFocus={true}
        id={
          "note-" +
          (this.props.id
            ? this.props.id.toString()
            : this.props.index.toString())
        }
        maxLength={1000}
        name="note"
        onChange={e => this._handleText(e)}
        placeholder="Type up to 1000 characters..."
      />
    ) : null;

    return (
      <div className="note">
        {noteText}
        {textarea}
        {noteMetadata}
      </div>
    );
  }
  private _handleResize(): void {
    const textarea: HTMLElement | null = document.getElementById(
      "note-" +
        (this.props.id ? this.props.id.toString() : this.props.index.toString())
    );
    if (textarea) {
      textarea.style.height = "0px";
      textarea.style.minHeight = "0px";
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = "";
      textarea.style.minHeight = (scrollHeight + 4).toString() + "px";
    }
  }

  private _handleText(e: any): void {
    const value: string = e.target.value;
    const count: number = 1000 - value.length;
    const text: string = value.substring(0, 1000);
    if (value.length <= 1000) {
      this.setState(
        {
          count: count,
          text: text
        },
        () => {
          this.props.noteChange(this.props.index, this.state.text);
        }
      );
    }
  }
}
