import { IFilters, IFiltersReq } from "../types/Filters";
import { getRequest } from "../core/api/requestConfig";

export function getFilters({
  includeAssignedTo = false,
  includeBottlers = false,
  includeRequestAuditTypes = false,
  includeRequestGroups = false,
  includeRequestProcesses = false,
  includeRequestTypes = false,
  includeStatuses = false,
  includeTowers = false,
  includeUsers = false,
  includeRequestPriorities = false
}: IFiltersReq): Promise<IFilters> {
  return new Promise<IFilters>((resolve, reject) => {
    getRequest(
      `/api/v1/getFilters?adminApp=true&includeAssignedTo=${includeAssignedTo}&includeBottlers=${includeBottlers}&includeRequestAuditTypes=${includeRequestAuditTypes}&includeRequestGroups=${includeRequestGroups}&includeRequestProcesses=${includeRequestProcesses}&includeRequestTypes=${includeRequestTypes}&includeStatuses=${includeStatuses}&includeTowers=${includeTowers}&includeUsers=${includeUsers}&includeRequestPriorities=${includeRequestPriorities}`
    )
      .then((filters: IFilters) => {
        // filters could be null from API
        if (filters) {
          return resolve(filters);
        } else {
          throw Error("Error retrieving filters.");
        }
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = "Error retrieving filters.";
        }
        return reject(error);
      });
  });
}
