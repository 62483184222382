import * as React from "react";
import Required from "./Required";
import { sizes } from "../../enums/sizes";

export interface ISelectProps {
  autoFocus?: boolean;
  className?: string;
  classNameWrapper?: string;
  defaultValue?: string;
  disabled?: boolean;
  id: string;
  loading?: boolean;
  multiple?: boolean;
  name: string;
  onBlur?: any;
  onChange: any;
  onFocus?: any;
  options: JSX.Element[];
  placeholder?: string;
  required?: boolean;
  selections?: any[];
  selectionType?: string;
  showOptionsOnFocus?: boolean;
  showPlaceholder?: boolean;
  showRequired?: boolean;
  showValidation?: boolean;
  size?: sizes;
  value?: string;
}

export interface ISelectState {
  options: JSX.Element[];
  selected: JSX.Element | null;
}

export default class Select extends React.Component<ISelectProps, ISelectState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected: null,
      options: this.props.showOptionsOnFocus ? [] : this.props.options
    };
    this._showOptionsOnFocus = this._showOptionsOnFocus.bind(this);
  }

  public componentDidMount(): void {
    const { selections, selectionType, showOptionsOnFocus, value } = this.props;

    if (selections && selections.length && showOptionsOnFocus) {
      selections.map(selection => {
        if (value === selection.id) {
          this.setState({
            selected: <option value={selection.id}>{selectionType === "label" ? selection.label : selection.code}</option>
          });
        }
      });
    }
  }

  public render(): React.ReactElement<ISelectProps> {
    const {
      autoFocus,
      className,
      defaultValue,
      disabled,
      id,
      loading,
      multiple,
      name,
      onBlur,
      onChange,
      onFocus,
      options,
      placeholder,
      required,
      selections,
      showOptionsOnFocus,
      showPlaceholder,
      showRequired,
      showValidation,
      size,
      value
    } = this.props;

    const { selected } = this.state;

    let cssClass: string = "select";
    if (showValidation) {
      cssClass += " selectRequired";
    }
    if (size === sizes.large) {
      cssClass += " selectLarge";
    }
    if (size === sizes.larger) {
      cssClass += " selectLarger";
    }
    if (size === sizes.largest) {
      cssClass += " selectLargest";
    }
    if (className) {
      cssClass = className;
    }

    const _showPlaceholder: JSX.Element | null = showPlaceholder ? <option value="">{placeholder ? placeholder : "Select..."}</option> : null;

    const showLoading: JSX.Element | null = loading ? <option value="">Loading...</option> : null;

    const _showRequired: JSX.Element | null = showRequired ? <Required /> : null;

    const selectWrapper: string = showRequired ? "selectWrapperRequired" : "selectWrapper";

    const _options: JSX.Element | JSX.Element[] = !showOptionsOnFocus
      ? options
      : selected && selections && showOptionsOnFocus && this.state.options.length === 0
      ? selected
      : this.state.options;

    return (
      <div className={`${selectWrapper} ${this.props.classNameWrapper}`}>
        <select
          autoFocus={autoFocus}
          className={cssClass}
          defaultValue={defaultValue}
          disabled={disabled || loading}
          id={id}
          multiple={multiple}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={showOptionsOnFocus ? this._showOptionsOnFocus : onFocus}
          required={required}
          value={value}
        >
          {showLoading}
          {_showPlaceholder}
          {_options}
        </select>
        {_showRequired}
      </div>
    );
  }

  private _showOptionsOnFocus(): void {
    const { options } = this.props;

    this.setState({
      options: options
    });
  }
}
