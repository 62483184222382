import * as React from "react";
import { withRouter } from "react-router-dom";
// import { Icon } from "office-ui-fabric-react/lib/Icon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { connect } from "react-redux";
// arrays
import { columns } from "./Columns";
import { sorting } from "./Sorting";
// components
import Alert from "../../../components/alert/Alert";
import BottlerSwitcher from "../../../components/switcher/BottlerSwitcher";
import DateRangePicker from "../../../components/input/DateRangePicker";
import Label from "../../../components/label/Label";
import Loader from "../../../components/loader/Loader";
import LoaderWrapper from "../../../components/loader/LoaderWrapper";
import Page from "../../../components/page/Page";
import Pagination from "../../../components/pagination/Pagination";
import RefreshGroup from "../../../components/refresh/Group";
import Search from "../../../components/search/Search";
import Select from "../../../components/input/Select";
import Sort from "../../../components/table/Sort";
import Table from "../../../components/table/Table";
import TableWrapper from "../../../components/table/TableWrapper";
import TBody from "../../../components/table/TBody";
import Td from "../../../components/table/Td";
import Th from "../../../components/table/Th";
import THead from "../../../components/table/THead";
import Tr from "../../../components/table/Tr";
// fetch
import { getRequestErrors } from "../../../services/RequestError";
// helpers
import { sanitizeCsvString } from "../../../helpers/csv";
import { shortFileDateTime, shortDate, fullTime } from "../../../helpers/time";
import zipcelx, { Config, Sheet, Row, Data, Cell } from "../../../helpers/zipcelx";
// logging
import { withPageLog } from "../../logging/LogComponentChange";
// types
import { DaylightSavingsTimezoneOptions, StandardTimezoneOptions } from "../../../types/Timezone";
import { IBottler } from "../../../types/Bottler";
import { IRequestError } from "../../../types/RequestError";
import { IUser } from "../../../types/User";
import { Apps } from "../../../types/App";

export interface IRequestErrorsProps {
  appId: number;
  bottlers: IBottler[];
  bottler: IBottler | null;
  location: {
    hash: string;
    pathname: string;
    search: string;
  };
  refreshId?: string;
  user: IUser;
}

export interface IRequestErrorsState {
  count: number;
  createdAtEnd: string;
  createdAtStart: string;
  errorCode: string | null;
  export: IRequestError[];
  exporting: boolean;
  loading: boolean;
  offset: number;
  requestErrors: IRequestError[];
  search: string;
  searching: boolean;
  sortBy: string;
  sortOrder: string;
}

class RequestErrors extends React.Component<IRequestErrorsProps, IRequestErrorsState> {
  timer;
  DEFAULT_LIMIT: number = 10;
  DEFAULT_OFFSET: number = 0;
  DEFAULT_SORT_ORDER: string = "DESC";
  DEFAULT_SORT_BY: string = "id";

  constructor(props: IRequestErrorsProps) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const createdAt = params.get("createdAt");
    const errorCode = params.get("errorCode");
    const sortBy = params.get("sortBy");

    this.state = {
      count: 0,
      createdAtEnd: createdAt ? this.formatCreatedAtEnd(createdAt) : this.getDefaultCreatedAtEnd(),
      createdAtStart: createdAt ? this.formatCreatedAtStart(createdAt) : this.getDefaultCreatedAtStart(),
      errorCode: errorCode || null,
      export: [],
      exporting: false,
      loading: true,
      offset: this.DEFAULT_OFFSET,
      requestErrors: [],
      search: "",
      searching: false,
      sortBy: sortBy || this.DEFAULT_SORT_BY,
      sortOrder: this.DEFAULT_SORT_ORDER
    };
  }

  componentDidMount(): void {
    this.getRequestErrors();
  }

  componentDidUpdate(prevProps: IRequestErrorsProps): void {
    if (prevProps.appId !== this.props.appId || prevProps.bottler !== this.props.bottler) {
      this.resetState();
    }
  }

  render() {
    const { refreshId, appId } = this.props;
    const { count, createdAtEnd, createdAtStart, exporting, loading, offset, search, sortOrder, sortBy } = this.state;

    const mobileSorterOptions: JSX.Element[] = this.renderMobileSorter();
    const headerColumns: JSX.Element[] = this.renderHeaderColumns();
    const rows: JSX.Element[] = this.renderRows();
    const exportText: string = this.renderExportText();

    let appTitle: string = `Bottler App`;
    if (appId === Apps.COKE_SERVICES_APP) {
      appTitle = "Service App";
    }
    const title: string = `Request Errors for ${appTitle}`;

    return (
      <Page className="requestErrors" permissions={true} title={title}>
        <BottlerSwitcher showAllOption={true} />
        <div>
          {refreshId && <RefreshGroup refreshId={refreshId} handleRefresh={this.getRequestErrors} isLoading={loading} />}
          <div className="dateRangePickerWithSearch">
            <DateRangePicker
              endDate={createdAtEnd}
              endDateChange={(date: string) => {
                this.handleDateRange(date, "end");
              }}
              endDateId="endDate"
              endDateMax={moment()
                .endOf("day")
                .toISOString()}
              endDateMin={createdAtStart}
              endDatePlaceholder="End date..."
              startDate={createdAtStart}
              startDateChange={(date: string) => {
                this.handleDateRange(date, "start");
              }}
              startDateId="startDate"
              startDateMax={createdAtEnd}
              startDatePlaceholder="Start date..."
            />
            <Search
              autoFocus={true}
              exportDisabled={count === 0 || exporting || loading}
              exportText={exportText}
              exportTitle={exportText}
              onExportButtonClick={this.exportToExcel}
              onSearchChange={this.handleSearchInput}
              onSearchKeyUp={this.handleSearchEnter}
              onSearchClick={this.search}
              placeholder="Search errors..."
              search={search}
              searchButtonText="Search Request Errors"
              searchButtonTitle="Search Request Errors"
              searchDisabled={exporting}
              searchLabelText="Search Request Errors"
              showExport={true}
            />
          </div>

          <div className="gridSorter">
            <Label htmlFor={"grid-sorter"} text={"Sort Requests By"} />
            <Select defaultValue={sortBy + " " + sortOrder} id={"grid-sorter"} name={"grid-sorter"} onChange={this.handleMobileSort} options={mobileSorterOptions} />
          </div>
          <LoaderWrapper>
            <TableWrapper>
              <Table>
                <THead>
                  <Tr>{headerColumns}</Tr>
                </THead>
                <TBody>{rows}</TBody>
              </Table>
            </TableWrapper>
            <Loader loading={exporting || loading} position="Top" showImage={true} text={exporting ? "Exporting..." : "Loading..."} type="Overlay" />
          </LoaderWrapper>
          <Pagination count={count} loading={loading} page={offset / this.DEFAULT_LIMIT + 1} perPage={this.DEFAULT_LIMIT} paginate={this.handlePaginate} />
          <ToastContainer />
        </div>
      </Page>
    );
  }

  getRequestErrors = (): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        const { bottler, appId } = this.props;
        const { createdAtEnd, createdAtStart, errorCode, offset, search, sortBy, sortOrder } = this.state;

        try {
          const response = await getRequestErrors({
            appId: appId,
            bottlerId: bottler ? bottler.id : undefined,
            createdAtEnd: createdAtEnd ? createdAtEnd : undefined,
            createdAtStart: createdAtStart ? createdAtStart : undefined,
            errorCode: errorCode ? errorCode : undefined,
            limit: this.DEFAULT_LIMIT,
            offset: offset === 0 ? 0 : offset,
            search: search ? search : undefined,
            sortBy: sortBy ? sortBy : undefined,
            sortOrder: sortOrder ? sortOrder : undefined
          });

          this.setState({
            count: response.count,
            loading: false,
            requestErrors: response.requestErrors
          });
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            count: 0,
            loading: false,
            requestErrors: []
          });
        }
      }
    );
  };

  handleDateRange = (date: string, startOrEnd: "start" | "end"): void => {
    if (date && moment(date).isValid()) {
      if (startOrEnd === "start") {
        this.setState(
          {
            createdAtStart: this.formatCreatedAtStart(date),
            offset: this.DEFAULT_OFFSET
          },
          () => {
            this.getRequestErrors();
          }
        );
      }

      if (startOrEnd === "end") {
        this.setState(
          {
            createdAtEnd: this.formatCreatedAtEnd(date),
            offset: this.DEFAULT_OFFSET
          },
          () => {
            this.getRequestErrors();
          }
        );
      }
    }
  };

  resetState = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    if (e) {
      e.preventDefault();
    }

    const createdAtEnd = this.getDefaultCreatedAtEnd();
    const createdAtStart = this.getDefaultCreatedAtStart();

    this.setState(
      {
        count: 0,
        createdAtEnd,
        createdAtStart,
        errorCode: null,
        loading: true,
        offset: this.DEFAULT_OFFSET,
        searching: false,
        search: "",
        sortBy: this.DEFAULT_SORT_BY,
        sortOrder: this.DEFAULT_SORT_ORDER
      },
      () => {
        this.getRequestErrors();
      }
    );
  };

  handlePaginate = (e: React.MouseEvent<HTMLButtonElement>, page: number): void => {
    e.preventDefault();

    this.setState(
      {
        offset: (page - 1) * this.DEFAULT_LIMIT
      },
      () => {
        this.getRequestErrors();
      }
    );
  };

  search = (): void => {
    this.setState(
      {
        offset: this.DEFAULT_OFFSET
      },
      () => {
        this.getRequestErrors();
      }
    );
  };

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();

    const search: string = e.target.value;

    clearTimeout(this.timer);

    this.setState(
      {
        search
      },
      () => {
        this.timer = setTimeout(() => {
          this.search();
        }, 300);
      }
    );
  };

  handleSearchEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();

    if (e.key === "Enter") {
      const search: string = e.currentTarget.value;

      this.setState(
        {
          search: search ? search : ""
        },
        () => {
          this.search();
        }
      );
    }
  };

  handleSort = (e: React.MouseEvent<HTMLButtonElement>, sortBy: string): void => {
    e.preventDefault();

    const { sortOrder } = this.state;

    this.setState(
      {
        offset: this.DEFAULT_OFFSET,
        sortBy: sortBy,
        sortOrder: sortOrder === "DESC" ? "ASC" : "DESC"
      },
      () => {
        this.getRequestErrors();
      }
    );
  };

  handleMobileSort = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();

    const value: string = e.target.value;
    const sortBy: string = value.split(" ")[0];
    const sortOrder: string = value.split(" ")[1];

    this.setState(
      {
        offset: this.DEFAULT_OFFSET,
        sortBy: sortBy,
        sortOrder: sortOrder
      },
      () => {
        this.getRequestErrors();
      }
    );
  };

  exportToExcel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    this.setState(
      {
        exporting: true
      },
      async () => {
        const { bottler, appId } = this.props;
        const { createdAtEnd, createdAtStart, search, sortBy, sortOrder } = this.state;

        try {
          const response = await getRequestErrors({
            appId: appId,
            bottlerId: bottler ? bottler.id : undefined,
            createdAtEnd: createdAtEnd ? createdAtEnd : undefined,
            createdAtStart: createdAtStart ? createdAtStart : undefined,
            limit: 0,
            offset: 0,
            search: search ? search : undefined,
            sortBy: sortBy ? sortBy : undefined,
            sortOrder: sortOrder ? sortOrder : undefined
          });

          const data: Data = [];

          const header: Row = [];

          columns.map(column => {
            header.push({
              value: column.text,
              type: "string"
            });
          });

          data.push(header);

          response.requestErrors.map(requestError => {
            const cell: Cell[] = [];

            columns.map(column => {
              if (column.id === "createdDate") {
                cell.push({
                  value: shortDate(requestError[column.databaseColumn]),
                  type: column.type
                });
              } else if (column.id === "createdTime") {
                cell.push({
                  value: fullTime(requestError[column.databaseColumn]),
                  type: column.type
                });
              } else if (column.databaseTable.includes("bottler")) {
                const _bottler = requestError.bottler[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(_bottler ? _bottler : ""),
                  type: column.type
                });
              } else if (column.databaseTable.includes("user")) {
                const user = requestError.user && requestError.user[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(user ? user : ""),
                  type: column.type
                });
              } else if (column.databaseTable.includes("equipmentType")) {
                const equipmentType = requestError.equipmentProblem.equipmentType[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(equipmentType ? equipmentType : ""),
                  type: column.type
                });
              } else if (column.databaseTable.includes("equipmentProblem")) {
                const equipmentProblem = requestError.equipmentProblem[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(equipmentProblem ? equipmentProblem : ""),
                  type: column.type
                });
              } else if (column.databaseTable.includes("equipment")) {
                const equipment = requestError.equipment && requestError.equipment[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(equipment ? equipment : ""),
                  type: column.type
                });
              } else if (column.databaseTable.includes("priority")) {
                const priority = requestError.equipmentProblem.priority[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(priority ? priority : ""),
                  type: column.type
                });
              } else if (column.databaseTable.includes("outlet")) {
                const outlet = requestError.outlet[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(outlet ? outlet : ""),
                  type: column.type
                });
              } else if (appId === Apps.COKE_SERVICES_APP && column.databaseTable.includes("requestEquipmentLocation")) {
                const value = requestError.requestEquipmentLocation && requestError.requestEquipmentLocation[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(value ? value : ""),
                  type: column.type
                });
              } else if (appId === Apps.COKE_SERVICES_APP && column.databaseTable.includes("requestContact") && column.databaseColumn.includes("Notification")) {
                const notification = requestError.requestContact && requestError.requestContact[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(notification ? "Yes" : "No"),
                  type: column.type
                });
              } else if (appId === Apps.COKE_SERVICES_APP && column.databaseTable.includes("contactRole")) {
                const value = requestError.requestContact && requestError.requestContact.contactRole[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(value ? value : ""),
                  type: column.type
                });
              } else if (appId === Apps.COKE_SERVICES_APP && column.databaseTable.includes("requestContact")) {
                const value = requestError.requestContact && requestError.requestContact[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(value ? value : ""),
                  type: column.type
                });
              } else if (appId === Apps.COKE_SERVICES_APP && column.databaseTable.includes("requestEmployee") && column.databaseColumn.includes("Notification")) {
                const notification = requestError.requestEmployee && requestError.requestEmployee[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(notification ? "Yes" : "No"),
                  type: column.type
                });
              } else if (appId === Apps.COKE_SERVICES_APP && column.databaseTable.includes("requestEmployee")) {
                const value = requestError.requestEmployee && requestError.requestEmployee[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(value ? value : ""),
                  type: column.type
                });
              } else {
                const other = requestError[column.databaseColumn];

                cell.push({
                  value: sanitizeCsvString(other ? other.toString() : ""),
                  type: column.type
                });
              }
            });

            data.push(cell);
          });

          const now: string = moment().toISOString();
          const filename = `Request_Errors_${appId === Apps.COKE_BOTTLER_APP ? "Bottler_App_" : "Service_App_"}${shortFileDateTime(now)}`;

          const sheet: Sheet = {
            data
          };

          const config: Config = {
            filename,
            sheet
          };

          zipcelx(config);

          this.setState({
            exporting: false
          });
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            exporting: false
          });
        }
      }
    );
  };

  renderExportText = (): string => {
    const { count } = this.state;
    return `Export ${count.toString()} Request Error${count !== 1 ? "s" : ""}`;
  };

  renderRows = (): JSX.Element[] => {
    const { appId } = this.props;
    const { count, requestErrors } = this.state;

    const rows: JSX.Element[] = [];

    if (count > 0) {
      requestErrors.map(requestError => {
        rows.push(
          <Tr hover={true} key={`requestError-${requestError.id}`} striped={true}>
            {columns.map(column => {
              const include = column.appIds.find(id => appId === id);
              if (include && appId === Apps.COKE_SERVICES_APP) {
                return (
                  <Td key={`requestError-column-${requestError.id}-${column.id}`} title={column.title}>
                    <p className="label">{column.title}</p>
                    {column.databaseTable.includes("requestError") && column.id !== "createdDate" && column.id !== "createdTime" && (
                      <span>{requestError[column.databaseColumn]}</span>
                    )}
                    {column.id === "createdDate" && <span>{shortDate(requestError[column.databaseColumn])}</span>}
                    {column.id === "createdTime" && <span>{fullTime(requestError[column.databaseColumn])}</span>}
                    {column.databaseTable.includes("bottler") && <span>{requestError.bottler[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("user") && <span>{requestError.user && requestError.user[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("equipmentType") && <span>{requestError.equipmentProblem.equipmentType[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("equipmentProblem") && <span>{requestError.equipmentProblem[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("equipment") && <span>{requestError.equipment && requestError.equipment[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("priority") && <span>{requestError.equipmentProblem.priority[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("outlet") && <span>{requestError.outlet[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("requestEquipmentLocation") && (
                      <span>{requestError.requestEquipmentLocation && requestError.requestEquipmentLocation[column.databaseColumn]}</span>
                    )}
                    {column.databaseTable.includes("requestContact") && column.databaseColumn.includes("Notification") && (
                      <span>{requestError.requestContact && requestError.requestContact[column.databaseColumn] ? "Yes" : "No"}</span>
                    )}
                    {column.databaseTable.includes("contactRole") && <span>{requestError.requestContact && requestError.requestContact.contactRole[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("requestContact") && <span>{requestError.requestContact && requestError.requestContact[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("requestEmployee") && <span>{requestError.requestEmployee && requestError.requestEmployee[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("requestEmployee") && column.databaseColumn.includes("Notification") && (
                      <span>{requestError.requestEmployee && requestError.requestEmployee[column.databaseColumn] ? "Yes" : "No"}</span>
                    )}
                  </Td>
                );
              } else if (include) {
                return (
                  <Td key={`requestError-column-${requestError.id}-${column.id}`} title={column.title}>
                    <p className="label">{column.title}</p>
                    {column.databaseTable.includes("requestError") && column.id !== "createdDate" && column.id !== "createdTime" && (
                      <span>{requestError[column.databaseColumn]}</span>
                    )}
                    {column.id === "createdDate" && <span>{shortDate(requestError[column.databaseColumn])}</span>}
                    {column.id === "createdTime" && <span>{fullTime(requestError[column.databaseColumn])}</span>}
                    {column.databaseTable.includes("bottler") && <span>{requestError.bottler[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("user") && <span>{requestError.user && requestError.user[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("equipmentType") && <span>{requestError.equipmentProblem.equipmentType[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("equipmentProblem") && <span>{requestError.equipmentProblem[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("equipment") && <span>{requestError.equipment && requestError.equipment[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("priority") && <span>{requestError.equipmentProblem.priority[column.databaseColumn]}</span>}
                    {column.databaseTable.includes("outlet") && <span>{requestError.outlet[column.databaseColumn]}</span>}
                  </Td>
                );
              }
            })}
          </Tr>
        );
      });
    } else {
      rows.push(
        <Tr key="no-request-errors-alert">
          <Td colSpan={columns.length}>
            <Alert show={true} type="Info">
              There are no request errors. Switch your Bottler or{" "}
              <a href={"#"} onClick={e => this.resetState(e)}>
                undo your search and filters
              </a>
              .
            </Alert>
          </Td>
        </Tr>
      );
    }

    return rows;
  };

  renderHeaderColumns = (): JSX.Element[] => {
    const headerColumns: JSX.Element[] = [];
    const { appId } = this.props;
    const { count, sortOrder, sortBy } = this.state;

    columns.map((column, index) => {
      const include = column.appIds.find(id => id === appId);
      if (include) {
        const ascending: boolean = column.sortBy === sortBy && sortOrder === "ASC" ? true : false;
        const descending: boolean = column.sortBy === sortBy && sortOrder === "DESC" ? true : false;

        const sort: JSX.Element | null =
          column.sort && count > 1 ? (
            <Sort text={column.title} ascending={ascending} descending={descending} onClick={e => this.handleSort(e, column.sortBy as string)} disabled={false} />
          ) : null;

        headerColumns.push(
          <Th key={index} text={column.text} title={column.title}>
            {sort}
          </Th>
        );
      }
    });

    return headerColumns;
  };

  renderMobileSorter = (): JSX.Element[] => {
    const options: JSX.Element[] = [];

    columns.map(column => {
      if (column.sortMobile) {
        sorting.map(sort => {
          options.push(
            <option value={column.sortBy + " " + sort.database} key={column.sortBy + " " + sort.database}>
              {column.text + " " + sort.text}
            </option>
          );
        });
      }
    });

    return options;
  };

  formatCreatedAtEnd = (date: Date | moment.Moment | string): string => {
    const endDate = moment(date).format("YYYY-MM-DD");
    return `${endDate}T23:59:59${moment().isDST() ? DaylightSavingsTimezoneOptions[0].value : StandardTimezoneOptions[0].value}`;
  };

  formatCreatedAtStart = (date: Date | moment.Moment | string): string => {
    const startDate = moment(date).format("YYYY-MM-DD");
    return `${startDate}T00:00:00${moment().isDST() ? DaylightSavingsTimezoneOptions[0].value : StandardTimezoneOptions[0].value}`;
  };

  getDefaultCreatedAtEnd = (): string => {
    const createdAtEnd = moment().toISOString();
    return this.formatCreatedAtEnd(createdAtEnd);
  };

  getDefaultCreatedAtStart = (): string => {
    const createdAtStart = moment()
      .subtract(29, "days")
      .toISOString();
    return this.formatCreatedAtStart(createdAtStart);
  };
}

export const mapStateToProps = (state: any) => {
  return {
    bottlers: state.bottlers.bottlers,
    bottler: state.bottler.bottler,
    user: state.user.user
  };
};

export default connect(mapStateToProps)(withRouter(withPageLog(RequestErrors)));
