import * as React from "react";
import Kronos from "react-kronos";
import { Icon } from "office-ui-fabric-react";
import moment from "moment";
// components
import Required from "./Required";
import Label from "../label/Label";
import Datepicker from "./Datepicker";

export interface IDateTimePickerProps {
  additionalOptions?: object;
  className?: string;
  date: Date | string | null | moment.Moment;
  disabled?: boolean;
  hideOutsideDateTimes?: boolean;
  id: string;
  maxDate?: string | Date | moment.Moment;
  maxTime?: string | Date | moment.Moment;
  minDate?: string | Date | moment.Moment;
  minTime?: string | Date | moment.Moment;
  onChange: any;
  onChangeDateTime?: (date: Date) => void;
  placeholder?: string;
  preventClickOnDateTimeOutsideRange?: boolean;
  required?: boolean;
  restrictedDates?: Date[];
  shouldTriggerOnChangeForDateTimeOutsideRange?: boolean;
  showRequired?: boolean;
  showTime?: boolean;
  showValidation?: boolean;
  time?: Date | string | null | moment.Moment;
}

export interface IDateTimePickerState {}

export default class DateTimePicker extends React.Component<IDateTimePickerProps, IDateTimePickerState> {
  public render() {
    const {
      additionalOptions,
      className,
      date,
      disabled,
      hideOutsideDateTimes,
      id,
      maxDate,
      maxTime,
      minDate,
      minTime,
      onChange,
      onChangeDateTime,
      placeholder,
      preventClickOnDateTimeOutsideRange,
      required,
      restrictedDates,
      showRequired,
      showTime,
      showValidation,
      shouldTriggerOnChangeForDateTimeOutsideRange,
      time
    } = this.props;

    const req: JSX.Element | null = showRequired ? <Required /> : null;

    let cssClass: string = "dateTimePicker";

    if (required) {
      cssClass += " dateTimePickerRequired";
    }

    if (className) {
      cssClass += " " + className;
    }

    const options = {
      format: {
        hour: "h:mm a"
      },
      ...additionalOptions
    };

    return (
      <div className="dateTimePickerWrapper">
        <div className="dateTimePickerDate">
          <Label htmlFor={`${id}Date`} srOnly={true} text="Pick A Date" />
          {restrictedDates ? (
            <Datepicker
              allowTextInput={true}
              onChange={onChange}
              placeholder={placeholder || ""}
              value={date ? moment(date).toDate() : null}
              minDate={minDate ? moment(minDate).toDate() : undefined}
              maxDate={maxDate ? moment(maxDate).toDate() : undefined}
              restrictedDates={restrictedDates}
            />
          ) : (
            <>
              <Kronos
                date={date}
                disabled={disabled ? disabled : false}
                format="M/D/YYYY"
                inputClassName={cssClass}
                inputId={`${id}Date`}
                max={maxDate ? maxDate : undefined}
                min={minDate ? minDate : undefined}
                name={`${id}Date`}
                onChangeDateTime={onChange}
                options={options}
                placeholder={placeholder ? placeholder : ""}
                preventClickOnDateTimeOutsideRange={preventClickOnDateTimeOutsideRange}
                returnAs={"ISO"}
                shouldTriggerOnChangeForDateTimeOutsideRange={shouldTriggerOnChangeForDateTimeOutsideRange}
              />
              <Label htmlFor={`${id}Date`} text="Pick A Date">
                <Icon iconName="Calendar" />
              </Label>
            </>
          )}
        </div>
        {showTime && (
          <div className="dateTimePickerTime">
            <Label htmlFor={`${id}Time`} srOnly={true} text="Pick A Time" />
            <Kronos
              disabled={disabled ? disabled : false}
              format="h:mm a"
              inputClassName={cssClass}
              inputId={`${id}Time`}
              minTime={minTime || undefined}
              maxTime={maxTime || undefined}
              name={`${id}Time`}
              onChangeDateTime={onChangeDateTime || onChange}
              options={options}
              placeholder={placeholder ? placeholder : ""}
              returnAs={"ISO"}
              time={time}
              timeStep={15}
              hideOutsideDateTimes={hideOutsideDateTimes || false}
            />
            <Label htmlFor={`${id}Time`} text="Pick A Time">
              <Icon iconName="Clock" />
            </Label>
          </div>
        )}
        {req}
        {showValidation && <p>Date is required.</p>}
      </div>
    );
  }
}
