import { getRequest } from "../core/api/requestConfig";
import { IBottlerEmployeeMatch } from "../types/BottlerEmployee";

export const getBottlerEmployeeMatch = (
  bottlerId: string,
  firstName: string,
  middleInitial: string,
  lastName: string,
  nickname: string,
  suffix: string,
  pernr: string,
  costCenter: string,
  payAreaId: string
) => {
  return new Promise<IBottlerEmployeeMatch>((resolve, reject) => {
    getRequest(
      "/legacy/api/v1/bottleremployee/bottler/" +
        bottlerId +
        "/firstname/" +
        firstName +
        "/middleinitial/" +
        middleInitial +
        "/lastname/" +
        lastName +
        "/nickname/" +
        nickname +
        "/suffix/" +
        suffix +
        "/pernr/" +
        pernr +
        "/costcenter/" +
        costCenter +
        "/payareaid/" +
        payAreaId
    )
      .then((match: IBottlerEmployeeMatch) => {
        return resolve(match);
      })
      .catch(error => {
        console.log(error);
        return reject(null);
      });
  });
};
