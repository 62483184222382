export enum Bottlers {
  Mercury = 3,
  Swire = 4,
  Abarta = 5,
  Canada = 6,
  Florida = 7,
  NewEngland = 8,
  Southwest = 9,
  Durango = 10,
  GreatLakes = 11,
  Heartland = 12,
  Liberty = 13,
  Reyes = 14,
  United = 15,
  SalesAndServices = 16,
  Consolidated = 17,
  CokeOne = 18,
  Bsna = 19
}
