import * as React from "react";

export default class H5 extends React.Component {
  public render() {
    return (
      <h5 className="h5">
        {this.props.children}
      </h5>
    );
  }
}
