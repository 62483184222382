import { getRequest } from "../core/api/requestConfig";
import { IEquipmentRepairRequestTotalsResponse } from "../types/EquipmentRepairRequest";

/**
 * Get equipment repair request totals from SQL
 * @param bottlerIds number | number[]
 * @param createdAtStart string
 * @param createdAtEnd string
 * @param appId number
 * @returns IEquipmentRepairRequestTotalsResponse
 */
export const getEquipmentRepairRequestTotals = async (
  bottlerIds: number | number[],
  createdAtStart: string,
  createdAtEnd: string,
  appId: number
): Promise<IEquipmentRepairRequestTotalsResponse> => {
  try {
    let query = `?createdAtStart=${createdAtStart}&createdAtEnd=${createdAtEnd}&appId=${appId}`;

    if (Array.isArray(bottlerIds)) {
      bottlerIds.map(bottlerId => {
        query += `&bottlerId=${bottlerId}`;
      });
    } else {
      query += `&bottlerId=${bottlerIds}`;
    }

    const response = await getRequest(`/api/v3/equipmentRepairRequests/totals${query}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
