import * as React from "react";

class Footer extends React.Component {
  constructor(props: any) {
    super(props);
    this._currentYear = this._currentYear.bind(this);
  }
  public render() {
    const currentYear: number = this._currentYear();
    return (
      <footer className="footer" id="footer">
        &copy; {currentYear} Coca-Cola Business Services North America LLC.
      </footer>
    );
  }
  private _currentYear(): number {
    return new Date().getFullYear();
  }
}

export default Footer;
