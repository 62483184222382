import * as React from "react";
// components
import RequestDetail from "./RequestDetail";
// logging
import { withPageLog } from "../../logging/LogComponentChange";

export interface IHrRequestProps {}

export interface IHrRequestState {}

class HrRequest extends React.Component<IHrRequestProps, IHrRequestState> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="hrRequest">
        <RequestDetail />
      </div>
    );
  }
}

export default withPageLog(HrRequest);
