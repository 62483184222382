import * as React from "react";
import { IUser } from "../../types/User";

interface IAvatarProps {
  tag?: string;
  children?: any;
  className?: string;
  showName?: boolean;
  user: IUser;
}

const Avatar = ({ className, showName, user }: IAvatarProps) => {
  let initials: string = "";
  let name: string = "";

  if (user.name && user.name.includes(" ")) {
    const split: string[] = user.name ? user.name.split(" ") : [];
    initials =
      split.length > 0 && split[0] && split[1] ? split[0].charAt(0) + split[1].charAt(0) : split.length > 0 && split[0] ? split[0].charAt(0) : "";
    name = user.name;
  } else if (user.firstname && user.lastname) {
    initials = `${user.firstname[0]}${user.lastname[0]}`;
    name = `${user.firstname} ${user.lastname}`;
  } else if (user.email) {
    initials = `${user.email[0]}${user.email[1]}`;
    name = user.email;
  } else if (user.username) {
    initials = `${user.username[0]}${user.username[1]}`;
    name = user.username;
  }

  return (
    <div className="avatar">
      <p className={className ? `loggedInAsPlaceholder ${className}` : "loggedInAsPlaceholder"}>{initials.toUpperCase()}</p>
      {showName && <p className={"avatarName"}>{name}</p>}
    </div>
  );
};

export default Avatar;
