import * as React from "react";
import Swagger from "./Swagger";
import Permissions from "../permissions/Permissions";

export interface ISwaggerV1Props {}

export interface ISwaggerV1State {}

class SwaggerV1 extends React.Component<ISwaggerV1Props, ISwaggerV1State> {
  public render(): React.ReactElement<ISwaggerV1Props> {
    return (
      <Permissions title={`Swagger v1`}>
        <Swagger version="v1" />
      </Permissions>
    );
  }
}

export default SwaggerV1;
