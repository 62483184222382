import * as React from "react";
import { connect } from "react-redux";
import Switcher from "./Switcher";
import { getBottlersCreator, setBottlersCreator } from "../../core/bottler/bottlers";
import { Bottler, getBottlerCreator, setBottlerCreator } from "../../core/bottler/bottler";

export interface IBottlerSwitcherProps {
  bottler: Bottler;
  bottlers: Bottler[];
  config: any;
  defaultBottler?: number | null;
  disabled?: boolean;
  getBottlersCreator: any;
  getBottlerCreator: any;
  handleChange?: any;
  headingText?: string;
  labelText?: string;
  requestTypeId: number | number[];
  setBottlerCreator: any;
  setBottlersCreator: any;
  showAllOption?: boolean;
}

class BottlerSwitcher extends React.Component<IBottlerSwitcherProps> {
  public componentDidMount() {
    this._getBottlers();
  }

  public componentDidUpdate(prevProps: IBottlerSwitcherProps) {
    const { bottler, bottlers, defaultBottler, showAllOption } = this.props;

    // this logic is here to set the current bottler in redux
    // based on the array of bottlers we have for the current screen
    if (bottlers && bottlers.length > 0 && bottlers !== prevProps.bottlers) {
      // if we have a bottler in redux already
      if (bottler) {
        // make sure that bottler is in the new list of bottlers
        let hasCurrentBottler: boolean = false;
        bottlers.map(_bottler => {
          if (_bottler && bottler.id === _bottler.id) {
            hasCurrentBottler = true;
          }
        });
        // if it is, set that bottler
        if (hasCurrentBottler) {
          this._setBottler(bottler);
        } else {
          // else if we don't have a showAllOption
          // set to the first bottler
          if (!showAllOption) {
            this._setBottler(bottlers[0]);
          } else {
            // else showAllOption
            this._setBottler(null);
          }
        }
      } else if (defaultBottler) {
        // else if we have a default bottler id set as a prop
        bottlers.map(_bottler => {
          if (_bottler && defaultBottler === _bottler.id) {
            this._setBottler(_bottler);
          }
        });
      } else if (!bottler && !showAllOption) {
        // else if we don't have a bottler in redux
        // or a default bottler id
        // and we don't have showAllOption
        // set to the first bottler
        this._setBottler(bottlers[0]);
      } else {
        // else showAllOption
        this._setBottler(null);
      }
    }

    if (bottlers && bottlers.length > 0 && defaultBottler && (!bottler || (bottler && defaultBottler !== bottler.id))) {
      bottlers.map(_bottler => {
        if (_bottler && defaultBottler === _bottler.id) {
          this._setBottler(_bottler);
        }
      });
    }
  }

  public render() {
    const { bottler, bottlers, showAllOption } = this.props;

    const options: JSX.Element[] = [];

    if (bottlers) {
      if (showAllOption) {
        options.push(
          <option key="0" value={0}>
            All
          </option>
        );
      }

      bottlers.map(_bottler => {
        if (_bottler) {
          options.push(
            <option key={_bottler.id} value={_bottler.id}>
              {_bottler.name}
            </option>
          );
        }
      });
    }

    return (
      <div className="bottlerSwitcher">
        <Switcher
          disabled={this.props.disabled ? this.props.disabled : false}
          headingText={this.props.headingText ? this.props.headingText : "Switch Bottler:"}
          labelText={this.props.labelText ? this.props.labelText : "Switch Bottler"}
          id="bottler-id"
          loading={!this.props.bottlers}
          onChange={
            this.props.handleChange
              ? e => {
                  this._changeBottler(e);
                  this.props.handleChange(e);
                }
              : e => {
                  this._changeBottler(e);
                }
          }
          options={options}
          value={bottler && bottler.id ? bottler.id.toString() : ""}
        />
      </div>
    );
  }

  private _getBottlers() {
    const { requestTypeId } = this.props;

    this.props.getBottlersCreator(requestTypeId);
  }

  private _setBottler(bottler: Bottler | null) {
    this.props.setBottlerCreator(bottler);
  }

  private _changeBottler(e: React.ChangeEvent<HTMLSelectElement>): void {
    const value: string = e.target.value;

    const bottler = this.props.bottlers.find(_bottler => {
      if (_bottler) {
        return _bottler.id === Number(value);
      } else {
        return false;
      }
    });
    if (bottler) {
      this._setBottler(bottler);
    } else {
      this._setBottler(null);
    }
  }
}

export const mapStateToProps = (state: any, ownProps: any) => {
  return {
    bottlers: state.bottlers.bottlers,
    bottler: state.bottler.bottler,
    config: state.config.config,
    programs: state.programs.programs,
    program: state.program.program,
    ownProps
  };
};

export default connect(
  mapStateToProps,
  {
    getBottlersCreator,
    getBottlerCreator,
    setBottlerCreator,
    setBottlersCreator
  }
)(BottlerSwitcher);
