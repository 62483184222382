import * as React from "react";

export interface IPaginationProps {
  count: number;
  loading: boolean;
  page: number;
  perPage: number;
  paginate: (e: React.MouseEvent<HTMLButtonElement>, page: number) => void;
}

export interface IPaginationState {
  show: number;
}

export default class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      show: 2
    };
    this._handleResize = this._handleResize.bind(this);
  }

  public componentWillMount(): void {
    this._handleResize();
  }

  public componentDidMount(): void {
    window.addEventListener("resize", this._handleResize);
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this._handleResize);
  }

  private _handleResize(): void {
    const width: number = window.innerWidth;
    if (width >= 568) {
      this.setState({ show: 2 });
    }
    if (width < 568 && width > 414) {
      this.setState({ show: 1 });
    }
    if (width <= 414) {
      this.setState({ show: 0 });
    }
  }

  public render(): React.ReactElement<IPaginationProps> {
    const { count, loading, page, perPage, paginate } = this.props;

    let pageCount: number = count / perPage;
    pageCount = Math.ceil(pageCount);

    const firstPrevDisabled: boolean = page === 1 || loading ? true : false;

    const first: JSX.Element = (
      <button className={"paginationButton"} disabled={firstPrevDisabled} onClick={e => paginate(e, 1)}>
        First
      </button>
    );

    const prev: JSX.Element = (
      <button className={"paginationButton"} disabled={firstPrevDisabled} onClick={e => paginate(e, page - 1)}>
        Prev
      </button>
    );

    const nextLastDisabled: boolean = count === 0 || page === pageCount || loading ? true : false;

    const next: JSX.Element = (
      <button className={"paginationButton"} disabled={nextLastDisabled} onClick={e => paginate(e, page + 1)}>
        Next
      </button>
    );

    const last: JSX.Element = (
      <button className={"paginationButton"} disabled={nextLastDisabled} onClick={e => paginate(e, pageCount)}>
        Last
      </button>
    );

    const pages: JSX.Element[] = [];

    const showTotal: number = this.state.show * 2 + 1;

    if (pageCount <= showTotal) {
      for (let i: number = 0; i < pageCount; i++) {
        let className: string = page === i + 1 ? "paginationButtonActive" : "paginationButton";

        pages.push(
          <button className={className} disabled={loading} key={i + 1} onClick={e => paginate(e, i + 1)}>
            {i + 1}
          </button>
        );
      }
    } else {
      let paging: number = this.state.show;
      let left: number = page - (paging + 1);
      let right: number = page;
      if (paging > 0) {
        for (let i: number = 0; i < paging; i++) {
          if (left >= 0) {
            let goTo: number = left + 1;

            pages.push(
              <button className={"paginationButton"} disabled={loading} key={goTo} onClick={e => paginate(e, goTo)}>
                {goTo}
              </button>
            );
          }
          left++;
        }
      }
      pages.push(
        <button className={"paginationButtonActive"} disabled={loading} key={page} onClick={e => paginate(e, page)}>
          {page}
        </button>
      );
      if (paging > 0) {
        for (let i: number = 0; i < paging; i++) {
          if (right < pageCount) {
            let goTo: number = right + 1;

            pages.push(
              <button className={"paginationButton"} disabled={loading} key={goTo} onClick={e => paginate(e, goTo)}>
                {goTo}
              </button>
            );
          }
          right++;
        }
      }
    }

    return (
      <div className={"pagination"}>
        <ul className={"paginationList"}>
          <li className={"paginationListItem"}>{first}</li>
          <li className={"paginationListItem"}>{prev}</li>
          {pages.map((p, index) => {
            return [
              <li className={"paginationListItem"} key={index}>
                {p}
              </li>
            ];
          })}
          <li className={"paginationListItem"}>{next}</li>
          <li className={"paginationListItem"}>{last}</li>
        </ul>
      </div>
    );
  }
}
