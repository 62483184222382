import * as React from "react";
import P from "../typography/P";

export interface IHelpTextProps {
  className?: string;
  text?: string;
}

export default class HelpText extends React.Component<IHelpTextProps, {}> {
  public render(): React.ReactElement<IHelpTextProps> {
    const { children, className, text } = this.props;

    return (
      <P className={`helpText ${className}`}>
        {text}
        {children}
      </P>
    );
  }
}
