import { getRequest } from "../../api/requestConfig";

type Programs = {
  programs: Program[];
} | null;

type Program = {
  description: string;
  id: string;
  phoneNumber: string;
  programID: string;
  toNotificationEmail: string;
  userDomain: string;
} | null;

enum TypeKeys {
  GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS",
  GET_PROGRAMS_FAILURE = "GET_PROGRAMS_FAILURE"
}

type Error = string | null;

type getProgramsSuccess = {
  programs: Programs;
  type: TypeKeys.GET_PROGRAMS_SUCCESS;
};

type getProgramsFailure = {
  error: Error;
  type: TypeKeys.GET_PROGRAMS_FAILURE;
};

/**
 *
 * @param programs programs values
 */
export const getProgramsSuccessCreator = (
  programs: Programs
): getProgramsSuccess => {
  return {
    programs,
    type: TypeKeys.GET_PROGRAMS_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const getProgramsFailureCreator = (error: Error): getProgramsFailure => {
  return {
    error,
    type: TypeKeys.GET_PROGRAMS_FAILURE
  };
};

/**
 *
 * @param apiUrl relative url for api endpoint
 */
export const getProgramsCreator = () => {
  return async dispatch => {
    try {
      const programs = await getRequest("/api/v1/listPrograms");
      dispatch(getProgramsSuccessCreator(programs));
    } catch (error) {
      console.log(error);
      dispatch(getProgramsFailureCreator(error));
    }
  };
};

export type Actions = getProgramsSuccess | getProgramsFailure;

export interface IState {
  isLoading: boolean;
  programs: Programs;
  error: Error;
}

export const initialState = {
  isLoading: true,
  programs: null,
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_PROGRAMS_SUCCESS:
      newState.programs = action.programs;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_PROGRAMS_FAILURE:
      newState.error = action.error as string;
      newState.programs = null;
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};
