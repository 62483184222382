import * as React from "react";

export interface ISecondaryNavLoadingProps {}
export interface ISecondaryNavLoadingState {}

class SecondaryNavLoading extends React.Component<ISecondaryNavLoadingProps, ISecondaryNavLoadingState> {
  public render(): React.ReactElement<ISecondaryNavLoadingProps> {
    return (
      <div className="secondaryNavLinkLoading">
        <div className="secondaryNavLinkIconLoading" />
        <div className="secondaryNavLinkTextLoading">
          <div className="secondaryNavLinkText" />
          <div className="secondaryNavLinkText" />
        </div>
      </div>
    );
  }
}

export default SecondaryNavLoading;
