import { getRequest } from "../../api/requestConfig";

export type Usage = {
  usage: any
} | null

enum TypeKeys {
  GET_USAGE_SUCCESS = "GET_USAGE_SUCCESS",
  GET_USAGE_FAILURE = "GET_USAGE_FAILURE"
}

type Error = string | null

type getUsageSuccess = {
  usage: Usage
  type: TypeKeys.GET_USAGE_SUCCESS
}

type getUsageFailure = {
  error: Error
  type: TypeKeys.GET_USAGE_FAILURE
}

/**
 * 
 * @param usage usage values
 */
export const getUsageSuccessCreator = (usage: Usage): getUsageSuccess => {
  return {
    usage,
    type: TypeKeys.GET_USAGE_SUCCESS
  }
}

/**
 * 
 * @param error error message
 */
export const getUsageFailureCreator = (error: Error): getUsageFailure => {
  return {
    error,
    type: TypeKeys.GET_USAGE_FAILURE
  }
}

/**
 * 
 * @param apiUrl relative url for api endpoint
 */
export const getUsageCreator = (apiUrl: string) => {
  return async dispatch => {
    try {
      const response = await getRequest(apiUrl)
      dispatch(getUsageSuccessCreator(response))
    } catch (error) {
      console.log(error)
      dispatch(getUsageFailureCreator(error))
    }
  }
}

export type Actions = getUsageSuccess | getUsageFailure

export interface IState {
  isLoading: boolean
  usage: Usage
  error: Error
}

export const initialState = {
  isLoading: true,
  usage: null,
  error: null
}

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state
  const newState = { ...state }
  switch (action.type) {
    case TypeKeys.GET_USAGE_SUCCESS:
      newState.usage = action.usage
      newState.error = null
      newState.isLoading = false
      break
    case TypeKeys.GET_USAGE_FAILURE:
      newState.error = action.error as string
      newState.usage = null
      newState.isLoading = false
      break
    default:
      break
  }
  return newState
}
