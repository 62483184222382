import { getRequest } from "../core/api/requestConfig";
import { IEquipmentProblem } from "../types/EquipmentProblems";

export const getEquipmentProblems = async (): Promise<IEquipmentProblem[]> => {
  try {
    const res = await getRequest(`/api/v2/equipmentProblems`);
    return Promise.resolve(res.equipmentProblems);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEquipmentProblemsByEquipmentId = async (equipmentId: number, bottlerId: number): Promise<IEquipmentProblem[]> => {
  try {
    const res = await getRequest(`/api/v2/equipmentProblems?equipmentTypeId=${equipmentId}&bottlerId=${bottlerId}`);
    return Promise.resolve(res.equipmentProblems);
  } catch (error) {
    return Promise.reject(error);
  }
};
