export enum breakpoints {
  xxxxs = "Xxxxs",
  xxxs = "Xxxs",
  xxs = "Xxs",
  xs = "Xs",
  sm = "Sm",
  md = "Md",
  lg = "Lg",
  xl = "Xl",
  xxl = "Xxl",
  xxxl = "Xxxl",
  xxxxl = "Xxxxl"
}
