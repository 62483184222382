import * as React from "react";

interface IProps {
  children: any;
  onClick: any;
  isSelected: boolean;
}

const TabButton = ({ children, onClick, isSelected }: IProps): JSX.Element => {
  return (
    <button
      className={isSelected ? `tabbarButton selected` : "tabbarButton"}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TabButton;
