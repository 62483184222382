import * as React from "react";
import Section from "../../components/layout/Section";
import ErrorNotFound from "../../components/errors/ErrorNotFound";

class NotFound extends React.Component {
  public render() {
    return (
      <div className="notFound">
        <Section>
          <ErrorNotFound />
        </Section>
      </div>
    );
  }
}

export default NotFound;
