import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
// components
import Card from "./Card";
import ButtonLink from "../button/ButtonLink";

interface IRequestCard {
  amount: string | number;
  type: string;
  timeLabel: string;
  onClick: any;
}

const RequestCard = ({ amount, type, timeLabel, onClick }: IRequestCard) => {
  return (
    <Card
      body={
        <>
          <p className="amount">{amount}</p>
          <p className="type">
            {type} Request{amount === 1 ? "" : "s"}
            <br /> For All Users &amp; Bottlers
          </p>
          <p className="time">{timeLabel}</p>
        </>
      }
      bodyPadding={true}
      footer={
        <ButtonLink
          className="buttonLinkBlock"
          iconRight={<Icon iconName="Forward" />}
          onClick={onClick}
          text={`View ${type} Requests`}
          href="#/shared-services/requests"
        />
      }
    />
  );
};

export default RequestCard;
