import { IAdminAppRoute } from "../types/AdminAppRoute";
import { getAdminAppRoutesByAdGroupObjectIds } from "../services/AdminAppRoute";

export enum TypeKeys {
  SET_ADMIN_APP_ROUTES_LOADING = "SET_ADMIN_APP_ROUTES_LOADING",
  GET_ADMIN_APP_ROUTES_SUCCESS = "GET_ADMIN_APP_ROUTES_SUCCESS",
  GET_ADMIN_APP_ROUTES_ERROR = "GET_ADMIN_APP_ROUTES_ERROR",
  SET_IS_ADMIN = "SET_IS_ADMIN"
}

type ErrorMessage = string | null;

type setAdminAppRoutesLoading = {
  type: TypeKeys.SET_ADMIN_APP_ROUTES_LOADING;
  loading: boolean;
};

type getAdminAppRoutesSuccess = {
  type: TypeKeys.GET_ADMIN_APP_ROUTES_SUCCESS;
  adminAppRoutes: IAdminAppRoute[];
};

type getAdminAppRoutesError = {
  type: TypeKeys.GET_ADMIN_APP_ROUTES_ERROR;
  error: ErrorMessage;
};

type setIsAdmin = {
  type: TypeKeys.SET_IS_ADMIN;
  isAdmin: boolean;
};

export const setAdminAppRoutesLoadingAction = (loading: boolean): setAdminAppRoutesLoading => {
  return {
    type: TypeKeys.SET_ADMIN_APP_ROUTES_LOADING,
    loading
  };
};

export const getAdminAppRoutesSuccessAction = (adminAppRoutes: IAdminAppRoute[]): getAdminAppRoutesSuccess => {
  return {
    type: TypeKeys.GET_ADMIN_APP_ROUTES_SUCCESS,
    adminAppRoutes
  };
};

export const getAdminAppRoutesErrorAction = (error: ErrorMessage): getAdminAppRoutesError => {
  return {
    type: TypeKeys.GET_ADMIN_APP_ROUTES_ERROR,
    error
  };
};

export const setIsAdminAction = (isAdmin: boolean): setIsAdmin => {
  return {
    type: TypeKeys.SET_IS_ADMIN,
    isAdmin
  };
};

export const getAdminAppRoutes = (adGroupObjectIds: string[]) => {
  return async dispatch => {
    try {
      dispatch(setAdminAppRoutesLoadingAction(true));

      if (adGroupObjectIds && adGroupObjectIds.length > 0) {
        const adminAppRoutes: IAdminAppRoute[] = await getAdminAppRoutesByAdGroupObjectIds(adGroupObjectIds);

        if (adminAppRoutes.length > 0) {
          dispatch(setIsAdminAction(true));
        } else {
          dispatch(setIsAdminAction(false));
        }

        dispatch(getAdminAppRoutesSuccessAction(adminAppRoutes));
      } else {
        dispatch(setIsAdminAction(false));
        dispatch(getAdminAppRoutesSuccessAction([]));
      }

      dispatch(setAdminAppRoutesLoadingAction(false));
    } catch (error) {
      dispatch(getAdminAppRoutesErrorAction(error.message));
      dispatch(setAdminAppRoutesLoadingAction(false));
    }
  };
};

export type Actions = getAdminAppRoutesSuccess | getAdminAppRoutesError | setAdminAppRoutesLoading | setIsAdmin;

export interface IAdminAppRouteState {
  adminAppRoutes: IAdminAppRoute[];
  isAdmin: boolean;
  error: ErrorMessage | null;
  loading: boolean;
}

export const initialState = {
  adminAppRoutes: [],
  isAdmin: true,
  error: null,
  loading: false
};

export const reducer = (state: IAdminAppRouteState, action: Actions) => {
  state = !state ? initialState : state;

  const newState = { ...state };

  switch (action.type) {
    case TypeKeys.SET_ADMIN_APP_ROUTES_LOADING:
      newState.loading = action.loading;
      break;

    case TypeKeys.GET_ADMIN_APP_ROUTES_SUCCESS:
      newState.adminAppRoutes = action.adminAppRoutes;
      newState.error = null;
      break;

    case TypeKeys.GET_ADMIN_APP_ROUTES_ERROR:
      newState.adminAppRoutes = [];
      newState.error = action.error;
      break;

    case TypeKeys.SET_IS_ADMIN:
      newState.isAdmin = action.isAdmin;
      break;

    default:
      break;
  }
  return newState;
};
