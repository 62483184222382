import { putRequest } from "../core/api/requestConfig";
import { IRequestStatusUpdate, IRequestStatusUpdateResponse } from "../types/Request";

export function updateRequestStatus(requestIdAndStatusId: IRequestStatusUpdate): Promise<IRequestStatusUpdateResponse> {
  return new Promise<IRequestStatusUpdateResponse>((resolve, reject) => {
    putRequest("/api/v1/requestStatus", requestIdAndStatusId)
      .then(res => {
        return resolve(res);
      })
      .catch((error: Error) => {
        if (!error.message) {
          error.message = `Error updating status for id ${requestIdAndStatusId.requestId}.`;
        }
        return reject(error);
      });
  });
}
