import * as React from "react";
import P from "../typography/P";

export interface IValidationProps {
  showValidation?: boolean;
  text: string;
  type?: "Error" | "Warning" | "Info";
}
export interface IValidationState {}

export default class Validation extends React.Component<IValidationProps, {}> {
  public render(): React.ReactElement<IValidationProps> {
    const { showValidation, text, type } = this.props;

    let cssClass: string = type ? `validation validation${type}` : "validation";

    if (!showValidation) {
      cssClass = "displayNone";
    }

    return <P className={cssClass}>{text}</P>;
  }
}
