import * as React from "react";
import ErrorMessage from "./ErrorMessage";

export interface IErrorUnauthorizedProps {
  errorMessage?: string;
}

export interface IErrorUnauthorizedState {}

class ErrorUnauthorized extends React.Component<
  IErrorUnauthorizedProps,
  IErrorUnauthorizedState
> {
  public render(): React.ReactElement<IErrorUnauthorizedProps> {
    const { errorMessage } = this.props;

    const message: string = errorMessage
      ? errorMessage
      : "You do not have permission to view this resource.";

    return (
      <ErrorMessage
        className="errorUnauthorized"
        errorCode={401}
        errorCodeType={"Unauthorized"}
        errorMessage={message}
      />
    );
  }
}

export default ErrorUnauthorized;
