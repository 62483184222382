import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Icon } from "office-ui-fabric-react";
// components
import Alert from "../../components/alert/Alert";
import Button from "../../components/button/Button";
import Column from "../../components/grid/Column";
import Field from "../../components/forms/Field";
import Form from "../../components/forms/Form";
import HelpText from "../../components/forms/HelpText";
import Hr from "../../components/typography/Hr";
import Input from "../../components/input/Input";
import Label from "../../components/label/Label";
import Loader from "../../components/loader/Loader";
import LoaderGradient from "../../components/loader/LoaderGradient";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Modal from "../../components/modal/Modal";
import Page from "../../components/page/Page";
import Row from "../../components/grid/Row";
import Search from "../../components/search/Search";
import Select from "../../components/input/Select";
import Sort from "../../components/table/Sort";
import TableWrapper from "../../components/table/TableWrapper";
import Table from "../../components/table/Table";
import TBody from "../../components/table/TBody";
import Td from "../../components/table/Td";
import THead from "../../components/table/THead";
import Tr from "../../components/table/Tr";
import Th from "../../components/table/Th";
// fetch
import { getAdGroupsByName } from "../../services/AdGroup";
import { createAdGroupAdminAppRoute, getAdGroupAdminAppRouteByAdminAppRouteId, deleteAdGroupAdminAppRoute } from "../../services/AdGroupAdminAppRoutes";
import { getAdminAppRoutes, getAdminAppRoute, updateAdminAppRoute, createAdminAppRoute, deleteAdminAppRoute } from "../../services/AdminAppRoutes";
// types
import { IAdGroup, IAdGroupsResponse } from "../../types/AdGroup";
import { IAdGroupAdminAppRouteCreate, IAdGroupAdminAppRouteResponse, IAdGroupAdminAppRoute, IAdGroupAdminAppRoutesResponse } from "../../types/AdGroupAdminAppRoutes";
import { IAdminAppRoute, IAdminAppRoutesResponse, IAdminAppRouteResponse, IAdminAppRouteCreate, adminAppRoutePositions, adminAppRouteTypes } from "../../types/AdminAppRoute";
import { routesColumns } from "../../types/Column";
import { IResponseDeleted } from "../../types/Response";
import ModalFooter from "../../components/modal/ModalFooter";

// import { icons } from "../../types/Icons";

export interface IRoutesProps {}

export interface IRoutesState {
  adGroups: IAdGroup[];
  adGroupAdminAppRoutes: IAdGroupAdminAppRoute[];
  adGroupAdminAppRoutesLoading: boolean;
  adminAppRoute: IAdminAppRoute | null;
  adminAppRoutes: IAdminAppRoute[];
  adminAppRoutesSearched: IAdminAppRoute[];
  deleting: boolean;
  loading: boolean;
  modalLoading: boolean;
  saving: boolean;
  search: string;
  searched: boolean;
  searching: boolean;
  sortBy: string;
  sortOrder: string;
}

enum inputs {
  id = "id",
  text = "text",
  title = "title",
  href = "href",
  icon = "icon",
  sortOrder = "sortOrder",
  parentId = "parentId",
  featureFlag = "featureFlag",
  adminAppRouteTypeId = "adminAppRouteTypeId",
  adminAppRoutePositionId = "adminAppRoutePositionId",
  showForAllUsers = "showForAllUsers"
}

type Name = {
  name: string;
};

const showForAllUsers: Name[] = [
  {
    name: "True"
  },
  {
    name: "False"
  }
];

class Routes extends React.Component<IRoutesProps, IRoutesState> {
  private searchInputTimer;
  private SORT_ORDER_ASC: string = "ASC";
  private SORT_ORDER_DESC: string = "DESC";
  private DEFAULT_SORT_ORDER: string = this.SORT_ORDER_ASC;
  private DEFAULT_SORT_BY: string = "text";

  constructor(props: IRoutesProps) {
    super(props);
    this.state = {
      adGroups: [],
      adGroupAdminAppRoutes: [],
      adGroupAdminAppRoutesLoading: false,
      adminAppRoute: null,
      adminAppRoutes: [],
      adminAppRoutesSearched: [],
      deleting: false,
      loading: true,
      modalLoading: false,
      saving: false,
      search: "",
      searched: false,
      searching: false,
      sortBy: this.DEFAULT_SORT_BY,
      sortOrder: this.DEFAULT_SORT_ORDER
    };

    this._handleAdminAppRouteChange = this._handleAdminAppRouteChange.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._handleSearchChange = this._handleSearchChange.bind(this);
    this._handleSearchClick = this._handleSearchClick.bind(this);
    this._handleSearchKeyUp = this._handleSearchKeyUp.bind(this);
  }

  public componentDidMount(): void {
    this._getAdGroupsByName();
    this._getAdminAppRoutes();
  }

  public render(): React.ReactElement<IRoutesProps> {
    const { deleting, loading, saving, search, searching } = this.state;

    const headerColumns: JSX.Element[] = this._renderHeaderColumns();
    const rows: JSX.Element[] = this._renderAdminAppRoutes();
    const addAdminAppRoute: JSX.Element = this._renderAddAdminAppRoute();

    return (
      <Page className="routes" permissions={true} title="Settings: Routes">
        <Search
          action={addAdminAppRoute}
          autoFocus={false}
          onSearchChange={this._handleSearchChange}
          onSearchKeyUp={this._handleSearchKeyUp}
          onSearchClick={this._handleSearchClick}
          placeholder={"Search routes..."}
          search={search}
          searchButtonText={"Search"}
          searchButtonTitle={"Search Routes"}
          searchDisabled={loading}
          searchLabelText={"Search Routes"}
        />
        <LoaderWrapper>
          <TableWrapper loading={loading} searching={searching}>
            <Table>
              <THead>
                <Tr>{headerColumns}</Tr>
              </THead>
              <TBody>{rows}</TBody>
            </Table>
          </TableWrapper>
          <Loader deleting={deleting} loading={loading} type="Overlay" saving={saving} showImage={true} position="Top" />
        </LoaderWrapper>
        <ToastContainer />
      </Page>
    );
  }

  private async _getAdGroupsByName(): Promise<void> {
    try {
      const response: IAdGroupsResponse = await getAdGroupsByName(`BSNA-AAD-Request`);

      this.setState({
        adGroups: response.adGroups
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

    this.setState({
      loading: false
    });
  }

  private _getAdminAppRoutes(): void {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const response: IAdminAppRoutesResponse = await getAdminAppRoutes();

          this.setState({
            adminAppRoutes: response.adminAppRoutes
          });
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }

        this.setState({
          loading: false
        });
      }
    );
  }

  private _getAdGroupAdminAppRoutes(): void {
    const { adminAppRoute } = this.state;

    if (adminAppRoute) {
      this.setState(
        {
          adGroupAdminAppRoutes: [],
          adGroupAdminAppRoutesLoading: true
        },
        async () => {
          try {
            const response: IAdGroupAdminAppRoutesResponse = await getAdGroupAdminAppRouteByAdminAppRouteId(adminAppRoute.id);

            this.setState({
              adGroupAdminAppRoutes: response.adGroupAdminAppRoutes
            });
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }

          this.setState({
            adGroupAdminAppRoutesLoading: false
          });
        }
      );
    }
  }

  private _createAdGroupAdminAppRoute(e: React.ChangeEvent<HTMLSelectElement>): void {
    const { value } = e.target;
    const { adGroups, adminAppRoute } = this.state;

    let adGroup: IAdGroup | null = null;
    adGroups.map(group => {
      if (value && Number(value) === group.id) {
        adGroup = group;
      }
    });

    if (adGroup && adminAppRoute) {
      this.setState(
        {
          saving: true
        },
        async () => {
          try {
            if (adGroup) {
              const create: IAdGroupAdminAppRouteCreate = {
                adGroupId: adGroup.id,
                adGroupAzureGroupId: adGroup.azureGroupId,
                adminAppRouteId: adminAppRoute.id
              };

              const response: IAdGroupAdminAppRouteResponse = await createAdGroupAdminAppRoute(create);

              toast.success(response.message, {
                position: toast.POSITION.BOTTOM_CENTER
              });

              this._getAdGroupAdminAppRoutes();
            }
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }

          this.setState({
            saving: false
          });
        }
      );
    }
  }

  private _deleteAdGroupAdminAppRoute(id: number): void {
    const result: boolean = window.confirm("Are you sure you want to delete the AD group joined to this route?");
    if (result) {
      this.setState(
        {
          deleting: true
        },
        async () => {
          try {
            const response: IResponseDeleted = await deleteAdGroupAdminAppRoute(id);

            if (response.deleted) {
              toast.success(response.message, {
                position: toast.POSITION.BOTTOM_CENTER
              });

              this._getAdGroupAdminAppRoutes();
            }
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }

          this.setState({
            deleting: false
          });
        }
      );
    }
  }

  private _getAdminAppRoute(id: number): void {
    this.setState(
      {
        modalLoading: true
      },
      async () => {
        try {
          const response: IAdminAppRouteResponse = await getAdminAppRoute(id);

          this.setState(
            {
              adminAppRoute: response.adminAppRoute
            },
            () => {
              this._getAdGroupAdminAppRoutes();
            }
          );
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }

        this.setState({
          modalLoading: false
        });
      }
    );
  }

  private _createAdminAppRoute(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    const { adminAppRoute } = this.state;

    if (adminAppRoute) {
      this.setState(
        {
          saving: true
        },
        async () => {
          try {
            const response: IAdminAppRouteResponse = await createAdminAppRoute(adminAppRoute);

            toast.success(response.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState(
              {
                adminAppRoute: response.adminAppRoute
              },
              () => {
                const route = this.state.adminAppRoute;

                if (route) {
                  // add the newly created route to the grid
                  const adminAppRoutes = this.state.adminAppRoutes.slice();

                  adminAppRoutes.push(route);

                  this.setState(
                    {
                      adminAppRoutes
                    },
                    () => {
                      this._handleSort(null, false);
                    }
                  );
                }
              }
            );
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }

          this.setState({
            saving: false
          });
        }
      );
    }
  }

  private _updateAdminAppRoute(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    const { adminAppRoute } = this.state;

    if (adminAppRoute) {
      this.setState(
        {
          saving: true
        },
        async () => {
          try {
            const response: IAdminAppRouteResponse = await updateAdminAppRoute(adminAppRoute);

            toast.success(response.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState(
              {
                adminAppRoute: response.adminAppRoute
              },
              () => {
                const route = this.state.adminAppRoute;

                if (route) {
                  // update the route in the grid
                  const adminAppRoutes = this.state.adminAppRoutes.slice();

                  adminAppRoutes.map(aar => {
                    if (aar.id === route.id) {
                      aar.href = route.href;
                      aar.text = route.text;
                    }
                  });

                  this.setState(
                    {
                      adminAppRoutes
                    },
                    () => {
                      this._handleSort(null, false);
                    }
                  );
                }
              }
            );
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }

          this.setState({
            saving: false
          });
        }
      );
    }
  }

  private _deleteAdminAppRoute(id: number): void {
    const result: boolean = window.confirm("Are you sure you want to delete this route?");
    if (result) {
      this.setState(
        {
          deleting: true
        },
        async () => {
          try {
            const response: IResponseDeleted = await deleteAdminAppRoute(id);

            if (response.deleted) {
              toast.success(response.message, {
                position: toast.POSITION.BOTTOM_CENTER
              });

              this._getAdminAppRoutes();
            }
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }

          this.setState({
            deleting: false
          });
        }
      );
    }
  }

  private _handleSort(column: string | null, reorder: boolean) {
    const { sortBy, sortOrder } = this.state;

    const order: string = reorder && sortOrder === this.SORT_ORDER_DESC ? this.SORT_ORDER_ASC : reorder && sortOrder === this.SORT_ORDER_ASC ? this.SORT_ORDER_DESC : sortOrder;

    const adminAppRoutes: IAdminAppRoute[] = this._sortAdminAppRoutes(this.state.adminAppRoutes.slice(), column ? column : sortBy, order);
    const adminAppRoutesSearched = this._sortAdminAppRoutes(this.state.adminAppRoutesSearched.slice(), column ? column : sortBy, order);

    this.setState({
      adminAppRoutes,
      adminAppRoutesSearched,
      sortBy: column ? column : sortBy,
      sortOrder: order
    });
  }

  private _sortAdminAppRoutes(adminAppRoutes: IAdminAppRoute[], sortBy: string, sortOrder: string): IAdminAppRoute[] {
    return adminAppRoutes.sort((a, b) => {
      const A: string | number = a[sortBy];
      const B: string | number = b[sortBy];

      if (sortOrder === this.SORT_ORDER_DESC) {
        return A < B ? 1 : -1; // ASC
      } else {
        return A > B ? 1 : -1; // DESC
      }
    });
  }

  private _handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const search: string = e.target.value;

    clearTimeout(this.searchInputTimer);

    this.setState(
      {
        search
      },
      () => {
        this.searchInputTimer = setTimeout(() => {
          this._searchAdminAppRoutes();
        }, 300);
      }
    );
  }

  private _handleSearchKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === "Enter") {
      this._searchAdminAppRoutes();
    }
  }

  private _handleSearchClick(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    this._searchAdminAppRoutes();
  }

  private _searchAdminAppRoutes(): void {
    const { adminAppRoutes, search } = this.state;

    if (search) {
      const condition = new RegExp(search, "i");

      this.setState(
        {
          searching: true
        },
        () => {
          setTimeout(() => {
            const adminAppRoutesSearched = adminAppRoutes.filter(adminAppRoute => {
              const text: boolean = condition.test(adminAppRoute.text);
              const href: boolean = condition.test(adminAppRoute.href);
              return text || href;
            });

            this.setState({
              adminAppRoutesSearched,
              searched: true,
              searching: false
            });
          }, 300);
        }
      );
    } else {
      this.setState(
        {
          searching: true
        },
        () => {
          setTimeout(() => {
            this.setState({
              adminAppRoutesSearched: [],
              searched: false,
              searching: false
            });
          }, 300);
        }
      );
    }
  }

  private _renderHeaderColumns(): JSX.Element[] {
    const { adminAppRoutes, adminAppRoutesSearched, sortOrder, sortBy } = this.state;

    const headerColumns: JSX.Element[] = routesColumns.map((column, index) => {
      return (
        <Th hidden={false} text={column.text} title={column.title} key={`Th-${index}`}>
          {column.sortBy ? (
            <Sort
              text={column.title}
              ascending={column.sortBy === sortBy && sortOrder === this.SORT_ORDER_ASC ? true : false}
              descending={column.sortBy === sortBy && sortOrder === this.SORT_ORDER_DESC ? true : false}
              onClick={e => this._handleSort(column.sortBy, true)}
              disabled={adminAppRoutesSearched.length === 1 ? true : adminAppRoutes.length > 1 ? false : true}
            />
          ) : null}
        </Th>
      );
    });
    return headerColumns;
  }

  private _renderAdminAppRoutes(): JSX.Element[] {
    const { adminAppRoutes, adminAppRoutesSearched, loading, modalLoading, deleting, saving, searched } = this.state;

    const rows: JSX.Element[] = [];

    const modalBody: JSX.Element[] = this._renderAdminAppRoute();

    const routes: IAdminAppRoute[] = searched || adminAppRoutesSearched.length ? adminAppRoutesSearched : adminAppRoutes;

    if (routes && routes.length > 0) {
      routes.map((route, index) => {
        const text: JSX.Element = (
          <Td>
            <Label srOnlyAt="Md" text={`Text`} />
            {route.text}
          </Td>
        );

        const href: JSX.Element = (
          <Td>
            <Label srOnlyAt="Md" text={`Href`} />
            {route.href}
          </Td>
        );

        const actions: JSX.Element = (
          <Td>
            <Modal
              body={modalBody}
              bodyDeleting={deleting}
              bodyLoading={modalLoading}
              bodyLoadingShowImage={true}
              bodyLoadingPosition="Top"
              bodyPadding={false}
              bodySaving={saving}
              cancelDisabled={deleting || saving}
              cancelIcon={"Cancel"}
              cancelText="Close"
              cancelTitle="Close"
              className="addEditRoute"
              heading="Edit Route"
              id="editRoute"
              modalSize="Md"
              onCancel={e => this._closeAdminAppRoute()}
              onOpen={e => this._getAdminAppRoute(route.id)}
              openClassName="buttonLinkInfoColor"
              openIcon="Edit"
              openText="Edit"
              openTitle="Edit Route"
              showCancel={false}
              showSave={false}
              showFooter={false}
            />
            <Button className="buttonLinkPrimaryColor" iconLeft={<Icon iconName="Trash" />} onClick={e => this._deleteAdminAppRoute(route.id)} text="Delete" title="Delete" />
          </Td>
        );

        rows.push(
          <Tr hover={true} striped={true} key={`Tr-${index}`}>
            {text}
            {href}
            {actions}
          </Tr>
        );
      });
    } else {
      if (!loading) {
        rows.push(
          <Tr key={`Tr-NoResults`}>
            <Td colSpan={routesColumns.length}>
              <Alert show={true} type="Info">
                There are no routes to display.
              </Alert>
            </Td>
          </Tr>
        );
      } else {
        const loadingRows = ["1", "2", "3", "4"];
        loadingRows.map(row => {
          rows.push(
            <Tr key={`Tr-Loading-${row}`}>
              {routesColumns.map((column, index) => {
                return (
                  <Td key={`column-${index}`}>
                    <LoaderGradient />
                  </Td>
                );
              })}
            </Tr>
          );
        });
      }
    }

    return rows;
  }

  private _renderAdminAppRoute(): JSX.Element[] {
    const { adGroups, adGroupAdminAppRoutes, adGroupAdminAppRoutesLoading, adminAppRoute, adminAppRoutes, deleting, saving } = this.state;

    let element: JSX.Element[] = [];

    if (adminAppRoute) {
      element.push(
        <Form key={`adminAppRoute`} onSubmit={adminAppRoute.id ? e => this._updateAdminAppRoute(e) : e => this._createAdminAppRoute(e)}>
          <div className="formInner">
            <Row>
              {adminAppRoute.id && (
                <Column breakpoint="Sm" columns={2}>
                  <Field>
                    <Label htmlFor={inputs.id.toString()} text="ID" />
                    <Input
                      id={inputs.id.toString()}
                      onChange={e => this._handleAdminAppRouteChange(e)}
                      name={inputs.id.toString()}
                      readOnly={true}
                      value={adminAppRoute.id.toString()}
                    />
                  </Field>
                </Column>
              )}

              <Column breakpoint="Sm" columns={adminAppRoute.id ? 2 : 4}>
                <Field>
                  <Label htmlFor={inputs.adminAppRouteTypeId.toString()} required={true} text="Type" />
                  <Select
                    autoFocus={adminAppRoute.id ? false : true}
                    id={inputs.adminAppRouteTypeId.toString()}
                    name={inputs.adminAppRouteTypeId.toString()}
                    onChange={e => this._handleAdminAppRouteChange(e)}
                    options={adminAppRouteTypes.map(adminAppRouteType => {
                      return (
                        <option key={`adminAppRouteType-${adminAppRouteType.id.toString()}`} value={adminAppRouteType.id.toString()}>
                          {adminAppRouteType.name}
                        </option>
                      );
                    })}
                    required={true}
                    showPlaceholder={false}
                    value={adminAppRoute.adminAppRouteTypeId ? adminAppRoute.adminAppRouteTypeId.toString() : ""}
                  />
                </Field>
              </Column>

              {adminAppRoute.adminAppRouteTypeId === 1 && (
                <Column breakpoint="Sm" columns={8}>
                  <Field>
                    <Label htmlFor={inputs.adminAppRoutePositionId.toString()} required={true} text="Position" />
                    <Select
                      id={inputs.adminAppRoutePositionId.toString()}
                      name={inputs.adminAppRoutePositionId.toString()}
                      onChange={e => this._handleAdminAppRouteChange(e)}
                      options={adminAppRoutePositions.map(adminAppRoutePosition => {
                        return (
                          <option key={`adminAppRoutePosition-${adminAppRoutePosition.id.toString()}`} value={adminAppRoutePosition.id.toString()}>
                            {adminAppRoutePosition.name}
                          </option>
                        );
                      })}
                      placeholder="Select a position..."
                      required={true}
                      showPlaceholder={true}
                      value={adminAppRoute.adminAppRoutePositionId ? adminAppRoute.adminAppRoutePositionId.toString() : ""}
                    />
                  </Field>
                </Column>
              )}

              {adminAppRoute.adminAppRouteTypeId === 2 && (
                <Column breakpoint="Sm" columns={8}>
                  <Field>
                    <Label htmlFor={inputs.parentId.toString()} required={true} text="Parent Route" />
                    <Select
                      id={inputs.parentId.toString()}
                      name={inputs.parentId.toString()}
                      onChange={e => this._handleAdminAppRouteChange(e)}
                      options={adminAppRoutes.map(route => {
                        return (
                          <option key={`parnetId-${route.id}`} value={route.id.toString()}>
                            {route.text} ({route.href})
                          </option>
                        );
                      })}
                      required={true}
                      showPlaceholder={true}
                      value={adminAppRoute.parentId ? adminAppRoute.parentId.toString() : ""}
                    />
                    <HelpText text="Primary route the secondary route belongs under" />
                  </Field>
                </Column>
              )}
            </Row>

            <Row>
              <Column breakpoint="Sm" columns={2}>
                <Field>
                  <Label htmlFor={inputs.sortOrder.toString()} required={true} text="Sort Order" />
                  <Input
                    id={inputs.sortOrder.toString()}
                    inputType="number"
                    onChange={e => this._handleAdminAppRouteChange(e)}
                    min={"0"}
                    name={inputs.sortOrder.toString()}
                    required={true}
                    value={adminAppRoute.sortOrder.toString()}
                  />
                </Field>
              </Column>

              <Column breakpoint="Sm" columns={2}>
                <Field>
                  <Label htmlFor={inputs.showForAllUsers.toString()} required={true} text="Show For All" />
                  <Select
                    id={inputs.showForAllUsers.toString()}
                    name={inputs.showForAllUsers.toString()}
                    onChange={e => this._handleAdminAppRouteChange(e)}
                    options={showForAllUsers.map(show => {
                      return (
                        <option key={`showForAllUsers-${show.name}`} value={show.name.toLowerCase()}>
                          {show.name}
                        </option>
                      );
                    })}
                    required={true}
                    value={adminAppRoute.showForAllUsers ? "true" : "false"}
                  />
                </Field>
              </Column>

              <Column breakpoint="Sm" columns={4}>
                <Field>
                  <Label htmlFor={inputs.text.toString()} required={true} text="Text" />
                  <Input id={inputs.text.toString()} onChange={e => this._handleAdminAppRouteChange(e)} name={inputs.text.toString()} required={true} value={adminAppRoute.text} />
                </Field>
              </Column>

              <Column breakpoint="Sm" columns={4}>
                <Field>
                  <Label htmlFor={inputs.icon.toString()} required={true} text="Icon" />
                  <Input id={inputs.icon.toString()} onChange={e => this._handleAdminAppRouteChange(e)} name={inputs.icon.toString()} required={true} value={adminAppRoute.icon} />
                  {/* TODO: Make typeahead */}
                  {/* <Select
                      id={inputs.icon.toString()}
                      onChange={e => this._handleAdminAppRouteChange(e)}
                      name={inputs.icon.toString()}
                      options={icons.map(icon => {
                        return (
                          <option key={`icon-${icon.name}`} value={icon.name}>
                            {icon.name}
                          </option>
                        );
                      })}
                      placeholder={`Select an icon...`}
                      required={true}
                      showPlaceholder={true}
                      value={adminAppRoute.icon}
                    /> */}
                  <HelpText>
                    Icon from{" "}
                    <a href="https://developer.microsoft.com/en-us/fabric#/styles/icons#icons" target="_blank" rel="noopener noreferrer">
                      Office Fabric
                    </a>
                  </HelpText>
                </Field>
              </Column>
            </Row>

            <Row>
              <Column breakpoint="Sm" columns={4}>
                <Field>
                  <Label htmlFor={inputs.href.toString()} required={true} text="Href" />
                  <Input id={inputs.href.toString()} onChange={e => this._handleAdminAppRouteChange(e)} name={inputs.href.toString()} required={true} value={adminAppRoute.href} />
                </Field>
              </Column>

              <Column breakpoint="Sm" columns={4}>
                <Field>
                  <Label htmlFor={inputs.title.toString()} text="Title" />
                  <Input
                    id={inputs.title.toString()}
                    onChange={e => this._handleAdminAppRouteChange(e)}
                    name={inputs.title.toString()}
                    value={adminAppRoute.title ? adminAppRoute.title : ""}
                  />
                  <HelpText text="Tooltip text" />
                </Field>
              </Column>

              <Column breakpoint="Sm" columns={4}>
                <Field>
                  <Label htmlFor={inputs.featureFlag.toString()} text="Feature Flag" />
                  <Input
                    id={inputs.featureFlag.toString()}
                    onChange={e => this._handleAdminAppRouteChange(e)}
                    name={inputs.featureFlag.toString()}
                    value={adminAppRoute.featureFlag ? adminAppRoute.featureFlag : ""}
                  />
                </Field>
              </Column>
            </Row>

            {adminAppRoute.id && (
              <div>
                <Hr />
                <Field>
                  <Label htmlFor="adGroups" text="Join To AD Groups" />
                  <Select
                    id="adGroups"
                    name="adGroups"
                    onChange={e => this._createAdGroupAdminAppRoute(e)}
                    options={adGroups.map(adGroup => {
                      return (
                        <option key={`adGroup-${adGroup.id}`} value={adGroup.id}>
                          {adGroup.name}
                        </option>
                      );
                    })}
                    placeholder={`Select an AD group...`}
                    showPlaceholder={true}
                    value={""}
                  />
                </Field>

                {adGroupAdminAppRoutesLoading && <Loader loading={true} showImage={true} type="Inline" />}

                <ul className="list">
                  {adGroupAdminAppRoutes.map((adGroupAdminAppRoute, index) => {
                    return (
                      <li className="listItem listItemBordered listItemStripedHover" key={`Delete-${index}`}>
                        <div className="listItemInner">
                          <span title={adGroupAdminAppRoute.adGroupAzureGroupId}>{adGroupAdminAppRoute.adGroupName}</span>
                          <Button
                            className="buttonLinkPrimaryColor"
                            iconLeft={<Icon iconName="Trash" />}
                            onClick={e => this._deleteAdGroupAdminAppRoute(adGroupAdminAppRoute.id)}
                            text="Delete"
                            title="Delete"
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>

          <ModalFooter
            cancelDisabled={deleting || saving}
            cancelIcon={"Cancel"}
            cancelText="Close"
            cancelTitle="Close"
            onCancel={e => this._closeAdminAppRoute()}
            saveButtonType="submit"
            saveIcon="Save"
            saveText="Save"
            saveTitle="Save"
            showCancel={false}
            showSave={true}
          />
        </Form>
      );
    }

    return element;
  }

  private _addAdminAppRoute(): void {
    this.setState(
      {
        loading: true
      },
      () => {
        const adminAppRoute: IAdminAppRouteCreate = {
          adminAppRouteTypeId: 2, // secondary
          adminAppRoutePositionId: null,
          featureFlag: null,
          href: "",
          icon: "",
          parentId: null,
          showForAllUsers: false,
          sortOrder: 0,
          text: "",
          title: null
        };

        this.setState({
          adminAppRoute: adminAppRoute as IAdminAppRoute, // id will be null
          loading: false
        });
      }
    );
  }

  private _renderAddAdminAppRoute(): JSX.Element {
    const { adminAppRoute, modalLoading, deleting, saving } = this.state;

    const modalBody: JSX.Element[] = this._renderAdminAppRoute();

    const addOrEdit: string = adminAppRoute && adminAppRoute.id ? "Edit" : "Add A";

    return (
      <Modal
        body={modalBody}
        bodyDeleting={deleting}
        bodyLoading={modalLoading || saving || deleting}
        bodyLoadingShowImage={true}
        bodyLoadingPosition="Top"
        bodyPadding={false}
        bodySaving={saving}
        cancelDisabled={deleting || saving}
        cancelIcon={"Cancel"}
        cancelText="Close"
        cancelTitle="Close"
        className="addEditRoute"
        heading={`${addOrEdit} Route`}
        id="addRoute"
        modalSize="Md"
        onCancel={e => this._closeAdminAppRoute()}
        onOpen={e => this._addAdminAppRoute()}
        openClassName="buttonInfo"
        openIcon="CircleAddition"
        openText="Add A Route"
        openTitle="Add A Route"
        showCancel={false}
        showSave={false}
        showFooter={false}
      />
    );
  }

  private _closeAdminAppRoute(): void {
    this.setState({
      adminAppRoute: null,
      modalLoading: false
    });
  }

  private _handleAdminAppRouteChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { id, value } = e.target;

    const adminAppRoute = this.state.adminAppRoute;

    if (adminAppRoute) {
      if (id === inputs.sortOrder.toString()) {
        adminAppRoute[id] = value ? Number(value) : 0;
      } else if (id === inputs.parentId.toString() || id === inputs.adminAppRouteTypeId.toString() || id === inputs.adminAppRouteTypeId.toString()) {
        adminAppRoute[id] = value ? Number(value) : null;
      } else if (id === inputs.showForAllUsers.toString()) {
        adminAppRoute[id] = value === "true" ? true : false;
      } else {
        adminAppRoute[id] = value;
      }
    }

    this.setState({
      adminAppRoute
    });
  }
}

export default Routes;
