export interface ITimezone {
  label: string;
  timezone: string;
}

export interface ITimezoneOption extends ITimezone {
  value: string;
}

export const DaylightSavingsTimezoneOptions: ITimezoneOption[] = [
  {
    label: "Eastern Timezone",
    value: "-04:00",
    timezone: "America/New_York"
  },
  {
    label: "Central Timezone",
    value: "-05:00",
    timezone: "America/Chicago"
  },
  {
    label: "Mountain Timezone",
    value: "-06:00",
    timezone: "America/Denver"
  },
  {
    label: "Pacific Timezone",
    value: "-07:00",
    timezone: "America/Los_Angeles"
  }
];

export const StandardTimezoneOptions: ITimezoneOption[] = [
  {
    label: "Eastern Timezone",
    value: "-05:00",
    timezone: "America/New_York"
  },
  {
    label: "Central Timezone",
    value: "-06:00",
    timezone: "America/Chicago"
  },
  {
    label: "Mountain Timezone",
    value: "-07:00",
    timezone: "America/Denver"
  },
  {
    label: "Pacific Timezone",
    value: "-08:00",
    timezone: "America/Los_Angeles"
  }
];
