import * as React from "react";
import Band from "../../../components/layout/Band";
import H1 from "../../../components/typography/H1";
import H2 from "../../../components/typography/H2";
import Section from "../../../components/layout/Section";
import LoaderWrapper from "../../../components/loader/LoaderWrapper";
import Loader from "../../../components/loader/Loader";
import Permissions from "../../permissions/Permissions";
import Hr from "../../../components/typography/Hr";

class LoaderStyleGuide extends React.Component {
  public render() {
    const title: string = "Design System: Loader";
    return (
      <div className="loader">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <H2>Inline Loader</H2>
            <LoaderWrapper>
              <Loader loading={true} type="Inline" showImage={true} />
            </LoaderWrapper>
            <Hr />
            <H2>Overlay Loader</H2>
            <LoaderWrapper>
              <Loader loading={true} type="Overlay" showImage={true} centered={true} position="Top" />
            </LoaderWrapper>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </Permissions>
        </Section>
      </div>
    );
  }
}

export default LoaderStyleGuide;
