import axios from "axios";
import { getToken } from "../authentication/authenticationConfig";

const MAX_RETRIES = 3;

type Methods = "post" | "get" | "put" | "delete";

const request = async (method: Methods, url: string, data?: object | null, resource?: string) => {
  const options = await getOptions(resource);

  for (let i: number = 0; i <= MAX_RETRIES; i++) {
    try {
      const result = await axios({
        method,
        url,
        data: data && data,
        ...options
      });
      return result.data;
    } catch (error) {
      // bail here if the request returns a 403, unauthorized, instead of trying to retry
      if (error && error.message.includes("403")) {
        // we resolve it so we can continue on show the not authorized component
        return Promise.resolve(error);
      }
      try {
        await retryRequest(i, error);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
};

export async function postRequest(url: string, data: object, resource?: string): Promise<any> {
  return await request("post", url, data, resource);
}

export async function postFiles(url: string, data: object, resource?: string): Promise<any> {
  const options = await getFilesOptions(resource);
  for (let i = 0; i <= MAX_RETRIES; i++) {
    try {
      const result = await axios.post(url, data, options);
      return result.data;
    } catch (error) {
      try {
        // bail here if the request returns a 403, unauthorized, instead of trying to retry
        if (error && error.message.includes("403")) {
          // we resolve it so we can continue on show the not authorized component
          return Promise.resolve(error);
        }
        await retryRequest(i, error);
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
}

export async function putRequest(url: string, data: object, resource?: string): Promise<any> {
  return await request("put", url, data, resource);
}

export async function deleteRequest(url: string, resource?: string): Promise<any> {
  return await request("delete", url, null, resource);
}

export async function getRequest(url: string, resource?: string): Promise<any> {
  return await request("get", url, null, resource);
}

function wait(timeout: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

async function retryRequest(i: number, error: any) {
  const timeout = Math.pow(2, i);
  console.log("Waiting", timeout, "ms");

  await wait(timeout);

  if (i !== MAX_RETRIES - 1) {
    console.log("Retrying", error.message, i);
    return Promise.resolve(error);
  }
  return Promise.reject(error);
}

async function getOptions(resource?: string) {
  let authToken = await getToken();
  let options = {
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json; charset=utf-8",
      Pragma: "no-cache" // for IE11
    }
  };

  return options;
}

async function getFilesOptions(resource?: string) {
  const options = await getOptions(resource);
  return {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "multipart/form-data"
    }
  };
}
