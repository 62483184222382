import * as React from "react";
// components
import Page from "../../components/page/Page";
import RequestTypeTable from "../../components/shared-services/RequestTypeTable";
// logging
import { withPageLog } from "../logging/LogComponentChange";

export interface IRequestTypeManagementProps {}

const RequestTypeManagement = ({  }: IRequestTypeManagementProps) => {
  return (
    <Page className="requestTypesTable" permissions={true} title="Request Type Management">
      <RequestTypeTable />
    </Page>
  );
};

export default withPageLog(RequestTypeManagement);
