import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";
import { NavLink } from "react-router-dom";

export interface ISubNavLinkProps {
  exact: boolean;
  icon: string;
  onClick?: any;
  text: string;
  title?: string;
  to: string;
}

class SubNavLink extends React.Component<ISubNavLinkProps> {
  public render() {
    const { exact, icon, text, title, to } = this.props;

    return (
      <NavLink activeClassName="subNavLinkActive" className="subNavLink" exact={exact} onClick={e => this._navLinkClick()} title={title} to={to}>
        <span className="subNavIcon">
          <Icon iconName={icon} />
        </span>
        <span className="subNavText">{text}</span>
      </NavLink>
    );
  }

  private _navLinkClick(): void {
    const { onClick, to } = this.props;

    if (onClick) {
      onClick();
    }

    const current: string = window.location.hash.replace("#", "");

    if (current === to) {
      window.location.reload();
    }
  }
}

export default SubNavLink;
