import { getRequest, postRequest, deleteRequest } from "../core/api/requestConfig";
import { IAdGroup, IAdGroupsResponse, IAdGroupResponse, IAdGroupsV2Response } from "../types/AdGroup";
import { IMsAdUsersResponse, IMsAdUser } from "./AdUsers";

export function getAdGroups(adGroupNames: string[], bottlerId: number): Promise<IAdGroup[]> {
  return new Promise<IAdGroup[]>((resolve, reject) => {
    let names: string = "";
    adGroupNames.map(name => {
      names += `&adGroupNames=${name}`;
    });

    getRequest(`/api/v1/getAdGroups?bottlerId=${bottlerId.toString()}${names}`)
      .then((adGroups: IAdGroup[]) => {
        return resolve(adGroups);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function getAdGroupsV2(adGroupNames: string[], bottlerId: number): Promise<IAdGroupsV2Response> {
  return new Promise<IAdGroupsV2Response>((resolve, reject) => {
    let names: string = "";
    adGroupNames.map(name => {
      names += `&name=${name}`;
    });

    getRequest(`/api/v2/adGroups?bottlerId=${bottlerId.toString()}${names}`)
      .then((response: IAdGroupsV2Response) => {
        return resolve(response);
      })
      .catch(error => {
        return reject(error);
      });
  });
}

export function getAdGroupsByName(name: string): Promise<IAdGroupsResponse> {
  return new Promise<IAdGroupsResponse>((resolve, reject) => {
    getRequest(`/api/v1/getAdGroupsByName?name=${name}`)
      .then((response: IAdGroupsResponse) => {
        return resolve(response);
      })
      .catch((error: IAdGroupsResponse) => {
        return reject(error);
      });
  });
}

export function getAdGroupsFromSql(): Promise<IAdGroupsResponse> {
  return new Promise<IAdGroupsResponse>((resolve, reject) => {
    getRequest(`/api/v1/adGroups`)
      .then((response: IAdGroupsResponse) => {
        return resolve(response);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function getAdGroup(groupId: string): Promise<IAdGroup | null> {
  return new Promise<IAdGroup | null>((resolve, reject) => {
    getRequest(`/api/v1/adGroups/${groupId}`)
      .then((response: IAdGroupResponse) => {
        return resolve(response.adGroup);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function getAdGroupMembers(groupId: string): Promise<IMsAdUser[]> {
  return new Promise<IMsAdUser[]>((resolve, reject) => {
    getRequest(`/api/v1/adGroups/${groupId}/members`)
      .then((response: IMsAdUsersResponse) => {
        return resolve(response.adUsers);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function removeUserFromAdGroup(azureGroupId: string, azureUserId: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    deleteRequest(`/api/v1/deleteUserFromAdGroup?azureGroupId=${azureGroupId}&azureUserId=${azureUserId}`)
      .then((result: boolean) => {
        if (result) {
          return resolve(result);
        } else {
          return reject(result);
        }
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function addUserToGroup(azureGroupId: string, azureUserId: string, name: string, email: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    postRequest(`/api/v1/postUserToAdGroup`, {
      azureGroupId,
      azureUserId,
      name,
      email
    })
      .then((result: boolean) => {
        if (result) {
          return resolve(result);
        } else {
          return reject(result);
        }
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}
