import { getRequest } from "../api/requestConfig";

export type Bottler = {
  hrRequestEmail: string | undefined;
  id: number | undefined;
  name: string | undefined;
  sharepointUrl: string | undefined;
  programId: string | undefined;
} | null;

export enum TypeKeys {
  GET_BOTTLER_SUCCESS = "GET_BOTTLER_SUCCESS",
  GET_BOTTLER_FAILURE = "GET_BOTTLER_FAILURE",
  SET_BOTTLER_SUCCESS = "SET_BOTTLER_SUCCESS",
  SET_BOTTLER_FAILURE = "SET_BOTTLER_FAILURE"
}

type Error = string | null;

type getBottlerSuccess = {
  bottler: Bottler;
  type: TypeKeys.GET_BOTTLER_SUCCESS;
};

type getBottlerFailure = {
  error: Error;
  type: TypeKeys.GET_BOTTLER_FAILURE;
};

type setBottlerSuccess = {
  bottler: Bottler;
  type: TypeKeys.SET_BOTTLER_SUCCESS;
};

type setBottlerFailure = {
  error: Error;
  type: TypeKeys.SET_BOTTLER_FAILURE;
};

export const getBottlerSuccessCreator = (
  bottler: Bottler
): getBottlerSuccess => {
  return {
    bottler,
    type: TypeKeys.GET_BOTTLER_SUCCESS
  };
};

export const getBottlerFailureCreator = (error: Error): getBottlerFailure => {
  return {
    error,
    type: TypeKeys.GET_BOTTLER_FAILURE
  };
};

export const setBottlerSuccessCreator = (
  bottler: Bottler
): setBottlerSuccess => {
  return {
    bottler,
    type: TypeKeys.SET_BOTTLER_SUCCESS
  };
};

export const setBottlerFailureCreator = (error: Error): setBottlerFailure => {
  return {
    error,
    type: TypeKeys.SET_BOTTLER_FAILURE
  };
};

export const getBottlerCreator = (bottlerId: number) => {
  return async dispatch => {
    try {
      let bottler: Bottler = await getRequest("/legacy/api/v1/bottler/" + bottlerId);

      dispatch(getBottlerSuccessCreator(bottler));
    } catch (error) {
      console.log(error);
      dispatch(getBottlerFailureCreator(error));
    }
  };
};

export const setBottlerCreator = (bottler: Bottler) => {
  return async dispatch => {
    try {
      dispatch(setBottlerSuccessCreator(bottler));
    } catch (error) {
      console.log(error);
      dispatch(setBottlerFailureCreator(error));
    }
  };
};

export type Actions =
  | getBottlerSuccess
  | getBottlerFailure
  | setBottlerSuccess
  | setBottlerFailure;

export interface IBottlerState {
  bottler: Bottler;
  error: Error;
}

export const initialState = {
  bottler: null,
  error: null
};

export const reducer = (state: IBottlerState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_BOTTLER_SUCCESS:
      newState.bottler = action.bottler;
      newState.error = null;
      break;
    case TypeKeys.GET_BOTTLER_FAILURE:
      newState.error = action.error as string;
      newState.bottler = null;
      break;
    case TypeKeys.SET_BOTTLER_SUCCESS:
      newState.bottler = action.bottler;
      newState.error = null;
      break;
    case TypeKeys.SET_BOTTLER_FAILURE:
      newState.error = action.error as string;
      newState.bottler = null;
      break;
    default:
      break;
  }
  return newState;
};

export const mapStateToProps = (state: any) => {
  return {
    bottler: state.bottler.bottler,
    error: state.bottler.error
  };
};
