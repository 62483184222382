import * as React from "react";
import { toast } from "react-toastify";
// components
import Modal from "../modal/Modal";
import Checkbox from "../input/Checkbox";
import Input from "../input/Input";
import Label from "../label/Label";
import LoaderWrapper from "../loader/LoaderWrapper";
import Loader from "../loader/Loader";
import Textarea from "../input/Textarea";
import ManagerGroupUsers from "../../containers/group-membership/ManagerGroupUsers";
import Field from "../forms/Field";
import Validation from "../validation/Validation";
// types
import { IRequestTypeV2 } from "../../types/RequestType";
import { IAdGroup } from "../../types/AdGroup";
import { IMsAdUser } from "../../services/AdUsers";
// services
import { getAdGroup, getAdGroupMembers } from "../../services/AdGroup";

export interface IRequestTypeModalProps {
  heading: string;
  requestType: IRequestTypeV2;
  handleSave: Function;
}

export interface IRequestTypeModalState {
  adGroup: IAdGroup | null;
  adUsers: IMsAdUser[];
  adGroupAzureObjectId: string;
  adGroupId: number;
  isFetchingAdUsers: boolean;
  id: number;
  label: string;
  description: string | null;
  isSubmittable: boolean;
  isActive: boolean;
  sla: number | null;
  groupMembers?: number;
  isFetchingAdGroup: boolean;
}
class RequestTypeModal extends React.PureComponent<IRequestTypeModalProps, IRequestTypeModalState> {
  state = {
    ...this.props.requestType,
    adGroup: null,
    isFetchingAdGroup: false,
    adUsers: [],
    isFetchingAdUsers: false
  };

  render() {
    const { heading, requestType, handleSave } = this.props;
    const { label, description, sla, isActive, isSubmittable, adGroup, adUsers, isFetchingAdUsers, isFetchingAdGroup } = this.state;

    return (
      <Modal
        body={[
          <>
            <Field>
              <Label htmlFor="name" text="Name" required={true} />
              <Input name="name" id="name" value={label} onChange={e => this.handleChange("label", e.target.value)} required={true} />
              <Validation showValidation={label.length === 0} text="Name is required." type="Error" />
            </Field>
            <Field>
              <Label htmlFor="description" text="Description" />
              <Textarea
                id="description"
                maxLength={2000}
                name="description"
                onChange={e => this.handleChange("description", e.target.value)}
                placeholder="Type up to 2000 characters..."
                showCharacterCount={true}
                value={description ? description : ""}
              />
            </Field>
            <Field className="flex requestTypeModalSpacing">
              <div>
                <Label htmlFor="sla" text="SLA" />
                <Input name="sla" id="sla" value={(sla && `${sla}`) || ""} onChange={e => this.handleChange("sla", e.target.value)} />
              </div>
              <div>
                <Label htmlFor="status" text="Status" />
                <Checkbox text="Active" name="status" id="status" checked={isActive} onChange={e => this.handleChange("isActive", !isActive)} />
              </div>
              <div>
                <Label htmlFor="Submittable" text="Submittable" />
                <Checkbox text="Yes" name="Submittable" id="Submittable" checked={isSubmittable} onChange={e => this.handleChange("isSubmittable", !isSubmittable)} />
              </div>
            </Field>
            <Field>
              <LoaderWrapper>
                <ManagerGroupUsers
                  usersHeading="Manage BSNA Associates"
                  getAdGroup={this.getAdGroup}
                  adGroup={adGroup}
                  adUsers={adUsers}
                  bottlerDomains={["coke-bsna.com", "ccbss.com"]}
                  isFetchingAdUsers={isFetchingAdUsers}
                  isLoading={isFetchingAdGroup}
                />
                <Loader loading={isFetchingAdGroup} position="Centered" showImage={true} type="Inline" />
              </LoaderWrapper>
            </Field>
          </>
        ]}
        cancelIcon="Cancel"
        cancelText="Cancel"
        cancelTitle="Cancel"
        className="addUsersModal"
        disabled={false}
        saveDisabled={label.length === 0}
        heading={heading}
        id="requestTypeModal"
        modalSize="Lg"
        onCancel={e =>
          this.setState({
            label: requestType.label,
            description: requestType.description,
            sla: requestType.sla ? requestType.sla : null,
            isActive: requestType.isActive
          })
        }
        onOpen={() => this.getAdGroup(requestType.adGroupAzureObjectId)}
        onSave={() =>
          handleSave({
            ...requestType,
            ...this.state
          })
        }
        openClassName="buttonLinkPrimaryColor"
        openIcon="Edit"
        openText="Edit"
        openTitle={heading}
        saveText="Save Request Type"
        saveTitle="Save Request Type"
        showCancel={true}
        showSave={true}
        showFooter={true}
      />
    );
  }

  handleChange = (type: string, value: any) => {
    this.setState({ [type]: value } as Pick<IRequestTypeModalState, keyof IRequestTypeModalState>);
  };

  getAdGroup = async (adGroupId: string, saved: boolean = false) => {
    this.setState(state => ({
      isFetchingAdGroup: true,
      adGroup: saved ? state.adGroup : null
    }));

    try {
      const adGroupRes: IAdGroup | null = await getAdGroup(adGroupId);

      this.setState({
        adGroup: adGroupRes,
        isFetchingAdGroup: false,
        isFetchingAdUsers: true
      });

      if (adGroupRes) {
        const adUsersRes: IMsAdUser[] = await getAdGroupMembers(adGroupRes.azureGroupId);

        this.setState({
          adUsers: adUsersRes,
          isFetchingAdUsers: false
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.setState({
        isFetchingAdGroup: false,
        isFetchingAdUsers: false
      });
    }
  };
}

export default RequestTypeModal;
