import * as React from "react";
// components
import RequestDetail from "./RequestDetail";
// logging
import { withPageLog } from "../../logging/LogComponentChange";

export interface IGeneralRequestProps {}

export interface IGeneralRequestState {}

class GeneralRequest extends React.Component<
  IGeneralRequestProps,
  IGeneralRequestState
> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="generalRequest">
        <RequestDetail />
      </div>
    );
  }
}

export default withPageLog(GeneralRequest);
