import { postRequest } from "../core/api/requestConfig";
// types
import { IRequestAuditsResponse, IRequestAuditsRequest } from "../types/RequestAudits";

export const getRequestAuditsById = (requestId: number, searchTerm: string): Promise<IRequestAuditsResponse> =>
  postRequest(`/api/v1/requestAudits/filter`, {
    requestId: [requestId],
    search: searchTerm,
    pageSize: -1
  })
    .then((res: IRequestAuditsResponse) => res)
    .catch((error: IRequestAuditsResponse) => {
      console.log(error);
      return error;
    });

export const getRequestAudits = ({
  bottlerId = [],
  changedFrom = "",
  changedTo = "",
  createdAtEnd = "",
  createdAtStart = "",
  orderBy = "",
  pageNumber = 1,
  pageSize = 10,
  requestAuditTypeId = [],
  requestProcessId = [],
  requestTypeId = [],
  sortOrder = "",
  search = "",
  towerId = [],
  userId = []
}: IRequestAuditsRequest): Promise<IRequestAuditsResponse> =>
  postRequest("/api/v1/requestAudits/filter", {
    bottlerId,
    changedFrom,
    changedTo,
    createdAtEnd,
    createdAtStart,
    orderBy,
    pageNumber,
    pageSize,
    requestAuditTypeId,
    requestProcessId,
    requestTypeId,
    search,
    sortOrder,
    towerId,
    userId
  })
    .then(res => res)
    .catch((error: IRequestAuditsResponse) => {
      console.error(error);
      return error;
    });
