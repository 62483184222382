import * as React from "react";
import { connect } from "react-redux";
import ldRedux from "ld-redux";
import LoaderFullscreen from "../../../components/loader/LoaderFullscreen";

export interface IState {
  user: any;
}

interface WrappedProps extends IState {}

interface ILDUser {
  key: string;
  email: string;
  name: string;
}

export interface IFeatureFlags {
  editAdGroupAdminAppRoute: boolean;
  hrPayrollRequests: boolean;
  "request-post-pilot": boolean;
  "self-service": boolean;
  wifiNetwork: boolean;
}

const initialFeatureFlags: IFeatureFlags = {
  editAdGroupAdminAppRoute: false,
  hrPayrollRequests: false,
  "request-post-pilot": false,
  "self-service": false,
  wifiNetwork: false
};

export class FeatureFlags extends React.Component<WrappedProps, IState> {
  public props: any;

  public componentWillMount() {
    const { launchDarklyKey } = this.props.config;
    const { name, username } = this.props.user;
    if (launchDarklyKey && name && username) {
      const user: ILDUser = {
        key: username,
        email: username,
        name: name
      };

      ldRedux.init({
        clientSideId: launchDarklyKey,
        dispatch: this.props.dispatch,
        initialFeatureFlags,
        user
      });
    }
  }
  public render() {
    if (this.props.isLoading) {
      return <LoaderFullscreen />;
    }

    const { launchDarklyKey } = this.props.config;

    const component = launchDarklyKey ? React.cloneElement(this.props.children) : <div>{`error loading feature flags`}</div>;

    return component;
  }
}

export const mapStateToProps = (state: any) => {
  return {
    user: state.user.user
  };
};

export const FeatureFlagProvider = connect(mapStateToProps)(FeatureFlags);
