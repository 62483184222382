import * as React from "react";

export interface IPrimaryNavLoadingProps {}
export interface IPrimaryNavLoadingState {}

class PrimaryNavLoading extends React.Component<IPrimaryNavLoadingProps, IPrimaryNavLoadingState> {
  public render(): React.ReactElement<IPrimaryNavLoadingProps> {
    return (
      <div className="navLinkLoading">
        <div className="navLinkIconLoading" />
        <div className="navLinkTextLoading" />
        <div className="navLinkTextLoading" />
      </div>
    );
  }
}

export default PrimaryNavLoading;
