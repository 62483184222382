import * as React from "react";
import Page from "../../components/page/Page";
import Checkbox from "../../components/input/Checkbox";
import P from "../../components/typography/P";
import Hr from "../../components/typography/Hr";

export interface ICachingProps {}

export interface ICachingState {
  cacheUser: boolean;
  showMessage: boolean;
}

class Caching extends React.Component<ICachingProps, ICachingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      cacheUser: true,
      showMessage: false
    };

    this._handleCache = this._handleCache.bind(this);
    this._handleRefresh = this._handleRefresh.bind(this);
  }

  public componentDidMount(): void {
    const cacheUser: string | null = this._getCache("cacheUser");

    this.setState({
      cacheUser: cacheUser === "false" ? false : true
    });
  }

  public render(): React.ReactElement<ICachingProps> {
    const { cacheUser, showMessage } = this.state;
    return (
      <Page className="caching" permissions={true} title="Settings: Caching">
        <Checkbox checked={cacheUser} id="cacheUser" name="cacheUser" onChange={e => this._handleCache(e)} showLabel={true} text="Cache User" />
        {showMessage && (
          <div>
            <Hr />
            <P italic={true}>
              In order for cache changes to take effect, you must{" "}
              <a href="#" onClick={e => this._handleRefresh(e)}>
                refresh your browser
              </a>
              .
            </P>
          </div>
        )}
      </Page>
    );
  }

  private _handleCache(e: React.ChangeEvent<HTMLInputElement>): void {
    const checked: boolean = e.target.checked;
    const id: string = e.target.id;

    this.setState(
      {
        cacheUser: id === "cacheUser" ? checked : this.state.cacheUser,
        showMessage: true
      },
      () => {
        this._setCache(id, checked);
      }
    );
  }

  private _getCache(id: string): string | null {
    return localStorage.getItem(id);
  }

  private _setCache(id: string, checked: boolean): void {
    localStorage.setItem(id, checked ? "true" : "false");
  }

  //   private _removeCache(id: string): void {
  //     localStorage.removeItem(id);
  //   }

  private _handleRefresh(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();

    window.location.reload();
  }
}

export default Caching;
