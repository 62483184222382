import * as React from "react";
// components
import Checkbox from "../input/Checkbox";
import Label from "../label/Label";
import Filter from "./Filter";

interface IFilterCheckboxesProps {
  filterText: string;
  isDisabled?: boolean;
  isFiltering?: boolean;
  isAllChecked?: boolean;
  handleFilterChange: Function;
  label?: string;
  options: any[];
  selectedOptions: number[];
}

const FilterCheckboxes = ({
  isDisabled = false,
  isFiltering = false,
  isAllChecked = true,
  handleFilterChange,
  label,
  options,
  filterText,
  selectedOptions
}: IFilterCheckboxesProps) => {
  return (
    <>
      {label && <Label text={label} />}
      <Filter
        filterDisabled={isDisabled}
        filterText={filterText}
        filterPosition="filterLeft"
        filtered={isFiltering}
      >
        <>
          <Checkbox
            key={`${filterText}-all`}
            checked={isAllChecked}
            className="checkboxHr"
            id={`${filterText}-all`}
            name={`${filterText}-all`}
            onChange={e => {
              if (!isAllChecked) {
                return handleFilterChange(options.map(option => option.id));
              }
            }}
            text="Toggle All"
          />
          {options.map((option, index: number) => {
            const isChecked: boolean = selectedOptions.includes(option.id);
            return (
              <Checkbox
                key={`${filterText}-${index}`}
                checked={isChecked}
                id={`${filterText}-${index}`}
                name={`${filterText}-${index}`}
                onChange={() =>
                  isChecked
                    ? handleFilterChange(
                        selectedOptions.filter(so => so !== option.id)
                      )
                    : handleFilterChange([...selectedOptions, option.id])
                }
                text={option.name || option.label}
              />
            );
          })}
        </>
      </Filter>
    </>
  );
};

export default FilterCheckboxes;
