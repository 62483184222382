import * as React from "react";

export interface ILiProps {
  className?: string;
}

export interface ILiState {}

export default class Li extends React.Component<ILiProps, ILiState> {
  public render(): React.ReactElement<ILiProps> {
    const { className } = this.props;

    const cssClass: string = className ? "li " + className : "li";

    return <li className={cssClass}>{this.props.children}</li>;
  }
}
