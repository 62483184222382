import { getRequest } from "../api/requestConfig";

export type Config = {
  adminUrl: string | undefined;
  appInsightsKey: string | undefined;
  launchDarklyKey: string | undefined;
} | null;

enum TypeKeys {
  GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS",
  GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE"
}

type Error = string | null;

type GetConfigSuccess = {
  config: Config;
  type: TypeKeys.GET_CONFIG_SUCCESS;
};

type GetConfigFailure = {
  error: Error;
  type: TypeKeys.GET_CONFIG_FAILURE;
};

/**
 *
 * @param config configuration values
 */
export const GetConfigSuccessCreator = (config: Config): GetConfigSuccess => {
  return {
    config,
    type: TypeKeys.GET_CONFIG_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const GetConfigFailureCreator = (error: Error): GetConfigFailure => {
  return {
    error,
    type: TypeKeys.GET_CONFIG_FAILURE
  };
};

/**
 *
 * @param apiUrl relative url for api endpoint
 */
export const GetConfigCreator = (apiUrl: string) => {
  return async dispatch => {
    try {
      const response = await getRequest(apiUrl);
      dispatch(
        GetConfigSuccessCreator({
          adminUrl: response.adminUrl,
          appInsightsKey: response.appInsightsKey,
          launchDarklyKey: response.launchDarklyKey
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(GetConfigFailureCreator(error));
    }
  };
};

export type Actions = GetConfigSuccess | GetConfigFailure;

export interface IState {
  isLoading: boolean;
  config: Config;
  error: Error;
}

export const initialState = {
  isLoading: true,
  config: null,
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_CONFIG_SUCCESS:
      newState.config = action.config;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_CONFIG_FAILURE:
      newState.error = action.error as string;
      newState.config = null;
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};

export const mapStateToProps = (state: any) => {
  return {
    config: state.config.config,
    error: state.config.error,
    isLoading: state.config.isLoading
  };
};
