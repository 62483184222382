import * as React from "react";

export interface ILoaderImageProps {}
export interface ILoaderImageState {}

export default class LoaderImage extends React.Component<ILoaderImageProps, ILoaderImageState> {
  public render(): React.ReactElement<ILoaderImageProps> {
    return (
      <div className="loaderImageWrapper">
        <div className="loaderImage">
          <div className="loaderCircle" />
          <div className="loaderCircle" />
          <div className="loaderCircle" />
        </div>
      </div>
    );
  }
}
