import * as React from "react";
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
import { getRequest } from "../../core/api/requestConfig";
import Alert from "../../components/alert/Alert";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";

export interface ISwaggerProps {
  version: string;
}

export interface ISwaggerState {
  error: Error | null;
  loading: boolean;
  rendered: boolean;
}

class Swagger extends React.Component<ISwaggerProps, ISwaggerState> {
  public constructor(props: ISwaggerProps) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      rendered: false
    };
  }

  public componentDidMount(): void {
    const { rendered } = this.state;

    if (!rendered) {
      this._loadSwagger();
    }
  }

  public componentDidUpdate(prevProps: ISwaggerProps): void {
    const { version } = this.props;

    if (version !== prevProps.version) {
      this._loadSwagger();
    }
  }

  public render(): React.ReactElement<ISwaggerProps> {
    const { version } = this.props;
    const { error, loading } = this.state;

    return (
      <LoaderWrapper>
        {!error && loading && <Loader loading={loading} position="Top" showImage={true} text={`Loading Swagger ${version} docs...`} />}
        {error && !loading && <Alert show={true} text={error.message} type="Error" />}
        {/* this div has to be rendered at all times in order for SwaggerUI to work */}
        <div className="swagger" id="swagger" />
      </LoaderWrapper>
    );
  }

  private async _loadSwagger(): Promise<void> {
    const { version } = this.props;

    try {
      this.setState({ error: null, loading: true, rendered: false });

      const spec = await getRequest(`/${version}/api-doc.json`);

      SwaggerUI({
        dom_id: "#swagger",
        spec
      });

      this.setState({ rendered: true });
    } catch (error) {
      console.error("_loadSwagger", error);
      this.setState({ error: { message: `Error loading Swagger ${version} docs.`, name: "" } });
    } finally {
      this.setState({ loading: false });
    }
  }
}

export default Swagger;
