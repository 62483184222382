import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { connect } from "react-redux";
// arrays
import { auditColumns } from "./Columns";
import { sorting } from "./Sorting";
// components
import Alert from "../../components/alert/Alert";
import BottlerSwitcher from "../../components/switcher/BottlerSwitcher";
import DateRangePicker from "../../components/input/DateRangePicker";
import Label from "../../components/label/Label";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Page from "../../components/page/Page";
import Pagination from "../../components/pagination/Pagination";
import RefreshGroup from "../../components/refresh/Group";
import Search from "../../components/search/Search";
import Select from "../../components/input/Select";
import Sort from "../../components/table/Sort";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import TBody from "../../components/table/TBody";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import THead from "../../components/table/THead";
import Tr from "../../components/table/Tr";
// fetch
import { getAdGroupUserAudits } from "../../services/AdGroupUserAudit";
// helpers
import { sanitizeCsvString } from "../../helpers/csv";
import { shortFileDateTime, shortDate, fullTime } from "../../helpers/time";
import zipcelx, { Config, Sheet, Row, Data, Cell } from "../../helpers/zipcelx";
// types
import { DaylightSavingsTimezoneOptions, StandardTimezoneOptions } from "../../types/Timezone";
import { IBottler } from "../../types/Bottler";
import { IRequestError } from "../../types/RequestError";
import { IUser } from "../../types/User";
import { IAdGroupUserAudit } from "../../types/AdGroupUserAudits";
// logging
import { withPageLog } from "../logging/LogComponentChange";

export interface IAuditLogReportProps {
  bottlers: IBottler[];
  bottler: IBottler | null;
  user: IUser;
}

export interface IAuditLogReportState {
  count: number;
  createdAtEnd: string;
  createdAtStart: string;
  export: IRequestError[];
  exporting: boolean;
  loading: boolean;
  pageSize: number;
  pageNumber: number;
  audits: any[];
  search: string;
  searching: boolean;
  orderBy: string;
  sortOrder: string;
  actionValue: number;
}

class AuditLogReport extends Component<IAuditLogReportProps, IAuditLogReportState> {
  timer;
  DEFAULT_PAGE_SIZE: number = 10;
  DEFAULT_PAGE: number = 1;
  DEFAULT_SORT_ORDER: string = "DESC";
  DEFAULT_SORT_BY: string = "createdAt";
  DEFAULT_ACTION = 0;

  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      createdAtEnd: this.getDefaultCreatedAtEnd(),
      createdAtStart: this.getDefaultCreatedAtStart(),
      export: [],
      exporting: false,
      loading: true,
      pageSize: this.DEFAULT_PAGE_SIZE,
      pageNumber: this.DEFAULT_PAGE,
      audits: [],
      search: "",
      searching: false,
      orderBy: this.DEFAULT_SORT_BY,
      sortOrder: this.DEFAULT_SORT_ORDER,
      actionValue: this.DEFAULT_ACTION
    };
  }

  componentDidMount(): void {
    this.getAuditLogs();
  }

  componentDidUpdate(prevProps: IAuditLogReportProps): void {
    if (prevProps.bottler !== this.props.bottler) {
      this.resetState();
    }
  }

  render() {
    const { count, createdAtEnd, createdAtStart, exporting, loading, pageNumber, search, sortOrder, orderBy } = this.state;

    const mobileSorterOptions: JSX.Element[] = this.renderMobileSorter();
    const headerColumns: JSX.Element[] = this.renderHeaderColumns();
    const rows: JSX.Element[] = this.renderRows();
    const exportText: string = this.renderExportText();

    const title: string = "Audit Log Report";

    return (
      <Page className="groupUserAudits" permissions={true} title={title}>
        <BottlerSwitcher showAllOption={true} />
        <div>
          <RefreshGroup refreshId="audit-log-report" handleRefresh={this.getAuditLogs} isLoading={loading} />
          <div className="dateRangePickerWithSearchAuditWrapper">
            <div className="dateRangePickerWithSearchAudit">
              <DateRangePicker
                endDate={createdAtEnd}
                endDateChange={(date: string) => {
                  this.handleDateRange(date, "end");
                }}
                endDateId="endDate"
                endDateMax={moment()
                  .endOf("day")
                  .toISOString()}
                endDateMin={createdAtStart}
                endDatePlaceholder="End date..."
                startDate={createdAtStart}
                startDateChange={(date: string) => {
                  this.handleDateRange(date, "start");
                }}
                startDateId="startDate"
                startDateMax={createdAtEnd}
                startDatePlaceholder="Start date..."
              />
              <Search
                autoFocus={true}
                exportDisabled={count === 0 || exporting || loading}
                exportText={exportText}
                exportTitle={exportText}
                onExportButtonClick={this.exportToExcel}
                onSearchChange={this.handleSearchInput}
                onSearchKeyUp={this.handleSearchEnter}
                onSearchClick={this.search}
                placeholder="Search by Group or User Name"
                search={search}
                searchButtonText="Search by Group or User Name"
                searchButtonTitle="Search by Group or User Name"
                searchDisabled={exporting}
                searchLabelText="Search by Group or User Name"
                showExport={true}
              />
            </div>
            <div className="dateRangePickerWithSearchAuditAction">
              <Label htmlFor="action-sorter" text="Filter By Action" />
              <Select
                defaultValue="All"
                id="action-sorter"
                name="action-sorter"
                onChange={e => {
                  this.setState(
                    {
                      pageNumber: this.DEFAULT_PAGE,
                      actionValue: Number(e.target.value)
                    },
                    () => {
                      this.getAuditLogs();
                    }
                  );
                }}
                options={[
                  <option value={0} key="All">
                    All
                  </option>,
                  <option value={1} key="Added">
                    User Added
                  </option>,
                  <option value={2} key="Removed">
                    User Removed
                  </option>
                ]}
              />
            </div>
          </div>
          <div className="gridSorter">
            <Label htmlFor={"grid-sorter"} text={"Sort Requests By"} />
            <Select defaultValue={orderBy + " " + sortOrder} id={"grid-sorter"} name={"grid-sorter"} onChange={this.handleMobileSort} options={mobileSorterOptions} />
          </div>
          <LoaderWrapper>
            <TableWrapper>
              <Table>
                <THead>
                  <Tr>{headerColumns}</Tr>
                </THead>
                <TBody>{rows}</TBody>
              </Table>
            </TableWrapper>
            <Loader loading={exporting || loading} position="Top" showImage={true} text={exporting ? "Exporting..." : "Loading..."} type="Overlay" />
          </LoaderWrapper>
          <Pagination count={count} loading={loading} page={pageNumber} perPage={this.DEFAULT_PAGE_SIZE} paginate={this.handlePaginate} />
          <ToastContainer />
        </div>
      </Page>
    );
  }
  getAuditLogs = (): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        const { bottler } = this.props;
        const { createdAtEnd, createdAtStart, search, orderBy, pageNumber, pageSize, sortOrder, actionValue } = this.state;
        try {
          const response = await getAdGroupUserAudits({
            bottlerId: bottler ? bottler.id : undefined,
            createdAtEnd: createdAtEnd ? createdAtEnd : undefined,
            createdAtStart: createdAtStart ? createdAtStart : undefined,
            pageNumber,
            pageSize,
            search: search ? search : undefined,
            orderBy: orderBy ? orderBy : undefined,
            sortOrder: sortOrder ? sortOrder : undefined,
            typeId: actionValue ? actionValue : undefined
          });
          this.setState({
            count: response.adGroupUserAuditsTotalCount || 0,
            loading: false,
            audits: response.adGroupUserAudits
          });
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            count: 0,
            loading: false,
            audits: []
          });
        }
      }
    );
  };

  handleDateRange = (date: string, startOrEnd: "start" | "end"): void => {
    if (date && moment(date).isValid()) {
      if (startOrEnd === "start") {
        this.setState(
          {
            createdAtStart: this.formatCreatedAtStart(date),
            pageNumber: this.DEFAULT_PAGE
          },
          () => {
            this.getAuditLogs();
          }
        );
      }

      if (startOrEnd === "end") {
        this.setState(
          {
            createdAtEnd: this.formatCreatedAtEnd(date),
            pageNumber: this.DEFAULT_PAGE
          },
          () => {
            this.getAuditLogs();
          }
        );
      }
    }
  };

  resetState = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    if (e) {
      e.preventDefault();
    }

    const createdAtEnd = this.getDefaultCreatedAtEnd();
    const createdAtStart = this.getDefaultCreatedAtStart();

    this.setState(
      {
        count: 0,
        createdAtEnd,
        createdAtStart,
        loading: true,
        pageNumber: this.DEFAULT_PAGE,
        searching: false,
        search: "",
        orderBy: this.DEFAULT_SORT_BY,
        sortOrder: this.DEFAULT_SORT_ORDER
      },
      () => {
        this.getAuditLogs();
      }
    );
  };

  handlePaginate = (e: React.MouseEvent<HTMLButtonElement>, page: number): void => {
    e.preventDefault();

    this.setState(
      {
        pageNumber: page
      },
      () => {
        this.getAuditLogs();
      }
    );
  };

  search = (): void => {
    this.setState(
      {
        pageNumber: this.DEFAULT_PAGE
      },
      () => {
        this.getAuditLogs();
      }
    );
  };

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();

    const search: string = e.target.value;

    clearTimeout(this.timer);

    this.setState(
      {
        search
      },
      () => {
        this.timer = setTimeout(() => {
          this.search();
        }, 300);
      }
    );
  };

  handleSearchEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();

    if (e.key === "Enter") {
      const search: string = e.currentTarget.value;

      this.setState(
        {
          search: search ? search : ""
        },
        () => {
          this.search();
        }
      );
    }
  };

  handleSort = (e: React.MouseEvent<HTMLButtonElement>, orderBy: string): void => {
    e.preventDefault();

    const { sortOrder } = this.state;

    this.setState(
      {
        pageNumber: this.DEFAULT_PAGE,
        orderBy: orderBy,
        sortOrder: sortOrder === "DESC" ? "ASC" : "DESC"
      },
      () => {
        this.getAuditLogs();
      }
    );
  };

  handleMobileSort = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();

    const value: string = e.target.value;
    const orderBy: string = value.split(" ")[0];
    const sortOrder: string = value.split(" ")[1];

    this.setState(
      {
        pageNumber: this.DEFAULT_PAGE,
        orderBy: orderBy,
        sortOrder: sortOrder
      },
      () => {
        this.getAuditLogs();
      }
    );
  };

  exportToExcel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    this.setState(
      {
        exporting: true
      },
      async () => {
        const { bottler } = this.props;
        const { createdAtEnd, createdAtStart, search, orderBy, sortOrder } = this.state;

        try {
          const response = await getAdGroupUserAudits({
            bottlerId: bottler ? bottler.id : undefined,
            createdAtEnd: createdAtEnd ? createdAtEnd : undefined,
            createdAtStart: createdAtStart ? createdAtStart : undefined,
            search: search ? search : undefined,
            orderBy: orderBy ? orderBy : undefined,
            sortOrder: sortOrder ? sortOrder : undefined
          });

          const data: Data = [];

          const header: Row = [];

          auditColumns.map(column => {
            header.push({
              value: column.text,
              type: "string"
            });
          });

          data.push(header);

          response.adGroupUserAudits.map((audit: IAdGroupUserAudit) => {
            const cell: Cell[] = [];

            auditColumns.map(column => {
              if (column.id === "createdAt") {
                cell.push({
                  value: shortDate(audit.createdAt),
                  type: column.type
                });
              } else if (column.id === "createdTime") {
                cell.push({
                  value: fullTime(audit.createdAt),
                  type: column.type
                });
              } else if (column.id === "adGroupName") {
                cell.push({
                  value: sanitizeCsvString(audit.adGroupName),
                  type: column.type
                });
              } else if (column.id === "changedByUserName") {
                cell.push({
                  value: sanitizeCsvString(audit.changedByUserName),
                  type: column.type
                });
              } else if (column.id === "changedByUserEmail") {
                cell.push({
                  value: sanitizeCsvString(audit.changedByUserEmail),
                  type: column.type
                });
              } else if (column.id === "changedUserName") {
                cell.push({
                  value: sanitizeCsvString(audit.changedUserName),
                  type: column.type
                });
              } else if (column.id === "changedUserEmail") {
                cell.push({
                  value: sanitizeCsvString(audit.changedUserEmail),
                  type: column.type
                });
              } else {
                cell.push({
                  value: sanitizeCsvString(audit.typeName),
                  type: column.type
                });
              }
            });

            data.push(cell);
          });

          const now: string = moment().toISOString();
          const filename = `AUDIT_LOG_REPORT_${shortFileDateTime(now)}`;

          const sheet: Sheet = {
            data
          };

          const config: Config = {
            filename,
            sheet
          };

          zipcelx(config);

          this.setState({
            exporting: false
          });
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            exporting: false
          });
        }
      }
    );
  };

  renderExportText = (): string => {
    const { count } = this.state;
    return `Export ${count.toString()} Audits`;
  };

  renderRows = (): JSX.Element[] => {
    const { count, audits } = this.state;

    const rows: JSX.Element[] = [];

    if (count > 0) {
      audits.map((audit: IAdGroupUserAudit, index: number) => {
        rows.push(
          <Tr hover={true} key={`${index}`} striped={true}>
            {auditColumns.map((column, auditIndex: number) => {
              return (
                <Td key={`${auditIndex}`} title={column.title}>
                  <p className="label">{column.title}</p>
                  {column.id === "createdAt" && <span>{shortDate(audit.createdAt)}</span>}
                  {column.id === "createdTime" && <span>{fullTime(audit.createdAt)}</span>}
                  {column.id === "bottler" && <span>{audit.bottlerName}</span>}
                  {column.id === "adGroupName" && <span>{audit.adGroupName}</span>}
                  {column.id === "changedByUserName" && <span>{audit.changedByUserName}</span>}
                  {column.id === "changedByUserEmail" && <span>{audit.changedByUserEmail}</span>}
                  {column.id === "changedUserName" && <span>{audit.changedUserName}</span>}
                  {column.id === "changedUserEmail" && <span>{audit.changedUserEmail}</span>}
                  {column.id === "typeName" && <span>{audit.typeName}</span>}
                </Td>
              );
            })}
          </Tr>
        );
      });
    } else {
      rows.push(
        <Tr key="no-audits-alert">
          <Td colSpan={auditColumns.length}>
            <Alert show={true} type="Info">
              There are no audit logs. Switch your Bottler or{" "}
              <a href={"#"} onClick={e => this.resetState(e)}>
                undo your search and filters
              </a>
              .
            </Alert>
          </Td>
        </Tr>
      );
    }

    return rows;
  };

  renderHeaderColumns = (): JSX.Element[] => {
    const headerColumns: JSX.Element[] = [];
    const { count, sortOrder, orderBy } = this.state;

    auditColumns.map((column, index) => {
      const ascending: boolean = column.orderBy === orderBy && sortOrder === "ASC" ? true : false;
      const descending: boolean = column.orderBy === orderBy && sortOrder === "DESC" ? true : false;

      const sort: JSX.Element | null =
        column.sort && count > 1 ? (
          <Sort text={column.title} ascending={ascending} descending={descending} onClick={e => this.handleSort(e, column.orderBy as string)} disabled={false} />
        ) : null;

      headerColumns.push(
        <Th key={index} text={column.text} title={column.title}>
          {sort}
        </Th>
      );
    });

    return headerColumns;
  };

  renderMobileSorter = (): JSX.Element[] => {
    const options: JSX.Element[] = [];

    auditColumns.map((column, columnIndex) => {
      if (column.sortMobile) {
        sorting.map((sort, sortIndex) => {
          options.push(
            <option value={column.orderBy + " " + sort.database} key={`${columnIndex} ${sortIndex}`}>
              {column.text + " " + sort.text}
            </option>
          );
        });
      }
    });

    return options;
  };

  formatCreatedAtEnd = (date: Date | moment.Moment | string): string => {
    const endDate = moment(date).format("YYYY-MM-DD");
    return `${endDate}T23:59:59${moment().isDST() ? DaylightSavingsTimezoneOptions[0].value : StandardTimezoneOptions[0].value}`;
  };

  formatCreatedAtStart = (date: Date | moment.Moment | string): string => {
    const startDate = moment(date).format("YYYY-MM-DD");
    return `${startDate}T00:00:00${moment().isDST() ? DaylightSavingsTimezoneOptions[0].value : StandardTimezoneOptions[0].value}`;
  };

  getDefaultCreatedAtEnd = (): string => {
    const createdAtEnd = moment().toISOString();
    return this.formatCreatedAtEnd(createdAtEnd);
  };

  getDefaultCreatedAtStart = (): string => {
    const createdAtStart = moment()
      .subtract(29, "days")
      .toISOString();
    return this.formatCreatedAtStart(createdAtStart);
  };
}

export const mapStateToProps = (state: any) => {
  return {
    bottlers: state.bottlers.bottlers,
    bottler: state.bottler.bottler,
    user: state.user.user
  };
};

export default connect(mapStateToProps)(withPageLog(AuditLogReport));
