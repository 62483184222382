import * as React from "react";
import Band from "../../../components/layout/Band";
import Section from "../../../components/layout/Section";
import H1 from "../../../components/typography/H1";
import H2 from "../../../components/typography/H2";
import H3 from "../../../components/typography/H3";
import H4 from "../../../components/typography/H4";
import H5 from "../../../components/typography/H5";
import H6 from "../../../components/typography/H6";
import Hr from "../../../components/typography/Hr";
import Li from "../../../components/typography/Li";
import Ol from "../../../components/typography/Ol";
import P from "../../../components/typography/P";
import Ul from "../../../components/typography/Ul";
import Permissions from "../../permissions/Permissions";

class Typography extends React.Component {
  public render() {
    const title: string = "Design System: Typography";

    const ulListItems: JSX.Element[] = [];
    const olListItems: JSX.Element[] = [];
    for (let i: number = 0; i < 3; i++) {
      ulListItems.push(<Li key={i}>Unorder list item</Li>);
      olListItems.push(<Li key={i}>Unorder list item</Li>);
    }
    const ulNestedListItems: JSX.Element[] = [];
    const olNestedListItems: JSX.Element[] = [];
    for (let i: number = 0; i < 3; i++) {
      ulNestedListItems.push(
        <Li key={i}>
          Unorder list item
          <Ul listItems={ulListItems} />
        </Li>
      );
      olNestedListItems.push(
        <Li key={i}>
          Unorder list item
          <Ol listItems={olListItems} />
        </Li>
      );
    }
    return (
      <div className="typography">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <H2>Headings</H2>
            <Hr />
            <H1>Heading 1</H1>
            <H2>Heading 2</H2>
            <H3>Heading 3</H3>
            <H4>Heading 4</H4>
            <H5>Heading 5</H5>
            <H6>Heading 6</H6>
            <Hr />
            <H2>Paragraphs</H2>
            <Hr />
            <P>
              <a href="https://www.worldofcoca-cola.com/about-us/coca-cola-history/" target="_blank">
                Coca-Cola history
              </a>{" "}
              began in 1886 when the curiosity of an Atlanta pharmacist, Dr. John S. Pemberton, led him to create a distinctive tasting soft drink
              that could be sold at soda fountains. He created a flavored syrup, took it to his neighborhood pharmacy, where it was mixed with
              carbonated water and deemed “excellent” by those who sampled it. Dr. Pemberton’s partner and bookkeeper, Frank M. Robinson, is credited
              with naming the beverage “Coca‑Cola” as well as designing the trademarked, distinct script, still used today.
            </P>
            <P>
              The first servings of Coca‑Cola were sold for 5 cents per glass. During the first year, sales averaged a modest nine servings per day in
              Atlanta. Today, daily servings of Coca‑Cola beverages are estimated at 1.9 billion globally.
            </P>
            <Hr />
            <H2>Lists</H2>
            <Hr />
            <H3>Unordered lists</H3>
            <Ul listItems={ulListItems} />
            <Ul listItems={ulNestedListItems} />
            <H3>Ordered lists</H3>
            <Ol listItems={olListItems} />
            <Ol listItems={olNestedListItems} />
          </Permissions>
        </Section>
      </div>
    );
  }
}

export default Typography;
