import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./Home";
import PayrollPeriods from "./PayrollPeriods";
import CurrentRequests from "./Requests";
import GeneralRequest from "./Requests/GeneralRequest";
import HrRequest from "./Requests/HrRequest";
import RequestTypeManagement from "./RequestTypeManagement";
import ArchivedRequests from "./Requests/ArchivedRequests";
import RequestHistory from "./Requests/RequestHistory";

const SharedServices = ({ requestPostPilotFF }: { requestPostPilotFF: boolean }) => {
  return (
    <div className="sharedServices">
      <Switch>
        <Route exact={true} path="/shared-services/home" component={Home} />
        <Route path="/shared-services/payroll-periods" component={PayrollPeriods} />
        <Route exact={true} path="/shared-services/requests" component={CurrentRequests} />
        <Route exact={true} path="/shared-services/requests-archive" component={ArchivedRequests} />
        {requestPostPilotFF && <Route exact={true} path="/shared-services/request-history" component={RequestHistory} />}
        <Route path="/shared-services/requests/request/:requestId" component={GeneralRequest} />
        <Route path="/shared-services/requests/hr-request/:requestId" component={HrRequest} />
        <Route path="/shared-services/request-type-management" component={RequestTypeManagement} />
      </Switch>
    </div>
  );
};
export const mapStateToProps = (state: any) => {
  return {
    requestPostPilotFF: state.featureFlags.requestPostPilot
  };
};

export default connect(mapStateToProps)(SharedServices);
