import { IResponse } from "./Response";

export interface IAdminAppRouteBase {
  adminAppRouteTypeId: number;
  adminAppRoutePositionId?: number | null;
  featureFlag?: string | null;
  href: string;
  icon: string;
  parentId: number | null;
  showForAllUsers: boolean;
  sortOrder: number;
  text: string;
  title?: string | null;
}

export interface IAdminAppRouteCreate extends IAdminAppRouteBase {
  createdAt?: string;
  deletedAt?: string | null;
  updatedAt?: string;
}

export interface IAdminAppRouteUpdate extends IAdminAppRouteCreate {
  id: number;
}

export interface IAdminAppRoute extends IAdminAppRouteUpdate {
  secondaryNavRoutes?: IAdminAppRoute[];
}

export interface IAdminAppRouteResponse extends IResponse {
  adminAppRoute: IAdminAppRoute | null;
}

export interface IAdminAppRoutesResponse extends IResponse {
  adminAppRoutes: IAdminAppRoute[];
}

// [dbo].[adminAppRouteType]
// 1: Primary
// 2: Secondary
export interface IAdminAppRouteTypeBase {
  name: string;
}

export interface IAdminAppRouteTypeCreate extends IAdminAppRouteTypeBase {
  createdAt?: string;
  deletedAt?: string | null;
  updatedAt?: string;
}

export interface IAdminAppRouteTypeUpdate extends IAdminAppRouteTypeCreate {
  id: number;
}

export interface IAdminAppRouteType extends IAdminAppRouteTypeUpdate {}

export const adminAppRouteTypes: IAdminAppRouteType[] = [
  {
    id: 1,
    name: "Primary"
  },
  {
    id: 2,
    name: "Secondary"
  }
];

// [dbo].[adminAppRoutePosition]
// 1: Top
// 2: Bottom
export interface IAdminAppRoutePositionBase {
  name: string;
}

export interface IAdminAppRoutePositionCreate extends IAdminAppRoutePositionBase {
  createdAt?: string;
  deletedAt?: string | null;
  updatedAt?: string;
}

export interface IAdminAppRoutePositionUpdate extends IAdminAppRoutePositionCreate {
  id: number;
}

export interface IAdminAppRoutePosition extends IAdminAppRoutePositionUpdate {}

export const adminAppRoutePositions: IAdminAppRoutePosition[] = [
  {
    id: 1,
    name: "Top"
  },
  {
    id: 2,
    name: "Bottom"
  }
];
