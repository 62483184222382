import * as React from "react";

export interface IBarTooltipProps {
  active?: any;
  payload?: any;
  label?: any;
}

export interface IBarTooltipState {}

export default class BarTooltip extends React.Component<IBarTooltipProps, IBarTooltipState> {
  public constructor(props: IBarTooltipProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { active, payload } = this.props;

    if (active) {
      return (
        <div className="barTooltip">
          <p className="heading">{`${payload[0].payload.programDescription}`}</p>
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    } else {
      return null;
    }
  }
}
