/**
 * Test whether a string is a valid stringified JSON object
 * @param jsonString string
 */
export const isJsonString = (jsonString: string): boolean => {
  try {
    JSON.parse(jsonString);
  } catch (error) {
    return false;
  }
  return true;
};
