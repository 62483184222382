import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { connect } from "react-redux";
import { logoutApp } from "../../core/authentication/authenticationConfig";
// components
import Avatar from "../avatar/Avatar";

export interface ILoggedInAsProps {
  user?: any;
}

export interface ILoggedInAsState {
  isOpen: boolean;
}
class LoggedInAs extends React.Component<ILoggedInAsProps, ILoggedInAsState> {
  // @ts-ignore
  menu: HTMLDivElement | null;
  constructor(props: ILoggedInAsProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }
  componentDidMount() {
    window.addEventListener("click", this.handleClickOutside, true);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOutside, true);
  }
  public render() {
    const { user } = this.props;
    const { isOpen } = this.state;

    let name: string;
    // the user.name will be "Error" when an unauthorized user logs in
    if (user.name && user.name !== "Error") {
      name = user.name;
    } else if (user.firstname && user.lastname) {
      name = `${user.firstname} ${user.lastname}`;
    } else {
      name = user.username;
    }
    return (
      <div aria-haspopup="true" aria-expanded={isOpen} className="loggedInAs" id="logged-in-as" ref={el => (this.menu = el)}>
        <button
          type="button"
          className="loggedInAsButton"
          onClick={() =>
            this.setState({
              isOpen: !isOpen
            })
          }
        >
          <span className="loggedInAsName">{name}</span>
          <span className="loggedInAsPhoto">
            <Avatar user={user} />
          </span>
        </button>
        <div className="loggedInAsDropdownList">
          <button onClick={e => logoutApp()} className="loggedInAsLink">
            <Icon iconName="SignOut" /> Logout
          </button>
        </div>
      </div>
    );
  }

  handleClickOutside = e => {
    if (this.menu && !this.menu.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user.user
});

export default connect(mapStateToProps)(LoggedInAs);
