import * as React from "react";
import Required from "./Required";
import { P } from "../typography";

export interface ITextareaProps {
  autoFocus?: boolean;
  autoComplete?: string;
  autoCorrect?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  id: string;
  minLength?: number;
  maxLength?: number;
  name: string;
  onBlur?: any;
  onFocus?: any;
  onChange?: any;
  onKeyUp?: any;
  placeholder?: string;
  readOnly?: boolean;
  refs?: any;
  required?: boolean;
  showRequired?: boolean;
  tabIndex?: number;
  value?: string;
  showCharacterCount?: boolean;
  showValidation?: boolean;
}

export interface ITextareaState {
  rows: number;
  minRows: number;
}

export default class Textarea extends React.Component<ITextareaProps, ITextareaState> {
  public constructor(props: ITextareaProps) {
    super(props);

    this.state = {
      rows: 1,
      minRows: 1
    };

    this._handleChange = this._handleChange.bind(this);
  }

  public render(): React.ReactElement<ITextareaProps> {
    const {
      autoFocus,
      autoComplete,
      autoCorrect,
      className,
      defaultValue,
      disabled,
      id,
      minLength,
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyUp,
      placeholder,
      readOnly,
      refs,
      required,
      showRequired,
      tabIndex,
      value,
      showCharacterCount,
      showValidation
    } = this.props;

    const { rows } = this.state;

    let cssClass: string = "textarea";

    if (showValidation) {
      cssClass += " textareaRequired";
    }
    if (className) {
      cssClass += " " + className;
    }
    const req: JSX.Element | null = showRequired ? <Required /> : null;

    const textareaWrapper: string = showRequired ? "textareaWrapperRequired" : "textareaWrapper";

    const change: (e: any) => void = onChange
      ? e => {
          this._handleChange(e);
          onChange(e);
        }
      : e => {
          this._handleChange(e);
        };

    return (
      <div className={textareaWrapper}>
        <textarea
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          className={cssClass}
          defaultValue={defaultValue ? defaultValue : undefined}
          disabled={disabled}
          id={id}
          minLength={minLength}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={change}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={refs}
          required={required}
          rows={rows}
          tabIndex={tabIndex}
          value={value ? value : undefined}
        />
        {req}
        {maxLength && showCharacterCount && <P className="helpText">{maxLength && value ? maxLength - value.length : maxLength} characters remaining</P>}
      </div>
    );
  }

  private _handleChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    const { minRows } = this.state;

    const textareaLineHeight: number = 16;

    e.target.rows = minRows; // reset number of rows in textarea

    const currentRows: number = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows > 0) {
      e.target.rows = currentRows;
    }

    this.setState({
      rows: currentRows
    });
  }
}
