import * as React from "react";
import { connect } from "react-redux";
// components
import BottlerSwitcher from "../../../components/switcher/BottlerSwitcher";
import Page from "../../../components/page/Page";
import RequestHistoryTable from "../../../components/shared-services/RequestHistoryTable";
// logging
import { withPageLog } from "../../logging/LogComponentChange";
import { IBottler } from "../../../types/Bottler";
import { IUser } from "../../../types/User";

interface IRequestHistoryProps {
  bottler: IBottler;
  user: IUser;
}

const RequestHistory = ({ bottler, user }: IRequestHistoryProps) => {
  return (
    <Page className="loginsActions" permissions={true} title="Request History">
      <BottlerSwitcher showAllOption={true} />
      <RequestHistoryTable bottler={bottler} />
    </Page>
  );
};

export default connect((state: any) => ({
  bottler: state.bottler.bottler
}))(withPageLog(RequestHistory));
