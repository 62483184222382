import moment from "moment";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { breakpoints } from "../../enums/breakpoints";
import Button from "../../components/button/Button";
import Checkbox from "../../components/input/Checkbox";
import Datepicker from "../../components/input/Datepicker";
import Input from "../../components/input/Input";
import Label from "../../components/label/Label";
import Band from "../../components/layout/Band";
import Section from "../../components/layout/Section";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import TBody from "../../components/table/TBody";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import THead from "../../components/table/THead";
import Tr from "../../components/table/Tr";
import H1 from "../../components/typography/H1";
import Hr from "../../components/typography/Hr";
import Switcher from "../../components/switcher/Switcher";
import { withPageLog } from "../logging/LogComponentChange";
import { getPayAreas } from "../../services/PayArea";
import { PayArea } from "../../types/PayArea";
import { getPayrollPeriods } from "../../services/PayrollPeriod";
import { IPayrollPeriod } from "../../types/PayrollPeriod";
import { putRequest } from "../../core/api/requestConfig";
import { IUser } from "../../types/User";
import Permissions from "../permissions/Permissions";

interface IColumn {
  type: string;
  text: string;
  title: string;
}

const columns: IColumn[] = [
  { type: "label", text: "Label", title: "Label" },
  { type: "startDate", text: "Start Date", title: "Start Date" },
  { type: "endDate", text: "End Date", title: "End Date" },
  { type: "checkDate", text: "Check Date", title: "Check Date" },
  { type: "isActive", text: "", title: "Active" },
  { type: "remove", text: "", title: "Remove Payroll Period" }
];

export interface IPayrollPeriodProps {
  config: any;
  user: IUser;
}

export interface IPayrollPeriodTypesState {
  dirty: boolean;
  disabled: boolean;
  loading: boolean;
  payArea: PayArea | null;
  payAreas: PayArea[];
  payrollPeriods: IPayrollPeriod[];
}

class PayrollPeriods extends React.Component<IPayrollPeriodProps, IPayrollPeriodTypesState> {
  public constructor(props: IPayrollPeriodProps) {
    super(props);
    this.state = {
      dirty: false,
      disabled: true,
      loading: true,
      payArea: null,
      payAreas: [],
      payrollPeriods: []
    };
    this._getPayAreas = this._getPayAreas.bind(this);
    this._getPayrollPeriods = this._getPayrollPeriods.bind(this);
    this._changePayArea = this._changePayArea.bind(this);
    this._addPayrollPeriod = this._addPayrollPeriod.bind(this);
    this._handleAdd = this._handleAdd.bind(this);
    this._handleChangePayArea = this._handleChangePayArea.bind(this);
    this._changeLabel = this._changeLabel.bind(this);
    this._changeIsActive = this._changeIsActive.bind(this);
    this._changeDate = this._changeDate.bind(this);
    this._savePayrollPeriods = this._savePayrollPeriods.bind(this);
    this._putPayrollPeriods = this._putPayrollPeriods.bind(this);
  }
  public shouldComponentUpdate(nextState: any): boolean {
    return this.state.payrollPeriods !== nextState.payrollPeriods ? true : false;
  }
  public componentDidMount(): void {
    this._getPayAreas();
  }
  public render() {
    const options: JSX.Element[] = this.state.payAreas.map(payArea => {
      return (
        <option key={payArea.id} value={payArea.id}>
          {payArea.label} {"("}
          {payArea.payrollPeriodType.label}
          {")"}
        </option>
      );
    });
    const headerColumns: JSX.Element[] = columns.map((column, index) => {
      return <Th key={index} text={column.text} title={column.title} hidden={false} />;
    });
    const rows: JSX.Element[] = [];
    if (this.state.payrollPeriods.length > 0) {
      this.state.payrollPeriods.map((payrollPeriod, rowIndex) => {
        if (!payrollPeriod.deletedAt) {
          const label: JSX.Element = (
            <Td>
              <Label htmlFor={"label-" + rowIndex.toString()} srOnlyAt={breakpoints.md} text="Label" />
              <Input
                autoFocus={payrollPeriod.isNew ? true : false}
                id={"label-" + rowIndex.toString()}
                inputType="text"
                name={"label"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this._changeLabel(e, rowIndex)}
                value={payrollPeriod.label ? payrollPeriod.label : ""}
              />
            </Td>
          );
          const start: Date | null = payrollPeriod.startDate ? moment(payrollPeriod.startDate, "YYYY-MM-DD").toDate() : null;
          const startDate: JSX.Element = (
            <Td>
              <Datepicker label="Start Date" onChange={(date: Date) => this._changeDate(date, "startDate", rowIndex)} placeholder="Start date..." required={true} value={start} />
            </Td>
          );
          const end: Date | null = payrollPeriod.endDate ? moment(payrollPeriod.endDate, "YYYY-MM-DD").toDate() : null;
          const endDate: JSX.Element = (
            <Td>
              <Datepicker label="End Date" onChange={(date: Date) => this._changeDate(date, "endDate", rowIndex)} placeholder="End date..." required={true} value={end} />
            </Td>
          );
          const check: Date | null = payrollPeriod.checkDate ? moment(payrollPeriod.checkDate, "YYYY-MM-DD").toDate() : null;
          const checkDate: JSX.Element = (
            <Td>
              <Datepicker label="Check Date" onChange={(date: Date) => this._changeDate(date, "checkDate", rowIndex)} placeholder="Check date..." required={true} value={check} />
            </Td>
          );
          const active: JSX.Element = (
            <Td>
              <Checkbox
                checked={payrollPeriod.isActive}
                id={"isActive-" + rowIndex.toString()}
                labelOnClick={(e: React.ChangeEvent<HTMLInputElement>) => this._changeIsActive(e, rowIndex)}
                name={"isActive"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this._changeIsActive(e, rowIndex)}
                text="Active"
              />
            </Td>
          );
          const remove: JSX.Element = (
            <Td>
              <Button
                className="buttonWhite"
                iconLeft={<Icon iconName="Delete" />}
                onClick={(e: any) => this._removePayrollPeriod(e, rowIndex)}
                text="Remove"
                title="Remove Payroll Period"
              />
            </Td>
          );
          rows.push(
            <Tr key={rowIndex} hover={true} striped={true}>
              {label}
              {startDate}
              {endDate}
              {checkDate}
              {active}
              {remove}
            </Tr>
          );
        }
      });
      if (rows.length === 0) {
        this._addPayrollPeriod();
      }
    }

    const title: string = "Shared Services: Payroll Periods";

    return (
      <div className="sharedServicesPayrollPeriods">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <div className="payrollPeriods">
              <Switcher
                id="payroll-peroids"
                headingText="Switch Pay Area:"
                labelText="Switch Pay Area"
                loading={this.state.loading}
                onChange={(e: any) => this._handleChangePayArea(e)}
                options={options}
              />
              <LoaderWrapper>
                <TableWrapper>
                  <Table>
                    <THead>
                      <Tr>{headerColumns}</Tr>
                    </THead>
                    <TBody colSpan={columns.length}>{rows}</TBody>
                  </Table>
                </TableWrapper>
                <Loader loading={this.state.loading} type="Overlay" />
              </LoaderWrapper>
              <Button
                className="buttonInfo"
                disabled={this.state.disabled}
                iconLeft={<Icon iconName="Add" />}
                onClick={(e: any) => this._handleAdd(e)}
                text="Add A Payroll Period"
                title="Add A Payroll Period"
              />
              <Hr />
              <Button
                className="buttonSave buttonLarge"
                disabled={this.state.disabled}
                iconLeft={<Icon iconName="Save" />}
                onClick={(e: any) => this._savePayrollPeriods(e)}
                text="Save Payroll Periods"
                title="Save Payroll Periods"
              />
            </div>
          </Permissions>
        </Section>
        <ToastContainer />
      </div>
    );
  }
  private _getPayAreas() {
    this.setState(
      {
        disabled: true,
        loading: true
      },
      async () => {
        try {
          const payAreas = await getPayAreas();
          if (payAreas) {
            this.setState(
              {
                payArea: payAreas[0],
                payAreas: payAreas
              },
              () => {
                this._getPayrollPeriods();
              }
            );
          } else {
            toast.error("There was an error fetching pay areas.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              dirty: false,
              loading: false,
              payArea: null,
              payAreas: []
            });
          }
        } catch (error) {
          toast.error("There was an error fetching pay areas.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            dirty: false,
            loading: false,
            payArea: null,
            payAreas: []
          });
        }
      }
    );
  }
  private _getPayrollPeriods(): void {
    this.setState(
      {
        disabled: true,
        loading: true
      },
      async () => {
        if (this.state.payArea) {
          try {
            const payrollPeriods = await getPayrollPeriods(this.state.payArea.id);
            if (payrollPeriods) {
              this.setState(
                {
                  payrollPeriods: []
                },
                () => {
                  this.setState(
                    {
                      dirty: false,
                      disabled: false,
                      loading: false,
                      payrollPeriods: payrollPeriods
                    },
                    () => {
                      if (this.state.payrollPeriods.length === 0) {
                        this._addPayrollPeriod();
                      }
                    }
                  );
                }
              );
            } else {
              toast.error("There was an error fetching payroll periods.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                dirty: false,
                disabled: false,
                loading: false,
                payrollPeriods: []
              });
            }
          } catch (error) {
            toast.error("There was an error fetching payroll periods.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              dirty: false,
              disabled: false,
              loading: false,
              payrollPeriods: []
            });
          }
        }
      }
    );
  }
  private _putPayrollPeriods(): void {
    this.setState(
      {
        disabled: true,
        loading: true
      },
      () => {
        putRequest("/legacy/api/v1/payrollperiod", this.state.payrollPeriods)
          .then(data => {
            toast.success("Payroll Periods saved successfully.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            setTimeout(() => {
              this._getPayrollPeriods();
            }, 300);
          })
          .catch(error => {
            this.setState(
              {
                disabled: false,
                loading: false
              },
              () => {
                toast.error("There was an error saving Payroll Periods.", {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                this._getPayrollPeriods();
              }
            );
          });
      }
    );
  }
  private _handleChangePayArea(e: any) {
    e.preventDefault();
    const value: string = e.target.value;
    if (this.state.dirty) {
      const result: boolean = window.confirm("You have unsaved changes. Are you sure you want to lose this information?");
      if (result) {
        this._changePayArea(value);
      }
    } else {
      this._changePayArea(value);
    }
  }

  private _changePayArea(value: string): void {
    const id: number = Number(value);
    this.state.payAreas.map(payArea => {
      if (payArea.id === id) {
        this.setState(
          {
            dirty: false,
            payArea: payArea
          },
          () => {
            this._getPayrollPeriods();
          }
        );
      }
    });
  }

  private _addPayrollPeriod() {
    const { user } = this.props;
    const { payArea } = this.state;
    const now: string = moment().toISOString();
    if (payArea) {
      const payrollPeriod: IPayrollPeriod = {
        checkDate: "",
        createdAt: now,
        deletedAt: null,
        endDate: "",
        isActive: true,
        isNew: true,
        label: "",
        payAreaId: payArea.id,
        startDate: "",
        updatedAt: now,
        userId: user.id
      };
      const rows: IPayrollPeriod[] = this.state.payrollPeriods.slice();
      rows.push(payrollPeriod);
      this.setState(
        {
          dirty: true,
          payrollPeriods: []
        },
        () => {
          this.setState({
            payrollPeriods: rows
          });
        }
      );
    }
  }

  private _handleAdd(e: any) {
    e.preventDefault();
    this._addPayrollPeriod();
  }

  private _savePayrollPeriods(e: any) {
    e.preventDefault();
    this._putPayrollPeriods();
  }

  private _changeLabel(e: React.ChangeEvent<HTMLInputElement>, rowIndex: number): void {
    const value: string = e.target.value;
    const rows: IPayrollPeriod[] = this.state.payrollPeriods.slice();
    rows[rowIndex].label = value;
    this.setState({
      dirty: true,
      payrollPeriods: rows
    });
  }

  private _changeDate(date: Date | null, type: string, rowIndex: number): void {
    if (date) {
      const iso: string = moment(date).toISOString();
      const rows: IPayrollPeriod[] = this.state.payrollPeriods.slice();
      if (type === "startDate") {
        rows[rowIndex].startDate = iso;
      }
      if (type === "endDate") {
        rows[rowIndex].endDate = iso;
      }
      if (type === "checkDate") {
        rows[rowIndex].checkDate = iso;
      }
      this.setState({
        dirty: true,
        payrollPeriods: rows
      });
    }
  }

  private _changeIsActive(e: React.ChangeEvent<HTMLInputElement>, rowIndex: number): void {
    const rows: IPayrollPeriod[] = this.state.payrollPeriods.slice();
    const checked: boolean = rows[rowIndex].isActive;
    if (checked) {
      rows[rowIndex].isActive = false;
    } else {
      rows[rowIndex].isActive = true;
    }
    this.setState(
      {
        dirty: true,
        payrollPeriods: []
      },
      () => {
        this.setState({
          payrollPeriods: rows
        });
      }
    );
  }

  private _removePayrollPeriod(e: any, rowIndex: number) {
    e.preventDefault();
    const result: boolean = window.confirm("Are you sure you want to remove this payroll period?");
    if (result) {
      const rows: IPayrollPeriod[] = this.state.payrollPeriods.slice();
      if (!rows[rowIndex].id) {
        rows.splice(rowIndex, 1);
      } else {
        rows[rowIndex].deletedAt = moment().toISOString();
      }
      this.setState(
        {
          dirty: true,
          payrollPeriods: []
        },
        () => {
          this.setState({
            payrollPeriods: rows
          });
        }
      );
    }
  }
}

export const mapStateToProps = (state: any) => {
  return {
    config: state.config.config,
    user: state.user.user
  };
};

export default connect(mapStateToProps)(withPageLog(PayrollPeriods));
