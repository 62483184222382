import * as React from "react";
// components
import { H6, P } from "../typography";

interface IStatProps {
  className?: string;
  title: string | JSX.Element;
  value: string;
}

const Stat = ({ className, title, value }: IStatProps) => {
  return (
    <div className={className ? `stat ${className}` : "stat"}>
      <H6>{title}</H6>
      <P>{value}</P>
    </div>
  );
};

export default Stat;
