import * as React from "react";
import { Route, Switch } from "react-router-dom";
import SwaggerV1 from "./SwaggerV1";
import SwaggerV2 from "./SwaggerV2";
import SwaggerV3 from "./SwaggerV3";
import SwaggerV4 from "./SwaggerV4";
import Documentation from "./Documentation";
import Typography from "./design-system/Typography";
import HorizontalRules from "./design-system/HorizontalRules";
import Colors from "./design-system/Colors";
import LoaderStyleGuide from "./design-system/Loader";

class DocumentationRoutes extends React.Component {
  public render() {
    return (
      <div className="documentationRoutes">
        <Switch>
          <Route exact={true} path="/documentation/home" component={Documentation} />
          <Route exact={true} path="/documentation/api-v1" component={SwaggerV1} />
          <Route exact={true} path="/documentation/api-v2" component={SwaggerV2} />
          <Route exact={true} path="/documentation/api-v3" component={SwaggerV3} />
          <Route exact={true} path="/documentation/api-v4" component={SwaggerV4} />
          <Route exact={true} path="/documentation/typography" component={Typography} />
          <Route exact={true} path="/documentation/horizontal-rules" component={HorizontalRules} />
          <Route exact={true} path="/documentation/colors" component={Colors} />
          <Route exact={true} path="/documentation/loader" component={LoaderStyleGuide} />
        </Switch>
      </div>
    );
  }
}

export default DocumentationRoutes;
