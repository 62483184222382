import * as React from "react";
import LogoRound from "../logo/LogoRound";

export interface ILoaderFullscreenProps {
  text?: string;
}

export interface ILoaderFullscreenState {}

export default class LoaderFullscreen extends React.Component<
  ILoaderFullscreenProps,
  ILoaderFullscreenState
> {
  public render(): React.ReactElement<ILoaderFullscreenProps> {
    const { text } = this.props;

    const t: string = text ? text : "Loading...";

    return (
      <div className="loaderFullscreen">
        <LogoRound />
        <p className="loaderText">{t}</p>
      </div>
    );
  }
}
