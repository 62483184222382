import { Apps } from "../../../types/App";

export type FilterType = "createdAt";

export interface IColumn {
  appIds: number[];
  databaseColumn: string; // used for search
  databaseTable: string; // used for search
  filter: boolean; // show filter dropdown
  filterPosition?: "filterLeft" | "filterRight";
  filterType?: FilterType;
  filterInput?: "dates" | "multiselect";
  id: string; // used for map key
  sort: boolean;
  sortBy?: string; // used for sorting
  sortMobile: boolean; // add to mobile select dropdown for sorting
  text: string; // mobile text label
  title: string; // desktop table th text
  type: "string" | "number"; // for excel export
}

export const columns: IColumn[] = [
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "id",
    databaseTable: "requestError",
    filter: false,
    id: "id",
    sort: true,
    sortBy: "id",
    sortMobile: true,
    text: "ID",
    title: "Error ID",
    type: "number"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "createdAt",
    databaseTable: "requestError",
    filter: false,
    id: "createdAtIso",
    sort: true,
    sortBy: "createdAt",
    sortMobile: true,
    text: "Created At (ISO)",
    title: "Created At (ISO)",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "createdAt",
    databaseTable: "requestError",
    filter: false,
    id: "createdDate",
    sort: false,
    sortMobile: false,
    text: "Date",
    title: "Date Created",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "createdAt",
    databaseTable: "requestError",
    filter: false,
    id: "createdTime",
    sort: false,
    sortMobile: false,
    text: "Time",
    title: "Time Created",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "errorMessage",
    databaseTable: "requestError",
    filter: false,
    id: "errorMessage",
    sort: true,
    sortBy: "errorMessage",
    sortMobile: true,
    text: "Error Message",
    title: "Error Message",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "errorCode",
    databaseTable: "requestError",
    filter: false,
    id: "errorCode",
    sort: true,
    sortBy: "errorCode",
    sortMobile: true,
    text: "Error Code",
    title: "Error Code",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "errorNumber",
    databaseTable: "requestError",
    filter: false,
    id: "errorNumber",
    sort: true,
    sortBy: "errorNumber",
    sortMobile: true,
    text: "Error Number",
    title: "Error Number",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "errorType",
    databaseTable: "requestError",
    filter: false,
    id: "errorType",
    sort: true,
    sortBy: "errorType",
    sortMobile: true,
    text: "Error Type",
    title: "Error Type",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "errorClass",
    databaseTable: "requestError",
    filter: false,
    id: "errorClass",
    sort: true,
    sortBy: "errorClass",
    sortMobile: true,
    text: "Error Class",
    title: "Error Class",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "name",
    databaseTable: "bottler",
    filter: false,
    id: "bottlerName",
    sort: true,
    sortBy: "bottler.name",
    sortMobile: true,
    text: "Bottler",
    title: "Bottler",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "name",
    databaseTable: "user",
    filter: false,
    id: "userName",
    sort: true,
    sortBy: "user.name",
    sortMobile: true,
    text: "Name",
    title: "User Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "email",
    databaseTable: "user",
    filter: false,
    id: "userEmail",
    sort: true,
    sortBy: "user.email",
    sortMobile: true,
    text: "Email",
    title: "User Email",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "serialNumber",
    databaseTable: "equipment",
    filter: false,
    id: "equipmentSerialNumber",
    sort: true,
    sortBy: "equipment.serialNumber",
    sortMobile: true,
    text: "Serial Number",
    title: "Serial Number",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "equipmentNumber",
    databaseTable: "equipment",
    filter: false,
    id: "equipmentNumber",
    sort: true,
    sortBy: "equipment.equipmentNumber",
    sortMobile: true,
    text: "Equipment Number",
    title: "Equipment Number",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "code",
    databaseTable: "equipmentType",
    filter: false,
    id: "equipmentTypeCode",
    sort: true,
    sortBy: "[equipmentProblem->equipmentType].code",
    sortMobile: true,
    text: "Equipment Type Code",
    title: "Equipment Type Code",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "description",
    databaseTable: "equipmentType",
    filter: false,
    id: "equipmentType",
    sort: true,
    sortBy: "[equipmentProblem->equipmentType].description",
    sortMobile: true,
    text: "Equipment Type",
    title: "Equipment Type",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "description",
    databaseTable: "equipmentProblem",
    filter: false,
    id: "equipmentProblemDescription",
    sort: true,
    sortBy: "equipmentProblem.description",
    sortMobile: true,
    text: "Problem",
    title: "Equipment Problem",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "problemCode",
    databaseTable: "equipmentProblem",
    filter: false,
    id: "equipmentProblemCode",
    sort: true,
    sortBy: "equipmentProblem.problemCode",
    sortMobile: true,
    text: "Problem Code",
    title: "Equipment Problem Code",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "description",
    databaseTable: "priority",
    filter: false,
    id: "priorityDescription",
    sort: true,
    sortBy: "[equipmentProblem->priority].description",
    sortMobile: true,
    text: "Priority",
    title: "Equipment Problem Priority",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "outletNumber",
    databaseTable: "outlet",
    filter: false,
    id: "outletNumber",
    sort: true,
    sortBy: "outlet.outletNumber",
    sortMobile: true,
    text: "Outlet Number",
    title: "Outlet Number",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "outletName",
    databaseTable: "outlet",
    filter: false,
    id: "outletName",
    sort: true,
    sortBy: "outlet.outletName",
    sortMobile: true,
    text: "Outlet Name",
    title: "Outlet Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "street",
    databaseTable: "outlet",
    filter: false,
    id: "outletStreet",
    sort: true,
    sortBy: "outlet.street",
    sortMobile: true,
    text: "Street",
    title: "Outlet Street",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "city",
    databaseTable: "outlet",
    filter: false,
    id: "outletCity",
    sort: true,
    sortBy: "outlet.city",
    sortMobile: true,
    text: "City",
    title: "Outlet City",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "state",
    databaseTable: "outlet",
    filter: false,
    id: "outletState",
    sort: true,
    sortBy: "outlet.state",
    sortMobile: true,
    text: "State",
    title: "Outlet State",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "postalCode",
    databaseTable: "outlet",
    filter: false,
    id: "outletPostalCode",
    sort: true,
    sortBy: "outlet.postalCode",
    sortMobile: true,
    text: "Postal Code",
    title: "Outlet Postal Code",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "outletContactName",
    databaseTable: "requestError",
    filter: false,
    id: "outletContactName",
    sort: true,
    sortBy: "outletContactName",
    sortMobile: true,
    text: "Contact Name",
    title: "Outlet Contact Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "outletContactPhone",
    databaseTable: "requestError",
    filter: false,
    id: "outletContactPhone",
    sort: true,
    sortBy: "outletContactPhone",
    sortMobile: true,
    text: "Contact Phone",
    title: "Outlet Contact Phone",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "outletContactPhoneExtension",
    databaseTable: "requestError",
    filter: false,
    id: "outletContactPhoneExtension",
    sort: true,
    sortBy: "outletContactPhoneExtension",
    sortMobile: true,
    text: "Contact Extension",
    title: "Outlet Contact Phone Extension",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "availabilityDays",
    databaseTable: "requestError",
    filter: false,
    id: "availabilityDays",
    sort: true,
    sortBy: "availabilityDays",
    sortMobile: true,
    text: "Availability Days",
    title: "Availability Days",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "availabilityHoursStart",
    databaseTable: "requestError",
    filter: false,
    id: "availabilityHoursStart",
    sort: true,
    sortBy: "availabilityHoursStart",
    sortMobile: true,
    text: "Hours Start",
    title: "Availability Hours Start",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "availabilityHoursEnd",
    databaseTable: "requestError",
    filter: false,
    id: "availabilityHoursEnd",
    sort: true,
    sortBy: "availabilityHoursEnd",
    sortMobile: true,
    text: "Hours End",
    title: "Availability Hours End",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "equipmentLocation",
    databaseTable: "requestError",
    filter: false,
    id: "equipmentLocation",
    sort: true,
    sortBy: "equipmentLocation",
    sortMobile: true,
    text: "Equipment Location",
    title: "Equipment Location",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "description",
    databaseTable: "requestError",
    filter: false,
    id: "comments",
    sort: true,
    sortBy: "description",
    sortMobile: true,
    text: "Comment",
    title: "Request Comment",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    databaseColumn: "equipmentRepairTicketNumber",
    databaseTable: "requestError",
    filter: false,
    id: "equipmentRepairTicketNumber",
    sort: true,
    sortBy: "equipmentRepairTicketNumber",
    sortMobile: true,
    text: "Ticket Number",
    title: "Ticket Number",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "street",
    databaseTable: "requestEquipmentLocation",
    filter: false,
    id: "requestEquipmentLocationStreet",
    sort: true,
    sortBy: "requestEquipmentLocation.street",
    sortMobile: true,
    text: "Equipment Street",
    title: "Equipment Street",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "city",
    databaseTable: "requestEquipmentLocation",
    filter: false,
    id: "requestEquipmentLocationCity",
    sort: true,
    sortBy: "requestEquipmentLocation.city",
    sortMobile: true,
    text: "Equipment City",
    title: "Equipment City",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "state",
    databaseTable: "requestEquipmentLocation",
    filter: false,
    id: "requestEquipmentLocationState",
    sort: true,
    sortBy: "requestEquipmentLocation.state",
    sortMobile: true,
    text: "Equipment State",
    title: "Equipment State",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "postalCode",
    databaseTable: "requestEquipmentLocation",
    filter: false,
    id: "requestEquipmentLocationPostalCode",
    sort: true,
    sortBy: "requestEquipmentLocation.postalCode",
    sortMobile: true,
    text: "Equipment Postal Code",
    title: "Equipment Postal Code",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "firstName",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactFirstName",
    sort: true,
    sortBy: "requestContact.firstName",
    sortMobile: true,
    text: "Request Contact First Name",
    title: "Request Contact First Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "lastName",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactLastName",
    sort: true,
    sortBy: "requestContact.lastName",
    sortMobile: true,
    text: "Request Contact Last Name",
    title: "Request Contact Last Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "email",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactEmail",
    sort: true,
    sortBy: "requestContact.email",
    sortMobile: true,
    text: "Request Contact Email",
    title: "Request Contact Email",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "emailNotification",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactEmailNotification",
    sort: true,
    sortBy: "requestContact.emailNotification",
    sortMobile: true,
    text: "Request Contact Email Notification",
    title: "Request Contact Email Notification",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "phone",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactPhone",
    sort: true,
    sortBy: "requestContact.phone",
    sortMobile: true,
    text: "Request Contact Phone",
    title: "Request Contact Phone",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "phoneNotification",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactPhoneNotification",
    sort: true,
    sortBy: "requestContact.phoneNotification",
    sortMobile: true,
    text: "Request Contact Phone Notification",
    title: "Request Contact Phone Notification",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "textNotification",
    databaseTable: "requestContact",
    filter: false,
    id: "requestContactTextNotification",
    sort: true,
    sortBy: "requestContact.textNotification",
    sortMobile: true,
    text: "Request Contact Text Notification",
    title: "Request Contact Text Notification",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "name",
    databaseTable: "contactRole",
    filter: false,
    id: "contactRoleName",
    sort: true,
    sortBy: "[requestContact->contactRole].name",
    sortMobile: true,
    text: "Request Contact Role",
    title: "Request Contact Role",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "employeeName",
    databaseTable: "requestEmployee",
    filter: false,
    id: "requestEmployeeName",
    sort: true,
    sortBy: "requestEmployee.employeeName",
    sortMobile: true,
    text: "Employee Name",
    title: "Employee Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "employeeEmail",
    databaseTable: "requestEmployee",
    filter: false,
    id: "requestEmployeeEmail",
    sort: true,
    sortBy: "requestEmployee.employeeEmail",
    sortMobile: true,
    text: "Employee Email",
    title: "Employee Email",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "employeePhone",
    databaseTable: "requestEmployee",
    filter: false,
    id: "requestEmployeePhone",
    sort: true,
    sortBy: "requestEmployee.employeePhone",
    sortMobile: true,
    text: "Employee Phone",
    title: "Employee Phone",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "emailNotification",
    databaseTable: "requestEmployee",
    filter: false,
    id: "requestEmployeeEmailNotification",
    sort: true,
    sortBy: "requestEmployee.emailNotification",
    sortMobile: true,
    text: "Employee Email Notification",
    title: "Employee Email Notification",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    databaseColumn: "textNotification",
    databaseTable: "requestEmployee",
    filter: false,
    id: "requestEmployeeTextNotification",
    sort: true,
    sortBy: "requestEmployee.textNotification",
    sortMobile: true,
    text: "Employee Text Notification",
    title: "Employee Text Notification",
    type: "string"
  }
];
