import * as React from "react";
import Label from "../label/Label";
import Select from "../input/Select";
import Hr from "../typography/Hr";
import { sizes } from "../../enums/sizes";
import H2 from "../typography/H2";

export interface ISwitcherProps {
  defaultValue?: string;
  disabled?: boolean;
  id: string;
  headingText: string;
  labelText: string;
  loading?: boolean;
  onChange: any;
  options: JSX.Element[];
  value?: string;
}

class Switcher extends React.Component<ISwitcherProps, {}> {
  public render() {
    const { defaultValue, disabled, id, headingText, labelText, loading, onChange, options, value } = this.props;
    
    return (
      <div className="switcher">
        <div className="switcherInner">
          <H2>{headingText}</H2>
          <div className="switcherInput">
            <Label htmlFor={id} srOnly={true} text={labelText} />
            <Select
              defaultValue={defaultValue}
              disabled={disabled}
              id={id}
              loading={loading}
              name={id}
              onChange={onChange}
              options={options}
              size={sizes.largest}
              value={value}
            />
          </div>
        </div>
        <Hr />
      </div>
    );
  }
}

export default Switcher;
