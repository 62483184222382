import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";

type AlertTypes = "Success" | "Error" | "Warning" | "Info";

export interface IAlertProps {
  icon?: string;
  show: boolean;
  text?: string;
  type: AlertTypes;
  className?: string;
}
export interface IAlertState {}

export default class Alert extends React.Component<IAlertProps, {}> {
  public render(): React.ReactElement<IAlertProps> {
    const { children, icon, show, text, type, className } = this.props;

    const iconName: string = icon
      ? icon
      : type === "Success"
      ? "CheckMark"
      : type === "Error"
      ? "ErrorBadge"
      : type === "Warning"
      ? "Warning"
      : type === "Info"
      ? "Info"
      : "AlertSolid";

    const cssClass: string = type ? `alert alert${type}` : `alert`;

    return (
      <div
        className={className ? `${cssClass} ${className}` : cssClass}
        role="alert"
        style={{ display: !show ? "none" : "" }}
      >
        <div className="alertIcon">
          <Icon iconName={iconName} />
        </div>
        <div className="alertMessage">
          {text} {children}
        </div>
      </div>
    );
  }
}
