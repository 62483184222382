import { IRequest } from "../types/Request";

export function requestTypeHierarchy(request: IRequest): string {
  const { requestType } = request;

  if (requestType.tower && requestType.requestGroup) {
    return `${requestType.tower.label} / ${requestType.requestGroup.label} / ${requestType.label}`;
  }

  return requestType.label;
}
