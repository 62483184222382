import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import TabButton from "./TabButton";

type Item = {
  text: string;
  icon?: string;
};

interface IProps {
  className?: string;
  items: Item[];
  handleChange: Function;
  selectedItem: string;
}

const TabBar = ({
  className,
  items,
  handleChange,
  selectedItem
}: IProps): JSX.Element => {
  return (
    <div className={className ? `tabbar ${className}` : "tabbar"}>
      {items.map(item => (
        <TabButton
          key={item.text}
          onClick={() => handleChange(item.text)}
          isSelected={selectedItem === item.text}
        >
          {item.icon && <Icon iconName={item.icon} />}
          {item.text}
        </TabButton>
      ))}
    </div>
  );
};

export default TabBar;
