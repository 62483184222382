import { getRequest, putRequest } from "../core/api/requestConfig";
import { IBottlerAccessGetResponse, IBottlerAccessPutResponse } from "../types/BottlerAccess";
import { AxiosResponse } from "axios";
// types
import { Apps } from "../types/App";

export const getBottlersAccess = async (): Promise<IBottlerAccessGetResponse> => {
  try {
    const res = await getRequest(`/api/v1/app-access-control?appId=${Apps.COKE_SERVICES_APP}`);
    return Promise.resolve(res);
  } catch (err) {
    console.error("getBottlersAccess", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};

export const updateBottlerAccess = async (id: number, isActive: boolean): Promise<IBottlerAccessPutResponse> => {
  try {
    const res = await putRequest(`/api/v1/app-access-control/${id}`, {
      appId: Apps.COKE_SERVICES_APP,
      isActive
    });
    return Promise.resolve(res);
  } catch (err) {
    console.error("updateBottlerAccess", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};
