export enum TypeKeys {
  SET_STATUS_FILTER = "SET_STATUS_FILTER"
}

type Error = string | null;

type setStatusFilterSuccess = {
  status: number | null;
  type: TypeKeys.SET_STATUS_FILTER;
};

export const setStatusFilterSuccessAction = (status: number | null): setStatusFilterSuccess => {
  return {
    type: TypeKeys.SET_STATUS_FILTER,
    status
  };
};

export const setStatusFilter = (status: number | null) => {
  return async dispatch => {
    dispatch(setStatusFilterSuccessAction(status));
  };
};

export type Actions = setStatusFilterSuccess;

export interface IStatusState {
  statusFilter: number | null;
  error: Error;
}

export const initialState = {
  statusFilter: null,
  error: null
};

export const reducer = (state: IStatusState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.SET_STATUS_FILTER:
      newState.statusFilter = action.status;
      newState.error = null;
      break;
    default:
      break;
  }
  return newState;
};
