import * as React from "react";
import LoaderWrapper from "../loader/LoaderWrapper";
import Loader from "../loader/Loader";

export interface ITableWrapperProps {
  loading?: boolean;
  searching?: boolean;
}
export interface ITableWrapperState {}

export default class TableWrapper extends React.Component<ITableWrapperProps, ITableWrapperState> {
  public render(): React.ReactElement<ITableWrapperProps> {
    const { loading, searching } = this.props;

    const cssClass: string = loading || searching ? "tableWrapper tableLoading" : "tableWrapper";

    return (
      <div className={cssClass}>
        <LoaderWrapper>
          {this.props.children}
          <Loader loading={loading} type="Overlay" searching={searching} showImage={true} position="Top" />
        </LoaderWrapper>
      </div>
    );
  }
}
