import * as React from "react";

export interface IButtonGroupProps {}

export interface IButtonGroupState {}

class ButtonGroup extends React.Component<
  IButtonGroupProps,
  IButtonGroupState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  public render() {
    return <div className="buttonGroup">{this.props.children}</div>;
  }
}

export default ButtonGroup;
