import React, { FC } from "react";
//import ReactQuill from "react-quill";
import clsx from "clsx";
import "react-quill/dist/quill.snow.css";

interface IWysiwygProps {
  className?: string;
  id: string;
  onBlur?: () => void;
  onChange: (html: string) => void;
  onFocus?: () => void;
  readOnly?: boolean;
  value: string | null;
  editorFormats?: Array<string>;
  editorOptions?: Array<any>;
}
export const Wysiwyg: FC<IWysiwygProps> = ({ className, id, onBlur, onChange, onFocus, readOnly, value, editorFormats, editorOptions }) => {
  const defaultOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic"],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ align: null }, { align: "center" }, { align: "right" }, { align: "justify" }],
    ["link"],
    ["clean"]
  ];

  const options = editorOptions ? editorOptions : defaultOptions;
  //For anyone wanting to customize this: https://quilljs.com/docs/formats/
  const formats = editorFormats ? editorFormats : undefined;

  // this is necessary to use ReactQuill with server rendering and avoid document undefined
  // uncomment the es6 import above and comment the lint below to activate component prop intellisense
  const ReactQuill = typeof window === "object" ? require("react-quill") : null;

  if (ReactQuill) {
    // Fix for onChange bug that requires clicking into an editor field 2x to edit
    // Issue is related to use of onChange and how it re-renders
    // Should use defaultValue when editor is blank
    // Can't use defaultValue and value together
    return value === "" || value === null || value === "<p><br></p>" ? (
      <ReactQuill
        className={clsx("wysiwyg", className ? className : "")}
        data-testid={id}
        formats={formats}
        id={id}
        modules={{
          clipboard: { matchVisual: false },
          toolbar: options
        }}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        readOnly={readOnly}
        defaultValue={value}
      />
    ) : (
      <ReactQuill
        className={clsx("wysiwyg", className ? className : "")}
        data-testid={id}
        formats={formats}
        id={id}
        modules={{
          clipboard: { matchVisual: false },
          toolbar: options
        }}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        readOnly={readOnly}
        value={value}
      />
    );
  } else {
    return null;
  }
};
