import * as React from "react";

export interface IPProps {
  bold?: boolean;
  className?: string;
  italic?: boolean;
  small?: boolean;
}

const P: React.StatelessComponent<IPProps> = ({ bold, children, className, italic, small }) => (
  <p className={`p ${className ? className : ""}`}>
    {bold && !italic && !small && <strong>{children}</strong>}
    {!bold && italic && !small && <em>{children}</em>}
    {bold && italic && !small && (
      <strong>
        <em>{children}</em>
      </strong>
    )}
    {!bold && !italic && small && <small>{children}</small>}
    {!bold && italic && small && (
      <em>
        <small>{children}</small>
      </em>
    )}
    {bold && italic && small && (
      <strong>
        <em>
          <small>{children}</small>
        </em>
      </strong>
    )}
    {!bold && !italic && !small && children}
  </p>
);

export default P;
