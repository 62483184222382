import * as React from "react";
import Band from "../layout/Band";
import H1 from "../typography/H1";
import Section from "../layout/Section";
import Permissions from "../../containers/permissions/Permissions";

export interface IPageProps {
  className?: string;
  permissions: boolean;
  title: string;
}

const Page: React.StatelessComponent<IPageProps> = ({ children, className, permissions, title }) => (
  <div className={className}>
    <Band>
      <H1>{title}</H1>
    </Band>
    <Section>
      {permissions && <Permissions title={title}>{children}</Permissions>}
      {!permissions && children}
    </Section>
  </div>
);

export default Page;
