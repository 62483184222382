import * as React from "react";
import H2 from "../typography/H2";
import P from "../typography/P";
import H3 from "../typography/H3";

export interface IErrorMessageProps {
  className?: string;
  errorCode: number;
  errorCodeType: string;
  errorMessage: string | JSX.Element;
}

export interface IErrorMessageState {}

class ErrorMessage extends React.Component<
  IErrorMessageProps,
  IErrorMessageState
> {
  public render(): React.ReactElement<IErrorMessageProps> {
    const { className, errorCode, errorCodeType, errorMessage } = this.props;

    let cssClass: string = "error";
    if (className) {
      cssClass += " " + className;
    }

    return (
      <div className={cssClass}>
        <div className="errorCode">
          <H2>{errorCode.toString()}</H2>
          <H3>{errorCodeType}</H3>
        </div>
        <P>{errorMessage}</P>
      </div>
    );
  }
}

export default ErrorMessage;
