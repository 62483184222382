import { getRequest } from "../api/requestConfig";

export enum TypeKeys {
    WIFI_SUCCESS = "WIFI_SUCCESS",
    WIFI_FAILURE = "WIFI_FAILURE",
} 
  
type WifiGenerated = {
    status: number;
    message: string;
    generated: string;
} | null;

type Error = string | null

type getWifiSuccess = {
  wifi: WifiGenerated
  type: TypeKeys.WIFI_SUCCESS
}

type getWifiFailure = {
  error: Error
  type: TypeKeys.WIFI_FAILURE
}

export const getResetSuccessCreator = (wifi: WifiGenerated): getWifiSuccess => {
    return {
      wifi,
      type: TypeKeys.WIFI_SUCCESS
    }
  }
  
export const getResetFailureCreator = (error: Error): getWifiFailure => {
    return {
      error,
      type: TypeKeys.WIFI_FAILURE
    }
}

export const resetPassword = () => {
    return async dispatch => {
        try {
            const response = await getRequest(`/api/v1/updateWifiPassword`)
            dispatch(getResetSuccessCreator(response))
        } catch (error) {
            console.log(error)
            dispatch(getResetFailureCreator(error))
        }
      }
};

export type Actions = getWifiSuccess | getWifiFailure;

export interface IWifiState {
  wifi: WifiGenerated,
  error: Error
}

export const initialState = {
  wifi: null,
  error: null
};

export const reducer = (state: IWifiState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.WIFI_SUCCESS:
        newState.wifi = action.wifi
        break;
    case TypeKeys.WIFI_FAILURE:
        newState.error = action.error
        break;
    default:
        break;
  }
  return newState;
};