export enum Apps {
  COKE_BOTTLER_APP = 1,
  SPFX_BOTTLER_REQUESTS = 2,
  SPFX_BOTTLER_DASHBOARD = 3,
  SPFX_BOTTLER_MANAGE_EMPLOYEES = 4,
  SPFX_BOTTLER_SELF_SERVICE = 5,
  SPFX_BOTTLER_ACCESS_MANAGEMENT = 6,
  SPFX_BOTTLER_EQUIPMENT_REPAIR_REPORT = 7,
  ADMIN_APP = 8,
  COKE_SERVICES_APP = 9
}
