import moment from "moment";
import "moment-timezone";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Icon, Message } from "semantic-ui-react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { DateRangePicker } from "react-dates";
import ReactTable from "react-table";
import "react-dates/initialize";
import "react-table/react-table.css";
import "react-dates/lib/css/_datepicker.css";
// components
import Band from "../../components/layout/Band";
import BarTooltip from "./BarTooltip";
import BottlerSwitcher from "../../components/switcher/BottlerSwitcher";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import H1 from "../../components/typography/H1";
import Hr from "../../components/typography/Hr";
import Permissions from "../permissions/Permissions";
import RefreshGroup from "../../components/refresh/Group";
import Section from "../../components/layout/Section";
import Select from "../../components/input/Select";
// enums
import { RequestTypes } from "../../enums/RequestTypes";
// helpers
import { sanitizeCsvString } from "../../helpers/csv";
import zipcelx, { Row, Data, Sheet, Config, Cell } from "../../helpers/zipcelx";
import { outletHours, createdAtIso, createdAtDate, createdAtTime } from "../../helpers/equipmentRepair";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// services
import { setBottlerCreator } from "../../core/bottler/bottler";
import { getServiceRequestsCreator } from "../../core/notify/reports/serviceRequests";
import { getEquipmentRepairRequestTotals } from "../../services/EquipmentRepairRequest";
// types
import { Bottler } from "../../types/Bottler";
import { IServiceRequest } from "../../types/ServiceRequest";
import { IReactTableColumn, UsageReportsColumns } from "../../types/Column";
import { ITimezoneOption, DaylightSavingsTimezoneOptions, StandardTimezoneOptions } from "../../types/Timezone";
import { IUsageTotal } from "../../types/UsageTotal";
import { IEquipmentRepairRequestTotal } from "../../types/EquipmentRepairRequest";
import { Apps } from "../../types/App";

export interface IBottlerTotalsReportsProps {
  appId: number;
  bottler: Bottler;
  bottlers: Bottler[];
  config: string;
  getUsageTotalsByProgramCreator: any;
  getServiceRequestsCreator: any;
  loading: boolean;
  search: string;
  setBottlerCreator: any;
  serviceRequests: IServiceRequest[];
  user: any;
}

export interface IBottlerTotalsReportsState {
  columns: IReactTableColumn[];
  end: moment.Moment;
  endIso: string | null; // ISO string with timezone
  focusedInput: any;
  message: string;
  start: moment.Moment;
  startIso: string | null; // ISO string with timezone
  timezone: string;
  timezoneOffset: string;
  timezones: ITimezoneOption[];
  usageTotal: IUsageTotal[];
  usageTotalLoading: boolean;
}

class BottlerTotalsReports extends React.Component<IBottlerTotalsReportsProps, IBottlerTotalsReportsState> {
  public constructor(props: IBottlerTotalsReportsProps) {
    super(props);

    const timezones = this._getTimezones();

    this.state = {
      columns: [],
      end: moment(),
      endIso: null,
      focusedInput: null,
      message: "",
      start: moment().subtract(29, "days"),
      startIso: null,
      timezone: timezones[0].timezone,
      timezoneOffset: timezones[0].value,
      timezones: timezones,
      usageTotal: [],
      usageTotalLoading: false
    };
  }

  public componentDidMount(): void {
    const columns = this._getColumns();
    const endIso = this._getDefaultEndIso();
    const startIso = this._getDefaultStartIso();

    this.setState(
      {
        columns,
        endIso,
        startIso
      },
      () => {
        this._getUsage();
        this._getServiceRequests();
      }
    );
  }

  public componentDidUpdate(prevProps: any): void {
    const { appId, bottler, bottlers } = this.props;

    if (appId !== prevProps.appId || bottler !== prevProps.bottler || bottlers !== prevProps.bottlers) {
      const columns = this._getColumns();
      this.setState({ columns });

      this._getUsage();
      this._getServiceRequests();
    }
  }

  public render() {
    const { bottler, loading, serviceRequests, appId } = this.props;
    const { columns, end, focusedInput, message, start, timezoneOffset, usageTotal, usageTotalLoading } = this.state;
    let appTitle: string = `Bottler App`;
    if (appId === Apps.COKE_SERVICES_APP) {
      appTitle = "Service App";
    }
    const title: string = `Repair Requests: Bottler Totals Reports for ${appTitle}`;

    return (
      <div className="reports">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <RefreshGroup
            refreshId="bottler-total-reports"
            handleRefresh={() => {
              this._getUsage();
              this._getServiceRequests();
            }}
            isLoading={loading}
          />
          <Permissions title={title}>
            <BottlerSwitcher requestTypeId={RequestTypes.EquipmentRepair} showAllOption={true} />
            <LoaderWrapper>
              {usageTotal && (
                <Grid padded={true}>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <div className="usageReportsControls">
                        <div className="usageReportsDatePicker">
                          <DateRangePicker
                            displayFormat={"M/D/YYYY"}
                            endDate={end || moment()}
                            endDateId={"end-date"}
                            focusedInput={focusedInput || null}
                            isOutsideRange={day => moment().diff(day) < 0}
                            onDatesChange={({ startDate, endDate }) => this._handleDateChange(startDate, endDate)}
                            onFocusChange={input => this.setState({ focusedInput: input })}
                            startDate={start || moment().subtract(29, "days")}
                            startDateId={"start-date"}
                          />
                        </div>
                        <div className="usageReportsTimezonePicker">
                          <Select
                            className="selectLargest"
                            id="timezone"
                            name="timezone"
                            onChange={e => this._handleTimezoneChange(e)}
                            options={this._renderTimezoneOptions()}
                            value={timezoneOffset}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>

                  {message && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Message content={message} icon="warning sign" negative={true} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {!loading && !usageTotalLoading && usageTotal.length === 0 && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Message content={"There is no usage for your selected bottler and date range."} icon="warning sign" negative={true} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {!loading && !usageTotalLoading && usageTotal.length !== 0 && start && end && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <ResponsiveContainer width="99%" height={400}>
                          <BarChart width={1200} height={400} data={usageTotal}>
                            <XAxis dataKey="programLabel" />
                            <Tooltip content={<BarTooltip />} />
                            <YAxis />
                            <CartesianGrid vertical={true} />
                            <Bar dataKey="Total" barSize={25} fill="#e51e2b" onClick={this._bottlerBarClick} />
                          </BarChart>
                        </ResponsiveContainer>
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {!loading && !usageTotalLoading && usageTotal.length !== 0 && start && end && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <button className="ui grey large basic button" onClick={e => this._downloadBottlerTotals(e)} type="button">
                          <Icon name="file excel outline" />
                          Download {bottler && bottler.name ? bottler.name : "All"} Totals
                        </button>
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {!loading && !usageTotalLoading && start && end && serviceRequests && serviceRequests.length === 0 && bottler && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Hr />
                        <Message content={"There are no service requests for your selected bottler and date range."} icon="warning sign" negative={true} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {!loading && !usageTotalLoading && start && end && serviceRequests && serviceRequests.length !== 0 && bottler && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <ReactTable data={serviceRequests} columns={columns} showPageSizeOptions={false} showPageJump={false} defaultPageSize={10} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {!loading && !usageTotalLoading && start && end && serviceRequests && serviceRequests.length !== 0 && bottler && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <button className="ui grey large basic button" onClick={e => this._downloadServiceRequests(e)} type="button">
                          <Icon name="file excel outline" />
                          Download {bottler && bottler.name ? bottler.name : ""} Requests
                        </button>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              )}
              <Loader loading={loading || usageTotalLoading} position="Top" showImage={true} type="Overlay" />
            </LoaderWrapper>
          </Permissions>
        </Section>
      </div>
    );
  }

  private _getServiceRequests = (): void => {
    const { endIso, startIso } = this.state;
    const { bottler, appId } = this.props;

    // only get service requests if a bottler is chosen
    if (bottler) {
      if (endIso && startIso) {
        const end: string = moment(endIso).format();
        const start: string = moment(startIso).format();

        this.props.getServiceRequestsCreator(
          `/api/v4/equipmentRepairRequestsList?bottlerId=${bottler.id}&appId=${appId}&startDate=${start}&endDate=${end}`,
          "equipmentRepairRequests"
        );
        this.setState({
          message: ""
        });
      } else {
        this.setState({
          message: "Please pick a start date and end date."
        });
      }
    }
  };

  private _getUsage = (): void => {
    const { bottler, bottlers, appId } = this.props;
    const { end, start } = this.state;

    if (bottler || (bottlers && bottlers.length > 0)) {
      this.setState(
        {
          usageTotal: [],
          usageTotalLoading: true
        },
        async () => {
          if (bottler) {
            try {
              const response = await getEquipmentRepairRequestTotals(bottler.id, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"), appId);
              const usageTotal = this._mapUsage(response.totals);

              this.setState({
                message: "",
                usageTotal,
                usageTotalLoading: false
              });
            } catch (error) {
              this.setState({
                message: error.message || "Error loading totals.",
                usageTotal: [],
                usageTotalLoading: false
              });
            }
          } else {
            try {
              const bottlerIds: number[] = bottlers.map(_bottler => {
                return _bottler.id;
              });

              const response = await getEquipmentRepairRequestTotals(bottlerIds, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"), appId);
              const usageTotal = this._mapUsage(response.totals);
              this.setState({
                message: "",
                usageTotal,
                usageTotalLoading: false
              });
            } catch (error) {
              this.setState({
                message: error.message || "Error loading totals.",
                usageTotal: [],
                usageTotalLoading: false
              });
            }
          }
        }
      );
    }
  };

  private _mapUsage = (totals: IEquipmentRepairRequestTotal[]): IUsageTotal[] => {
    const mapped: IUsageTotal[] = [];

    totals.map(total => {
      mapped.push({
        programDescription: total.bottlerName,
        programId: total.bottlerCode,
        programLabel: total.bottlerCode,
        Total: total.total
      });
    });

    mapped.sort((a, b) => {
      return a.programLabel > b.programLabel ? 1 : -1;
    });

    return mapped;
  };

  private _downloadServiceRequests = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { bottler, serviceRequests, appId } = this.props;
    const { end, start, timezone } = this.state;

    const header: Row = [];

    header.push(
      {
        value: "UUID",
        type: "string"
      },
      {
        value: "Created On ISO",
        type: "string"
      },
      {
        value: "Date",
        type: "string"
      },
      {
        value: "Time",
        type: "string"
      },
      {
        value: "Created By",
        type: "string"
      },
      {
        value: "Created By Email",
        type: "string"
      },
      {
        value: "Outlet Number",
        type: "string"
      },
      {
        value: "Outlet Name",
        type: "string"
      },
      {
        value: "Outlet Street",
        type: "string"
      },
      {
        value: "Outlet City",
        type: "string"
      },
      {
        value: "Outlet State",
        type: "string"
      },
      {
        value: "Equipment Serial",
        type: "string"
      },
      {
        value: "Contact",
        type: "string"
      },
      {
        value: "Contact Phone",
        type: "string"
      },
      {
        value: "Contact Phone Extension",
        type: "string"
      },
      {
        value: "Equipment Type",
        type: "string"
      },
      {
        value: "Problem Code",
        type: "string"
      },
      {
        value: "Equipment Location",
        type: "string"
      },
      {
        value: "Comments",
        type: "string"
      },
      {
        value: "Hours Available",
        type: "string"
      },
      {
        value: " Days Available",
        type: "string"
      },
      {
        value: "Ticket Number",
        type: "string"
      }
    );
    if (appId === Apps.COKE_SERVICES_APP) {
      header.push(
        {
          value: "Equipment Street",
          type: "string"
        },
        {
          value: "Equipment City",
          type: "string"
        },
        {
          value: "Equipment State",
          type: "string"
        },
        {
          value: "Equipment Postal Code",
          type: "string"
        },
        {
          value: "Request Contact Email",
          type: "string"
        },
        {
          value: "Request Contact Email Notification",
          type: "string"
        },
        {
          value: "Request Contact First Name",
          type: "string"
        },
        {
          value: "Request Contact Last Name",
          type: "string"
        },
        {
          value: "Request Contact Phone",
          type: "string"
        },
        {
          value: "Request Contact Phone Notification",
          type: "string"
        },
        {
          value: "Request Contact Text Notification",
          type: "string"
        },
        {
          value: "Request Contact Role",
          type: "string"
        },
        {
          value: "Employee Name",
          type: "string"
        },
        {
          value: "Employee Email",
          type: "string"
        },
        {
          value: "Employee Phone",
          type: "string"
        },
        {
          value: "Employee Email Notification",
          type: "string"
        },
        {
          value: "Employee Text Notification",
          type: "string"
        }
      );
    }

    const data: Data = [];

    data.push(header);

    serviceRequests.map(serviceRequest => {
      const date: string = createdAtDate(serviceRequest.createdAt, timezone);
      const time: string = createdAtTime(serviceRequest.createdAt, timezone);
      const createdOnIso: string = createdAtIso(serviceRequest.createdAt, timezone);
      const hours: string = outletHours(serviceRequest.from, serviceRequest.to);

      const cell: Cell[] = [];

      cell.push(
        {
          value: serviceRequest.uuid,
          type: "string"
        },
        {
          value: createdOnIso,
          type: "string"
        },
        {
          value: date,
          type: "string"
        },
        {
          value: time,
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.userName),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.userEmail),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletNumber),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletName),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletStreet),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletCity),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletState),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentSerialNumber),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletContact),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletPhone),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletPhoneExtension),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentType),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.problemCodeDescription),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentLocation),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.comment),
          type: "string"
        },
        {
          value: hours,
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.availabilityDays),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentRepairTicketNumber),
          type: "string"
        }
      );
      if (appId === Apps.COKE_SERVICES_APP) {
        cell.push(
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationStreet),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationCity),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationState),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationPostalCode),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactEmail),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactEmailNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactFirstName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactLastName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactPhone),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactPhoneNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactTextNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.contactRoleName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeEmail),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeePhone),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeEmailNotification === null ? null : serviceRequest.employeeEmailNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeTextNotification === null ? null : serviceRequest.employeeTextNotification ? "Yes" : "No"),
            type: "string"
          }
        );
      }

      data.push(cell);
    });

    const filename: string = `${appId === Apps.COKE_SERVICES_APP ? "Services_App_" : "Bottler_App_"}Requests_Report_${bottler ? bottler.code : "All_Bottlers"}_${moment(
      start
    ).format("M-D-YYYY")}_${moment(end).format("M-D-YYYY")}`;

    const sheet: Sheet = {
      data
    };

    const config: Config = {
      filename,
      sheet
    };

    zipcelx(config);
  };

  private _downloadBottlerTotals = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { bottler } = this.props;
    const { end, start, usageTotal } = this.state;

    const header: Row = [
      {
        value: "Bottler",
        type: "string"
      },
      {
        value: "Total",
        type: "string"
      }
    ];

    const data: Data = [];

    data.push(header);

    usageTotal.map(total => {
      data.push([
        {
          value: sanitizeCsvString(total.programLabel),
          type: "string"
        },
        {
          value: sanitizeCsvString(total.Total.toString()),
          type: "number"
        }
      ]);
    });

    const bottlerName: string = bottler ? bottler.code : "All_Bottlers";

    const filename: string = "Bottler_Totals_Report_" + bottlerName + "_" + moment(start).format("M-D-YYYY") + "_" + moment(end).format("M-D-YYYY");

    const sheet: Sheet = {
      data
    };

    const config: Config = {
      filename,
      sheet
    };

    zipcelx(config);
  };

  private _bottlerBarClick = (data: any) => {
    const { bottlers } = this.props;

    bottlers.map(bottler => {
      if (bottler && data && bottler.programId === data.programId) {
        this.props.setBottlerCreator(bottler);
        this._getUsage();
        this._getServiceRequests();
      }
    });
  };

  private _handleDateChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    const { timezoneOffset } = this.state;

    let { endIso, startIso } = this.state;

    if (endDate) {
      const date = moment(endDate).format("YYYY-MM-DD");
      endIso = `${date}T23:59:59${timezoneOffset}`;
    }

    if (startDate) {
      const date = moment(startDate).format("YYYY-MM-DD");
      startIso = `${date}T00:00:00${timezoneOffset}`;
    }

    this.setState(
      {
        end: endDate ? endDate : this.state.end,
        endIso,
        start: startDate ? startDate : this.state.start,
        startIso
      },
      () => {
        this._getUsage();
        this._getServiceRequests();
      }
    );
  };

  private _handleTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target;

    const { timezones } = this.state;

    if (value) {
      let timezone = "";
      timezones.map(_timezone => {
        if (_timezone.value === value) {
          timezone = _timezone.timezone;
        }
      });

      this.setState(
        {
          timezone,
          timezoneOffset: value
        },
        () => {
          const columns = this._getColumns();

          const { timezoneOffset } = this.state;
          let { endIso, startIso } = this.state;

          if (endIso && startIso) {
            endIso = endIso.slice(0, 19);
            endIso = `${endIso}${timezoneOffset}`;

            startIso = startIso.slice(0, 19);
            startIso = `${startIso}${timezoneOffset}`;
          }

          this.setState(
            {
              columns,
              endIso,
              startIso
            },
            () => {
              this._getUsage();
              this._getServiceRequests();
            }
          );
        }
      );
    }
  };

  private _getDefaultStartIso = (): string => {
    const { timezoneOffset } = this.state;

    let startDate = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD");

    startDate = `${startDate}T00:00:00${timezoneOffset}`;

    return startDate;
  };

  private _getDefaultEndIso = (): string => {
    const { timezoneOffset } = this.state;

    let endDate = moment().format("YYYY-MM-DD");

    endDate = `${endDate}T23:59:59${timezoneOffset}`;

    return endDate;
  };

  private _getColumns = (): IReactTableColumn[] => {
    const { appId } = this.props;
    const { timezone } = this.state;

    if (timezone) {
      return UsageReportsColumns(timezone, undefined, appId);
    } else {
      return [];
    }
  };

  private _getTimezones = (): ITimezoneOption[] => {
    if (moment().isDST()) {
      return DaylightSavingsTimezoneOptions;
    } else {
      return StandardTimezoneOptions;
    }
  };

  private _renderTimezoneOptions = (): JSX.Element[] => {
    const { timezones } = this.state;

    return timezones.map(timezone => {
      return (
        <option key={timezone.value} value={timezone.value}>
          {timezone.label}
        </option>
      );
    });
  };
}

export const mapStateToProps = (state: any) => {
  return {
    bottler: state.bottler.bottler,
    bottlers: state.bottlers.bottlers,
    config: state.config.config.requestsApi,
    loading: state.usageTotal.isLoading || state.servicerequests.isLoading ? true : false,
    serviceRequests: state.servicerequests.servicerequests,
    usageTotal: state.usageTotal.usageTotal
  };
};

export default connect(mapStateToProps, {
  getServiceRequestsCreator,
  setBottlerCreator
})(withRouter(withPageLog(BottlerTotalsReports)));
