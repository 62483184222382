import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";

interface IIconButtonProps {
  iconName: string;
  isActive: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
}

const IconButton = ({ isActive, iconName, onClick, title }: IIconButtonProps) => {
  return (
    <button title={title} type="button" className={isActive ? "editorButtonActive" : "editorButton"} onClick={onClick}>
      <Icon iconName={iconName} className="editorIcon" />
    </button>
  );
};

export default IconButton;
