import * as React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
// components
import Page from "../../../components/page/Page";
import ErrorUnauthorized from "../../../components/errors/ErrorUnauthorized";
import BottlerSwitcher from "../../../components/switcher/BottlerSwitcher";
import RequestsList from "./RequestsList";
import TabBar from "../../../components/tabbar/TabBar";
import RequestsKanban from "./RequestsKanban";
import { Hr, P } from "../../../components/typography";
// logging
import { withPageLog } from "../../logging/LogComponentChange";
// types
import { IUser } from "../../../types/User";
import { IBottler } from "../../../types/Bottler";

export interface IRequestsListProps {
  bottler: IBottler;
  user: IUser;
  requestPostPilotFF: boolean;
}

export interface IRequestsListState {
  selectedTab: string;
}

class Requests extends React.Component<IRequestsListProps, IRequestsListState> {
  state = {
    // mobile size, use the grid as default on refresh
    selectedTab: window.innerWidth < 768 || !this.props.requestPostPilotFF ? "Grid" : "Kanban"
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize, true);
    const { selectedTab } = this.state;
    const { requestPostPilotFF } = this.props;
    // maintain the selected Tab throughout the admin
    // mainly used for when you go the edit details page of a selected Request
    // and then click "Requests" to go back
    if (localStorage.getItem("requestsView") !== selectedTab && requestPostPilotFF) {
      return this.setState({
        selectedTab: localStorage.getItem("requestsView") || selectedTab
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize, true);
  }
  handleResize = e => {
    if (e.target.innerWidth < 768 && this.state.selectedTab === "Kanban") {
      localStorage.setItem("requestsView", "Grid");
      this.setState({ selectedTab: "Grid" });
    }
  };
  render() {
    const { user, requestPostPilotFF } = this.props;
    const { selectedTab } = this.state;
    const hasPermission: boolean = user && user.requestTypes && user.requestTypes.length > 0;
    return (
      <Page className="requests" permissions={true} title="Current Requests">
        {!hasPermission && <ErrorUnauthorized errorMessage="You do not have permission to view requests." />}
        {hasPermission && (
          <>
            <P italic={true}>
              Requests created in the last 60 days. For older requests, visit the <Link to="/shared-services/requests-archive">archive</Link>.
            </P>
            <Hr />
            <BottlerSwitcher showAllOption={true} />
            {requestPostPilotFF && (
              <TabBar
                className="requestsTabbar"
                selectedItem={selectedTab}
                handleChange={(tab: string) => {
                  localStorage.setItem("requestsView", tab);
                  this.setState({ selectedTab: tab });
                }}
                items={[{ text: "Kanban", icon: "BacklogBoard" }, { text: "Grid", icon: "Table" }]}
              />
            )}
            {selectedTab === "Grid" && (
              <RequestsList
                {...this.props}
                refreshId="current-requests"
                _createdAtStart={moment()
                  .subtract(60, "days")
                  .toISOString()}
              />
            )}
            {selectedTab === "Kanban" && <RequestsKanban {...this.props} refreshId="current-requests" />}
          </>
        )}
      </Page>
    );
  }
}

export const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    bottler: state.bottler.bottler,
    requestPostPilotFF: state.featureFlags.requestPostPilot
  };
};

export default connect(mapStateToProps)(withPageLog(Requests));
