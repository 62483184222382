import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Dropdown from "../dropdown/Dropdown";

export interface IRequestsSaveProps {
  _saveRequest: any;
  admin: boolean;
  invalid?: number;
  loading: boolean;
  tasks?: number;
  unmatched?: number;
}

export interface IRequestsSaveState {}

export default class RequestsSave extends React.Component<
  IRequestsSaveProps,
  IRequestsSaveState
> {
  public render(): React.ReactElement<IRequestsSaveProps> {
    const disabled: boolean =
      this.props.loading ||
      (this.props.invalid && this.props.invalid !== 0) ||
      (this.props.unmatched && this.props.unmatched !== 0) ||
      (this.props.tasks && this.props.tasks === 0)
        ? true
        : false;
    const save: JSX.Element = (
      <Dropdown
        id="save"
        buttonText={this.props.loading ? "Saving..." : "Save"}
        buttonClass={"requestSaveButton"}
        buttonIcon="Save"
        disabled={disabled}
      >
        {!this.props.admin && (
          <button
            type="submit"
            className={"requestSaveDropdownButton"}
            disabled={disabled}
            onClick={e => this.props._saveRequest(e, 1)} // Draft
          >
            <Icon iconName="CircleRing" /> Draft
          </button>
        )}
        <button
          type="submit"
          className={"requestSaveDropdownButton"}
          disabled={disabled}
          onClick={e => this.props._saveRequest(e, 2)} // New
        >
          <Icon iconName="Completed" /> New
        </button>
        <button
          type="submit"
          className={"requestSaveDropdownButton"}
          disabled={disabled}
          onClick={e => this.props._saveRequest(e, 3)} // BSNA Processing
        >
          <Icon iconName="Settings" /> BSNA Processing
        </button>
        <button
          type="submit"
          className={"requestSaveDropdownButton"}
          disabled={disabled}
          onClick={e => this.props._saveRequest(e, 4)} // Completed
        >
          <Icon iconName="CompletedSolid" /> Complete Request
        </button>
      </Dropdown>
    );
    return <div className="requestsSave">{save}</div>;
  }
}
