import { Apps } from "../../../types/App";

export interface IColumn {
  appIds: number[];
  check?: string; // export
  id: string;
  keys: string[]; // export, order is important
  sort: boolean;
  sortBy: string; // used for sorting
  sortMobile: boolean; // add to mobile select dropdown for sorting
  text: string; // mobile text label
  title: string; // desktop table th text
  type: "boolean" | "datetime" | "number" | "string"; // export
}

export const columns: IColumn[] = [
  {
    appIds: [Apps.COKE_BOTTLER_APP],
    id: "loginCreated",
    keys: ["createdAt"],
    sort: true,
    sortBy: "[login].[createdAt]",
    sortMobile: true,
    text: "Login Created",
    title: "Login Created",
    type: "datetime"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    id: "loginCreated",
    keys: ["createdAt"],
    sort: true,
    sortBy: "[login].[createdAt]",
    sortMobile: true,
    text: "Lookup Created",
    title: "Lookup Created",
    type: "datetime"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    check: "bottler",
    id: "bottler",
    keys: ["bottler", "name"],
    sort: true,
    sortBy: "[bottler].[name]",
    sortMobile: true,
    text: "Bottler",
    title: "Bottler",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP],
    check: "user",
    id: "userName",
    keys: ["user", "name"],
    sort: true,
    sortBy: "[user].[name]",
    sortMobile: true,
    text: "User Name",
    title: "User Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP],
    check: "user",
    id: "userEmail",
    keys: ["user", "email"],
    sort: true,
    sortBy: "[user].[email]",
    sortMobile: true,
    text: "User Email",
    title: "User Email",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP],
    id: "equipmentLookup",
    keys: ["equipmentLookupId"],
    sort: true,
    sortBy: "[login].[equipmentLookupId]",
    sortMobile: true,
    text: "Equipment Lookup",
    title: "Equipment Lookup",
    type: "boolean"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    check: "equipmentLookup",
    id: "equipmentLookupTypeName",
    keys: ["equipmentLookup", "equipmentLookupType", "name"],
    sort: true,
    sortBy: "[equipmentLookup->equipmentLookupType].[name]",
    sortMobile: true,
    text: "Equipment Lookup Type",
    title: "Equipment Lookup Type",
    type: "string"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    id: "request",
    keys: ["requestId"],
    sort: true,
    sortBy: "[login].[requestId]",
    sortMobile: true,
    text: "Request",
    title: "Request",
    type: "boolean"
  },
  {
    appIds: [Apps.COKE_BOTTLER_APP, Apps.COKE_SERVICES_APP],
    check: "request",
    id: "requestType",
    keys: ["request", "requestType", "label"],
    sort: true,
    sortBy: "[request->requestType].[label]",
    sortMobile: true,
    text: "Request Type",
    title: "Request Type",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    check: "request",
    id: "requestContactFirstName",
    keys: ["request", "requestContact", "firstName"],
    sort: true,
    sortBy: "[request->requestContact].[firstName]",
    sortMobile: true,
    text: "Request Contact First Name",
    title: "Request Contact First Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    check: "request",
    id: "requestContactLastName",
    keys: ["request", "requestContact", "lastName"],
    sort: true,
    sortBy: "[request->requestContact].[lastName]",
    sortMobile: true,
    text: "Request Contact Last Name",
    title: "Request Contact Last Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    check: "request",
    id: "requestContactEmail",
    keys: ["request", "requestContact", "email"],
    sort: true,
    sortBy: "[request->requestContact].[email]",
    sortMobile: true,
    text: "Request Contact Email",
    title: "Request Contact Email",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    check: "request",
    id: "employeeName",
    keys: ["request", "requestEmployee", "employeeName"],
    sort: true,
    sortBy: "[request->requestEmployee].[employeeName]",
    sortMobile: true,
    text: "Employee Name",
    title: "Employee Name",
    type: "string"
  },
  {
    appIds: [Apps.COKE_SERVICES_APP],
    check: "request",
    id: "employeeEmail",
    keys: ["request", "requestEmployee", "employeeEmail"],
    sort: true,
    sortBy: "[request->requestEmployee].[employeeEmail]",
    sortMobile: true,
    text: "Employee Email",
    title: "Employee Email",
    type: "string"
  }
];
