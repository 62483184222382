import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createHashHistory } from "history";
import moment from "moment";
// components
import Alert from "../../components/alert/Alert";
import Band from "../../components/layout/Band";
import Cards from "../../components/card/Cards";
import H1 from "../../components/typography/H1";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Loader from "../../components/loader/Loader";
import Permissions from "../permissions/Permissions";
import RequestCard from "../../components/card/RequestCard";
import Section from "../../components/layout/Section";
import Switcher from "../../components/switcher/Switcher";
// services
import { getDashboard } from "../../services/Request";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// redux
import { setStatusFilter } from "../../core/filters/filters";
import { setBottlerCreator } from "../../core/bottler/bottler";
// types
import { IRequestDashboard } from "../../types/Request";
import { IUser } from "../../types/User";

type DaysOption = {
  days: number;
  label: string;
};

export interface IDashboardProps {
  setStatusFilter: Function;
  match: any;
  requestPostPilotFF: boolean;
  search: any;
  setBottlerCreator: any;
  user: IUser;
}

export interface IDashboardState {
  days: DaysOption;
  error: boolean;
  errorMessage: string;
  isLoading: boolean;
  stats: IRequestDashboard | null;
}

const daysOptions: DaysOption[] = [
  {
    days: 1,
    label: "Created In The Last Day"
  },
  {
    days: 7,
    label: "Created In The Last 7 Days"
  },
  {
    days: 30,
    label: "Created In The Last 30 Days"
  },
  {
    days: 60,
    label: "Created In The Last 60 Days"
  }
];

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);

    this.state = {
      days: daysOptions[3], // 60 days
      error: false,
      errorMessage: "",
      isLoading: true,
      stats: null
    };
  }

  componentDidMount(): void {
    this.getStats();

    localStorage.removeItem("requestedFilter");
    localStorage.removeItem("requestPriorityFilter");
  }

  getStats = async (): Promise<void> => {
    try {
      const { user } = this.props;
      const { days } = this.state;

      const requestTypes: number[] = [];

      if (user && user.requestTypes) {
        user.requestTypes.map(requestType => {
          requestTypes.push(requestType.RequestTypeID);
        });
      }

      const stats: IRequestDashboard = await getDashboard(days.days, requestTypes);

      this.setState({
        isLoading: false,
        stats
      });
    } catch (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        isLoading: false
      });
    }
  };

  handleDaysChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value: string = e.target.value;

    let days: DaysOption = daysOptions[3];

    daysOptions.map(option => {
      if (Number(value) === option.days) {
        days = option;
      }
    });

    this.setState(
      {
        days: days,
        isLoading: true
      },
      this.getStats
    );
  };

  sendToRequests = (e: React.MouseEvent<HTMLAnchorElement>, status: number | null, requestPriorityFilter?: string): void => {
    e.preventDefault();

    const { days } = this.state;

    // set the bottler switch to all when clicking on one of the dashboard links
    // since the dashboard stats show stats for all bottlers
    this.props.setBottlerCreator(null);

    if (status) {
      this.props.setStatusFilter(status);
    }

    if (requestPriorityFilter) {
      localStorage.setItem("requestPriorityFilter", requestPriorityFilter);
    }
    localStorage.setItem("requestsView", "Grid");
    localStorage.setItem(
      "requestedFilter",
      moment()
        .subtract(days.days, "days")
        .toISOString()
    );

    const history = createHashHistory();
    history.push("/shared-services/requests");
  };

  public render(): React.ReactElement<IDashboardProps> {
    const { days, error, errorMessage, isLoading, stats } = this.state;
    const { requestPostPilotFF } = this.props;

    const options: JSX.Element[] = daysOptions.map(option => {
      return (
        <option key={option.days} value={option.days}>
          {option.label}
        </option>
      );
    });

    const title: string = "Service Requests Home";

    return (
      <div className="dashboard">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <LoaderWrapper>
              <Switcher
                headingText="Switch Stats:"
                labelText="Switch Stats"
                id="daysChange"
                onChange={e => this.handleDaysChange(e)}
                options={options}
                value={days.days.toString()}
              />
              {!error && (
                <Cards
                  cards={[
                    requestPostPilotFF && (
                      <RequestCard
                        key="high-priority"
                        amount={stats && stats.highPriority > 1000 ? "999+" : stats && stats.highPriority < 1000 ? stats.highPriority : 0}
                        type="High Priority"
                        timeLabel={days.label}
                        onClick={e => this.sendToRequests(e, null, "2")}
                      />
                    ),
                    <RequestCard
                      key="new"
                      amount={stats && stats.new > 1000 ? "999+" : stats && stats.new < 1000 ? stats.new : 0}
                      type="Submitted"
                      timeLabel={days.label}
                      onClick={e => this.sendToRequests(e, 2)}
                    />,
                    <RequestCard
                      key="processing"
                      amount={stats && stats.processing > 1000 ? "999+" : stats && stats.processing < 1000 ? stats.processing : 0}
                      type="Processing"
                      timeLabel={days.label}
                      onClick={e => this.sendToRequests(e, 3)}
                    />,
                    <RequestCard
                      key="completed"
                      amount={stats && stats.completed > 1000 ? "999+" : stats && stats.completed < 1000 ? stats.completed : 0}
                      type="Completed"
                      timeLabel={days.label}
                      onClick={e => this.sendToRequests(e, 4)}
                    />
                  ].filter(Boolean)}
                  className={requestPostPilotFF ? "dashboardCards" : "dashboard3Cards"}
                />
              )}
              {error && <Alert show={true} text={errorMessage} type="Error" />}
              <Loader loading={isLoading} type="Overlay" position="Top" showImage={true} />
            </LoaderWrapper>
          </Permissions>
        </Section>
      </div>
    );
  }
}

export const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    requestPostPilotFF: state.featureFlags.requestPostPilot
  };
};

export default withRouter(connect(
  mapStateToProps,
  { setBottlerCreator, setStatusFilter }
)(withPageLog(Dashboard)) as any);
