import * as React from "react";
import { connect } from "react-redux";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// arrays
import { columns } from "./SelfServiceColumns";
import { sorting } from "../shared-services/Sorting";
// components
import Alert from "../../components/alert/Alert";
import BottlerSwitcher from "../../components/switcher/BottlerSwitcher";
import Button from "../../components/button/Button";
import FilterContains from "../../components/filter/FilterContains";
import Input from "../../components/input/Input";
import Select from "../../components/input/Select";
import Label from "../../components/label/Label";
import Band from "../../components/layout/Band";
import Section from "../../components/layout/Section";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Pagination from "../../components/pagination/Pagination";
import Permissions from "../permissions/Permissions";
import H1 from "../../components/typography/H1";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import TBody from "../../components/table/TBody";
import Td from "../../components/table/Td";
import Th from "../../components/table/Th";
import THead from "../../components/table/THead";
import Tr from "../../components/table/Tr";
import Sort from "../../components/table/Sort";
// fetch
import { getSelfServiceRequests, processServiceRequest } from "../../services/Request";
// helpers
import { shortFriendlyDateTime } from "../../helpers/time";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// types
import { IBottler } from "../../types/Bottler";
import { IFilter } from "../../types/Filter";
import { IUser } from "../../types/User";
import { ISelfServiceRequest, ISelfServiceRequestList } from "../../types/SelfServiceRequest";

export interface ISelfServiceListProps {
  bottlers: IBottler[];
  bottler: IBottler | null;
  user: IUser;
  search: string;
}

export interface ISelfServiceListState {
  count: number;
  filter: IFilter[];
  loading: boolean;
  page: number;
  requests: ISelfServiceRequest[];
  search: string;
  searching: boolean;
  sortBy: string;
  sortOrder: string;
}

class SelfServiceRequestsList extends React.Component<ISelfServiceListProps, ISelfServiceListState> {
  timer;
  searchInput;

  state = {
    count: 0,
    filter: [],
    loading: true,
    page: 0,
    requests: [],
    search: "",
    searching: false,
    sortBy: "ssr.createdAt",
    sortOrder: "DESC"
  } as ISelfServiceListState;

  async componentDidMount(): Promise<void> {
    const { search, sortBy, sortOrder, page } = this.state;

    this.setupFilters();
    this.getRequests(search, sortBy, sortOrder, page);
  }

  componentDidUpdate(prevProps: ISelfServiceListProps): void {
    const { bottler } = this.props;
    const { search, sortBy, sortOrder, page } = this.state;

    if (bottler !== prevProps.bottler) {
      this.setupFilters();
      this.getRequests(search, sortBy, sortOrder, page);
    }
  }

  render() {
    const { count, loading, search, searching, sortOrder, sortBy, requests } = this.state;

    const gridSorter: JSX.Element[] = [];
    columns.map(column => {
      if (column.sortBy) {
        sorting.map(sort => {
          gridSorter.push(
            <option value={column.sortBy + " " + sort.database} key={column.sortBy + " " + sort.database}>
              {column.text + " " + sort.text}
            </option>
          );
        });
      }
    });

    const headerColumns: JSX.Element[] = columns.map((column, index) => {
      let ascending: boolean = column.sortBy === sortBy && sortOrder === "ASC" ? true : false;
      let descending: boolean = column.sortBy === sortBy && sortOrder === "DESC" ? true : false;
      let sort: JSX.Element | null =
        column.sortBy && count > 1 ? (
          <Sort text={column.title} ascending={ascending} descending={descending} onClick={e => this.handleSort(e, column.sortBy)} disabled={false} />
        ) : null;
      let filter: JSX.Element | null = null;
      if (column.filter && this.state.filter) {
        this.state.filter.map(f => {
          if (f.filterColumn === column.filter) {
            filter = (
              <FilterContains
                _filter={e => this.handleFilter(e)}
                _filterEnter={e => this.handleFilterEnter(e)}
                _filterSearch={e => this.filterSearch(e, index)}
                _filterBy={e => this.filterBy(e, index)}
                _undoFilter={e => this.undoFilter(e, index)}
                filter={f}
                filterBy={f.filterBy}
                filterDisabled={(count < 2 && !f.filtered) || (!f.filterBy || !f.filterSearch) ? true : false}
                filterSearch={f.filterSearch}
                filtered={f.filtered ? f.filtered : false}
                id={column.filter}
                position={column.filterPosition}
                text={column.title}
                undoDisabled={f.filterBy || f.filterSearch ? false : true}
              />
            );
          }
        });
      }
      return (
        <Th hidden={false} text={column.text} title={column.title} key={index}>
          {sort}
          {filter}
        </Th>
      );
    });
    const rows: JSX.Element[] = [];
    if (requests && requests.length > 0) {
      requests.map((request, index) => {
        let requestId: JSX.Element = (
          <Td title={undefined}>
            <p className="label">ID</p>
            {request.id}
          </Td>
        );
        let bottler: JSX.Element = (
          <Td title={undefined}>
            <p className="label">Bottler</p>
            {request.bottler}
          </Td>
        );
        let requestedBy: JSX.Element = (
          <Td title={undefined}>
            <p className="label">Requester</p>
            {request.name}
          </Td>
        );
        let email: JSX.Element = (
          <Td title={undefined}>
            <p className="label">Email</p>
            {request.email}
          </Td>
        );
        let group: JSX.Element = (
          <Td title={undefined}>
            <p className="label">Requested Group</p>
            {request.requestGroup}
          </Td>
        );
        let dateRequested: JSX.Element = (
          <Td title={undefined}>
            <p className="label">Requested</p>
            {shortFriendlyDateTime(request.createdAt)}
          </Td>
        );
        let actions: JSX.Element = (
          <Td title="Actions">
            <span className="button buttonLinkSuccessColor" onClick={() => this.processRequest(true, request)}>
              <span className="actionButton buttonIcon buttonIconLeft">
                <Icon iconName="BoxCheckmarkSolid" />
              </span>
              Accept
            </span>
            <span className="button buttonLinkPrimaryColor" onClick={() => this.processRequest(false, request)}>
              <span className="actionButton buttonIcon buttonIconLeft">
                <Icon iconName="BoxMultiplySolid" />
              </span>
              Reject
            </span>
          </Td>
        );
        rows.push(
          <Tr hover={true} striped={true} error={false} key={index}>
            {requestId}
            {bottler}
            {requestedBy}
            {email}
            {group}
            {dateRequested}
            {actions}
          </Tr>
        );
      });
    } else {
      rows.push(
        <Tr key="no-results">
          <Td colSpan={columns.length}>
            <Alert show={true} type="Info">
              There are no requests. Switch your Bottler or{" "}
              <a href={"#"} onClick={e => this.clearSearch(e)}>
                undo your search and filters
              </a>
              .
            </Alert>
          </Td>
        </Tr>
      );
    }

    const title: string = "Self-Service Requests";

    return (
      <div className="requestList">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <div>
              <BottlerSwitcher showAllOption={true} />
              <div className="search">
                <Label htmlFor={"search"} text={"Search Requests"} srOnly={true} />
                <Input
                  autoFocus={true}
                  value={search}
                  disabled={count < 2 && !searching}
                  id="search"
                  inputType="search"
                  name="search"
                  onChange={this.handleSearchInput}
                  onKeyUp={this.handleSearchEnter}
                  placeholder="Search requests..."
                  refs={input => {
                    this.searchInput = input;
                  }}
                />
                <Button
                  className="buttonInfo"
                  disabled={count < 2 && !searching}
                  hideText={true}
                  iconLeft={<Icon iconName="Search" />}
                  onClick={this.handleSearchButton}
                  text="Search Requests"
                  title="Search Requests"
                />
              </div>
              <div className="gridSorter">
                <Label htmlFor="grid-sorter" text="Sort Requests By" />
                <Select
                  defaultValue={`${sortBy} ${sortOrder}`}
                  id="grid-sorter"
                  name="grid-sorter"
                  onChange={this.handleMobileSort}
                  options={gridSorter}
                />
              </div>
              <LoaderWrapper>
                <TableWrapper>
                  <Table>
                    <THead>
                      <Tr>{headerColumns}</Tr>
                    </THead>
                    <TBody>{rows}</TBody>
                  </Table>
                </TableWrapper>
                <Pagination
                  count={count}
                  loading={loading}
                  page={this.state.page + 1}
                  perPage={10}
                  paginate={(e, page) => this.handlePaginate(e, page)}
                />
                <Loader
                  loading={loading || searching}
                  type="Overlay"
                  showImage={true}
                  text={searching ? "Searching..." : "Loading..."}
                  position="Top"
                />
              </LoaderWrapper>
            </div>
          </Permissions>
        </Section>
        <ToastContainer />
      </div>
    );
  }

  getRequests = (search: string, sortBy: string, sortOrder: string, page: number): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        if (this.props.user) {
          let id = this.props.bottler !== null ? this.props.bottler.id : 0;

          const filter: IFilter[] = this.state.filter.slice();
          const filters: IFilter[] = [];
          filter.map(f => {
            if (f.filterBy && f.filterColumn && f.filterSearch) {
              filters.push({
                filterBy: f.filterBy,
                filterColumn: f.filterColumn,
                filterSearch: f.filterSearch
              });
              f.filtered = true;
            } else {
              f.filtered = false;
            }
          });

          try {
            const requests: ISelfServiceRequestList = await getSelfServiceRequests(id, filters, page, sortBy, sortOrder, search);

            if (requests.error) {
              toast.error(requests.error, {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                count: 0,
                requests: [],
                loading: false,
                page: 0,
                searching: false,
                search: "",
                sortBy: "ssr.createdAt",
                sortOrder: "DESC"
              });
            } else {
              this.setState({
                count: requests.count,
                requests: requests.rows,
                loading: false,
                page: page,
                searching: false,
                search: search,
                sortBy: sortBy,
                sortOrder: sortOrder
              });
            }
          } catch (error) {
            toast.error(error.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              count: 0,
              requests: [],
              loading: false,
              page: 0,
              searching: false,
              search: "",
              sortBy: "ssr.id",
              sortOrder: "DESC"
            });
          }
        }
      }
    );
  };

  handlePaginate = (e: any, page: number): void => {
    e.preventDefault();
    this.getRequests(this.state.search, this.state.sortBy, this.state.sortOrder, page - 1);
  };

  search = (search: string): void => {
    this.setState({ searching: true }, () => {
      this.getRequests(search, this.state.sortBy, this.state.sortOrder, 0);
    });
  };

  handleSearchInput = (e: any): void => {
    e.preventDefault();
    const search: string = e.target.value;
    clearTimeout(this.timer);
    this.setState(
      {
        search: search
      },
      () => {
        this.timer = setTimeout(() => {
          this.search(this.state.search);
        }, 300);
      }
    );
  };

  handleSearchButton = (e: any): void => {
    e.preventDefault();
    const search: string = this.searchInput.value;
    this.search(search);
  };

  handleSearchEnter = (e: any): void => {
    e.preventDefault();
    const search: string = this.searchInput.value;
    if (e.key === "Enter") {
      this.search(search);
    }
  };

  clearSearch = (e: any): void => {
    e.preventDefault();
    this.search("");
    this.searchInput.value = "";
  };

  handleSort = (e: any, sortBy: string): void => {
    e.preventDefault();
    this.getRequests(this.state.search, sortBy, this.state.sortOrder === "DESC" ? "ASC" : "DESC", 0);
  };

  handleMobileSort = (e: any): void => {
    e.preventDefault();
    const value: string = e.target.value;
    const sortBy: string = value.split(" ")[0];
    const sortOrder: string = value.split(" ")[1];
    this.getRequests(this.state.search, sortBy, sortOrder, 0);
  };

  setupFilters = (): void => {
    const filter: IFilter[] = [];
    columns.map(column => {
      if (column.filter) {
        filter.push({
          filterBy: "",
          filterColumn: column.filter,
          filterSearch: ""
        });
      }
    });
    this.setState({
      filter: filter
    });
  };

  filterSearch = (e: any, filterIndex: number): void => {
    e.preventDefault();
    const value: string = e.target.value;
    const filter: IFilter[] = this.state.filter.slice();
    filter[filterIndex].filterSearch = value;
    this.setState({
      filter: filter
    });
  };

  filterBy = (e: any, filterIndex: number): void => {
    e.preventDefault();
    const value: string = e.target.value;
    const filter: IFilter[] = this.state.filter.slice();
    filter[filterIndex].filterBy = value;
    this.setState({
      filter: filter
    });
  };

  handleFilter = (e: any): void => {
    e.preventDefault();
    this.getRequests(this.state.search, this.state.sortBy, this.state.sortOrder, this.state.page);
  };

  handleFilterEnter = (e: any): void => {
    e.preventDefault();
    if (e.keyCode === 13) {
      this.getRequests(this.state.search, this.state.sortBy, this.state.sortOrder, this.state.page);
    }
  };

  undoFilter = (e: any, filterIndex: number): void => {
    e.preventDefault();
    const filter: IFilter[] = this.state.filter.slice();
    filter[filterIndex].filterBy = "";
    filter[filterIndex].filterSearch = "";
    this.setState(
      {
        filter: []
      },
      () => {
        this.setState(
          {
            filter: filter
          },
          () => {
            this.getRequests(this.state.search, this.state.sortBy, this.state.sortOrder, this.state.page);
          }
        );
      }
    );
  };

  processRequest = (isApproved: boolean, request: ISelfServiceRequest): void => {
    this.setState({ loading: true });
    processServiceRequest(isApproved, request)
      .then((result: boolean) => {
        if (result) {
          this.setState(
            {
              loading: true
            },
            () => {
              this.getRequests(this.state.search, this.state.sortBy, this.state.sortOrder, this.state.page);
            }
          );
        } else {
          toast.error("Error during Self Service processing. Please try again.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
      .catch((error: Error) => {
        toast.error(error.message ? error.message : "Error during Self Service processing. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      });
  };
}

export default connect((state: any) => ({
  bottlers: state.bottlers.bottlers,
  bottler: state.bottler.bottler,
  user: state.user.user,
  search: state.requestsearch.search
}))(withPageLog(SelfServiceRequestsList));
