import { getRequest, postRequest, deleteRequest } from "../core/api/requestConfig";
// types
import { IAdGroupAdminAppRouteCreate, IAdGroupAdminAppRouteResponse, IAdGroupAdminAppRoutesResponse } from "../types/AdGroupAdminAppRoutes";
import { IResponseDeleted } from "../types/Response";

export function getAdGroupAdminAppRouteByAdminAppRouteId(adminAppRouteId: number): Promise<IAdGroupAdminAppRoutesResponse> {
  return new Promise<IAdGroupAdminAppRoutesResponse>((resolve, reject) => {
    getRequest(`/api/v1/adGroupAdminAppRoutesByAdminAppRouteId?adminAppRouteId=${adminAppRouteId}`)
      .then((response: IAdGroupAdminAppRoutesResponse) => {
        return resolve(response);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function createAdGroupAdminAppRoute(create: IAdGroupAdminAppRouteCreate): Promise<IAdGroupAdminAppRouteResponse> {
  return new Promise<IAdGroupAdminAppRouteResponse>((resolve, reject) => {
    postRequest(`/api/v1/adGroupAdminAppRoutes`, create)
      .then((response: IAdGroupAdminAppRouteResponse) => {
        return resolve(response);
      })
      .catch((error: IAdGroupAdminAppRouteResponse) => {
        return reject(error);
      });
  });
}

export function deleteAdGroupAdminAppRoute(id: number): Promise<IResponseDeleted> {
  return new Promise<IResponseDeleted>((resolve, reject) => {
    deleteRequest(`/api/v1/adGroupAdminAppRoutes/${id}`)
      .then((response: IResponseDeleted) => {
        return resolve(response);
      })
      .catch((error: IResponseDeleted) => {
        return reject(error);
      });
  });
}
