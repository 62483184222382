import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Button from "../button/Button";

export interface IModalFooterProps {
  cancelDisabled?: boolean;
  cancelIcon?: string;
  cancelText: string;
  cancelTitle: string;
  footer?: JSX.Element | null;
  onCancel?: any;
  onSave?: any;
  saveButtonType?: "button" | "submit";
  saveDisabled?: boolean;
  saveIcon?: string;
  saveText?: string;
  saveTitle?: string;
  showCancel: boolean;
  showSave: boolean;
}
export interface IModalFooterState {}

export default class ModalFooter extends React.Component<IModalFooterProps, IModalFooterState> {
  public render(): React.ReactElement<IModalFooterProps> {
    const {
      cancelText,
      cancelTitle,
      footer,
      onCancel,
      onSave,
      saveButtonType,
      saveDisabled,
      saveIcon,
      saveText,
      saveTitle,
      showCancel,
      showSave
    } = this.props;

    return (
      <div className={"modalFooter"}>
        {footer}
        {showSave && (
          <Button
            buttonType={saveButtonType ? saveButtonType : "button"}
            className="buttonSave"
            iconLeft={saveIcon ? <Icon iconName={saveIcon} /> : undefined}
            text={saveText ? saveText : ""}
            title={saveTitle ? saveTitle : ""}
            disabled={saveDisabled}
            onMouseDown={onSave}
          />
        )}
        {showCancel && <Button className="buttonWhite" text={cancelText} title={cancelTitle} onMouseDown={onCancel} />}
      </div>
    );
  }
}
