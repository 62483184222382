import * as React from "react";
import AsyncSelect from "react-select/lib/Async";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { toast } from "react-toastify";
// components
import AddUsersList from "./AddUsersList";
import Alert from "../../components/alert/Alert";
import { P, H3, H4 } from "../../components/typography";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import Label from "../../components/label/Label";
import Loader from "../../components/loader/Loader";
import Textarea from "../../components/input/Textarea";
// services
import { removeUserFromAdGroup, addUserToGroup } from "../../services/AdGroup";
import { getAdUsersv2 } from "../../services/AdUsers";
import { IMsAdUser } from "../../services/AdUsers";
import { inviteAdUser } from "../../services/User";
// types
import { IAdGroup } from "../../types/AdGroup";
import { IAdInvite, IAdInviteResponse, AdInviteStatuses, AdInviteUserTypes } from "../../types/AdInvite";
import { IMSGraphUserEnhanced } from "../../types/User";
// helpers
import { delay } from "../../helpers/delay";
import { formatName, formatEmail, formatUserName } from "../../helpers/format";

export interface IManagerGroupUsersProps {
  adGroup: IAdGroup | null;
  adUsers: IMsAdUser[];
  bottlerDomains: string[];
  isFetchingAdUsers: boolean;
  getAdGroup: (adGroupId: string, saved: boolean) => void;
  key?: string;
  isLoading: boolean;
  usersHeading?: string;
}

export interface IManagerGroupUsersState {
  adding: boolean;
  adUsers: IMsAdUser[];
  message: string;
  msGraphUsers: IMSGraphUserEnhanced[];
  msGraphSearching: boolean;
  permission: boolean;
  removing: boolean;
  redirectUrl: string;
  redirectUrlDirty: boolean;
  saving: boolean;
  showInviteInputs: boolean;
}

class ManagerGroupUsers extends React.PureComponent<IManagerGroupUsersProps, IManagerGroupUsersState> {
  DEFAULT_INVITE_REDIRECT_URL: string = "https://coke-bsna.com/clientbottlers/";
  DEFAULT_INVITE_STATUS: AdInviteStatuses = "Completed";
  DEFAULT_INVITE_USER_TYPE: AdInviteUserTypes = "Guest";

  constructor(props: IManagerGroupUsersProps) {
    super(props);
    this.state = {
      adding: false,
      adUsers: props.adUsers,
      message: "",
      msGraphUsers: [],
      msGraphSearching: false,
      permission: false,
      redirectUrl: "",
      redirectUrlDirty: false,
      removing: false,
      saving: false,
      showInviteInputs: false
    };
  }

  componentDidUpdate(prevProps: IManagerGroupUsersProps) {
    const { adUsers } = this.props;

    if (prevProps.adUsers.length !== adUsers.length) {
      this.setState({ adUsers });
    }
  }

  render() {
    const { adGroup, bottlerDomains, isFetchingAdUsers, isLoading, usersHeading } = this.props;
    const { adding, adUsers, message, msGraphUsers, msGraphSearching, redirectUrl, redirectUrlDirty, removing, saving, showInviteInputs } = this.state;

    return (
      <>
        {!adGroup && !isLoading && <Alert type="Error" show={true} key="noAdGroup" text="Couldn't find AD group." />}
        {adGroup && (
          <div key={"body"}>
            <div className="addNewUsersMeta">
              {usersHeading && <H3 className="addNewUsersNameInterpretation">{usersHeading}</H3>}
              {!usersHeading && adGroup.nameFriendly && (
                <>
                  <H3 className="addNewUsersNameInterpretation">{adGroup.nameFriendly}</H3>
                  <P className="addNewUsersDescription" italic={true}>
                    {adGroup.description}
                  </P>
                </>
              )}
            </div>
            <div className="addNewUsersContainer">
              <div className="flex-grid-2">
                <div className="col addNewUsersWrapper">
                  <H4 className="addNewUsersHeading">
                    <Icon iconName="AddFriend" /> Add Users{adGroup.nameFriendlyLast ? ` To ${adGroup.nameFriendlyLast}` : ``}
                  </H4>

                  <P className="addNewUsersCallout">
                    <Icon iconName="Info" />
                    Only users with the domain{bottlerDomains.length > 1 ? `s` : ``}
                    {` `}
                    {bottlerDomains.map((bottlerDomain, index) => {
                      return `${bottlerDomain}${index + 1 !== bottlerDomains.length ? ` or ` : ``}`;
                    })}
                    {` `}
                    may be added.
                  </P>

                  <div>
                    <div className="addNewUsersInput">
                      <AsyncSelect
                        cacheOptions={false}
                        className="reactSelect"
                        classNamePrefix="reactSelect"
                        controlShouldRenderValue={false}
                        defaultOptions={false}
                        hideSelectedOptions={false}
                        inputId="add-users-to-group"
                        isDisabled={msGraphSearching}
                        isLoading={msGraphSearching}
                        isOptionDisabled={option => this.renderDisabledOption(option)}
                        loadOptions={this.getAdUsers}
                        loadingMessage={inputValue => "Searching..."}
                        maxMenuHeight={140}
                        noOptionsMessage={inputValue => "Start typing an email to search..."}
                        onChange={(value: any) => this.addMsGraphUser(value)}
                        pageSize={5}
                        placeholder={"Search by email..."}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  {msGraphUsers.length > 0 && (
                    <div className="addNewUsersAdded">
                      <ul className="addNewUsersAddedList">
                        <AddUsersList removing={removing} msGraphUsers={msGraphUsers} handleSendEmailChange={this.handleSendEmailChange} removeUser={this.removeMsGraphUser} />
                      </ul>
                    </div>
                  )}

                  {showInviteInputs && (
                    <div className="addNewUsersInputs">
                      <div className="flex-grid-2">
                        <div className="col">
                          <div>
                            <Label htmlFor="message" text="Message" />
                            <Textarea
                              id="message"
                              name="message"
                              onChange={e =>
                                this.setState({
                                  message: e.target.value
                                })
                              }
                              value={message}
                            />
                            <P className="helpText">The helpful text users will see in the email invite.</P>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <Label htmlFor="redirectUrl" text="Redirect URL" />
                            <Input
                              id="redirectUrl"
                              name="redirectUrl"
                              onChange={e =>
                                this.setState({
                                  redirectUrl: e.target.value,
                                  redirectUrlDirty: true
                                })
                              }
                              value={redirectUrlDirty ? redirectUrl : this.DEFAULT_INVITE_REDIRECT_URL}
                            />
                            <P className="helpText">The web page users will be sent to after they accept the email invite.</P>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {msGraphUsers.length > 0 && (
                    <div className="addNewUsersSave">
                      <Button
                        className="buttonSuccess"
                        disabled={msGraphUsers.length === 0 || saving || msGraphSearching || adding || removing}
                        onClick={e => this.addUsersToGroup(adGroup.azureGroupId)}
                        text={showInviteInputs ? "Save And Invite New Users" : "Save New Users"}
                        title={showInviteInputs ? "Save And Invite New Users" : "Save New Users"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <P className="exisitingUsersCount">
              <Icon iconName="People" /> <strong>{adUsers && adUsers.length ? adUsers.length : 0} </strong>
              <span className="colorGray">users in this group</span>
            </P>

            <div className="Rtable">
              <div className="Rtable-row">
                <span className="Rtable-cell Rtable-cell--header wide-cell">
                  <Icon iconName="Contact" /> Name
                </span>
                <span className="Rtable-cell Rtable-cell--header wide-cell">
                  <Icon iconName="Mail" /> Email
                </span>
                <span className="Rtable-cell Rtable-cell--header narrow-cell">
                  <Icon iconName="SetAction" /> Action
                </span>
              </div>
              {isFetchingAdUsers && <Loader loading={isFetchingAdUsers} position="Centered" showImage={true} text="Loading users..." type="Inline" />}
              {!isFetchingAdUsers &&
                adUsers &&
                adUsers.map(adUser => {
                  return (
                    <div className="Rtable-row" key={adUser.id}>
                      <span className="Rtable-cell wide-cell">{adUser.displayName}</span>
                      <span className="Rtable-cell wide-cell">{adUser.mail}</span>
                      <span className="Rtable-cell narrow-cell">
                        {adUser.id && (
                          <Button
                            className="buttonOutlinePrimary"
                            disabled={removing}
                            iconLeft={<Icon iconName="ChromeClose" />}
                            onClick={e => this.removeUserFromAdGroup(adGroup.azureGroupId, adUser.id)}
                            text="Remove User"
                            title="Remove User"
                          />
                        )}
                      </span>
                    </div>
                  );
                })}
              {!isFetchingAdUsers && adUsers.length === 0 && <Alert type="Info" show={true} key="noAdUsers" text="There are no users in this group." />}
            </div>
          </div>
        )}
      </>
    );
  }

  renderDisabledOption = (option: IMSGraphUserEnhanced): boolean => {
    const { adUsers } = this.props;
    const { msGraphUsers }: IManagerGroupUsersState = this.state;

    let disabled: boolean = false;

    if (adUsers && adUsers.length > 0) {
      adUsers.map(adUser => {
        if (option.displayName === adUser.displayName || option.mail === adUser.mail || option.userPrincipalName === adUser.userPrincipalName) {
          disabled = true;
        }
      });
    }

    if (msGraphUsers && msGraphUsers.length > 0) {
      msGraphUsers.map(msGraphUser => {
        if (option.displayName === msGraphUser.displayName || option.mail === msGraphUser.mail || option.userPrincipalName === msGraphUser.userPrincipalName) {
          disabled = true;
        }
      });
    }

    return disabled;
  };

  addMsGraphUser = (msGraphUser: IMSGraphUserEnhanced): void => {
    // react-select will send an empty array when cleared
    if (msGraphUser && msGraphUser.id) {
      this.setState(
        {
          adding: true
        },
        () => {
          const msGraphUsers = this.state.msGraphUsers.slice();

          let duplicate: boolean = false;
          msGraphUsers.map(user => {
            if (user.id === msGraphUser.id) {
              duplicate = true;
            }
          });

          if (!duplicate) {
            msGraphUsers.push(msGraphUser);
          }

          this.setState({
            adding: false,
            msGraphUsers
          });
        }
      );
    }
  };

  removeUserFromAdGroup = (azureGroupId: string, azureUserId: string | null): void => {
    const result: boolean = window.confirm("Are you sure you want to remove this user from the AD group?");

    if (result && azureUserId) {
      this.setState(
        {
          removing: true
        },
        async () => {
          try {
            await removeUserFromAdGroup(azureGroupId, azureUserId);
            await delay(300); // give AD time to update after remove

            this.setState(
              {
                removing: false
              },
              () => {
                // we need to refresh everything in order to update react-select
                this.props.getAdGroup(azureGroupId, false);
              }
            );

            toast.success("User removed from group successfully.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
          } catch (error) {
            toast.error("Error removing user from group.", {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState({
              removing: false
            });
          }
        }
      );
    }
  };

  getAdUsers = async (inputValue: string, callback: Function): Promise<void> => {
    let msGraphUsers: IMSGraphUserEnhanced[] = [];

    // TODO: Add debounce

    if (inputValue) {
      try {
        const { bottlerDomains } = this.props;

        const response = await getAdUsersv2(inputValue, bottlerDomains);

        if (response.adUsers && response.adUsers.length) {
          msGraphUsers = response.adUsers;

          msGraphUsers.map(msGraphUser => {
            const name = formatName(msGraphUser.displayName, msGraphUser.givenName, msGraphUser.surname);
            const email = formatEmail(msGraphUser.mail, msGraphUser.userPrincipalName);

            msGraphUser.label = `${name} (${email})`;
            msGraphUser.value = msGraphUser.id;
          });
        } else {
          msGraphUsers.push({
            displayName: ``,
            givenName: null,
            id: ``,
            invite: true,
            label: `No users found.`,
            mail: ``,
            sendEmail: false,
            surname: null,
            userPrincipalName: ``,
            value: ``
          });

          const username: string = formatUserName(inputValue);

          bottlerDomains.map(domain => {
            const email: string = `${username}@${domain}`;

            msGraphUsers.push({
              displayName: `${username}`,
              givenName: null,
              id: `${email}`,
              invite: true,
              label: `Invite ${email}.`,
              mail: `${email}`,
              sendEmail: false,
              surname: null,
              userPrincipalName: `${email}`,
              value: `${email}`
            });
          });
        }

        callback(msGraphUsers);

        this.setState({
          msGraphSearching: false
        });
      } catch (error) {
        toast.error("Error searching Graph API for users.", {
          position: toast.POSITION.BOTTOM_CENTER
        });

        this.setState({
          msGraphSearching: false
        });
      }
    }
  };

  addUsersToGroup(azureGroupId: string): void {
    const msGraphUsers = this.state.msGraphUsers.slice();

    if (msGraphUsers && msGraphUsers.length) {
      this.setState(
        {
          saving: true
        },
        async () => {
          const { message, redirectUrl } = this.state;
          try {
            await Promise.all(
              msGraphUsers.map(async msGraphUser => {
                const name = formatName(msGraphUser.displayName, msGraphUser.givenName, msGraphUser.surname);
                const email = formatEmail(msGraphUser.mail, msGraphUser.userPrincipalName);

                if (msGraphUser.invite) {
                  const invite: IAdInvite = {
                    invitedUserDisplayName: name,
                    invitedUserEmailAddress: email,
                    inviteRedirectUrl: redirectUrl ? redirectUrl : this.DEFAULT_INVITE_REDIRECT_URL,
                    invitedUserMessageInfo: message ? { customizedMessageBody: message } : undefined,
                    invitedUserType: this.DEFAULT_INVITE_USER_TYPE,
                    status: this.DEFAULT_INVITE_STATUS,
                    sendInvitationMessage: msGraphUser.sendEmail ? true : false
                  };

                  const invited: IAdInviteResponse = await inviteAdUser(invite);

                  if (invited.invited) {
                    const added: boolean = await addUserToGroup(
                      azureGroupId,
                      invited.invited.invitedUser.id,
                      invited.invited.invitedUserDisplayName,
                      invited.invited.invitedUserEmailAddress
                    );

                    if (added) {
                      msGraphUsers.map((addedUser, index) => {
                        if (
                          invited.invited &&
                          (invited.invited.invitedUserEmailAddress === addedUser.mail || invited.invited.invitedUserEmailAddress === addedUser.userPrincipalName)
                        ) {
                          msGraphUsers.splice(index, 1);
                        }
                      });
                    } else {
                      toast.error(`Error adding ${email} to group. Please try saving again.`, {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    }
                  } else {
                    if (invited.error && invited.error.message) {
                      toast.error(invited.error.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    } else {
                      toast.error(`Error adding ${msGraphUser.userPrincipalName} to group. Please try saving again.`, {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    }
                  }
                } else {
                  const added: boolean = await addUserToGroup(azureGroupId, msGraphUser.id, name, email);

                  if (added) {
                    msGraphUsers.map((addedUser, index) => {
                      if (msGraphUser.id === addedUser.id) {
                        msGraphUsers.splice(index, 1);
                      }
                    });
                  } else {
                    toast.error(`Error adding ${email} to group. Please try saving again.`, {
                      position: toast.POSITION.BOTTOM_CENTER
                    });
                  }
                }
              })
            );

            if (msGraphUsers.length === 0) {
              this.setState(
                {
                  saving: false,
                  showInviteInputs: false,
                  msGraphUsers
                },
                () => {
                  this.props.getAdGroup(azureGroupId, true);
                }
              );
            } else {
              this.setState(
                {
                  saving: false,
                  msGraphUsers
                },
                () => {
                  toast.error("Error saving users to group. Please try saving again.", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                }
              );
            }
          } catch (error) {
            toast.error("Error saving users to group. Please try saving again.", {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState({
              saving: false,
              msGraphUsers
            });
          }
        }
      );
    }
  }

  removeMsGraphUser = (index: number): void => {
    this.setState(
      {
        removing: true
      },
      () => {
        const msGraphUsers = this.state.msGraphUsers.slice();

        msGraphUsers.splice(index, 1);

        this.setState({
          msGraphUsers,
          removing: false
        });
      }
    );
  };

  handleSendEmailChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const checked: boolean = e.target.checked;
    let showInviteInputs: boolean = false;

    const msGraphUsers = this.state.msGraphUsers.slice();

    msGraphUsers[index].sendEmail = checked;

    if (checked) {
      showInviteInputs = true;
    } else {
      msGraphUsers.map(msGraphUser => {
        if (msGraphUser.sendEmail) {
          showInviteInputs = true;
        }
      });
    }

    this.setState({
      msGraphUsers,
      showInviteInputs
    });
  };
}

export default ManagerGroupUsers;
