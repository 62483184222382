import { IRequestErrorGetResponse, IRequestErrorGetFilter } from "../types/RequestError";
import { getRequest } from "../core/api/requestConfig";

/**
 * Get request errors from SQL
 * @returns IRequestErrorGetResponse
 */
export const getRequestErrors = async (filter?: IRequestErrorGetFilter): Promise<IRequestErrorGetResponse> => {
  let query: string = ``;

  if (filter) {
    query += `?`;

    if (filter.appId) {
      query += `appId=${filter.appId}&`;
    }
    if (filter.bottlerId) {
      query += `bottlerId=${filter.bottlerId}&`;
    }

    if (filter.createdAtEnd) {
      query += `createdAtEnd=${filter.createdAtEnd}&`;
    }

    if (filter.createdAtStart) {
      query += `createdAtStart=${filter.createdAtStart}&`;
    }

    if (filter.errorCode) {
      query += `errorCode=${filter.errorCode}&`;
    }

    if (filter.limit !== undefined) {
      query += `limit=${filter.limit}&`;
    }

    if (filter.offset !== undefined) {
      query += `offset=${filter.offset}&`;
    }

    if (filter.search) {
      query += `search=${filter.search}&`;
    }

    if (filter.sortBy) {
      query += `sortBy=${filter.sortBy}&`;
    }

    if (filter.sortOrder) {
      query += `sortOrder=${filter.sortOrder}&`;
    }
  }

  try {
    const result = await getRequest(`/api/v1/requestErrors${query}`);
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};
