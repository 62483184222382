import * as React from "react";
import moment from "moment";
// components
import { AutoSizer, Column, Table } from "react-virtualized";
import Datepicker from "../input/Datepicker";
import Input from "../input/Input";
import Select from "../input/Select";
import Label from "../label/Label";
import Modal from "../modal/Modal";
import Alert from "../alert/Alert";
// enums
import { breakpoints } from "../../enums/breakpoints";
// types
import { ICurrencyType } from "../../types/CurrencyType";
import { IPayArea } from "../../types/PayArea";
import { IRequestType } from "../../types/RequestType";
import { Row } from "../../types/Task";
import { IWageType } from "../../types/WageType";

export interface IMergingProps {
  _cancelMerge: any;
  _dateChange: any;
  _inputChange: any;
  _inputBlur: any;
  _openMerge: any;
  _saveMerge: any;
  currencyTypes: ICurrencyType[];
  currencyTypeOptions: JSX.Element[];
  disabled: boolean;
  mergee: Row[];
  merges: Row[];
  merging: boolean;
  payAreas: IPayArea[];
  payAreaOptions: JSX.Element[];
  requestType: IRequestType;
  wageTypes: IWageType[];
  wageTypeOptions: JSX.Element[];
}
export interface IMergingState {
  height: number;
  mergesHeight: number;
  mergeeHeight: number;
}

export default class Merging extends React.Component<IMergingProps, IMergingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      height: 45,
      mergesHeight: 200,
      mergeeHeight: 80
    };
    this._datepickerMerges = this._datepickerMerges.bind(this);
    this._inputNumberMerges = this._inputNumberMerges.bind(this);
    this._inputTextMerges = this._inputTextMerges.bind(this);
    this._selectMerges = this._selectMerges.bind(this);
    this._datepickerRenderer = this._datepickerRenderer.bind(this);
    this._inputNumberRenderer = this._inputNumberRenderer.bind(this);
    this._inputTextRenderer = this._inputTextRenderer.bind(this);
    this._selectRenderer = this._selectRenderer.bind(this);
    this._setHeight = this._setHeight.bind(this);
  }
  public componentDidMount(): void {
    window.addEventListener("resize", this._setHeight);
  }

  public componentWillMount(): void {
    this._setHeight();
  }
  public componentWillUnmount(): void {
    window.removeEventListener("resize", this._setHeight);
  }
  public render() {
    const merges: JSX.Element | null =
      this.props.merging && this.props.merges.length ? (
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <Table
              headerHeight={35}
              height={this.state.mergesHeight}
              overscanRowCount={2}
              rowHeight={this.state.height}
              rowCount={this.props.merges.length}
              rowGetter={({ index }) => this.props.merges[index]}
              width={width}
            >
              <Column width={120} label="First" dataKey="firstName" cellRenderer={this._inputTextMerges} />
              <Column width={60} label="MI" dataKey="middleInitial" cellRenderer={this._inputTextMerges} />
              <Column width={120} label="Last" dataKey="lastName" cellRenderer={this._inputTextMerges} />
              <Column width={60} label="Suffix" dataKey="suffix" cellRenderer={this._inputTextMerges} />
              <Column width={105} label="Nickname" dataKey="nickname" cellRenderer={this._inputTextMerges} />
              <Column width={120} label="PERNR" dataKey="pernr" cellRenderer={this._inputTextMerges} />
              <Column width={120} label="Cost Center" dataKey="costCenter" cellRenderer={this._inputTextMerges} />
              <Column width={120} label="Pay Area" dataKey="payAreaId" cellRenderer={this._selectMerges} />
              <Column width={100} label="Wage Type" dataKey="wageTypeId" cellRenderer={this._selectMerges} />
              {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                <Column width={90} label="Hours" dataKey="hours" cellRenderer={this._inputNumberMerges} />
              ) : null}
              {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                <Column width={90} label="Number" dataKey="number" cellRenderer={this._inputNumberMerges} />
              ) : null}
              <Column width={90} label="Units" dataKey="units" cellRenderer={this._inputNumberMerges} />
              <Column width={135} label="Date" dataKey="dateOfPay" cellRenderer={this._datepickerMerges} flexGrow={0} />
              <Column width={120} label="Amount" dataKey="amount" cellRenderer={this._inputNumberMerges} />
              <Column width={100} label="Currency" dataKey="currencyTypeId" cellRenderer={this._selectMerges} />
              {this.props.requestType && this.props.requestType.code === "IT0014" ? (
                <Column width={120} label="Dollar Limit" dataKey="dollarLimit" cellRenderer={this._inputNumberMerges} />
              ) : null}
              {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                <Column width={90} label="Rate" dataKey="rate" cellRenderer={this._inputNumberMerges} />
              ) : null}
              <Column width={120} label="Authority" dataKey="authority" cellRenderer={this._inputTextMerges} />
              <Column width={120} label="Comment" dataKey="comment" cellRenderer={this._inputTextMerges} flexGrow={1} />
            </Table>
          )}
        </AutoSizer>
      ) : null;
    const mergee: JSX.Element | null =
      this.props.merging && this.props.mergee.length ? (
        <AutoSizer disableHeight={true}>
          {({ width }) => (
            <Table
              headerHeight={35}
              height={this.state.mergeeHeight}
              overscanRowCount={0}
              rowHeight={this.state.height}
              rowCount={this.props.mergee.length}
              rowGetter={({ index }) => this.props.mergee[index]}
              width={width}
            >
              <Column width={120} label="First" dataKey="firstName" cellRenderer={this._inputTextRenderer} />
              <Column width={60} label="MI" dataKey="middleInitial" cellRenderer={this._inputTextRenderer} />
              <Column width={120} label="Last" dataKey="lastName" cellRenderer={this._inputTextRenderer} />
              <Column width={60} label="Suffix" dataKey="suffix" cellRenderer={this._inputTextRenderer} />
              <Column width={105} label="Nickname" dataKey="nickname" cellRenderer={this._inputTextRenderer} />
              <Column width={120} label="PERNR" dataKey="pernr" cellRenderer={this._inputTextRenderer} />
              <Column width={120} label="Cost Center" dataKey="costCenter" cellRenderer={this._inputTextRenderer} />
              <Column width={120} label="Pay Area" dataKey="payAreaId" cellRenderer={this._selectRenderer} />
              <Column width={100} label="Wage Type" dataKey="wageTypeId" cellRenderer={this._selectRenderer} />
              {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                <Column width={90} label="Hours" dataKey="hours" cellRenderer={this._inputNumberRenderer} />
              ) : null}
              {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                <Column width={90} label="Number" dataKey="number" cellRenderer={this._inputNumberRenderer} />
              ) : null}
              <Column width={90} label="Units" dataKey="units" cellRenderer={this._inputNumberRenderer} />
              <Column width={135} label="Date" dataKey="dateOfPay" cellRenderer={this._datepickerRenderer} flexGrow={0} />
              <Column width={120} label="Amount" dataKey="amount" cellRenderer={this._inputNumberRenderer} />
              <Column width={100} label="Currency" dataKey="currencyTypeId" cellRenderer={this._selectRenderer} />
              {this.props.requestType && this.props.requestType.code === "IT0014" ? (
                <Column width={120} label="Dollar Limit" dataKey="dollarLimit" cellRenderer={this._inputNumberRenderer} />
              ) : null}
              {this.props.requestType && this.props.requestType.code === "IT2010" ? (
                <Column width={90} label="Rate" dataKey="rate" cellRenderer={this._inputNumberRenderer} />
              ) : null}
              <Column width={120} label="Authority" dataKey="authority" cellRenderer={this._inputTextRenderer} />
              <Column width={120} label="Comment" dataKey="comment" cellRenderer={this._inputTextRenderer} flexGrow={1} />
            </Table>
          )}
        </AutoSizer>
      ) : null;
    const body: JSX.Element = (
      <div className="merging">
        <p>Merge the following tasks:</p>
        {merges}
        <p>Merged task:</p>
        {mergee}
      </div>
    );
    const footer: JSX.Element = <Alert show={true} icon="Info" text="Merged entries will not be deleted." type="Info" />;
    return (
      <Modal
        id="merge-tasks"
        modalSize="Xxl"
        disabled={this.props.disabled}
        onOpen={this.props._openMerge}
        onSave={this.props._saveMerge}
        onCancel={this.props._cancelMerge}
        heading={"Merge"}
        body={this.props.merging ? [body] : []}
        bodyLoading={false}
        footer={footer}
        openIcon={"Merge"}
        openText={"Merge Tasks"}
        openTitle={"Merge Tasks"}
        cancelIcon={"Cancel"}
        cancelText={"Cancel"}
        cancelTitle={"Cancel Merge"}
        saveDisabled={false}
        saveIcon={"Merge"}
        saveText={"Merge"}
        saveTitle={"Merge"}
        showCancel={true}
        showSave={true}
      />
    );
  }
  private _inputNumberMerges({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-merges-" + rowIndex + "-" + columnIndex;
    const text: string =
      dataKey === "amount"
        ? "Amount"
        : dataKey === "dollarLimit"
        ? "Dollar Limit"
        : dataKey === "number"
        ? "Number"
        : dataKey === "rate"
        ? "Rate"
        : dataKey === "hours"
        ? "Hours"
        : dataKey === "units"
        ? "Units"
        : "";
    const value: string =
      dataKey === "amount"
        ? rowData.data.amount
        : dataKey === "dollarLimit"
        ? rowData.data.dollarLimit
        : dataKey === "number"
        ? rowData.data.number
        : dataKey === "rate"
        ? rowData.data.rate
        : dataKey === "hours"
        ? rowData.data.hours
        : dataKey === "units"
        ? rowData.data.units
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="number"
          min="0"
          name={dataKey}
          onBlur={null}
          onChange={null}
          pattern="[0-9]*"
          readOnly={true}
          step="0.01"
          tabIndex={-1}
          value={value}
        />
      </div>
    );
  }
  private _inputTextMerges({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-merges-" + rowIndex + "-" + columnIndex;
    const text: string =
      dataKey === "firstName"
        ? "First Name"
        : dataKey === "middleInitial"
        ? "Middle Initial"
        : dataKey === "lastName"
        ? "Last Name"
        : dataKey === "suffix"
        ? "Suffix"
        : dataKey === "nickname"
        ? "Nickname"
        : dataKey === "pernr"
        ? "PERNR"
        : dataKey === "costCenter"
        ? "Cost Center"
        : dataKey === "authority"
        ? "Authority"
        : dataKey === "comment"
        ? "Comment"
        : "";
    const value: string =
      dataKey === "firstName"
        ? rowData.data.firstName
        : dataKey === "middleInitial"
        ? rowData.data.middleInitial
        : dataKey === "lastName"
        ? rowData.data.lastName
        : dataKey === "suffix"
        ? rowData.data.suffix
        : dataKey === "nickname"
        ? rowData.data.nickname
        : dataKey === "pernr"
        ? rowData.data.pernr
        : dataKey === "costCenter"
        ? rowData.data.costCenter
        : dataKey === "authority"
        ? rowData.data.authority
        : dataKey === "comment"
        ? rowData.data.comment
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="text"
          name={dataKey}
          onBlur={null}
          onChange={null}
          readOnly={true}
          tabIndex={-1}
          value={value}
        />
      </div>
    );
  }
  private _selectMerges({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = true;
    const id: string = dataKey + "-merges-" + rowIndex + "-" + columnIndex;
    const value: string =
      dataKey === "wageTypeId" && rowData.data.wageTypeId
        ? rowData.data.wageTypeId
        : dataKey === "currencyTypeId" && rowData.data.currencyTypeId
        ? rowData.data.currencyTypeId
        : dataKey === "payAreaId" && rowData.data.payAreaId
        ? rowData.data.payAreaId
        : "";
    if (dataKey === "wageTypeId") {
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Wage Type" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={null}
            onChange={null}
            options={this.props.wageTypeOptions}
            placeholder={"Select..."}
            value={value}
          />
        </div>
      );
    } else if (dataKey === "currencyTypeId") {
      return (
        <div>
          <Label htmlFor={id} srOnlyAt={breakpoints.md} text="Currency" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={null}
            onChange={null}
            options={this.props.currencyTypeOptions}
            placeholder={"Select..."}
            value={value ? value : "1"} // default to USD
          />
        </div>
      );
    } else {
      // dataKey === "payAreaId"
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Pay Area" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={null}
            onChange={null}
            options={this.props.payAreaOptions}
            placeholder={"Select..."}
            value={value}
          />
        </div>
      );
    }
  }

  private _datepickerMerges({ rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = true;
    const value: Date | null = rowData.data.dateOfPay ? moment(rowData.data.dateOfPay, "YYYY-MM-DD").toDate() : null;
    return (
      <div>
        <Datepicker disabled={disabled} label="Date" onChange={null} placeholder={""} required={false} value={value} />
      </div>
    );
  }

  private _inputNumberRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const readOnly: boolean = false;
    const tabIndex: number = 0;
    const text: string =
      dataKey === "amount"
        ? "Amount"
        : dataKey === "dollarLimit"
        ? "Dollar Limit"
        : dataKey === "number"
        ? "Number"
        : dataKey === "rate"
        ? "Rate"
        : dataKey === "hours"
        ? "Hours"
        : dataKey === "units"
        ? "Units"
        : "";
    const value: string =
      dataKey === "amount"
        ? rowData.data.amount
        : dataKey === "dollarLimit"
        ? rowData.data.dollarLimit
        : dataKey === "number"
        ? rowData.data.number
        : dataKey === "rate"
        ? rowData.data.rate
        : dataKey === "hours"
        ? rowData.data.hours
        : dataKey === "units"
        ? rowData.data.units
        : "";
    return (
      <div>
        <Label htmlFor={id} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          id={id}
          inputType="number"
          min="0"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowIndex, "merge")}
          onChange={e => this.props._inputChange(e, rowIndex, "merge")}
          pattern="[0-9]*"
          readOnly={readOnly}
          step="0.01"
          tabIndex={tabIndex}
          value={value}
        />
      </div>
    );
  }
  private _inputTextRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const autoFocus: boolean = rowIndex === 0 && dataKey === "firstName";
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const text: string =
      dataKey === "firstName"
        ? "First Name"
        : dataKey === "middleInitial"
        ? "Middle Initial"
        : dataKey === "lastName"
        ? "Last Name"
        : dataKey === "suffix"
        ? "Suffix"
        : dataKey === "nickname"
        ? "Nickname"
        : dataKey === "pernr"
        ? "PERNR"
        : dataKey === "costCenter"
        ? "Cost Center"
        : dataKey === "authority"
        ? "Authority"
        : dataKey === "comment"
        ? "Comment"
        : "";
    const readOnly: boolean = false;
    const required: boolean =
      dataKey === "firstName" || dataKey === "lastName" || dataKey === "pernr" || dataKey === "costCenter" || dataKey === "authority" ? true : false;
    const showValidation: boolean =
      (dataKey === "firstName" && !rowData.firstNameValid && !rowData.data.firstName) ||
      (dataKey === "lastName" && !rowData.lastNameValid && !rowData.data.lastName) ||
      (dataKey === "pernr" && !rowData.pernrValid && !rowData.data.pernr) ||
      (dataKey === "costCenter" && !rowData.costCenterValid && !rowData.data.costCenter) ||
      (dataKey === "authority" && !rowData.costCenterValid && !rowData.data.authority) ||
      (dataKey === "pernr" && rowData.isDuplicate)
        ? true
        : false;
    const tabIndex: number = 0;
    const value: string =
      dataKey === "firstName"
        ? rowData.data.firstName
        : dataKey === "middleInitial"
        ? rowData.data.middleInitial
        : dataKey === "lastName"
        ? rowData.data.lastName
        : dataKey === "suffix"
        ? rowData.data.suffix
        : dataKey === "nickname"
        ? rowData.data.nickname
        : dataKey === "pernr"
        ? rowData.data.pernr
        : dataKey === "costCenter"
        ? rowData.data.costCenter
        : dataKey === "authority"
        ? rowData.data.authority
        : dataKey === "comment"
        ? rowData.data.comment
        : "";
    return (
      <div>
        <Label htmlFor={id} required={required} srOnlyAt={breakpoints.md} text={text} />
        <Input
          autoComplete="off"
          autoCorrect="off"
          autoFocus={autoFocus}
          id={id}
          inputType="text"
          name={dataKey}
          onBlur={e => this.props._inputBlur(e, rowIndex, "merge")}
          onChange={e => this.props._inputChange(e, rowIndex, "merge")}
          readOnly={readOnly}
          required={required}
          showRequired={required}
          showValidation={showValidation}
          tabIndex={tabIndex}
          value={value}
        />
      </div>
    );
  }
  private _selectRenderer({ columnIndex, dataKey, rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = false;
    const id: string = dataKey + "-" + rowIndex + "-" + columnIndex;
    const value: string =
      dataKey === "wageTypeId" && rowData.data.wageTypeId
        ? rowData.data.wageTypeId
        : dataKey === "currencyTypeId" && rowData.data.currencyTypeId
        ? rowData.data.currencyTypeId
        : dataKey === "payAreaId" && rowData.data.payAreaId
        ? rowData.data.payAreaId
        : "";
    if (dataKey === "wageTypeId") {
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Wage Type" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowIndex, "merge")}
            onChange={e => this.props._inputChange(e, rowIndex, "merge")}
            options={this.props.wageTypeOptions}
            placeholder={"Select..."}
            required={true}
            showPlaceholder={true}
            showRequired={true}
            showValidation={!rowData.wageTypeIdValid && !rowData.data.wageTypeId ? true : false}
            value={value}
          />
        </div>
      );
    } else if (dataKey === "currencyTypeId") {
      return (
        <div>
          <Label htmlFor={id} srOnlyAt={breakpoints.md} text="Currency" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowIndex, "merge")}
            onChange={e => this.props._inputChange(e, rowIndex, "merge")}
            options={this.props.currencyTypeOptions}
            placeholder={"Select..."}
            value={value ? value : "1"} // default to USD
          />
        </div>
      );
    } else {
      // dataKey === "payAreaId"
      return (
        <div>
          <Label htmlFor={id} required={true} srOnlyAt={breakpoints.md} text="Pay Area" />
          <Select
            disabled={disabled}
            id={id}
            name={dataKey}
            onBlur={e => this.props._inputBlur(e, rowIndex, "merge")}
            onChange={e => this.props._inputChange(e, rowIndex, "merge")}
            options={this.props.payAreaOptions}
            placeholder={"Select..."}
            required={true}
            showPlaceholder={true}
            showRequired={true}
            showValidation={!rowData.payAreaIdValid || (!rowData.payAreaIdValid && !rowData.data.payAreaId) ? true : false}
            value={value}
          />
        </div>
      );
    }
  }
  private _datepickerRenderer({ rowData, rowIndex }: any): JSX.Element {
    const disabled: boolean = false;
    const value: Date | null = rowData.data.dateOfPay ? moment(rowData.data.dateOfPay, "YYYY-MM-DD").toDate() : null;
    return (
      <div>
        <Datepicker
          disabled={disabled}
          label="Date"
          onChange={(date: Date) => this.props._dateChange(date, rowIndex, "merge")}
          placeholder={""}
          required={false}
          value={value}
        />
      </div>
    );
  }
  private _setHeight(): void {
    if (window.innerWidth < 1024) {
      if (this.props.requestType && this.props.requestType.code === "IT2010") {
        this.setState({
          height: 1100,
          mergesHeight: 300,
          mergeeHeight: 1100
        });
      } else if (this.props.requestType && this.props.requestType.code === "IT0014") {
        this.setState({
          height: 950,
          mergesHeight: 300,
          mergeeHeight: 950
        });
      } else {
        this.setState({
          height: 870,
          mergesHeight: 300,
          mergeeHeight: 870
        });
      }
    } else {
      this.setState({
        height: 45,
        mergesHeight: 200,
        mergeeHeight: 80
      });
    }
  }
}
