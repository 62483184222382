import * as React from "react";
// components
import AdGroupsList from "./AdGroupsList";
import Band from "../../components/layout/Band";
import H1 from "../../components/typography/H1";
import Permissions from "../permissions/Permissions";
import Section from "../../components/layout/Section";
// enums
import { Bottlers } from "../../enums/Bottler";
// logging
import { withPageLog } from "../logging/LogComponentChange";

interface IAdminManagementProps {}

export const AdminManagement: React.StatelessComponent<IAdminManagementProps> = () => {
  const title: string = "BSNA Admin Management";

  return (
    <div className="groupMembership adminManagememnt">
      <Band>
        <H1>{title}</H1>
      </Band>
      <Section>
        <Permissions title={title}>
          <AdGroupsList
            bottlerCode={["BSNA-AAD-RequestAdmin"]}
            bottlerDomains={["coke-bsna.com", "ccbss.com"]}
            bottlerId={Bottlers.Bsna}
            showAddUsersMessage={true}
            title={title}
          />
        </Permissions>
      </Section>
    </div>
  );
};

export default withPageLog(AdminManagement);
