import * as React from "react";

export interface IButtonProps {
  buttonType?: "button" | "submit";
  className?: string;
  id?: string;
  text: string | JSX.Element | null;
  title: string;
  disabled?: boolean;
  onClick?: any;
  onMouseDown?: any;
  iconLeft?: JSX.Element | null;
  iconRight?: JSX.Element | null;
  hideText?: boolean;
}

class Button extends React.Component<IButtonProps, {}> {
  render() {
    const { buttonType, className, disabled, hideText, iconLeft, iconRight, id, onClick, onMouseDown, text, title } = this.props;

    let cssClass: string = "button";
    if (className) {
      cssClass += " " + className;
    }

    const left: JSX.Element | null = iconLeft ? <span className="buttonIcon buttonIconLeft">{iconLeft}</span> : null;

    const right: JSX.Element | null = iconRight ? <span className="buttonIcon buttonIconRight">{iconRight}</span> : null;

    let buttonText: JSX.Element | null = <span className="buttonText">{text}</span>;

    if (hideText) {
      buttonText = null;
    }

    return (
      <button type={buttonType ? buttonType : "button"} className={cssClass} id={id} onClick={onClick} onMouseDown={onMouseDown} title={title} disabled={disabled}>
        {left}
        {buttonText}
        {right}
      </button>
    );
  }
}

export default Button;
