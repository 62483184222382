import * as React from "react";

export interface IColumnProps {
  breakpoint: "Xxxs" | "Xxs" | "Xs" | "Sm" | "Md" | "Lg" | "Xl" | "Xxl" | "Xxxl" | "Xxxxl";
  columns: 12 | 11 | 10 | 9 | 8 | 7 | 6 | 5 | 4 | 3 | 2 | 1;
}

const Column: React.StatelessComponent<IColumnProps> = ({ breakpoint, children, columns }) => (
  <div className={`column column${breakpoint} column${columns}`}>{children}</div>
);

export default Column;
