import * as React from "react";

class Main extends React.Component {
  public render() {
    return (
      <div className="main" id="main">
        {this.props.children}
      </div>
    );
  }
}

export default Main;
