import * as React from "react";

export interface ICheckboxProps {
  checked: boolean;
  className?: string;
  id: string;
  text: string;
  title?: string;
  name: string;
  disabled?: boolean;
  onChange?: any;
  showLabel?: boolean;
  labelOnClick?: any;
}

export default class Checkbox extends React.Component<ICheckboxProps, {}> {
  public render(): React.ReactElement<ICheckboxProps> {
    const { className } = this.props;

    let cssClass: string = className ? "checkbox " + className : "checkbox";

    let labelCssClass: string = this.props.showLabel === false ? "checkLabel checkLabelSrOnly" : "checkLabel";

    return (
      <div className={cssClass}>
        <input
          className="check"
          id={this.props.id}
          name={this.props.name}
          type="checkbox"
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          title={this.props.title}
        />
        <label className={labelCssClass} htmlFor={this.props.id} onClick={this.props.labelOnClick}>
          <span className={this.props.showLabel === false ? "checkText srOnly" : "checkText"}>{this.props.text}</span>
        </label>
      </div>
    );
  }
}
