import { getRequest, deleteRequest, postRequest, putRequest } from "../core/api/requestConfig";
import { ISlaGetResponse, ISlaGetFilter, ISlaPost, ISlaPostResponse, ISlaPut, ISlaPutResponse } from "../types/Sla";
import { AxiosResponse } from "axios";

export const getSlas = async (filters: ISlaGetFilter): Promise<ISlaGetResponse> => {
  const queryString = Object.keys(filters)
    .map(key => `${key}=${filters[key]}`)
    .join("&");
  try {
    const res = await getRequest(`/api/v1/slas?${queryString}`);
    return Promise.resolve(res);
  } catch (err) {
    console.error("getSlas", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};

export const deleteSlas = async (slaId: number): Promise<ISlaGetResponse> => {
  try {
    const res = await deleteRequest(`/api/v1/slas/${slaId}`);
    return Promise.resolve(res);
  } catch (err) {
    console.error("deleteSlas", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};

export const createSla = async (data: ISlaPost): Promise<ISlaPostResponse> => {
  try {
    const res = await postRequest(`/api/v1/slas`, data);
    return Promise.resolve(res);
  } catch (err) {
    console.error("createSla", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};

export const updateSla = async (id: number, data: ISlaPut): Promise<ISlaPutResponse> => {
  try {
    const res = await putRequest(`/api/v1/slas/${id}`, data);
    return Promise.resolve(res);
  } catch (err) {
    console.error("updateSla", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};
