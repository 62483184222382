import * as React from "react";

export interface ILoaderGradientProps {}

export interface ILoaderGradientState {}

export default class LoaderGradient extends React.Component<
  ILoaderGradientProps,
  {}
> {
  public render(): React.ReactElement<ILoaderGradientProps> {
    return <div className="loaderGradient" />;
  }
}
