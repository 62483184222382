import * as React from "react";

export interface IFootProps {}
export interface IFootState {}

export default class Foot extends React.Component<IFootProps, IFootState> {
  public render(): React.ReactElement<IFootProps> {
    return <div className={"foot"}>{this.props.children}</div>;
  }
}
