import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducers } from "./reducers";

/**
 * STATE MANAGEMENT APPROACH
 *
 * Our approach to handling state to to keep all shared state in the store and
 * map to props using connect()(Component)
 *
 * The exception to this is when working with external components such as UI changes
 * where we will use local component state and then dispatch actions to the store
 *
 */

/**
 * this function creates the only store of the application
 * the purpose of this store to is hold the state of the
 * entire application as an object tree
 * the api functions are subscribe, dispatch, and getState
 */
export const store = createStore(reducers, {}, composeWithDevTools(compose(applyMiddleware(...[thunkMiddleware]))) as any);
