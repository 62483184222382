import * as React from "react";
import Swagger from "./Swagger";
import Permissions from "../permissions/Permissions";

export interface ISwaggerV4Props {}

const SwaggerV4: React.StatelessComponent<ISwaggerV4Props> = ({}) => {
  return (
    <Permissions title={`Swagger v4`}>
      <Swagger version="v4" />
    </Permissions>
  );
};

export default SwaggerV4;
