import * as React from "react";

export interface IFieldProps {
  children: any;
  className?: string;
}

const Field = ({ children, className = "" }: IFieldProps) => {
  return <div className={className ? `field ${className}` : "field"}>{children}</div>;
};

export default Field;
