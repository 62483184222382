import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UsageReports from "./UsageReports";
import BottlerTotalsReports from "./BottlerTotalsReports";
import LoginsV2 from "./Logins/LoginsV2";
import UserAdoption from "./UserAdoption";
import RequestErrors from "./RequestErrors/RequestErrors";
// types
import { Apps } from "../../types/App";

const Notify: React.StatelessComponent = () => {
  return (
    <div className="notify">
      <Switch>
        <Route path="/repair-requests/usage-reports/coke-service-app" render={props => <UsageReports appId={Apps.COKE_SERVICES_APP} />} />
        <Route path="/repair-requests/usage-reports" render={props => <UsageReports appId={Apps.COKE_BOTTLER_APP} />} />
        <Route path="/repair-requests/bottler-totals-reports/coke-service-app" render={props => <BottlerTotalsReports appId={Apps.COKE_SERVICES_APP} />} />
        <Route path="/repair-requests/bottler-totals-reports" render={props => <BottlerTotalsReports appId={Apps.COKE_BOTTLER_APP} />} />
        <Route path="/repair-requests/logins-actions/coke-service-app" render={() => <LoginsV2 appId={Apps.COKE_SERVICES_APP} />} />
        <Route path="/repair-requests/logins-actions" render={() => <LoginsV2 appId={Apps.COKE_BOTTLER_APP} />} />
        <Route path="/repair-requests/user-adoption" component={UserAdoption} />
        <Route path="/repair-requests/request-errors/coke-service-app" render={props => <RequestErrors refreshId="request-errors-refresh" appId={Apps.COKE_SERVICES_APP} />} />
        <Route path="/repair-requests/request-errors" render={props => <RequestErrors refreshId="request-errors-refresh" appId={Apps.COKE_BOTTLER_APP} />} />
        <Redirect to="/repair-requests/usage-reports" />
      </Switch>
    </div>
  );
};

export default Notify;
