import * as React from "react";
import RToggle from "react-toggle";
import "react-toggle/style.css";
import Label from "../label/Label";

interface IToggleProps {
  className?: string;
  handleChange: Function;
  id: string;
  isChecked: boolean;
  label?: string;
  icons?: {
    checked: JSX.Element;
    unchecked: JSX.Element;
  };
}

const Toggle = ({ className, id, label, handleChange, isChecked, icons }: IToggleProps) => {
  return (
    <div className={className ? `toggleButton ${className}` : "toggleButton"}>
      <RToggle id={id} checked={isChecked} onChange={handleChange} icons={icons} />
      {label && <Label className="toggleLabel" htmlFor={id} text={label} />}
    </div>
  );
};

export default Toggle;
