import * as React from "react";
import { NavLink } from "react-router-dom";

export interface ICardProps {
  href?: string;
  header?: JSX.Element | null;
  body?: JSX.Element | null;
  bodyPadding?: boolean;
  footer?: JSX.Element | null;
}
export interface ICardState {}

export default class Card extends React.Component<ICardProps, ICardState> {
  public render(): React.ReactElement<ICardProps> {
    if (this.props.href) {
      return (
        <div className="cardWrapper">
          <NavLink className="card cardLink" to={this.props.href}>
            <div className="cardInner">
              {this.props.header ? (
                <div className="cardHeader">{this.props.header}</div>
              ) : null}
              {this.props.body ? (
                <div className="cardBody">{this.props.body}</div>
              ) : null}
              {this.props.footer ? (
                <div className="cardFooter">{this.props.footer}</div>
              ) : null}
            </div>
          </NavLink>
        </div>
      );
    } else {
      return (
        <div className="cardWrapper">
          <div className="card">
            <div className="cardInner">
              {this.props.header ? (
                <div className="cardHeader">{this.props.header}</div>
              ) : null}
              {this.props.body ? (
                <div className="cardBody">{this.props.body}</div>
              ) : null}
              {this.props.footer ? (
                <div className="cardFooter">{this.props.footer}</div>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}
