import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import Button from "./Button";

interface IRefreshProps {
  onClick: Function;
  isLoading: boolean;
}

interface IRefreshState {
  isRefreshing: boolean;
}

class Refresh extends React.PureComponent<IRefreshProps, IRefreshState> {
  state = {
    isRefreshing: false
  };
  componentDidUpdate(prevProps: IRefreshProps, prevState: IRefreshState) {
    if (
      prevState.isRefreshing &&
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.setState({ isRefreshing: false });
    }
  }
  render() {
    const { onClick, isLoading } = this.props;
    const { isRefreshing } = this.state;
    return (
      <Button
        className="refreshButton"
        disabled={isRefreshing || isLoading}
        onClick={() => {
          this.setState({ isRefreshing: true });
          onClick();
        }}
        iconLeft={
          <Icon
            className={isRefreshing ? "refreshIconSpinning" : "refreshIcon"}
            iconName="Refresh"
          />
        }
        text="Refresh"
        title="Refresh"
      />
    );
  }
}

export default Refresh;
