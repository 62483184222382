import moment from "moment";
import "moment-timezone";

export const SHORT_FRIENDLY: string = "M/D/YYYY h:mm a";
export const SHORT_DATE = "M/D/YYYY";

/**
 * Convert a date to "MMMM D, YYYY" format
 * @param isoString string
 * @returns string
 */
export const fullDate = (isoString: string): string => {
  return moment(isoString).format("MMMM D, YYYY");
};

/**
 * Convert a date to "M/D/YYYY" format
 * @param isoString string
 * @returns string
 */
export const shortDate = (isoString: string): string => {
  return moment(isoString).format("M/D/YYYY");
};

/**
 * Convert a date to "MMMM D, YYYY h:mm a" format
 * @param isoString string
 * @returns string
 */
export const fullFriendlyDateTime = (isoString: string): string => {
  return moment(isoString).format("MMMM D, YYYY h:mm a");
};

/**
 * Convert a date to "M/D/YYYY h:mm a" format
 * @param isoString string
 * @returns string
 */
export const shortFriendlyDateTime = (isoString: string): string => {
  return moment(isoString).format("M/D/YYYY h:mm a");
};

/**
 * Convert a date to "M_D_YYYY_h_mm_a" format
 * @param isoString string
 * @returns string
 */
export const shortFileDateTime = (isoString: string): string => {
  return moment(isoString).format("M_D_YYYY_h_mm_a");
};

/**
 * Convert a date to "h:mm a" format
 * @param isoString string
 * @returns string
 */
export const fullTime = (isoString: string): string => {
  return moment(isoString).format("h:mm a");
};

/**
 *
 * @param isoString string
 * @returns string
 */
export const payrollPeriodDates = (startDate: string, endDate: string, checkDate: string): string => {
  return (
    moment(startDate)
      .utc()
      .format("M/D/YYYY") +
    "–" + // ndash
    moment(endDate)
      .utc()
      .format("M/D/YYYY") +
    " (Check " +
    moment(checkDate)
      .utc()
      .format("M/D/YYYY") +
    ")"
  );
};

/**
 * Get the users timezone
 * @example "America/New_York"
 * @returns string
 */
export const getTimezone = (): string => {
  // support looks good back to IE11: https://caniuse.com/#feat=internationalization
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};
