import * as React from "react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { DragDropContext, Droppable, DropResult, DroppableProvided } from "react-beautiful-dnd";
// components
import Avatar from "../avatar/Avatar";
import Button from "../button/Button";
import KanbanCard from "../kanban/KanbanCard";
import LoadingWrapper from "../loader/LoaderWrapper";
import Loader from "../loader/Loader";
import RequestExport from "../../containers/shared-services/Requests/RequestExport";
// helpers
import { shortFriendlyDateTime } from "../../helpers/time";
// types
import { IRequest } from "../../types/Request";
import { IUser } from "../../types/User";
import { RequestPriorities } from "../../types/RequestPriorities";

type Column = {
  [id: string]: {
    count?: number;
    data?: any;
    isDropDisabled?: boolean;
  };
};

type Heading = {
  id: string;
  text: string;
};

interface IKanbanProps {
  columns: Column;
  columnHeadings: Heading[];
  alert?: JSX.Element;
  handleDragEnd: Function;
  handleUpdate: Function;
  handleLoadMore: (statusId: number, statusLabel: string) => void;
  isLoadingMore: {
    [id: number]: boolean;
  };
  page: {
    [id: number]: number;
  };
}

interface IKanbanState {
  columns: Column | null;
}

// this will be used if we implement sorting within a column
// const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

const reorderColumnMap = ({ columns, source, destination }) => {
  const currentColumn = columns[source.droppableId];
  const nextColumn = columns[destination.droppableId];
  const currentColumnCount = currentColumn.count;
  const nextColumnCount = nextColumn.count;
  const currentData: Column[] = currentColumn.data;
  const nextData: Column[] = nextColumn.data;
  const target: Column = currentData[source.index];

  // moving the cards inside the same column

  // TODO: maybe another story so the user can change the order of the items in a column
  // right now the API enforces sortOrder
  if (source.droppableId === destination.droppableId) {
    // const reordered: Column[] = reorder(
    //   current,
    //   source.index,
    //   destination.index
    // );
    // return {
    //   ...columns,
    //   [source.droppableId]: {
    //     data: reordered
    //   }
    // };
    return columns;
  }
  // moving to different column

  // remove from original
  currentData.splice(source.index, 1);
  // insert into next
  nextData.splice(destination.index, 0, target);

  return {
    ...columns,
    [source.droppableId]: {
      data: currentData,
      count: currentColumnCount - 1
    },
    [destination.droppableId]: {
      data: nextData,
      count: nextColumnCount + 1
    }
  };
};

const statusMap = {
  2: "New",
  3: "Processing",
  4: "Completed",
  5: "Archived"
};

class Kanban extends React.Component<IKanbanProps, IKanbanState> {
  state = {
    columns: {}
  };

  public componentDidUpdate(prevProps: IKanbanProps) {
    if (this.props.columns !== prevProps.columns) {
      this.setState({ columns: this.props.columns });
    }
  }

  onDragEnd = (result: DropResult) => {
    const { handleDragEnd } = this.props;
    const { columns } = this.state;
    const { destination, source } = result;
    // dropped nowhere
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    // send the result from finishing dragging to the callback function
    handleDragEnd(result);
    this.setState({
      columns: reorderColumnMap({
        columns,
        source,
        destination
      })
    });
  };

  public render() {
    const { columnHeadings, alert, handleUpdate, handleLoadMore, isLoadingMore, page } = this.props;
    const { columns } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="flex-grid-4 kanbanBoard">
          {columnHeadings.map(columnHeading => {
            const currentCountShown: number = (page[columnHeading.id] + 1) * 10;
            const isAtCountLimit: boolean = columns[columnHeading.id] && currentCountShown >= columns[columnHeading.id].count;
            return (
              <div className="col" key={columnHeading.text}>
                <div className="kanbanHeading">
                  <p className={`kanbanHeadingTitle ${columnHeading.text}`}>{columnHeading.text}</p>
                  <p className="kanbanHeadingValue">{columns[columnHeading.id] && columns[columnHeading.id].count} Total</p>
                </div>
                {columns[columnHeading.id] && (
                  <Droppable droppableId={columnHeading.id} isDropDisabled={columns[columnHeading.id].isDropDisabled}>
                    {(provided: DroppableProvided) => (
                      <div className="kanbanColumn" ref={provided.innerRef} {...provided.droppableProps}>
                        {columns[columnHeading.id].data.map((d: IRequest, index: number) => {
                          const isHrPayrollRequest: boolean = d.requestTypeId === 1 || d.requestTypeId === 2 || d.requestTypeId === 3;

                          return (
                            <KanbanCard
                              handleModalClose={handleUpdate}
                              key={d.id}
                              isDragDisabled={columnHeading.text === "Archived"}
                              isHighPriority={d.requestPriority.id === RequestPriorities.HIGH}
                              index={index}
                              footer={
                                <>
                                  <Avatar className="kanbanCardAvatar" showName={true} user={d.user as IUser} />
                                  {isHrPayrollRequest && d.taskCount > 0 && (
                                    <RequestExport
                                      payrollPeriod={d.payrollPeriod}
                                      request={d}
                                      requestType={d.requestType}
                                      showExportHistory={false}
                                      status={d.status && d.status.label}
                                    />
                                  )}
                                </>
                              }
                              icons={
                                <div className="kanbanCardBodyIcons">
                                  {isHrPayrollRequest && (
                                    <>
                                      <Icon iconName="CheckboxComposite" title="Tasks Count" />
                                      <span title="Tasks Count">{d.taskCount}</span>
                                    </>
                                  )}
                                  <Icon iconName="AddNotes" title="Notes Count" />
                                  <span title="Notes Count">{d.noteCount}</span>
                                  {!isHrPayrollRequest && (
                                    <>
                                      <Icon iconName="Stack" title="Docs Count" />
                                      <span title="Docs Count">{d.fileCount}</span>
                                    </>
                                  )}
                                </div>
                              }
                              group={d.requestType && d.requestType.tower.label}
                              id={d.id}
                              body={
                                !isHrPayrollRequest && (
                                  <p className="kanbanCardBodyDate">
                                    {d.dueDate ? `Due Date: ${shortFriendlyDateTime(d.dueDate)}` : "Due Date: N/A"}
                                  </p>
                                )
                              }
                              summary={d.summary}
                              title={d.requestType && d.requestType.label}
                              columnText={statusMap[columnHeading.id]}
                            />
                          );
                        })}
                        {/* react-beautiful-dnd thing that needs to be here */}
                        {provided.placeholder}
                        {/* make sure the number of requests is greater than 10, but less than the total amount */}
                        {columns[columnHeading.id].count > 10 && !isAtCountLimit && (
                          <>
                            {isLoadingMore[columnHeading.id] && (
                              <LoadingWrapper>
                                <Loader type="Inline" loading={true} showImage={true} />
                              </LoadingWrapper>
                            )}
                            {!isLoadingMore[columnHeading.id] && (
                              <Button
                                className="buttonGeneral kanbanViewMoreButton"
                                text="View More"
                                title="View More"
                                onClick={() => handleLoadMore(Number(columnHeading.id), columnHeading.text)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Droppable>
                )}
              </div>
            );
          })}
          {alert}
        </div>
      </DragDropContext>
    );
  }
}

export default Kanban;
