import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { uniq, uniqBy } from "lodash";
import { connect } from "react-redux";
// arrays
import { columns, FilterType } from "../Columns";
import { sorting } from "../Sorting";
// components
import Alert from "../../../components/alert/Alert";
import Badge from "../../../components/badge/Badge";
import Button from "../../../components/button/Button";
import Checkbox from "../../../components/input/Checkbox";
import DateTimePicker from "../../../components/input/DateTimePicker";
import Filter from "../../../components/filter/Filter";
import H6 from "../../../components/typography/H6";
import Label from "../../../components/label/Label";
import Loader from "../../../components/loader/Loader";
import LoaderWrapper from "../../../components/loader/LoaderWrapper";
import Pagination from "../../../components/pagination/Pagination";
import RefreshGroup from "../../../components/refresh/Group";
import RequestExport from "./RequestExport";
import Search from "../../../components/search/Search";
import Select from "../../../components/input/Select";
import Sort from "../../../components/table/Sort";
import Table from "../../../components/table/Table";
import TableWrapper from "../../../components/table/TableWrapper";
import TBody from "../../../components/table/TBody";
import Td from "../../../components/table/Td";
import Th from "../../../components/table/Th";
import THead from "../../../components/table/THead";
import Tr from "../../../components/table/Tr";
// fetch
import { getFilters } from "../../../services/Filters";
import { getRequests } from "../../../services/Request";
import { getRequestTypesForFilter } from "../../../services/RequestType";
// helpers
import { hasOnlyHRRequests } from "../../../helpers/user";
import { sanitizeCsvString } from "../../../helpers/csv";
import { shortFriendlyDateTime, shortFileDateTime } from "../../../helpers/time";
import { getRequestTypeIds } from "../../../helpers/request";
import { formatWysiwyg } from "../../../helpers/format";
import zipcelx, { Config, Sheet, Row, Data } from "../../../helpers/zipcelx";
// logging
import { withPageLog } from "../../logging/LogComponentChange";
// redux
import { setStatusFilter } from "../../../core/filters/filters";
// types
import { IBottler } from "../../../types/Bottler";
import { IFilters } from "../../../types/Filters";
import { IRequestList, IRequest } from "../../../types/Request";
import { IRequestTypeFilter } from "../../../types/RequestType";
import { IUser } from "../../../types/User";

export interface IRequestsListProps {
  archived?: boolean | null;
  bottlers: IBottler[];
  bottler: IBottler | null;
  config: any;
  _createdAtEnd: string | null;
  _createdAtStart: string | null;
  user: IUser;
  refreshId?: string;
  requestPostPilotFF: boolean;
  setStatusFilter: Function;
  statusFilter: number;
}

export interface IRequestsListState {
  assignedTo: number[];
  assignedToFiltered: boolean;
  assignedToShowNull: boolean;
  bottlers: number[];
  bottlersFiltered: boolean;
  count: number;
  createdAtStart: string | null;
  createdAtEnd: string | null;
  createdAtFiltered: boolean;
  dueDateStart: string | null;
  dueDateEnd: string | null;
  dueDateFiltered: boolean;
  export: IRequest[];
  exporting: boolean;
  filter: boolean;
  filtered: boolean;
  filters: IFilters | null;
  filtersLoading: boolean;
  loading: boolean;
  page: number;
  requests: IRequest[];
  requestGroups: number[];
  requestGroupsFiltered: boolean;
  requestTypes: number[];
  requestTypesFiltered: boolean;
  requestPriorities: number[];
  requestPrioritiesFiltered: boolean;
  search: string;
  searching: boolean;
  sortBy: string[] | string;
  sortOrder: string[] | string;
  statuses: number[];
  statusesFiltered: boolean;
  towers: number[];
  towersFiltered: boolean;
  users: number[];
  usersFiltered: boolean;
}

class RequestsList extends React.Component<IRequestsListProps, IRequestsListState> {
  timer;
  DEFAULT_PAGE: number = 0;
  DEFAULT_SORT_ORDER: string[] = ["DESC", "DESC"];
  DEFAULT_SORT_BY: string[] = ["[request].[requestPriorityId]", "[request].[id]"];
  DEFAULT_STATUSES: number[] = [2, 3, 4]; // new, processing, completed

  state = {
    assignedTo: [], // default all assignedTo users
    assignedToFiltered: false,
    assignedToShowNull: true,
    bottlers: [], // default to all bottlers
    bottlersFiltered: false,
    count: 0,
    createdAtStart: this.props._createdAtStart ? this.props._createdAtStart : null,
    createdAtEnd: this.props._createdAtEnd ? this.props._createdAtEnd : null,
    createdAtFiltered: true,
    dueDateStart: null,
    dueDateEnd: null,
    dueDateFiltered: false,
    export: [],
    exporting: false,
    filter: false,
    filtered: false,
    filters: null,
    filtersLoading: true,
    loading: true,
    page: this.DEFAULT_PAGE,
    requests: [],
    requestGroups: [], // default all request groups
    requestGroupsFiltered: false,
    requestPriorities: [1, 2],
    requestPrioritiesFiltered: false,
    requestTypes: [], // default all request types
    requestTypesFiltered: false,
    search: "",
    searching: false,
    sortBy: this.DEFAULT_SORT_BY,
    sortOrder: this.DEFAULT_SORT_ORDER,
    statuses: this.DEFAULT_STATUSES,
    statusesFiltered: false,
    towers: [], // default all towers
    towersFiltered: false,
    users: [], // default all users
    usersFiltered: false
  } as IRequestsListState;

  async componentDidMount(): Promise<void> {
    this.getFilters();
    this.getRequests();
  }

  componentDidUpdate(prevProps: IRequestsListProps): void {
    if (prevProps.bottler !== this.props.bottler) {
      this.resetFiltersAndSearch();
    }
  }

  componentWillUnmount(): void {
    this.props.setStatusFilter(null);
    this.deleteRequestPriorityFilter();
    this.deleteRequestedFilter();
  }

  render() {
    const { refreshId } = this.props;
    const { count, exporting, loading, search, searching, sortOrder, sortBy } = this.state;
    const mobileSorterOptions: JSX.Element[] = this.renderMobileSorter();
    const headerColumns: JSX.Element[] = this.renderHeaderColumns();
    const rows: JSX.Element[] = this.renderRows();
    const exportText: string = this.renderExportText(count);

    return (
      <div className="requestList">
        {refreshId && <RefreshGroup refreshId={refreshId} handleRefresh={this.getRequests} isLoading={loading} />}
        <Search
          autoFocus={true}
          exportDisabled={count === 0 || exporting || loading}
          exportText={exportText}
          exportTitle={exportText}
          onExportButtonClick={this.exportToExcel}
          onSearchChange={this.handleSearchInput}
          onSearchKeyUp={this.handleSearchEnter}
          onSearchClick={this.search}
          placeholder="Search requests..."
          search={search}
          searchButtonText="Search Requests"
          searchButtonTitle="Search Requests"
          searchDisabled={(count < 2 && !searching) || exporting}
          searchLabelText="Search Requests"
          showExport={true}
        />
        <div className="gridSorter">
          <Label htmlFor={"grid-sorter"} text={"Sort Requests By"} />
          <Select defaultValue={sortBy + " " + sortOrder} id={"grid-sorter"} name={"grid-sorter"} onChange={this.handleMobileSort} options={mobileSorterOptions} />
        </div>
        <LoaderWrapper>
          <TableWrapper>
            <Table className="tableEllipsis">
              <THead>
                <Tr>{headerColumns}</Tr>
              </THead>
              <TBody>{rows}</TBody>
            </Table>
          </TableWrapper>
          <Loader loading={exporting || loading} position="Top" showImage={true} text={exporting ? "Exporting..." : "Loading..."} type="Overlay" />
        </LoaderWrapper>
        <Pagination count={count} loading={loading} page={this.state.page + 1} perPage={10} paginate={this.handlePaginate} />
        <ToastContainer />
      </div>
    );
  }

  getRequestPriorityFilter = (): string | null => {
    return localStorage.getItem("requestPriorityFilter");
  };

  deleteRequestPriorityFilter = (): void => {
    localStorage.removeItem("requestPriorityFilter");
  };

  getRequestedFilter = (): string | null => {
    return localStorage.getItem("requestedFilter");
  };

  deleteRequestedFilter = (): void => {
    localStorage.removeItem("requestedFilter");
  };

  getRequests = (): void => {
    const { bottler, _createdAtEnd, _createdAtStart, statusFilter, user } = this.props;

    const requestPriorityFilter: string | null = this.getRequestPriorityFilter();
    const requestedFilter: string | null = this.getRequestedFilter();

    const {
      assignedTo,
      assignedToShowNull,
      bottlers,
      createdAtEnd,
      createdAtStart,
      dueDateEnd,
      dueDateStart,
      filter,
      page,
      requestGroups,
      requestTypes,
      requestPriorities,
      search,
      sortBy,
      sortOrder,
      statuses,
      towers,
      users
    } = this.state;

    this.setState(
      {
        loading: true,
        statuses: statusFilter ? [statusFilter] : statuses
      },
      async () => {
        try {
          let requestTypeFilters: IRequestTypeFilter[] = [];
          let types: number[] = [];

          if (!filter) {
            requestTypeFilters = await getRequestTypesForFilter();
            types = getRequestTypeIds(requestTypeFilters, user);
          }

          const requests: IRequestList = await getRequests(
            assignedTo,
            assignedToShowNull,
            bottler ? bottler.id : null,
            bottlers,
            requestedFilter ? requestedFilter : createdAtStart ? createdAtStart : _createdAtStart ? _createdAtStart : null,
            createdAtEnd ? createdAtEnd : _createdAtEnd ? _createdAtEnd : null,
            dueDateStart,
            dueDateEnd,
            filter,
            true, // isAdmin
            null, // limit
            null, // offset
            page,
            requestGroups,
            types.length > 0 ? types : requestTypes,
            requestPriorityFilter ? [Number(requestPriorityFilter)] : requestPriorities,
            sortBy,
            sortOrder,
            statusFilter ? [statusFilter] : statuses,
            search,
            towers,
            null,
            null,
            user.id,
            users
          );

          this.setState({
            createdAtEnd: createdAtEnd ? createdAtEnd : _createdAtEnd ? _createdAtEnd : null,
            createdAtStart: requestedFilter ? requestedFilter : createdAtStart ? createdAtStart : _createdAtStart ? _createdAtStart : null,
            createdAtFiltered: requestedFilter || createdAtEnd || createdAtStart || _createdAtEnd || _createdAtStart ? true : false,
            count: requests.count,
            loading: false,
            requests: requests.rows,
            requestTypes: types.length > 0 ? types : requestTypes,
            page: page,
            searching: search ? true : false,
            search: search,
            sortBy: sortBy,
            sortOrder: sortOrder
          });
        } catch (error) {
          toast.error(error.error, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.resetState();
        }
      }
    );
  };

  getFilters = (): void => {
    this.setState(
      {
        filtersLoading: true
      },
      async () => {
        try {
          const filters: IFilters | null = await getFilters({
            includeAssignedTo: true,
            includeBottlers: true,
            includeRequestGroups: true,
            includeRequestTypes: true,
            includeStatuses: true,
            includeTowers: true,
            includeUsers: true,
            includeRequestPriorities: true
          });

          if (filters.message) {
            toast.error(filters.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState({
              filtersLoading: false
            });
          } else {
            this.mapFilters(filters, false);
          }
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            filtersLoading: false
          });
        }
      }
    );
  };

  mapFilters = (filters: IFilters, refresh: boolean): void => {
    const { statusFilter, user } = this.props;
    const requestPriorityFilter: string | null = this.getRequestPriorityFilter();
    const { requestTypes } = filters;
    const userRequestTypeIds = uniq(user.requestTypes.map(urt => urt.RequestTypeID));
    filters.requestTypes = [];

    if (user && user.requestTypes) {
      requestTypes.map(rt => {
        rt.requestTypes.map(r => {
          if (userRequestTypeIds.includes(r.id)) {
            filters.requestTypes.push(rt);
          }
        });
      });

      filters.requestTypes = uniqBy(filters.requestTypes, "requestProcessId");
    }

    const assignedTo: number[] = filters.assignedTo.map(u => {
      return u.id;
    });

    const bottlers: number[] = filters.bottlers.map(b => {
      return b.id;
    });

    const requestGroups: number[] = filters.requestGroups.map(requestGroup => {
      return requestGroup.id;
    });

    const types: number[] = [].concat.apply(
      [],
      // @ts-ignore
      filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
    );

    const statuses: number[] = [];

    if (statusFilter) {
      statuses.push(statusFilter);
    } else {
      filters.statuses.map(status => {
        statuses.push(status.id);
      });
    }

    const towers: number[] = filters.towers.map(tower => {
      return tower.id;
    });

    const users: number[] = filters.users.map(u => {
      return u.id;
    });

    const requestPriorities: number[] = [];
    if (requestPriorityFilter) {
      requestPriorities.push(Number(requestPriorityFilter));
    } else {
      filters.requestPriorities.map(requestPriority => requestPriorities.push(requestPriority.id));
    }

    this.setState(
      {
        assignedTo,
        bottlers,
        filter: true,
        filters,
        filtersLoading: false,
        requestGroups,
        requestTypes: types,
        requestPriorities,
        statuses,
        towers,
        users
      },
      () => {
        if (refresh) {
          this.getRequests();
        }
      }
    );
  };

  renderMultiselectFilters = (filters: IFilters | null, filterType: FilterType): JSX.Element | null => {
    let filter: JSX.Element | null = null;
    let checkboxes: JSX.Element[] = [];
    let filterArray: any[] = [];
    const requestPriorityFilter: string | null = this.getRequestPriorityFilter();
    if (filters) {
      const { bottler, statusFilter } = this.props;
      const { assignedTo, assignedToShowNull, bottlers, requestGroups, requestTypes, requestPriorities, statuses, towers, users } = this.state;

      let toggleChecked: boolean = false;

      if (filterType === "assignedTo") {
        filterArray = filters.assignedTo;

        if (filterArray.length === assignedTo.length && assignedToShowNull) {
          toggleChecked = true;
        }
      }

      if (!bottler && filterType === "bottler") {
        filterArray = filters.bottlers;

        if (filterArray.length === bottlers.length) {
          toggleChecked = true;
        }
      }

      if (filterType === "requestGroup") {
        filterArray = filters.requestGroups;

        if (filterArray.length === requestGroups.length) {
          toggleChecked = true;
        }
      }

      if (filterType === "requestType") {
        filterArray = filters.requestTypes;
        const requestFilterIds = [].concat.apply(
          [],
          // @ts-ignore
          filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
        );

        if (requestFilterIds.length === requestTypes.length) {
          toggleChecked = true;
        }
      }

      if (filterType === "requestPriority") {
        filterArray = filters.requestPriorities;

        if (requestPriorityFilter) {
          toggleChecked = false;
        } else if (filterArray.length === requestPriorities.length) {
          toggleChecked = true;
        }
      }

      if (filterType === "status") {
        filterArray = filters.statuses;

        if (statusFilter) {
          toggleChecked = false;
        } else if (filterArray.length === statuses.length) {
          toggleChecked = true;
        }
      }

      if (filterType === "tower") {
        filterArray = filters.towers;

        if (filterArray.length === towers.length) {
          toggleChecked = true;
        }
      }

      if (filterType === "user") {
        filterArray = filters.users;

        if (filterArray.length === users.length) {
          toggleChecked = true;
        }
      }

      filterArray.map((f, index) => {
        if (index === 0) {
          checkboxes.push(
            <Checkbox
              key={`${filterType}-all`}
              checked={toggleChecked}
              className="checkboxHr"
              id={`${filterType}-all`}
              name={`${filterType}-all`}
              onChange={e => this.handleFilterCheckAll(e, filterType)}
              text={`Toggle All`}
            />
          );
          if (filterType === "assignedTo") {
            checkboxes.push(
              <Checkbox
                key={`${filterType}-null`}
                checked={assignedToShowNull}
                className="checkboxHr"
                id={`${filterType}-null`}
                name={`${filterType}-null`}
                onChange={e => this.handleFilterToggleNull(e)}
                text={`Not Assigned`}
              />
            );
          }
        }

        let checked: boolean = false;

        if (filterType === "assignedTo") {
          assignedTo.map(assigned => {
            if (assigned === f.id) {
              checked = true;
            }
          });
        }

        if (!bottler && filterType === "bottler") {
          bottlers.map(b => {
            if (b === f.id) {
              checked = true;
            }
          });
        }

        if (filterType === "requestGroup") {
          requestGroups.map(requestGroup => {
            if (requestGroup === f.id) {
              checked = true;
            }
          });
        }

        if (filterType === "requestType") {
          checkboxes.push(
            <React.Fragment key={`requestTypeFilterLabel-${f.requestProcessId}`}>
              <H6 className="requestTypeFilterLabel">{f.requestProcessLabel}</H6>
              {f.requestTypes.map(type => (
                <Checkbox
                  key={`${filterType}-${type.id}`}
                  checked={requestTypes.includes(type.id)}
                  id={`${filterType}-${type.id}`}
                  name={`${filterType}-${type.id}`}
                  onChange={e => this.handleFilterCheck(e, filterType, type.id)}
                  text={type.label}
                />
              ))}
            </React.Fragment>
          );
        }

        if (filterType === "status") {
          if (statusFilter) {
            statuses.map(status => {
              if (status === statusFilter && status === f.id) {
                checked = true;
              }
            });
          } else {
            statuses.map(status => {
              if (status === f.id) {
                checked = true;
              }
            });
          }
        }

        if (filterType === "tower") {
          towers.map(tower => {
            if (tower === f.id) {
              checked = true;
            }
          });
        }

        if (filterType === "user") {
          users.map(user => {
            if (user === f.id) {
              checked = true;
            }
          });
        }
        if (filterType === "requestPriority") {
          if (requestPriorityFilter) {
            requestPriorities.map(requestPriority => {
              if (requestPriority === Number(requestPriorityFilter) && requestPriority === f.id) {
                checked = true;
              }
            });
          }
          if (requestPriorities.includes(f.id)) {
            checked = true;
          }
        }
        if (filterType !== "requestType") {
          checkboxes.push(
            <Checkbox
              key={`${filterType}-${f.id.toString()}`}
              checked={checked}
              id={`${filterType}-${f.id.toString()}`}
              name={`${filterType}-${f.id.toString()}`}
              onChange={e => this.handleFilterCheck(e, filterType, f.id)}
              text={f.label ? f.label : f.name ? f.name : f.email ? f.email : ""}
            />
          );
        }
      });
      filter = <div>{checkboxes}</div>;
    }

    return filter;
  };

  renderDateFilters = (filters: IFilters | null, filterType: FilterType): JSX.Element | null => {
    let filter: JSX.Element | null = null;

    if (filters) {
      const { _createdAtEnd, _createdAtStart } = this.props;
      const { createdAtEnd, createdAtStart, dueDateEnd, dueDateStart } = this.state;

      filter = (
        <div>
          <div className="field">
            <Label htmlFor="" text={filterType === "createdAt" ? `Requested After` : `Due After`} />
            <DateTimePicker
              date={filterType === "createdAt" ? createdAtStart : dueDateStart}
              id={filterType === "createdAt" ? "createdAtDate" : "dueDateDate"}
              minDate={filterType === "createdAt" && _createdAtStart ? _createdAtStart : undefined}
              maxDate={filterType === "createdAt" && _createdAtEnd ? _createdAtEnd : undefined}
              onChange={(date: Date) => this.handleFilterDateTime(date, filterType, "start")}
              time={filterType === "createdAt" ? createdAtStart : dueDateStart}
            />
          </div>
          <div className="field">
            <Label htmlFor="" text={filterType === "createdAt" ? `Requested Before` : `Due Before`} />
            <DateTimePicker
              date={filterType === "createdAt" ? createdAtEnd : dueDateEnd}
              id={filterType === "createdAt" ? "createdAtTime" : "dueDateTime"}
              minDate={filterType === "createdAt" && _createdAtStart ? _createdAtStart : undefined}
              maxDate={filterType === "createdAt" && _createdAtEnd ? _createdAtEnd : undefined}
              onChange={(date: Date) => this.handleFilterDateTime(date, filterType, "end")}
              time={filterType === "createdAt" ? createdAtEnd : dueDateEnd}
            />
          </div>
          <div className="field">
            <Button
              className="buttonInfo buttonBlock"
              disabled={filterType === "createdAt" ? !createdAtEnd && !createdAtStart : !dueDateEnd && !dueDateStart}
              onClick={e => this.handleClearDateTime(e, filterType)}
              text="Clear Filter"
              title="Clear Fitler"
            />
          </div>
        </div>
      );
    }

    return filter;
  };

  handleFilterDateTime = (date: Date, filterType: FilterType, startOrEnd: "start" | "end"): void => {
    const { _createdAtEnd, _createdAtStart } = this.props;

    if (date && moment(date).isValid()) {
      let iso: string = moment(date).toISOString();

      if (startOrEnd === "start") {
        if (filterType === "createdAt") {
          this.deleteRequestedFilter();
        }

        this.setState({
          createdAtStart: filterType === "createdAt" ? iso : this.state.createdAtStart,
          dueDateStart: filterType === "dueDate" ? iso : this.state.dueDateStart
        });
      }

      if (startOrEnd === "end") {
        this.setState({
          createdAtEnd: filterType === "createdAt" ? iso : this.state.createdAtEnd,
          dueDateEnd: filterType === "dueDate" ? iso : this.state.dueDateEnd
        });
      }

      this.setState(
        {
          createdAtFiltered: filterType === "createdAt" ? true : this.state.createdAtFiltered,
          dueDateFiltered: filterType === "dueDate" ? true : this.state.dueDateFiltered,
          filtered: true
        },
        () => {
          this.getRequests();
        }
      );
    } else {
      if (startOrEnd === "start") {
        this.setState({
          createdAtStart: filterType === "createdAt" && _createdAtStart ? _createdAtStart : filterType === "createdAt" ? null : this.state.createdAtStart,
          dueDateStart: filterType === "dueDate" ? null : this.state.dueDateStart
        });
      }

      if (startOrEnd === "end") {
        this.setState({
          createdAtEnd: filterType === "createdAt" && _createdAtEnd ? _createdAtEnd : filterType === "createdAt" ? null : this.state.createdAtEnd,
          dueDateEnd: filterType === "dueDate" ? null : this.state.dueDateEnd
        });
      }

      this.setState(
        {
          createdAtFiltered: filterType === "createdAt" && (_createdAtEnd || _createdAtStart) ? true : filterType === "createdAt" ? false : this.state.createdAtFiltered,
          dueDateFiltered: filterType === "dueDate" ? false : this.state.dueDateFiltered
        },
        () => {
          const { createdAtFiltered, dueDateFiltered } = this.state;

          this.setState({
            filtered: createdAtFiltered || dueDateFiltered
          });

          this.getRequests();
        }
      );
    }
  };

  handleClearDateTime = (e: React.MouseEvent<HTMLButtonElement>, filterType: FilterType): void => {
    this.deleteRequestedFilter();

    const { _createdAtEnd, _createdAtStart } = this.props;

    this.setState(
      {
        createdAtEnd: filterType === "createdAt" && _createdAtEnd ? _createdAtEnd : filterType === "createdAt" ? null : this.state.createdAtEnd,
        createdAtStart: filterType === "createdAt" && _createdAtStart ? _createdAtStart : filterType === "createdAt" ? null : this.state.createdAtStart,
        createdAtFiltered: filterType === "createdAt" && (_createdAtEnd || _createdAtStart) ? true : filterType === "createdAt" ? false : this.state.createdAtFiltered,
        dueDateEnd: filterType === "dueDate" ? null : this.state.dueDateEnd,
        dueDateStart: filterType === "dueDate" ? null : this.state.dueDateStart,
        dueDateFiltered: filterType === "dueDate" ? false : this.state.dueDateFiltered
      },
      () => {
        this.getRequests();
      }
    );
  };

  handleFilterCheckAll = (e: React.ChangeEvent<HTMLInputElement>, filterType: FilterType): void => {
    const { bottler, statusFilter } = this.props;
    const { filters } = this.state;

    const requestPriorityFilter: string | null = this.getRequestPriorityFilter();

    if (filters) {
      let array: number[] = [];
      let filter: number[] = [];
      let assignedToShowNull: boolean = true;

      if (filterType === "assignedTo") {
        array = this.state.assignedTo.slice();
        filter = filters.assignedTo.map(user => {
          return user.id;
        });
      }

      if (!bottler && filterType === "bottler") {
        array = this.state.bottlers.slice();
        filter = filters.bottlers.map(b => {
          return b.id;
        });
      }

      if (filterType === "requestGroup") {
        array = this.state.requestGroups.slice();
        filter = filters.requestGroups.map(requestGroup => {
          return requestGroup.id;
        });
      }

      if (filterType === "requestType") {
        array = this.state.requestTypes.slice();
        filter = [].concat.apply(
          [],
          // @ts-ignore
          filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
        );
      }

      if (filterType === "requestPriority") {
        if (requestPriorityFilter) {
          this.deleteRequestPriorityFilter();
        }
        array = this.state.requestPriorities.slice();
        filter = filters.requestPriorities.map(rp => rp.id);
      }

      if (filterType === "status") {
        if (statusFilter) {
          this.props.setStatusFilter(null);
        }

        array = this.state.statuses.slice();
        filter = filters.statuses.map(status => {
          return status.id;
        });
      }

      if (filterType === "tower") {
        array = this.state.towers.slice();
        filter = filters.towers.map(tower => {
          return tower.id;
        });
      }

      if (filterType === "user") {
        array = this.state.users.slice();
        filter = filters.users.map(user => {
          return user.id;
        });
      }

      const checked: boolean = e.target.checked;

      if (checked) {
        array = filter.map(id => {
          return id;
        });
        if (filterType === "assignedTo") {
          assignedToShowNull = true;
        }
      } else {
        array = [];
        if (filterType === "assignedTo") {
          assignedToShowNull = false;
        }
      }

      this.setState(
        {
          assignedTo: filterType === "assignedTo" ? array : this.state.assignedTo,
          assignedToFiltered: filterType === "assignedTo" ? !checked : this.state.assignedToFiltered,
          assignedToShowNull: filterType === "assignedTo" ? assignedToShowNull : this.state.assignedToShowNull,
          bottlers: filterType === "bottler" ? array : this.state.bottlers,
          bottlersFiltered: filterType === "bottler" ? !checked : this.state.bottlersFiltered,
          filtered: true,
          requestGroups: filterType === "requestGroup" ? array : this.state.requestGroups,
          requestGroupsFiltered: filterType === "requestGroup" ? !checked : this.state.requestGroupsFiltered,
          requestTypes: filterType === "requestType" ? array : this.state.requestTypes,
          requestTypesFiltered: filterType === "requestType" ? !checked : this.state.requestTypesFiltered,
          requestPriorities: filterType === "requestPriority" ? array : this.state.requestPriorities,
          requestPrioritiesFiltered: filterType === "requestPriority" ? !checked : this.state.requestPrioritiesFiltered,
          statuses: filterType === "status" ? array : this.state.statuses,
          statusesFiltered: filterType === "status" ? !checked : this.state.statusesFiltered,
          towers: filterType === "tower" ? array : this.state.towers,
          towersFiltered: filterType === "tower" && !checked ? !checked : this.state.towersFiltered,
          users: filterType === "user" ? array : this.state.users,
          usersFiltered: filterType === "user" ? !checked : this.state.usersFiltered
        },
        () => {
          this.getRequests();
        }
      );
    }
  };

  handleFilterCheck = (e: React.ChangeEvent<HTMLInputElement>, filterType: FilterType, id: number): void => {
    const { bottler, statusFilter } = this.props;
    const { filters } = this.state;

    const requestPriorityFilter: string | null = this.getRequestPriorityFilter();

    if (filters) {
      let array: number[] = [];

      if (filterType === "assignedTo") {
        array = this.state.assignedTo.slice();
      }

      if (!bottler && filterType === "bottler") {
        array = this.state.bottlers.slice();
      }

      if (filterType === "requestGroup") {
        array = this.state.requestGroups.slice();
      }

      if (filterType === "requestType") {
        array = this.state.requestTypes.slice();
      }

      if (filterType === "requestPriority") {
        if (requestPriorityFilter) {
          this.deleteRequestPriorityFilter();
        }
        array = this.state.requestPriorities.slice();
      }

      if (filterType === "status") {
        if (statusFilter) {
          this.props.setStatusFilter(null);
        }

        array = this.state.statuses.slice();
      }

      if (filterType === "tower") {
        array = this.state.towers.slice();
      }

      if (filterType === "user") {
        array = this.state.users.slice();
      }

      const checked: boolean = e.target.checked;

      if (checked) {
        array.push(id);
      } else {
        const index: number = array.findIndex(item => {
          return id === item;
        });
        if (index !== -1) {
          array.splice(index, 1);
        }
      }

      this.setState(
        {
          assignedTo: filterType === "assignedTo" ? array : this.state.assignedTo,
          assignedToFiltered: filterType === "assignedTo" ? true : this.state.assignedToFiltered,
          bottlers: filterType === "bottler" ? array : this.state.bottlers,
          bottlersFiltered: filterType === "bottler" ? true : this.state.bottlersFiltered,
          filtered: true,
          requestGroups: filterType === "requestGroup" ? array : this.state.requestGroups,
          requestGroupsFiltered: filterType === "requestGroup" ? true : this.state.requestGroupsFiltered,
          requestTypes: filterType === "requestType" ? array : this.state.requestTypes,
          requestTypesFiltered: filterType === "requestType" ? true : this.state.requestTypesFiltered,
          requestPriorities: filterType === "requestPriority" ? array : this.state.requestPriorities,
          requestPrioritiesFiltered: filterType === "requestPriority" && filters.requestPriorities.length !== array.length,
          statuses: filterType === "status" ? array : this.state.statuses,
          statusesFiltered: filterType === "status" ? true : this.state.statusesFiltered,
          towers: filterType === "tower" ? array : this.state.towers,
          towersFiltered: filterType === "tower" ? true : this.state.towersFiltered,
          users: filterType === "user" ? array : this.state.users,
          usersFiltered: filterType === "user" ? true : this.state.usersFiltered
        },
        () => {
          this.getRequests();
        }
      );
    }
  };

  handleFilterToggleNull = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { assignedToShowNull } = this.state;

    const showNull: boolean = assignedToShowNull ? false : true;

    this.setState(
      {
        assignedToFiltered: showNull ? true : false,
        assignedToShowNull: showNull,
        filtered: true
      },
      () => {
        this.getRequests();
      }
    );
  };

  resetState = (): void => {
    this.setState({
      count: 0,
      requests: [],
      loading: false,
      page: this.DEFAULT_PAGE,
      searching: false,
      search: "",
      sortBy: this.DEFAULT_SORT_BY,
      sortOrder: this.DEFAULT_SORT_ORDER
    });
  };

  resetFiltersAndSearch = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    this.deleteRequestPriorityFilter();
    this.deleteRequestedFilter();

    if (e) {
      e.preventDefault();
    }

    const { _createdAtEnd, _createdAtStart } = this.props;
    const { filters } = this.state;

    if (filters) {
      this.setState(
        {
          assignedToFiltered: false,
          assignedToShowNull: true,
          bottlersFiltered: false,
          createdAtStart: _createdAtStart ? _createdAtStart : null,
          createdAtEnd: _createdAtEnd ? _createdAtEnd : null,
          createdAtFiltered: true,
          dueDateStart: null,
          dueDateEnd: null,
          dueDateFiltered: false,
          filtered: false,
          loading: true,
          page: this.DEFAULT_PAGE,
          requestGroupsFiltered: false,
          requestTypesFiltered: false,
          requestPrioritiesFiltered: false,
          search: "",
          sortBy: this.DEFAULT_SORT_BY,
          sortOrder: this.DEFAULT_SORT_ORDER,
          statuses: this.DEFAULT_STATUSES,
          statusesFiltered: false,
          towersFiltered: false,
          usersFiltered: false
        },
        () => {
          this.mapFilters(filters, true);
        }
      );
    }
  };

  handlePaginate = (e: React.MouseEvent<HTMLButtonElement>, page: number): void => {
    e.preventDefault();

    this.setState(
      {
        page: page - 1
      },
      () => {
        this.getRequests();
      }
    );
  };

  search = (): void => {
    this.setState(
      {
        page: this.DEFAULT_PAGE
      },
      () => {
        this.getRequests();
      }
    );
  };

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();

    const search: string = e.target.value;

    clearTimeout(this.timer);

    this.setState(
      {
        search
      },
      () => {
        this.timer = setTimeout(() => {
          this.search();
        }, 300);
      }
    );
  };

  handleSearchEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();

    if (e.key === "Enter") {
      const search: string = e.currentTarget.value;

      this.setState(
        {
          search: search ? search : ""
        },
        () => {
          this.search();
        }
      );
    }
  };

  handleSort = (e: React.MouseEvent<HTMLButtonElement>, sortBy: string): void => {
    e.preventDefault();

    const { sortOrder } = this.state;

    this.setState(
      {
        page: this.DEFAULT_PAGE,
        sortBy: sortBy,
        sortOrder: sortOrder === "DESC" ? "ASC" : "DESC"
      },
      () => {
        this.getRequests();
      }
    );
  };

  handleMobileSort = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();
    const value: string = e.target.value;
    const sortBy: string = value.split(" ")[0];
    const sortOrder: string = value.split(" ")[1];

    this.setState(
      {
        page: this.DEFAULT_PAGE,
        sortBy: sortBy,
        sortOrder: sortOrder
      },
      () => {
        this.getRequests();
      }
    );
  };

  exportToExcel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    this.setState(
      {
        exporting: true
      },
      async () => {
        const { bottler, _createdAtEnd, _createdAtStart, statusFilter, user } = this.props;
        const {
          assignedTo,
          assignedToShowNull,
          bottlers,
          createdAtEnd,
          createdAtStart,
          dueDateEnd,
          dueDateStart,
          filter,
          requestGroups,
          requestTypes,
          requestPriorities,
          search,
          sortBy,
          sortOrder,
          statuses,
          towers,
          users
        } = this.state;

        if (user && user.requestTypes && user.requestTypes.length > 0) {
          try {
            let requestTypeFilters: IRequestTypeFilter[] = [];
            let types: number[] = [];

            if (!filter) {
              requestTypeFilters = await getRequestTypesForFilter();
              types = getRequestTypeIds(requestTypeFilters, user);
            }

            const requests: IRequestList = await getRequests(
              assignedTo,
              assignedToShowNull,
              bottler ? bottler.id : null,
              bottlers,
              _createdAtStart ? _createdAtStart : createdAtStart,
              _createdAtEnd ? _createdAtEnd : createdAtEnd,
              dueDateStart,
              dueDateEnd,
              filter,
              true, // isAdmin
              0, // limit
              0, // offset
              0, // page
              requestGroups,
              types.length > 0 ? types : requestTypes,
              requestPriorities,
              sortBy,
              sortOrder,
              statusFilter ? [statusFilter] : statuses,
              search,
              towers,
              null,
              null,
              user.id,
              users
            );

            const onlyHrRequests: boolean = hasOnlyHRRequests(user.requestTypes);

            const data: Data = [];

            if (!onlyHrRequests) {
              const header: Row = [
                {
                  value: "Request ID",
                  type: "string"
                },
                {
                  value: "Bottler",
                  type: "string"
                },
                {
                  value: "Tower",
                  type: "string"
                },
                {
                  value: "Request Group",
                  type: "string"
                },
                {
                  value: "Request Type",
                  type: "string"
                },
                {
                  value: "Status",
                  type: "string"
                },
                {
                  value: "Requester",
                  type: "string"
                },
                {
                  value: "Requested",
                  type: "string"
                },
                {
                  value: "Assigned To",
                  type: "string"
                },
                {
                  value: "Due Date",
                  type: "string"
                },
                {
                  value: "Summary",
                  type: "string"
                },
                {
                  value: "Description",
                  type: "string"
                }
              ];

              data.push(header);
            } else {
              const header: Row = [
                {
                  value: "Request ID",
                  type: "string"
                },
                {
                  value: "Bottler",
                  type: "string"
                },
                {
                  value: "Tower",
                  type: "string"
                },
                {
                  value: "Request Group",
                  type: "string"
                },
                {
                  value: "Request Type",
                  type: "string"
                },
                {
                  value: "Status",
                  type: "string"
                },
                {
                  value: "Requester",
                  type: "string"
                },
                {
                  value: "Requested",
                  type: "string"
                }
              ];

              data.push(header);
            }

            requests.rows.map((request, index) => {
              if (request && request.bottler && request.requestType && request.status && request.user) {
                const name: string = request.user.name ? request.user.name : request.user.email;
                const tower: string = request.requestType.tower ? request.requestType.tower.label : "";
                const requestGroup: string = request.requestType.requestGroup ? request.requestType.requestGroup.label : "";

                if (!onlyHrRequests) {
                  const id = sanitizeCsvString(request.id.toString());
                  const summary: string = request.summary ? request.summary : ""; // else "null" shows
                  const description = request.description ? formatWysiwyg(request.description) : "";
                  const assignedToUser: string =
                    request.assignedToUser && request.assignedToUser.name
                      ? request.assignedToUser.name
                      : request.assignedToUser && request.assignedToUser.email
                      ? request.assignedToUser.email
                      : "";
                  const dueDate: string = request.dueDate ? shortFriendlyDateTime(request.dueDate) : "";

                  data.push([
                    {
                      value: id,
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(request.bottler.name),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(tower),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(requestGroup),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(request.requestType.label),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(request.status.label),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(name),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(shortFriendlyDateTime(request.createdAt)),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(assignedToUser),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(dueDate),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(summary),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(description),
                      type: "string"
                    }
                  ]);
                } else {
                  data.push([
                    {
                      value: sanitizeCsvString(request.id.toString()),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(request.bottler.name),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(tower),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(requestGroup),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(request.requestType.label),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(request.status.label),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(name),
                      type: "string"
                    },
                    {
                      value: sanitizeCsvString(shortFriendlyDateTime(request.createdAt)),
                      type: "string"
                    }
                  ]);
                }
              }
            });

            const now: string = moment().toISOString();
            const filename = "Requests_" + shortFileDateTime(now);

            const sheet: Sheet = {
              data
            };

            const config: Config = {
              filename,
              sheet
            };

            zipcelx(config);

            this.setState({
              exporting: false
            });
          } catch (error) {
            toast.error(error.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState({
              exporting: false
            });
          }
        } else {
          toast.error("You do not have permission to export requests.", {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            exporting: false
          });
        }
      }
    );
  };

  renderExportText = (count: number): string => {
    return count === 1 ? "Export " + count.toString() + " Request" : "Export " + count.toString() + " Requests";
  };

  renderRows = (): JSX.Element[] => {
    const rows: JSX.Element[] = [];

    const { archived, bottler, user, requestPostPilotFF } = this.props;
    const { requests } = this.state;

    const onlyHrRequests: boolean = hasOnlyHRRequests(user.requestTypes);

    if (requests && requests.length > 0) {
      requests.map((request, index) => {
        const requestId: JSX.Element = (
          <Td title={"ID"} tooltipText={request.id.toString()}>
            <p className="label">ID</p>
            <span>{request.id.toString()}</span>
          </Td>
        );

        const bottlerName: JSX.Element | null = !bottler ? (
          <Td title={"Bottler"} tooltipText={request.bottler ? request.bottler.name : ""} tooltipWidth="tipXxl">
            <p className="label">Bottler</p>
            <span>{request.bottler ? request.bottler.name : ""}</span>
          </Td>
        ) : null;

        const tower: JSX.Element = (
          <Td title={"Tower"} tooltipText={request && request.requestType && request.requestType.tower ? request.requestType.tower.label : ""} tooltipWidth="tipXxl">
            <p className="label">Tower</p>
            <span>{request && request.requestType && request.requestType.tower ? request.requestType.tower.label : ""}</span>
          </Td>
        );
        // feature flag check here
        const requestPriority: JSX.Element | null = requestPostPilotFF ? (
          <Td title="Request Prioirty" tooltipText={request.requestPriority.name ? request.requestPriority.name : ""} tooltipWidth="tipXxxxl">
            <p className="label">Request Priority</p>
            <span>{request.requestPriority.name ? request.requestPriority.name : ""}</span>
          </Td>
        ) : null;

        const summary: JSX.Element = (
          <Td title={"Summary"} tooltipText={request.summary ? request.summary : ""} tooltipWidth="tipXxxxl">
            <p className="label">Summary</p>
            <span>{request.summary ? request.summary : ""}</span>
          </Td>
        );

        const requestGroup: JSX.Element = (
          <Td
            title={"Request Group"}
            tooltipText={request && request.requestType && request.requestType.requestGroup ? request.requestType.requestGroup.label : ""}
            tooltipWidth="tipXxl"
          >
            <p className="label">Request Group</p>
            <span>{request && request.requestType && request.requestType.requestGroup ? request.requestType.requestGroup.label : ""}</span>
          </Td>
        );

        const requestType: JSX.Element = (
          <Td title={"Request Type"} tooltipText={request && request.requestType ? request.requestType.label : ""} tooltipWidth="tipXxl">
            <p className="label">Request Type</p>
            <span>{request && request.requestType ? request.requestType.label : ""}</span>
            {(request.requestTypeId === 1 || request.requestTypeId === 2 || request.requestTypeId === 3) && (
              <Badge text={request.taskCount.toString()} title={request.taskCount.toString() + " tasks"} />
            )}
          </Td>
        );

        const statusIcon: JSX.Element | null =
          request.status && (request.status.id === 1 || request.status.id === 2) ? ( // draft or new
            <Icon iconName="Unlock" />
          ) : request.status && (request.status.id === 3 || request.status.id === 4) ? ( // processing or completed
            <Icon iconName="LockSolid" />
          ) : null;

        const status: JSX.Element = (
          <Td title={"Status"}>
            <p className="label">Status</p>
            <p className="status">
              {statusIcon}
              <span>{request.status ? request.status.label : ""}</span>
            </p>
          </Td>
        );

        const requester: JSX.Element = (
          <Td title={"Requester"} tooltipText={request.user && request.user.name ? request.user.name : ""} tooltipWidth="tipXxl">
            <p className="label">Requester</p>
            <span>{request.user && request.user.name ? request.user.name : ""}</span>
          </Td>
        );

        const requested: JSX.Element = (
          <Td title={"Requested"}>
            <p className="label">Requested</p>
            <span>{shortFriendlyDateTime(request.createdAt)}</span>
          </Td>
        );

        const assignedTo: JSX.Element = (
          <Td title={"Assigned To"} tooltipText={request.assignedToUser ? request.assignedToUser.name || request.assignedToUser.email : ""} tooltipWidth="tipXxl">
            <p className="label">Assigned To</p>
            <span>{request.assignedToUser ? request.assignedToUser.name || request.assignedToUser.email : ""}</span>
          </Td>
        );

        const dueDate: JSX.Element = (
          <Td title={"Due Date"}>
            <p className="label">Due Date</p>
            <span>{request.dueDate ? shortFriendlyDateTime(request.dueDate) : ""}</span>
          </Td>
        );

        const exportRequest: JSX.Element | null =
          request.requestType &&
          (request.requestType.id === 1 || request.requestType.id === 2 || request.requestType.id === 3) &&
          request.status &&
          request.status.id !== 1 &&
          request.taskCount &&
          request.taskCount > 0 ? (
            <RequestExport
              _afterExport={this.getRequests}
              payrollPeriod={request.payrollPeriod}
              request={request}
              requestType={request.requestType}
              showExportHistory={false}
              status={request.status.label}
            />
          ) : null;

        const edit: JSX.Element =
          request.requestType && (request.requestType.id === 1 || request.requestType.id === 2 || request.requestType.id === 3) ? (
            <NavLink to={"/shared-services/requests/hr-request/" + request.id.toString()} className="button buttonLinkPrimaryColor" title="Edit Request">
              <span className="buttonIcon buttonIconLeft">
                <Icon iconName="Edit" />
              </span>
              <span className="buttonText">Edit</span>
            </NavLink>
          ) : (
            <NavLink to={"/shared-services/requests/request/" + request.id.toString()} className="button buttonLinkPrimaryColor" title="Edit Request">
              <span className="buttonIcon buttonIconLeft">
                <Icon iconName="Edit" />
              </span>
              <span className="buttonText">Edit</span>
            </NavLink>
          );

        const actions: JSX.Element = (
          <Td title={request.status && request.status.id !== 1 ? "Edit Or Export" : "Edit"}>
            {edit}
            {exportRequest}
          </Td>
        );

        rows.push(
          <Tr
            hover={true}
            striped={true}
            success={request.status && request.status.id === 4 ? true : false}
            warning={request.status && request.status.id === 3 ? true : false}
            error={false}
            key={index}
          >
            {requestId}
            {bottlerName}
            {tower}
            {!onlyHrRequests ? summary : null}
            {requestGroup}
            {requestType}
            {requestPriority}
            {!archived ? status : null}
            {requester}
            {requested}
            {!onlyHrRequests ? assignedTo : null}
            {!onlyHrRequests ? dueDate : null}
            {actions}
          </Tr>
        );
      });
    } else {
      rows.push(
        <Tr key="no-results">
          <Td colSpan={columns.length}>
            <Alert show={true} type="Info">
              There are no requests. Switch your Bottler or{" "}
              <a href={"#"} onClick={e => this.resetFiltersAndSearch(e)}>
                undo your search and filters
              </a>
              .
            </Alert>
          </Td>
        </Tr>
      );
    }

    return rows;
  };

  renderHeaderColumns = (): JSX.Element[] => {
    const headerColumns: JSX.Element[] = [];

    const requestPriorityFilter: string | null = this.getRequestPriorityFilter();

    const { archived, bottler, statusFilter, user, requestPostPilotFF } = this.props;

    const {
      assignedToFiltered,
      bottlersFiltered,
      count,
      createdAtFiltered,
      dueDateFiltered,
      filters,
      filtersLoading,
      requestGroupsFiltered,
      requestTypesFiltered,
      statusesFiltered,
      sortOrder,
      sortBy,
      towersFiltered,
      usersFiltered
    } = this.state;

    const onlyHrRequests: boolean = hasOnlyHRRequests(user.requestTypes);

    columns.map((column, index) => {
      // feature flag check here
      if (column.filterType === "requestPriority" && !requestPostPilotFF) {
        return;
      }
      let ascending: boolean = column.sortBy === sortBy && sortOrder === "ASC" ? true : false;
      let descending: boolean = column.sortBy === sortBy && sortOrder === "DESC" ? true : false;

      let sort: JSX.Element | null =
        column.sortBy && count > 1 ? (
          <Sort text={column.title} ascending={ascending} descending={descending} onClick={e => this.handleSort(e, column.sortBy)} disabled={false} />
        ) : null;

      let filter: JSX.Element | null = null;

      if (column.filter && column.filterType) {
        let dropdown: JSX.Element | null = null;

        if (column.filterInput === "dates") {
          dropdown = this.renderDateFilters(filters, column.filterType);
        } else if (column.filterInput === "multiselect") {
          dropdown = this.renderMultiselectFilters(filters, column.filterType);
        }

        filter = (
          <Filter
            className={column.filterType === "createdAt" || column.filterType === "dueDate" ? "hasCalendar" : ""}
            filterDisabled={filtersLoading}
            filtered={
              (column.filterType === "assignedTo" && assignedToFiltered) ||
              (column.filterType === "bottler" && bottlersFiltered) ||
              (column.filterType === "createdAt" && createdAtFiltered) ||
              (column.filterType === "dueDate" && dueDateFiltered) ||
              (column.filterType === "requestGroup" && requestGroupsFiltered) ||
              (column.filterType === "requestType" && requestTypesFiltered) ||
              (column.filterType === "requestPriority" && requestPriorityFilter !== null) ||
              (column.filterType === "status" && statusesFiltered) ||
              (column.filterType === "status" && statusFilter !== null) ||
              (column.filterType === "tower" && towersFiltered) ||
              (column.filterType === "user" && usersFiltered)
            }
            filterPosition={column.filterPosition}
            filterText={column.title}
          >
            {dropdown}
          </Filter>
        );
      }

      if (column.text === "Due Date" || column.text === "Assigned To" || column.text === "Request Summary") {
        if (!onlyHrRequests) {
          headerColumns.push(
            <Th hidden={false} text={column.text} title={column.title} key={index}>
              {sort}
              {filter}
            </Th>
          );
        }
      } else if (column.text === "Bottler") {
        if (!bottler) {
          headerColumns.push(
            <Th hidden={false} text={column.text} title={column.title} key={index}>
              {sort}
              {filter}
            </Th>
          );
        }
      } else if (column.text === "Status") {
        if (!archived) {
          headerColumns.push(
            <Th hidden={false} text={column.text} title={column.title} key={index}>
              {sort}
              {filter}
            </Th>
          );
        }
      } else {
        headerColumns.push(
          <Th hidden={false} text={column.text} title={column.title} key={index}>
            {sort}
            {filter}
          </Th>
        );
      }
    });

    return headerColumns;
  };

  renderMobileSorter = (): JSX.Element[] => {
    const options: JSX.Element[] = [];

    columns.map(column => {
      if (column.sortBy) {
        sorting.map(sort => {
          options.push(
            <option value={column.sortBy + " " + sort.database} key={column.sortBy + " " + sort.database}>
              {column.text + " " + sort.text}
            </option>
          );
        });
      }
    });

    return options;
  };
}

export const mapStateToProps = (state: any) => {
  return {
    bottlers: state.bottlers.bottlers,
    bottler: state.bottler.bottler,
    config: state.config.config,
    user: state.user.user,
    statusFilter: state.filters.statusFilter,
    requestPostPilotFF: state.featureFlags.requestPostPilot
  };
};

export default connect(mapStateToProps, {
  setStatusFilter
})(withRouter(withPageLog(RequestsList)));
