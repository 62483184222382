import * as React from "react";
import FocusTrap from "focus-trap-react/dist/focus-trap-react";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Button from "../button/Button";
import LoaderWrapper from "../loader/LoaderWrapper";
import Loader from "../loader/Loader";
import ModalFooter from "./ModalFooter";

export interface IModalProps {
  body?: JSX.Element[];
  bodyClassName?: string;
  bodyDeleting?: boolean;
  bodyLoading?: boolean;
  bodyLoadingShowImage?: boolean;
  bodyLoadingText?: string;
  bodyLoadingPosition?: "Top" | "Centered";
  bodyPadding?: boolean;
  bodySaving?: boolean;
  cancelDisabled?: boolean;
  cancelIcon?: string;
  cancelText?: string;
  cancelTitle?: string;
  className?: string;
  disabled?: boolean;
  footer?: JSX.Element | null;
  handleSave?: any;
  handleOpen?: any;
  handleCancel?: any;
  heading: string;
  id: string;
  isOpen?: boolean;
  modalSize?: "Xxxs" | "Xxs" | "Xs" | "Sm" | "Md" | "Lg" | "Xl" | "Xxl" | "Xxxl" | "Xxxxl";
  onCancel?: any;
  onOpen?: any;
  onSave?: any;
  openClassName?: string;
  openIcon?: string;
  openText: string | JSX.Element;
  openTitle: string;
  saveButtonType?: "button" | "submit";
  saveDisabled?: boolean;
  saveIcon?: string;
  saveText?: string;
  saveTitle?: string;
  showCancel: boolean;
  showSave: boolean;
  showFooter?: boolean;
  staticBodyContent?: JSX.Element;
}
export interface IModalState {
  isOpen: boolean;
  isOpening: boolean;
  isClosing: boolean;
}

export default class Modal extends React.Component<IModalProps, IModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      isOpening: false,
      isClosing: false
    };
    this._handleToggle = this._handleToggle.bind(this);
  }

  public render(): React.ReactElement<IModalProps> {
    const {
      body,
      bodyClassName,
      bodyDeleting,
      bodyLoading,
      bodyLoadingShowImage,
      bodyLoadingText,
      bodyLoadingPosition,
      bodyPadding,
      bodySaving,
      cancelDisabled,
      cancelIcon = "Cancel",
      cancelText = "Cancel",
      cancelTitle = "Cancel",
      className,
      disabled,
      footer,
      handleCancel,
      handleOpen,
      handleSave,
      heading,
      id,
      modalSize,
      onOpen,
      onCancel,
      onSave,
      openClassName,
      openIcon,
      openText,
      openTitle,
      saveButtonType,
      saveDisabled,
      saveIcon,
      saveText,
      saveTitle,
      showCancel,
      showSave,
      showFooter,
      staticBodyContent
    } = this.props;

    const { isClosing, isOpening } = this.state;
    const isOpen = this.props.isOpen || this.state.isOpen;
    const cancel: (e: React.MouseEvent<HTMLButtonElement>) => void = onCancel
      ? e => {
          this._handleToggle(e);
          onCancel(e);
        }
      : e => {
          this._handleToggle(e);
        };

    const open: (e: React.MouseEvent<HTMLButtonElement>) => void = onOpen
      ? e => {
          this._handleToggle(e);
          onOpen(e);
        }
      : e => {
          this._handleToggle(e);
        };

    const save: (e: React.MouseEvent<HTMLButtonElement>) => void = onSave
      ? e => {
          this._handleToggle(e);
          onSave(e);
        }
      : e => {
          this._handleToggle(e);
        };

    const cssClass: string = className ? "modalWrapper " + className : "modalWrapper";

    return (
      <div className={cssClass}>
        <Button
          className={openClassName ? openClassName : "buttonWhite"}
          iconLeft={openIcon ? <Icon iconName={openIcon} /> : null}
          text={openText}
          title={openTitle}
          disabled={disabled ? true : false}
          onMouseDown={handleOpen || open}
        />
        <FocusTrap active={isOpen}>
          <div
            className={isOpening ? "modal modalIsOpening" : isClosing ? "modal modalIsClosing" : "modal"}
            id={id}
            tabIndex={isOpen ? 0 : -1}
            role="dialog"
            aria-hidden={isOpen || isOpening || isClosing ? false : true}
          >
            <div className={modalSize ? `modalInner${modalSize}` : "modalInner"}>
              <div className={"modalHeader"}>
                <h6>{heading}</h6>
                <Button
                  className="buttonCloseModal"
                  disabled={cancelDisabled ? true : false}
                  iconLeft={cancelIcon ? <Icon iconName={cancelIcon} /> : null}
                  text=""
                  title="Close"
                  onMouseDown={handleCancel || cancel}
                />
              </div>
              <div className={bodyPadding === false ? `modalBody ${bodyClassName || ""}` : `modalBody modalBodyPadding ${bodyClassName || ""}`}>
                {staticBodyContent}
                <LoaderWrapper>
                  {isOpen && body && body.length
                    ? body.map((b, index) => {
                        return <div key={index}>{b}</div>;
                      })
                    : null}
                  <Loader
                    deleting={bodyDeleting ? bodyDeleting : false}
                    loading={bodyLoading ? bodyLoading : false}
                    position={bodyLoadingPosition ? bodyLoadingPosition : undefined}
                    saving={bodySaving ? bodySaving : false}
                    showImage={bodyLoadingShowImage}
                    text={bodyLoadingText ? bodyLoadingText : undefined}
                    type="Overlay"
                  />
                </LoaderWrapper>
              </div>

              {showFooter !== false && (
                <ModalFooter
                  cancelDisabled={cancelDisabled}
                  cancelIcon={cancelIcon}
                  cancelText={cancelText}
                  cancelTitle={cancelTitle}
                  footer={footer}
                  onCancel={handleCancel || cancel}
                  onSave={handleSave || save}
                  saveButtonType={saveButtonType ? saveButtonType : "button"}
                  saveDisabled={saveDisabled}
                  saveIcon={saveIcon}
                  saveText={saveText}
                  saveTitle={saveTitle}
                  showSave={showSave}
                  showCancel={showCancel}
                />
              )}
            </div>
          </div>
        </FocusTrap>
      </div>
    );
  }

  private _handleToggle(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    const { isOpen } = this.state;
    const body: HTMLElement = document.getElementById("body") as HTMLElement;
    if (isOpen) {
      // this is needed for ios devices so the modal doesn't break
      body.classList.remove("modalOpen");
      this.setState(
        {
          isOpen: true,
          isClosing: true
        },
        () => {
          setTimeout(() => {
            this.setState({
              isOpen: false,
              isClosing: false
            });
          }, 600);
        }
      );
    } else {
      body.classList.add("modalOpen");
      this.setState(
        {
          isOpen: false,
          isOpening: true
        },
        () => {
          setTimeout(() => {
            this.setState({
              isOpen: true,
              isOpening: false
            });
          }, 600);
        }
      );
    }
  }
}
