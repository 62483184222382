import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";
import Button from "../button/Button";
import LoggedInAs from "./LoggedInAs";
import Logo from "./Logo";

class Header extends React.Component {
  public render() {
    return (
      <header className="header" id="header">
        <Logo />
        <Button
          className="openMenu"
          iconLeft={<Icon iconName="GlobalNavButton" />}
          onClick={(e: any) => this._toggleMenu(e)}
          text="Menu"
          title="Open Menu"
        />
        <LoggedInAs />
      </header>
    );
  }
  private _toggleMenu(e: any): void {
    e.preventDefault();
    const body: HTMLElement | null = document.getElementById("body");
    if (body) {
      if (body.classList.contains("menuOpen")) {
        body.classList.add("menuIsClosing");
        setTimeout(
          () => {
            body.classList.remove("menuOpen", "menuIsClosing");
          },
          400
        );
      } else {
        body.classList.add("menuOpen");
      }
    }
  }
}

export default Header;