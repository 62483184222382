import moment from "moment";
import "moment-timezone";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Icon, Message, Label } from "semantic-ui-react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { DateRangePicker } from "react-dates";
import ReactTable from "react-table";
import "react-dates/initialize";
import "react-table/react-table.css";
import "react-dates/lib/css/_datepicker.css";
// components
import Band from "../../components/layout/Band";
import BottlerSwitcher from "../../components/switcher/BottlerSwitcher";
import H1 from "../../components/typography/H1";
import Hr from "../../components/typography/Hr";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Permissions from "../permissions/Permissions";
import RefreshGroup from "../../components/refresh/Group";
import Section from "../../components/layout/Section";
import Select from "../../components/input/Select";
// enums
import { RequestTypes } from "../../enums/RequestTypes";
// helpers
import { sanitizeCsvString } from "../../helpers/csv";
import { createdAtIso, outletHours, createdAtDate, createdAtTime } from "../../helpers/equipmentRepair";
import zipcelx, { Sheet, Config, Row, Data, Cell } from "../../helpers/zipcelx";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// services
import { getRequestUsages } from "../../services/RequestUsage";
import { getServiceRequestsCreator, getServiceRequestsSuccessCreator } from "../../core/notify/reports/serviceRequests";
// types
import { Bottler } from "../../types/Bottler";
import { IServiceRequest } from "../../types/ServiceRequest";
import { IReactTableColumn, UsageReportsColumns } from "../../types/Column";
import { ITimezoneOption, DaylightSavingsTimezoneOptions, StandardTimezoneOptions } from "../../types/Timezone";
import { IRequestUsage } from "../../types/RequestUsage";
import { Apps } from "../../types/App";

interface IUsageReportsProps {
  appId: number;
  bottlers: Bottler[];
  bottler: Bottler;
  config: string;
  getServiceRequestsCreator: any;
  getServiceRequestsSuccessCreator: any;
  loading: boolean;
  serviceRequests: IServiceRequest[];
}

interface IUsageReportsState {
  columns: IReactTableColumn[];
  dateRangeUsageTotal: string;
  end: moment.Moment;
  endIso: string | null; // ISO string with timezone
  focusedInput: any;
  message: string;
  start: moment.Moment;
  startIso: string | null; // ISO string with timezone
  timezone: string;
  timezoneOffset: string;
  timezones: ITimezoneOption[];
  usage: IRequestUsage[];
  usageError: Error | null;
  usageLoading: boolean;
}

class UsageReports extends React.Component<IUsageReportsProps, IUsageReportsState> {
  public constructor(props: IUsageReportsProps) {
    super(props);

    const timezones = this._getTimezones();

    this.state = {
      columns: [],
      dateRangeUsageTotal: "",
      end: moment(),
      endIso: null,
      focusedInput: null,
      message: "",
      start: moment().subtract(29, "days"),
      startIso: null,
      timezone: timezones[0].timezone,
      timezoneOffset: timezones[0].value,
      timezones: timezones,
      usage: [],
      usageError: null,
      usageLoading: false
    };
  }

  public componentDidMount(): void {
    const columns = this._getColumns();
    const endIso = this._getDefaultEndIso();
    const startIso = this._getDefaultStartIso();

    this.setState(
      {
        columns,
        endIso,
        startIso
      },
      () => {
        this._getServiceRequests();
        this._getUsage();
      }
    );
  }

  public componentDidUpdate(prevProps: IUsageReportsProps): void {
    const { appId, bottler } = this.props;

    if (bottler !== prevProps.bottler || appId !== prevProps.appId) {
      const columns = this._getColumns();
      this.setState({ columns });
      this._getServiceRequests();
      this._getUsage();
    }
  }

  public render() {
    const { bottler, loading, serviceRequests, appId } = this.props;
    const { columns, end, focusedInput, message, start, timezoneOffset, usage, usageError, usageLoading } = this.state;
    let appTitle: string = `Bottler App`;
    if (appId === Apps.COKE_SERVICES_APP) {
      appTitle = "Service App";
    }
    const title: string = `Repair Requests: Usage Reports for ${appTitle}`;
    return (
      <div className="reports">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <RefreshGroup refreshId="usage-reports" handleRefresh={this._getServiceRequests} isLoading={loading} />
          <Permissions title={title}>
            <BottlerSwitcher requestTypeId={RequestTypes.EquipmentRepair} showAllOption />
            <LoaderWrapper>
              {usage && (
                <Grid padded={true}>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <div className="usageReportsControls">
                        <div className="usageReportsDatePicker">
                          <DateRangePicker
                            displayFormat={"M/D/YYYY"}
                            startDate={start || this._getDefaultStartIso()}
                            endDate={end || this._getDefaultEndIso()}
                            onDatesChange={({ startDate, endDate }) => this._handleDateChange(startDate, endDate)}
                            focusedInput={focusedInput || null}
                            onFocusChange={input => this.setState({ focusedInput: input })}
                            isOutsideRange={() => false}
                            startDateId={"startDate"}
                            endDateId={"endDate"}
                          />
                          <br />
                          {serviceRequests && (
                            <Label basic={true} color="red" pointing={true}>
                              Total Requests: {serviceRequests.length}
                            </Label>
                          )}
                        </div>
                        <div className="usageReportsTimezonePicker">
                          {timezoneOffset && (
                            <Select
                              className="selectLargest"
                              id="timezone"
                              name="timezone"
                              onChange={e => this._handleTimezoneChange(e)}
                              options={this._renderTimezoneOptions()}
                              value={timezoneOffset}
                            />
                          )}
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>

                  {serviceRequests && serviceRequests.length === 0 && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Message content={"There are no service requests for your date range."} icon="warning sign" negative={true} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {message && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Message content={message} icon="warning sign" negative={true} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {usageError && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Message content={usageError.message} icon="warning sign" negative={true} />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  {serviceRequests && serviceRequests.length > 0 && usage.length > 0 && !usageError && (
                    <>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <ResponsiveContainer width="99%" height={400}>
                            <LineChart width={1200} height={400} data={usage}>
                              <XAxis dataKey="usageDateFriendly" />
                              <Tooltip />
                              <YAxis />
                              <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                              <Line type="monotone" dataKey="usageTotal" stroke="#e51e2b" />
                            </LineChart>
                          </ResponsiveContainer>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <button className="ui grey large basic button" onClick={e => this._downloadUsage(e)} type="button">
                            <Icon name="file excel outline" />
                            Download {bottler && bottler.name ? bottler.name : "All"} Usage
                          </button>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Hr />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <ReactTable data={serviceRequests} columns={columns} showPageSizeOptions={false} showPageJump={false} defaultPageSize={10} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <button className="ui grey large basic button" onClick={e => this._downloadServiceRequests(e)} type="button">
                            <Icon name="file excel outline" />
                            Download {bottler && bottler.name ? bottler.name : "All"} Service Requests
                          </button>
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                </Grid>
              )}
              <Loader loading={loading || usageLoading} position="Top" showImage={true} type="Overlay" />
            </LoaderWrapper>
          </Permissions>
        </Section>
      </div>
    );
  }

  private _handleDateChange = (startDate: moment.Moment | null, endDate: moment.Moment | null): void => {
    const { timezoneOffset } = this.state;

    let { endIso, startIso } = this.state;

    if (endDate) {
      const date = moment(endDate).format("YYYY-MM-DD");
      endIso = `${date}T23:59:59${timezoneOffset}`;
    }

    if (startDate) {
      const date = moment(startDate).format("YYYY-MM-DD");
      startIso = `${date}T00:00:00${timezoneOffset}`;
    }

    this.setState(
      {
        end: endDate ? endDate : this.state.end,
        endIso,
        start: startDate ? startDate : this.state.start,
        startIso
      },
      () => {
        this._getServiceRequests();
        this._getUsage();
      }
    );
  };

  private _handleTimezoneChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();

    const { value } = e.target;

    const { timezones } = this.state;

    if (value) {
      let timezone = "";
      timezones.map(_timezone => {
        if (_timezone.value === value) {
          timezone = _timezone.timezone;
        }
      });

      this.setState(
        {
          timezone,
          timezoneOffset: value
        },
        () => {
          const columns = this._getColumns();

          const { timezoneOffset } = this.state;
          let { endIso, startIso } = this.state;

          if (endIso && startIso) {
            endIso = endIso.slice(0, 19);
            endIso = `${endIso}${timezoneOffset}`;

            startIso = startIso.slice(0, 19);
            startIso = `${startIso}${timezoneOffset}`;
          }

          this.setState(
            {
              columns,
              endIso,
              startIso
            },
            () => {
              this._getServiceRequests();
              this._getUsage();
            }
          );
        }
      );
    }
  };

  private _getDefaultStartIso = (): string => {
    const { timezoneOffset } = this.state;

    let startDate = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD");

    startDate = `${startDate}T00:00:00${timezoneOffset}`;

    return startDate;
  };

  private _getDefaultEndIso = (): string => {
    const { timezoneOffset } = this.state;

    let endDate = moment().format("YYYY-MM-DD");

    endDate = `${endDate}T23:59:59${timezoneOffset}`;

    return endDate;
  };

  private _getServiceRequests = (): void => {
    const { bottler, getServiceRequestsSuccessCreator, getServiceRequestsCreator, appId } = this.props;
    const { endIso, startIso } = this.state;

    getServiceRequestsSuccessCreator(null);
    getServiceRequestsCreator(
      `/api/v4/equipmentRepairRequestsList?startDate=${startIso}&endDate=${endIso}&appId=${appId}${bottler ? `&bottlerId=${bottler.id}` : ""}`,
      "equipmentRepairRequests"
    );

    this.setState({
      message: ""
    });
  };

  private _downloadServiceRequests = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { bottler, serviceRequests, appId } = this.props;
    const { end, start, timezone } = this.state;

    const header: Row = [];

    header.push(
      {
        value: "UUID",
        type: "string"
      },
      {
        value: "Created On ISO",
        type: "string"
      },
      {
        value: "Date",
        type: "string"
      },
      {
        value: "Time",
        type: "string"
      }
    );

    if (!bottler) {
      header.push({
        value: "Bottler Name",
        type: "string"
      });
    }

    header.push(
      {
        value: "Created By",
        type: "string"
      },
      {
        value: "Created By Email",
        type: "string"
      },
      {
        value: "Outlet Number",
        type: "string"
      },
      {
        value: "Outlet Name",
        type: "string"
      },
      {
        value: "Outlet Street",
        type: "string"
      },
      {
        value: "Outlet City",
        type: "string"
      },
      {
        value: "Outlet State",
        type: "string"
      },
      {
        value: "Equipment Serial",
        type: "string"
      },
      {
        value: "Contact",
        type: "string"
      },
      {
        value: "Contact Phone",
        type: "string"
      },
      {
        value: "Contact Phone Extension",
        type: "string"
      },
      {
        value: "Equipment Type",
        type: "string"
      },
      {
        value: "Problem Code",
        type: "string"
      },
      {
        value: "Equipment Location",
        type: "string"
      },
      {
        value: "Comments",
        type: "string"
      },
      {
        value: "Hours Available",
        type: "string"
      },
      {
        value: " Days Available",
        type: "string"
      },
      {
        value: "Ticket Number",
        type: "string"
      }
    );

    if (appId === Apps.COKE_SERVICES_APP) {
      header.push(
        {
          value: "Equipment Street",
          type: "string"
        },
        {
          value: "Equipment City",
          type: "string"
        },
        {
          value: "Equipment State",
          type: "string"
        },
        {
          value: "Equipment Postal Code",
          type: "string"
        },
        {
          value: "Request Contact Email",
          type: "string"
        },
        {
          value: "Request Contact Email Notification",
          type: "string"
        },
        {
          value: "Request Contact First Name",
          type: "string"
        },
        {
          value: "Request Contact Last Name",
          type: "string"
        },
        {
          value: "Request Contact Phone",
          type: "string"
        },
        {
          value: "Request Contact Phone Notification",
          type: "string"
        },
        {
          value: "Request Contact Text Notification",
          type: "string"
        },
        {
          value: "Request Contact Role",
          type: "string"
        },
        {
          value: "Employee Name",
          type: "string"
        },
        {
          value: "Employee Email",
          type: "string"
        },
        {
          value: "Employee Phone",
          type: "string"
        },
        {
          value: "Employee Email Notification",
          type: "string"
        },
        {
          value: "Employee Text Notification",
          type: "string"
        }
      );
    }

    header.push({
      value: "Type",
      type: "string"
    });

    const data: Data = [];

    data.push(header);

    serviceRequests.map(serviceRequest => {
      const date: string = createdAtDate(serviceRequest.createdAt, timezone);
      const time: string = createdAtTime(serviceRequest.createdAt, timezone);
      const createdOnIso: string = createdAtIso(serviceRequest.createdAt, timezone);
      const hours: string = outletHours(serviceRequest.from, serviceRequest.to);

      const cell: Cell[] = [];

      cell.push(
        {
          value: serviceRequest.uuid,
          type: "string"
        },
        {
          value: createdOnIso,
          type: "string"
        },
        {
          value: date,
          type: "string"
        },
        {
          value: time,
          type: "string"
        }
      );

      if (!bottler) {
        cell.push({
          value: sanitizeCsvString(serviceRequest.bottlerName),
          type: "string"
        });
      }

      cell.push(
        {
          value: sanitizeCsvString(serviceRequest.userName),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.userEmail),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletNumber),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletName),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletStreet),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletCity),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletState),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentSerialNumber),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletContact),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletPhone),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.outletPhoneExtension),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentType),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.problemCodeDescription),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentLocation),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.comment),
          type: "string"
        },
        {
          value: hours,
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.availabilityDays),
          type: "string"
        },
        {
          value: sanitizeCsvString(serviceRequest.equipmentRepairTicketNumber),
          type: "string"
        }
      );
      if (appId === Apps.COKE_SERVICES_APP) {
        cell.push(
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationStreet),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationCity),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationState),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestEquipmentLocationPostalCode),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactEmail),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactEmailNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactFirstName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactLastName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactPhone),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactPhoneNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.requestContactTextNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.contactRoleName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeName),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeEmail),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeePhone),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeEmailNotification == null ? null : serviceRequest.employeeEmailNotification ? "Yes" : "No"),
            type: "string"
          },
          {
            value: sanitizeCsvString(serviceRequest.employeeTextNotification == null ? null : serviceRequest.employeeTextNotification ? "Yes" : "No"),
            type: "string"
          }
        );
      }
      cell.push({
        value: serviceRequest.requestType,
        type: "string"
      });

      data.push(cell);
    });

    const filename: string = `${appId === Apps.COKE_SERVICES_APP ? "Services_App_" : "Bottler_App_"}Requests_Report_${bottler ? bottler.code : "All_Bottlers"}_${moment(
      start
    ).format("M-D-YYYY")}_${moment(end).format("M-D-YYYY")}`;

    const sheet: Sheet = {
      data
    };

    const config: Config = {
      filename,
      sheet
    };

    zipcelx(config);
  };

  private _downloadUsage = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { bottler } = this.props;
    const { end, start, usage } = this.state;

    const header: Row = [
      {
        value: "Date",
        type: "string"
      },
      {
        value: "Total",
        type: "string"
      }
    ];

    const data: Data = [];

    data.push(header);

    usage.map(use => {
      data.push([
        {
          value: sanitizeCsvString(use.usageDateFriendly),
          type: "string"
        },
        {
          value: sanitizeCsvString(use.usageTotal.toString()),
          type: "number"
        }
      ]);
    });

    const filename: string = `Usage_Report_${bottler ? bottler.code : "All_Bottlers"}_${moment(start).format("M-D-YYYY")}_${moment(end).format("M-D-YYYY")}`;

    const sheet: Sheet = {
      data
    };

    const config: Config = {
      filename,
      sheet
    };

    zipcelx(config);
  };

  private _getUsage = (): void => {
    const { bottler, appId } = this.props;
    const { end, start } = this.state;

    const endIso = end.toISOString();
    const startIso = start.toISOString();

    this.setState({ usageLoading: true }, async () => {
      try {
        const usageDateStart = moment(startIso).format("YYYY-MM-DD");
        const usageDateEnd = moment(endIso).format("YYYY-MM-DD");
        const response = await getRequestUsages(
          bottler,
          RequestTypes.EquipmentRepair,
          usageDateStart,
          usageDateEnd,
          appId,
          appId === Apps.COKE_SERVICES_APP ? RequestTypes.CustomerService : undefined
        );
        let usage = response.requestUsages;
        // aggregate the usage totals for when the user selects "All"
        if (!bottler) {
          // @ts-ignore
          usage = response.requestUsages.reduce((acc: any[], curr: IRequestUsage) => {
            const item = acc.filter(a => a.usageDate === curr.usageDate)[0];
            const indexOf = acc.indexOf(item);
            if (indexOf > -1) {
              // we sum usage total if the dates match
              acc[indexOf] = { ...curr, usageTotal: item.usageTotal + curr.usageTotal };
            } else {
              acc.push(curr);
            }
            return acc;
          }, []);
        }
        this.setState({
          usage,
          usageLoading: false
        });
      } catch (error) {
        this.setState({
          usage: [],
          usageError: error,
          usageLoading: false
        });
      }
    });
  };

  private _getColumns = (): IReactTableColumn[] => {
    const { bottler, appId } = this.props;
    const { timezone } = this.state;

    if (timezone) {
      return UsageReportsColumns(timezone, !bottler ? true : false, appId);
    } else {
      return [];
    }
  };

  private _getTimezones = (): ITimezoneOption[] => {
    if (moment().isDST()) {
      return DaylightSavingsTimezoneOptions;
    } else {
      return StandardTimezoneOptions;
    }
  };

  private _renderTimezoneOptions = (): JSX.Element[] => {
    const { timezones } = this.state;

    return timezones.map(timezone => {
      return (
        <option key={timezone.value} value={timezone.value}>
          {timezone.label}
        </option>
      );
    });
  };
}

export const mapStateToProps = (state: any) => {
  return {
    bottler: state.bottler.bottler,
    bottlers: state.bottlers.bottlers,
    loading: state.servicerequests.isLoading,
    program: state.program.program,
    serviceRequests: state.servicerequests.servicerequests
  };
};

export default connect(mapStateToProps, {
  getServiceRequestsCreator,
  getServiceRequestsSuccessCreator
})(withRouter(withPageLog(UsageReports)));
