import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { toast } from "react-toastify";
// components
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import Alert from "../../../components/alert/Alert";
// types
import { PayrollPeriod } from "../../../types/PayrollPeriod";
import { IRequest, IRequestUpdate } from "../../../types/Request";
import { IRequestType } from "../../../types/RequestType";
import { IRequestExport, IRequestExportCreate } from "../../../types/RequestExport";
import { Task } from "../../../types/Task";
import { IUser } from "../../../types/User";
import { IWageType } from "../../../types/WageType";
import { IPayArea } from "../../../types/PayArea";
import { ICurrencyType } from "../../../types/CurrencyType";
// fetch
import { getCurrencyTypes } from "../../../services/CurrencyType";
import { getPayAreas } from "../../../services/PayArea";
import { getWageTypes } from "../../../services/WageType";
// helpers
import { fullFriendlyDateTime, shortFriendlyDateTime } from "../../../helpers/time";
import { getRequest, postRequest, putRequest } from "../../../core/api/requestConfig";
import zipcelx, { Config, Sheet, Row, Data } from "../../../helpers/zipcelx";

export interface IRequestExportProps {
  _afterExport?: any;
  payrollPeriod: PayrollPeriod;
  request: IRequest;
  requestType?: IRequestType;
  showExportHistory: boolean;
  user: IUser;
}

export interface IRequestExportState {
  currencyTypes: ICurrencyType[];
  exporting: boolean;
  exportHistory: IRequestExport[];
  isOpen: boolean;
  loading: boolean;
  payAreas: IPayArea[];
  wageTypes: IWageType[];
}

class RequestExport extends React.Component<IRequestExportProps, IRequestExportState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currencyTypes: [],
      exporting: false,
      exportHistory: [],
      isOpen: false,
      loading: false,
      payAreas: [],
      wageTypes: []
    };
    this._getExportHistory = this._getExportHistory.bind(this);
    this._getTasks = this._getTasks.bind(this);
    this._exportRequest = this._exportRequest.bind(this);
    this._handleExport = this._handleExport.bind(this);
    this._addExportHistory = this._addExportHistory.bind(this);
    this._saveRequest = this._saveRequest.bind(this);
    this._toggleMore = this._toggleMore.bind(this);
  }

  public componentDidMount(): void {
    this._getExportHistory();
  }

  public render() {
    const { showExportHistory } = this.props;

    const { exporting, exportHistory, isOpen, loading } = this.state;

    // last exported
    const lastExportHistory: JSX.Element[] = [];
    exportHistory.slice(0, 1).map((history, index) => {
      if (history) {
        lastExportHistory.push(
          <li key={history.id}>
            Last exported
            {history.userId && history.user && (
              <span>
                {" by "}
                <a href={"mailto:" + history.user.email}>{history.user.name ? history.user.name : history.user.email}</a>
              </span>
            )}{" "}
            on {shortFriendlyDateTime(history.createdAt)}
          </li>
        );
      }
    });

    const lastExportHistoryList: JSX.Element = <ul className="exportHistory">{lastExportHistory}</ul>;

    // the rest of the history
    const exportHistories: JSX.Element[] = [];
    exportHistory.slice(1).map(history => {
      if (history) {
        exportHistories.push(
          <li key={history.id}>
            Exported
            {history.userId && history.user && (
              <span>
                {" by "}
                <a href={"mailto:" + history.user.email}>{history.user.name ? history.user.name : history.user.email}</a>
              </span>
            )}{" "}
            on {shortFriendlyDateTime(history.createdAt)}
          </li>
        );
      }
    });

    const exportHistoryList: JSX.Element = <ul className={isOpen ? "exportHistory" : "displayNone"}>{exportHistories}</ul>;

    // history for the modal
    const fullExportHistory: JSX.Element[] = [];
    exportHistory.map(history => {
      if (history) {
        fullExportHistory.push(
          <li key={history.id}>
            Exported
            {history.userId && history.user && (
              <span>
                {" by "}
                <a href={"mailto:" + history.user.email}>{history.user.name ? history.user.name : history.user.email}</a>
              </span>
            )}{" "}
            on {shortFriendlyDateTime(history.createdAt)}
          </li>
        );
      }
    });

    const fullExportHistoryList: JSX.Element = <ul className="exportHistory">{fullExportHistory}</ul>;

    const exportButton: JSX.Element = (
      <Button
        className={showExportHistory ? "buttonInfo exportRequest" : "buttonLinkInfoColor"}
        disabled={exporting}
        iconLeft={<Icon iconName="ExcelDocument" />}
        onClick={e => this._exportRequest(e)}
        text={showExportHistory && !exporting ? "Export Excel" : exporting ? "Exporting..." : "Export"}
        title={exporting ? "Exporting..." : "Export Excel"}
      />
    );

    const modalBody: JSX.Element = (
      <div key="export-modal-body">
        <p>This request has already been exported:</p>
        {fullExportHistoryList}
        <p>
          <strong>Are you sure you want to export it again?</strong>
        </p>
      </div>
    );

    const modalFooter: JSX.Element = <Alert show={true} icon="Info" text="Merged entries will not be exported." type="Info" />;

    const exportModal: JSX.Element =
      exportHistory.length > 1 ? (
        <Modal
          id="export-modal"
          modalSize="Xs"
          disabled={exporting || loading}
          onSave={e => this._exportRequest(e)}
          heading={"Export To Excel"}
          body={[modalBody]}
          footer={modalFooter}
          openClassName={showExportHistory ? "buttonInfo exportRequest" : "buttonLinkInfoColor"}
          openIcon={"ExcelDocument"}
          openText={showExportHistory && !exporting ? "Export Excel" : exporting ? "Exporting..." : "Export"}
          openTitle={"Export Excel"}
          cancelIcon={"Cancel"}
          cancelText={"Cancel"}
          cancelTitle={"Cancel"}
          saveDisabled={false}
          saveIcon={"ExcelDocument"}
          saveText={"Export Again"}
          saveTitle={"Export Again"}
          showCancel={true}
          showSave={true}
        />
      ) : (
        exportButton
      );
    return (
      <div className="requestExport">
        {exportModal}
        {showExportHistory && loading ? <Loader loading={loading} text="Loading history..." /> : null}
        {showExportHistory && !loading && lastExportHistory.length > 0 ? lastExportHistoryList : null}
        {showExportHistory && !loading && exportHistory.length > 0 ? exportHistoryList : null}
        {showExportHistory && !loading && exportHistory.length > 0 ? (
          <Button
            className="toggleMore"
            iconLeft={<Icon iconName={isOpen ? "CaretSolidUp" : "CaretSolidDown"} />}
            onClick={e => this._toggleMore(e)}
            text={isOpen ? "Show Less" : "Show More"}
            title={isOpen ? "Show Less" : "Show More"}
          />
        ) : null}
      </div>
    );
  }

  private _getExportHistory(): void {
    const { request } = this.props;

    this.setState(
      {
        loading: true
      },
      () => {
        if (request) {
          getRequest("/legacy/api/v1/requestexport/request/" + request.id)
            .then(exportHistory => {
              if (exportHistory.error) {
                toast.error(exportHistory.error, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                  exportHistory: [],
                  loading: false
                });
              } else {
                this.setState({
                  exportHistory: exportHistory,
                  loading: false
                });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("There was an error fetching request export history.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                exportHistory: [],
                loading: false
              });
            });
        }
      }
    );
  }

  private _exportRequest(e: any): void {
    e.preventDefault();
    this.setState(
      {
        exporting: true
      },
      async () => {
        try {
          const currencyTypes = await getCurrencyTypes();
          const payAreas = await getPayAreas();
          const wageTypes = await getWageTypes();

          if (currencyTypes && payAreas && wageTypes) {
            this.setState(
              {
                currencyTypes: currencyTypes,
                payAreas: payAreas,
                wageTypes: wageTypes
              },
              () => {
                this._getTasks();
              }
            );
          } else {
            toast.error("There was an error fetching currency types, pay areas, and wage types.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              currencyTypes: [],
              exporting: false,
              payAreas: [],
              wageTypes: []
            });
          }
        } catch (error) {
          toast.error("There was an error fetching pay areas and wage types.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            exporting: false,
            payAreas: [],
            wageTypes: []
          });
        }
      }
    );
  }

  private _getTasks(): void {
    const { request } = this.props;

    this.setState(
      {
        exporting: true
      },
      () => {
        if (request) {
          getRequest("/legacy/api/v1/task/request/" + request.id)
            .then(tasks => {
              if (tasks.error) {
                toast.error(tasks.error, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                  exporting: false
                });
              } else {
                this._handleExport(tasks);
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("There was an error fetching request tasks.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                exporting: false
              });
            });
        }
      }
    );
  }

  private _handleExport(tasks: Task[]): void {
    const { request, requestType } = this.props;
    const { currencyTypes, payAreas, wageTypes } = this.state;

    if (requestType) {
      const timestamp: string = moment().format("M-D-YYYY-h-mm-a");
      const name: string = `${requestType.label} (${requestType.code})`;

      const data: Data = [];

      if (requestType && requestType.id === 1) {
        const header: Row = [
          {
            value: "First Name",
            type: "string"
          },
          {
            value: "Middle Initial",
            type: "string"
          },
          {
            value: "Last Name",
            type: "string"
          },
          {
            value: "Suffix",
            type: "string"
          },
          {
            value: "Nickname",
            type: "string"
          },
          {
            value: "PERNR",
            type: "string"
          },
          {
            value: "Cost Center",
            type: "string"
          },
          {
            value: "Pay Area",
            type: "string"
          },
          {
            value: "Wage Type",
            type: "string"
          },
          {
            value: "Amount",
            type: "string"
          },
          {
            value: "Currency",
            type: "string"
          },
          {
            value: "Units",
            type: "string"
          },
          {
            value: "Date",
            type: "string"
          },
          {
            value: "Authority",
            type: "string"
          },
          {
            value: "Comment",
            type: "string"
          }
        ];

        data.push(header);
      }

      if (requestType && requestType.id === 2) {
        // NOTICE: if you update the order here, also update below
        const header: Row = [
          {
            value: "First Name",
            type: "string"
          },
          {
            value: "Middle Initial",
            type: "string"
          },
          {
            value: "Last Name",
            type: "string"
          },
          {
            value: "Suffix",
            type: "string"
          },
          {
            value: "Nickname",
            type: "string"
          },
          {
            value: "PERNR",
            type: "string"
          },
          {
            value: "Cost Center",
            type: "string"
          },
          {
            value: "Pay Area",
            type: "string"
          },
          {
            value: "Wage Type",
            type: "string"
          },
          {
            value: "Amount",
            type: "string"
          },
          {
            value: "Currency",
            type: "string"
          },
          {
            value: "Units",
            type: "string"
          },
          {
            value: "Dollar Limit",
            type: "string"
          },
          {
            value: "Date",
            type: "string"
          },
          {
            value: "Authority",
            type: "string"
          },
          {
            value: "Comment",
            type: "string"
          }
        ];

        data.push(header);
      }

      if (requestType && requestType.id === 3) {
        // NOTICE: if you update the order here, also update below
        const header: Row = [
          {
            value: "First Name",
            type: "string"
          },
          {
            value: "Middle Initial",
            type: "string"
          },
          {
            value: "Last Name",
            type: "string"
          },
          {
            value: "Suffix",
            type: "string"
          },
          {
            value: "Nickname",
            type: "string"
          },
          {
            value: "PERNR",
            type: "string"
          },
          {
            value: "Cost Center",
            type: "string"
          },
          {
            value: "Pay Area",
            type: "string"
          },
          {
            value: "Wage Type",
            type: "string"
          },
          {
            value: "Amount",
            type: "string"
          },
          {
            value: "Currency",
            type: "string"
          },
          {
            value: "Hours",
            type: "string"
          },
          {
            value: "Number",
            type: "string"
          },
          {
            value: "Rate",
            type: "string"
          },
          {
            value: "Units",
            type: "string"
          },
          {
            value: "Date",
            type: "string"
          },
          {
            value: "Authority",
            type: "string"
          },
          {
            value: "Comment",
            type: "string"
          }
        ];

        data.push(header);
      }

      tasks.map(task => {
        if (task && task.bottlerEmployee && !task.isMerged) {
          let wageTypeCode: string = "";
          wageTypes.map(wageType => {
            if (wageType && wageType.id === task.wageTypeId) {
              wageTypeCode = wageType.code;
            }
          });

          let payAreaLabel: string = "";
          payAreas.map(payArea => {
            if (payArea && task.bottlerEmployee && payArea.id === task.bottlerEmployee.payAreaId) {
              payAreaLabel = payArea.label;
            }
          });

          let currencyTypeLabel: string = "";
          currencyTypes.map(currencyType => {
            if (currencyType && currencyType.id === task.currencyTypeId) {
              currencyTypeLabel = currencyType.label;
            }
          });

          if (requestType && requestType.id === 1) {
            data.push([
              {
                value: task.bottlerEmployee.firstName,
                type: "string"
              },
              {
                value: task.bottlerEmployee.middleInitial ? task.bottlerEmployee.middleInitial : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.lastName,
                type: "string"
              },
              {
                value: task.bottlerEmployee.suffix ? task.bottlerEmployee.suffix : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.nickname ? task.bottlerEmployee.nickname : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.pernr,
                type: "string"
              },
              {
                value: task.bottlerEmployee.costCenter,
                type: "string"
              },
              {
                value: payAreaLabel,
                type: "string"
              },
              {
                value: wageTypeCode,
                type: "string"
              },
              {
                value: task.amount ? task.amount.toString() : "",
                type: "string"
              },
              {
                value: currencyTypeLabel,
                type: "string"
              },
              {
                value: task.units ? task.units : "",
                type: "string"
              },
              {
                value: task.dateOfPay ? task.dateOfPay : "",
                type: "string"
              },
              {
                value: task.authority,
                type: "string"
              },
              {
                value: task.comment ? task.comment : "",
                type: "string"
              }
            ]);
          } else if (requestType && requestType.id === 2) {
            // NOTICE: if you update the order here, also update above
            data.push([
              {
                value: task.bottlerEmployee.firstName,
                type: "string"
              },
              {
                value: task.bottlerEmployee.middleInitial ? task.bottlerEmployee.middleInitial : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.lastName,
                type: "string"
              },
              {
                value: task.bottlerEmployee.suffix ? task.bottlerEmployee.suffix : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.nickname ? task.bottlerEmployee.nickname : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.pernr,
                type: "string"
              },
              {
                value: task.bottlerEmployee.costCenter,
                type: "string"
              },
              {
                value: payAreaLabel,
                type: "string"
              },
              {
                value: wageTypeCode,
                type: "string"
              },
              {
                value: task.amount ? task.amount.toString() : "",
                type: "string"
              },
              {
                value: currencyTypeLabel,
                type: "string"
              },
              {
                value: task.units ? task.units : "",
                type: "string"
              },
              {
                value: task.dollarLimit ? task.dollarLimit : "",
                type: "string"
              },
              {
                value: task.dateOfPay ? task.dateOfPay : "",
                type: "string"
              },
              {
                value: task.authority,
                type: "string"
              },
              {
                value: task.comment ? task.comment : "",
                type: "string"
              }
            ]);
          } else if (requestType && requestType.id === 3) {
            // NOTICE: if you update the order here, also update above
            data.push([
              {
                value: task.bottlerEmployee.firstName,
                type: "string"
              },
              {
                value: task.bottlerEmployee.middleInitial ? task.bottlerEmployee.middleInitial : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.lastName,
                type: "string"
              },
              {
                value: task.bottlerEmployee.suffix ? task.bottlerEmployee.suffix : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.nickname ? task.bottlerEmployee.nickname : "",
                type: "string"
              },
              {
                value: task.bottlerEmployee.pernr,
                type: "string"
              },
              {
                value: task.bottlerEmployee.costCenter,
                type: "string"
              },
              {
                value: payAreaLabel,
                type: "string"
              },
              {
                value: wageTypeCode,
                type: "string"
              },
              {
                value: task.amount ? task.amount.toString() : "",
                type: "string"
              },
              {
                value: currencyTypeLabel,
                type: "string"
              },
              {
                value: task.hours ? task.hours : "",
                type: "string"
              },
              {
                value: task.number ? task.number : "",
                type: "string"
              },
              {
                value: task.rate ? task.rate : "",
                type: "string"
              },
              {
                value: task.units ? task.units : "",
                type: "string"
              },
              {
                value: task.dateOfPay ? task.dateOfPay : "",
                type: "string"
              },
              {
                value: task.authority,
                type: "string"
              },
              {
                value: task.comment ? task.comment : "",
                type: "string"
              }
            ]);
          }
        }
      });

      if (data.length > 0) {
        const filename: string =
          request && request.requestImportFilename && request.bottler
            ? `${request.bottler.name} ${request.requestImportFilename}`
            : request.bottler && requestType
            ? `${request.bottler.name} ${name} ${timestamp}`
            : `${timestamp}`;

        this._addExportHistory();

        const sheet: Sheet = {
          data
        };

        const config: Config = {
          filename,
          sheet
        };

        zipcelx(config);
      } else {
        toast.error("Tasks could not be exported because all tasks are missing data.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    }
  }

  private _addExportHistory(): void {
    const { request, user } = this.props;

    const now: string = moment().toISOString();

    if (request) {
      const requestExport: IRequestExportCreate = {
        createdAt: now,
        deletedAt: null,
        requestId: request.id,
        updatedAt: now,
        userId: user.id
      };

      postRequest("/legacy/api/v1/requestexport", requestExport)
        .then(history => {
          if (history.error) {
            toast.error(history.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
              exporting: false
            });
          } else {
            this.setState(
              {
                exporting: false
              },
              () => {
                this._getExportHistory();
                this._saveRequest();
              }
            );
          }
        })
        .catch(error => {
          console.error(error);
          toast.error("There was an error logging the request export.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.setState({
            exporting: false
          });
        });
    }
  }

  private _saveRequest(): void {
    const { _afterExport, payrollPeriod, request, user } = this.props;

    if (payrollPeriod && request) {
      const now: string = moment().toISOString();

      const updateRequest: IRequestUpdate = {
        assignedTo: request.assignedTo,
        createdAt: request.createdAt,
        createdAtFormatted: request.createdAtFormatted,
        dueDate: request.dueDate,
        dueDateReminderSent: request.dueDateReminderSent,
        deletedAt: null,
        description: request.description,
        id: request.id,
        requestImportFilename: request.requestImportFilename,
        requestTypeId: request.requestTypeId,
        requestPriority: request.requestPriority,
        requestPriorityId: request.requestPriorityId,
        bottlerId: request.bottlerId,
        payrollPeriodId: payrollPeriod.id,
        seenSinceLastUpdate: false,
        statusId: 3, // 3 = BSNA Processing
        summary: request.summary,
        title: request.title,
        updatedByAdmin: true,
        updatedByUserId: user.id,
        updatedAt: now,
        updatedAtFormatted: fullFriendlyDateTime(now),
        userId: request.userId,
        uuid: request.uuid
      };

      putRequest("/legacy/api/v1/request", updateRequest)
        .then(req => {
          if (req.error) {
            toast.error(req.error, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          } else {
            toast.success("Request exported successfully.", {
              position: toast.POSITION.BOTTOM_CENTER
            });
            if (_afterExport) {
              _afterExport();
            }
          }
        })
        .catch(error => {
          console.error(error);
          toast.error("Error saving request after exporting.", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        });
    }
  }

  private _toggleMore(e: any): void {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  }
}

export const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user.user,
    ownProps
  };
};

export default connect(mapStateToProps)(RequestExport);
