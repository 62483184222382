import { getRequest } from "../../api/requestConfig";

export type ServiceRequests = {
  servicerequests: any;
} | null;

enum TypeKeys {
  GET_SERVICE_REQUESTS_REQUEST = "GET_SERVICEREQUESTS_REQUEST",
  GET_SERVICE_REQUESTS_SUCCESS = "GET_SERVICEREQUESTS_SUCCESS",
  GET_SERVICE_REQUESTS_FAILURE = "GET_SERVICEREQUESTS_FAILURE"
}

type Error = string | null;

type getServiceRequestsRequest = {
  isLoading: boolean;
  type: TypeKeys.GET_SERVICE_REQUESTS_REQUEST;
};

type getServiceRequestsSuccess = {
  servicerequests: ServiceRequests;
  type: TypeKeys.GET_SERVICE_REQUESTS_SUCCESS;
};

type getServiceRequestsFailure = {
  error: Error;
  type: TypeKeys.GET_SERVICE_REQUESTS_FAILURE;
};

/**
 *
 * @param servicerequests servicerequests values
 */
export const getServiceRequestsRequest = (isLoading: boolean): getServiceRequestsRequest => {
  return {
    isLoading,
    type: TypeKeys.GET_SERVICE_REQUESTS_REQUEST
  };
};

/**
 *
 * @param servicerequests servicerequests values
 */
export const getServiceRequestsSuccessCreator = (servicerequests: ServiceRequests): getServiceRequestsSuccess => {
  return {
    servicerequests,
    type: TypeKeys.GET_SERVICE_REQUESTS_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const getServiceRequestsFailureCreator = (error: Error): getServiceRequestsFailure => {
  return {
    error,
    type: TypeKeys.GET_SERVICE_REQUESTS_FAILURE
  };
};

/**
 *
 * @param apiUrl relative url for api endpoint
 */
export const getServiceRequestsCreator = (apiUrl: string, dataKey?: string) => {
  return async dispatch => {
    dispatch(getServiceRequestsRequest(true));

    try {
      const response = await getRequest(apiUrl);
      dispatch(getServiceRequestsSuccessCreator(dataKey ? response[dataKey] : response));
    } catch (error) {
      console.log(error);
      dispatch(getServiceRequestsFailureCreator(error));
    }
  };
};

export type Actions = getServiceRequestsRequest | getServiceRequestsSuccess | getServiceRequestsFailure;

export interface IState {
  isLoading: boolean;
  servicerequests: ServiceRequests;
  error: Error;
}

export const initialState = {
  isLoading: false,
  servicerequests: null,
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_SERVICE_REQUESTS_REQUEST:
      newState.isLoading = action.isLoading;
      break;
    case TypeKeys.GET_SERVICE_REQUESTS_SUCCESS:
      newState.servicerequests = action.servicerequests;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_SERVICE_REQUESTS_FAILURE:
      newState.error = action.error as string;
      newState.servicerequests = null;
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};
