import { getRequest } from "../../api/requestConfig";
import { getLocalStorage, setLocalStorage } from "../../storage/localStorage";

type Program = {
  description: string;
  id: string;
  phoneNumber: string;
  programID: string;
  toNotificationEmail: string;
  userDomain: string;
} | null;

enum TypeKeys {
  GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS",
  GET_PROGRAM_FAILURE = "GET_PROGRAM_FAILURE",
  SET_PROGRAM_SUCCESS = "SET_PROGRAM_SUCCESS",
  SET_PROGRAM_FAILURE = "SET_PROGRAM_FAILURE"
}

type Error = string | null;

type getProgramSuccess = {
  program: Program;
  type: TypeKeys.GET_PROGRAM_SUCCESS;
};

type getProgramFailure = {
  error: Error;
  type: TypeKeys.GET_PROGRAM_FAILURE;
};

type setProgramSuccess = {
  program: Program;
  type: TypeKeys.SET_PROGRAM_SUCCESS;
};

type setProgramFailure = {
  error: Error;
  type: TypeKeys.SET_PROGRAM_FAILURE;
};

/**
 *
 * @param program program values
 */
export const getProgramSuccessCreator = (
  program: Program
): getProgramSuccess => {
  return {
    program,
    type: TypeKeys.GET_PROGRAM_SUCCESS
  };
};

/**
 *
 * @param error error message
 */
export const getProgramFailureCreator = (error: Error): getProgramFailure => {
  return {
    error,
    type: TypeKeys.GET_PROGRAM_FAILURE
  };
};

/**
 *
 * @param program program values
 */
export const setProgramSuccessCreator = (
  program: Program
): setProgramSuccess => {
  return {
    program,
    type: TypeKeys.SET_PROGRAM_SUCCESS
  };
};

export const setProgramFailureCreator = (error: Error): setProgramFailure => {
  return {
    error,
    type: TypeKeys.SET_PROGRAM_FAILURE
  };
};

export const getProgramCreator = () => {
  return async dispatch => {
    try {
      let program: Program = null;
      const item: string | null = getLocalStorage("program");
      if (item) {
        program = JSON.parse(item);
      } else {
        const programs = await getRequest("/api/v1/listPrograms");
        program = programs[0];
        setLocalStorage("program", JSON.stringify(program));
      }
      dispatch(getProgramSuccessCreator(program));
    } catch (error) {
      console.log(error);
      dispatch(getProgramFailureCreator(error));
    }
  };
};

export const setProgramCreator = (program: Program) => {
  return async dispatch => {
    try {
      setLocalStorage("program", JSON.stringify(program));
      dispatch(setProgramSuccessCreator(program));
    } catch (error) {
      console.log(error);
      dispatch(setProgramFailureCreator(error));
    }
  };
};

export type Actions =
  | getProgramSuccess
  | getProgramFailure
  | setProgramSuccess
  | setProgramFailure;

export interface IState {
  isLoading: boolean;
  program: Program;
  error: Error;
}

export const initialState = {
  isLoading: true,
  program: null,
  error: null
};

export const reducer = (state: IState, action: Actions) => {
  state = !state ? initialState : state;
  const newState = { ...state };
  switch (action.type) {
    case TypeKeys.GET_PROGRAM_SUCCESS:
      newState.program = action.program;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.GET_PROGRAM_FAILURE:
      newState.error = action.error as string;
      newState.program = null;
      newState.isLoading = false;
      break;
    case TypeKeys.SET_PROGRAM_SUCCESS:
      newState.program = action.program;
      newState.error = null;
      newState.isLoading = false;
      break;
    case TypeKeys.SET_PROGRAM_FAILURE:
      newState.error = action.error as string;
      newState.program = null;
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};
