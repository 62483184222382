import * as React from "react";
// components
import Stat from "../stat/Stat";
import Label from "../label/Label";
import Select from "react-select";
import { P } from "../typography";
// hocs
import FetchData from "../../hocs/FetchData";
// types
import { IRequestTypesResponse, IRequestTypeV2 } from "../../types/RequestType";
import { IRequestPrioritiesRes, IRequestPriority } from "../../types/RequestPriorities";

interface IRequestMetadataProps {
  assignedTo?: JSX.Element | null;
  bottlerName: string;
  createdBy: string;
  createdAt: string;
  dueDate: JSX.Element | null;
  handleRequestTypeChange: (value: { value: IRequestTypeV2; label: string }) => void;
  handleRequestPriority: (value: { value: number; label: string }) => void;
  isHrRequest?: boolean;
  canChangeRequestTypes: boolean;
  payArea?: string;
  payrollPeriod?: string;
  requestType: string;
  requestPriority: string | null;
  requestPostPilotFF: boolean;
  selectedRequestTypeId: number | null;
  status: string;
  updatedAt: string;
  updatedAtHistory: JSX.Element;
}

interface IFetchDataActiveRequestType {
  data: IRequestTypesResponse;
}

type ReactSelectOption = {
  value: any;
  label: string;
};

type GroupOptions = {
  label: string;
  options: ReactSelectOption[];
};

const RequestMetadata = ({
  assignedTo,
  bottlerName,
  createdAt,
  createdBy,
  dueDate,
  handleRequestTypeChange,
  handleRequestPriority,
  isHrRequest,
  canChangeRequestTypes,
  payArea,
  payrollPeriod,
  requestType,
  requestPriority,
  requestPostPilotFF,
  selectedRequestTypeId,
  status,
  updatedAt,
  updatedAtHistory
}: IRequestMetadataProps) => {
  return (
    <div className="requestMetadata">
      <Stat className="column" title="Bottler" value={bottlerName} />
      {canChangeRequestTypes && requestPostPilotFF ? (
        <FetchData url="/api/v2/requestTypes">
          {({ data }: IFetchDataActiveRequestType) => {
            const groupOptions =
              data &&
              data.requestTypes &&
              data.requestTypes.map(type => ({
                label: type.requestProcessLabel,
                options: [{ value: type, label: type.label }]
              }));
            let selectedOption;
            if (groupOptions) {
              groupOptions.map((option: GroupOptions) =>
                option.options.map((o: ReactSelectOption) => {
                  if (o.value.id === selectedRequestTypeId) {
                    selectedOption = o;
                  }
                })
              );
            }

            return (
              <div className="column requestTypeSelect">
                <Label className="h6" htmlFor="request-type" text="Request Type" />
                {groupOptions && groupOptions.length > 0 ? (
                  <Select
                    className="reactSelect"
                    classNamePrefix="reactSelect"
                    inputId="request-type"
                    // menuIsOpen={true}
                    // @ts-ignore
                    onChange={handleRequestTypeChange}
                    options={groupOptions}
                    value={selectedOption}
                  />
                ) : (
                  <P>Loading...</P>
                )}
              </div>
            );
          }}
        </FetchData>
      ) : (
        <Stat className="column requestTypeSelect" title="Request Type" value={requestType} />
      )}
      {requestPostPilotFF && (
        <FetchData url="/api/v1/requestPriorities">
          {({ data }: { data: IRequestPrioritiesRes }) => {
            const options =
              data &&
              data.requestPriorities.map((d: IRequestPriority) => ({
                label: d.name,
                value: d.id
              }));
            let selectedOption = options && options.find(option => option.label === requestPriority);
            return (
              <div className="column requestPrioritiesSelect">
                <Label className="h6" htmlFor="request-type" text="Request Priority" />
                {options && options.length > 0 ? (
                  // @ts-ignore
                  <Select className="reactSelect" classNamePrefix="reactSelect" inputId="request-type" onChange={handleRequestPriority} options={options} value={selectedOption} />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            );
          }}
        </FetchData>
      )}
      {dueDate && !isHrRequest && <div className="column">{dueDate}</div>}
      {assignedTo && !isHrRequest && <div className="column">{assignedTo}</div>}
      <Stat className="column" title="Status" value={status} />
      <Stat className="column" title="Requester" value={createdBy} />
      <Stat className="column" title="Requested" value={createdAt} />
      <Stat className="column" title={updatedAtHistory} value={updatedAt} />
      {payArea && isHrRequest && <Stat className="column" title="Pay Area" value={payArea} />}
      {payrollPeriod && isHrRequest && <Stat className="column" title="Payroll Period" value={payrollPeriod} />}
    </div>
  );
};

export default RequestMetadata;
