import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import _sortBy from "lodash/sortBy";
import _findIndex from "lodash/findIndex";
// components
import RequestTypeModal from "./RequestTypeModal";
import Alert from "../../components/alert/Alert";
import Loader from "../../components/loader/Loader";
import LoaderGradient from "../../components/loader/LoaderGradient";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import { Search } from "../../components/search/Search";
import Sort from "../../components/table/Sort";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import Tr from "../../components/table/Tr";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import THead from "../../components/table/THead";
import TBody from "../../components/table/TBody";
// services
import {
  getRequestTypes,
  updateRequestTypeV2
} from "../../services/RequestType";
// types
import { IRequestTypeV2 } from "../../types/RequestType";

export interface IRequestTypeTableProps {}

export interface IRequestTypeTableState {
  isLoading: boolean;
  data: IRequestTypeV2[];
  sortBy: string;
  sortOrder: string;
  searchTerm: string;
}

export interface IColumn {
  sortBy: string;
  text: string;
  title: string;
}

const columns: IColumn[] = [
  { title: "Name", sortBy: "label", text: "Name" },
  { title: "Description", sortBy: "description", text: "Description" },
  { title: "Status", sortBy: "isActive", text: "Status" },
  { title: "Submittable", sortBy: "isSubmittable", text: "Submittable" },
  { title: "SLA", sortBy: "sla", text: "SLA" },
  {
    title: "Actions",
    sortBy: "",
    text: ""
  }
];

class RequestTypeTable extends React.PureComponent<
  IRequestTypeTableProps,
  IRequestTypeTableState
> {
  state = {
    isLoading: true,
    data: [],
    sortBy: "label",
    sortOrder: "DESC",
    searchTerm: ""
  };

  public async componentDidMount() {
    try {
      const response = await getRequestTypes();

      this.setState({
        isLoading: false,
        data: response.requestTypes
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.setState({
        isLoading: false
      });
    }
  }
  public render(): React.ReactElement<IRequestTypeTableProps> {
    const { data, isLoading, searchTerm } = this.state;

    return (
      <div className="defaultTable">
        <Search
          autoFocus={false}
          onSearchChange={e => this.setState({ searchTerm: e.target.value })}
          placeholder="Search types..."
          search={searchTerm}
          searchDisabled={isLoading}
          searchLabelText="Search Types"
          showExport={false}
          showSearchButton={false}
        />
        <LoaderWrapper>
          <TableWrapper loading={isLoading}>
            <Table>
              <THead>
                <Tr>{this.renderHeaderColumns()}</Tr>
              </THead>
              <TBody>
                {this.searchData(data, searchTerm).map(
                  (type: IRequestTypeV2, index: number) => {
                    return (
                      <Tr hover={true} striped={true} error={false} key={index}>
                        {this.renderTd("Name", type.label)}
                        {this.renderTd("Description", type.description)}
                        {this.renderTd(
                          "Status",
                          type.isActive ? "Active" : "Inactive"
                        )}
                        {this.renderTd(
                          "Submittable",
                          type.isSubmittable ? "Yes" : "No"
                        )}
                        {this.renderTd("SLA", type.sla)}
                        {this.renderTd(
                          "Actions",
                          <RequestTypeModal
                            heading={`Edit Request Type ${type.label}`}
                            requestType={type}
                            handleSave={async (requestType: IRequestTypeV2) => {
                              this.setState({ isLoading: true });
                              try {
                                requestType.sla = requestType.sla
                                  ? Number(requestType.sla)
                                  : null;

                                const response = await updateRequestTypeV2(
                                  requestType
                                );

                                if (response.requestType) {
                                  const updateIndex: number = _findIndex(data, [
                                    "id",
                                    response.requestType.id
                                  ]);
                                  // make a copy of the data
                                  const updateData: IRequestTypeV2[] = data.splice(
                                    0
                                  );
                                  // replace in the index for the data with the updated data
                                  updateData[updateIndex] = requestType;

                                  this.setState({
                                    data: updateData,
                                    isLoading: false
                                  });
                                }
                              } catch (error) {
                                toast.error("Error updating request type.", {
                                  position: toast.POSITION.BOTTOM_CENTER
                                });
                              }
                            }}
                          />
                        )}
                      </Tr>
                    );
                  }
                )}
                {isLoading &&
                  ["1", "2", "3", "4"].map((row, index) => (
                    <Tr key={`loading-${row}`}>
                      {columns.map((column, i) => {
                        return (
                          <Td key={`column-${i}`}>
                            <LoaderGradient />
                          </Td>
                        );
                      })}
                    </Tr>
                  ))}
                {this.searchData(data, searchTerm).length === 0 && !isLoading && (
                  <Tr key="no-results">
                    <Td colSpan={columns.length}>
                      <Alert show={true} type="Info">
                        There are no requests.{" "}
                        <a onClick={e => this.setState({ searchTerm: "" })}>
                          Undo your search
                        </a>
                        .
                      </Alert>
                    </Td>
                  </Tr>
                )}
              </TBody>
            </Table>
          </TableWrapper>
          <Loader
            loading={isLoading}
            type="Overlay"
            showImage={true}
            text="Loading..."
            position="Top"
          />
        </LoaderWrapper>
        <ToastContainer />
      </div>
    );
  }

  renderHeaderColumns = (): JSX.Element[] => {
    const { sortBy, sortOrder, data } = this.state;
    return columns.map((column, index) => {
      let sort: JSX.Element | null = column.sortBy ? (
        <Sort
          text={column.title}
          ascending={column.sortBy === sortBy && sortOrder === "ASC"}
          descending={column.sortBy === sortBy && sortOrder === "DESC"}
          onClick={e => {
            if (sortOrder === "DESC") {
              return this.setState({
                data: _sortBy(data, [column.sortBy]),
                sortOrder: "ASC",
                sortBy: column.sortBy
              });
            }
            return this.setState({
              data: _sortBy(data, [column.sortBy]).reverse(),
              sortOrder: "DESC",
              sortBy: column.sortBy
            });
          }}
          disabled={false}
        />
      ) : null;
      return (
        <Th hidden={false} text={column.text} title={column.title} key={index}>
          {sort}
        </Th>
      );
    });
  };

  renderTd = (label: string, value: any): JSX.Element => {
    return (
      <Td>
        <p className="label">{label}</p>
        {value}
      </Td>
    );
  };

  searchData = (
    data: IRequestTypeV2[],
    searchTerm: string
  ): IRequestTypeV2[] => {
    if (searchTerm) {
      return data.filter(
        (d: IRequestTypeV2) =>
          d.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (d.description &&
            d.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    return data;
  };
}

export default RequestTypeTable;
