import * as React from "react";

export interface ITBodyProps {
  className?: string;
  colSpan?: number;
}

export default class TBody extends React.Component<ITBodyProps, {}> {
  public render(): React.ReactElement<ITBodyProps> {
    const className: string = this.props.className ? "tbody " + this.props.className : "tbody";
    return <tbody className={className}>{this.props.children}</tbody>;
  }
}
