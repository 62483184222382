import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// components
import Alert from "../../components/alert/Alert";
import { columns } from "./Columns";
import Loader from "../../components/loader/Loader";
import LoaderGradient from "../../components/loader/LoaderGradient";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import ManagerGroupUsers from "./ManagerGroupUsers";
import Modal from "../../components/modal/Modal";
import P from "../../components/typography/P";
import { Search } from "../../components/search/Search";
import Sort from "../../components/table/Sort";
import Table from "../../components/table/Table";
import TableWrapper from "../../components/table/TableWrapper";
import Tr from "../../components/table/Tr";
import Th from "../../components/table/Th";
import Td from "../../components/table/Td";
import THead from "../../components/table/THead";
import TBody from "../../components/table/TBody";
// services
import { getAdGroupsV2, getAdGroup, getAdGroupMembers } from "../../services/AdGroup";
import { IMsAdUser } from "../../services/AdUsers";
// types
import { IAdGroup, IAdGroupV2 } from "../../types/AdGroup";
import { IMSGraphUserEnhanced } from "../../types/User";

export interface IAdGroupsListProps {
  allowExternalInvite?: boolean;
  bottlerCode: string[] | null;
  bottlerDomains: string[];
  bottlerId: number | null;
  showAddUsersMessage?: boolean;
  title: string;
}

export interface IAdGroupsListState {
  adding: boolean;
  adGroup: IAdGroup | null;
  adGroups: IAdGroupV2[];
  adUsers: IMsAdUser[];
  adUsersLoading: boolean;
  loading: boolean;
  message: string;
  modalLoading: boolean;
  msGraphUsers: IMSGraphUserEnhanced[];
  msGraphSearching: boolean;
  permission: boolean;
  removing: boolean;
  redirectUrl: string;
  redirectUrlDirty: boolean;
  saving: boolean;
  search: string;
  searched: IAdGroupV2[];
  searching: boolean;
  showInviteInputs: boolean;
  sortBy: string;
  sortOrder: string;
}

class AdGroupsList extends React.Component<IAdGroupsListProps, IAdGroupsListState> {
  searchInputTimer;
  SORT_ORDER_ASC: string = "ASC";
  SORT_ORDER_DESC: string = "DESC";
  DEFAULT_SORT_ORDER: string = this.SORT_ORDER_DESC;
  DEFAULT_SORT_BY: string = "name";

  state = {
    adding: false,
    adGroup: null,
    adGroups: [],
    adUsers: [],
    adUsersLoading: false,
    loading: true,
    message: "",
    modalLoading: false,
    msGraphUsers: [],
    msGraphSearching: false,
    permission: false,
    redirectUrl: "",
    redirectUrlDirty: false,
    removing: false,
    saving: false,
    search: "",
    searched: [],
    searching: false,
    showInviteInputs: false,
    sortBy: this.DEFAULT_SORT_BY,
    sortOrder: this.DEFAULT_SORT_ORDER
  } as IAdGroupsListState;

  componentDidMount(): void {
    this.getAdGroups();
  }

  componentDidUpdate(prevProps: IAdGroupsListProps): void {
    const { bottlerCode, bottlerId } = this.props;

    if (bottlerCode !== prevProps.bottlerCode && bottlerId !== prevProps.bottlerId && bottlerCode && bottlerId) {
      this.getAdGroups();
    }
  }

  render() {
    const { loading, search, searching } = this.state;

    const headerColumns: JSX.Element[] = this.renderHeaderColumns();

    const rows: JSX.Element[] = this.renderGroupsTable();
    return (
      <div className="adGroupsList">
        <Search
          autoFocus={false}
          onSearchChange={this.searchChange}
          onSearchKeyUp={e => e.key === "Enter" && this.searchAdGroups}
          onSearchClick={this.searchAdGroups}
          placeholder={"Search groups..."}
          search={search}
          searchButtonText={"Search"}
          searchButtonTitle={"Search Groups"}
          searchDisabled={loading}
          searchLabelText={"Search Groups"}
          showExport={false}
        />
        <LoaderWrapper>
          <TableWrapper loading={loading || searching}>
            <Table>
              <THead>
                <Tr>{headerColumns}</Tr>
              </THead>
              <TBody>{rows}</TBody>
            </Table>
          </TableWrapper>
          <Loader loading={loading || searching} type="Overlay" showImage={true} text={searching ? "Searching..." : "Loading..."} position="Top" />
        </LoaderWrapper>
        <ToastContainer />
      </div>
    );
  }

  getAdGroups = (): void => {
    const { bottlerCode, bottlerId } = this.props;

    if (bottlerCode && bottlerCode.length > 0 && bottlerId) {
      this.setState(
        {
          loading: true
        },
        async () => {
          try {
            const bottlerCodes: string[] = bottlerCode.map(code => {
              // todo: fix code not matching MNM AD groups
              if (code === "MNMCC") {
                code = "MNM";
              }
              return code;
            });

            // add exception for Great Lakes/Reyes to also get GLCC-B2B and RCCB-B2B as well as GLCCB-RCCB-B2B
            if (bottlerCode[0] === 'GLCCB-RCCB-B2B') {
              bottlerCodes.push('GLCCB-B2B');
              bottlerCodes.push('RCCB-B2B');
            }
            if (bottlerCode[0] === 'GLCCB-RCCB-B2B-PBI') {
              bottlerCodes.push('GLCCB-B2B-PBI');
              bottlerCodes.push('RCCB-B2B-PBI');
            }

            const response = await getAdGroupsV2(bottlerCodes, bottlerId);

            this.setState({
              adGroups: response.adGroups,
              loading: false,
              searched: []
            });
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState({
              loading: false
            });
          }
        }
      );
    }
  };

  getAdGroup = (adGroupId: string, saved: boolean = false): void => {
    let { adGroup } = this.state;

    this.setState(
      {
        modalLoading: true,
        adGroup: saved ? adGroup : null
      },
      async () => {
        try {
          adGroup = await getAdGroup(adGroupId);

          this.setState(
            {
              adGroup,
              modalLoading: false
            },
            () => {
              if (saved) {
                toast.success("Users saved to group successfully.", {
                  position: toast.POSITION.BOTTOM_CENTER
                });

                this.resetState();
              }

              this.getAdGroupMembers();
            }
          );
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            modalLoading: false
          });
        }
      }
    );
  };

  getAdGroupMembers = (): void => {
    const { adGroup } = this.state;

    if (adGroup) {
      this.setState(
        {
          adUsers: [],
          adUsersLoading: true
        },
        async () => {
          try {
            const adUsers: IMsAdUser[] = await getAdGroupMembers(adGroup.azureGroupId);

            this.setState({
              adUsers: adUsers,
              adUsersLoading: false
            });
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            this.setState({
              adUsersLoading: false
            });
          }
        }
      );
    } else {
      // adGroup 404 handled in _getAdGroup
    }
  };

  handleSort = (sortBy: string) => {
    const { sortOrder } = this.state;

    const adGroups = this.sortAdGroups(this.state.adGroups.slice(), sortBy, sortOrder);
    const searched = this.sortAdGroups(this.state.searched.slice(), sortBy, sortOrder);

    this.setState({
      adGroups: adGroups,
      searched: searched,
      sortBy: sortBy,
      sortOrder: sortOrder === this.SORT_ORDER_DESC ? this.SORT_ORDER_ASC : this.SORT_ORDER_DESC
    });
  };

  sortAdGroups = (adGroups: IAdGroupV2[], sortBy: string, sortOrder: string): IAdGroupV2[] => {
    if (adGroups) {
      return adGroups.sort((a, b) => {
        const A: string | number = a[sortBy];
        const B: string | number = b[sortBy];

        if (sortOrder === this.SORT_ORDER_DESC) {
          return A < B ? 1 : -1; // ASC
        } else {
          return A > B ? 1 : -1; // DESC
        }
      });
    } else {
      return [];
    }
  };

  renderHeaderColumns = (): JSX.Element[] => {
    const { adGroups, searched, sortOrder, sortBy } = this.state;

    const headerColumns: JSX.Element[] = columns.map((column, index) => {
      return (
        <Th hidden={false} text={column.text} title={column.title} key={index}>
          {column.sortBy ? (
            <Sort
              text={column.title}
              ascending={column.sortBy === sortBy && sortOrder === this.SORT_ORDER_ASC ? true : false}
              descending={column.sortBy === sortBy && sortOrder === this.SORT_ORDER_DESC ? true : false}
              onClick={e => this.handleSort(column.sortBy)}
              disabled={searched.length === 1 ? true : adGroups.length > 1 ? false : true}
            />
          ) : null}
        </Th>
      );
    });
    return headerColumns;
  };

  renderGroupsTable = (): JSX.Element[] => {
    const { bottlerDomains } = this.props;
    const { adGroup, adGroups, adUsers, adUsersLoading, loading, modalLoading, removing, saving, searched } = this.state;

    const rows: JSX.Element[] = [];
    const groups: IAdGroupV2[] = searched.length ? searched : adGroups;

    if (groups && groups.length > 0) {
      groups.map((group, index) => {
        let name: JSX.Element = (
          <Td>
            <p className="label">AD Group Name</p>
            {group.nameFriendly ? (
              <P bold={true} className="marginBottomSmallest">
                {group.nameFriendly}
              </P>
            ) : null}
            <P bold={!group.nameFriendly ? true : false} italic={group.nameFriendly ? true : false} small={group.nameFriendly ? true : false}>
              {group.name}
            </P>
          </Td>
        );

        let description: JSX.Element = (
          <Td>
            <p className="label">AD Group Description</p>
            <P>{group.description}</P>
          </Td>
        );

        let actions: JSX.Element = (
          <Td>
            <Modal
              body={[
                <ManagerGroupUsers
                  key="body"
                  getAdGroup={this.getAdGroup}
                  adGroup={adGroup}
                  adUsers={adUsers}
                  bottlerDomains={bottlerDomains}
                  isFetchingAdUsers={adUsersLoading}
                  isLoading={modalLoading}
                />
              ]}
              bodyLoading={modalLoading || saving || removing}
              bodyLoadingShowImage={true}
              bodyLoadingText={saving ? "Saving..." : removing ? "Removing..." : "Loading..."}
              bodyLoadingPosition="Top"
              cancelIcon={"Cancel"}
              cancelText="Close"
              cancelTitle="Close"
              className="addUsersModal"
              disabled={false}
              heading={`Add & Remove Users${adGroup && adGroup.nameFriendlyLast ? ` To ${adGroup.nameFriendlyLast}` : ``}`}
              id="adGroupAndUsers"
              modalSize="Lg"
              onOpen={e => this.getAdGroup(group.azureGroupId, false)}
              onSave={null}
              openClassName="buttonLinkPrimaryColor"
              openIcon="Edit"
              openText="Edit Users"
              openTitle={`Add & Remove Users`}
              showCancel={false}
              showSave={false}
              showFooter={false}
            />
          </Td>
        );
        rows.push(
          <Tr hover={true} striped={true} error={false} key={index}>
            {name}
            {description}
            {actions}
          </Tr>
        );
      });
    } else {
      if (!loading) {
        rows.push(
          <Tr key="no-results">
            <Td colSpan={columns.length}>
              <Alert show={true} type="Info">
                There are no groups to display.
              </Alert>
            </Td>
          </Tr>
        );
      } else {
        const loadingRows = ["1", "2", "3", "4"];
        loadingRows.map((row, index) => {
          rows.push(
            <Tr key={`loading-${row}`}>
              {columns.map((column, i) => {
                return (
                  <Td key={`column-${i}`}>
                    <LoaderGradient />
                  </Td>
                );
              })}
            </Tr>
          );
        });
      }
    }

    return rows;
  };

  searchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const search: string = e.target.value;

    clearTimeout(this.searchInputTimer);

    this.setState(
      {
        search
      },
      () => {
        this.searchInputTimer = setTimeout(() => {
          this.searchAdGroups();
        }, 300);
      }
    );
  };

  searchAdGroups = (): void => {
    const { adGroups, search } = this.state;

    const condition = new RegExp(search, "i");

    this.setState(
      {
        searching: true
      },
      () => {
        setTimeout(() => {
          const searched = adGroups.filter(adGroup => {
            const name: boolean = condition.test(adGroup.name);
            const nameFriendly: boolean = adGroup.nameFriendly ? condition.test(adGroup.nameFriendly) : false;
            const description: boolean = adGroup.description ? condition.test(adGroup.description) : false;
            return name || nameFriendly || description;
          });

          this.setState({
            searched: searched,
            searching: false
          });
        }, 300);
      }
    );
  };

  resetState = (): void => {
    this.setState({
      adding: false,
      message: "",
      msGraphUsers: [],
      msGraphSearching: false,
      redirectUrl: "",
      redirectUrlDirty: false,
      removing: false,
      saving: false,
      searching: false,
      showInviteInputs: false
    });
  };
}

export default AdGroupsList;
