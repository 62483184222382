import { IError } from "../types/Error";
import { getRequest, putRequest } from "../core/api/requestConfig";
import { IPrivacyPolicyBase } from "../types/PrivacyPolicy";
import { Apps } from "../types/App";
import { AxiosResponse } from "axios";

export function getPrivacyPolicy(): Promise<IPrivacyPolicyBase> {
  return new Promise<IPrivacyPolicyBase>((resolve, reject) => {
    getRequest(`/api/v1/privacy-policies?appId=${Apps.COKE_SERVICES_APP}`)
      .then((policies: IPrivacyPolicyBase) => {
        return resolve(policies);
      })
      .catch((error: IError) => {
        console.log(error);
        return reject(error);
      });
  });
}

export const updatePrivacyPolicy = async (id: number, data): Promise<IPrivacyPolicyBase> => {
  try {
    const res = await putRequest(`/api/v1/privacy-policies/${id}`, data);
    return Promise.resolve(res);
  } catch (err) {
    console.error("updatePrivacyPolicy", err);
    const error = err.response as AxiosResponse;
    return Promise.reject(error.data);
  }
};
