import * as React from "react";
import moment from "moment";
// components
import Hr from "../../../components/typography/Hr";
import P from "../../../components/typography/P";
import Page from "../../../components/page/Page";
import RequestsList from "./RequestsList";
import BottlerSwitcher from "../../../components/switcher/BottlerSwitcher";
// logging
import { withPageLog } from "../../logging/LogComponentChange";

const ArchivedRequests = () => (
  <Page className="requests" permissions={true} title="Archived Requests">
    <P italic={true}>Requests created more than 60 days ago.</P>
    <Hr />
    <BottlerSwitcher showAllOption={true} />
    <RequestsList
      refreshId="archived-requests"
      archived={true}
      _createdAtEnd={moment()
        .subtract(60, "days")
        .toISOString()}
    />
  </Page>
);

export default withPageLog(ArchivedRequests);
