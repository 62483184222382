import * as React from "react";
import * as SlateReact from "slate-react";
import * as Slate from "slate";
import { isKeyHotkey } from "is-hotkey";
import is from "is_js";
// components
import EditorButton from "./EditorButton";
const imageExtensions = require("image-extensions");

// schema needed to determine what is allowed to create in the editor
const schema = {
  document: {
    nodes: [
      {
        match: [{ type: "paragraph" }, { type: "image" }, { type: "bulleted-list" }]
      },
      {
        marks: [{ type: "bold" }, { type: "italic" }]
      }
    ],
    last: { type: "paragraph" },
    normalize: (editor: Slate.Editor, { code, node }: Slate.SlateError) => {
      switch (code) {
        case "last_child_type_invalid": {
          const paragraph = Slate.Block.create("paragraph");
          return editor.insertNodeByKey(node.key, node.nodes.size, paragraph);
        }
        default:
          return;
      }
    }
  },
  blocks: {
    paragraph: {
      nodes: [
        {
          match: { object: "text" }
        },
        {
          marks: [{ type: "bold" }, { type: "italic" }]
        }
      ]
    },
    image: {
      isVoid: true
    }
  }
};

const isImage = url => {
  return !!imageExtensions.find(url.endsWith);
};

const insertImage = (editor, src, target) => {
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({
    type: "image",
    data: { src }
  });
};

const DEFAULT_NODE = "paragraph";

// hot keys to trigger marks, "mod" works for Mac/Windows
const isBoldHotkey = isKeyHotkey("mod+b");
const isItalicHotkey = isKeyHotkey("mod+i");
const isUnderlinedHotkey = isKeyHotkey("mod+u");

interface IEditorProps {
  value: string | null;
  readOnly: boolean;
  onChange: (value: string) => void;
}

interface IEditorState {
  isReady: boolean;
  value: any;
}

class Editor extends React.Component<IEditorProps, IEditorState> {
  // @ts-ignore
  editor: SlateReact.Editor;
  constructor(props: IEditorProps) {
    super(props);

    this.state = {
      isReady: false,
      value: " "
    };
  }

  componentDidMount(): void {
    const { value } = this.props;

    // new values will be saved in JSON string format
    // prexisiting values are just plain strings
    let existingValue: any;

    try {
      existingValue = value && JSON.parse(value);
    } catch (error) {
      existingValue = {
        document: {
          nodes: [
            {
              object: "block",
              type: "paragraph",
              nodes: [
                {
                  object: "text",
                  leaves: [
                    {
                      text: value
                    }
                  ]
                }
              ]
            }
          ]
        }
      };
    }

    this.setState({
      isReady: true,
      value: Slate.Value.fromJSON(existingValue)
    });
  }

  hasMark = (type: string) => {
    const { value } = this.state;
    if (value && value.activeMarks) {
      return value.activeMarks.some((mark: { type: string }) => mark.type === type);
    }

    return false;
  };

  hasBlock = (type: string) => {
    const { value } = this.state;
    if (value && value.blocks) {
      return value.blocks.some((node: { type: string }) => node.type === type);
    }

    return false;
  };

  ref = editor => {
    this.editor = editor;
  };

  render() {
    const { value, isReady } = this.state;
    const { readOnly } = this.props;
    return (
      isReady && (
        <div className="editorWrapper">
          <div className="editorToolbar">
            {this.renderMarkButton("bold", "Bold")}
            {this.renderMarkButton("italic", "Italic")}
            {this.renderMarkButton("underlined", "Underline")}
            {this.renderBlockButton("bulleted-list", "BulletedList")}
          </div>
          <SlateReact.Editor
            className="editor"
            spellCheck={true}
            ref={this.ref}
            readOnly={readOnly}
            // @ts-ignore
            schema={schema}
            value={value}
            onDrop={this.onDropOrPaste}
            onPaste={this.onDropOrPaste}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            renderNode={this.renderNode}
            renderMark={this.renderMark}
          />
        </div>
      )
    );
  }

  renderMarkButton = (type: string, icon: string) => {
    const isActive: boolean = this.hasMark(type);
    return <EditorButton title={type} isActive={isActive} iconName={icon} onClick={event => this.onClickMark(event, type)} />;
  };

  renderBlockButton = (type: string, icon: string) => {
    let isActive: boolean = this.hasBlock(type);

    if (["numbered-list", "bulleted-list"].includes(type)) {
      const {
        value: { document, blocks }
      } = this.state;

      if (blocks && blocks.size > 0 && document) {
        const parent = document.getParent(blocks.first().key);
        isActive = this.hasBlock("list-item") && parent && parent.type === type;
      }
    }
    return <EditorButton title={type} isActive={isActive} iconName={icon} onClick={event => this.onClickBlock(event, type)} />;
  };

  renderNode = (props, editor, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case "image":
        const src = node.data.get("src");
        return <img className="editorImage" src={src} {...attributes} alt="" />;
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      default:
        return next();
    }
  };

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case "bold":
        return <strong {...attributes}>{children}</strong>;
      case "italic":
        return <em {...attributes}>{children}</em>;
      case "underlined":
        return <u {...attributes}>{children}</u>;
      default:
        return next();
    }
  };

  onChange = ({ value }) => {
    // Check to see if the document has changed before saving.
    if (value.document !== this.state.value.document) {
      const content = JSON.stringify(value.toJSON());
      this.props.onChange(content);
    }
    this.setState({ value });
  };

  onKeyDown = (event, editor, next: Function) => {
    let mark: string;

    if (isBoldHotkey(event)) {
      mark = "bold";
    } else if (isItalicHotkey(event)) {
      mark = "italic";
    } else if (isUnderlinedHotkey(event)) {
      mark = "underlined";
    } else {
      return next();
    }

    event.preventDefault();
    editor.toggleMark(mark);
  };

  onClickMark = (event, type: string) => {
    event.preventDefault();
    this.editor.toggleMark(type);
  };

  onClickBlock = (event, type: string) => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons.
    if (type !== "bulleted-list" && type !== "numbered-list") {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock("list-item");

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock("list-item");
      const isType = value.blocks.some((block: any) => {
        return !!document.getClosest(block.key, (parent: any) => parent.type === type);
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock("bulleted-list")
          .unwrapBlock("numbered-list");
      } else if (isList) {
        editor.unwrapBlock(type === "bulleted-list" ? "numbered-list" : "bulleted-list").wrapBlock(type);
      } else {
        editor.setBlocks("list-item").wrapBlock(type);
      }
    }
  };
  onDropOrPaste = (event, editor, next) => {
    const target = SlateReact.getEventRange(event, editor);
    if (!target && event.type === "drop") {
      return next();
    }

    const transfer = SlateReact.getEventTransfer(event);
    const { type, text, files }: any = transfer;

    if (type === "files") {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");
        if (mime !== "image") {
          continue;
        }

        reader.addEventListener("load", () => {
          editor.command(insertImage, reader.result, target);
        });

        reader.readAsDataURL(file);
      }
      return;
    }

    if (type === "text") {
      if (!is.url(text)) {
        return next();
      }
      if (!isImage(text)) {
        return next();
      }
      editor.command(insertImage, text, target);
      return;
    }

    next();
  };
}
export default Editor;
