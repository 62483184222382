import * as React from "react";
import { connect } from "react-redux";
import { AppInsights } from "applicationinsights-js";

export interface IState {}

export interface IProps {
  config?: any;
}

export class LogProvider extends React.Component<IProps, IState> {
  componentDidMount() {
    if (AppInsights !== undefined) {
      if (AppInsights.downloadAndSetup) {
        AppInsights.downloadAndSetup({ instrumentationKey: this.props.config.appInsightsKey });
      }
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state: any) => ({
  config: state.config.config
});

export const Logger = connect(mapStateToProps)(LogProvider);
