import * as React from "react";
import { Link } from "react-router-dom";

import logo from "../../images/logo/logo-horizontal-white.svg";

class Logo extends React.Component {
  public render() {
    return (
      <Link className="logo" id="logo" to="/dashboard" title="Back To Dashboard">
        <img className="logoImage" src={logo} alt="Coca-Cola Business Services North America" />
      </Link>
    );
  }
}

export default Logo;
