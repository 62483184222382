import * as React from "react";

export interface ITdProps {
  className?: string;
  colSpan?: number;
  title?: string;
  hidden?: boolean;
  alignCenter?: boolean;
  tooltipText?: string | null | undefined;
  tooltipWidth?: string;
}

export default class Td extends React.Component<ITdProps, {}> {
  public render(): React.ReactElement<ITdProps> {
    const { className, alignCenter, colSpan, hidden, title, tooltipText, tooltipWidth } = this.props;

    let tdCssClass: string = "td";

    if (tooltipText) {
      tdCssClass += " tip tipRight";
    }

    if (tooltipWidth) {
      tdCssClass += " " + tooltipWidth;
    }

    if (className) {
      tdCssClass += " " + className;
    }

    if (hidden) {
      tdCssClass = "displayNone";
    }

    const tdInnerCssClass: string = alignCenter ? "tdInner tdInnerCenter" : "tdInner";

    return (
      <td className={tdCssClass} colSpan={colSpan} title={title} data-tip={tooltipText}>
        <div className={tdInnerCssClass}>{this.props.children}</div>
      </td>
    );
  }
}

export interface ITdWithLabel {
  label: string;
}

export const TdWithLabel: React.StatelessComponent<ITdWithLabel> = ({ children, label }) => (
  <Td className="tdWithLabel">
    <p className="label">{label}</p>
    {children}
  </Td>
);
