import * as React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
// types
import { IBottlerMemberAndLoginCount } from "../../types/Bottler";

interface IUserAdoptionChartProps {
  data: IBottlerMemberAndLoginCount[];
}

const UserAdoptionChart = ({ data }: IUserAdoptionChartProps) => {
  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart barSize={25} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="bottlerCode" />
          <YAxis />
          {data && <Tooltip content={<UserAdoptionTooltip />} />}
          <Legend verticalAlign="bottom" iconType="square" wrapperStyle={{ bottom: 0 }} />
          <Bar name="Equipment Requests" dataKey="requestStackCount" fill="#f29fa5" stackId="a" />
          <Bar name="Equipment Lookups" dataKey="equipmentLookupStackCount" fill="#e7454f" stackId="a" />
          <Bar name="Logins" dataKey="loginStackCount" fill="#be1923" stackId="a" />
          <Bar name="Total Users" dataKey="memberStackCount" fill="#730f15" stackId="a" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

class UserAdoptionTooltip extends React.Component<any> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render() {
    const { active } = this.props;

    if (active) {
      const { contentStyle, payload, itemStyle, itemSorter } = this.props;

      // taken from DefaultTooltipContent.js inside recharts
      if (payload && payload.length) {
        const data = payload[0].payload as IBottlerMemberAndLoginCount;

        const listStyle = { padding: 0, margin: 0 };

        const items = payload.sort(itemSorter).map((entry, i) => {
          const finalItemStyle = {
            display: "block",
            paddingTop: 4,
            paddingBottom: 4,
            color: entry.color || "#000",
            ...itemStyle
          };

          return (
            <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={finalItemStyle}>
              <span className="recharts-tooltip-item-value">
                {/* This is the customized part */}
                {entry.name === "Total Users"
                  ? `${data.memberCountLabel}: ${data.memberCount}`
                  : entry.name === "Logins"
                  ? `${data.loginCountLabel}: ${data.loginCount}`
                  : entry.name === "Equipment Lookups"
                  ? `${data.equipmentLookupCountLabel}: ${data.equipmentLookupCount}`
                  : entry.name === "Equipment Requests"
                  ? `${data.requestCountLabel}: ${data.requestCount}`
                  : `${entry.name}: ${entry.value}`}
              </span>
            </li>
          );
        });

        const list = (
          <ul className="recharts-tooltip-item-list" style={listStyle}>
            {items}
          </ul>
        );

        const finalStyle = {
          margin: 0,
          padding: 10,
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          whiteSpace: "nowrap",
          ...contentStyle
        };

        return (
          <div className="recharts-default-tooltip" style={finalStyle}>
            <p className="recharts-tooltip-label">{`${data.bottlerName}`}</p>
            {list}
          </div>
        );
      }

      return null;
    }

    return null;
  }
}

export default UserAdoptionChart;
