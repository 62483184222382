import * as React from "react";
import { Icon } from "office-ui-fabric-react";
// components
import Label from "../label/Label";
import Input from "../input/Input";
import Button from "../button/Button";
import ButtonLink from "../button/ButtonLink";

interface IOnExportButtonClick {
  (e: React.MouseEvent<HTMLButtonElement>): void;
}

interface IOnExportButtonLinkClick {
  (e: React.MouseEvent<HTMLAnchorElement>): void;
}

interface IOnSearchChange {
  (e: React.ChangeEvent<HTMLInputElement>): void;
}

interface IOnSearchKeyUp {
  (e: React.KeyboardEvent<HTMLInputElement>): void;
}

interface IOnSearchClick {
  (e: React.MouseEvent<HTMLButtonElement>): void;
}

export interface ISearchProps {
  action?: JSX.Element;
  autoFocus: boolean;
  exportDisabled?: boolean;
  exportText?: string;
  exportTitle?: string;
  onExportButtonClick?: IOnExportButtonClick;
  onExportButtonLinkClick?: IOnExportButtonLinkClick;
  onSearchClick?: IOnSearchClick;
  onSearchChange: IOnSearchChange;
  onSearchKeyUp?: IOnSearchKeyUp;
  placeholder: string;
  search: string;
  searchButtonText?: string;
  searchButtonTitle?: string;
  searchDisabled: boolean;
  searchLabelText: string;
  showExport?: boolean;
  showSearchButton?: boolean;
}

export interface ISearchState {}

export class Search extends React.Component<ISearchProps, ISearchState> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<ISearchProps> {
    const {
      action,
      autoFocus,
      exportDisabled,
      exportText,
      exportTitle,
      onExportButtonClick,
      onExportButtonLinkClick,
      onSearchClick,
      onSearchChange,
      onSearchKeyUp,
      placeholder,
      search,
      searchButtonText,
      searchButtonTitle,
      searchDisabled,
      searchLabelText,
      showExport,
      showSearchButton = true
    } = this.props;

    return (
      <div className="search">
        <Label htmlFor={"search"} text={searchLabelText} srOnly={true} />
        <Input
          autoFocus={autoFocus}
          value={search}
          disabled={searchDisabled}
          id="search"
          inputType={"search"}
          name={"search"}
          onChange={onSearchChange}
          onKeyUp={onSearchKeyUp}
          placeholder={placeholder}
        />
        {showSearchButton && (
          <Button
            className="buttonInfo"
            disabled={searchDisabled}
            hideText={true}
            iconLeft={<Icon iconName="Search" />}
            onClick={onSearchClick}
            text={searchButtonText || ""}
            title={searchButtonTitle || ""}
          />
        )}

        {showExport && onExportButtonClick && exportText && exportTitle && (
          <Button
            className="buttonWhite"
            disabled={exportDisabled}
            iconLeft={<Icon iconName="Download" />}
            onClick={!exportDisabled ? onExportButtonClick : () => null}
            text={exportText}
            title={exportTitle}
          />
        )}

        {showExport && onExportButtonLinkClick && exportText && exportTitle && (
          <ButtonLink
            className="buttonWhite"
            href={exportDisabled ? "" : "#"}
            disabled={exportDisabled}
            iconLeft={<Icon iconName="Download" />}
            onClick={!exportDisabled ? onExportButtonLinkClick : () => null}
            text={exportText}
            title={exportTitle}
          />
        )}

        {action && <div className="searchAction">{action}</div>}
      </div>
    );
  }
}

export default Search;
