import * as React from "react";

interface IH6Props {
  className?: string;
}

export default class H6 extends React.Component<IH6Props> {
  public render() {
    const { className, children } = this.props;
    return <h6 className={className ? `h6 ${className}` : "h6"}>{children}</h6>;
  }
}
