import { createdAtIso, createdAtDate, createdAtTime } from "../helpers/equipmentRepair";
import { Apps } from "./App";

export interface IColumn {
  sortBy: string;
  text: string;
  title: string;
}

export const routesColumns: IColumn[] = [
  {
    sortBy: "text",
    text: "Text",
    title: "Text"
  },
  {
    sortBy: "href",
    text: "Href",
    title: "Href"
  },
  {
    sortBy: "",
    text: "",
    title: "Actions"
  }
];

export interface IReactTableColumn {
  Header: string;
  accessor: string;
  Cell?: any;
}

export const UsageReportsColumns = (timezone: string, all = false, appId: number): IReactTableColumn[] => {
  const columns: IReactTableColumn[] = [];

  columns.push(
    {
      Header: "UUID",
      accessor: "uuid"
    },
    {
      Header: "Created On (ISO)",
      accessor: "createdAt",
      Cell: props => createdAtIso(props.value, timezone)
    },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: props => createdAtDate(props.value, timezone)
    },
    {
      Header: "Time",
      accessor: "createdAt",
      Cell: props => createdAtTime(props.value, timezone)
    }
  );

  if (all) {
    columns.push({
      Header: "Bottler",
      accessor: "bottlerName"
    });
  }

  columns.push(
    {
      Header: "Created By",
      accessor: "userName"
    },
    {
      Header: "Created By Email",
      accessor: "userEmail"
    },
    {
      Header: "Outlet Number",
      accessor: "outletNumber"
    },
    {
      Header: "Outlet Name",
      accessor: "outletName"
    },
    {
      Header: "Street",
      accessor: "outletStreet"
    },
    {
      Header: "City",
      accessor: "outletCity"
    },
    {
      Header: "State",
      accessor: "outletState"
    },
    {
      Header: "Equipment Serial",
      accessor: "equipmentSerialNumber"
    },
    {
      Header: "Contact",
      accessor: "outletContact"
    },
    {
      Header: "Contact Phone",
      accessor: "outletPhone"
    },
    {
      Header: "Contact Phone Ext",
      accessor: "outletPhoneExtension"
    },
    {
      Header: "Equipment Type",
      accessor: "equipmentType"
    },
    {
      Header: "Problem Code",
      accessor: "problemCodeDescription"
    },
    {
      Header: "Location",
      accessor: "equipmentLocation"
    },
    {
      Header: "Comment",
      accessor: "comment"
    },
    {
      Header: "Available From",
      accessor: "from"
    },
    {
      Header: "Available To",
      accessor: "to"
    },
    {
      Header: "Available Days",
      accessor: "availabilityDays"
    },
    {
      Header: "Ticket Number",
      accessor: "equipmentRepairTicketNumber"
    }
  );

  if (appId === Apps.COKE_SERVICES_APP) {
    columns.push(
      {
        Header: "Equipment Street",
        accessor: "requestEquipmentLocationStreet"
      },
      {
        Header: "Equipment City",
        accessor: "requestEquipmentLocationCity"
      },
      {
        Header: "Equipment State",
        accessor: "requestEquipmentLocationState"
      },
      {
        Header: "Equipment Postal Code",
        accessor: "requestEquipmentLocationPostalCode"
      },
      {
        Header: "Request Contact Email",
        accessor: "requestContactEmail"
      },
      {
        Header: "Request Contact Email Notification",
        accessor: "requestContactEmailNotification",
        Cell: props => (props.value ? "Yes" : "No")
      },
      {
        Header: "Request Contact First Name",
        accessor: "requestContactFirstName"
      },
      {
        Header: "Request Contact Last Name",
        accessor: "requestContactLastName"
      },
      {
        Header: "Request Contact Phone",
        accessor: "requestContactPhone"
      },
      {
        Header: "Request Contact Phone Notification",
        accessor: "requestContactPhoneNotification",
        Cell: props => (props.value ? "Yes" : "No")
      },
      {
        Header: "Request Contact Text Notification",
        accessor: "requestContactTextNotification",
        Cell: props => (props.value ? "Yes" : "No")
      },
      {
        Header: "Request Contact Role",
        accessor: "contactRoleName"
      },
      {
        Header: "Employee Name",
        accessor: "employeeName"
      },
      {
        Header: "Employee Email",
        accessor: "employeeEmail"
      },
      {
        Header: "Employee Phone",
        accessor: "employeePhone"
      },
      {
        Header: "Employee Email Notification",
        accessor: "employeeEmailNotification",
        Cell: props => (props.value == null ? null : props.value ? "Yes" : "No")
      },
      {
        Header: "Employee Text Notification",
        accessor: "employeeTextNotification",
        Cell: props => (props.value == null ? null : props.value ? "Yes" : "No")
      }
    );
  }
  // Push separately, to ensure always last column
  columns.push({
    Header: "Type",
    accessor: "requestType"
  });

  return columns;
};
