import * as React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { Icon } from "office-ui-fabric-react";
// components
import { DragDropFile } from "../drag-and-drop/DragAndDrop";
import Button from "../button/Button";
import H6 from "../typography/H6";
import HR from "../typography/Hr";
import Li from "../typography/Li";
import P from "../typography/P";
import Ul from "../typography/Ul";
// types
import { IDoc } from "../../types/Doc";

interface IDocDelete {
  (e: React.MouseEvent<HTMLButtonElement>, index: number): void;
}

interface IDocDownload {
  (e: React.MouseEvent<HTMLAnchorElement>, blobName: string): void;
}

interface IDocNewDelete {
  (e: React.MouseEvent<HTMLButtonElement>, index: number): void;
}

interface IDocNewAdd {
  (acceptedFiles: any[], rejectedFiles: any[]): void;
}

export interface IDocumentsProps {
  docDelete: IDocDelete;
  docDownload: IDocDownload;
  docNewDelete: IDocNewDelete;
  docNewAdd: IDocNewAdd;
  docs: IDoc[];
  docsDirty: boolean;
  docsNew: any[];
  requestPostPilotFF: boolean;
}

export interface IDocumentsState {}

class Documents extends React.Component<IDocumentsProps, IDocumentsState> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactElement<IDocumentsProps> {
    const { docDelete, docDownload, docNewDelete, docNewAdd, docs, docsNew, requestPostPilotFF } = this.props;

    const docItems: JSX.Element[] = [];

    docs.map((doc, index) => {
      docItems.push(
        <Li className="doc" key={doc.uuid}>
          <a className="docLink" href="#" onClick={e => docDownload(e, doc.blobName)}>
            <Icon iconName="DownloadDocument" />
            {doc.name}
          </a>{" "}
          <Button
            className="buttonLinkPrimaryColor docDelete"
            hideText={true}
            iconLeft={<Icon iconName="Delete" />}
            onClick={e => docDelete(e, index)}
            text="Delete Document"
            title="Delete Document"
          />
        </Li>
      );
    });

    docsNew.map((doc, index) => {
      docItems.push(
        <Li className="doc docNew" key={index}>
          <P className="docUpload">
            <span>{doc.name}</span>
          </P>{" "}
          <Button
            className="buttonLinkPrimaryColor docDelete docNewDelete"
            hideText={true}
            iconLeft={<Icon iconName="Delete" />}
            onClick={e => docNewDelete(e, index)}
            text="Delete Document"
            title="Delete Document"
          />
        </Li>
      );
    });

    return (
      <div className="documents">
        <H6>Documents</H6>
        <Ul className="docs" listItems={docItems} />
        <DragDropFile
          handleFileChange={(acceptedFiles, rejectedFiles) => docNewAdd(acceptedFiles, rejectedFiles)}
          multiple={true}
          text={<P>Drag and drop or click to add documents.{requestPostPilotFF && " File upload limit is 20MB."}</P>}
        />
        <HR />
      </div>
    );
  }
}

export default Documents;
