import * as React from "react";

export interface ITrProps {
  className?: string;
  hover?: boolean;
  striped?: boolean;
  success?: boolean;
  error?: boolean;
  warning?: boolean;
  info?: boolean;
}

export default class Tr extends React.Component<ITrProps, {}> {
  public render() {
    let className: string = "tr";
    if (this.props.hover && !this.props.striped) {
      className = "trHover";
    }
    if (!this.props.hover && this.props.striped) {
      className = "trStriped";
    }
    if (this.props.hover && this.props.striped) {
      className = "trStripedHover";
    }
    if (this.props.success) {
      className = "trSuccess";
    }
    if (this.props.error) {
      className = "trError";
    }
    if (this.props.warning) {
      className = "trWarning";
    }
    if (this.props.info) {
      className = "trInfo";
    }
    if (this.props.className) {
      className += " " + this.props.className;
    }
    return <tr className={className}>{this.props.children}</tr>;
  }
}
