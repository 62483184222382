export enum RequestPriorities {
  NORMAL = 1,
  HIGH = 2
}

export interface IRequestPriority {
  id: number;
  name: string;
}

export interface IRequestPrioritiesRes {
  statusCode: number;
  message: string;
  requestPriorities: IRequestPriority[];
}
