import * as React from "react";
// components
import Alert from "./Alert";
import Tr from "../table/Tr";
import Td from "../table/Td";

interface ITableAlertProps {
  numOfColumns: number;
  message: string;
}

const TableAlert = ({ numOfColumns, message }: ITableAlertProps) => {
  return (
    <Tr key="no-results">
      <Td colSpan={numOfColumns}>
        <Alert show={true} type="Info">
          {message}
        </Alert>
      </Td>
    </Tr>
  );
};

export default TableAlert;
