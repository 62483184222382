import * as React from "react";
import { connect } from "react-redux";
// components
import Alert from "../../components/alert/Alert";
import ErrorUnauthorized from "../../components/errors/ErrorUnauthorized";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
// types
import { IAdminAppRoute } from "../../types/AdminAppRoute";

export interface IAdGroupsListProps {
  adminAppRoutes: IAdminAppRoute[];
  adminAppRoutesLoading: boolean;
  adminAppRoutesError: string;
  title: string;
}

export interface IAdGroupsListState {
  permission: boolean;
}

class AdGroupsList extends React.Component<IAdGroupsListProps, IAdGroupsListState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      permission: false
    };
  }

  public componentDidMount(): void {
    this._checkPermissions();
  }

  public componentDidUpdate(prevProps: any): void {
    const { adminAppRoutes } = this.props;

    if (adminAppRoutes !== prevProps.adminAppRoutes && adminAppRoutes && adminAppRoutes.length > 0) {
      this._checkPermissions();
    }
  }

  public render(): React.ReactElement<IAdGroupsListProps> {
    const { adminAppRoutesError, adminAppRoutesLoading, children, title } = this.props;
    const { permission } = this.state;

    if (adminAppRoutesError) {
      return <Alert icon="Error" show={true} text={adminAppRoutesError} type="Error" />;
    } else if (!adminAppRoutesLoading && !permission) {
      return <ErrorUnauthorized errorMessage={`You do not have permissions to manage ${title}.`} />;
    } else if (adminAppRoutesLoading) {
      return (
        <div className="permissions">
          <LoaderWrapper>
            <Loader loading={adminAppRoutesLoading} showImage={true} text={"Checking permissions..."} position="Top" />
          </LoaderWrapper>
        </div>
      );
    } else {
      return <div>{children}</div>;
    }
  }

  private _checkPermissions(): void {
    const { adminAppRoutes } = this.props;

    const hash: string = window.location.hash.replace("#", "");

    let hasParams: boolean = false;

    if (hash.includes("?")) {
      hasParams = true;
    }

    // check to see if we're at a detail view, like
    // /#/shared-services/requests/request/1843
    // last url part will be a number
    if (!hasParams) {
      const urlParts: string[] = hash.split("/");
      if (urlParts && urlParts.length > 0) {
        const lastPart: string = urlParts[urlParts.length - 1];

        if (!isNaN(Number(lastPart))) {
          hasParams = true;
        }
      }
    }

    let permission: boolean = false;
    let primaryNavPermission: boolean = false;
    let hasSecondaryNav: boolean = false;
    let secondaryNavPermission: boolean = false;

    adminAppRoutes.map(adminAppRoute => {
      if (adminAppRoute.href === hash) {
        primaryNavPermission = true;

        if (adminAppRoute.secondaryNavRoutes && adminAppRoute.secondaryNavRoutes.length > 0) {
          hasSecondaryNav = true;
        }
      }
    });

    adminAppRoutes.map(adminAppRoute => {
      if (adminAppRoute.secondaryNavRoutes && adminAppRoute.secondaryNavRoutes.length > 0) {
        adminAppRoute.secondaryNavRoutes.map(secondaryNavRoute => {
          if (secondaryNavRoute.href === hash) {
            secondaryNavPermission = true;
          }

          // if we have params, we need to check includes instead of strict equals
          if (hasParams && hash.includes(secondaryNavRoute.href)) {
            secondaryNavPermission = true;
          }
        });
      }
    });

    if ((primaryNavPermission && !hasSecondaryNav) || secondaryNavPermission) {
      permission = true;
    }

    this.setState({
      permission
    });
  }
}

export const mapStateToProps = (state: any) => {
  return {
    adminAppRoutes: state.adminAppRoutes.adminAppRoutes,
    adminAppRoutesLoading: state.adminAppRoutes.loading,
    adminAppRoutesError: state.adminAppRoutes.errorMessage
  };
};

export default connect(mapStateToProps)(AdGroupsList);
