import * as React from "react";
import Band from "../../../components/layout/Band";
import H1 from "../../../components/typography/H1";
import Permissions from "../../permissions/Permissions";
import Section from "../../../components/layout/Section";

class Colors extends React.Component {
  public render() {
    const title: string = "Design System: Colors";

    return (
      <div className="typography">
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          <Permissions title={title}>
            <p>Overview</p>
          </Permissions>
        </Section>
      </div>
    );
  }
}

export default Colors;
