import * as React from "react";

class Overlay extends React.Component {
  public render() {
    return (
      <div className="overlay" onClick={(e: any) => this._toggleMenu(e)} />
    );
  }
  private _toggleMenu(e: any): void {
    e.preventDefault();
    const body: HTMLElement | null = document.getElementById("body");
    if (body) {
      if (body.classList.contains("menuOpen")) {
        body.classList.add("menuIsClosing");
        setTimeout(
          () => {
            body.classList.remove("menuOpen", "menuIsClosing");
          },
          400
        );
      } else {
        body.classList.add("menuOpen");
      }
    }
  }
}

export default Overlay;
