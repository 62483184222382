import { IUserRequestTypes } from "../types/User";

// RequestsList
export function hasOnlyHRRequests(requestTypes: IUserRequestTypes[]): boolean {
  let onlyHrRequests: boolean = true;

  if (requestTypes && requestTypes.length > 0) {
    requestTypes.map(requestType => {
      if (requestType.RequestTypeID > 3) {
        onlyHrRequests = false;
      }
    });
  }

  return onlyHrRequests;
}

/**
 * Returns name from MS Graph API user properties
 * @param displayName
 * @param givenName
 * @param surname
 */
export function renderName(displayName: string | null, givenName: string | null, surname: string | null): string {
  return displayName ? displayName : givenName && surname ? `${givenName} ${surname}` : "";
}

/**
 * Returns an email address from MS Graph API user properties
 * @param mail
 * @param userPrincipalName
 */
export function renderEmail(mail: string | null | undefined, userPrincipalName: string): string {
  return mail ? mail : userPrincipalName;
}

/**
 * Return a username from an email
 * @example zwinnie from zwinnie@coke-bsna.com
 * @param emailPart
 */
export function renderUsername(email: string): string {
  let username: string = "";

  if (email && email.indexOf("@") > -1) {
    const emailParts: string[] = email.split("@");
    username = emailParts[0];
  } else if (email) {
    username = email;
  }

  return username;
}
