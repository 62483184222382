import * as React from "react";
import Band from "../../components/layout/Band";
import H1 from "../../components/typography/H1";
import Section from "../../components/layout/Section";
import Button from "../../components/button/Button";
import Alert from "../../components/alert/Alert";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Loader from "../../components/loader/Loader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withPageLog } from "../logging/LogComponentChange";
import { resetPassword } from "../../core/wifiNetwork/wifiNetwork";
import Permissions from "../permissions/Permissions";
import ErrorUnauthorized from "../../components/errors/ErrorUnauthorized";

export interface IWifiNetworkProps {
  resetPassword: any;
  wifi: any;
  loading: boolean;
  wifiNetwork: boolean;
}

export interface IWifiNetworkState {
  loading: boolean;
}

export class WifiNetwork extends React.Component<IWifiNetworkProps, IWifiNetworkState> {
  public constructor(props: any) {
    super(props);
    this.state = { loading: false };
    this.generateNewPassword = this.generateNewPassword.bind(this);
  }

  generateNewPassword() {
    this.setState({ loading: true });
    this.props.resetPassword().then(() => {
      this.setState({ loading: false });
    });
  }

  public render(): React.ReactElement<IWifiNetworkProps> {
    const { wifi, wifiNetwork } = this.props;

    const title: string = "WiFi Network";

    return (
      <div className={"wifiNetwork"}>
        <Band>
          <H1>{title}</H1>
        </Band>
        <Section>
          {!wifiNetwork && <ErrorUnauthorized errorMessage={`You do not have permissions to manage ${title}.`} />}
          {wifiNetwork && (
            <Permissions title={title}>
              <p>Click the button below to force a password reset for the WiFi Network.</p>
              <Button
                buttonType="submit"
                className="buttonPrimary"
                disabled={false}
                text="Reset Password"
                title="Reset Password"
                onClick={() => this.generateNewPassword()}
              />
              <LoaderWrapper>
                <Loader loading={this.state.loading} type="Overlay" text="Generating New Password..." />
                {wifi.error !== null && (
                  <Alert
                    show={true}
                    icon="Info"
                    text="Error: A new password for the WiFi Network was not generated. Please try again."
                    type="Error"
                  />
                )}
                {wifi.wifi !== null &&
                  wifi.wifi.status === 204 && (
                    <div>
                      <p>The new password for the WiFi Network is:</p>
                      <h4>{wifi.wifi.generated}</h4>
                    </div>
                  )}
              </LoaderWrapper>
            </Permissions>
          )}
        </Section>
      </div>
    );
  }
}

export const mapStateToProps = (state: any) => {
  return {
    wifi: state.wifi,
    wifiNetwork: state.featureFlags.wifiNetwork
  };
};

export default withRouter(connect(
  mapStateToProps,
  {
    resetPassword
  }
)(withPageLog(WifiNetwork)) as any);
