import * as React from "react";
import { debounce } from "lodash";
// import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { toast } from "react-toastify";
import { sortBy } from "lodash";
// components
import Button from "../button/Button";
import Label from "../label/Label";
import Loader from "../loader/Loader";
import { Hr } from "../typography";
// helpers
import { renderUsername, renderName, renderEmail } from "../../helpers/user";
// types
import { IAddtionalUser, IUser, IMSGraphUser, IAddAddtionalUser } from "../../types/User";
// services
import { getAdUsers } from "../../services/AdUsers";
import { addAddtionalUser, removeAddtionalUser } from "../../services/AdditionalUsers";

interface IRequestDetailAdditionalUsersProps {
  assignees: IUser[];
  bottlerDomain: string;
  initialAdditionalUsers: IAddtionalUser[];
  isLoading: boolean;
  requestId: number;
  requestUser: IUser;
}

interface IRequestDetailAdditionalUsersState {
  additionalUsers: IAddtionalUser[];
  isAdding: boolean;
  isRemoving: {
    [uuid: string]: boolean;
  };
  newAdditionalUsers: IAddAddtionalUser[];
  newAdditionalUsersLoading: boolean;
  showOptions: boolean;
}

class RequestDetailAdditionalUsers extends React.PureComponent<IRequestDetailAdditionalUsersProps, IRequestDetailAdditionalUsersState> {
  constructor(props: IRequestDetailAdditionalUsersProps) {
    super(props);

    this.state = {
      additionalUsers: this.props.initialAdditionalUsers,
      isAdding: false,
      isRemoving: {},
      newAdditionalUsers: [],
      newAdditionalUsersLoading: false,
      showOptions: true
    };

    this._getNewAdditionalUsers = debounce(this._getNewAdditionalUsers.bind(this), 300);
  }

  render() {
    const { isLoading } = this.props;
    const { additionalUsers, isAdding, isRemoving, showOptions } = this.state;

    return (
      <>
        <Label className="h6" htmlFor="additional-users" text="Add Additional Users" />
        {showOptions && (
          <AsyncSelect
            cacheOptions={false}
            className="reactSelect requestAdditionalUsersSelect"
            classNamePrefix="reactSelect"
            controlShouldRenderValue={false}
            defaultOptions={false}
            hideSelectedOptions={false}
            inputId="additional-users"
            isDisabled={isLoading}
            isLoading={isLoading}
            isOptionDisabled={option => this._renderDisabledOption(option)}
            loadOptions={this._getNewAdditionalUsers}
            loadingMessage={inputValue => "Searching..."}
            maxMenuHeight={140}
            // menuIsOpen={true}
            noOptionsMessage={inputValue => "Start typing an email or name to search..."}
            onChange={(value: any) => this.handleAdd(value)}
            pageSize={5}
            placeholder={"Search by email or name..."}
          />
        )}

        <Loader loading={isAdding} type="Inline" text="Adding User..." showImage={true} />

        <ul className="requestAdditionalUsers">
          {sortBy(additionalUsers, ["userName"]).map((additionalUser: IAddtionalUser) =>
            isRemoving[additionalUser.uuid] ? (
              <Loader type="Inline" loading={true} text="Removing User..." showImage={true} key={additionalUser.uuid} />
            ) : (
              <li className="requestAdditionalUsersListItem flexAlignCenter" key={additionalUser.uuid}>
                {additionalUser.userName} ({additionalUser.userEmail}){" "}
                <Button text="Remove" title="Remove" className="buttonGeneral removeButton" onClick={() => this.handleRemove(additionalUser)} />
              </li>
            )
          )}
        </ul>
        <Hr />
      </>
    );
  }

  private _getNewAdditionalUsers(inputValue: string, callback: any): void {
    this.setState(
      {
        newAdditionalUsersLoading: true
      },
      async () => {
        let newAddtionalUsers: IAddAddtionalUser[] = [];

        if (inputValue) {
          try {
            const { assignees, bottlerDomain } = this.props;

            const msGraphUsers: IMSGraphUser[] = await getAdUsers(inputValue, bottlerDomain);

            msGraphUsers.map(msGraphUser => {
              const name = renderName(msGraphUser.displayName, msGraphUser.givenName, msGraphUser.surname);
              const email = renderEmail(msGraphUser.mail, msGraphUser.userPrincipalName);

              newAddtionalUsers.push({
                azureObjectId: msGraphUser.id,
                email: email,
                id: null,
                label: `${name} (${email})`,
                name: name,
                value: msGraphUser.id
              });
            });

            assignees.map(assignee => {
              if (assignee && assignee.name && (assignee.name.includes(inputValue) || assignee.email.includes(inputValue))) {
                newAddtionalUsers.push({
                  azureObjectId: assignee.azureObjectId as string,
                  email: assignee.email,
                  id: assignee.id,
                  label: `${assignee.name} (${assignee.email})`,
                  name: assignee.name,
                  value: assignee.email
                });
              }
            });

            if (newAddtionalUsers.length === 0) {
              const username: string = renderUsername(inputValue);
              const email: string = `${username}@${bottlerDomain}`;

              newAddtionalUsers.push({
                azureObjectId: email,
                email: email,
                id: null,
                label: `No users found.`,
                name: "",
                value: email
              });
            }

            callback(newAddtionalUsers);

            this.setState({
              newAdditionalUsers: newAddtionalUsers,
              newAdditionalUsersLoading: false
            });
          } catch (error) {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        } else {
          this.setState({
            newAdditionalUsers: [],
            newAdditionalUsersLoading: false
          });
        }
      }
    );
  }

  private _renderDisabledOption(option: IAddAddtionalUser): boolean {
    const { requestUser } = this.props;
    const { additionalUsers } = this.state;

    let disabled: boolean = false;

    additionalUsers.map(additionalUser => {
      if (
        additionalUser.userEmail === option.email ||
        additionalUser.userId === option.id ||
        requestUser.email === option.email ||
        requestUser.id === option.id ||
        option.label === "No users found."
      ) {
        disabled = true;
      }
    });

    return disabled;
  }

  handleAdd = async (selectedOption: IAddAddtionalUser) => {
    const { additionalUsers } = this.state;

    const { requestId } = this.props;

    this.setState({ isAdding: true, showOptions: false });

    try {
      const res = await addAddtionalUser({
        azureObjectId: selectedOption.azureObjectId,
        email: selectedOption.email,
        name: selectedOption.name,
        requestId: requestId,
        userId: selectedOption.id
      });

      this.setState({
        additionalUsers: [res.additionalUser, ...additionalUsers],
        isAdding: false,
        showOptions: true
      });

      toast.success("User added.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    } catch (error) {
      this.setState({ isAdding: false, showOptions: true });

      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  handleRemove = async (user: IAddtionalUser) => {
    const { additionalUsers } = this.state;

    this.setState({ isRemoving: { [user.uuid]: true }, showOptions: false });

    try {
      const res = await removeAddtionalUser(user.uuid);

      if (res.deleted) {
        this.setState({
          additionalUsers: additionalUsers.filter(additionalUser => additionalUser.userEmail !== user.userEmail),
          isRemoving: {},
          showOptions: true
        });

        toast.success("User removed.", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    } catch (error) {
      this.setState({ isRemoving: {} });

      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };
}

export default RequestDetailAdditionalUsers;
