import { IDoc, IDocs, IDocDeleted } from "../types/Doc";
import { IError, IErrorResponse } from "../types/Error";
import { postFiles, postRequest, getRequest } from "../core/api/requestConfig";

export function postDocs(requestId: string, requestUuid: string, docsNew: any[], userId: string): Promise<IDocs> {
  return new Promise<IDocs>((resolve, reject) => {
    const formData: FormData = new FormData();

    docsNew.map(doc => {
      formData.append("files", doc);
    });
    formData.append("parentId", requestUuid);
    formData.append("requestId", requestId);
    formData.append("userId", userId);

    postFiles("/api/v1/uploadFiles", formData)
      .then((createdDocs: IDoc[]) => {
        const docs: IDocs = {
          docs: createdDocs
        };
        return resolve(docs);
      })
      .catch((error: IError) => {
        console.error(error.error);
        const docs: IDocs = {
          docs: [],
          error: error.error
        };
        return reject(docs);
      });
  });
}

export function deleteDoc(doc: IDoc): Promise<IDocDeleted> {
  return new Promise<IDocDeleted>((resolve, reject) => {
    postRequest("/api/v1/softDeleteFile", doc)
      .then(response => {
        const deleted: IDocDeleted = {
          deleted: response,
          error: null,
          success: "Document deleted successfully."
        };
        return resolve(deleted);
      })
      .catch((error: IErrorResponse) => {
        const deleted: IDocDeleted = {
          deleted: false,
          error: error.response.data.message,
          success: null
        };
        return reject(deleted);
      });
  });
}

export function getDocUrl(blobName: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    getRequest("/api/v1/getDocumentDownloadUrl?name=" + blobName)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        return reject(error);
      });
  });
}
