import * as React from "react";

export interface ITableProps {
  className?: string;
  children: any;
}
const Table = ({ className, children }: ITableProps) => {
  return (
    <table className={className ? `table ${className}` : "table"}>
      {children}
    </table>
  );
};

export default Table;
