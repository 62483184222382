import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { uniqBy } from "lodash";
// components
import Alert from "../../../components/alert/Alert";
import Button from "../../../components/button/Button";
import Checkbox from "../../../components/input/Checkbox";
import DateTimePicker from "../../../components/input/DateTimePicker";
import Filter from "../../../components/filter/Filter";
import H6 from "../../../components/typography/H6";
import Kanban from "../../../components/kanban/Kanban";
import Label from "../../../components/label/Label";
import Loader from "../../../components/loader/Loader";
import LoaderWrapper from "../../../components/loader/LoaderWrapper";
import Search from "../../../components/search/Search";
import RefreshGroup from "../../../components/refresh/Group";
// helpers
import { getRequestTypeIds } from "../../../helpers/request";
// services
import { getRequests } from "../../../services/Request";
import { updateRequestStatus } from "../../../services/RequestStatus";
import { getRequestTypesForFilter } from "../../../services/RequestType";
import { getFilters } from "../../../services/Filters";
// logging
import { withPageLog } from "../../logging/LogComponentChange";
// types
import { IBottler } from "../../../types/Bottler";
import { IRequest, IRequestList } from "../../../types/Request";
import { IRequestTypeFilter, IRequestTypeWithProcessFilter } from "../../../types/RequestType";
import { IUser, IUserFilter } from "../../../types/User";
import { IBottlerFilter } from "../../../types/Bottler";
import { IRequestGroupFilter } from "../../../types/RequestGroup";
import { ITowerFilter } from "../../../types/Tower";
import { IFilters } from "../../../types/Filters";

interface IRequestKanbanProps {
  bottler: IBottler;
  user: IUser;
  refreshId?: string;
}

interface IRequestKanbanState {
  archivedCount: number | 0;
  archivedData: IRequest[];
  areFiltersLoading: boolean;
  assignedTo: IUserFilter[];
  bottlers: IBottlerFilter[];
  completedCount: number | 0;
  completedData: IRequest[];
  createdAtEnd: string | null;
  createdAtStart: string | null;
  dueDateEnd: string | null;
  dueDateStart: string | null;
  hasNoData: boolean;
  isLoading: boolean;
  isLoadingMore: {
    [id: number]: boolean;
  };
  isNotAssignedChecked: boolean;
  newCount: number | 0;
  newData: IRequest[];
  page: {
    [id: number]: number;
  };
  processingCount: number | 0;
  processingData: IRequest[];
  requestGroups: IRequestGroupFilter[];
  requestTypeIds: number[];
  requestTypes: IRequestTypeFilter[];
  requests: IRequest[];
  searchTerm: string;
  selectedAssignedTo: number[];
  selectedBottlers: number[];
  selectedRequestGroups: number[];
  selectedRequestTypes: number[];
  selectedTowers: number[];
  selectedUsers: number[];
  sortBy: string;
  sortOrder: string;
  towers: ITowerFilter[];
  userRequestTypes: number[];
  users: IUserFilter[];
}

type selectKeys = "selectedAssignedTo" | "selectedBottlers" | "selectedRequestGroups" | "selectedRequestTypes" | "selectedTowers" | "selectedUsers";

class RequestsKanban extends React.Component<IRequestKanbanProps, IRequestKanbanState> {
  private timer;
  private DEFAULT_SORT_ORDER: string = "DESC";
  private DEFAULT_SORT_BY: string = "[request].[id]";
  state = {
    archivedCount: 0,
    archivedData: [],
    areFiltersLoading: false,
    assignedTo: [],
    bottlers: [], // default to all bottlers
    completedCount: 0,
    completedData: [],
    createdAtEnd: null,
    createdAtStart: null,
    dueDateEnd: null,
    dueDateStart: null,
    hasNoData: false,
    isLoading: false,
    isLoadingMore: {},
    isNotAssignedChecked: true,
    newCount: 0,
    newData: [],
    page: {},
    processingCount: 0,
    processingData: [],
    requestGroups: [], // default all request groups
    requestTypeIds: [],
    requestTypes: [], // default all request types
    requests: [],
    searchTerm: "",
    selectedAssignedTo: [],
    selectedBottlers: [],
    selectedRequestGroups: [],
    selectedRequestTypes: [],
    selectedTowers: [],
    selectedUsers: [],
    sortBy: this.DEFAULT_SORT_BY,
    sortOrder: this.DEFAULT_SORT_ORDER,
    towers: [], // default all towers
    userRequestTypes: [],
    users: [] // default all users
  };
  public async componentDidMount() {
    this.setState({ isLoading: true });
    const { user } = this.props;
    const requestTypeFilters: IRequestTypeFilter[] = await getRequestTypesForFilter();
    const types: number[] = getRequestTypeIds(requestTypeFilters, user);
    this.setState({ userRequestTypes: types });
    this.fetchFilters();
    this.fetchandSetData();
  }
  public componentDidUpdate(prevProps: IRequestKanbanProps) {
    const { bottler } = this.props;
    const { assignedTo, bottlers, towers, requestGroups, requestTypeIds, users }: IRequestKanbanState = this.state;
    if ((prevProps.bottler && prevProps.bottler.id) !== (bottler && bottler.id)) {
      this.fetchandSetData();
      // reset the filters to all
      this.setState({
        selectedAssignedTo: assignedTo.map(a => a.id),
        selectedBottlers: bottlers.map(b => b.id),
        selectedTowers: towers.map(t => t.id),
        selectedRequestGroups: requestGroups.map(rg => rg.id),
        selectedRequestTypes: requestTypeIds,
        selectedUsers: users.map(u => u.id)
      });
    }
  }
  public render() {
    const { bottler, refreshId } = this.props;
    const {
      archivedCount,
      archivedData,
      areFiltersLoading,
      assignedTo,
      bottlers,
      completedCount,
      completedData,
      dueDateEnd,
      dueDateStart,
      hasNoData,
      isLoading,
      isLoadingMore,
      isNotAssignedChecked,
      newCount,
      newData,
      page,
      processingCount,
      processingData,
      requestGroups,
      requestTypeIds,
      requestTypes,
      searchTerm,
      selectedAssignedTo,
      selectedBottlers,
      selectedRequestGroups,
      selectedRequestTypes,
      selectedTowers,
      selectedUsers,
      towers,
      users
    }: IRequestKanbanState = this.state;
    return (
      <div className="requestsKanban">
        {refreshId && <RefreshGroup isLoading={isLoading} handleRefresh={() => this.fetchandSetData()} refreshId={refreshId} />}
        <LoaderWrapper>
          <div className="kanbanFilters">
            <Search
              autoFocus={true}
              onSearchChange={e => this.handleSearch(e.target.value)}
              placeholder="Search requests..."
              search={searchTerm}
              searchButtonTitle="Search Requests"
              searchDisabled={false}
              searchLabelText="Search Requests"
              showSearchButton={false}
              showExport={false}
            />
            {!bottler && (
              <div className="flexAlignCenter">
                <Label text="Bottler" />
                <Filter
                  filterDisabled={selectedBottlers.length === bottlers.length && hasNoData}
                  filterText="Bottler"
                  filterPosition="filterLeft"
                  filtered={selectedBottlers.length !== bottlers.length}
                >
                  <>
                    <Checkbox
                      key="bottler-all"
                      checked={selectedBottlers.length === bottlers.length}
                      className="checkboxHr"
                      id="bottler-all"
                      name="bottler-all"
                      onChange={e => {
                        if (selectedBottlers.length !== bottlers.length) {
                          return this.handleFilterAdd(
                            "selectedBottlers",
                            bottlers.map(b => b.id)
                          );
                        }
                        return this.handleFilterAdd("selectedBottlers", []);
                      }}
                      text="Toggle All"
                    />
                    {bottlers.map((b: IBottlerFilter, index: number) => {
                      const isChecked: boolean = selectedBottlers.includes(b.id);
                      return (
                        <Checkbox
                          key={`bottler-${index}`}
                          checked={isChecked}
                          id={`bottler-${index}`}
                          name={`bottler-${index}`}
                          onChange={() => {
                            if (isChecked) {
                              return this.handleFilterRemove("selectedBottlers", b.id);
                            }
                            return this.handleFilterAdd("selectedBottlers", [...selectedBottlers, b.id]);
                          }}
                          text={b.name}
                        />
                      );
                    })}
                  </>
                </Filter>
              </div>
            )}
            <div className="flexAlignCenter">
              <Label text="Tower" />
              <Filter
                filterDisabled={selectedTowers.length === towers.length && hasNoData}
                filterText="Tower"
                filterPosition="filterLeft"
                filtered={selectedTowers.length !== towers.length}
              >
                <>
                  <Checkbox
                    key="tower-all"
                    checked={selectedTowers.length === towers.length}
                    className="checkboxHr"
                    id="tower-all"
                    name="tower-all"
                    onChange={e => {
                      if (selectedTowers.length !== towers.length) {
                        return this.handleFilterAdd(
                          "selectedTowers",
                          towers.map(t => t.id)
                        );
                      }
                      return this.handleFilterAdd("selectedTowers", []);
                    }}
                    text="Toggle All"
                  />
                  {towers.map((t: ITowerFilter, index: number) => {
                    const isChecked: boolean = selectedTowers.includes(t.id);
                    return (
                      <Checkbox
                        key={`tower-${index}`}
                        checked={isChecked}
                        id={`tower-${index}`}
                        name={`tower-${index}`}
                        onChange={() => {
                          if (isChecked) {
                            return this.handleFilterRemove("selectedTowers", t.id);
                          }
                          return this.handleFilterAdd("selectedTowers", [...selectedTowers, t.id]);
                        }}
                        text={t.label}
                      />
                    );
                  })}
                </>
              </Filter>
            </div>
            <div className="flexAlignCenter">
              <Label text="Request Type" />
              <Filter
                filterDisabled={selectedRequestTypes.length === requestTypeIds.length && hasNoData}
                filterText="Request Type"
                filterPosition="filterLeft"
                filtered={selectedRequestTypes.length !== requestTypeIds.length}
              >
                <>
                  <Checkbox
                    key="requestTypes-all"
                    checked={selectedRequestTypes.length === requestTypeIds.length}
                    className="checkboxHr"
                    id="requestTypes-all"
                    name="requestTypes-all"
                    onChange={e => {
                      if (selectedRequestTypes.length !== requestTypeIds.length) {
                        return this.handleFilterAdd("selectedRequestTypes", requestTypeIds);
                      }
                      return this.handleFilterAdd("selectedRequestTypes", []);
                    }}
                    text="Toggle All"
                  />
                  {requestTypes.map((rt: IRequestTypeFilter, i: number) => (
                    <React.Fragment key={`requestTypelabel-${i}`}>
                      <H6 className="requestTypeFilterLabel">{rt.requestProcessLabel}</H6>
                      {rt.requestTypes.map((type: IRequestTypeWithProcessFilter) => {
                        const isChecked: boolean = selectedRequestTypes.includes(type.id);
                        return (
                          <Checkbox
                            key={`requestType-${type.id}`}
                            checked={isChecked}
                            id={`requestType-${type.id}`}
                            name={`requestType-${type.id}`}
                            onChange={() => {
                              if (isChecked) {
                                return this.handleFilterRemove("selectedRequestTypes", type.id);
                              }
                              return this.handleFilterAdd("selectedRequestTypes", [...selectedRequestTypes, type.id]);
                            }}
                            text={type.label}
                          />
                        );
                      })}
                    </React.Fragment>
                  ))}
                </>
              </Filter>
            </div>
            <div className="flexAlignCenter">
              <Label text="Request Group" />
              <Filter
                filterDisabled={selectedRequestGroups.length === requestGroups.length && hasNoData}
                filterText="Request Group"
                filterPosition="filterLeft"
                filtered={selectedRequestGroups.length !== requestGroups.length}
              >
                <>
                  <Checkbox
                    key="requestGroups-all"
                    checked={selectedRequestGroups.length === requestGroups.length}
                    className="checkboxHr"
                    id="requestGroups-all"
                    name="requestGroups-all"
                    onChange={e => {
                      if (selectedRequestGroups.length !== requestGroups.length) {
                        return this.handleFilterAdd(
                          "selectedRequestGroups",
                          requestGroups.map(rg => rg.id)
                        );
                      }
                      return this.handleFilterAdd("selectedRequestGroups", []);
                    }}
                    text="Toggle All"
                  />
                  {requestGroups.map((rg: IRequestGroupFilter, index: number) => {
                    const isChecked: boolean = selectedRequestGroups.includes(rg.id);
                    return (
                      <Checkbox
                        key={`requestGroup-${index}`}
                        checked={isChecked}
                        id={`requestGroup-${index}`}
                        name={`requestGroup-${index}`}
                        onChange={() => {
                          if (isChecked) {
                            return this.handleFilterRemove("selectedRequestGroups", rg.id);
                          }
                          return this.handleFilterAdd("selectedRequestGroups", [...selectedRequestGroups, rg.id]);
                        }}
                        text={rg.label}
                      />
                    );
                  })}
                </>
              </Filter>
            </div>
            <div className="flexAlignCenter">
              <Label text="Requester" />
              <Filter
                filterDisabled={selectedUsers.length === users.length && hasNoData}
                filterText="Requester"
                filterPosition="filterLeft"
                filtered={selectedUsers.length !== users.length}
              >
                <>
                  <Checkbox
                    key="users-all"
                    checked={selectedUsers.length === users.length}
                    className="checkboxHr"
                    id="users-all"
                    name="users-all"
                    onChange={e => {
                      if (selectedUsers.length !== users.length) {
                        return this.handleFilterAdd(
                          "selectedUsers",
                          users.map(rg => rg.id)
                        );
                      }
                      return this.handleFilterAdd("selectedUsers", []);
                    }}
                    text="Toggle All"
                  />
                  {users.map((u: IUserFilter, index: number) => {
                    const isChecked: boolean = selectedUsers.includes(u.id);
                    return (
                      <Checkbox
                        key={`user-${index}`}
                        checked={isChecked}
                        id={`user-${index}`}
                        name={`user-${index}`}
                        onChange={() => {
                          if (isChecked) {
                            return this.handleFilterRemove("selectedUsers", u.id);
                          }
                          return this.handleFilterAdd("selectedUsers", [...selectedUsers, u.id]);
                        }}
                        text={u.name}
                      />
                    );
                  })}
                </>
              </Filter>
            </div>
            <div className="flexAlignCenter">
              <Label text="Due Date" />
              <Filter className="hasCalendar" filterDisabled={!dueDateEnd && !dueDateStart && hasNoData} filterText="Due Date" filterPosition="filterLeft" filtered={false}>
                <>
                  <div className="field">
                    <Label htmlFor="" text="Due After" />
                    <DateTimePicker
                      date={dueDateStart}
                      id="dueDateDate"
                      minDate={undefined}
                      onChange={(date: Date) =>
                        this.setState(
                          {
                            dueDateStart: moment(date).toISOString()
                          },
                          () => this.fetchandSetData()
                        )
                      }
                      time={dueDateStart}
                    />
                  </div>
                  <div className="field">
                    <Label htmlFor="" text="Due Before" />
                    <DateTimePicker
                      date={dueDateEnd}
                      id="dueDateTime"
                      minDate={undefined}
                      onChange={(date: Date) =>
                        this.setState(
                          {
                            dueDateEnd: moment(date).toISOString()
                          },
                          () => this.fetchandSetData()
                        )
                      }
                      time={dueDateEnd}
                    />
                  </div>
                  <div className="field">
                    <Button
                      className="buttonInfo buttonBlock"
                      disabled={!dueDateEnd && !dueDateStart}
                      onClick={e => this.setState({ dueDateEnd: null, dueDateStart: null }, () => this.fetchandSetData())}
                      text="Clear Filter"
                      title="Clear Fitler"
                    />
                  </div>
                </>
              </Filter>
            </div>
            <div className="flexAlignCenter">
              <Label text="Assigned To" />
              <Filter
                filterDisabled={selectedAssignedTo.length === assignedTo.length && hasNoData}
                filterText="Assigned To"
                filterPosition="filterLeft"
                filtered={selectedAssignedTo.length !== assignedTo.length || !isNotAssignedChecked}
              >
                <>
                  <Checkbox
                    checked={selectedAssignedTo.length === assignedTo.length}
                    className="checkboxHr"
                    id="assignedTo-all"
                    name="assignedTo-all"
                    onChange={() => {
                      if (selectedAssignedTo.length !== assignedTo.length) {
                        this.setState({ isNotAssignedChecked: true });
                        return this.handleFilterAdd(
                          "selectedAssignedTo",
                          assignedTo.map(rg => rg.id)
                        );
                      }
                      this.setState({ isNotAssignedChecked: false });
                      return this.handleFilterAdd("selectedAssignedTo", []);
                    }}
                    text="Toggle All"
                  />
                  <Checkbox
                    checked={isNotAssignedChecked}
                    className="checkboxHr"
                    id="assignedTo-null"
                    name="assignedTo-null"
                    onChange={() => this.setState({ isNotAssignedChecked: !isNotAssignedChecked }, this.fetchandSetData)}
                    text="Not Assigned"
                  />
                  {assignedTo.map((a: IUserFilter, index: number) => {
                    const isChecked: boolean = selectedAssignedTo.includes(a.id);
                    return (
                      <Checkbox
                        key={`assignedTo-${index}`}
                        checked={isChecked}
                        id={`assignedTo-${index}`}
                        name={`assignedTo-${index}`}
                        onChange={() => {
                          if (isChecked) {
                            return this.handleFilterRemove("selectedAssignedTo", a.id);
                          }
                          return this.handleFilterAdd("selectedAssignedTo", [...selectedAssignedTo, a.id]);
                        }}
                        text={a.name}
                      />
                    );
                  })}
                </>
              </Filter>
            </div>
          </div>
          <Kanban
            isLoadingMore={isLoadingMore}
            page={page}
            handleLoadMore={this.handleLoadMore}
            handleUpdate={this.fetchandSetData}
            handleDragEnd={async result => {
              try {
                await updateRequestStatus({
                  requestId: result.draggableId,
                  statusId: Number(result.destination.droppableId)
                });
              } catch (error) {
                toast.error(error.error, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                // reset the state if there is an error
                this.setState({ ...this.state });
              }
            }}
            alert={
              <Alert show={hasNoData && !isLoading} type="Info" className="noDataKanban">
                There are no requests. Switch your Bottler or{" "}
                <a href="#" onClick={e => this.handleSearch("")}>
                  undo your search
                </a>
                .
              </Alert>
            }
            columnHeadings={[
              {
                id: "2",
                text: "New"
              },
              {
                id: "3",
                text: "Processing"
              },
              {
                id: "4",
                text: "Completed"
              },
              {
                id: "5",
                text: "Archived"
              }
            ]}
            columns={{
              "2": {
                count: newCount,
                data: newData
              },
              "3": {
                count: processingCount,
                data: processingData
              },
              "4": {
                count: completedCount,
                data: completedData
              },
              "5": {
                count: archivedCount,
                isDropDisabled: true,
                data: archivedData
              }
            }}
          />
          <Loader loading={isLoading || areFiltersLoading} type="Overlay" showImage={true} text="Loading..." position="Top" />
        </LoaderWrapper>
        <ToastContainer />
      </div>
    );
  }
  fetchFilters = async () => {
    this.setState({ areFiltersLoading: true });
    try {
      const filters: IFilters | null = await getFilters({
        includeAssignedTo: true,
        includeBottlers: true,
        includeTowers: true,
        includeRequestTypes: true,
        includeRequestGroups: true,
        includeUsers: true
      });

      if (filters && filters.message) {
        toast.error(filters.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });

        return this.setState({
          areFiltersLoading: false
        });
      }
      const { userRequestTypes }: IRequestKanbanState = this.state;
      const requestTypeIds = [].concat.apply(
        [],
        // @ts-ignore
        filters.requestTypes.map(rt => rt.requestTypes.map(type => type.id))
      );
      let filterRequestTypes: IRequestTypeFilter[] = [];
      // filter out requestType filtering options and only show the ones the user has access to
      // there might be a case where a user changes the request type to something they are not allowed to see
      filters.requestTypes.map(rt => {
        rt.requestTypes.map(r => {
          if (userRequestTypes.includes(r.id)) {
            filterRequestTypes.push(rt);
          }
        });
      });

      filterRequestTypes = uniqBy(filterRequestTypes, "requestProcessId");

      return this.setState({
        assignedTo: filters.assignedTo,
        areFiltersLoading: false,
        bottlers: filters.bottlers,
        requestGroups: filters.requestGroups,
        requestTypes: filterRequestTypes,
        requestTypeIds,
        towers: filters.towers,
        users: filters.users,
        selectedAssignedTo: filters.assignedTo.map(a => a.id),
        selectedBottlers: filters.bottlers.map(b => b.id),
        selectedTowers: filters.towers.map(t => t.id),
        selectedRequestGroups: filters.requestGroups.map(rg => rg.id),
        selectedRequestTypes: requestTypeIds,
        selectedUsers: filters.users.map(u => u.id)
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.setState({
        areFiltersLoading: false
      });
    }
  };
  fetchandSetData = async () => {
    this.setState({
      isLoading: true,
      // reset the page for each column to 0, used when changing filters
      page: {
        2: 0,
        3: 0,
        4: 0,
        5: 0
      }
    });
    try {
      const { newRequests, processingRequests, completedRequests, archivedRequests } = await this.fetchAllRequests();
      this.setState({
        archivedCount: archivedRequests.count,
        archivedData: archivedRequests.rows,
        completedCount: completedRequests.count,
        completedData: completedRequests.rows,
        hasNoData: processingRequests.rows.length === 0 && newRequests.rows.length === 0 && completedRequests.rows.length === 0 && archivedRequests.rows.length === 0,
        isLoading: false,
        newCount: newRequests.count,
        newData: newRequests.rows,
        processingCount: processingRequests.count,
        processingData: processingRequests.rows
      });
    } catch (error) {
      toast.error(error.error, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.setState({ isLoading: false });
    }
  };
  getTypes = () => {
    const { requestTypeIds, selectedRequestTypes, userRequestTypes } = this.state;
    if (requestTypeIds.length !== selectedRequestTypes.length) {
      return selectedRequestTypes;
    }
    return userRequestTypes;
  };
  handleLoadMore = async (statusId: number, statusLabel: string) => {
    await this.setState(state => ({
      isLoadingMore: {
        [statusId]: true
      },
      page: {
        ...state.page,
        [statusId]: state.page[statusId] + 1
      }
    }));
    const statusData: string = `${statusLabel.toLowerCase()}Data`;
    const requests: IRequestList = await this.fetchRequests(statusId, this.getTypes(), statusId === 5);
    this.setState(
      state =>
        ({
          [statusData]: state[statusData].concat(requests.rows),
          isLoadingMore: {}
        } as IRequestKanbanState)
    );
  };
  handleSearch = (searchTerm: string) => {
    clearTimeout(this.timer);

    this.setState(
      {
        searchTerm
      },
      () => {
        this.timer = setTimeout(() => {
          this.fetchandSetData();
        }, 300);
      }
    );
  };
  handleFilterAdd = (type: selectKeys, value: any) => {
    return this.setState(
      {
        [type]: value
      } as Pick<IRequestKanbanState, keyof IRequestKanbanState>,
      this.fetchandSetData
    );
  };
  handleFilterRemove = (type: selectKeys, id: number) => {
    const selectState = this.state[type];
    return this.setState(
      {
        [type]: selectState.filter(selectedId => selectedId !== id)
      } as Pick<any, selectKeys>,
      this.fetchandSetData
    );
  };
  fetchAllRequests = async () => {
    let types: number[] = this.getTypes();
    const [newRequests, processingRequests, completedRequests, archivedRequests] = await Promise.all([
      this.fetchRequests(2, types),
      this.fetchRequests(3, types),
      this.fetchRequests(4, types),
      this.fetchRequests(5, types, true)
    ]);

    return {
      newRequests,
      processingRequests,
      completedRequests,
      archivedRequests
    };
  };
  fetchRequests = async (status: number, types: number[], isArchived?: boolean) => {
    const { bottler, user } = this.props;
    const {
      assignedTo,
      bottlers,
      createdAtEnd,
      createdAtStart,
      dueDateEnd,
      dueDateStart,
      page,
      isNotAssignedChecked,
      requestGroups,
      requestTypeIds,
      searchTerm,
      selectedAssignedTo,
      selectedBottlers,
      selectedRequestGroups,
      selectedRequestTypes,
      selectedTowers,
      selectedUsers,
      towers,
      users
    } = this.state;
    const isFiltering: boolean =
      !isNotAssignedChecked ||
      assignedTo.length !== selectedAssignedTo.length ||
      bottlers.length !== selectedBottlers.length ||
      requestGroups.length !== selectedRequestGroups.length ||
      requestTypeIds.length !== selectedRequestTypes.length ||
      towers.length !== selectedTowers.length ||
      users.length !== selectedUsers.length ||
      !!dueDateEnd ||
      !!dueDateStart;
    return getRequests(
      selectedAssignedTo, // assignedTo
      isNotAssignedChecked, // assignedToShowNull
      (bottler && bottler.id) || null,
      selectedBottlers, // bottlers
      createdAtEnd, // createdAtStart
      createdAtStart, // createdAtEnd
      dueDateStart, // dueDateStart
      dueDateEnd, // dueDateEnd
      isFiltering, // filter
      true, // isAdmin
      10, // limit
      page[status] > 0 ? page[status] * 10 : null, // offset
      page[status], // page,
      selectedRequestGroups, // requestGroups,
      types, // requestTypes
      [1, 2], // request priorites
      ["[request].[requestPriorityId]", "[request].[id]"], // sortBy
      ["DESC", "DESC"], // sortOrder
      status === 5 ? [4] : [status], // statuses
      searchTerm, // search
      selectedTowers, // towers
      isArchived
        ? moment()
            .subtract(60, "days")
            .toString()
        : null, // updatedAtEnd
      !isArchived
        ? moment()
            .subtract(60, "days")
            .toString()
        : null, // updatedAtStart
      user.id,
      selectedUsers // users
    );
  };
}

export default withPageLog(RequestsKanban);
