import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import { Icon } from "office-ui-fabric-react";
// components
import Button from "../../components/button/Button";
import Field from "../../components/forms/Field";
import Label from "../../components/label/Label";
import LoaderGradient from "../../components/loader/LoaderGradient";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import Loader from "../../components/loader/Loader";
import Modal from "../../components/modal/Modal";
import Page from "../../components/page/Page";
import Select from "../../components/input/Select";
import TableWrapper from "../../components/table/TableWrapper";
import Table from "../../components/table/Table";
import Th from "../../components/table/Th";
import THead from "../../components/table/THead";
import Tr from "../../components/table/Tr";
import TBody from "../../components/table/TBody";
import Td, { TdWithLabel } from "../../components/table/Td";
// fetch
import { getAdGroupsFromSql } from "../../services/AdGroup";
import { getAdGroupRequestTypes, createAdGroupRequestType, deleteAdGroupRequestType } from "../../services/AdGroupRequestType";
import { getRequestTypes } from "../../services/RequestType";
// types
import { IRequestTypeV2 } from "../../types/RequestType";
import { IAdGroup } from "../../types/AdGroup";
import { IColumn } from "../../types/Column";
import { IAdGroupRequestType, IAdGroupRequestTypeCreate } from "../../types/AdGroupRequestType";
import { Hr, H2, P } from "../../components/typography";

const columns: IColumn[] = [
  { title: "Name", sortBy: "label", text: "Name" },
  { title: "Description", sortBy: "description", text: "Description" },
  {
    title: "Actions",
    sortBy: "",
    text: ""
  }
];

export interface IRequestTypesProps {}

export interface IRequestTypesState {
  adGroupRequestTypes: IAdGroupRequestType[];
  adGroupRequestTypesDeleting: boolean;
  adGroupRequestTypesLoading: boolean;
  adGroupRequestTypesSaving: boolean;
  adGroups: IAdGroup[];
  adGroupsLoading: boolean;
  loading: boolean;
  requestTypes: IRequestTypeV2[];
}

class RequestTypes extends React.Component<IRequestTypesProps, IRequestTypesState> {
  constructor(props: IRequestTypesProps) {
    super(props);

    this.state = {
      adGroupRequestTypes: [],
      adGroupRequestTypesDeleting: false,
      adGroupRequestTypesLoading: false,
      adGroupRequestTypesSaving: false,
      adGroups: [],
      adGroupsLoading: true,
      loading: true,
      requestTypes: []
    };

    this._handleAdGroupRequestTypeCreate = this._handleAdGroupRequestTypeCreate.bind(this);
    this._handleAdGroupRequestTypeDelete = this._handleAdGroupRequestTypeDelete.bind(this);
  }

  public componentDidMount(): void {
    this._getRequestTypes();
    this._getAdGroups();
  }

  public render(): React.ReactElement<IRequestTypesProps> {
    const { loading, requestTypes } = this.state;

    return (
      <Page className="requestTypeSettings" permissions={true} title="Settings: Request Types">
        <LoaderWrapper>
          <TableWrapper>
            <Table>
              <THead>
                <Tr>{this._renderHeaderColumns()}</Tr>
              </THead>
              <TBody>
                {requestTypes.map((requestType: IRequestTypeV2, index: number) => {
                  return (
                    <Tr hover={true} striped={true} key={index}>
                      <TdWithLabel label="Label">{requestType.label}</TdWithLabel>
                      <TdWithLabel label="Description">{requestType.description}</TdWithLabel>
                      <Td>
                        <Modal
                          body={[this._renderBody(requestType)]}
                          cancelIcon="Cancel"
                          cancelText="Cancel"
                          cancelTitle="Cancel"
                          className="requestTypeSettingsModal"
                          disabled={false}
                          heading={`Add & Remove AD Groups`}
                          id="requestTypeModal"
                          modalSize="Lg"
                          onOpen={() => this._getAdGroupRequestTypes(requestType.id)}
                          openClassName="buttonLinkPrimaryColor"
                          openIcon="Edit"
                          openText={`Edit`}
                          openTitle={`Edit`}
                          showCancel={true}
                          showSave={false}
                          showFooter={true}
                        />
                      </Td>
                    </Tr>
                  );
                })}
                {loading &&
                  ["1", "2", "3", "4"].map((row, index) => (
                    <Tr key={`loading-${row}`}>
                      {columns.map((column, i) => {
                        return (
                          <Td key={`column-${i}`}>
                            <LoaderGradient />
                          </Td>
                        );
                      })}
                    </Tr>
                  ))}
              </TBody>
            </Table>
          </TableWrapper>
          <Loader loading={loading} type="Overlay" showImage={true} position="Top" />
        </LoaderWrapper>
        <ToastContainer />
      </Page>
    );
  }

  private async _getRequestTypes(): Promise<void> {
    try {
      const response = await getRequestTypes();

      this.setState({
        loading: false,
        requestTypes: response.requestTypes
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.setState({
        loading: false
      });
    }
  }

  private async _getAdGroups(): Promise<void> {
    try {
      const response = await getAdGroupsFromSql();

      this.setState({
        adGroups: response.adGroups,
        adGroupsLoading: false
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });

      this.setState({
        adGroupsLoading: false
      });
    }
  }

  private _getAdGroupRequestTypes(requestTypeId: number): void {
    this.setState(
      {
        adGroupRequestTypes: [],
        adGroupRequestTypesLoading: true
      },
      async () => {
        try {
          const response = await getAdGroupRequestTypes(requestTypeId);

          this.setState({
            adGroupRequestTypes: response.adGroupRequestTypes,
            adGroupRequestTypesLoading: false
          });
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            adGroupRequestTypes: [],
            adGroupRequestTypesLoading: false
          });
        }
      }
    );
  }

  private _renderHeaderColumns(): JSX.Element[] {
    return columns.map((column, index) => {
      return <Th text={column.text} title={column.title} key={index} />;
    });
  }

  private _renderBody(requestType: IRequestTypeV2): JSX.Element {
    const {
      adGroupRequestTypes,
      adGroupRequestTypesDeleting,
      adGroupRequestTypesLoading,
      adGroupRequestTypesSaving,
      adGroups,
      adGroupsLoading
    } = this.state;

    return (
      <LoaderWrapper key="requestTypeSettingsModal">
        <div className="requestTypeSettingsMetadata">
          <H2>{requestType.label}</H2>
          <P>{requestType.description}</P>
        </div>

        <Hr />

        <Field>
          <Label htmlFor="ad-group" text="AD Groups" />
          <Select
            id="ad-group"
            loading={adGroupsLoading}
            name="ad-group"
            onChange={e => this._handleAdGroupRequestTypeCreate(e, requestType.id)}
            options={adGroups.map(adGroup => {
              return (
                <option key={adGroup.azureGroupId} value={adGroup.azureGroupId}>
                  {adGroup.name}
                </option>
              );
            })}
            placeholder="Select an AD group to join..."
            showPlaceholder={true}
          />
        </Field>

        {adGroupRequestTypesLoading && <Loader loading={true} showImage={true} type="Inline" />}

        <ul className="list">
          {adGroupRequestTypes.map(adGroupRequestType => {
            return (
              <li className="listItem listItemBordered listItemStripedHover" key={adGroupRequestType.id}>
                <div className="listItemInner">
                  <span title={adGroupRequestType.adGroupObjectId}>{adGroupRequestType.adGroupName}</span>
                  <Button
                    className="buttonLinkPrimaryColor"
                    iconLeft={<Icon iconName="Trash" />}
                    onClick={e => this._handleAdGroupRequestTypeDelete(adGroupRequestType.id, adGroupRequestType.requestTypeId)}
                    text="Delete"
                    title="Delete"
                  />
                </div>
              </li>
            );
          })}
        </ul>

        <Loader loading={adGroupRequestTypesDeleting || adGroupRequestTypesSaving} type="Overlay" showImage={true} position="Top" />
      </LoaderWrapper>
    );
  }

  private _handleAdGroupRequestTypeCreate(e: React.ChangeEvent<HTMLSelectElement>, requestTypeId: number): void {
    const value: string = e.target.value;

    this.setState(
      {
        adGroupRequestTypesSaving: true
      },
      async () => {
        try {
          const { adGroups } = this.state;

          const adGroup = adGroups.filter(group => group.azureGroupId === value);

          const create: IAdGroupRequestTypeCreate = {
            adGroupId: adGroup[0].id,
            adGroupObjectId: adGroup[0].azureGroupId,
            requestTypeId: requestTypeId
          };

          const created = await createAdGroupRequestType(create);

          toast.success(created.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState(
            {
              adGroupRequestTypesSaving: false
            },
            () => {
              this._getAdGroupRequestTypes(requestTypeId);
            }
          );
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            adGroupRequestTypesSaving: false
          });
        }
      }
    );
  }

  private _handleAdGroupRequestTypeDelete(id: number, requestTypeId: number): void {
    this.setState(
      {
        adGroupRequestTypesDeleting: true
      },
      async () => {
        try {
          const deleted = await deleteAdGroupRequestType(id);

          toast.success(deleted.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState(
            {
              adGroupRequestTypesDeleting: false
            },
            () => {
              this._getAdGroupRequestTypes(requestTypeId);
            }
          );
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this.setState({
            adGroupRequestTypesDeleting: false
          });
        }
      }
    );
  }
}

export default RequestTypes;
