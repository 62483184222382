import { getRequest, postRequest, deleteRequest } from "../core/api/requestConfig";
// types
import { IResponseDeleted, IResponseCreated } from "../types/Response";
import { IAdGroupRequestTypesResponse, IAdGroupRequestTypeCreate } from "../types/AdGroupRequestType";

export function getAdGroupRequestTypes(requestTypeId: number): Promise<IAdGroupRequestTypesResponse> {
  return new Promise<IAdGroupRequestTypesResponse>((resolve, reject) => {
    getRequest(`/api/v1/adGroupRequestTypes?requestTypeId=${requestTypeId}`)
      .then((response: IAdGroupRequestTypesResponse) => {
        return resolve(response);
      })
      .catch((error: Error) => {
        return reject(error);
      });
  });
}

export function createAdGroupRequestType(create: IAdGroupRequestTypeCreate): Promise<IResponseCreated> {
  return new Promise<IResponseCreated>((resolve, reject) => {
    postRequest(`/api/v1/adGroupRequestTypes`, create)
      .then((response: IResponseCreated) => {
        return resolve(response);
      })
      .catch((error: IResponseCreated) => {
        return reject(error);
      });
  });
}

export function deleteAdGroupRequestType(id: number): Promise<IResponseDeleted> {
  return new Promise<IResponseDeleted>((resolve, reject) => {
    deleteRequest(`/api/v1/adGroupRequestTypes/${id}`)
      .then((response: IResponseDeleted) => {
        return resolve(response);
      })
      .catch((error: IResponseDeleted) => {
        return reject(error);
      });
  });
}
