import * as React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// components
import Alert from "../../components/alert/Alert";
import Checkbox from "../../components/input/Checkbox";
import Loader from "../../components/loader/Loader";
import LoaderWrapper from "../../components/loader/LoaderWrapper";
import { P, H2, Hr } from "../../components/typography";
import Page from "../../components/page/Page";
// helpers
import { shortFriendlyDateTime } from "../../helpers/time";
// logging
import { withPageLog } from "../logging/LogComponentChange";
// services
import { getAppModes, putAppMode } from "../../services/AppMode";
// types
import { IAppModeGet } from "../../types/AppMode";
import { IUser } from "../../types/User";

export interface IAppModesProps {
  user: IUser;
}

export interface IAppModesState {
  appModes: IAppModeGet[];
  error: Error | null;
  loading: boolean;
}

class AppModes extends React.Component<IAppModesProps, IAppModesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      appModes: [],
      error: null,
      loading: true
    };
  }

  public componentDidMount(): void {
    this._getAppModes();
  }

  public render(): React.ReactElement<IAppModesProps> {
    const { appModes, error, loading } = this.state;

    return (
      <Page className="appModes" permissions={true} title="Settings: App Modes">
        <LoaderWrapper>
          {error && !loading && <Alert show={true} text={error.message} type="Error" />}

          {appModes.map(appMode => {
            return (
              <div key={appMode.uuid}>
                <H2>{appMode.requestType.label}</H2>
                <Hr />

                <Checkbox
                  checked={appMode.mode}
                  id={appMode.uuid}
                  name={appMode.uuid}
                  onChange={e => this._toggleAppMode(e, appMode.uuid)}
                  showLabel={true}
                  text={appMode.label}
                />

                <P>{appMode.description}</P>

                {appMode.appModeAudit && appMode.appModeAudit.user && (
                  <P>
                    Last {appMode.appModeAudit.mode ? `enabled` : ` disabled`} by{" "}
                    <a href={`mailto:${appMode.appModeAudit.user.email}`}>{appMode.appModeAudit.user.name}</a>,{" "}
                    {shortFriendlyDateTime(appMode.appModeAudit.createdAt)}.
                  </P>
                )}
              </div>
            );
          })}

          {!loading && appModes.length === 0 && <Alert show={true} text={`There are no app modes.`} type="Info" />}

          <Loader loading={loading} position="Top" showImage={true} type="Overlay" />
        </LoaderWrapper>
      </Page>
    );
  }

  private _getAppModes = (): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const result = await getAppModes();

          this.setState({
            appModes: result.appModes,
            loading: false
          });
        } catch (error) {
          this.setState({
            error,
            loading: false
          });
        }
      }
    );
  };

  private _toggleAppMode = (e: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
    const { checked } = e.target;

    if (window.confirm(`Are you sure you want to ${checked ? `enable` : `disable`} this app mode?`)) {
      const appModes = this.state.appModes.slice();

      const appMode = appModes.find(_appMode => {
        return _appMode.uuid === uuid;
      });

      if (appMode) {
        appMode.mode = checked;

        this._updateAppMode(appMode);
      }
    }
  };

  private _updateAppMode = (appMode: IAppModeGet): void => {
    this.setState(
      {
        loading: true
      },
      async () => {
        const { user } = this.props;

        try {
          const updated = await putAppMode({ mode: appMode.mode, userId: user.id, uuid: appMode.uuid });

          toast.success(updated.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });

          this._getAppModes();
        } catch (error) {
          this.setState({
            error,
            loading: false
          });
        }
      }
    );
  };
}

export const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps)(withPageLog(AppModes));
